//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('inspectionReportFactory', ['inspectionReportServices', 'assetDataServices', 'assetServices', 'assetDataFactory', 'googlemaps', 'myLog', 'expanseConst', '$sce', 'utility', 'myAlert', 'myThrow',
    function(inspectionReportServices, assetDataServices, assetServices, assetDataFactory, googlemaps, myLog, expanseConst, $sce, utility, myAlert, myThrow) {

        //Return true/false if details are available for a question
        function _isDetailsAvailable(_question, _answer) {
            var _ret = false;

            if ((_question.viewType == 'thumbs' || 
                _question.viewType == 'map' ||
                _question.viewType == 'map-thumbs' ||
                _question.viewType == 'map-methane' ||
                _question.viewType == 'temperature-graph' ||
                _question.viewType == 'ocr' ||
                _question.viewType == 'thumbs-tag') && 
                (_answer && _answer.extraInfo && _answer.extraInfo.fdd_ids && _answer.extraInfo.fdd_ids.length>0)) {
                // (_answer && _answer.extraInfo && _answer.extraInfo != '{}' && _answer.extraInfo != '{null}')) {
                _ret = true;
            }

            return _ret;
        }

        function _getIconMethane(_threshold, _ppm) {
            var _icon;
            if (_ppm > _threshold ) {
                _icon = '/img/icons/markers/marker-red.png'
            } else {
                _icon = '/img/icons/markers/marker-green.png'
            }

            return _icon;
        }

        function _createMarker(_data) {
            var _icon = "";
            var _title = "";
            var _pathImg = null;

            if (_data.viewType == 'thumbs' || 
                _data.viewType == 'map' ||
                _data.viewType == 'map-thumbs') {
                
                _icon = '/img/icons/markers/marker-photos.png'
                _title = _data.assetDataDetails.fileName
                _pathImg = ""+_data.srcUrls.mdUrl

            } else if (_data.viewType == 'map-methane' ) {
                _icon = _getIconMethane(_data.methaneThreshold, _data.assetDataDetails.ppm)
                _title = ""+_data.assetDataDetails.ppm
            }

            const _marker = new google.maps.Marker({
                map: _data.map,
                position: new google.maps.LatLng(_data.lat, _data.lng),
                icon: _icon,
                title: _title,
                lat: _data.lat,
                log: _data.lng,
                id: _data.assetDataDetails.id,
                item: _data.assetDataDetails,
                pathImg: _pathImg
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                //The popup is based on an external .html file
                googlemaps.setInfoWindow(_data.map, _marker, _data.assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
            });

            return _marker;
        }


        return {
            getReportInfo: function(_assetData, _asset,  _success) {    
                //Get all the Inspection-Report-Answer records about _assetData
                const _inspectionReportPromise = new Promise((resolve, reject) => {
                    inspectionReportServices.getInspectionReports(_assetData.id, resolve, reject);
                });

                //Get all the Inspection-Report-Answer records about _assetData
                const _inspectionAnswerPromise = new Promise((resolve, reject) => {
                    inspectionReportServices.getInspectionAnswers(_assetData.id, resolve, reject);
                });

                //Get all the Inspection-Question records
                const _inspectionQuestionsPromise = new Promise((resolve, reject) => {
                    inspectionReportServices.getInspectionQuestionsByReportId(_assetData.id, resolve, reject);
                });

                const _assetStatusTypePromise = new Promise((resolve, reject) => {
                    assetServices.getAssetStatusTypes(resolve, reject);
                });

                var  result = Promise
                .all([_inspectionReportPromise, _inspectionAnswerPromise, _inspectionQuestionsPromise, _assetStatusTypePromise])
                .then(([_inspectionReports, _inspectionAnswers, _inspectionQuestions, _assetStatusTypes]) => {

                    var _listInspectionReportById = utility.getArrayByField(_inspectionReports, 'id');
                    var _listInspectionAnswersById = utility.getArrayByField(_inspectionAnswers, 'inspectionQuestionId');


                    //Collect all the info about the answer into an array
                    var _listInspectionReportObj = [];
                    _inspectionQuestions.forEach(e => {
                        var _item = {                            
                            question: e,
                            isDetailsVisible: false,
                            answer: null,
                        }

                        if (_listInspectionAnswersById && _listInspectionAnswersById[e.id]) {
                            var _answer = _listInspectionAnswersById[e.id];

                            _item.answer = _answer;
                            _item.answer.extraInfo = JSON.parse(_answer.extraInfo);
                            _item.inspectionReport = _listInspectionReportById[_answer.inspectionReportId];
                        }

                        _item.isDetailsAvailable= _isDetailsAvailable(_item.question, _item.answer),

                        _listInspectionReportObj.push(_item);
                    })

                    var _questionGroup;
                    _listInspectionReportObj.forEach(e => {
                        if (e.question.group != _questionGroup) {
                            e.questionGroup = e.question.group;
                            _questionGroup = e.question.group;
                        }
                    })

                    //Collect all the main info about the report
                    _assetData.name = 'Inspection Report Generated Automaticaly'
                    _assetData.executiveSummary = 'Summary about the report'
                    _assetData.createdAtFormatted = utility.formatDate(_assetData.createdAt, 'dd/mm/yyyy')

                    //Create and return an object that have all the info about a report
                    var _reportInfo = {
                        templateType: _inspectionReports[0].irttKey?_inspectionReports[0].irttKey:'default',
                        assetStatusTypes: _assetStatusTypes,
                        assetData: _assetData,
                        listInspectionReportObj: _listInspectionReportObj
                    };

                    return _reportInfo;
                });                


                return result.then(_res => {
                    _success(_res)
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                });
            },

            getReportInfoPrevious: function(_assetData, _asset,  _success) {
                assetDataServices.getInspectionReport(_assetData.id, _asset.id, _res => {
                    this.getReportInfo(_res, _asset, _res1 => {
                        _success(_res1)
                    })
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                })
            },

            addDetails: function(_inspectionReportObj, _assetData, _listAssetDataFileTypeByKey, _customerId, _asset, _success) {
                if (_inspectionReportObj.question.viewType == 'thumbs' ||
                    _inspectionReportObj.question.viewType == 'map' ||
                    _inspectionReportObj.question.viewType == 'map-thumbs' || 
                    _inspectionReportObj.question.viewType == 'ocr' ||
                    _inspectionReportObj.question.viewType == 'thumbs-tag') {

                    if (!_inspectionReportObj.details || _inspectionReportObj.details.length==0) {
                        assetDataServices.getAssetDataDetails(_inspectionReportObj.inspectionReport.facilityDataIdRef, _res => {
                            var _tmp = [];

                            //take only the facility_data_details with the reference into inspection_answer.extraInfo field 
                            // var _extraInfo = JSON.parse(_inspectionReportObj.answer.extraInfo);
                            var _extraInfo = _inspectionReportObj.answer.extraInfo;
                            if (_extraInfo && _extraInfo.fdd_ids) {
                                _extraInfo.fdd_ids.forEach(x => {
                                    _res.some(e => {
                                        if (e.id == x.fdd_id) {
                                            var _item = {
                                                detail : e,
                                            }
                                            
                                            if (_inspectionReportObj.question.viewType == 'thumbs-tag') {
                                                if (_extraInfo.tagKey) {
                                                    _item.srcUrls = assetDataFactory.buildSrcUrl(e, _assetData, _listAssetDataFileTypeByKey, _customerId, _asset.id, _extraInfo.tagKey)
                                                } else {
                                                    _item.srcUrls = assetDataFactory.buildSrcUrl(e, _assetData, _listAssetDataFileTypeByKey, _customerId, _asset.id, x.tagKey)
                                                }
                                            } else {
                                                _item.srcUrls = assetDataFactory.buildSrcUrl(e, _assetData, _listAssetDataFileTypeByKey, _customerId, _asset.id)
                                            }

                                            _tmp.push(_item)
                                            
                                            return true;
                                        }
                                    })
                                })
                            }
        
                            _inspectionReportObj.details = _tmp;        
                            _inspectionReportObj.zoomInitialized = false;


                            _success(_inspectionReportObj);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        });
                    }
                } else if (_inspectionReportObj.question.viewType == 'map-methane') {
                    if (_inspectionReportObj.listMethane == undefined) {
                        assetDataFactory.getAssetDataDetails(_inspectionReportObj.inspectionReport.facilityDataIdRef, _res => {

                            _inspectionReportObj.listMethane = _res[0].listMethane;
                            _inspectionReportObj.methaneThreshold= _asset.methaneThreshold;

                            _success(_inspectionReportObj);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        });
                    }
                } else if (_inspectionReportObj.question.viewType == 'temperature-graph') {
                    if (_inspectionReportObj.previousAnswers == undefined) {
                        inspectionReportServices.getInspectionAnswersPrevious(_assetData.id, _inspectionReportObj.question.id, _res => {

                            _inspectionReportObj.previousAnswers = _res;
                            _success(_inspectionReportObj);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        })
                    }
                }

            },
            getEstateAssets: function(_estateId, _success) {
                assetServices.getEstateAssets(_estateId, _res => {
                    _success(_res)
                })
            },
            createMap: function(_idMap, _callback) {    
                myLog("Create a map to inspection-report-info")
                googlemaps.initGoogleMap("#"+_idMap, _m => {
                    _callback(_m);
                });    
            },
            putMarkersOnMap: function(_inspectionReportObj, _map) {
                if (!_inspectionReportObj.listMarker) {
                    var _listMarker = [];

                    if (_inspectionReportObj.question.viewType == 'thumbs' ||
                        _inspectionReportObj.question.viewType == 'map' ||
                        _inspectionReportObj.question.viewType == 'map-thumbs') {

                        _inspectionReportObj.details.forEach(e => {
                            var _data = {
                                map: _map,
                                lat: e.detail.latitude, 
                                lng: e.detail.longitude, 
                                assetDataDetails: e.detail, 
                                srcUrls: e.srcUrls,
                                viewType: _inspectionReportObj.question.viewType
                            }
                            
                            _listMarker.push(_createMarker(_data));
                        })
                    } else if (_inspectionReportObj.question.viewType == 'map-methane') {
                        _inspectionReportObj.listMethane.forEach(e => {
                            var _data = {
                                map: _map,
                                lat: e.latitude, 
                                lng: e.longitude, 
                                assetDataDetails: e, 
                                viewType: _inspectionReportObj.question.viewType,
                                methaneThreshold: _inspectionReportObj.methaneThreshold
                            }

                            _listMarker.push(_createMarker(_data));
                        })

                    }
    
                    _inspectionReportObj.listMarker = _listMarker;

                    googlemaps.fitBounds(_map, _listMarker)
                }
            },
            updateMethaneMarkers: function(_inspectionReportObj, _threshold) {
                _inspectionReportObj.listMarker.forEach(e => {
                    e.setIcon(_getIconMethane(_threshold, e.item.ppm))
                });
            }



        };
    }
]);

