//Write here all the services about CUSTOMER
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("customerServices", ['myHttp', 'buildUrl', 'myLog', 'expanseConst', function(myHttp, buildUrl, myLog,expanseConst) {
        return  {
            // Customer's statuses and their colors
            customerStatusTitles: {
                active: 'Active',
                inactive: 'Inactive',
            },

            // customer's company types
            companyType: Object.freeze({
                No: 0,
                Inspecting: 1,
                Inspected: 2
            }),

            //Get all company's customers from backend
            //_companyId: user company
            //_success: callback function called if the request succeeded
            //_error: callback function called if the request goes wrong
            getAllCustomers: function(_companyId, _userId, _success, _error) {
                var _url = buildUrl( '/expanse/customers/byInspectingCompanyId/' + _companyId + "/byUserId/" + _userId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listCustomerDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the customers about the company: '"+_companyId+"'";
                    _error(_err);
                } );
            },

            getCustomer: function(_customerId, _success, _error) {
                var _url = buildUrl( '/expanse/customer/' + _customerId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.customerDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the customer with id: '"+_customerId+"'";
                    _error(_err);
                } );
            },

            saveCustomer: function(_userId, _customer, _success, _error) {
                var _url = buildUrl('/expanse/customer/' + _userId);

                myHttp.post(_url, JSON.stringify(_customer)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.customerDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this customer";
                    }

                    _error(_err);
                } );
            },

            /**
             * Get customer data
             * @param _companyId
             * @param _success
             * @param _error
             */
            getCustomerStatusData: function(_userId, _companyId, _success, _error) {
                const url = buildUrl( '/expanse/customers/info/byInspectingCompanyId/' + _companyId+"/byUserId/"+_userId);

                myHttp.get(url).success( res => {
                    if (!res || !res.companyInfoModel) {
                        _error(res);
                        return;
                    }
                    const info = res.companyInfoModel;

                    const sum = info.activeCustomers + info.inactiveCustomers;

                    const data = {
                        data: [
                            Math.round(info.activeCustomers / sum * 100),
                            Math.round(info.inactiveCustomers / sum * 100),
                        ],
                        dataAbs: [
                            info.activeCustomers,
                            info.inactiveCustomers
                        ],
                        labels: [
                            'active',
                            'inactive'
                        ],
                        titles: [
                            'Active customers',
                            'Inactive customers'
                        ],
                        backgroundColor: []
                    };

                    _success(data);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the customers data of the company: '"+_companyId+"'";
                    _error(_err);
                } );
            },

            getReports: function(_userId, _company, _success, _error, _isInspectingCompany) {
                const _url = _isInspectingCompany ?
                    buildUrl( '/expanse/reports/byInspectingCompanyId/' + _company+"/byUserId/"+_userId) :
                    buildUrl( '/expanse/reports/byCustomer/' + _company);

                myHttp.get(_url).success( _res => {
                    _success(_res.listReportDto.map(data => (Object.assign(data[0],
                        _isInspectingCompany ?
                        {
                            moreData: {
                                facility: data[1],
                                company: data[2]
                            }
                        } :
                        {
                            moreData: {
                                ic: data[1]
                            }
                        }
                    ))));

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    if (!_err.data) {
                        _err.data = {};
                    }
                    _err.data.message = "I wasn't able to get the customer with id: '" + _company + "'";
                    _error(_err);
                } );
            },

            getSeveritiesByLocations: function(locations) {
                const severities = {
                    comment: 0,
                    low: 0,
                    medium: 0,
                    high: 0,
                    other: 0
                };

                locations.forEach(location => {
                    switch (location.severity) {
                        case 'COMMENT':
                            severities.comment += 1;
                            break;
                        case 'LOW':
                            severities.low += 1;
                            break;
                        case 'MEDIUM':
                            severities.medium += 1;
                            break;
                        case 'HIGH':
                            severities.high += 1;
                            break;
                        default:
                            severities.other += 1;
                    }
                });

                return severities;
            },
            getCustomerLogo: function(_customerId, _success, _error) {    
                myHttp.get(buildUrl("/exAdministration/getFileStorageItem/companyLogo/byCompanyId/" + _customerId)).success(_res => {
                        if (_res && _res.path.length > 0) {
                            _success(_res.path);
                        } else {
                            _success();
                        }
                    }).error( _err => {
                        myLog("Error from " + _url, _err.data);
                        _err.data.message = "I wasn't able to get the customers logo by customerId: '"+_customerId+"'";
                        _error(_err);
                    } );     
                },
            
            getCustomerAssetData: function(_customerId, _id, _success, _error) {  
                
                this.getAllCustomers(_customerId, _id,  _resCust => {
                    //upload on cloud completed
                    
                    //extract customer IDs.
                    console.log( _resCust.map(function(a) {return a.id;}))

                    myHttp.post('/api/getAllCustomerData', JSON.stringify({'customer_ids': _resCust.map(function(a) {return a.id;})})).success(_res => {
                        console.log(JSON.parse(_res))
                        _success(JSON.parse(_res));
                    }).error( _err => {
                        myLog("Error from getAllCustomerData" + _err.data);
                        _err.data.message = "I wasn't able to get the customers logo by customerId: '"+_customerId+"'";
                        _error(_err);
                    } );     
    
                }, _err => {
                    _error ? _error(_err) : null;
                    myAlert("Error: " + _err.data.message, 'danger');
                });
                    
            },


            getAllInspectors: function(companyId, _userId, customerId,_success, _error) {
                var _url = '/api/getInspectors?companyId=' + companyId +"&userId="+_userId + "&customerId="+customerId;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );  
            }, 


        };
    }]);