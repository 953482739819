let expanseLiteApp = require('./modules/appModule.js'); 

expanseLiteApp


// =========================================================================
// Fast Launch
// =========================================================================

    .service('fastlaunchService', ['$resource', function($resource) {
    var hazardList = [];

    var addList = function(newObj) {
        //hazardList.push(newObj);
        hazardList = newObj;

    };

    var getList = function() {
        return hazardList;
    };

    var init = function() {
        //console.log('undefined the list');
        hazardList = {};
    };

    return {
        addList: addList,
        getList: getList,
        init: init
    };
}])

// =========================================================================
// Fast Launch Dummy
// =========================================================================

.service('fastlaunchServiceDummy', ['$resource', function($resource) {
    var hazardList = [];

    var addList = function(newObj) {
        //hazardList.push(newObj);
        hazardList = newObj;

    };

    var getList = function() {
        return hazardList;
    };

    return {
        addList: addList,
        getList: getList
    };
}])

// =========================================================================
// Header Messages and Notifications list Data
// =========================================================================

.service('messageService', ['$resource', function($resource) {
    this.getMessage = function(img, user, text) {
        var gmList = $resource("data/messages-notifications.json");

        return gmList.get({
            img: img,
            user: user,
            text: text
        });
    }
}])


// =========================================================================
// Best Selling Widget Data (Home Page)
// =========================================================================

.service('bestsellingService', ['$resource', function($resource) {
    this.getBestselling = function(img, name, range) {
        var gbList = $resource("data/best-selling.json");

        return gbList.get({
            img: img,
            name: name,
            range: range,
        });
    }
}])


// =========================================================================
// Todo List Widget Data
// =========================================================================

.service('todoService', ['$resource', function($resource) {
    this.getTodo = function(todo) {
        var todoList = $resource("data/todo.json");

        return todoList.get({
            todo: todo
        });
    }
}])


// =========================================================================
// Recent Items Widget Data
// =========================================================================

.service('recentitemService', ['$resource', function($resource) {
    this.getRecentitem = function(id, name, price) {
        var recentitemList = $resource("data/recent-items.json");

        return recentitemList.get({
            id: id,
            name: name,
            price: price
        })
    }
}])


// =========================================================================
// Recent Posts Widget Data
// =========================================================================

.service('recentpostService', ['$resource', function($resource) {
    this.getRecentpost = function(img, user, text) {
        var recentpostList = $resource("data/messages-notifications.json");

        return recentpostList.get({
            img: img,
            user: user,
            text: text
        })
    }
}])

// =========================================================================
// Job Board Data Table
// =========================================================================

.service('jobtableServiceX', ['JobsFactory',
    function(JobsFactory) {

        this.data = JobsFactory.query(function(JobsFactory) {});
    }
])

.service('jobtableService', [function() {
    this.data = [{
            "id": 10238,
            "status": "open"
        },
        {
            "id": 10243,
            "status": "closed"
        },
        {
            "id": 10248,
            "status": "open"
        },
        {
            "id": 10253,
            "status": "open"
        },
        {
            "id": 10234,
            "status": "open"

        },
        {
            "id": 10239,
            "status": "closed"
        },
        {
            "id": 10244,
            "status": "open"
        },
        {
            "id": 10249,
            "status": "open"
        },
        {
            "id": 10237,
            "status": "open"
        },
        {
            "id": 10242,
            "status": "closed"
        },
        {
            "id": 10247,
            "status": "open"
        },
        {
            "id": 10252,
            "status": "open"
        },
        {
            "id": 10236,
            "status": "open"
        },
        {
            "id": 10241,
            "status": "open"
        },
        {
            "id": 10246,
            "status": "open"
        },
        {
            "id": 10251,
            "status": "open"
        },
        {
            "id": 10235,
            "status": "closed"
        },
        {
            "id": 10240,
            "status": "open"
        },
        {
            "id": 10245,
            "status": "open"
        },
        {
            "id": 10250,
            "status": "open"
        }
    ];
}])

// =========================================================================
// Data Table
// =========================================================================

.service('tableService', [function() {
    this.data = [{
            "id": 10238,
            "name": "Marc Barnes",
            "email": "marc.barnes54@example.com",
            "username": "MarcBarnes",
            "contact": "(382)-122-5003"
        },
        {
            "id": 10243,
            "name": "Glen Curtis",
            "email": "glen.curtis11@example.com",
            "username": "GlenCurtis",
            "contact": "(477)-981-4948"
        },
        {
            "id": 10248,
            "name": "Beverly Gonzalez",
            "email": "beverly.gonzalez54@example.com",
            "username": "BeverlyGonzalez",
            "contact": "(832)-255-5161"
        },
        {
            "id": 10253,
            "name": "Yvonne Chavez",
            "email": "yvonne.chavez@example.com",
            "username": "YvonneChavez",
            "contact": "(477)-446-3715"
        },
        {
            "id": 10234,
            "name": "Melinda Mitchelle",
            "email": "melinda@example.com",
            "username": "MelindaMitchelle",
            "contact": "(813)-716-4996"

        },
        {
            "id": 10239,
            "name": "Shannon Bradley",
            "email": "shannon.bradley42@example.com",
            "username": "ShannonBradley",
            "contact": "(774)-291-9928"
        },
        {
            "id": 10244,
            "name": "Virgil Kim",
            "email": "virgil.kim81@example.com",
            "username": "VirgilKim",
            "contact": "(219)-181-7898"
        },
        {
            "id": 10249,
            "name": "Letitia Robertson",
            "email": "letitia.rober@example.com",
            "username": "Letitia Robertson",
            "contact": "(647)-209-4589"
        },
        {
            "id": 10237,
            "name": "Claude King",
            "email": "claude.king22@example.com",
            "username": "ClaudeKing",
            "contact": "(657)-988-8701"
        },
        {
            "id": 10242,
            "name": "Roland Craig",
            "email": "roland.craig47@example.com",
            "username": "RolandCraig",
            "contact": "(932)-935-9471"
        },
        {
            "id": 10247,
            "name": "Colleen Parker",
            "email": "colleen.parker38@example.com",
            "username": "ColleenParker",
            "contact": "(857)-459-2792"
        },
        {
            "id": 10252,
            "name": "Leah Jensen",
            "email": "leah.jensen27@example.com",
            "username": "LeahJensen",
            "contact": "(861)-275-4686"
        },
        {
            "id": 10236,
            "name": "Harold Martinez",
            "email": "martinez67@example.com",
            "username": "HaroldMartinez",
            "contact": "(836)-634-9133"
        },
        {
            "id": 10241,
            "name": "Keith Lowe",
            "email": "keith.lowe96@example.com",
            "username": "KeithLowe",
            "contact": "(778)-787-3100"
        },
        {
            "id": 10246,
            "name": "Charles Walker",
            "email": "charles.walker90@example.com",
            "username": "CharlesWalker",
            "contact": "(486)-440-4716"
        },
        {
            "id": 10251,
            "name": "Lillie Curtis",
            "email": "lillie.curtis12@example.com",
            "username": "LillieCurtis",
            "contact": "(342)-510-2258"
        },
        {
            "id": 10235,
            "name": "Genesis Reynolds",
            "email": "genesis@example.com",
            "username": "GenesisReynolds",
            "contact": "(339)-375-1858"
        },
        {
            "id": 10240,
            "name": "Oscar Palmer",
            "email": "oscar.palmer24@example.com",
            "username": "OscarPalmer",
            "contact": "(544)-270-9912"
        },
        {
            "id": 10245,
            "name": "Lena Bishop",
            "email": "Lena Bishop",
            "username": "LenaBishop",
            "contact": "(177)-521-1556"
        },
        {
            "id": 10250,
            "name": "Kent Nguyen",
            "email": "kent.nguyen34@example.com",
            "username": "KentNguyen",
            "contact": "(506)-533-6801"
        }
    ];
}])


// =========================================================================
// Malihu Scroll - Custom Scroll bars
// =========================================================================
.service('scrollService', function() {
    var ss = {};
    ss.malihuScroll = function scrollBar(selector, theme, mousewheelaxis) {
        $(selector).mCustomScrollbar({
            theme: theme,
            scrollInertia: 100,
            axis: 'yx',
            mouseWheel: {
                enable: true,
                axis: mousewheelaxis,
                preventDefault: true
            }
        });
    }

    return ss;
})


//==============================================
// BOOTSTRAP GROWL
//==============================================

.service('growlService', function() {
    var gs = {};
    gs.growl = function(message, type) {
        // var _width = $(window).width() / 2;
        var _spaces = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        $.growl({
            // icon: "img/icons/warning-sign.png",
            // message: _spaces + message + _spaces
            message: message 
        }, {
            icon_type: 'image',
            type: type,
            allow_dismiss: true,
            // label: 'Cancel',
            // className: 'btn-xs btn-inverse',
            placement: {
                from: 'top',
                align: 'center'
            },
            delay: 0,
            // animate: {
            //     enter: 'animated bounceIn',
            //     exit: 'animated bounceOut'
            // },
            offset: {
                // x: _width,
                y: 0
            }
        });
    }

    return gs;
})

//==============================================
// BOOTSTRAP GROWL version 3
//==============================================
.service('growlService2', function() {
    var gs = {};
    gs.notify = function(message, type) {
        $.notify({
            // options
            message: message
        }, {
            // settings
            element: 'body',
            position: null,
            type: type,
            allow_dismiss: false,
            allow_duplicates: false,
            newest_on_top: false,
            showProgressbar: false,
            placement: {
                from: "top",
                align: "right"
            },
            offset: 20,
            spacing: 10,
            z_index: 1031,
            delay: 5000,
            timer: 1000,
            url_target: '_blank',
            mouse_over: null,
            animate: {
                enter: 'animated bounceIn',
                exit: 'animated bounceOut'
            },
            onShow: null,
            onShown: null,
            onClose: null,
            onClosed: null,
            icon_type: 'class',
            template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>'
        });
    };
    return gs;
})


// //Prefix to call Fast Launch rest api
// .value('fastLaunchRestUrl', 'http://54.229.119.94:9090//fastLaunch')

//Service to build the header to call Fast Launch rest api with Authorization
.service('fsHttp', ['$resource', '$base64', function($resource, $base64) {
    var retrievedObject = localStorage.getItem('sffd.identity');
    var fd_user = JSON.parse(retrievedObject);

    var _auth = $base64.encode(fd_user.result[0].email + ":" + fd_user.result[0].xyz);

    var _headers = {
        'Authorization': 'Basic ' + _auth,
        "X-Requested-With": 'XMLHttpRequest'
    };

    return {
        header: function(_h) {
            var _ret = {};
            if (_h) {
                _ret = $.extend(_headers, _h);
            } else {
                _ret = { 'headers': _headers };
            }

            return _ret;
        }
    };
}])

// .service('fsValidator', ['sfUtil', function(sfUtil) {
//     var _errors = {};

//     function putErrorMsg(f, msg) {
//         if (!_errors[f]) {
//             //If the field f doesn't have any error, 
//             //add it, with the error message msg, to _errors array
//             _errors[f] = msg;
//         } else {
//             //If the field f exists in _error array, 
//             //contact the new msg to the existing one
//             var m = _errors[f] + ', ' + msg;
//             _errors[f] = m;
//         }
//     };

//     return {
//         newInstance: function() {
//             _errors = {};

//             return this;
//         },
//         // validate: function(element, fieldName) {
//         validate: function(a, b, c) {
//             var element;
//             var fieldName;
//             var dateA;
//             var dateB;

//             if (arguments.length === 2) {
//                 element = a;
//                 fieldName = b;
//             } else {
//                 dateA = a;
//                 dateB = b;
//                 fieldName = c;
//             }
//             return {
//                 isEmpty: function(errorMsg) {
//                     if (!element || ((element instanceof String) && element.length === 0)) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isUndefined: function(errorMsg) {
//                     if (element === undefined) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isGreaterThan: function(errorMsg) {
//                     if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (dateA > dateB) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isSmallerThan: function(errorMsg) {
//                     if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (dateA < dateB) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isNotEqualTo: function(errorMsg) {
//                     if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
//                     if (dateA !== dateB) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isValidEmail: function(errorMsg) {
//                     var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//                     if (!re.test(element)) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isValidMinSec: function(errorMsg) {
//                     var re = /^\d*\.?\d{1,2}$/;

//                     if (!re.test(element)) putErrorMsg(fieldName, errorMsg);

//                     return this;
//                 },
//                 isValidDate: function(errorMsg) {
//                     var dateString = element;

//                     if (dateString && dateString.trim().length > 0) {
//                         var _valid = true;
//                         // First check for the pattern
//                         if (!/^\d{1,2}\-\d{1,2}\-\d{4}$/.test(dateString))
//                             _valid = false;

//                         // Parse the date parts to integers
//                         var parts = dateString.split("-");
//                         var day = parseInt(parts[0], 10);
//                         var month = parseInt(parts[1], 10);
//                         var year = parseInt(parts[2], 10);

//                         // Check the ranges of month and year
//                         if ((_valid) && (year < 1000 || year > 3000 || month == 0 || month > 12))
//                             _valid = false;

//                         var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

//                         // Adjust for leap years
//                         if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
//                             monthLength[1] = 29;

//                         // Check the range of the day
//                         _valid = _valid && day > 0 && day <= monthLength[month - 1];

//                         if (!_valid) putErrorMsg(fieldName, errorMsg);
//                     }
//                 },
//                 isValidDateInsp: function(errorMsg) {
//                     var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//                     var dateString = element.split("/");
//                     var _valid = true;
//                     if (isNaN(dateString[0]) == true || dateString[0] < 0 || dateString[0] > 31) {
//                         _valid = false;
//                     }
//                     if (isNaN(dateString[2]) == true || dateString[2] < 1000 || dateString[2] > 3000) {
//                         _valid = false;
//                     }
//                     if (months.indexOf(dateString[1]) < 0) {
//                         _valid = false;
//                     }

//                     if (!_valid) putErrorMsg(fieldName, errorMsg);

//                 },
//                 isZero: function(errorMsg) {
//                     var _valid = true;
//                     if (element == 0) {
//                         _valid = false;
//                     }
//                     if (!_valid) putErrorMsg(fieldName, errorMsg);
//                 },
//                 isValidDateTime: function(errorMsg) {
//                     var dateTimeString = element;
//                     var _valid = false;
//                     try {
//                         var matches = dateTimeString.match(/^(\d{2})\-(\d{2})\-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/);
//                         if (matches === null) {
//                             // invalid
//                         } else {
//                             // now lets check the date sanity
//                             var year = parseInt(matches[3], 10);
//                             var month = parseInt(matches[2], 10) - 1; // months are 0-11
//                             var day = parseInt(matches[1], 10);
//                             var hour = parseInt(matches[4], 10);
//                             var minute = parseInt(matches[5], 10);
//                             var second = parseInt(matches[6], 10);
//                             var date = new Date(year, month, day, hour, minute, second);
//                             if (date.getFullYear() !== year ||
//                                 date.getMonth() != month ||
//                                 date.getDate() !== day ||
//                                 date.getHours() !== hour ||
//                                 date.getMinutes() !== minute ||
//                                 date.getSeconds() !== second
//                             ) {
//                                 // invalid
//                             } else {
//                                 // valid
//                                 _valid = true;
//                             }
//                         }
//                     } catch (e) {
//                         // invalid
//                     }
//                     if (!_valid) putErrorMsg(fieldName, errorMsg);
//                 },
//                 isThereSomeCharacter: function(_arrayChars, errorMsg) {
//                     var _valid = true;
//                     if (_arrayChars) {
//                         for(var i=0; i<_arrayChars.length; i++) {
                            
//                             if (element.includes(_arrayChars[i])) {
//                                 _valid = false;
//                                 break;
//                             }
//                         }
//                     }

//                     if (!_valid) putErrorMsg(fieldName, errorMsg);
//                 }

//             }
//         },
//         setErrors: function(errors) {
//             Object.keys(errors).forEach(e => {
//                 console.log(e, _errors[e]);
//                 // if (_errors[e]) {
//                 _errors[e] = errors[e];
//                 // }
//             })
//         },
//         getErrors: function() {
//             return _errors;
//         },
//         //run fn if there are errors
//         errors: function(fn) {
//             if (Object.keys(_errors).length > 0) fn();

//             return this;
//         },
//         //run fn if there aren't errors
//         success: function(fn) {
//             if (Object.keys(_errors).length === 0) fn();

//             return this;
//         },
//         isSuccess: function() {
//             return (Object.keys(_errors).length === 0);
//         }
//     }
// }])

// .service('fsMessage', ['$uibModal', '$interpolate', function($uibModal, $interpolate) {
//     var $ctrl = this;
//     $ctrl.items = [];

//     return {
//         openModal: function(size, title, msg, ctrl, fns) {
//             return $uibModal.open({
//                 ariaLabelledBy: 'modal-title',
//                 ariaDescribedBy: 'modal-body',
//                 templateUrl: '/views_TO-DELETE/sfModalContentVar.html',
//                 controller: 'MsgVarCtrl',
//                 controllerAs: '$ctrl',
//                 backdrop: false,
//                 size: size,
//                 resolve: {
//                     scope: function() {
//                         return {
//                             _title: title,
//                             _msg: msg,
//                             _ctrl: ctrl,
//                             _fns: fns
//                         };
//                     }
//                 }
//             });
//         },
//         openModalBody: function(size, title, ctrl, fns, htmlPage) {
//             return $uibModal.open({
//                 ariaLabelledBy: 'modal-title',
//                 ariaDescribedBy: 'modal-body',
//                 templateUrl: '/views/sfModalBodyVar.html',
//                 controller: 'MsgVarCtrl',
//                 controllerAs: '$ctrl',
//                 backdrop: false,
//                 size: size,
//                 resolve: {
//                     scope: function() {
//                         return {
//                             _title: title,
//                             _ctrl: ctrl,
//                             _fns: fns,
//                             _htmlPage: htmlPage
//                         };
//                     }
//                 }
//             });
//         }

//     }
// }])



.service('sfAllocation', ['$http', '$q', 'fastLaunchRestUrl', function($http, $q, fastLaunchRestUrl) {
    return {
        saveAllocationFromJob: function(job, id_user) {
            //Create a new allocation from this job
            let dateStr = (value) => typeof(value || '') === 'string' ? value : value.format('YYYY-MM-DD HH:mm:SS');

            let _allocation = {
                tag: "bgm-orange",
                companyId: job.companyId,
                equipmentBundleId: job.uavBundleAllocationId,
                pilotId: job.pilotId,
                startDate: dateStr(job.dateOn),
                endDate: dateStr(job.dateTo),
                fastLaunchJobId: job.id,
                isFromJob: true
            };


            var fsAllocationUrl = fastLaunchRestUrl + '/flCross/allocation/fromAssignment/' + id_user;

            var msForm = $http({
                method: "post",
                url: fsAllocationUrl,
                data: JSON.stringify(_allocation),
                headers: { 'Content-Type': 'application/json' }
            });

            $q.all([msForm]);
        }
    }
}])

.service('sfTechlog', ['$http', '$q', 'fastLaunchRestUrl', function($http, $q, fastLaunchRestUrl) {

    var _log = {};

    var fsAllocationUrl;
    var _msForm;
    var id_user;

    function loadInfo(equipmentBundleId, callback) {
        var retrievedObject = localStorage.getItem('sffd.identity');
        var fd_user = JSON.parse(retrievedObject);
        var company_id = fd_user.result[0].company_id;

        id_user = fd_user.result[0].id;

        fsAllocationUrl = fastLaunchRestUrl + '/flJob/techlog/automaticLogging/' + id_user;
        _msForm = {
            method: "post",
            url: fsAllocationUrl,
            headers: { 'Content-Type': 'application/json' }
        };

        _log.companyId = company_id;
        _log.userId = id_user;

        var ebUrl = fastLaunchRestUrl + '/flCross/equipmentBundle/' + equipmentBundleId;
        $http.get(ebUrl).success(res => {
            var _isSignedOut = res.equipmentBundleDto.isSignedOut;
            _log.isSignedOut = _isSignedOut;

            callback(res);
        });
    }

    return {
        addSignOut: function(equipmentBundleId, techlogId, userSign, callback) {
            loadInfo(equipmentBundleId, res => {
                _log.automaticLoggingTypeFlj = 'sign-out';
                _log.techlogFljId = techlogId;
                _log.sign = userSign;
                var msForm = _msForm;
                msForm.data = JSON.stringify(_log);

                $q.all([$http(msForm)]).then(function(result) {
                    callback(result);
                });
            });
        },
        addSignIn: function(equipmentBundleId, techlogId, userSign, callback) {
            loadInfo(equipmentBundleId, res => {
                _log.automaticLoggingTypeFlj = 'sign-in';
                _log.techlogFljId = techlogId;
                _log.sign = userSign;
                var msForm = _msForm;
                msForm.data = JSON.stringify(_log);

                $q.all([$http(msForm)]).then(function(result) {
                    callback(result);
                });

            });
        },
        addFlightState: function(equipmentBundleId, _flJobId, _state) {
            loadInfo(equipmentBundleId, res => {
                _log.automaticLoggingTypeFlj = _state;

                var _validState = false;
                switch (_state) {
                    case 'flight-commenced':
                        _log.description = "Flight Commenced";
                        _validState = true;
                        break;
                    case 'flight-completed':
                        _log.description = "Flight Completed";
                        _validState = true;
                        break;
                    case 'flight-cancelled':
                        _log.description = "Flight Cancelled";
                        _validState = true;
                        break;
                }

                if (_validState) {
                    var msForm = _msForm;

                    var ebUrl = fastLaunchRestUrl + '/flJob/techlog/byEb/' + equipmentBundleId + '/user/' + id_user;
                    $http.get(ebUrl).then(function(result) {
                        _log.techlogFljId = result.data.techlogFlj.techlogFljDto.id;
                        _log.fastLaunchJobId = _flJobId;
                        msForm.data = JSON.stringify(_log);

                        $q.all([$http(msForm)])
                    })
                }
            });

        },
        addSignedTo: function(equipmentBundleId, techlogId, username, callback) {
            loadInfo(equipmentBundleId, res => {
                _log.automaticLoggingTypeFlj = 'signed-to';
                _log.techlogFljId = techlogId;
                _log.description = "UAV signed to " + username;
                var msForm = _msForm;
                msForm.data = JSON.stringify(_log);

                $q.all([$http(msForm)]).then(function(result) {
                    callback(result);
                });
            });
        },
        addFlightTelemetry: function(equipmentBundleId, _flJobId, _telemetryRef, _startDateTime, _duration, _lonStart, _latStart, _lonEnd, _latEnd, _fileSize) {
            loadInfo(equipmentBundleId, res => {
                var msForm = _msForm;

                var ebUrl = fastLaunchRestUrl + '/flJob/techlog/byEb/' + equipmentBundleId + '/user/' + id_user;
                $http.get(ebUrl).then(function(result) {
                    var _data = result

                    var gmUrl = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + _latStart + "," + _lonStart;
                    $http.get(gmUrl).then(function(result) {
                        var _placeName;

                        result.data.results.some(e => {
                            e.address_components.some(e1 => {
                                e1.types.some(e2 => {
                                    if (e2 === "locality") {
                                        _placeName = e1.long_name;
                                        return true;
                                    }
                                });
                                if (_placeName) { return true; }
                            });
                            if (_placeName) { return true; }
                        });

                        _log.automaticLoggingTypeFlj = 'flight-telemetry';
                        _log.description = "Flight Telemetry";
                        _log.flightStartDateTime = _startDateTime;
                        _log.flightDuration = _duration;
                        _log.flightTelemetryLogRef = _telemetryRef;
                        _log.techlogFljId = _data.data.techlogFlj.techlogFljDto.id;
                        _log.fastLaunchJobId = _flJobId;
                        _log.gpsLatitudeStart = _latStart;
                        _log.gpsLongitudeStart = _lonStart;
                        _log.gpsLatitudeEnd = _latEnd;
                        _log.gpsLongitudeEnd = _lonEnd;
                        _log.placeName = _placeName;
                        _log.fileSize = _fileSize;
                        msForm.data = JSON.stringify(_log);

                        $q.all([$http(msForm)]);
                    });

                })
            });

        },
        addFlightDuration: function(equipmentBundleId, _flJobId, _time) {
            loadInfo(equipmentBundleId, res => {
                var msForm = _msForm;

                var ebUrl = fastLaunchRestUrl + '/flJob/techlog/byEb/' + equipmentBundleId + '/user/' + id_user;
                $http.get(ebUrl).then(function(result) {
                    _log.automaticLoggingTypeFlj = 'flight-duration';
                    _log.description = "Flight Duration";
                    _log.flightDuration = _time;
                    _log.techlogFljId = result.data.techlogFlj.techlogFljDto.id;
                    _log.fastLaunchJobId = _flJobId;
                    msForm.data = JSON.stringify(_log);

                    $q.all([$http(msForm)])
                })
            });
        }
    }
}])

.service('sfErrors', function() {
    return {
        constraintViolation: function() {
            return '_constraint_violation';
        }
    }
})


.service('sfUtil', function() {
    return {
        splitTime: function(sec) {
            var _sec = Math.round(sec / 1000);
            var _h = Math.floor(_sec / 3600);
            var _min = _sec - (_h * 3600)
            var _m = Math.floor(_min / 60);
            var _s = _min - _m * 60;

            var _flDur = "";
            if (_h > 0) {
                _flDur += _h + "h ";
            }
            if (_m > 0) {
                _flDur += _m + "m ";
            }
            if (_s > 0) {
                _flDur += _s + "s";
            }

            return _flDur;
        },
        splitTimeDot: function(sec) {
            var _sec = Math.round(sec / 1000);
            var _m = Math.floor(_sec / 60);
            var _s = _sec - _m * 60;

            var _flDur = "";
            _flDur += _m;
            if (_s > 0) {
                _flDur += "." + _s;
            }

            return _flDur;
        },
        formatDate(_date) {
            if (_date) {
                return new Date(_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
            } else {
                return null;
            }
        },
        formatSize(_size) {
            var _unitSize = [Math.pow(1024, 4), Math.pow(1024, 3), Math.pow(1024, 2), Math.pow(1024, 1)];
            var _unitSimbol = ["Tb", "Gb", "Mb", "Kb"];

            if (_size) {
                for (var i = 0; i < _unitSize.length; i++) {
                    // var _d = Math.round(_size/_unitSize[i]);
                    var _d = _size / _unitSize[i];

                    if (_d >= 1) {
                        return Math.round(_d * 100) / 100 + _unitSimbol[i];
                    }
                }

                return _size + "b";
            } else {
                return null;
            }
        },
        runEmptyField(_value, _text) {
            if (_value) {
                return _value;
            } else {
                return _text;
            }
        },
        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },
        compareJson: function(_js1, _js2) {
            return {
                removeElements: function() {
                    if (arguments.length > 0) {
                        _js1 = angular.copy(_js1)
                        _js2 = angular.copy(_js2)
                        Array.from(arguments).forEach(e => {
                            delete _js1[e];
                            delete _js2[e];
                        })
                    }

                    return this;
                },
                notEquals: function(fn) {
                    if (JSON.stringify(_js1) != JSON.stringify(_js2)) fn();

                    return this;
                },
                equals: function(fn) {
                    if (JSON.stringify(_js1) == JSON.stringify(_js2)) fn();

                    return this;
                }
            }

        },
        getGeoPosition: function(_lat1, _lon1, _lat2, _lon2) {
            var _dLon = _lon1 - _lon2;
            var _y = Math.sin(_dLon)*Math.cos(_lat1);
            var _x = Math.cos(_lat2)*Math.sin(_lat1) - Math.sin(_lat2)*Math.cos(_lat1)*Math.cos(_dLon);
            var _brng = Math.atan2(_y, _x)*180/Math.PI;
            var _direction_flag;

            if (_brng > -22.5 && _brng <22.5) _direction_flag = 'N'
            if (_brng > 22.5 || _brng <67.5) _direction_flag = 'NW'
            if (_brng > 67.5 || _brng <112.5) _direction_flag = 'W'
            if (_brng > 112.5 && _brng <157.5) _direction_flag = 'SW'
            if (_brng > 157.5 || _brng <-157.5) _direction_flag = 'S'
            if (_brng > -157.5 && _brng < -112.5) _direction_flag = 'SE'
            if (_brng > -112.5 && _brng <-67.5) _direction_flag = 'E'
            if (_brng > -67.5 && _brng <-22.5) _direction_flag = 'NE'

            return _direction_flag
        }

    }
})



// .service('myHttp', function($http, $base64) {
//     var _user = undefined;

//     function loadState() {
//         var retrievedObject = localStorage.getItem('exlite.identity');
//         _user = JSON.parse(retrievedObject);

//     }

//     return {
//         authentifiedRequest: function(method, url, data, okCallback, koCallback) {
//                 loadState();
//                 // var _auth = $base64.encode(_user.result[0].email + ":" + CryptoJS.AES.decrypt(_user.result[0].abc, 'abcdefg').toString(CryptoJS.enc.Utf8));
//                 var _auth = $base64.encode(_user.login + ":" + CryptoJS.AES.decrypt(_user.password, 'abcdefg').toString(CryptoJS.enc.Utf8));

//                 var _header = {
//                     'Authorization': 'Basic bGFwaUBsaWJlcm86bGFwaQ==',
//                     'X-Requested-With': 'XMLHttpRequest',
//                     // 'Access-Control-Allow-Origin': 'http://localhost:8080',
//                     'Access-Control-Allow-Credentials': true
//                 };

//                 $http({
//                     method: method,
//                     url: url,
//                     data: data,
//                     headers: _header
//                 }).success(r => { okCallback(r) }).error(err => { koCallback() });
//             }
//             // ,
//             // clean: function() {
//             //     localStorage.setItem('sffd.identity',null);
//             //     var retrievedObject = localStorage.getItem('sffd.identity');
//             //     var _user = JSON.parse(retrievedObject);
//             //     console.log("clean", _user);
//             // }
//     }
// })


