//Write in this controller all the code about Asset
//In this code you can find the words 'asset', 'estate', 'assetEstate', ....
//All of them have to be considered assets and estate. The difference depend on the asset_type
//An estate is a particular asset
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.controller('assetCtrl',
    ['$scope', '$stateParams','Scopes', 'myLog', 'myAlert', 'myHttp', '$http', '$sce', '$filter', 'assetServices', 'ngTableParams', 'popup', 'utility', 'utilityImage', 'buildChart', 'googlemaps', '$mdDialog', 'popupConfirm', 
        'fsValidator', 'singleUploader', '$timeout', 'expanseConst', 'featureService', 'appFactory', 'catalogueFactory', 'productServices', 'customerServices', 'structureServices', 'inventoryFactory',
        'signalPropagationFactory', 'dynamicCADFactory','bomFactory', 'classManagmentFactory', 'assetDataServices', 'ngTableEventsChannel', 'projectFactory',
        function ($scope, $stateParams, Scopes, myLog, myAlert, myHttp, $http, $sce, $filter, assetServices, ngTableParams, popup, utility, utilityImage, buildChart, googlemaps, $mdDialog, popupConfirm, 
            fsValidator, singleUploader, $timeout, expanseConst, featureService, appFactory, catalogueFactory, productServices, customerServices, structureServices,  inventoryFactory,
            signalPropagationFactory, dynamicCADFactory, bomFactory, classManagmentFactory, assetDataServices, ngTableEventsChannel, projectFactory) {
                
            //Get the information about the user logged
            var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

            var _this = this;

            //-------------------------- private area Begin -----------------------------------
            var _controllerName = "assetCtrl";                  //controller name
            var _idAssetStatusChart = "_assetStatusChart";      //html element id, that contain the chart
            var _assetStatus;                                   //array represents the asset status (see _buildAssetStatus function)
            var _maps = [];                                     //array contains all the google maps created
            var _listAssetMarker = [];                          //marker array about assets
            var _listEstateMarker = [];
            var _listNetworkLinkMarker = [];
            //-------------------------- private area End-- -----------------------------------

            // ------------------------- private NOKIA asset list tupload ---------------------

            _this.uploader_al = singleUploader('assetList', {
                url: 'api/addAsList',
                clickable: '#addAssetList',
                autoProcessQueue: true
            });

            // _this.uploader_in = singleUploader('assetListInventory', {
            //     url: 'api/addAsList',
            //     clickable: '#addInventory',
            //     autoProcessQueue: true
            // });

            
    
            //-------------------------- _this area Begin -------------------------------------   
            _this.utilityRef = utility;         
            _this.expanseConst = expanseConst;              //Expose expanse's consts service
            _this.utilityImage = utilityImage;
            _this.assetSelected;
            _this.listAsset = [];                               //contain the assets list
            _this.listEstate = [];                               //contain the estates list
            _this.assetTableParams;                             //contain the ng-table parameters
            _this.isAssetTableFilterVisible = false;            //Set asset table filter unvisible
            _this.views = {                                     //asset views available
                // 'assetList': 'asset-list',
                // 'estateList': 'estate-list',
                // 'networkLinkList': 'networkLink-list',
                // 'assetMap': 'asset-map',
                // 'estateMap': 'estate-map',
                // 'networkLinkMap': 'networkLink-map',
                'signalPropagationMap': 'signalPropagation-map',
                'inventory': 'inventory',
                'asset': 'asset',
                'estate': 'estate',
                'networkLink':'networkLink',
                'assetMapper':'assetMapper',
                'dynamicCADEditor':'dynamic-cad-editor',
                'project':'project'
            };
            _this.viewsDescription = {                                     
                'asset': 'Assets',
                'estate': 'Estates',
                'networkLink': 'Network Links',
                'project':'Project'
            }
            _this.menuOptions = {
                'asset': 'asset',
                'estate': 'estate',
                'networkLink':'networkLink',
                'chart': 'chart',
                'project':'project'

            }

            _this.viewShown = _this.views.asset;      //hold info about what view, about asset, is shown at the moment
            _this.menuOption = _this.menuOptions.data;

            _this.assetCanvasId = '_assetCanvas';         //asset-estate html canvas id
            _this.estateCanvasId = '_estateCanvas';         //asset-estate html canvas id
            _this.networkLinkCanvasId = '_networkLinkCanvas';         //asset-estate html canvas id
            _this.signalPropagationMapId = '_signalPropagationMapId';
            _this.listAssetStatusTypeByKey = [];
            _this.listAssetStatusType = [];
            _this.listAssetType = [];
            _this.listAsset3dModel = [];
            _this.listAssetStructure = [];
            _this.listAssetForensicPoint = [];
            _this.estateArea = undefined;
            _this._bearing_orientation = 0;
            _this._image_angle = 0;
            _this.inventoryData = {};
            _this.pippo = '';
            _this.listAssetStatusPhase = [];
            _this.listIscQuestionCategory = [];
            //-------------------------- _this area End-- -------------------------------------

            //-------------------------- $scope area Begin ------------------------------------
            $scope.assetIdSelected;
            $scope.listFeatureKey = appFactory.getListFeatureKey();
            //-------------------------- $scope area End-- ------------------------------------



            var _listAssetStructure = [];
            _this.listAssetDataFileTypeByKey = [];


            //function used into html and this controller
            $scope.isFeatureEnabled = function(_featureKey) {
                return featureService.isEnabled(_featureKey);
            }

            //function used into popup
            _this.isFeatureEnabled = function() {
                return function(_featureKey) {
                    return featureService.isEnabled(_featureKey);
                } 
            }
    

            $scope.dropDownSettings = {
                styleActive: true, template: '<b>{{option.name}}</b>', scrollableHeight: '200px', scrollable: true,
                smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return "Asset list"; }
            };

            $scope.dropDownCstomTexts = { buttonDefaultText: 'Assets list' };

            var _status_labels = '<div style="display: inline-flex"> <div class="asset-status {{option.key}}" ></div>';

            var _template = _status_labels + ' <div><b>{{option.value}}</b></div></div>';

            $scope.dropDownSettingsAssetStatus = {
                template: _template, showCheckAll: false, showUncheckAll: false,
                smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
            };

            $scope.dropDownEventsAssetStatus = {
                onItemSelect: function (item) {
                }
            }

            var _status_project_labels = '<div style="display: inline-flex"> <div class="asset-status-project {{option.key}}" ></div>';

            var _template_project = _status_project_labels + ' <div><b>{{option.value}}</b></div></div>';

            $scope.dropDownSettingsAssetStatusProject = {
                template: _template_project, showCheckAll: false, showUncheckAll: false,
                smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
            };

            $scope.dropDownEventsAssetStatusProject = {
                onItemSelect: function (item) {
                }
            }


            $scope.dropDownSettingsInventoryItemExtraInfoLabel = {
                styleActive: true, template: '<b>{{option.value}}</b>', scrollableHeight: '200px', scrollable: true,
                smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.key; }
            };


            function _checkParams() {
                if (_assetStatus) {
                    // if ($stateParams.customer_id) {
                    //     _this.getCustomerAssetsEstates($stateParams.customer_id, _res => {

                    //         if ($stateParams.asset_id) {
                    //             var _index = utility.getIndexArrayElementByField(_this.listAsset, 'id', $stateParams.asset_id);
                    //             if (_index != undefined) {
                    //                 _this.setAssetSelected(_this.listAsset[_index]);
                    //             } else {
                    //                 _index = utility.getIndexArrayElementByField(_this.listEstate, 'id', $stateParams.asset_id);                                    
                    //                 if (_index != undefined) {
                    //                     _this.setAssetSelected(_this.listEstate[_index]);
                    //                 } else {
                    //                     _index = utility.getIndexArrayElementByField(_this.listNetworkLink, 'id', $stateParams.asset_id);                                    
                    //                     if (_index != undefined) {
                    //                         _this.setAssetSelected(_this.listNetworkLink[_index]);
                    //                     }    
                    //                 }
                    //             }
                    //         }
                    //     });         
                    // }

                    var _customerId = $stateParams.customer_id;
                    var _assetId = $stateParams.asset_id;
                    if (_customerId) {_this.customerIdSelected = _customerId;}
                    if (_customerId && _assetId) {
                        _this.getCustomerAssetsEstates(_customerId, _res => {
                            var _items = [..._this.listAsset, ..._this.listEstate, ..._this.listNetworkLink];

                            var _item = _items.find(e => e.id == _assetId);
                            if (_item) _this.setAssetSelected(_item)    
                        });         
                    }
    
                } else {
                    $timeout(function() {
                        _checkParams();
                    }, 200)    
                }
            }


            _this.getAllCustomers = function(_success) {
                if (_identity.isInspector) {
                    customerServices.getAllCustomers(_identity.companyId, _identity.id, _res => {
                        _success(_res);
                    })    
                } else {
                    customerServices.getCustomer(_identity.companyId, _res => {
                        _success(_res);
                    })    
                }
            }            

            //Write here the code that you need when the controller is created
            //The $scope will be put and get from store.
            //In this way it will be possible to cache some information
            //and it will be possible to use some function in others controllers
            _this.init = function () {
                ngTableEventsChannel.onAfterCreated(function(_event) {
                    if (_event._settings.my_info && _event._settings.my_info.tableName=='assetTable') {
                        var _state = _identity.assetTableParamsState;

                        if (_state) {
                            if (_state.customerId == _this.customerIdSelected) {
                                console.log("table created........................", _event)
                                _identity.assetTableParamsState.hasTableJustCreated = true;    
                            } 
                        }  else {
                            _identity.assetTableParamsState = {
                                hasTableJustCreated: true,
                                page: 1,
                                count: 10,
                                customerId: _this.customerIdSelected,
                            }
                        }
                    }
                }, $scope)

                ngTableEventsChannel.onPagesChanged(function(_event) {
                    if (_event._settings.my_info && _event._settings.my_info.tableName=='assetTable' ) {
                        if (_identity.assetTableParamsState && Object.keys(_identity.assetTableParamsState).length) {
                            if (_identity.assetTableParamsState.hasTableJustCreated) {
                                _this.assetTableParams.count(_identity.assetTableParamsState.count)
                                _this.assetTableParams.page(_identity.assetTableParamsState.page)
                                _this.assetTableParams.sorting(_identity.assetTableParamsState.sorting)
                                _identity.assetTableParamsState.hasTableJustCreated = false;    
                            } else {
                                _identity.assetTableParamsState = {
                                    page: _this.assetTableParams.page(),
                                    count: _this.assetTableParams.count(),
                                    sorting: _this.assetTableParams.sorting(),
                                    customerId: _this.customerIdSelected,
                                }        
                            }    

                            localStorage.setItem('exlite.identity', angular.toJson(_identity));
                        }

                        console.log("page.........---", _identity.assetTableParamsState)
                    }

                }, $scope);


                //I need to make this check
                //in case the user change the customer_id in the url
                _this.getAllCustomers(_res => {

                    if ((_identity.isInspector && $stateParams.customer_id && _res.filter(e => e.id == $stateParams.customer_id).length == 1) || 
                        (!_identity.isInspector && $stateParams.customer_id==_res.id) || !$stateParams.customer_id) {

                        // if (Scopes.get(_controllerName)) {
                        //     _identity.assetTableParamsState = Scopes.get(_controllerName).assetTableParamsState;
                        // }
                        Scopes.store(_controllerName, $scope);

                        _this.menuOption = _this.menuOptions.asset;
                        _this.isAssetMenuOpen = true;
                        _this.isAssetMenuOpenTransitionCompleted = true;
            
                        _checkParams();
        
        
                        var _mainCtrl = Scopes.get('mainCtrl');
                        _getAssetStatusTypes(_res => {
                            _this.listAssetStatusType = _res;
                            _this.listAssetStatusTypeByKey = utility.getArrayByField(_res, 'key');
        
                            console.log('status types', _this.listAssetStatusType, _this.listAssetStatusTypeByKey);
        
                            _assetStatus = _buildAssetStatus(_res, _mainCtrl.getCssAboutAssetStatusChart());
            
                        });

                        assetServices.getAssetStatusProject(_res => {
                            _this.listAssetStatusProject = _res;
                            _this.listAssetStatusProjectByKey = utility.getArrayByField(_res, 'key');
                        })
        
                        _getAssetTypes(_res => {
                            _this.listAssetType = _res.filter(e => e.key != 'estate' && e.key != 'networkLink');
        
                            _this.listAssetTypeKey = utility.getArrayByField(_res, 'id')
                            _this.listAssetTypeByKey = utility.getArrayByField(_res, 'key');
        
        
                            myLog('listAssetType', _this.listAssetType)
                        })
        
                        assetServices.getFacilityStatusPhase(_res => {
                            _this.listAssetStatusPhase = _res.listFacilityStatusPhaseDto;
                        }, _err => {
                            _error ? _error(_err) : null;
        
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });
        
                        assetServices.getIscQuestionCategory(_res => {
                            _this.listIscQuestionCategory = _res.iscQuestionCategoryDto;
                        }, _err => {
                            _error ? _error(_err) : null;
        
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });
                        
        
                        assetServices.getSiteTypes(_res => {
                            _this.listSiteType = _res;                
                        }, _err => {
                            _error ? _error(_err) : null;
        
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });
        
                        assetServices.getStructureTypeByCompanyId(_identity.companyId, _res => {
                            _this.listStructureType = _res;                
                            _this.listStructureTypeByKey = utility.getArrayByField(_res, 'key');
                        }, _err => {
                            _error ? _error(_err) : null;
        
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });    
                        
                        _getAssetDataFileTypes(_res => {
                            _this.listAssetDataFileType = _res;
                            _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');
        
                        });
                    } else {
                        myAlert("Error: Customer required doesn't exist", 'danger');
                    }
                })


            }

            _this.toggleAssetMenu = function(_state) {
                _this.isAssetMenuOpen = _state?_state:!_this.isAssetMenuOpen;

                $timeout(function() {

                    if (_this.isAssetMenuOpen) {
                        _this.isAssetMenuOpenTransitionCompleted = true
                    } else {
                        _this.isAssetMenuOpenTransitionCompleted = false
                    }    
                }, 250)
            }

            _this.setMenuOption = function(_option) {
                _this.menuOption = _option;
            }

            function _getAssetDataFileTypes(_success, _error) {
                var _array = [];

                assetServices.getAssetDataFileTypes(_res => {
                    _success ? _success(_res) : null;

                    myLog("Asset data file types list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }

            _this.setView = function (_view) {
                _this.viewShown = _view;

                if (_view == _this.views.asset) {
                    _this.showAssetList();
                } else if (_view == _this.views.estate) {
                    _this.showEstateList();
                } else if (_view == _this.views.networkLink) {
                    _this.showNetworkLinkList();
                } else if (_view == _this.views.project) {
                    _this.showProjectList();
                }
            }

            //Get all the asset status types
            //return the asset status type list
            //and an array by key with the asset status type
            function _getAssetStatusTypes(_success, _error) {
                var _array = [];

                assetServices.getAssetStatusTypes(_res => {
                    _success ? _success(_res) : null;

                    myLog("Assets status types list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }

            function _getAssetTypes(_success, _error) {
                var _array = [];

                assetServices.getAssetTypes(_res => {
                    _success ? _success(_res) : null;

                    myLog("Assets types list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }

            function _getAsset3dModelsByAssetType(_companyId, _assetTypeId, _success, _error) {
                var _array = [];

                assetServices.getAsset3dModelsByAssetType(_companyId, _assetTypeId, _res => {
                    _success ? _success(_res) : null;

                    myLog("Assets 3D Model list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }


            function _getAsset3dPhotogrammetryByAssetId(_assetId, _success, _error) {
                var _array = [];

                assetServices.getAsset3dPhotogrammetryByAssetId(_assetId, _res => {
                    _success ? _success(_res) : null;

                    myLog("Assets 3D Photogrammetry list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }


            //Set up the assets table ngTableParams
            _this.getAssetTableParams = function () {
                // _this.assetTableParams = ngTableParams.default();
                // _this.estateTableParams = ngTableParams.default();
                // _this.networkLinkTableParams = ngTableParams.default();
            }

            function _updateAssetStatusChart(_view) {
                var _list = undefined;

                if (_view  == _this.views.asset) {
                    _list = _this.listAsset;
                    // var _assetStatusComputed = _computeAssetStatusValues(_this.listAsset, _assetStatus);
                    // _this.setAssetStatusChart(_assetStatusComputed);
                } else if (_view  == _this.views.estate) {
                    _list = _this.listEstate;
                    // var _assetStatusComputed = _computeAssetStatusValues(_this.listEstate, _assetStatus);
                    // _this.setAssetStatusChart(_assetStatusComputed);
                } else if (_view  == _this.views.networkLink) {
                    _list = _this.listNetworkLink;
                }

                var _assetStatusComputed = _computeAssetStatusValues(_list, _assetStatus);
                _this.setAssetStatusChart(_assetStatusComputed);

            }

            //refresh the table that contains the asset/estate list
            //and the status chart
            _this.showAssetList = function () {
                //set the asset view shown to asset/estate list
                _this.viewShown = _this.views.asset;

                utility.callFunctionEveryTime("$('#_assetListPanel').get(0)", 250, function () {
                    _this.assetTableParams = ngTableParams.withConfig({sorting: { latestUpdate: 'desc' }}, _this.listAsset);

                    // var _assetStatusComputed = _computeAssetStatusValues(_this.listAsset, _assetStatus);
                    // _this.setAssetStatusChart(_assetStatusComputed);
                    _updateAssetStatusChart(_this.viewShown);

                })
            }
            
            _this.showEstateList = function () {
                //set the asset view shown to asset/estate list
                _this.viewShown = _this.views.estate;

                utility.callFunctionEveryTime("$('#_assetListPanel').get(0)", 250, function () {
                    _this.estateTableParams = ngTableParams.default(_this.listEstate);

                    // var _assetStatusComputed = _computeAssetStatusValues(_this.listEstate, _assetStatus);
                    // _this.setAssetStatusChart(_assetStatusComputed);
                    _updateAssetStatusChart(_this.viewShown);
                })
            }

            _this.showNetworkLinkList = function () {
                //set the asset view shown to asset/estate list
                _this.viewShown = _this.views.networkLink;

                utility.callFunctionEveryTime("$('#_assetListPanel').get(0)", 250, function () {
                    _this.networkLinkTableParams = ngTableParams.default(_this.listNetworkLink);

                    // var _assetStatusComputed = _computeAssetStatusValues(_this.listEstate, _assetStatus);
                    // _this.setAssetStatusChart(_assetStatusComputed);
                    _updateAssetStatusChart(_this.viewShown);
                })
            }

           
            _this.showEstateAssets = function (_estate) {
                _estate.showAssets = !_estate.showAssets;


                if (_estate.showAssets) {
                    assetServices.getEstateLinkAssets(_estate.id, _res => {
                        _estate.listAsset = _this.listAsset.filter(e => {
                            var _found = false;
                            _res.some(r => {
                                if (e.id == r.facilityId) {
                                    _found = true
                                    return true;
                                }
                            })

                            return _found;
                        });

                        _this.listEstateCleaned.find(e => {
                            if (e.id == _estate.id) {
                                e.listAsset = _cleanListAssetForTableSearch(_estate.listAsset)
                            }
                        })

                        myLog("Estate's assets", _estate.listAsset)

                        _this.listEstate.some((e, _index) => {
                            if (e.id == _estate.id) {
                                _this.listEstate[_index] = _estate;
                                return true;
                            }
                        })

                        // _this.estateTableParams = ngTableParams.default(_this.listEstate)
                        $("#_assetSearch").change();

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })
                }
            }

            _this.showNetworkLinkAssets = function (_networkLink) {
                _networkLink.showAssets = !_networkLink.showAssets;


                if (_networkLink.showAssets) {
                    assetServices.getNetworkLink(_networkLink.id, _res => {
                        _networkLink.listAsset = _this.listAsset.filter(e => {
                            var _found = false;
                            _res.some(r => {
                                if (e.id == r.facilityId) {
                                    _found = true
                                    return true;
                                }
                            })

                            return _found;
                        });

                        _this.listNetworkLinkCleaned.find(e => {
                            if (e.id == _networkLink.id) {
                                e.listAsset = _cleanListAssetForTableSearch(_networkLink.listAsset)
                            }
                        })

                        myLog("Network Link's assets", _networkLink.listAsset)

                        _this.listNetworkLink.some((e, _index) => {
                            if (e.id == _networkLink.id) {
                                _this.listNetworkLink[_index] = _networkLink;
                                return true;
                            }
                        })

                        // _this.networkLinkTableParams = ngTableParams.default(_this.listNetworkLink)
                        $("#_assetSearch").change();

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })
                }
            }


            _this.preGetCustomerAssetsEstates = function(_customer, _customerPrev, _customerCtrl)  {
                if (_this.viewShown == _this.views.inventory) {
                    //if has been chosen a new customer
                    //check if the application is on the Inventory Screen,
                    //in that case the _assetCtrl.closeInventory will be call
                    inventoryFactory.closeInventory(_this.inventoryData, function() {
                        _this.showAssetList(_customer.id)    

                        _this.getCustomerAssetsEstates(_customer.id);
                        _customerCtrl.setCustomerSelected(_customer); 
                        _customerCtrl.getCustomerUsers(_customer.id)
        
                    }, function() {
                        var _assetSelected = angular.copy(_this.assetSelected);

                        // _this.getCustomerAssetsEstates(_customerPrev.id);
                        _customerCtrl.setCustomerSelected(_customerPrev); 
                        _customerCtrl.getCustomerUsers(_customerPrev.id)
                        _this.setAssetSelected(_assetSelected)
                    })
                } else {
                    _this.getCustomerAssetsEstates(_customer.id);
                    _customerCtrl.setCustomerSelected(_customer); 
                    _customerCtrl.getCustomerUsers(_customer.id)
                }
            }

            function _getDatasetLatestUploaded(_customerId, _userId) {
                console.log("_getDatasetLatestUploaded....begin....", _customerId, _userId, Date.now())
                assetDataServices.getAssetDataLatestUploadedByCustomerId(_customerId, _identity.id, _res => {
                    _this.listAsset.forEach(e => {
                        var _found = false;
                        _res.find(ds => {
                            if (e.id == ds.facilityId) {
                                var _facilityData = e.lastUpdateTime?e.lastUpdateTime:e.createdDate;
                                e.latestUpdate = ds.createdAt>_facilityData?ds.createdAt:_facilityData;
                                _found = true;
                                return true;
                            }
                        })
                        if (!_found) e.latestUpdate = e.lastUpdateTime?e.lastUpdateTime:e.createdDate;
                    })
                    
                    _this.assetTableParams = ngTableParams.withConfig({sorting: { latestUpdate: 'desc' },}, _this.listAsset, {tableName: 'assetTable'});   
                    

                    console.log("_getDatasetLatestUploaded....end....", _customerId, _userId, Date.now())
                })
            }

            //Get all the customer's assets
            //and put them into listAsset variable
            _this.getCustomerAssetsEstates = function (_customerId, _success) {
                if (_customerId) {
                    _this.customerIdSelected = _customerId;

                    if (_this.viewShown==_this.views.asset || _this.viewShown==_this.views.estate || _this.viewShown==_this.views.estate) {
                        if (_identity.assetTableParamsState && _identity.assetTableParamsState.customerId!=_customerId) { 
                            _identity.assetTableParamsState = {
                                hasTableJustCreated: false,
                                page: 1,
                                count: 10,
                                customerId: _customerId,
                            }
                        }
                        _this.setAssetSelected(null);
    
                        myLog("Get all asset with customerId: ", _customerId);
    
                        //Get the customer's assets
                        assetServices.getCustomerAssets(_identity.id, false, _customerId, _res => {
                            myLog("Assets list:", _res);
    
    
                            _this.listAsset = _res.filter(e => e.facilityTypeId && _this.listAssetTypeKey[e.facilityTypeId].key != 'estate' && _this.listAssetTypeKey[e.facilityTypeId].key != 'networkLink');
                            _this.listEstate = _res.filter(e => e.facilityTypeId && _this.listAssetTypeKey[e.facilityTypeId].key == 'estate');
                            _this.listNetworkLink = _res.filter(e => e.facilityTypeId && _this.listAssetTypeKey[e.facilityTypeId].key == 'networkLink');                        
    
                            //Set up the assets table data with _res data
                            // _this.assetTableParams = ngTableParams.withConfig({sorting: { latestUpdate: 'desc' },}, _this.listAsset);
                            _this.estateTableParams = ngTableParams.default(_this.listEstate);
                            _this.networkLinkTableParams = ngTableParams.default(_this.listNetworkLink);
    
    
                            var _item;
                            if (_this.viewShown==_this.views.asset && _this.listAsset && _this.listAsset.length >0) {
                                _item = _this.listAsset[0];
                            } else if (_this.viewShown==_this.views.estate && _this.listEstate && _this.listEstate.length >0) {
                                _item = _this.listEstate[0];
                            } else if (_this.viewShown==_this.views.estate && _this.listNetworkLink && _this.listNetworkLink.length >0) {
                                _item = _this.listNetworkLink[0];
                            }
    
                            _this.setAssetSelected(_item)
    
                            _this.listAssetCleaned = _cleanListAssetForTableSearch(_this.listAsset);
                            _this.listEstateCleaned = _cleanListEstateForTableSearch(_this.listEstate);
                            _this.listNetworkLinkCleaned = _cleanListNetworkLinkForTableSearch(_this.listNetworkLink);
    
    
                            //check if the asset view shown is that used to show the asset-estate list
                            if (_this.viewShown == _this.views.asset) {
                                _getDatasetLatestUploaded(_customerId, _identity.id)
                                // assetDataServices.getAssetDataLatestUploadedByCustomerId(_customerId, _identity.id, _res => {
                                //     _this.listAsset.forEach(e => {
                                //         var _found = false;
                                //         _res.find(ds => {
                                //             if (e.id == ds.facilityId) {
                                //                 e.latestUpdate = ds.createdAt;
                                //                 _found = true;
                                //                 return true;
                                //             }
                                //         })
                                //         if (!_found) e.latestUpdate = -2208992400000; //it's equal to 01/01/1900
                                //     })
    
                                //     _this.assetTableParams = ngTableParams.withConfig({sorting: { latestUpdate: 'desc' },}, _this.listAsset);    
                                // })
    
                                //compute the asset status values
                                var _assetStatusComputed = _computeAssetStatusValues(_this.listAsset, _assetStatus);
    
                                //Use the asset status computed before, to show them into a chart
                                _this.setAssetStatusChart(_assetStatusComputed);
                                //check if the asset view shown is that used to show the the google map with the asset-estate on it
                            // } else if (_this.viewShown == _this.views.asset) {
                                //Remove, if there are, the markers on the map
                                _deleteMarkersOnMap(_listAssetMarker);
    
                                //Get the map object, previously created, from the _maps array
                                var _map = _maps[utility.getIndexArrayElementByField(_maps, 'id', '#' + _this.assetCanvasId)].map;
                                //Create the markers based on asset on the map
                                _listAssetMarker = _putAssetMarkersOnMap(_map, _this.listAsset);
    
                                _highlightAssetMarker(_this.assetSelected, true);
                            } else if (_this.viewShown == _this.views.estate) {
                                //compute the asset status values
                                var _assetStatusComputed = _computeAssetStatusValues(_this.listEstate, _assetStatus);
    
                                //Use the asset status computed before, to show them into a chart
                                _this.setAssetStatusChart(_assetStatusComputed);
                            // } else if (_this.viewShown == _this.views.estate) {
                                //Remove, if there are, the markers on the map
                                _deleteMarkersOnMap(_listEstateMarker);
    
                                //Get the map object, previously created, from the _maps array
                                var _map = _maps[utility.getIndexArrayElementByField(_maps, 'id', '#' + _this.estateCanvasId)].map;
                                //Create the markers based on asset on the map
                                _listEstateMarker = _putEstateMarkersOnMap(_map, _this.listEstate);
                            } else if (_this.viewShown == _this.views.networkLink) {
                                //compute the asset status values
                                var _assetStatusComputed = _computeAssetStatusValues(_this.listNetworkLink, _assetStatus);
    
                                //Use the asset status computed before, to show them into a chart
                                _this.setAssetStatusChart(_assetStatusComputed);
                            // } else if (_this.viewShown == _this.views.networkLink) {
                                //Remove, if there are, the markers on the map
                                _deleteMarkersOnMap(_listNetworkLinkMarker);
    
                                //Get the map object, previously created, from the _maps array
                                var _map = _maps[utility.getIndexArrayElementByField(_maps, 'id', '#' + _this.networkLinkCanvasId)].map;
                                //Create the markers based on asset on the map
                                _putNetworkLinkMarkersOnMap(_map, _this.listNetworkLink, _this.listAsset, _res => {
                                    _listNetworkLinkMarker = _res;
                                });
                            }
    
                            _success?_success():null;
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        })
                    } else if (_this.viewShown==_this.views.project) {        
                        _this.showProjectList();
                    }
                } else {
                    myAlert("Error: No customer was requested", 'danger');
                    myThrow(_controllerName + ": _customerId parameter undefined or null");
                }
            }

            //Example of ngTable subrow
            //Use it to show the assets list belong to an estate
            // _this.show = function (_asset) {
            //     _asset.showHistory = !_asset.showHistory;
            // }


            //return an array like _assetStatus with
            //asset status percentage and count computed
            //using the asset list  and _assetStatus object
            //_assetStatus object won't be modified
            function _computeAssetStatusValues(_listAsset, _assetStatus) {
                return assetServices.computeAssetStatusValues(_listAsset, _assetStatus);
            }


            //This function create a Chart object and associated it
            //to the _assetStatusChart element into html
            _this.setAssetStatusChart = function (_assetStatus) {
                //get the element with id _assetStatusChart from html
                var _ctx = document.getElementById(_idAssetStatusChart);
                var _labels = [];
                var _data = [];
                var _backgroundColor = [];

                //from _assetStatus parameter
                //extracts _data, _labels and _backgroundColor
                //for the chart
                _assetStatus.forEach(e => {
                    _data.push(e.percentage);
                    _labels.push(e.name);
                    _backgroundColor.push(e.chart['bg-color']);
                })

                //call the buildChart service to create the chart shown into html
                buildChart.default(_ctx, 'doughnut', _data, _labels, _backgroundColor);
            }



            function _buildAssetStatus(_assetStatusTypes, _listCssExtracted) {
                return assetServices.buildAssetStatusArray(_assetStatusTypes, _listCssExtracted);
            }

            //this function create and show a google map into _canvasId element
            //(_canvasId is a html element id reference where the google map will be shown )
            //and create a marker for each asset into _listAsset
            _this.createMapAndShowAssets = function (_listAsset, _canvasId) {
                //set the view, about asset, has shown
                _this.viewShown = _this.views.asset;

                //delete from _maps array, if exist, the previous google map object
                var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId)
                if (_index != undefined) {
                    _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);
                }

                var _map;

                googlemaps.initGoogleMap(_canvasId, _m => {
                    //push the just created google map into _maps array
                    _maps.push({ id: _canvasId, map: _m });
                    _map = _m;

                    //Create the markers about the asset list and manatain
                    //the marker array returned from the function
                    _listAssetMarker = _putAssetMarkersOnMap(_map, _this.listAsset);

                });

                console.log("asset selected.....",_this.assetSelected)
            }


            _this.createMapAndShowEstates = function (_listEstate, _canvasId) {
                //set the view, about asset, has shown
                _this.viewShown = _this.views.estate;

                //delete from _maps array, if exist, the previous google map object
                var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId)
                if (_index != undefined) {
                    _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);
                }

                var _map;

                googlemaps.initGoogleMap(_canvasId, _m => {
                    //push the just created google map into _maps array
                    _maps.push({ id: _canvasId, map: _m });
                    _map = _m;

                    //Create the markers about the asset list and manatain
                    //the marker array returned from the function
                    _listEstateMarker = _putEstateMarkersOnMap(_map, _this.listEstate);
                });
            }

            function _putEstateMarkersOnMap(_map, _listEstate) {
                var _listMarker = [];

                if (_listEstate && _listEstate.length > 0) {
                    _listEstate.forEach((e, _index) => {
                        if (!e.listAreaCoordination) {
                            assetServices.getEstateAreaCoordinate(e.id, _res => {
                                var _listCoordinate = [];
                                _res.forEach(c => {
                                    _listCoordinate.push({
                                        lat: c.latitude,
                                        lng: c.longitude
                                    })
                                })

                                _listEstate[_index].listAreaCoordinate = _listCoordinate;

                                if (_listCoordinate.length > 0) {
                                    //Create a marker based on an asset
                                    //and put the object returned, from the function, into a list of marker
                                    _listMarker.push(_createEstateMarker(_map, e.latitude, e.longitude, e));

                                    //Set out the map zoom based on the markers position
                                    googlemaps.fitBoundPolylines(_map, _listMarker);
                                }
                            })
                        }

                    })



                }

                return _listMarker;
            }

            function _createEstateMarker(_map, _lat, _lng, _estate, _icon) {

                var _estateArea = new google.maps.Polygon({
                    paths: _estate.listAreaCoordinate,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35
                });

                _estateArea.setMap(_map);

                return _estateArea;
            }


            _this.createMapAndShowNetworkLinks = function (_listNetworkLink, _listAsset, _canvasId) {
                //set the view, about asset, has shown
                _this.viewShown = _this.views.networkLink;

                //delete from _maps array, if exist, the previous google map object
                var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId)
                if (_index != undefined) {
                    _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);
                }

                var _map;

                googlemaps.initGoogleMap(_canvasId, _m => {
                    //push the just created google map into _maps array
                    _maps.push({ id: _canvasId, map: _m });
                    _map = _m;

                    //Create the markers about the asset list and manatain
                    //the marker array returned from the function
                    _putNetworkLinkMarkersOnMap(_map, _listNetworkLink, _listAsset, _res => {
                        _listNetworkLinkMarker = _res;
                    });
                });
            }

            function _putNetworkLinkMarkersOnMap(_map, _listNetworkLink, _listAsset, _success) {
                var _listMarker = [];
                var _listNetworkLinkElaborated = [];

                if (_listNetworkLink && _listNetworkLink.length > 0) {
                    var _listAssetById = utility.getArrayByField(_listAsset, 'id');

                    _listNetworkLink.forEach((e, _index) => {
                        // if (!e.listLineCoordination) {
                            assetServices.getNetworkLink(e.id, _res => {
                                _listNetworkLinkElaborated.push(_listNetworkLink[_index]);                                
                                var _listCoordinate = [];

                                _res.forEach(c => {
                                    var _asset = _listAssetById[c.facilityId];

                                    _listCoordinate.push({
                                        lat: _asset.latitude,
                                        lng: _asset.longitude,
                                        asset: _asset
                                    })
                                })

                                _listNetworkLink[_index].listLineCoordination = _listCoordinate;

                                if (_listCoordinate.length > 0) {
                                    //Create a marker based on an asset
                                    //and put the object returned, from the function, into a list of marker
                                    _listMarker.push(_createNetworkLinkMarker(_map, e.latitude, e.longitude, e));

                                    _listCoordinate.forEach(c => {
                                        var _marker = googlemaps.createAssetMarker(_map, c.lat, c.lng, c.asset);
                                        _marker.type="marker"
                                        _listMarker.push(_marker)
                                        _setAssetMarkerIcon(_marker, c.asset)

                                    })
                                    //Set out the map zoom based on the markers position
                                    googlemaps.fitBoundPolylines(_map, _listMarker.filter(e => e.type=='link'));
                                }

                                if (_listNetworkLink.length == _listNetworkLinkElaborated.length) {
                                    _success(_listMarker);
                                }
                            }, _err => {})
                        // }
                    })
                }

                return _listMarker;
            }


            function _createNetworkLinkMarker(_map, _lat, _lng, _networkLink, _icon) {
                var _color;

                switch (_networkLink.status) {
                    case 'good_condition':
                        _color = '#59B031';
                        break;
                    case 'maintenance_schedule':
                        _color = '#FFD600';
                        break;
                    case 'low_schedule':
                        _color = '#F18A16';
                        break;
                    case 'urgent_attention':
                        _color = '#FF0000';
                        break;
                    case 'unknown':
                        _color = '#7E8C8D';
                        break;
                }


                var _networkLinkLine = new google.maps.Polyline({
                    path: _networkLink.listLineCoordination,
                    strokeColor: _color,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: _color,
                    fillOpacity: 0.35,
                    type:"link",
                    map: _map
                });

                // _networkLinkLine.setMap(_map);

                return _networkLinkLine;
            }


            //Create a google marker for each asset into _listAsset
            //and put the marker into _map
            //The map zoom is set out based on the markers position
            //Return a list of marker
            function _putAssetMarkersOnMap(_map, _listAsset) {
                var _markers =  googlemaps.putAssetMarkersOnMap(_map, _listAsset);

                _markers.forEach(e => {
                    _addAssetStatusTypeToMarker(e, e.asset);
                    _addAssetTypeToMarker(e, e.asset);
                    _setAssetMarkerIcon(e, e.asset);
                })
        
                return _markers;
            }

            function _addAssetStatusTypeToMarker(_marker, _asset) {
                _marker.facilityStatusType = _this.listAssetStatusTypeByKey[_asset.status];
            }
        
            function _addAssetTypeToMarker(_marker, _asset) {
                _marker.facilityType = _this.listAssetTypeKey[_asset.facilityTypeId];
            }
        
            function _setAssetMarkerIcon(_marker, _asset) {
                if (_asset.facilityTypeId) {
                    var _ret = 'marker-' + _this.listAssetTypeKey[_asset.facilityTypeId].key + "_" + (_asset.status?_asset.status:'unknown') + '.png';

                    _marker.setIcon('/img/icons/markers/' + _ret);
                } else {
                    return null;
                }
            }


            //remove all the markers into _listMarker
            //from a google map
            function _deleteMarkersOnMap(_listMarker) {
                _listMarker.forEach(e => {
                    e.setMap(null);
                });
            }

            _this.getStructureTypeByKey = function() {
                return function (_key) {
                    return _this.listStructureTypeByKey[_key];
                }
            }

            _this.getCustomerById = function() {
                return function (_id) {
                    return _this.listCustomerById[_id];
                }
            }

            _this.getStructureManufacturerTypesByFacilityTypeId = function() {
                var _prevStructureManufacturerTypesByFacilityTypeId = undefined;
                var _listStructureManufacturerTypeFiltered = undefined;

                return function (_facilityTypeId) {
                    if (_facilityTypeId && _prevStructureManufacturerTypesByFacilityTypeId != _facilityTypeId) {
                        _prevStructureManufacturerTypesByFacilityTypeId = _facilityTypeId;

                        var _facilityTypeKey = utility.getArrayElementByField(_this.listAssetType, 'id', _facilityTypeId).key;

                        _listStructureManufacturerTypeFiltered = _this.listStructureManufacturerType.filter(e => e.facilityTypeKey==_facilityTypeKey);
                        return _listStructureManufacturerTypeFiltered;
                    } else {
                        return _listStructureManufacturerTypeFiltered;
                    }
                }
            }

            _this.getStructureManufacturerTypesByKey = function() {
                return function (_key) {
                    return _this.listStructureManufacturerTypeByKey[_key];
                }
            }

            _this.getStructureById = function() {
                return function (_listStructure, _id) {
                    return utility.getArrayElementByField(_listStructure, 'id', _id);
                }
            }


            //Set up and open the customer popup
            //usefull to edit/add a customer
            function _editAssetPopup(_assetData) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == 'asset'" },
                    { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true, 'btn_validation': 'validationAssetFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveAssetFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'asset'" },
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == 'structure'" },
                    { 'btn_identifier': '_2_2', 'btn_close': false, 'btn_confirm': false, 'btn_msg': 'Add Structure', 'btn_color': 'btn-light', 'btn_fn': 'addAssetStructureFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'structure'" },
                    { 'btn_identifier': '_2_3', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationAssetStructureFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveAssetStructureFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'structure'" },
                    { 'btn_identifier': '_3_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    { 'btn_identifier': '_3_2', 'btn_close': false, 'btn_confirm': false, 'btn_msg': 'Add 3D Model', 'btn_color': 'btn-light', 'btn_fn': 'addAsset3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    { 'btn_identifier': '_3_3', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationAsset3dModelFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveAsset3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                ];

                // if ($scope.isFeatureEnabled($scope.listFeatureKey.autoInventory)) {
                //     _fns.push({ 'btn_identifier': 'addInventoryPopup', 'btn_close': false, 'btn_msg': 'Bulk Upload Asset Inventory', 'btn_color': 'btn-light', 'btn_fn': 'assetListInventoryFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'inventory'" })
                // }

                // _this.assetStructureTableParamsRef =  _this.getAssetStructureTableParams();
                var _config = {
                    'funzione': {
                        initAssetPopupData: _this.initAssetPopupData(),
                        btnCreateMapForAssetLocation: _this.createMapForAssetLocation(),
                        btnCreateMapForForensicPoint: _this.createMapForForensicPoint(),
                        btnMapAddForForensicPoint: _this.MapAddForForensicPoint(),
                        btnMapDeleteForensicPoint: _this.MapDeleteForensicPoint(),
                        getAsset3dModelTableParams: _this.getAsset3dModelTableParams(),
                        editAsset3dModel: _this.editAsset3dModel(),
                        FplistSelectedAsset: _this.FplistSelectedAsset(),
                        RemoveAllFPAsset: _this.RemoveAllFPAsset(),
                        uploader: _this.uploader,
                        uploader_fp: _this.uploader_fp,
                        getAssetStructureTableParams: _this.getAssetStructureTableParams(),
                        getForensicPointTableParams: _this.getForensicPointTableParams(),
                        editAssetStructure: _this.editAssetStructure(),
                        getStructureTypeByKey: _this.getStructureTypeByKey(),
                        isFeatureEnabled: _this.isFeatureEnabled(),
                        getCustomerById: _this.getCustomerById(),
                        getStructureManufacturerTypesByFacilityTypeId: _this.getStructureManufacturerTypesByFacilityTypeId(),
                        getStructureManufacturerTypesByKey: _this.getStructureManufacturerTypesByKey(),
                        getStructureById: _this.getStructureById(),
                    },
                    'size': 'xlg',
                    'title': _assetData.asset.name ? _assetData.asset.name : 'New Asset',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_assetData),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/asset_popup.html',
                    'innerStatus': {
                        'asset_section': 'asset',
                        'map-location': false
                    }
                }
                popup.openModalBody(_config);
            }


            function _editEstatePopup(_estateData) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == 'estate'" },
                    { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true, 'btn_validation': 'validationEstateFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveEstateFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'estate'" },
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == 'assets'" },
                    { 'btn_identifier': '_2_2', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationEstateFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveEstateFromPopup', 'btn_visible_if': "$innerStatus.asset_section == 'assets'" },
                    { 'btn_identifier': '_3_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    { 'btn_identifier': '_3_2', 'btn_close': false, 'btn_confirm': false, 'btn_msg': 'Add 3D Model', 'btn_color': 'btn-light', 'btn_fn': 'addEstate3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    { 'btn_identifier': '_3_3', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationEstate3dModelFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveEstate3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" }

                ];

                var _config = {
                    'funzione': {
                        btnCreateMapForEstateAreaLocation: _this.btnCreateMapForEstateAreaLocation(),
                        getAsset3dModelTableParams: _this.getAsset3dModelTableParams(),
                        editAsset3dModel: _this.editAsset3dModel(),
                        FplistSelectedEstate: _this.FplistSelectedEstate(),
                        RemoveAllFPEstate: _this.RemoveAllFPEstate(),
                        uploader: _this.uploader,
                        uploader_fp: _this.uploader_fp,
                        initEstatePopupData: _this.initEstatePopupData(),
                        addAssetLinkedToEstate: _this.addAssetLinkedToEstate(),
                        removeAssetLinkedToEstate: _this.removeAssetLinkedToEstate(),
                        getForensicPointTableParams: _this.getForensicPointTableParams(),
                        isFeatureEnabled: _this.isFeatureEnabled(),

                    },
                    'size': 'xlg',
                    'title': _estateData.estate.name ? _estateData.estate.name : 'New Estate',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_estateData),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/estate_popup.html',
                    'innerStatus': {
                        'asset_section': 'estate',
                        'map-location': false
                    }
                }
                popup.openModalBody(_config);
            }


            function _editNetworkLinkPopup(_networkLinkData) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.networkLink_section == 'networkLink'" },
                    { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true, 'btn_validation': 'validationNetworkLinkFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveNetworkLinkFromPopup', 'btn_visible_if': "$innerStatus.networkLink_section == 'networkLink'" },
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.networkLink_section == 'assets'" },
                    { 'btn_identifier': '_2_2', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationNetworkLinkFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveNetworkLinkFromPopup', 'btn_visible_if': "$innerStatus.networkLink_section == 'assets'" },
                ];

                var _config = {
                    'funzione': {
                        initNetworkLinkPopupData: _this.initNetworkLinkPopupData(),
                        addAssetLinkedToNetworkLink: _this.addAssetLinkedToNetworkLink(),
                        removeAssetLinkedToNetworkLink: _this.removeAssetLinkedToNetworkLink(),
                        isFeatureEnabled: _this.isFeatureEnabled(),

                    },
                    'size': 'xlg',
                    'title': _networkLinkData.networkLink.name ? _networkLinkData.networkLink.name : 'New Network Link',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_networkLinkData),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/networkLink_popup.html',
                    'innerStatus': {
                        'networkLink_section': 'networkLink',
                    }
                }
                popup.openModalBody(_config);
            }



            //*********************************************************************************************************************************** */
            //------------------------------- BULK CUSTOMER UPLOAD -------------------------------------
            //*********************************************************************************************************************************** */


            $scope.listNewAssetCreated = []
            _this.uploadDataPopup = function(customerId) {

                $scope.customerEdit = customerId;
                $scope.listNewAssetCreated = []
                var _uploadData = {
                    uploadData: {description:''},
                    listStructure: _listAssetStructure,
                };

                var _fns = [
                    { 'btn_identifier':'_1_1', 'btn_close': false, 'btn_msg': 'Remove All Files', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_section == 'upload'", 'btn_fn': 'removeAllSelectedFile' },
                    { 'btn_identifier':'_1_2', 'btn_close': false, 'btn_msg': 'Next', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'upload'", 'btn_fn': 'uploadAssetNextFromPopup' },

                    { 'btn_identifier':'_2_1', 'btn_close': false, 'btn_msg': 'Back', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'uploadAssetBackFromPopup'  },
                    { 'btn_identifier':'_2_2', 'btn_close': false, 'btn_msg': 'Run Upload & Analysis', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'creationFacilityRecord' },                   
                    { 'btn_identifier':'_2_2', 'btn_close': true, 'btn_msg': 'Close', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'concluded'"}
                ];

                var _config = {
                    funzione: {
                        initUploadDataPopupData: _this.initUploadDataPopupData(),
                        getIconNameByFileUploadType: _this.getIconNameByFileUploadType(),
                    },
                    size: 'lg',
                    title: 'Upload New Data',
                    ctrl: _controllerName,
                    data: JSON.stringify(_uploadData),
                    fns: JSON.stringify(_fns),
                    htmlPage: 'views/asset/popup/asset_upload_popup.html',
                    innerStatus: {
                        asset_section:'upload',
                        isUploadCompleted: false,
                        isUploading: false,
                        isUploadConfirmed: false,
                    }
                }
                popup.openModalBody(_config);       
            }
    
            _this.getIconNameByFileUploadType = function() {
                return function(_popup, _name) {
                    var _extention = _name.split('.').pop().toLowerCase();
                    return _this.listAssetDataFileTypeByKey[_popup.data.listFileTypeByKey[_extention].value].imgName;
                } 
            } 

            _this.initUploadDataPopupData = function () {
                return function (_popup) {

                    assetServices.getFileUploadTypes(_res => {
                        _popup.data.listFileTypeByKey = utility.getArrayByField(_res, 'key');
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);        
                    })
                }
            }

            $scope.uploadAssetNextFromPopup = function(_popup) {
                switch (_popup.innerStatus.asset_section) {
                    case 'upload':
                        _popup.innerStatus.asset_section = 'results';
                        break;
                }
            }

            $scope.uploadAssetBackFromPopup = function(_popup) {
                switch (_popup.innerStatus.asset_section) {
                    case 'results':
                        _popup.innerStatus.asset_section = 'upload';
                        break;
                }
            }

            function makeid(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
            
            $scope.removeAllSelectedFile = function(_popup){
                _popup.data.uploaderFlow.files = [];
                
            }


            $scope.creationFacilityRecord = function(_popup) {
                
                //create the facility data required and associate them to each data       
                _popup.file_list = [];

                for (var i = 0; i < _popup.data.uploaderFlow.files.length; i++) {
                    var string = makeid(5);    
                    string = string.concat('_prefix_', _popup.data.uploaderFlow.files[i].name);
    
                    _popup.file_list[i] = [string,  _popup.data.uploaderFlow.files[i].relativePath];
                    _popup.data.uploaderFlow.files[i].name = string;
                }
    
                // parameter to be sent to the backend for the creation of the facility data and facility data details
                var Data_t = {    

                    list_files: _popup.file_list,
                    Customer: 'customer_' + $scope.customerEdit,
                    Comment: 'experiment',

                    Inspection: 1
                }

                assetDataUploadServices.prepareUpload(Data_t, _popup.data.uploaderFlow, _res => {
                    
                    if (_res[0] == 'list'){
                        //return the list of file to be uploaded
                        _popup.listUploadedFile = _res[1];
                    }

                    if  (_res[0] == 'file'){
                        //Find index of specific object using findIndex method.    
                        var objIndex = _popup.listUploadedFile.findIndex((obj => obj.name == _res[1]));

                        //Update object's name property.
                        _popup.listUploadedFile[objIndex].upload_status = true;
                        
                        //check amount of file to be uploaded
                        var countFalse = _popup.listUploadedFile.filter(x => x.upload_status === false).length;
                        if (countFalse == 0){
                            //all file uploaded -> post processing can start
                            _popup.innerStatus.asset_section = 'concluded';
                            // _popup.data.uploadData.description
                            assetDataUploadServices.postProcessingUpload(_popup.listUploadedFile, _popup.data.uploadData.email,  _identity.companyId);
                            
                        }
                    }                                   
    
                }, _err => {
                    _error ? _error(_err) : null;
                    myAlert("Error: " + _err.data.message, 'danger');
                });
            }



            //*********************************************************************************************************************************** */
            //------------------------------------END BULK UPLOAD -------------------------------------------
            //*********************************************************************************************************************************** */



            _this.editAsset3dModel = function () {
                return function (_model3d, _popup) {
                    _popup.data.asset3dModelSelected = angular.copy(_model3d)

                    _this.uploader.setMethods('3dModel', _popup.data.dzMethods);
                }
            }

            _this.FplistSelectedAsset = function () {
                return function (_popup) {
                    _this.uploader_fp.setMethods('forensicPoint', _popup.data.dzMethods_fp);
                    
                    _this.uploader_fp.setDataToSendBackend('forensicPoint',{
                        name: 'forensic_point_list.csv',
                        description: 'forrensic point list',
                        companyId: _popup.data.asset.companyId,
                        userId: _identity.id,
                        assetId: _popup.data.asset.id
                    })
                }
            }

            _this.RemoveAllFPAsset = function () {
                return function (_popup) {
                    assetServices.removeAssetForensicPoint(_popup.data.asset.id, _res => {
                        myLog("Removed Forensic Point list:", _res);
                        var new_fp = []
                        _popup.data.listAssetForensicPoint.forEach(e => {  
                            if (_res.indexOf(e.id) >= 0){
                                new_fp.push(e)
                            }
                        })
                        _popup.data.listAssetForensicPoint = new_fp;
                        myAlert("Forensic Points Removed", 'info');

                    }, _err => {

                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    });
                }
            }

            _this.FplistSelectedEstate = function () {
                return function (_popup) {
                    _this.uploader_fp.setMethods('forensicPoint',_popup.data.dzMethods_fp);
                    
                    _this.uploader_fp.setDataToSendBackend('forensicPoint',{
                        name: 'forensic_point_list.csv',
                        description: 'forrensic point list',
                        companyId: _popup.data.estate.companyId,
                        userId: _identity.id,
                        assetId: _popup.data.estate.id
                    })
                }
            }

            _this.RemoveAllFPEstate = function () {
                return function (_popup) {
                    assetServices.removeAssetForensicPoint(_popup.data.estate.id, _res => {
                        myLog("Removed Forensic Point list:", _res);
                        var new_fp = []
                        _popup.data.listAssetForensicPoint.forEach(e => {  
                            if (_res.indexOf(e.id) >= 0){
                                new_fp.push(e)
                            }
                        })
                        _popup.data.listAssetForensicPoint = new_fp;
                        myAlert("Forensic Points Removed", 'info');

                    }, _err => {

                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    });
                }
            }



            _this.assetListAdd = function (customerIdSelected) {

                _this.uploader_al.setMethods('assetList', _this.data.dzMethods_al);

                _this.uploader_al.setDataToSendBackend('assetList', {
                    name: 'asset_list.csv',
                    description: 'Asset list',
                    companyId: customerIdSelected,
                    userId: _identity.id,
                    assetId:' data.asset.id',
                    autoProcessQueue: true
                })   
            }



            _this.editAssetStructure = function () {
                return function (_assetStructure, _popup) {
                    _popup.data.assetStructureSelected = angular.copy(_assetStructure)

                    utility.callFunctionEveryTime('!$("#_structure_fields").hasClass("ng-hide")', 200, function() {
                        $("#_asset_panel").scrollTop( $("#_structure_fields").offset().top );   
                        $("#_structure_name").focus();               
        
                    });    
                }
            }

            _this.getAsset3dModelTableParams = function () {
                var _ngTableParams;
                return {
                    init: function () {
                        //I'm going to show in the table only the 3D Model and not the 3D Photogrammetry
                        _ngTableParams = ngTableParams.default(_this.listAsset3dModel.filter(e => !e.photogrammetryType));
                        return _ngTableParams;
                    },
                    getData: function () {
                        return _ngTableParams;
                    }
                }
            }


            _this.getAssetStructureTableParams = function () {
                var _ngTableParams;
                return {
                    init: function () {
                        _ngTableParams = ngTableParams.default(_this.listAssetStructure);
                        return _ngTableParams;
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            }

            _this.getForensicPointTableParams = function () {
                var _ngTableParams;
                return {
                    init: function (_type, _assetId) {
                        // if (appFactory.listFeatureKey) {
                            if (_assetId && 
                                ((_type="asset" && $scope.isFeatureEnabled($scope.listFeatureKey.forensicPointAsset)) ||
                                (_type="estate" && $scope.isFeatureEnabled($scope.listFeatureKey.forensicPointEstate)))) {
                                assetServices.getAssetForensicPoint(_assetId, _res => {
                                    _ngTableParams = ngTableParams.default(_res);
            
                                    myLog("Assets Forensic Point list:", _res);
                                    return _ngTableParams;
                                }, _err => {

                                    myAlert("Error: " + _err.data.message, 'danger');
                                    myThrow(_err);
                                    return null;
                                });
                            } else {
                                return null;
                            }                            
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                            return _ngTableParams.reload();
                    }
                }
            }

            _this.initAssetPopupData = function () { 
                return function (_popup) {
                    var _asset = _popup.data.asset;                    
                    
                    if (_asset.id) {
                        _getAsset3dModelsByAssetType(_asset.companyId, _asset.facilityTypeId, _res => {
                            _this.listAsset3dModel = _res;
                            _popup.data.listAsset3dModel = _this.listAsset3dModel;
                            _popup.updateData(_popup.data);
                            myLog('listAsset3dModel', _this.listAsset3dModel)


                            _getAsset3dPhotogrammetryByAssetId(_asset.id,  _res1 => {
                                //_res containt listFacilityDataDto
                                //I'm going to add new element into listAsset3dModel
                                //from listFacilityDataDto
    
                                var _item;
                                _res1.forEach(e => {
                                    _item = {
                                        fileStorageItemId: e.fileStorageItemId,
                                        photogrammetryType: e.saveType
                                    }
    
                                    if (e.saveType == 'generated') {
                                        _item.name =  e.description?e.description:"3D Photogrammetry generated on " + utility.formatDate(e.createdAt, 'dd/mm/yyyy hh:mm');
                                    } else {
                                        _item.name =  e.description?e.description:"3D Photogrammetry uploaded on " + utility.formatDate(e.createdAt, 'dd/mm/yyyy hh:mm');
                                    }
    
                                    _this.listAsset3dModel.push(_item)
                                })
    
                                _popup.data.listAsset3dModel = _this.listAsset3dModel;    
                                _popup.updateData(_popup.data);    
                                myLog('listAsset3dPhotogrammetry', _res1)
                            });    
                        });

                        assetServices.getAssetForensicPoint(_asset.id, _res => {
                            _this.listAssetForensicPoint = _res;
                            _popup.data.listAssetForensicPoint = _this.listAssetForensicPoint;

                            _popup.updateData(_popup.data);
                            myLog("Assets Forensic Point list:", _res);
                        }, _err => {

                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });

                        assetServices.getAssetStructures(_asset.id, _res => {
                            _this.listAssetStructure = _res;
                            _popup.data.listAssetStructure = _this.listAssetStructure;

                            _popup.updateData(_popup.data);

                            myLog("Assets structures list:", _res);
                        }, _err => {

                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });

                        customerServices.getAllCustomers(_identity.companyId, _identity.id, _res => {
                            _popup.data.listCustomer = _res;
                            _this.listCustomerById = utility.getArrayByField(_res, 'id');

                            _popup.updateData(_popup.data);

                            myLog("Customers' list:", _res);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });

                        structureServices.getStructureManufacturerTypes( _res => {
                            _popup.data.listStructureManufacturerType = undefined;
                            _this.listStructureManufacturerType = _res;
                            _this.listStructureManufacturerTypeByKey = utility.getArrayByField(_res, 'key');

                            _this.prevStructureManufacturerTypesByFacilityTypeId = undefined;

                            _popup.updateData(_popup.data);

                            myLog("Structure Manufacturer types list:", _res);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });
                    }
                }
            }



            _this.initEstatePopupData = function () {
                return function (_popup) {
                    var _estate = _popup.data.estate;

                    if (_estate.id) {
                        _getAsset3dModelsByAssetType(_estate.companyId, _estate.facilityTypeId, _res => {
                            _this.listAsset3dModel = _res;
                            _popup.data.listAsset3dModel = _this.listAsset3dModel;
                            _popup.updateData(_popup.data);
                            myLog('listAsset3dModel', _this.listAsset3dModel)

                            _getAsset3dPhotogrammetryByAssetId(_estate.id,  _res1 => {
                                //_res containt listFacilityDataDto
                                //I'm going to add new element into listAsset3dModel
                                //from listFacilityDataDto
    
                                var _item;
                                _res1.forEach(e => {
                                    _item = {
                                        fileStorageItemId: e.fileStorageItemId,
                                        photogrammetryType: e.saveType
                                    }
    
                                    if (e.saveType == 'generated') {
                                        _item.name =  e.description?e.description:"3D Photogrammetry generated on " + utility.formatDate(e.createdAt, 'dd/mm/yyyy hh:mm');
                                    } else {
                                        _item.name =  e.description?e.description:"3D Photogrammetry uploaded on " + utility.formatDate(e.createdAt, 'dd/mm/yyyy hh:mm');
                                    }
    
                                    _this.listAsset3dModel.push(_item)
                                })
    
                                _popup.data.listAsset3dModel = _this.listAsset3dModel;    
                                _popup.updateData(_popup.data);    
                                myLog('listAsset3dPhotogrammetry', _res1)
                            })

                        });

                        assetServices.getEstateLinkAssets(_estate.id, _res => {
                            var _listEstateLink = _res;

                            // var _listEstateAssets = _listEstateLink.map(e => {
                            //     return utility.getArrayElementByField(_this.listAsset, 'id', e.facilityId);                                
                            // })
                            var _listEstateAssets = _this.listAsset.filter(a => {
                                return (utility.getArrayElementByField(_listEstateLink, 'facilityId', a.id) != undefined);
                            })

                            _popup.data.listEstateLink = _listEstateLink;
                            _popup.data.listEstateAssets = _listEstateAssets;
                            _popup.data.listAssetAvailable = _this.listAsset.filter(e => {
                                return (utility.getIndexArrayElementByField(_listEstateAssets, 'id', e.id) == undefined);
                            })

                            _popup.updateData(_popup.data)

                            myLog("Estate's assets list:", _res);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        })

                        assetServices.getAssetForensicPoint(_estate.id, _res => {
                            _this.listAssetForensicPoint = _res;
                            _popup.data.listAssetForensicPoint = _this.listAssetForensicPoint;

                            _popup.updateData(_popup.data);

                            myLog("Assets Forensic Point list:", _res);
                        }, _err => {

                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });

                        assetServices.getEstateAreaCoordinate(_estate.id, _res => {
                            _this.listEstateAreaCoordinate = _res;

                            _popup.data.listEstateAreaCoordinate = _this.listEstateAreaCoordinate;

                            _popup.updateData(_popup.data)

                            myLog("Estate's assets list:", _res);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        })

                    } else {
                        _popup.data.listEstateLink = [];
                        _popup.data.listEstateAssets = [];
                        _popup.data.listAssetAvailable = angular.copy(_this.listAsset);

                        _popup.updateData(_popup.data)
                    }
                }
            }

            _this.initNetworkLinkPopupData = function () {
                return function (_popup) {
                    var _networkLink = _popup.data.networkLink;


                    assetServices.getNetworkLinkInds( _res => {
                        _popup.data.listNetworkLinkInd = _res;
                        _popup.updateData(_popup.data)
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })

                    assetServices.getNetworkLinkTypes( _res => {
                        _popup.data.listNetworkLinkType = _res;
                        _popup.updateData(_popup.data)
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })

                    if (_networkLink.id) {
                        assetServices.getNetworkLink(_networkLink.id, _res => {
                            var _listNetworkLink = _res;

                            // var _listEstateAssets = _listEstateLink.map(e => {
                            //     return utility.getArrayElementByField(_this.listAsset, 'id', e.facilityId);                                
                            // })
                            var _listNetworkLinkAssets = _this.listAsset.filter(a => {
                                return (utility.getArrayElementByField(_listNetworkLink, 'facilityId', a.id) != undefined);
                            })

                            _popup.data.listNetworkLink = _listNetworkLink;
                            _popup.data.listNetworkLinkAssets = _listNetworkLinkAssets;
                            _popup.data.listAssetAvailable = _this.listAsset.filter(e => {
                                return (utility.getIndexArrayElementByField(_listNetworkLinkAssets, 'id', e.id) == undefined);
                            })

                            _popup.updateData(_popup.data)

                            myLog("Network Link's assets list:", _res);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        })
                    } else {
                        _popup.data.listNetworkLink = [];
                        _popup.data.listNetworkLinkAssets = [];
                        _popup.data.listAssetAvailable = angular.copy(_this.listAsset);

                        _popup.updateData(_popup.data)

                    }
                }
            }



            //This function is used to edit an existent asset or add a new one
            //If any asset with _assetId is found into listAsset,
            //a popup with all the fields blank will be shown,
            //otherwise a popup with the asset info will be shown
            _this.editAsset = function (_assetId, _companyId) {
                _this.uploader = singleUploader('3dModel', {
                    url: expanseConst.expanseRestUrl + '/awss3/facilityType',
                    clickable: '#selectAdd3dModel',
                    progressbarid: '#_myprogress',
                    autoProcessQueue: false
                });

                _this.uploader_fp = singleUploader('forensicPoint', {
                    url: 'api/addFpList',
                    clickable: '#selectFpLists',
                    progressbarid: '#_myprogress_fp',
                    autoProcessQueue: false
                });

                _this.uploader_inp = singleUploader('assetListInventoryPopup', {
                    url: 'api/addAsList',
                    clickable: '#addInventoryPopup',
                    autoProcessQueue: true
                });
    

                var _assetData = {
                    listAssetType: _this.listAssetType,
                    listSiteType: _this.listSiteType,
                    listStructureType: _this.listStructureType,
                    listAssetStatusType: _this.listAssetStatusType,
                    listAssetStatusProject: _this.listAssetStatusProject,
                    dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                    dropDownSettingsAssetStatusProject: $scope.dropDownSettingsAssetStatusProject,
                    dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                    dropDownEventsAssetStatusProject: $scope.dropDownEventsAssetStatusProject,
                    listAssetForensicPoint: _this.listAssetForensicPoint,
                    listFeatureKey: $scope.listFeatureKey,                
                    // dropDownSettingsInventoryItemExtraInfoLabel: $scope.dropDownSettingsInventoryItemExtraInfoLabel,
                    // listInventoryItemExtraInfoSelected: [],
                    inspectingCompanyId: _identity.companyId,
                }                

                if (_assetId) { //Edit an existent asset
                    // //get an asset, with id equal to _assetId, from listAsset
                    _asset = utility.getArrayElementByField(_this.listAsset, 'id', _assetId);

                    // var _assetData = {
                    //     asset: _asset,
                    //     listAssetType: _this.listAssetType,
                    //     listSiteType: _this.listSiteType,
                    //     listStructureType: _this.listStructureType,
                    //     listAssetStatusType: _this.listAssetStatusType,
                    //     dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                    //     dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                    //     assetStatusSelected: [{ key: _asset.status }],
                    //     listAssetForensicPoint: _this.listAssetForensicPoint,
                    //     assetKey: _this.listAssetType.find(element => element.id == _asset.facilityTypeId).key,
                    //     listFeatureKey: $scope.listFeatureKey,                
                    //     dropDownSettingsInventoryItemExtraInfoLabel: $scope.dropDownSettingsInventoryItemExtraInfoLabel,
                    //     listInventoryItemExtraInfoSelected: [],
                    //     inspectingCompanyId: _identity.companyId,
                    // }

                    _assetData.assetKey = _this.listAssetType.find(element => element.id == _asset.facilityTypeId).key
                    _assetData.assetStatusSelected = [{ key: _asset.status }]
                    _assetData.assetStatusProjectSelected = [{ key: _asset.statusProject }]
                    _assetData.asset = _asset
                    _assetData.assetKey= _this.listAssetType.find(element => element.id == _asset.facilityTypeId).key,

                    assetServices.getAsset(_asset.id, _res => {
                        _updateAssetAfterActions(_this.listAsset, _asset, _res);

                        _assetData.asset = _res;
                        _editAssetPopup(_assetData);
                    })
                } else { //Create a new asset
                    //create an empty asset
                    var _asset = {
                        companyId: _companyId,
                        latitude: 0.00001,
                        longitude: 0.00001
                    };

                    // var _assetData = {
                    //     asset: _asset,
                    //     listAssetType: _this.listAssetType,
                    //     listSiteType: _this.listSiteType,
                    //     listStructureType: _this.listStructureType,
                    //     listAssetStatusType: _this.listAssetStatusType,
                    //     dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                    //     dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                    //     assetStatusSelected: [{ key: _asset.status }],
                    //     listAssetForensicPoint: _this.listAssetForensicPoint,
                    //     assetType: _this.assetSelected,
                    //     listFeatureKey: $scope.listFeatureKey,
                    //     dropDownSettingsInventoryItemExtraInfoLabel: $scope.dropDownSettingsInventoryItemExtraInfoLabel,
                    //     listInventoryItemExtraInfoSelected: [],
                    //     inspectingCompanyId: _identity.companyId,
                    // }

                    _assetData.assetStatusSelected = [{ key: _asset.status }]
                    _assetData.assetStatusProjectSelected = [{ key: _asset.statusProject }]
                    _assetData.asset = _asset
                    _assetData.assetType= _this.assetSelected
                    _this.listAssetStructure = [];

                    _editAssetPopup(_assetData);
                }
            }

            _this.editEstate = function (_estateId, _companyId) {
                _this.estateArea = undefined;

                _this.uploader = singleUploader('3dModel',{
                    url: expanseConst.expanseRestUrl + '/awss3/facilityType',
                    clickable: '#selectAdd3dModel',
                    progressbarid: '#_myprogress'
                });

                _this.uploader_fp = singleUploader('forensicPoint', {
                    url: 'api/addFpList',
                    clickable: '#selectFpLists',
                    progressbarid: '#_myprogress_fp'
                });

                var _estateData = undefined;

                if (_estateId) { //Edit an existent asset
                    // //get an asset, with id equal to _assetId, from listAsset
                    var _estate = utility.getArrayElementByField(_this.listEstate, 'id', _estateId);

                    _estateData = {
                        estate: _estate,
                        listAssetType: _this.listAssetType,
                        listAssetStatusType: _this.listAssetStatusType,
                        listAssetStatusProject: _this.listAssetStatusProject,
                        dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                        dropDownSettingsAssetStatusProject: $scope.dropDownSettingsAssetStatusProject,
                        dropDownSettings: $scope.dropDownSettings,
                        dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                        dropDownEventsAssetStatusProject: $scope.dropDownEventsAssetStatusProject,
                        assetStatusSelected: [{ key: _estate.status }],
                        assetStatusProjectSelected : [{ key: _this.assetSelected.statusProject }],
                        listAssetAvailableSelected: [],
                        listFeatureKey: $scope.listFeatureKey
                    }
 
                    // _editEstatePopup(_estateData);

                } else { //Create a new asset
                    //create an empty asset
                    var _estate = {
                        companyId: _companyId,
                        latitude: 0.00001,
                        longitude: 0.00001,
                        facilityTypeId: 24
                    };

                    _estateData = {
                        estate: _estate,
                        listAssetType: _this.listAssetType,
                        listAssetStatusType: _this.listAssetStatusType,
                        listAssetStatusProject: _this.listAssetStatusProject,
                        dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                        dropDownSettingsAssetStatusProject: $scope.dropDownSettingsAssetStatusProject,
                        dropDownSettings: $scope.dropDownSettings,
                        dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                        dropDownEventsAssetStatusProject: $scope.dropDownEventsAssetStatusProject,
                        assetStatusSelected: [{ key: _estate.status }],
                        assetStatusProjectSelected : [{ key: _this.assetSelected.statusProject }],
                        listAssetAvailableSelected: [],
                        listFeatureKey: $scope.listFeatureKey
                    }


                    // _editEstatePopup(_assetData);
                }
                _editEstatePopup(_estateData);
            }


            _this.editNetworkLink = function (_networkLinkId, _companyId) {
                var _networkLinkData = undefined;

                if (_networkLinkId) { //Edit an existent link
                    // //get a link, with id equal to _linkId, from listLink
                    var _networkLink = utility.getArrayElementByField(_this.listNetworkLink, 'id', _networkLinkId);

                    _networkLinkData = {
                        networkLink: _networkLink,
                        listAssetType: _this.listAssetType,
                        listAssetStatusType: _this.listAssetStatusType,
                        dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                        dropDownSettingsAssetStatusProject: $scope.dropDownSettingsAssetStatusProject,
                        dropDownSettings: $scope.dropDownSettings,
                        dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                        dropDownEventsAssetStatusProject: $scope.dropDownEventsAssetStatusProject,
                        assetStatusSelected: [{ key: _networkLink.status }],
                        listAssetAvailableSelected: [],
                        listFeatureKey: $scope.listFeatureKey
                    }

                    // _editLinkPopup(_linkData);

                } else { //Create a new link

                    //create an empty link
                    var _networkLink = {
                        companyId: _companyId,
                        latitude: 0.00001,
                        longitude: 0.00001,
                        facilityTypeId: _this.listAssetTypeByKey['networkLink'].id
                    };

                    _networkLinkData = {
                        networkLink: _networkLink,
                        listAssetType: _this.listAssetType,
                        listAssetStatusType: _this.listAssetStatusType,
                        dropDownSettingsAssetStatus: $scope.dropDownSettingsAssetStatus,
                        dropDownSettingsAssetStatusProject: $scope.dropDownSettingsAssetStatusProject,
                        dropDownSettings: $scope.dropDownSettings,
                        dropDownEventsAssetStatus: $scope.dropDownEventsAssetStatus,
                        dropDownEventsAssetStatusProject: $scope.dropDownEventsAssetStatusProject,
                        assetStatusSelected: [{ key: _networkLink.status }],
                        listAssetAvailableSelected: [],
                        listFeatureKey: $scope.listFeatureKey
                    }

                    // _editLinkPopup(_assetData);
                }
                _editNetworkLinkPopup(_networkLinkData);
            }


            _this.addAssetLinkedToEstate = function() {
                return function(_popup) {
                    var _estate = _popup.data.estate;
                    var _listAssetAvailableSelected = _popup.data.listAssetAvailableSelected;
                    var _listAssetAvailable = _popup.data.listAssetAvailable;
                    var _listEstateAssets = _popup.data.listEstateAssets;
                    var _listEstateLink = _popup.data.listEstateLink;

                    if (_listAssetAvailableSelected) {
                        _listAssetAvailableSelected.forEach(e => {
                            _listEstateAssets.push(e);
                            _listEstateLink.push({estateId: _estate.id, facilityId: e.id})

                            _listAssetAvailable.splice(utility.getIndexArrayElementByField(_listAssetAvailable, 'id', e.id), 1)
                        })
                    }

                    _popup.data.listAssetAvailableSelected = [];
                    _popup.data.listAssetAvailable = _listAssetAvailable;
                    _popup.data.listEstateAssets = _listEstateAssets;
                    _popup.data.listEstateLink = _listEstateLink;

                    _popup.updateData(_popup.data)
                }
            }

            _this.removeAssetLinkedToEstate = function() {
                return function(_popup, _assetId) {


                    _popup.data.listAssetAvailable.push(utility.getArrayElementByField(_this.listAsset, 'id', _assetId));
                    _popup.data.listEstateAssets.splice(utility.getIndexArrayElementByField(_popup.data.listEstateAssets, 'id', _assetId), 1);
                    _popup.data.listEstateLink.splice(utility.getIndexArrayElementByField(_popup.data.listEstateLink, 'facilityId', _assetId), 1);

                    _popup.updateData(_popup.data)

                }
            }


            _this.addAssetLinkedToNetworkLink = function() {
                return function(_popup) {
                    var _networkLink = _popup.data.networkLink;
                    var _listAssetAvailableSelected = _popup.data.listAssetAvailableSelected;
                    var _listAssetAvailable = _popup.data.listAssetAvailable;
                    var _listNetworkLinkAssets = _popup.data.listNetworkLinkAssets;
                    var _listNetworkLink = _popup.data.listNetworkLink;

                    if (_listAssetAvailableSelected) {
                        _listAssetAvailableSelected.forEach(e => {
                            _listNetworkLinkAssets.push(e);
                            _listNetworkLink.push({networkLinkId: _networkLink.id, facilityId: e.id})

                            _listAssetAvailable.splice(utility.getIndexArrayElementByField(_listAssetAvailable, 'id', e.id), 1)
                        })
                    }

                    _popup.data.listAssetAvailableSelected = [];
                    _popup.data.listAssetAvailable = _listAssetAvailable;
                    _popup.data.listNetworkLinkAssets = _listNetworkLinkAssets;
                    _popup.data.listNetworkLink = _listNetworkLink;

                    _popup.updateData(_popup.data)
                }
            }

            _this.removeAssetLinkedToNetworkLink = function() {
                return function(_popup, _assetId) {


                    _popup.data.listAssetAvailable.push(utility.getArrayElementByField(_this.listAsset, 'id', _assetId));
                    _popup.data.listNetworkLinkAssets.splice(utility.getIndexArrayElementByField(_popup.data.listNetworkLinkAssets, 'id', _assetId), 1);
                    _popup.data.listNetworkLink.splice(utility.getIndexArrayElementByField(_popup.data.listNetworkLink, 'facilityId', _assetId), 1);

                    _popup.updateData(_popup.data)

                }
            }

            //Highlight an asset Marker
            //or remove it
            //based on _highlight parameter
            function _highlightAssetMarker(_asset, _highlight) {

                _listAssetMarker.find(e => {
                    if (e.asset.id == _asset.id) {

                        var _iconSize = {}

                        if (_highlight) {                            
                            _iconSize.width = 48;
                            _iconSize.height = 54;
                        } else {
                            _iconSize.width = 32;
                            _iconSize.height = 37;
                        }

                        var _icon = {
                            url: (e.getIcon() instanceof Object)?e.getIcon().url:e.getIcon(),
                            size: new google.maps.Size(_iconSize.width, _iconSize.height),
                            scaledSize: new google.maps.Size(_iconSize.width, _iconSize.height),
                        };

                        e.setIcon(_icon);

                        if (_highlight) {
                            //Move the marker highlighted to the right
                            //precisaly to x=75% of the screen's width
                            //and y=50% of the screen's height
                            var _x = $("#_customer_asset_container").width()*0.75;
                            var _y = $("#_customer_asset_container").height()*0.5;

                            googlemaps.moveTo(e.map, e.getPosition(), _x, _y, 10)
                        }

                        return true;
                    }
                })
            }

            _this.setAssetSelected = function (_asset) {
                if (_this.assetSelected) _highlightAssetMarker(_this.assetSelected, false);

                if (_asset) {
                    $scope.assetIdSelected = _asset.id;
                    _this.assetSelected = _asset;
                    _highlightAssetMarker(_asset, true);


    
                    Scopes.get('headerCtrl').setAssetId(_asset.id);    
                } else {
                    $scope.assetIdSelected = undefined;
                    _this.assetSelected = undefined;
    
                    Scopes.get('headerCtrl').setAssetId(undefined);    
                }
            }

            $scope.saveAssetStructureFromPopup = function (_popup) {
                _popup.data.asset.status = _popup.data.assetStatusSelected[0].key;
                _popup.data.asset.statusProject = _popup.data.assetStatusProjectSelected[0].key;
                _saveAsset(_popup.data.asset, (_res, _updatated) => {

                    _popup.data.asset = _res;
                    if (!_updatated) {
                        //comunicate to the popup that the saving customer task was correctly completed
                        _popup.success(_popup.data)
                    } else {
                        _popup.data.asset = _res;
                        _popup.updateData(_popup.data, { 'title': _res.name });
                    }

                    _popup.data.assetStructureSelected.facilityId = _popup.data.asset.id;
                    if (_popup.data.assetStructureSelected.structureTypeKey) {
                        _popup.data.assetStructureSelected.name = _this.listStructureTypeByKey[_popup.data.assetStructureSelected.structureTypeKey].value
                    }

                    _saveAssetStructure(_popup.data.assetStructureSelected, _res => {
                        _popup.data.assetStructureSelected = false;
                        // _popup.data.listAssetStructure = _res;
                        _popup.success(_popup.data)
                    }, _err => {
                        _popup.error(_err);
    
                    });
    
                }, _err => {
                    _popup.error(_err);

                });

            }

            function _saveAssetStructure(_assetStructure, _success, _error) {
                assetServices.saveAssetStructure(_identity.id, _assetStructure, _res => {

                    myAlert("Asset's structure saved", 'info');

                    _updateAssetStructureAfterActions(_this.listAssetStructure, _res)

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                        var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                        $mdDialog.show(_confirm).then(function () {

                            assetServices.getAsset(_asset.id, _res => {
                                _updateAssetAfterActions(_this.listAsset, _asset, _res);

                                _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                            })
                        }, function () {
                        });


                    } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    }
                })
            }

            $scope.saveAsset3dModelFromPopup = function (_popup) {
                _saveAsset3dModel(_popup.data.asset.id, _popup.data.asset3dModelSelected, _res => {
                    _popup.data.asset3dModelSelected = false;
                    _popup.success(_popup.data)
                }, _err => {
                    _popup.error(_err);

                });
            }

            function _saveAsset3dModel(_assetId, _asset3dModel, _success, _error) {
                if (!_asset3dModel.id) {
                    _this.upload3dModel(_assetId, _asset3dModel, _res => {
                        _asset3dModel.nameModelObj = _res.name;
                        _updateAsset3dModelAfterActions(_this.listAsset3dModel, _asset3dModel)
                        _success();    
                    });
                    // _this.upload3dModel(_assetId, _asset3dModel);

                    // _updateAsset3dModelAfterActions(_this.listAsset3dModel, _asset3dModel)
                    // _success();
                } else {
                    assetServices.saveAsset3dModel(_identity.id, _assetId, _asset3dModel, _res => {
                        _this.upload3dModel(_assetId, _asset3dModel, _res => {
                            _asset3dModel.nameModelObj = _res.name;
                            _updateAsset3dModelAfterActions(_this.listAsset3dModel, _asset3dModel)
                            _success();    
                        });
    
                        // _this.upload3dModel(_assetId, _asset3dModel);
                        // _updateAsset3dModelAfterActions(_this.listAsset3dModel, _res)
                        // myAlert("3D Model saved", 'info');
                        // _success ? _success(_res) : null;  //Call the _success function only if it is defined

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');

                        if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                            var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                            $mdDialog.show(_confirm).then(function () {

                                assetServices.getAsset(_asset.id, _res => {
                                    _updateAssetAfterActions(_this.listAsset, _asset, _res);

                                    _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                                })
                            }, function () {
                            });


                        } else {
                            _error ? _error(_err) : null;  //Call the _error function only if it is defined
                        }
                    })
                }
            }

            $scope.saveEstate3dModelFromPopup = function (_popup) {
                _saveEstate3dModel(_popup.data.estate.id, _popup.data.asset3dModelSelected, _res => {
                    _popup.data.asset3dModelSelected = false;
                    _popup.success(_popup.data)
                }, _err => {
                    _popup.error(_err);

                });
            }

            function _saveEstate3dModel(_estateId, _estate3dModel, _success, _error) {
                if (!_estate3dModel.id) {
                    _this.upload3dModel(_estateId, _estate3dModel, _res => {
                        _estate3dModel.nameModelObj = _res.name;
                        _updateAsset3dModelAfterActions(_this.listAsset3dModel, _estate3dModel)
                        _success();    
                    });
                    // _updateAsset3dModelAfterActions(_this.listAsset3dModel, _estate3dModel)
                    // _success();
                } else {
                    assetServices.saveAsset3dModel(_identity.id, _estateId, _estate3dModel, _res => {
                        _this.upload3dModel(_estateId, _estate3dModel, _res => {
                            _estate3dModel.nameModelObj = _res.name;
                            _updateAsset3dModelAfterActions(_this.listAsset3dModel, _estate3dModel)
                            _success();    
                        });

                        // _this.upload3dModel(_estateId, _estate3dModel);
                        // _updateAsset3dModelAfterActions(_this.listAsset3dModel, _res)
                        // myAlert("3D Model saved", 'info');
                        // _success ? _success(_res) : null;  //Call the _success function only if it is defined

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');

                        if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                            var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                            $mdDialog.show(_confirm).then(function () {

                                assetServices.getAsset(_asset.id, _res => {
                                    _updateAssetAfterActions(_this.listAsset, _asset, _res);

                                    _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                                })
                            }, function () {
                            });


                        } else {
                            _error ? _error(_err) : null;  //Call the _error function only if it is defined
                        }
                    })
                }
            }



            $scope.addAssetStructureFromPopup = function (_popup) {
                _popup.data.assetStructureSelected = {
                    facilityId: _popup.data.asset.id,
                    name: '',
                    description: ''
                }

                utility.callFunctionEveryTime('!$("#_structure_fields").hasClass("ng-hide")', 200, function() {
                    $("#_asset_panel").scrollTop( $("#_structure_fields").offset().top );   
                    $("#_structure_name").focus();               
    
                });
            }

            $scope.addAsset3dModelFromPopup = function (_popup) {
                _popup.data.asset3dModelSelected = {
                    companyId: _popup.data.asset.companyId,
                    facilityTypeId: _popup.data.asset.facilityTypeId,
                    name: '',
                    description: ''
                }
                _this.uploader.setMethods('3dModel',_popup.data.dzMethods);
            }

            $scope.addEstate3dModelFromPopup = function (_popup) {
                _popup.data.asset3dModelSelected = {
                    companyId: _popup.data.estate.companyId,
                    facilityTypeId: _popup.data.estate.facilityTypeId,
                    name: '',
                    description: ''
                }

                _this.uploader.setMethods('3dModel',_popup.data.dzMethods);
            }


            _this.upload3dModel = function (_assetId, _asset3dModel, _success) {
                // var _callbackData = {
                //     asset3dModel: _asset3dModel,
                // }
                // _this.uploader.setSuccessCallback('_updateAsset3dModelAfterActions', _callbackData)

                _this.uploader.getOptions('3dModel').successCallback = _success;
                // _this.uploader.getOptions().callbackData = _callbackData;

                _this.uploader.setDataToSendBackend('3dModel',{
                    facilityTypeId: _asset3dModel.facilityTypeId,
                    name: _asset3dModel.name,
                    description: _asset3dModel.description,
                    companyId: _asset3dModel.companyId,
                    userId: _identity.id,
                    assetId: _assetId
                })
                _this.uploader.getMethods('3dModel').autoProcessQueue = true;
                _this.uploader.getMethods('3dModel').processQueue();
            }


            function _checkAndSaveAssetStructure(_popup, _facilityId, _success, _error) {
                //create a structure if the asset is new and don't have any structure
                if (!_popup.data.listAssetStructure || _popup.data.listAssetStructure.length == 0) {
                    var _structure = {
                        description: "Complete Asset",
                        facilityId: _popup,
                        name: "Complete Asset",
                    }

                    if (_popup.data.listStructureType && _popup.data.listStructureType.length>0) {
                        _structure.structureTypeKey = "complete-asset"
                    }

                    _structure.facilityId = _facilityId;

                    _saveAssetStructure(_structure, _res => {
                        _success(_popup.data)
                    }, _err => {
                        _error(_err);

                    });
                }                
            }

            $scope.saveAssetFromPopup = function (_popup) {
                _popup.data.asset.status = _popup.data.assetStatusSelected[0].key;
                _popup.data.asset.statusProject = _popup.data.assetStatusProjectSelected[0].key;

                if ($scope.isFeatureEnabled($scope.listFeatureKey.forensicPointAsset)) {
                    // _this.uploader_fp.getMethods('forensicPoint').autoProcessQueue = true;
                    var n = 0;
                    try {
                        n = _this.uploader_fp.getMethods('forensicPoint').getAllFiles().length;                            
                    } catch (error) {}

                    console.log(n)

                    // if (_popup.data.dzMethods.getAllFiles().length > 0) { 
                    if (n > 0) { 
                        _this.uploader_fp.getMethods('forensicPoint').processQueue();
                        myAlert("Forensic Point List Uploaded", 'info');
                    }
                }
                

                if (_popup.data.asset.facilityModel3dId) {
                    _popup.data.asset.facilityAssetMapperModelId = undefined;
                    _popup.data.asset.facility3dModelParsingResult = undefined;

                    _popup.updateData(_popup.data);
                }


                _saveAsset(_popup.data.asset, (_res, _updatated) => {

                    _popup.data.asset = _res;
                    if (!_updatated) {
                        _listAssetMarker.some(e => {
                            if (e.asset.id == _res.id) {
                                e.setPosition(new google.maps.LatLng(_res.latitude, _res.longitude))
                                _setAssetMarkerIcon(e, _res)       
                                return true                         
                            }
                        })
                        // //create a structure if the asset is new and don't have any structure
                        // if (!_popup.data.listAssetStructure || _popup.data.listAssetStructure.length == 0) {
                        //     var _structure = {
                        //         description: "Complete Asset",
                        //         facilityId: _popup,
                        //         name: "Complete Asset",
                        //     }

                        //     if (_popup.data.listStructureType && _popup.data.listStructureType.length>0) {
                        //         _structure.structureTypeKey = "complete-asset"
                        //     }

                        //     _structure.facilityId = _popup.data.asset.id;
        
                        //     _saveAssetStructure(_structure, _res => {
                        //         _popup.success(_popup.data)
                        //     }, _err => {
                        //         _popup.error(_err);
            
                        //     });
                        // }
                        _checkAndSaveAssetStructure(_popup, _popup.data.asset.id, _res => {
                            _popup.success(_popup.data)
                        }, _err => {
                            _popup.error(_err);            
                        });

                        //comunicate to the popup that the saving customer task was correctly completed
                        _popup.success(_popup.data)
                    } else {
                        _popup.data.asset = _res;
                        _popup.updateData(_popup.data, { 'title': _res.name });
                    }
                }, _err => {
                    _popup.error(_err);

                });

                if ($scope.isFeatureEnabled($scope.listFeatureKey.forensicPointAsset)) {
                    _saveForensicPoint(_popup.data.listAssetForensicPoint, (_res, _updatated) => {

                        _popup.data.listAssetForensicPoint = _res;
                        if (!_updatated) {
                            //comunicate to the popup that the saving customer task was correctly completed
                            _popup.success(_popup.data)
                        } else {
                            _popup.data.listAssetForensicPoint = _res;
                            _popup.updateData(_popup.data, { 'title': _res.name });
                        }
                    }, _err => {
                        _popup.error(_err);

                    });

                    if (_popup.data.newFP){
                        _saveNewForensicPoint([_popup.data.newFP], (_res, _updatated) => {

                            _popup.data.listAssetForensicPoint = _res;
                            if (!_updatated) {
                                //comunicate to the popup that the saving customer task was correctly completed
                                _popup.success(_popup.data)
                            } else {
                                _popup.data.listAssetForensicPoint = _res;
                                _popup.updateData(_popup.data, { 'title': _res.name });
                            }
                        }, _err => {
                            _popup.error(_err);
        
                        });

                    }
                }
            }


            $scope.saveEstateFromPopup = function (_popup) {
                _popup.data.estate.status = _popup.data.assetStatusSelected[0].key;
                _popup.data.estate.statusProject = _popup.data.assetStatusProjectSelected[0].key;
                _popup.data.estate.listEstateLinkDto = _popup.data.listEstateLink;

                if ($scope.isFeatureEnabled($scope.listFeatureKey.forensicPointEstate)) {
                    _this.uploader_fp.getMethods('forensicPoint').autoProcessQueue = true;
                    // var n = _this.uploader_fp.getMethods('forensicPoint').get
    
                    if (_popup.data.dzMethods_fp.getAllFiles().length > 0) { 
                        _this.uploader_fp.getMethods('forensicPoint').processQueue();
                        myAlert("Forensic Point List Uploaded", 'info');
                    }    
                }

                if (_popup.data.estate.facilityModel3dId) {
                    _popup.data.estate.facilityAssetMapperModelId = undefined;
                    _popup.data.estate.facility3dModelParsingResult = undefined;

                    _popup.updateData(_popup.data);
                }

                if (_this.estateArea != undefined) {
                    _popup.data.estate.listFacilityAreaCoordination = [];
                    _this.estateArea.getPath().forEach(p => {
                        _popup.data.estate.listFacilityAreaCoordination.push({latitude: p.lat(), longitude:p.lng()})
                    });

                } else {
                    _popup.data.estate.listFacilityAreaCoordination = _popup.data.listEstateAreaCoordinate;
                }

                _saveEstate(_popup.data.estate, (_res, _updatated) => {

                    _popup.data.estate = _res;
                    if (!_updatated) {

                        _checkAndSaveAssetStructure(_popup, _popup.data.estate.id, _res => {
                            _popup.success(_popup.data)
                        }, _err => {
                            _popup.error(_err);            
                        });

                        //comunicate to the popup that the saving customer task was correctly completed
                        _popup.success(_popup.data)
                    } else {
                        _popup.data.estate = _res;
                        _popup.updateData(_popup.data, { 'title': _res.name });
                    }
                }, _err => {
                    _popup.error(_err);

                });

                if ($scope.isFeatureEnabled($scope.listFeatureKey.forensicPointEstate) && _popup.data.listAssetForensicPoint) {
                    _saveForensicPoint(_popup.data.listAssetForensicPoint, (_res, _updatated) => {

                        _popup.data.listAssetForensicPoint = _res;
                        if (!_updatated) {
                            //comunicate to the popup that the saving customer task was correctly completed
                            _popup.success(_popup.data)
                        } else {
                            _popup.data.listAssetForensicPoint = _res;
                            _popup.updateData(_popup.data, { 'title': _res.name });
                        }
                    }, _err => {
                        _popup.error(_err);

                    });

                    if (_popup.data.newFP){
                        _saveNewForensicPoint([_popup.data.newFP], (_res, _updatated) => {

                            _popup.data.listAssetForensicPoint = _res;
                            if (!_updatated) {
                                //comunicate to the popup that the saving customer task was correctly completed
                                _popup.success(_popup.data)
                            } else {
                                _popup.data.listAssetForensicPoint = _res;
                                _popup.updateData(_popup.data, { 'title': _res.name });
                            }
                        }, _err => {
                            _popup.error(_err);
        
                        });

                    }
                }
            }


            $scope.saveNetworkLinkFromPopup = function (_popup) {
                _popup.data.networkLink.status = _popup.data.assetStatusSelected[0].key;
                _popup.data.networkLink.listNetworkLinkDto = _popup.data.listNetworkLink;


                _saveNetworkLink(_popup.data.networkLink, (_res, _updatated) => {

                    _popup.data.networkLink = _res;
                    if (!_updatated) {

                        _checkAndSaveAssetStructure(_popup, _popup.data.networkLink.id, _res => {
                            _popup.success(_popup.data)
                        }, _err => {
                            _popup.error(_err);            
                        });

                        //comunicate to the popup that the saving customer task was correctly completed
                        _popup.success(_popup.data)
                    } else {
                        _popup.data.networkLink = _res;
                        _popup.updateData(_popup.data, { 'title': _res.name });
                    }
                }, _err => {
                    _popup.error(_err);

                });
            }



            function _updateAssetStructureAfterActions(_listAssetStructure, _assetStructureNew) {
                //get the asset index into the array
                var _index = utility.getIndexArrayElementByField(_listAssetStructure, 'id', _assetStructureNew.id);

                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listAssetStructure[_index] = _assetStructureNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listAssetStructure.push(_assetStructureNew);
                }
            }

            

            function _updateAsset3dModelAfterActions(_listAsset3dModel, _asset3dModelNew) {
                //get the asset index into the array
                var _index = utility.getIndexArrayElementByField(_listAsset3dModel, 'id', _asset3dModelNew.id);

                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listAsset3dModel[_index] = _asset3dModelNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listAsset3dModel.push(_asset3dModelNew);
                }

            }


            function _updateAssetAfterActions(_listAsset, _assetOld, _assetNew) {
                //get the asset index into the array
                var _index = utility.getIndexArrayElementByField(_listAsset, 'id', _assetNew.id);

                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listAsset[_index] = _assetNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listAsset.push(_assetNew);
                }

                _getDatasetLatestUploaded(_assetOld.companyId, _identity.id);
                // _this.listAsset.forEach(e => {
                //     e.latestUpdate = e.lastUpdateTime?e.lastUpdateTime:e.createdDate;
                // })

                _this.setAssetSelected(_assetNew);

                if (_this.viewShown == _this.views.asset) {
                    _this.showAssetList();
                }
            }

            function _updateEstateAfterActions(_listEstate, _estateOld, _estateNew) {
                //get the asset index into the array
                var _index = utility.getIndexArrayElementByField(_listEstate, 'id', _estateNew.id);

                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listEstate[_index] = _estateNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listEstate.push(_estateNew);
                }

                _this.setAssetSelected(_estateNew);

                _this.showEstateList();
            }

            function _updateNetworkLinkAfterActions(_listNetworkLink, _networkLinkOld, _networkLinkNew) {
                //get the asset index into the array
                var _index = utility.getIndexArrayElementByField(_listNetworkLink, 'id', _networkLinkNew.id);

                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listNetworkLink[_index] = _networkLinkNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listNetworkLink.push(_networkLinkNew);
                }

                _this.setAssetSelected(_networkLinkNew);

                _this.showNetworkLinkList();

            }


            function _validationItems(_type, _item) {
                var v = fsValidator.newInstance();

                switch (_type) {
                    case 'asset':
                        v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                        v.validate(_item.name, 'name').isThereSomeCharacter(['/'], 'The character / is not allowed in this field')
                        v.validate(_item.facilityTypeId, 'facilityTypeId').isUndefined("This field can't be empty")
                        // v.validate(_item.siteTypeKey, 'siteTypeKey').isUndefined("This field can't be empty")
                        v.validate(_item.latitude, 'latitude').isEmpty("This field can't be empty")
                        v.validate(_item.longitude, 'longitude').isEmpty("This field can't be empty")
                        break;
                    case 'estate':
                        v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                        v.validate(_item.name, 'name').isThereSomeCharacter(['/'], 'The character / is not allowed in this field')
                        break;
                    case 'networkLink':
                        v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                        v.validate(_item.name, 'name').isThereSomeCharacter(['/'], 'The character / is not allowed in this field')
                        break;
                    case 'structure-by_list_of_structures':
                        v.validate(_item.structureTypeKey, 'structureTypeKey').isUndefined("This field can't be empty")
                        break;
                    case 'structure-by_name_of_structure':
                        v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                        break;
                    case '3d-model':
                        v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                        break;
                }

                _item.errors = v.getErrors();

                return v;

            }

            function _validationAsset(_asset) {
                var _ret = false;

                _validationItems('asset', _asset).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = _asset;
                })

                return _ret
            }

            $scope.validationAssetFromPopup = function (_popup) {
                var _ret = _validationAsset(_popup.data.asset);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.asset = _ret;
                    return _popup.data;
                }

            }

            function _validationEstate(_estate) {
                var _ret = false;

                _validationItems('estate', _estate).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = _estate;
                })

                return _ret
            }

            $scope.validationEstateFromPopup = function (_popup) {
                var _ret = _validationEstate(_popup.data.estate);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.estate = _ret;
                    return _popup.data;
                }

            }

            function _validationNetworkLink(_networkLink) {
                var _ret = false;

                _validationItems('networkLink', _networkLink).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = _networkLink;
                })

                return _ret
            }

            $scope.validationNetworkLinkFromPopup = function (_popup) {
                var _ret = _validationNetworkLink(_popup.data.networkLink);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.networkLink = _ret;
                    return _popup.data;
                }

            }


            function _validationAsset3dModel(_asset3dModel) {
                var _ret = false;

                _validationItems('3d-model', _asset3dModel).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = _asset3dModel;
                })

                return _ret
            }

            $scope.validationAsset3dModelFromPopup = function (_popup) {
                var _ret = _validationAsset3dModel(_popup.data.asset3dModelSelected);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.asset3dModelSelected = _ret;
                    return _popup.data;
                }
            }

            $scope.validationEstate3dModelFromPopup = function (_popup) {
                var _ret = _validationAsset3dModel(_popup.data.asset3dModelSelected);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.asset3dModelSelected = _ret;
                    return _popup.data;
                }

            }


            function _validationAssetStructure(_assetStructure, _type) {
                var _ret = false;

                _validationItems('structure-'+_type, _assetStructure).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = _assetStructure;
                })

                return _ret
            }

            $scope.validationAssetStructureFromPopup = function (_popup) {
                var _ret;
                if (_popup.data.listStructureType && _popup.data.listStructureType.length>0) {
                    _ret = _validationAssetStructure(_popup.data.assetStructureSelected, 'by_list_of_structures');
                } else {
                    _ret = _validationAssetStructure(_popup.data.assetStructureSelected, 'by_name_of_structure');
                }

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.assetStructureSelected = _ret;
                    return _popup.data;
                }
            }            

            function _saveAsset(_asset, _success, _error) {
                assetServices.saveAsset(_identity.id, _asset, _res => {

                    _updateAssetAfterActions(_this.listAsset, _asset, _res);

                    myAlert("Asset saved", 'info');

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                        var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                        $mdDialog.show(_confirm).then(function () {

                            assetServices.getAsset(_asset.id, _res => {
                                _updateAssetAfterActions(_this.listAsset, _asset, _res);

                                _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                            })
                        }, function () {
                        });


                    } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    }
                })
            }

            function _saveForensicPoint(_listForensicPoints, _success, _error) {
                assetServices.saveAssetForensicPoint(_identity.id, _listForensicPoints, _res => {

                    // _updateAssetAfterActions(_this.listAsset, _listForensicPoints, _res);

                    myAlert("Asset saved", 'info');

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                        var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')

                    } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    }
                })
            }

            function _saveNewForensicPoint(newForensicPoint, _success, _error) {
                assetServices.saveAssetNewForensicPoint(_identity.id, newForensicPoint, _res => {

                    // _updateAssetAfterActions(_this.listAsset, _listForensicPoints, _res);

                    myAlert("Asset saved", 'info');

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                        var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')

                    } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    }
                })
            }

            function _saveEstate(_estate, _success, _error) {
                assetServices.saveAsset(_identity.id, _estate, _res => {

                    _updateEstateAfterActions(_this.listEstate, _estate, _res);



                    myAlert("Estate saved", 'info');

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                        var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                        $mdDialog.show(_confirm).then(function () {

                            assetServices.getAsset(_asset.id, _res => {
                                _updateAssetAfterActions(_this.listAsset, _asset, _res);

                                _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                            })
                        }, function () {
                        });


                    } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    }
                })
            }

            function _saveNetworkLink(_networkLink, _success, _error) {
                assetServices.saveNetworkLink(_identity.id, _networkLink, _res => {

                    _updateNetworkLinkAfterActions(_this.listNetworkLink, _networkLink, _res);



                    myAlert("Network Link saved", 'info');

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');

                    // if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                    //     var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                    //     $mdDialog.show(_confirm).then(function () {

                    //         assetServices.getAsset(_asset.id, _res => {
                    //             _updateAssetAfterActions(_this.listAsset, _asset, _res);

                    //             _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                    //         })
                    //     }, function () {
                    //     });


                    // } else {
                        _error ? _error(_err) : null;  //Call the _error function only if it is defined
                    // }
                })
            }


            function _createAssetLocationMarker(_map, _lat, _lng, _asset) {

                var _marker = new google.maps.Marker({
                    map: _map,
                    position: new google.maps.LatLng(_lat, _lng),
                    // icon: _icon,
                    title: _asset.name,
                    lat: _lat,
                    log: _lng

                });


                return _marker;
            }

            _this.createMapForAssetLocation = function () {
                return function (_canvasId, _popup) {
                    var _asset = _popup.data.asset;

                    //delete from _maps array, if exist, the previous google map object
                    var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId);
                    if (_index != undefined) {
                        _maps.splice(_index, 1);
                    }

                    if (_popup.getInnerStatus('map-location')) {
                        var _map;

                        googlemaps.initGoogleMap(_canvasId, _m => {
                            //push the just created google map into _maps array
                            _maps.push({ id: _canvasId, map: _m });
                            _map = _m;

                            _map.setCenter(new google.maps.LatLng(_asset.latitude, _asset.longitude));

                            var _marker = _createAssetLocationMarker(_map, _asset.latitude, _asset.longitude, _asset)


                            google.maps.event.addListener(_map, 'click', function (_event) {
                                _marker.setMap(null)
                                _marker = _createAssetLocationMarker(_map, _event.latLng.lat(), _event.latLng.lng(), _asset)


                                _asset.latitude = _event.latLng.lat();
                                _asset.longitude = _event.latLng.lng();

                                $scope.$apply();
                            })
                        });

                        google.maps.event.addListenerOnce(_map, 'idle', function(){
                            // do something only the first time the map is loaded
                            $("#_asset_panel").scrollTop( $("#_assetLocationPopup").offset().top );   
                        });
                    }
                }
            }

            _this.createMapForForensicPoint = function () {
                return function (_canvasId, forensic_point_id, _popup) {
                    var _currentFP = _popup.data.listAssetForensicPoint.find(x => x.id === forensic_point_id)

                    //delete from _maps array, if exist, the previous google map object
                    _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);

                    var _map;

                    googlemaps.initGoogleMap(_canvasId, _m => {
                        //push the just created google map into _maps array
                        _maps.push({ id: _canvasId, map: _m });
                        _map = _m;

                        _map.setCenter(new google.maps.LatLng(_currentFP.latitude, _currentFP.longitude));

                        var _marker = _createAssetLocationMarker(_map, _currentFP.latitude, _currentFP.longitude, _currentFP)

                        google.maps.event.addListener(_map, 'click', function (_event) {
                            _marker.setMap(null)
                            _marker = _createAssetLocationMarker(_map, _event.latLng.lat(), _event.latLng.lng(), _currentFP)


                            _currentFP.latitude = _event.latLng.lat();
                            _currentFP.longitude = _event.latLng.lng();

                            $scope.$apply();
                        })
                    });
                }
            }

            _this.MapAddForForensicPoint = function () {
                return function (_canvasId, _popup) {
                    _popup.data.newFP = {               
                        'facilityId': _popup.data.asset.id,
                        'name': 'Example',
                        'latitude': _popup.data.asset.latitude,
                        'longitude': _popup.data.asset.longitude,
                        'altitude': 0,
                        'bearing': 0,
                        'angle': 0,
                        'creationDate': '',
                        'creationUserId': ''
                    }

                    //delete from _maps array, if exist, the previous google map object
                    _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);

                    var _map;

                    googlemaps.initGoogleMap(_canvasId, _m => {
                        //push the just created google map into _maps array
                        _maps.push({ id: _canvasId, map: _m });
                        _map = _m;

                        _map.setCenter(new google.maps.LatLng(_popup.data.newFP.latitude, _popup.data.newFP.longitude));

                        var _marker = _createAssetLocationMarker(_map, _popup.data.newFP.latitude, _popup.data.newFP.longitude,_popup.data.newFP)

                        google.maps.event.addListener(_map, 'click', function (_event) {
                            _marker.setMap(null)
                            _marker = _createAssetLocationMarker(_map, _event.latLng.lat(), _event.latLng.lng(), _popup.data.newFP)


                            _popup.data.newFP.latitude = _event.latLng.lat();
                            _popup.data.newFP.longitude = _event.latLng.lng();

                            $scope.$apply();
                        })
                    });
                }
            }   
        
            _this.MapDeleteForensicPoint = function() {
                console.log('ok')
                return function (_canvasId, forensic_point_id, _popup) {
                }
            }



            _this.btnCreateMapForEstateAreaLocation = function () {
                return function (_canvasId, _popup) {
                    var _estate = _popup.data.estate;
                    var _listEstateAreaCoordinate = _popup.data.listEstateAreaCoordinate;

                    //delete from _maps array, if exist, the previous google map object
                    var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId)
                    if (_index != undefined) {
                        _maps.splice(utility.getIndexArrayElementByField(_maps, 'id', _canvasId), 1);
                    }
                    
                    var _map;

                    googlemaps.initGoogleMap(_canvasId, _m => {
                        //push the just created google map into _maps array
                        _maps.push({ id: _canvasId, map: _m });
                        _map = _m;



                        //used to set the marker
                        function getId(_lat, _lng) {
                            return Math.trunc(_lat * _lng * 100000);
                        }

                        var _estateAreaCoords = [];
                        _this.estateArea = [];

                        if (!_listEstateAreaCoordinate || _listEstateAreaCoordinate.length == 0) {
                            _this.estateArea = new google.maps.Polyline({
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 3
                            });

                            _this.estateArea.setMap(_map);
                        } else {
                            var _bounds = new google.maps.LatLngBounds();

                            _listEstateAreaCoordinate.forEach(f => {
                                _estateAreaCoords.push({
                                    lat: f.latitude,
                                    lng: f.longitude
                                })

                                _bounds.extend(_estateAreaCoords[_estateAreaCoords.length - 1]);
                            });

                            _map.setCenter(_bounds.getCenter());

                            _this.estateArea = new google.maps.Polygon({
                                paths: _estateAreaCoords,
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 3,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35
                            });

                            _this.estateArea.setMap(_map);

                            var _path = _this.estateArea.getPath();

                            _path.forEach(p => {
                                var marker = new google.maps.Marker({
                                    icon: '/img/icons/markers/marker-polygon.png',
                                    id: getId(p.lat(), p.lng()),
                                    position: new google.maps.LatLng(p.lat(), p.lng()),
                                    draggable: true,
                                    map: _map
                                });

                                marker.addListener('dragend', function (e) {
                                    markerDragend(marker, e);
                                });

                                marker.addListener('rightclick', function (e) {
                                    markerRightClick(marker, e);
                                });

                            });

                        }

                        function markerDragend(marker, e) {
                            var _path = _this.estateArea.getPath();

                            var _newpath = [];
                            _path.forEach(p => {
                                if (getId(p.lat(), p.lng()) == marker.id) {
                                    _newpath.push(e.latLng);
                                    marker.id = getId(e.latLng.lat(), e.latLng.lng());
                                } else {
                                    _newpath.push(p);
                                }
                            })

                            _this.estateArea.setPath(_newpath);
                        }

                        function markerRightClick(marker, e) {
                            var _path = _this.estateArea.getPath();

                            var _newpath = [];
                            _path.forEach(p => {
                                if (getId(p.lat(), p.lng()) != marker.id) {
                                    _newpath.push(p);
                                }
                            })

                            _this.estateArea.setPath(_newpath);

                            marker.setMap(null);

                        }

                        google.maps.event.addListener(_map, 'click', function (e) {

                            var _path = _this.estateArea.getPath();
                            _path.push(e.latLng);

                            if (_path.getLength() == 3 && (!_listEstateAreaCoordinate || _listEstateAreaCoordinate.length == 0)) {
                                _estateAreaCoords = angular.copy(_path);

                                _this.estateArea.setMap(null);

                                _this.estateArea = new google.maps.Polygon({
                                    paths: _estateAreaCoords,
                                    strokeColor: '#FF0000',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 3,
                                    fillColor: '#FF0000',
                                    fillOpacity: 0.35
                                });

                                _this.estateArea.setMap(_map);
                            }

                            var marker = new google.maps.Marker({
                                icon: '/img/icons/markers/marker-polygon.png',
                                id: getId(e.latLng.lat(), e.latLng.lng()),
                                position: e.latLng,
                                draggable: true,
                                map: _map
                            });

                            marker.addListener('dragend', function (e) {
                                markerDragend(marker, e, _this.estateArea) ;
                            });

                            marker.addListener('rightclick', function (e) {
                                markerRightClick(marker, e, _this.estateArea);
                            });

                        });



                    });
                }
            }

            _this.toggleAssetTableFilterVisible = function() {
                _this.isAssetTableFilterVisible = !_this.isAssetTableFilterVisible;
            }


            //function called from the markers
            _this.editAssetFromMarker = function(_assetId, _customerId) {
                _this.viewShown = _this.views.asset;

                // _this.getCustomerAssetsEstates(_customerId, function() {
                    _this.editAsset(_assetId, _customerId)    
                // })

            } 
            
            _this.editAssetClosedFromMarkerCallback = function(_assetId, _customerId, _success) {
                utility.callFunctionEveryTime('$(".modal-dialog").get(0)', 200, function() {
                    $(".modal-dialog").bind("$destroy", function() {

                        if (_success) {
                            _success(_this.assetSelected)
                        } else {
                            _listAssetMarker.some((e, index) => {
                                if (e.asset.id == _assetId) {
                                    assetServices.getAsset(_assetId, _res => {
                                        _listAssetMarker.splice(index, 1);    
                                        e.setMap(null);
            
                                        var _map = _maps[utility.getIndexArrayElementByField(_maps, 'id', "#"+_this.assetCanvasId)].map;
                                        var _asset = _res;
                                        var _marker = googlemaps.createAssetMarker(_map, _asset.latitude, _asset.longitude, _asset, googlemaps.getAssetMarkerIcon(_asset), false, googlemaps.getAssetMarkerIcon(_asset));
    
                                        _addAssetStatusTypeToMarker(_marker, _asset);
                                        _addAssetTypeToMarker(_marker, _asset);
                                        _setAssetMarkerIcon(_marker, _asset);
                                        
                                        _listAssetMarker.push(_marker);
            
                                        new google.maps.event.trigger( _marker, 'click' );
                
                                        return true;
                                    })
                                }
                            });
    
                        }
                    });
        
                })
            }
        

            //function called from the markers on the Dashboard screen's google map
            _this.editAssetFromDashboardMarker = function(_assetId, _customerId) {
                _this.viewShown = undefined;

                _this.getCustomerAssetsEstates(_customerId, function() {
                    _this.editAsset(_assetId, _customerId)    
                })
            } 


            _this.getSystemDNNTableParams = function () {
                var _ngTableParams;
                var _facilityTypeSelectedPrev;
                return {
                    init: function (_popup) {
                        if (_popup.data.facilityTypeSelected && _facilityTypeSelectedPrev != _popup.data.facilityTypeSelected) {
                            _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                            _popup.data.refreshSystemDNNTableParams = false;
                            _ngTableParams = ngTableParams.default(_this.listAiMachineSystem);
                            return _ngTableParams;
                        }
                    },
                    getData: function () {
                        return _ngTableParams;
                    }
                }
            }

            _this.getCompanyDNNTableParams = function () {
                var _ngTableParams;
                var _facilityTypeSelectedPrev;
                return {
                    // init: function (_popup) {
                    init: function (_popup, _type) { //just for Nokia. The _type parameter shouldn't exist
                        if (_popup.data.facilityTypeSelected && _facilityTypeSelectedPrev != _popup.data.facilityTypeSelected) {
                            _this.listAiMachineCompany.forEach(e => e.type = _type)    
                            _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                            _popup.data.refreshCompanyDNNTableParams = false;
                            _ngTableParams = ngTableParams.default(_this.listAiMachineCompany);
                            return _ngTableParams;
                        }
                    },
                    getData: function () {
                        return _ngTableParams;
                    }
                }
            }

            _this.getDNNByCompanyAndFacilityType = function() {
                var _facilityTypeSelectedPrev;
                return function(_popup) {
                    if (_popup.data.facilityTypeSelected && _facilityTypeSelectedPrev != _popup.data.facilityTypeSelected) {
                        _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                        _popup.updateData(_popup.data);

                        // if (_identity.companyId != 1) {
                        if (_this.customerId != 1) { //just for Nokia. Usually the DNN belonging to a customer, are those belonging to the inspecting company, not the inspected company (as in this case)
                                catalogueFactory.getListAiMachineByCompanyIdAndFacilityTypeId(_this.customerId, _popup.data.facilityTypeSelected, _res => {
                                _this.listAiMachineCompany = _res
                                _popup.data.refreshCompanyDNNTableParams = true;
                                _popup.updateData(_popup.data);
                            }, _err => {
                                myAlert("Error: " + _err.data.message, 'danger');
                            })    
                        }
    
                        catalogueFactory.getListAiMachineBySystemAndFacilityTypeId(_popup.data.facilityTypeSelected, _res => {
                            _this.listAiMachineSystem = _res;
                            _popup.data.refreshSystemDNNTableParams = true;
                            _popup.updateData(_popup.data);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        })           
                    }

                }
            }

            _this.getProductTableParams = function () {
                var _ngTableParams;
                var _productGroupSelectedPrev;
                return {
                    init: function (_popup) {
                        if (_popup.data.productGroupSelected && _productGroupSelectedPrev != _popup.data.productGroupSelected) {
                            _productGroupSelectedPrev = _popup.data.productGroupSelected;
                            _popup.data.refreshProductTableParams = false;
                            _ngTableParams = ngTableParams.default(_this.listProduct);
                            return _ngTableParams;
                        }
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            }


            _this.getProductByCompanyAndProductGroup = function() {
                var _productGroupSelectedPrev;
                return function(_popup) {
                    if (_popup.data.productGroupSelected && _productGroupSelectedPrev != _popup.data.productGroupSelected) {
                        _productGroupSelectedPrev = _popup.data.productGroupSelected;
                        _popup.updateData(_popup.data);

                        var _productGroup;
                        _this.listProductGroup.some(e => {
                            if (e.id == _popup.data.productGroupSelected) {
                                _productGroup = e;
                                return true;
                            }
                        })

                        productServices.getProductByCompanyIdAndProductGroupId(_productGroup.companyId, _productGroup.id, _res => {
                            _this.listProduct = _res
                            _popup.data.refreshProductTableParams = true;
                            _popup.updateData(_popup.data);
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        })    
                    }

                }
            }


            _this.createArrayInventoryFieldByProductGroupId = function(_productGroupId, _arrayInventoryFieldByInventoryGroupId, _listProductGroupInventoryGroup, _inventoryFieldValueJson) {
                var _listInventoryGroup = _listProductGroupInventoryGroup.filter(e => e.productGroupId==_productGroupId);
                var _ret = [];

                //set the "value" field to undefined
                _arrayInventoryFieldByInventoryGroupId.forEach(a => {
                    a.listInventoryField.forEach(f => f.value = undefined);
                })

                // _listInventoryGroup.forEach(e => _ret.push(_arrayInventoryFieldByInventoryGroupId.filter(i => i.inventoryGroupId==e.inventoryGroupId)[0]));
                _listInventoryGroup.forEach(e => _ret.push(_arrayInventoryFieldByInventoryGroupId.find(i => i.inventoryGroupId==e.inventoryGroupId)));

                //set the values of all the InventoryField based on _inventoryFieldValueJson
                if (_inventoryFieldValueJson) {
                    _ret.forEach(r => {
                        _inventoryFieldValueJson.some(v => {
                            if (v.inventoryGroupId == r.inventoryGroupId) {
                                r.listInventoryField.forEach(rf => {
                                    // rf.value = v.listInventoryFieldValue.filter(vf => vf.inventoryFieldId == rf.id)[0].value;
                                    rf.value = v.listInventoryFieldValue.find(vf => vf.inventoryFieldId == rf.id).value;
                                })
                            }
                        })
                    })
                }

                myLog("createArrayInventoryFieldByProductGroupId", _ret)

                return _ret;
            }

            _this.getListProductGroupType = function() {
                return function() {
                    return appFactory.getListProductGroupType();
                }
            }

            $scope.cancelBomFromPopup = function(_popup) {
                bomFactory.cancelItem(_popup);
            }

            $scope.saveBomFromPopup = function(_popup) {
                bomFactory.saveItem(_popup, _identity.id);
            }

            $scope.addBomFromPopup = function(_popup) {
                bomFactory.addItem(_popup, _this.customerId);
            }


            $scope.cancelClassManagmentFromPopup = function(_popup) {
                classManagmentFactory.cancelItem(_popup);
            }

            $scope.saveClassManagmentFromPopup = function(_popup) {
                classManagmentFactory.saveItem(_popup, _identity.id);
            }

            $scope.addClassManagmentFromPopup = function(_popup) {
                classManagmentFactory.addItem(_popup, _this.customerId);
                
            }

            function _cataloguesPopup(_catalogueData) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Ok', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.catalogue_section == 'dnn'" },
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Ok', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.catalogue_section == 'product'" },
                    { 'btn_identifier': '_3_1', 'btn_msg': 'Ok', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.catalogue_section == 'linkage'" },
                    { 'btn_identifier': '_4_1', 'btn_msg': 'Close', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.catalogue_section == 'bom'" },
                    { 'btn_identifier': '_4_2', 'btn_msg': 'Cancel', 'btn_close': false, 'btn_color': 'btn-clear', 'btn_fn':'cancelBomFromPopup',  'btn_visible_if': "$innerStatus.catalogue_section == 'bom' && $innerStatus.bomSelected" },
                    { 'btn_identifier': '_4_3', 'btn_msg': 'Add Item', 'btn_close': false, 'btn_color': 'btn-light', 'btn_fn':'addBomFromPopup', 'btn_visible_if': "$innerStatus.catalogue_section == 'bom'" },
                    { 'btn_identifier': '_4_4', 'btn_msg': 'Save', 'btn_close': false, 'btn_color': 'btn-light', 'btn_fn':'saveBomFromPopup', 'btn_visible_if': "$innerStatus.catalogue_section == 'bom' && $innerStatus.bomSelected" },
                    { 'btn_identifier': '_4_2', 'btn_msg': 'Cancel', 'btn_close': false, 'btn_color': 'btn-clear', 'btn_fn':'cancelClassManagmentFromPopup',  'btn_visible_if': "$innerStatus.catalogue_section == 'class_managment' && $innerStatus.classManagmentSelected" },
                    { 'btn_identifier': '_4_3', 'btn_msg': 'Add Item', 'btn_close': false, 'btn_color': 'btn-light', 'btn_fn':'addClassManagmentFromPopup', 'btn_visible_if': "$innerStatus.catalogue_section == 'class_managment'" },
                    { 'btn_identifier': '_4_4', 'btn_msg': 'Save', 'btn_close': false, 'btn_color': 'btn-light', 'btn_fn':'saveClassManagmentFromPopup', 'btn_visible_if': "$innerStatus.catalogue_section == 'class_managment' && $innerStatus.classManagmentSelected" },
                    // { 'btn_identifier': '_3_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    // { 'btn_identifier': '_3_2', 'btn_close': false, 'btn_confirm': false, 'btn_msg': 'Add 3D Model', 'btn_color': 'btn-light', 'btn_fn': 'addAsset3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },
                    // { 'btn_identifier': '_3_3', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationAsset3dModelFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveAsset3dModelFromPopup', 'btn_visible_if': "$innerStatus.asset_section == '3d-model'" },

                ];

                _this.listAiMachineSystem = [];
                _this.listAiMachineCompany = [];
                _this.listProduct = [];
                _this.listObjectClasses = [];

                var _config = {
                    'funzione': {
                        getSystemDNNTableParams: _this.getSystemDNNTableParams(),
                        getCompanyDNNTableParams: _this.getCompanyDNNTableParams(),
                        getDNNByCompanyAndFacilityType: _this.getDNNByCompanyAndFacilityType(),
                        getProductByCompanyAndProductGroup: _this.getProductByCompanyAndProductGroup(),
                        getProductTableParams: _this.getProductTableParams(),
                        getListProductGroupType: _this.getListProductGroupType(),
                        getBomTableParams: bomFactory.getBomTableParams(),
                        editItem: bomFactory.editItem(),
                        editClassItem: classManagmentFactory.editClassItem(),
                        getSystemClassParam: classManagmentFactory.getSystemClassParam(),
                        getObjectClasses: classManagmentFactory.getObjectClasses(),
                        
                    },

                    'size': 'xlg',
                    'title': 'Catalogues',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_catalogueData),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/catalogue_popup.html',
                    'innerStatus': {
                        'catalogue_section': 'dnn',
                    }
                }
                popup.openModalBody(_config);
            }            
    
            _this.showCatalogues = function(_customerId) {
                var _catalogueData = {
                    listAssetType: _this.listAssetType,
                    customerId: _customerId
                }

                _this.customerId = _customerId

                catalogueFactory.getListProductGroupByCompanyId(_identity.companyId, _res => {
                    _this.listProductGroup= _res;
                    _catalogueData.listProductGroup = _this.listProductGroup;

                    bomFactory.getListBomByCompanyId(_this.customerId, _res => {
                        _catalogueData.listBom = _res;
                        _cataloguesPopup(_catalogueData);
                    })

                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                })    
            }

            //-------------------------------------------SIGNAL PROPAGATION begin------------------------------------------------------------------------------------
            _this.showSignalPropagationMap = function(_asset) {
                //set the view, about asset, has shown
                _this.viewShown = _this.views.signalPropagationMap;

                signalPropagationFactory.showSignalPropagationMap(_this.signalPropagationMapId, _asset, _identity.companyId, 'wind-turbines');
            }        
            //-------------------------------------------SIGNAL PROPAGATION end------------------------------------------------------------------------------------


            //-------------------------------------------INVENTORY begin------------------------------------------------------------------------------------
            _this.showInventory = function(_asset)  {
                //set the view
                _this.viewShown = _this.views.inventory;

                _this.assetSelected = _asset;
                $scope.assetIdSelected = _asset.id;


                //This object hold all the info about the inventory
                _this.inventoryData = {
                    controllerName: _controllerName,
                    inspectingCompanyId: _identity.companyId,
                    asset: _this.assetSelected,
                    getInventoryStatusTypeByKey: inventoryFactory.getInventoryStatusTypeByKey(),
                    getInventoryItemTableParams: inventoryFactory.getInventoryItemTableParams(),
                    getListProduct: inventoryFactory.getListProduct(),
                    setInventoryProductName: inventoryFactory.setInventoryProductName(),
                    showInventoryItemDetails: inventoryFactory.showInventoryItemDetails(),
                    addInventoryItemExtraInfo: inventoryFactory.addInventoryItemExtraInfo(),
                    getInventoryItemExtraInfoLabelByKey: inventoryFactory.getInventoryItemExtraInfoLabelByKey(),
                    getProductIndicatorTypeByKey: inventoryFactory.getProductIndicatorTypeByKey(),
                    getInventoryGroupById: inventoryFactory.getInventoryGroupById(),
                    getStructureById: inventoryFactory.getStructureById(),
                };

                //Get all the info used into the inventory screen                
                inventoryFactory.init(_asset.id, _identity.companyId, 'wind-turbines', _res => {
                    _this.inventoryData.listInventoryItem= _res.listInventoryItem;
                    _this.inventoryData.listInventoryStatusType= _res.listInventoryStatusType;
                    _this.inventoryData.listInventoryItemExtraInfoLabel= _res.listInventoryItemExtraInfoLabel;
                    _this.inventoryData.listInventoryGroup= _res.listInventoryGroup;
                    _this.inventoryData.listProductGroupInventoryGroup= _res.listProductGroupInventoryGroup;
                    _this.inventoryData.arrayInventoryFieldByInventoryGroupId= _res.arrayInventoryFieldByInventoryGroupId;
                    _this.inventoryData.listProductGroup= _res.listProductGroup;
                    _this.inventoryData.listProductIndicatorType= _res.listProductIndicatorType;
                    _this.inventoryData.listAssetStructure = _res.listAssetStructure;
                    _this.inventoryData.listAssetTypeByKey = _res.listAssetTypeByKey;

                    _this.inventoryData.getInventoryItemTableParams.init(_this.inventoryData.listInventoryItem);
                    _this.inventoryData.getInventoryItemTableParams.refresh();

                });
            }
            
            var _showMarkerAreaRef;
            _this.editInventoryItem = function(_inventoryItem, _inventoryData) {
                inventoryFactory.editInventoryItem(_identity.companyId, _inventoryItem, _inventoryData);

                utility.callFunctionEveryTime("$('#_lapixxx').get(0)", 300, function() {                      
                    $("body>#_lapixxx_src").remove(); //remove the previous element moved under body

                    $("#_lapixxx_src").css("position", "absolute")
                    $("#_lapixxx_src").width($("body").width()/2)
                    $("#_lapixxx_src").height($("body").height()/2)
                    $("#_lapixxx_src").css("top", $("body").height())
                    $("#_lapixxx_src").appendTo($("body"))
                    $("#_lapixxx_src").css("visibility", "visible")

                    var sourceImage = document.getElementById("_lapixxx_src");                
                    setSourceImage(sourceImage);
        

                    var sampleImage = document.getElementById("_lapixxx");
                    var _markers = undefined;
                    if (_this.inventoryData.inventoryItemSelected.imageMarkers) {
                        _markers = JSON.parse(_this.inventoryData.inventoryItemSelected.imageMarkers);
                    }                        

                    if (_this.inventoryData.inventoryItemSelected.imageUrl) {
                        renderImageSrc(sampleImage, sourceImage, _markers);
                    }

                    sampleImage.removeEventListener("click", _showMarkerAreaRef );

                    sampleImage.addEventListener("click", _showMarkerAreaRef=function() {
                        _markers = undefined;
                        if (_this.inventoryData.inventoryItemSelected.imageMarkers) {
                            _markers = JSON.parse(_this.inventoryData.inventoryItemSelected.imageMarkers);
                        }                        
                        
                        showMarkerArea(sampleImage, sourceImage, _markers)
                    });

                })                

            }

            _this.deleteInventoryItem = function(_inventoryItem, _inventoryData) {
                inventoryFactory.deleteInventoryItem(_inventoryItem, _inventoryData);                
            }

            _this.addInventoryItem = function (_inventoryData) {
                inventoryFactory.addInventoryItem(_inventoryData)
            }

            //Close the add/edit screen's section of inventory's item 
            _this.clearInventoryItem = function(_inventoryData) {
                inventoryFactory.clearInventoryItem(_inventoryData);
            }

            _this.saveInventoryItem = function (_inventoryData) {
                inventoryFactory.saveInventoryItem(_inventoryData, _identity.companyId)
            }

            //Close the Inventory screen
            _this.closeInventory = function(_inventoryData, _customerId) {
                inventoryFactory.closeInventory(_inventoryData, _res => {
                    _this.showAssetList(_customerId)    
                })
            }

            _this.autoInventoryFromModel3D = function(_inventoryData) {
                inventoryFactory.autoInventoryFromModel3D(_inventoryData, _res => {
                    
                })
            }
            //-------------------------------------------INVENTORY end--------------------------------------------------------------------------------------


            var sourceImage, targetRoot; 
            var maState;
            // var maState = {"width":960,"height":485,"markers":[{"fillColor":"transparent","strokeColor":"#EF4444","strokeWidth":3,"strokeDasharray":"","opacity":1,"left":343,"top":131,"width":241,"height":104,"rotationAngle":0,"visualTransformMatrix":{"a":1,"b":0,"c":0,"d":1,"e":343,"f":131},"containerTransformMatrix":{"a":1,"b":0,"c":0,"d":1,"e":0,"f":0},"typeName":"FrameMarker","state":"select"},{"arrowType":"end","strokeColor":"#EF4444","strokeWidth":3,"strokeDasharray":"","x1":543,"y1":65,"x2":250,"y2":275,"typeName":"ArrowMarker","state":"select"}]};
    
            function setSourceImage(source) {
                sourceImage = source;
                targetRoot = $("body").get(0);
              }

            
            //   function checkMarkerAreaTarget(markerArea, callback) {
            //     if (markerArea.target.complete) {
            //         // $timeout(function() {
            //             callback()
            //         // }, 600)
            //     } else {
            //         $timeout(function() {
            //             checkMarkerAreaTarget(markerArea, callback)    
            //         },300)
            //     }
            //   }
              
            function renderImageSrc(target, source, state) {
                utility.callFunctionEveryTime('$("#_lapixxx_src").attr("src")!=""', 300, function() {
                    var markerArea = new markerjs2.MarkerArea(source);

                    markerArea.targetRoot = $("body").get(0);

                    markerArea.show();
                    if (state) {
                        markerArea.restoreState(state);
                    }

                    $(".__markerjs2_").css("visibility", "hidden");        

                    utility.callFunctionWithScopeEveryTime(markerArea, ".target.complete", 300, function() {
                        markerArea.render().then((imgUrl, state) => {
                            target.src = imgUrl;
                            markerArea.close();    
                        });

                    })
                    // checkMarkerAreaTarget(markerArea, function() {
                    //     markerArea.render().then((imgUrl, state) => {
                    //         console.log("-------------");

                    //         target.src = imgUrl;
                    //         markerArea.close();    
                    //     });

                    // })
                })
            }
    
            function showMarkerArea(target, source, state) {
                maState = state;

                var markerArea = new markerjs2.MarkerArea(source);
                // since the container div is set to position: relative it is now our positioning root
                // end we have to let marker.js know that
                markerArea.targetRoot = targetRoot;
    
                markerArea.addRenderEventListener((imgURL, state) => {
                    target.src = imgURL;
    
                    // save the state of MarkerArea
                    maState = state;
                    console.log(JSON.stringify(state));
    
                    // _this.inventoryData.inventoryItemSelected.imageBase64 = imgURL;
                    _this.inventoryData.inventoryItemSelected.imageMarkers = JSON.stringify(state);
    
                });

                // markerArea.addCloseEventListener((imgUrl) => {
                //     console.log("close....")
                // })
    
                markerArea.show();
    
                if (maState) {
                    markerArea.restoreState(maState);
                    
                    // markerArea.render().then((a, b) => {
                    //     console.log(a);

                    // });

                    // markerArea.close();
                  }    
    
                  $(".__markerjs2_").css("top", "25%");        
                  $(".__markerjs2_").css("left", "25%");        
                  $(".__markerjs2_toolbar-block:first").prepend('<div id="__markerjs2_drag" class="__markerjs2_toolbar_button"><img src="img/icons/move.png"/></div>')
                utility.callFunctionEveryTime("$('#__markerjs2_drag').get(0)", 300, function() {
                    $(".__markerjs2_").draggable();
                    $(".__markerjs2_").draggable('option','handle','#__markerjs2_drag');
                });
              }


            var assetMapperModelData = {}
            var assetMapperIFrameNative;

            _this.showAssetMapper = function(_asset) {
                _this.viewShownPrev = _this.viewShown;
                _this.viewShown = _this.views.assetMapper;


                    //remove old event listener
                    window.removeEventListener('message', $scope.assetMapperMessageRef, false )    
                    
                    $scope.assetMapperMessageRef = _this.assetMapperMessage()

                    //create new event listener
                    window.addEventListener('message', $scope.assetMapperMessageRef)   

                    $http.get(expanseConst.softeqUrl + "facility/info/"+_asset.publicId+"?liteToken=" + _identity.liteToken, {
                        withCredentials: true,
                        mode: 'cors',
                        contentType: 'text/plain',
                    }
                    ).success(res => {

                        assetMapperModelData = {
                            pathToAssetMapperModel: res.pathToAssetMapperModel,
                            latitude: res.latitude,
                            longtitude: res.longitude,
                            name: res.name,
                            publicId: res.publicId,
                            operationResult: res.operationResult,
                            assetType: res.assetType
                        }
                        
                        _this.getAssetMapperIframeUrl("#_asset-mapper");
                    }).error(err => {
                        console.log(err)
                    })
            }    

            _this.getAssetMapperIframeUrl = function(_id) {
                var _element = "$('"+_id+"').get(0)"
                utility.callFunctionEveryTime(_element, 200, function() {

                    $(_id).attr("src", $sce.trustAsResourceUrl(expanseConst.softeqUrl + "iframe/assetMapper?application=EMPLOYEE&amp;environment=QA&amp;baseUrl=https%3A%2F%2Fexpanseai.com"))
                })
            }

            _this.assetMapperMessage = function() {
                return function(event) {
                    var data;

                    try {
                        data = JSON.parse(event.data);
                    } catch(error){}

                    if (!data) {
                        return;
                    }


                    switch (data.command) {
                        case "initialise": 
                            myLog("initialise")
                            assetMapperIFrameNative = $('#_asset-mapper').get(0);
                            assetMapperIFrameNative.contentWindow.postMessage(
                                JSON.stringify({
                                    command: 'initialise'
                                }),
                                '*'
                            );
                            break;
                        case "assetMapperModelData":
                            analyzeStore.setAssetMapperModelData(data.data);
                            break;
                        case "getAllMapData": 
                            myLog("getAllMapData")
                            assetMapperIFrameNative = $('#_asset-mapper').get(0);
                            assetMapperIFrameNative.contentWindow.postMessage(
                                JSON.stringify({
                                    command: 'fillMapData',
                                    asset: assetMapperModelData
                                }),
                                '*'
                            );
                            break;
                    }
                }
            }        
                    

            _this.closeAssetMapper = function() {
                _this.viewShown = _this.viewShownPrev;
            }            

            //return a new list with all the listAsset's fields shown in the table
            //in Customer-Asset screen
            function _cleanListAssetForTableSearch(_list) {
                var _ret = [];
                if (_list) {
                    _list.forEach(e => {
                        var _item = {
                            id: e.id,
                            name: e.name,
                            description: e.description,
                            type: _this.listAssetTypeKey[e.facilityTypeId].name,
                            status: e.status!=null?_this.listAssetStatusTypeByKey[e.status].value:_this.listAssetStatusTypeByKey['unknown'].value
                        }

                        _ret.push(_item)
                    })
                }

                return _ret;
            }

            //return a new list with all the listEstate's fields shown in the table
            //in Customer-Asset screen
            function _cleanListEstateForTableSearch(_list) {
                var _ret = [];
                if (_list) {
                    _list.forEach(e => {
                        var _item = {
                            id: e.id,
                            name: e.name,
                            description: e.description,
                            status: e.status!=null?_this.listAssetStatusTypeByKey[e.status].value:_this.listAssetStatusTypeByKey['unknown'].value
                        }
                        if (e.listAsset) {
                            _item.listAsset = _cleanListAssetForTableSearch(e.listAsset);
                        }

                        _ret.push(_item)
                    })
                }

                return _ret;
            }

            //return a new list with all the listNetworkLink's fields shown in the table
            //in Customer-Asset screen
            function _cleanListNetworkLinkForTableSearch(_list) {
                var _ret = [];
                if (_list) {
                    _list.forEach(e => {
                        var _item = {
                            id: e.id,
                            name: e.name,
                            description: e.description,
                            status: e.status!=null?_this.listAssetStatusTypeByKey[e.status].value:_this.listAssetStatusTypeByKey['unknown'].value
                        }
                        if (e.listAsset) {
                            _item.listAsset = _cleanListAssetForTableSearch(e.listAsset);
                        }

                        _ret.push(_item)
                    })
                }

                return _ret;
            }


            //Watch the assetSearch variable
            //to make the filter on the element shown in the customer-asset table
            $scope.$watch("_assetCtrl.assetSearch", function(_new, _old) {
                if (_this.viewShown==_this.views.asset && _this.listAsset && _this.listAsset.length>0) {
                    var _listTmp = $filter('filter')(_this.listAssetCleaned, _new);
                    var _list = _this.listAsset.filter(e => _listTmp.find(t => t.id == e.id));

                    _this.assetTableParams = ngTableParams.default(_list);
                    _this.assetTableParams.reload();    
                } else if (_this.viewShown==_this.views.estate && _this.listEstate && _this.listEstate.length>0) {
                    var _listTmp = $filter('filter')(_this.listEstateCleaned, _new);
                    var _list = _this.listEstate.filter(e => _listTmp.find(t => t.id == e.id));

                    _this.estateTableParams = ngTableParams.default(_list);
                    _this.estateTableParams.reload();    
                } else if (_this.viewShown==_this.views.networkLink && _this.listNetworkLink && _this.listNetworkLink.length>0) {
                    var _listTmp = $filter('filter')(_this.listNetworkLinkCleaned, _new);
                    var _list = _this.listNetworkLink.filter(e => _listTmp.find(t => t.id == e.id));

                    _this.networkLinkTableParams = ngTableParams.default(_list);
                    _this.networkLinkTableParams.reload();    
                }
            })

            // _this.moveTablePagination = function(_idTable) {
            //     utility.moveTablePagination(_idTable);
            //     // utility.callFunctionEveryTime("$('#"+_idTable+" div[ng-table-pagination]').get(0)", 300, function() {
            //     //     var _idTableContainer = _idTable + 'Container'
            //     //     $("#"+_idTableContainer+" .main-table:first").css("height","calc(100% - 55px")
            //     //     $("#"+_idTableContainer+" div[ng-table-pagination]").addClass("main-table external-pagination")
            //     //     $("#"+_idTableContainer+" div[ng-table-pagination]").appendTo($("#"+_idTableContainer))    
            //     // })
            // }


            //-------------------------------------------------------------Dynamic CAD editor: BEGIN------------------------------------------------------------------------------------
            _this.showDynamicCADEditor = function(_asset) { 
                _this.viewShown = _this.views.dynamicCADEditor;

                $scope.dCadObject = dynamicCADFactory.newInstance($scope, _asset, _identity.company, _identity.id, true);

                $scope.dCadObject.initDataSource(_res => {
                    $scope.dCadObject.initScene("_editor_3d_editor_player");
                });
            };
            
            _this.closeEditor3DModel  = function(_dCadObject) {
                var _asset = _dCadObject.getAsset();
                
                //update the asset into the listAsset
                _this.listAsset.find((e, index) => {
                    if (e.id == _asset.id) {
                        _this.listAsset[index] = _asset;
                        return true;
                    }
                })  
                _this.showAssetList()               
                _dCadObject.close(); 
            }

            _this.saveScene3DModel = function(_dCadObject) {
                _dCadObject.saveScene();
            }

            _this.editAfter3DModel = function(_dCadObject) {

                $scope.dCadObject = dynamicCADFactory.newInstance($scope, _dCadObject.data.asset, _identity.company, _identity.id, true);

                $scope.dCadObject.initDataSource(_res => {
                    $scope.dCadObject.data.isAfterScreenVisible = true;
                    $scope.dCadObject.initScene("_editor_3d_editor_player_now");

                    if (!$scope.dCadObject.data.asset.model3dAfterId) {
                        $scope.dCadObject.cloneModel3D(_identity.id, _resClone => {
                            $scope.dCadObjectAfter = dynamicCADFactory.newInstance($scope, $scope.dCadObject.data.asset, _identity.company, _identity.id, false);
    
                            $scope.dCadObjectAfter.initDataSource(_res => {
                                $scope.dCadObjectAfter.initScene("_editor_3d_editor_player_after", undefined, _res => {
                                    // $scope.pickableObjects = $scope.dCadObject.data.pickableObjects;
                                    // $scope.pickableObjectsAfter = $scope.dCadObject.data.pickableObjects;

                                    _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);

                                    //Watch if something change into dCadObject 
                                    $scope.$watch('dCadObject.data.pickableObjects', (_new, _old) => {
                                        _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);
                                    })

                                    //Watch if something change into dCadObject 
                                    $scope.$watch('dCadObjectAfter.data.pickableObjects', (_new, _old) => {
                                        _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);
                                    })
    
                                });
                            });
                        });
                    } else {
                        $scope.dCadObjectAfter = dynamicCADFactory.newInstance($scope, $scope.dCadObject.data.asset, _identity.company, _identity.id, false);
    
                        $scope.dCadObjectAfter.initDataSource(_res => {
                            $scope.dCadObjectAfter.initScene("_editor_3d_editor_player_after", undefined, _res => {
                                // $scope.pickableObjects = $scope.dCadObject.data.pickableObjects;
                                // $scope.pickableObjectsAfter = $scope.dCadObject.data.pickableObjects;

                                _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);

                                //Watch if something change into dCadObject 
                                $scope.$watch('dCadObject.data.pickableObjects', (_new, _old) => {
                                    _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);
                                })

                                //Watch if something change into dCadObject 
                                $scope.$watch('dCadObjectAfter.data.pickableObjects', (_new, _old) => {
                                    _updateListChanges($scope.dCadObject, $scope.dCadObjectAfter);
                                })

                            });
                        });
                    }
    
                });
            }

            function _updateListChanges(_now, _after) {
                _this.dCadChangesInfo = dynamicCADFactory.getListChanges(_now, _after)
                utility.scopeApply($scope);
                console.log("something changed", _this.dCadChangesInfo)
            }

            // _this.showDynamicCADEditor = function(_asset) { 
            //     _this.viewShown = _this.views.dynamicCADEditor;

            //     _this.assetSelected = _asset;
            //     $scope.assetIdSelected = _asset.id;

            //     //Contains all the info about the D-CAD screen
            //     _this.dynamicCADInfo = {
            //         isAfterScreenVisible: false,
            //         asset: _asset,
            //     }
            //     //Contains all the info about the asset's 3D Model
            //     _this.dynamicCADNowInfo = {}
            //     //Contains all the info about the future asset's 3D Model
            //     _this.dynamicCADAfterInfo = {}

            //     //Contains all the info about the changes between main and after 3D Models
            //     _this.dynamicCADChangesInfo = {}

            //     dynamicCADFactory.setAsMain3DModel(_this.dynamicCADNowInfo);
            //     dynamicCADFactory.init('_editor_3d_editor_player', $scope, _this.dynamicCADNowInfo, _asset, _identity.companyId, _asset.model3dId,  _res => {
            //     });
            //     dynamicCADFactory.init('_editor_3d_editor_player_after', $scope, _this.dynamicCADAfterInfo, _asset, _identity.companyId, _res => {
            //     });

            // } 

            // _this.selectTower = function(_params ) {
            //     // _this.dynamicCADInfo.towerSelected = _params.tower;
            //     dynamicCADFactory.changeTower(_params.info, _params.tower, _assetSelected, _inspectingCompanyId);
            // }

            // _this.selectHeadframe = function(_params) {
            //     // _this.dynamicCADInfo.headframeSelected = _headframe;
            //     dynamicCADFactory.changeHeadframe(_params.info, _params.headFrame);
            // } 

            // _this.selectAntenna = function(_params) {
            //     // _this.dynamicCADInfo.antennaSelected = _params.antenna;
            //     dynamicCADFactory.selectAntenna(_params.info, _params.antenna, _params.attachDotId, _res => {
            //         _dynamicCADChanges();
            //     }); 
            // } 

            // _this.deleteAntenna = function(_params) {
            //     dynamicCADFactory.deleteAntenna(_params.info, _params.clickNode, _res => {
            //         _dynamicCADChanges();
            //     });
            // }

            // _this.transformTranslation = function(_info, _objectSelected) {
            //     _info.objectSelected = _objectSelected
            //     dynamicCADFactory.setTransformTranslation(_info, [0, _info.objectSelected.translation.axisY-_info.objectSelected.translation.axisYPrev,0])
            //     _info.objectSelected.translation.axisYPrev = _info.objectSelected.translation.axisY
            // }


            // _this.transformTranslationCompass = function(_info, _object) {
            //     _info.object = _object
            //     dynamicCADFactory.setTransformTranslationCompass(_info, [0, _info.object.translation.axisY-_info.object.translation.axisYPrev,0])
            //     _info.object.translation.axisYPrev = _info.object.translation.axisY
            // }

            // _this.transformRotation = function(_info, _objectSelected) {
            //     _info.objectSelected = _objectSelected
            //     dynamicCADFactory.setTransformRotation(_info, [_info.objectSelected.rotation.axisX-_info.objectSelected.rotation.axisXPrev, _info.objectSelected.rotation.axisY-_info.objectSelected.rotation.axisYPrev,0])
            //     _info.objectSelected.rotation.axisXPrev = _info.objectSelected.rotation.axisX
            //     _info.objectSelected.rotation.axisYPrev = _info.objectSelected.rotation.axisY
            // }

            // _this.saveFromEditor3DModel = function(_info) {
            //     dynamicCADFactory.saveScene(_info, _identity.id);
            // }

            // _this.closeEditor3DModel  = function(_info) {
            //     //update the asset into the listAsset
            //     _this.listAsset.find((e, index) => {
            //         if (e.id == _info.asset.id) {
            //             _this.listAsset[index] = _info.asset;
            //             return true;
            //         }
            //     })  
            //     _this.showAssetList()                
            // }

            // _this.dynamicCADPropertiesPanelToggle = function(_info) {
            //     _info.isPropertiesPanelShown = !_info.isPropertiesPanelShown;
            // }
            // _this.moveCompass = function(_params) {
            //     dynamicCADFactory.moveCompass(_params.info, _params.event);
            // }

            // _this.editAfter3DModel = function(_info) {
            //     _info.isAfterScreenVisible = true;

            //     dynamicCADFactory.setAsMain3DModel(_this.dynamicCADNowInfo);
            //     dynamicCADFactory.init('_editor_3d_editor_player', $scope, _this.dynamicCADNowInfo, _info.asset, _identity.companyId, _info.asset.model3dId, _res => {
            //     });

            //     if (!_info.asset.model3dAfterId) {
            //         dynamicCADFactory.cloneModel3D(_identity.id, _info.asset.model3dId, _info.asset.id, _resClone => {
            //             _info.asset = _resClone.facility;
                        
            //             dynamicCADFactory.init('_editor_3d_editor_player_after', $scope, _this.dynamicCADAfterInfo, _info.asset, _identity.companyId, _info.asset.model3dAfterId, _res => {
            //             });    
    
            //         })
            //     } else {
            //         dynamicCADFactory.init('_editor_3d_editor_player_after', $scope, _this.dynamicCADAfterInfo, _info.asset, _identity.companyId, _info.asset.model3dAfterId, _res => {
            //         });    
            //     }
            // }

            // _this.confirmModel3DAfter = function(_info) {
            //     dynamicCADFactory.confirmModel3DAfter(_identity.id, _info.asset.id, _resConfirm => {
            //         _info.asset = _resConfirm.facility;
                    
            //         _this.closeEditor3DModel(_info)
            //     });
            // }

            // function _dynamicCADChanges() {
            //     if (_this.dynamicCADInfo.isAfterScreenVisible) {
            //         _this.dynamicCADChangesInfo = dynamicCADFactory.getListChanges(_this.dynamicCADNowInfo, _this.dynamicCADAfterInfo)
            //         // if(!$scope.$$phase) {
            //         //     $scope.$apply();
            //         // }
            //         utility.scopeApply($scope);
            //     }
            // }


            //-------------------------------------------------------------Dynamic CAD editor: END--------------------------------------------------------------------------------------


            //---------------------------------- MASTER LIST VISUALIZATION START--------------
            function _masterListPopup(_masterData) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.master_list_section == 'main'" },
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Save Template', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.master_list_section == 'main'", 'btn_fn': 'addNewISCTemplate'},
                    { 'btn_identifier': '_3_1', 'btn_msg': 'Ok', 'btn_color': 'btn-light', 'btn_visible_if': "$innerStatus.master_list_section == 'linkage'" },
                ];

                _this.listMLQuestionSystem = [];
                _this.listProduct = [];

                var _config = {
                    'funzione': {
                        getMasterListTableParams: _this.getMasterListTableParams(),
                        getCompanyDNNTableParams: _this.getCompanyDNNTableParams(),
                        getMLQuestionFacilityType: _this.getMLQuestionFacilityType(),
                        getProductByCompanyAndProductGroup: _this.getProductByCompanyAndProductGroup(),
                        getProductTableParams: _this.getProductTableParams(),
                        getListProductGroupType: _this.getListProductGroupType(),
                        getFacilityTypeValue: _this.getFacilityTypeValue(),
                        getISCListTableParams: _this.getISCListTableParams(),
                        getTemplateQuestions: _this.getTemplateQuestions(),
                        getFacilityStatusPhase: _this.getFacilityStatusPhase(),
                        getIscQuestionCategory: _this.getIscQuestionCategory()
                    },

                    'size': 'xlg',
                    'title': 'Checklist Management',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_masterData),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/masterlist_popup.html',
                    'innerStatus': {
                        'master_list_section': 'main',
                    }
                }
                popup.openModalBody(_config);
            }    


            _this.getMLQuestionFacilityType = function() {
                var _facilityTypeSelectedPrev;
                return function(_popup) {
                    if (_popup.data.facilityTypeSelected && _facilityTypeSelectedPrev != _popup.data.facilityTypeSelected) {
                        _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                        _popup.updateData(_popup.data);
   
                        // catalogueFactory.getListAiMachineBySystemAndFacilityTypeId(_popup.data.facilityTypeSelected, _res => {

                        _popup.data.listQuestions = _popup.data.listMasterQuestions.filter((i=>i.facilityTypeId == _popup.data.facilityTypeSelected));
                        _popup.data.refreshSystemDNNTableParams = true;
                        _popup.data.listISCQuestion = [];
                        
                    }

                }
            }


            _this.getIscTemplateTableParam = function () {
                //ML TABLE
                var _ngTableParams;
                return {
                    init: function (_popup) {
                        _popup.data.refreshMasterListableParams = false;
                        _ngTableParams = ngTableParams.default(_popup.data.listQuestions);
                        return _ngTableParams;                    
                    },
                    getData: function () {
                        // return _this.getFacilityTypeValue(_ngTableParams);
                        return _ngTableParams
                    },
                    addData: function(_popup, id) {
                        var objIndex = _popup.data.listQuestions.findIndex((obj => obj.questionId == id));
                        _popup.data.listISCQuestion.push(_popup.data.listQuestions[objIndex])
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            }


            
            
            _this.getMasterListTableParams = function () {
                //ML TABLE
                var _ngTableParams;
                return {
                    init: function (_popup) {
                        _popup.data.refreshMasterListableParams = false;
                        _ngTableParams = ngTableParams.default(_popup.data.listQuestions);
                        return _ngTableParams;                    
                    },
                    getData: function () {
                        // return _this.getFacilityTypeValue(_ngTableParams);
                        return _ngTableParams
                    },
                    addData: function(_popup, id) {
                        var objIndex = _popup.data.listQuestions.findIndex((obj => obj.questionId == id));

                        //check if there are alreaady asset with different asset Category
                        var assetCategoryToAdd = _popup.data.listQuestions[objIndex].facilityTypeId
                        var objIndexAsssetCategory = _popup.data.listISCQuestion.findIndex((obj => obj.facilityTypeId == assetCategoryToAdd));
                        if (objIndexAsssetCategory == -1 & _popup.data.listISCQuestion.length >0){
                            myAlert("Please add Questions with the same Aasset Category", 'info');
                        }
                        else{
                            _popup.data.listISCQuestion.push(_popup.data.listQuestions[objIndex])
                        }
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            }


            _this.getISCListTableParams = function () {
                //ISC TABLE
                var _ngTableParams;
                return {
                    init: function () {
                        _ngTableParams = ngTableParams.default(_this.listISCQuestion);
                        return _ngTableParams;                    
                    },
                    getData: function () {
                        // return _this.getFacilityTypeValue(_ngTableParams);
                        return _ngTableParams
                    },
                    removeData: function(_popup, id) {
                        var objIndex = _popup.data.listISCQuestion.findIndex((obj => obj.questionId == id));
                        _popup.data.listISCQuestion.splice(objIndex,1);
                    }
                }
            }

            _this.getFacilityTypeValue = function(){
                return function(facilityTypeId){
                    var objIndex = _this.listAssetType.findIndex((obj => obj.id == facilityTypeId));
                    return  _this.listAssetType[objIndex].name;                    
                }
            }

            _this.getFacilityStatusPhase= function(){
                return function(assetStatusPhase){
                    var objIndex = _this.listAssetStatusPhase.findIndex((obj => obj.key == assetStatusPhase));
                    return  _this.listAssetStatusPhase[objIndex].value;                    
                }
            }


            _this.getIscQuestionCategory= function(){
                return function(iscQuestionCategoy){
                    var objIndex = _this.listIscQuestionCategory.findIndex((obj => obj.key == iscQuestionCategoy));
                    return  _this.listIscQuestionCategory[objIndex].value;                    
                }
            }


            _this.getTemplateQuestions = function(){
                var iscTemplateSelectedPrev;
                return function(_popup){
                    if (_popup.data.iscTemplateSelected &&iscTemplateSelectedPrev != _popup.data.iscTemplateSelected){
                        assetServices.getTemplateQuestions(_popup.data.iscTemplateSelected, _res => {
                            _popup.data.listISCTemplateQuestions = _res['questions']
                            var objIndex = _this.listISCTemplate.findIndex((obj => obj.iscTemplateId == _popup.data.iscTemplateSelected));
                            _popup.data.iscTemplateAssetPhaseSelected = _this.listISCTemplate[objIndex].statusPhase
                            _popup.data.iscTemplateAssetCategorySelected = _this.listISCTemplate[objIndex].facilityTypeId

                        })
                        iscTemplateSelectedPrev = _popup.data.iscTemplateSelected;
                    }
                }
            }
            // _this.getFacilityTypeValue = function (_ngTableParams){

            //     //prepare the Master list table
            //     if (typeof _ngTableParams !==  'undefined'){
                    
            //         _ngTableParams.data.forEach(e => { 
            //             //check assettype
            //             if (typeof e !==  'undefined'){
            //                 var objIndex = _this.listAssetType.findIndex((obj => obj.id == e.facilityTypeId));
            //                 if (objIndex != -1){
            //                     e.facilityTypeId = _this.listAssetType[objIndex].name;
            //                 }
            //             }
            //         })
            //         return _ngTableParams
            //     }
            //     else{
            //         _ngTableParams
            //     }
            // }


            $scope.addNewISCTemplate = function(_popup) {
                //function to save the created template
                assetServices.saveISCTemplate(_popup.data.listISCQuestion, _this.customerId, _popup.data.template_name, _popup.data.assetStatusPhaseSelected, _res => {
                    _success ? _success(_res) : null;

                    myLog("Template Saved");
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            } 

            _this.dropDownSettingsAssetStatus = {
                template: _template, showCheckAll: false, showUncheckAll: false,
                smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
            };

            

            _this.showMasterList = function(_customerId) {

                _this.customerId = _customerId

                assetServices.masterListRetrieve(_customerId, _res => {
                    
                    assetServices.iscTemplateRetrieve(_customerId, _resISC => {
                        _this.listQuestions = _res.masterListQuestionsDto;
                        _this.listISCTemplate = _resISC.iscTemplateDto;
                    
                        _this.listISCQuestion = [];
                        
                        var _masterData = {
                            listAssetType: _this.listAssetType,
                            listMasterQuestions: _res.masterListQuestionsDto,
                            listISCQuestion: _this.listISCQuestion,
                            listQuestions: _res.masterListQuestionsDto,
                            listAssetStatusPhase: _this.listAssetStatusPhase,
                            listIscQuestionCategory: _this.listIscQuestionCategory,
                            listISCTemplate: _this.listISCTemplate
                            
                        }


                        _masterListPopup(_masterData);
                    })
              })

            }
            
            //---------------------------------- MASTER LIST VISUALIZATION ENDs--------------

            //-----------------------------------------------PROJECT begin-----------------------------------------------------------
            _this.showProjectList = function () {
                _this.viewShown = _this.views.project;

                if (_this.listAsset.length>0) {
                    if (!_this.projectObject) {          
                        _this.projectObject = projectFactory.newInstance({
                            listContainerId: '_assetListPanel',
                            companyId: _this.customerIdSelected,
                            listAsset: _this.listAsset,
                            controllerName: _controllerName,
                            userId: _identity.id,
                            scope: $scope,
                        });
        
                        _this.projectObject.init();    
                    } else {
                        _this.projectObject.getProjectsByCompanyId(_this.customerIdSelected, _this.listAsset);
                    }
                
                } else {
                    assetServices.getCustomerAssets(_identity.id, false, _this.customerIdSelected, _res => {
                        assetServices.getAssetTypes(_res1 => {
                            var _listAssetTypeKey = utility.getArrayByField(_res1, 'id');
                            var _listAsset = _res.filter(e => e.facilityTypeId && _listAssetTypeKey[e.facilityTypeId].key != 'estate' && _listAssetTypeKey[e.facilityTypeId].key != 'networkLink');
    
                            if (!_this.projectObject) {          
                                _this.projectObject = projectFactory.newInstance({
                                    listContainerId: '_assetListPanel',
                                    companyId: _this.customerIdSelected,
                                    listAsset: _listAsset,
                                    controllerName: _controllerName,
                                    userId: _identity.id,
                                    scope: $scope,
                                });
                
                                _this.projectObject.init();    
                            } else {
                                _this.projectObject.getProjectsByCompanyId(_this.customerIdSelected, _listAsset);
                            }
            
                        });
                    })
                }
            }

            $scope.validationProjectFromPopup = function (_popup) {
                var _ret =  _this.projectObject.validationProjectFromPopup(_popup)
                return _ret;
            }

            $scope.saveProjectFromPopup = function (_popup) {
                _this.projectObject.saveProjectFromPopup(_popup)
            }

            //-----------------------------------------------PROJECT end-----------------------------------------------------------



            // ---------------------------------------------MHD----------------------------------------------
            function _mhdReportFlush(_popup, _res) {
                $timeout(function() {
                    const blob = new Blob([_res], {type: 'text/csv'})

                    var _filename = _popup.data.listReport[_popup.data.iscReportSelected].reportKey + '_'+utility.formatDate(new Date(), 'dd-mm-yyyy') + '.csv';
                    saveAs(blob, _filename);         
                    myAlert("Processing Complete", 'info');                        
                }, 200) 
            }

            $scope.ReportCreationMhD = function(_popup){
                _popup.updateData(_popup.data);
                var _reportKey = _popup.data.listReport[_popup.data.iscReportSelected].reportKey;

                var _alert = myAlert("Report creation in progress.....", 'info', 0);                    
                switch (_reportKey) {
                    case 'allObservationReport':
                        assetServices.getAllObservationReportMHD(_popup.data.customerId, _popup.data.userId, _identity.isInspector, _res => {
                            _alert.close();
                            _mhdReportFlush(_popup, _res);
                        })                        
                        break;
                    case 'severityByPoleReport':
                        assetServices.getSeverityByPoleReportMHD(_popup.data.customerId, _popup.data.userId, _identity.isInspector, _res => {
                            _alert.close();
                            _mhdReportFlush(_popup, _res);
                        })                    
                        break;
                    case 'incrementalInspectionReport':
                        assetServices.getIncrementalInspectionReportMHD(_popup.data.customerId, _popup.data.userId, _identity.isInspector, _popup.data.begin, _popup.data.end, _res => {
                            _alert.close();
                            _mhdReportFlush(_popup, _res);
                        })                    
                        break;                        
                    case 'poleTopClassificationReport':
                        assetServices.getPoleTopClassificationReportMHD(_popup.data.customerId, _popup.data.userId, _res => {
                            _alert.close();
                            _mhdReportFlush(_popup, _res);
                        })                        
                        break;
                    default:
                        //function to save run the creation of checklists
                        assetServices.reportCreationMhD(_popup.data.iscReportSelected, _popup.data.customerId,  _res => {
                            _alert.close();
                            _mhdReportFlush(_popup, _res);            
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        })
                        break;
                }
                // if (_reportKey=='allObservationReport' || _reportKey=='severityByPoleReport' || _reportKey=='incrementalInspectionReport') {

                //     if (_reportKey=='allObservationReport') {
                //     } else if (_reportKey=='severityByPoleReport') {
                //     } else if (_reportKey=='incrementalInspectionReport') {
                //     }

                // } else {
                // }
            }
 

            function _mhdReportPopup(_customerId) {

                _this.listReport = [{'reportName':'LocationReport.csv', 'reportId': 0, 'reportKey': 'LocationReport'}, 
                                    {'reportName':'AnomalyReport.csv', 'reportId': 1, 'reportKey': 'AnomalyReport'},
                                    {'reportName':'allObservationReport.csv', 'reportId': 2, 'reportKey': 'allObservationReport'},
                                    {'reportName':'severityByPoleReport.csv', 'reportId': 3, 'reportKey': 'severityByPoleReport'},
                                    {'reportName':'incrementalInspectionReport.csv', 'reportId': 4, 'reportKey': 'incrementalInspectionReport'},
                                    {'reportName':'poleTopClassificationReport.csv', 'reportId': 5, 'reportKey': 'poleTopClassificationReport'}
                                ]

                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Close', 'btn_confirm': false, 'btn_color': 'btn-clear'},
                    { 'btn_identifier':'_1_2', 'btn_msg': 'Generate Report',  'btn_close': false, 'btn_confirm': false, 'btn_color': 'btn-primary', 'btn_fn': 'ReportCreationMhD'},
                ];

                var _data = {
                    listReport: _this.listReport ,
                    customerId: _customerId,
                    userId: _identity.id,
                }

                var _config = {
                    'size': 'md',
                    'title': 'Report Generation',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_data),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset/popup/mhd_report.html',
                }
                
                popup.openModalBody(_config);
            }            
    
            _this.showMhdReport = function(_customerId) {

                _mhdReportPopup(_customerId);
            
            }
            
            // ---------------------------------------------MHD Start----------------------------------------
        }

        

    ]);