//Write here all the services about AssetData
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("assetDataServices", ['myHttp', 'buildUrl', 'myLog', '$http', 'serviceUtility', function(myHttp, buildUrl, myLog, $http, serviceUtility) {

        return  {
            getAssetDataLatestUploadedByCustomerId: function(_customerId, _userId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityData/latestUploaded/byCustomer/'+_customerId+'/byUserId/' + _userId);
                var _errorMsg =  "I wasn't able to get the dataset's list of latest uploaded dataset for customer: '"+_customerId+"'";

                serviceUtility.runGet("getAssetDataLatestUploadedByCustomerId", _url, _errorMsg, _res => _success(_res.listFacilityDataDto), _error);
            },
            getAssetsAssetData: function(_assetId, _isInspector, _success, _error) {
                var _url = buildUrl( '/expanse/facilityData/byFacility/' + _assetId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    var _listFacilityData = _res.listFacilityDataDto;

                    _url = buildUrl( '/expanse/report/byFacility/' + _assetId);
                    myHttp.get(_url).success( _res => {    
                        myLog("Result from " + _url, _res);
                        
                        var _item;
                        _res.listReportDto.forEach(e => {
                            if (_isInspector || (!_isInspector && e.status!='DRAFT')) {
                                _item = e;
                                _item.fileType = 'report';
                                _item.createdAt = e.createdDate;
                                _item.description = e.name;

                                _listFacilityData.push(_item)
                            }
                        })
                        _success(_listFacilityData.sort((a,b) => {return a.createdAt<b.createdAt?1:a.createdAt>b.createdAt?-1:0}));
        
                    }).error( _err => {
                        myLog("Error from " + _url, _err.data);
                        _err.data.message = "I wasn't able to get the report data about the asset: '"+_assetId+"'";
                        _error(_err);
                    } );    
    
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data about the asset: '"+_assetId+"'";
                    _error(_err);
                } );    
            },

            saveAssetData: function(_userId, _assetData, _success, _error) {
                // var _url = buildUrl('/expanse/facilityData/' + _userId);

                // myHttp.post(_url, JSON.stringify(_assetData)).success(_res => {
                //     myLog("Result from " + _url, _res);
                //     _success(_res.facilityDataDto);

                // }).error( _err => {
                //     myLog("Error from " + _url, _err.data);

                //     if (!myHttp.analizeBackendError(_err)) {
                //         _err.data.message = "I wasn't able to save this asset-data";
                //     }

                //     _error(_err);
                // } );

                var _url = buildUrl('/expanse/facilityData/' + _userId);
                var _errorMsg = "I wasn't able to save this asset-data";

                serviceUtility.runPost("saveAssetData", _url, _assetData, _errorMsg, _res => _success(_res.facilityDataDto), _error);    

            },

            saveAssetDataDetails: function(_userId, _assetDataDetails, _success, _error) {
                var _url = buildUrl('/expanse/facilityDataDetails/' + _userId);

                myHttp.post(_url, JSON.stringify(_assetDataDetails)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDataDetailsDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this asset-data-details";
                    }

                    _error(_err);
                } );
            },

            getAssetDataById: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityData/byId/' + _assetDataId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDataDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data about id: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },
            
            getAssetDataDetails: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetails/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataDetailsDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data details about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },

            getAssetDataDetailsTag: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetailsTag/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataDetailsTagDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data details tag about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },

            getAssetDataDetailsTagTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetailsTagTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataDetailsTagTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data details tag types ";
                    _error(_err);
                } );    
            },

            getAssetDataMethane: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataMethane/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataMethaneDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data methane about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },

            getThermalImages: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/thermalImages/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listThermalImageDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the thermal images about facilityDataId:" + _assetDataId;
                    _error(_err);
                } );    
            },

            getAssetDataFileTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/facilityDataFileTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataFileTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data file types list";
                    _error(_err);
                } );    
            },

            check3dPhotogrammetry: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/check3dPhotogrammetry/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the check3dPhotogrammetry api";
                    _error(_err);
                } );    
            },

            generate3DPhotogrammetry: function(_data, _success, _error) {
                var _url = buildUrl( '/pix4d/generate3DPhotogrammetry');
    
                myHttp.post(_url, JSON.stringify(_data)).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the generate3DPhotogrammetry api";
                    _error(_err);
                } );    
            },

            getFileUploadTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/fileUploadTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFileUploadTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the fileUploadTypes api";
                    _error(_err);
                } );    
            },

                   

            getAiComponent: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/componentType/byCompanyId/' + _companyId + '/byFacilityTypeId/' + _facilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listComponentTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the component types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },

            getAiComponentByFacilityByCompany: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/componentType/byFacilityTypeId/' + _facilityTypeId + '/byCompanyId/' + _companyId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listComponentTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the component types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },


            getSpinModelClustering: function(_data,  _success, _error){
                var _url = '/api/SpinModelClustering';

                myHttp.post(_url, JSON.stringify(_data)).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the getSpinModelClustering api";
                    _error(_err);
                } );    

            },

            getImageOrientation: function(_data,  _success, _error){
                var _url = '/api/ImageOrientation';
                myHttp.post(_url, JSON.stringify(_data)).success( _res => {    
                    myLog("Result from " + _url, _res);

                    var element = document.getElementById('compass_bearing')
                    var rotation = _res[0].toFixed(2) + '°';
                    var rotation_des = _res[0] + 'deg';
                    element.setAttribute('style','transform:rotate('+ rotation_des + ')');
                    _success(rotation);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the getSpinModelClustering api";
                    _error(_err);
                } );    
            },

            getAiAnomaly: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/anomalyType/byCompanyId/' + _companyId + '/byFacilityTypeId/' + _facilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAnomalyTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the anomaly types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },

            getAiAnomalyByFacilityByCompany: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/anomalyType/byFacilityTypeId/' + _facilityTypeId + '/byCompanyId/' + _companyId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAnomalyTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the anomaly types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },

            getPlfFunctions: function(_companyId, type, FacilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/plf/byCompanyId/' + _companyId + '/byType/' + type + '/byFacilityTypeId/' + FacilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listPlfDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "No PLF available for companyId:" + _companyId ;
                    _error(_err);
                } );    
            },

            getInspectionReport: function(_assetDataId, _assetId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityData/inspectionReport/previous/'+_assetDataId+'/byFacilityId/'+ _assetId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDataDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "No facility data inspection report previous to:" + _assetDataId + " found" ;
                    _error(_err);
                } );    
            },

            saveMatterport: function(_userId, _matterport, _success, _error) {
                var _url = buildUrl('/expanse/facilityData/matterport/' + _userId);

                myHttp.post(_url, JSON.stringify(_matterport)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDataDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this matterport";
                    }

                    _error(_err);
                } );
            },

            getFacilityDataDetailsById: function(_FacilityDataDetailsId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetails/byId/' + _FacilityDataDetailsId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDataDetailsDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data about id: '"+_FacilityDataDetailsId+"'";
                    _error(_err);
                } );    
            },

            getTextFromImageById: function(_facilityDataDetailsId, _success, _error) {
                var _url = buildUrl( '/googleImage/extractTextFromImage/byFacilityDataDetailsId/' + _facilityDataDetailsId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to extract text from the asset data details id: '"+_facilityDataDetailsId+"'";
                    _error(_err);
                } );    
            },
            extractInfoFromBarcodeAndQrcode: function(_data, _success, _error) {
                var _url = buildUrl( '/expanse/barAndQrCodeReader');
                var _errorMsg = "I wasn't able read the barcode/qrcode in the image";

                serviceUtility.runPost("extractInfoFromBarcodeAndQrcode", _url, _data, _errorMsg, _res => _success(_res.result), _error);
            },



            getAccessTokenAutodesk: function(id, _success, _error) {
                var _url = '/api/get_autodesk_info';

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res['access_token']);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to extract text from the asset data details id'";
                    _error(_err);
                } );    
            },
            getChecklist: function(_facilityDataId, _success, _error) {
                var _url = buildUrl( '/expanse/checkListQuestions/byFacilityDataId/' + _facilityDataId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listCheckListQuestionsDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the checklist for facility_data_id: '"+_facilityDataId+"'";
                    _error(_err);
                } );    
            },
            deleteDataset: function(_listDataset, _success, _error) {
                var _url = buildUrl('/expanse/deleteDatasets');

                myHttp.post(_url, JSON.stringify(_listDataset)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success();

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },
            orthoMosaicVal: function(Data, _success, _error) {
                var _url = '/api/orthomosaicval';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },
            orthoMosaicGen: function(Data, _success, _error) {
                var _url = '/api/orthomosaic';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },
            getPresignedUrl: function(_facilityDataId, _timeoutMills, _success, _error) {
                var _url = buildUrl('/awss3/getPresignedUrl/byFacilityDataId/' + _facilityDataId + "/timeout/" + _timeoutMills);
                var _errorMsg = "I wasn't able to get the aws presigned url for the facilityDataId: " +_facilityDataId;

                serviceUtility.runGet("getPresignedUrl", _url, _errorMsg, _res => _success(_res.presignedUrl), _error);
            },

            renameEngineerReport: function(_userId, _assetData, _success, _error) {
                var _url = buildUrl('/expanse/engineerReport/rename/' + _userId);
                var _errorMsg = "I wasn't able to rename the Engineer Report";

                serviceUtility.runPost("renameEngineerReport", _url, _assetData, _errorMsg, _res => _success(_res.reportDto), _error);    
            },
            deleteEngineerReport: function(_userId, _assetData, _success, _error) {
                var _url = buildUrl('/expanse/engineerReport/delete/' + _userId);
                var _errorMsg = "I wasn't able to delete the Engineer Report";

                serviceUtility.runPost("deleteEngineerReport", _url, _assetData, _errorMsg, _res => _success(_res.reportDto), _error);    
            },
            getPresignedUrl: function(_facilityDataId, _timeoutMills, _success, _error) {
                var _url = buildUrl('/awss3/getPresignedUrl/byFacilityDataId/' + _facilityDataId + "/timeout/" + _timeoutMills);
                var _errorMsg = "I wasn't able to get the aws presigned url for the facilityDataId: " +_facilityDataId;

                serviceUtility.runGet("getPresignedUrl", _url, _errorMsg, _res => _success(_res.presignedUrl), _error);
            },

            retrieveQAISC: function(checklistID, _success, _error) {
               
                var _url = '/api/createChecklistISCTemplate?checklistID=' + checklistID;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },

            updateQAISC: function(Data, _success, _error) {
                var _url = '/api/updateQAChecklist';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            updateDataType: function(Data, _success, _error) {
                var _url = '/api/updateDataType';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            runPostProcessing: function(Data, _success, _error) {
                var _url = '/api/forcePostProcessing';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            removeAssetData: function(Data, _success, _error) {
                var _url = '/api/removeAssetData';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            generatePix4dProjectShareToken: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/pix4d/generatePix4dProjectShareToken/byFacilityDataId/' + _assetDataId);
                var _errorMsg =  "I wasn't able to generate the pix4d share token by: '"+_assetDataId+"'";

                serviceUtility.runPost("generatePix4dProjectShareToken", _url, null, _errorMsg, _res => _success(_res), _error);
            },
            deletePix4dShareToken: function(_shareToken, _success, _error) {
                var _url = buildUrl( '/pix4d/deleteShareToken/' + _shareToken);
                var _errorMsg =  "I wasn't able to delete the pix4d share token";

                serviceUtility.runDelete("deletePix4dShareToken", _url, _errorMsg, _res => _success(_res), _error);
            },

            labelAnalysis: function(Data, _success, _error) {
                var _url = '/api/labelAnalysis';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            humanEye: function(Data, _success, _error) {
                var _url = '/api/humanEye';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            exportDataset: function(Data, _success, _error) {
                var _url = '/api/exportDataset';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            exportDatasetAnalyzed: function(Data, _success, _error) {
                var _url = '/api/exportDatasetAnalyzed';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            exportDatasetAnalyzedDropBox: function(Data, _success, _error) {
                var _url = '/api/exportDatasetAnalyzedDropBox';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            saveTiltAngleImage: function(Data, _success, _error) {
                var _url = '/api/saveTiltAngleImage';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            imageChangeDetection: function(Data, _success, _error) {
                var _url = '/api/imageChangeDetection';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            GetThermalImage: function(Data, _success, _error) {
                var _url = '/api/GetThermalImage';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            Fingerprinting_update_db: function(Data, _success, _error) {
                var _url = '/api/Fingerprinting_update_db';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            Fingerprinting_gps: function(Data, _success, _error) {
                var _url = '/api/Fingerprinting_gps';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            retrieve_single_image_finger: function(Data, _success, _error) {
                var _url = '/api/retrieve_single_image_finger';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            Fingerprinting: function(Data, _success, _error) {
                var _url = '/api/Fingerprinting';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            retrieve_single_image_finger: function(Data, _success, _error) {
                var _url = '/api/retrieve_single_image_finger';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            saveTiltAngleImage: function(Data, _success, _error) {
                var _url = '/api/saveTiltAngleImage';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            autoInventoryB: function(Data, _success, _error) {
                var _url = '/api/autoInventoryB';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            exportPredictedStatistics: function(Data, _success, _error) {
                var _url = '/api/exportPredictedStatistics';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            exportInventory: function(Data, _success, _error) {
                var _url = '/api/exportInventory';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            get_straming_fp: function(Data, _success, _error) {
                var _url = '/api/get_straming_fp';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            retrieveTagAreas: function(fddId, _success, _error) {
               
                var _url = '/api/retrieveTagAreas?FacilityDataDetailsId=' + fddId;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },

            retrieveAvailableComponent: function(fdId, customerId, _success, _error) {
               
                var _url = '/api/retrieveAvailableObjects?FacilityDataId=' + fdId + '&CustomerId=' + customerId;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },

            saveAnomalyBB: function(info, fdd_id, fd_id, bbox, _success, _error) {
                var _url = '/api/saveAnomalyBB';

                myHttp.post(_url, JSON.stringify({'data':info, 'fdd_id':fdd_id, 'fd_id':fd_id, 'bbox': bbox})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            }, 

            saveComponentBB: function(info, fdd_id, fd_id, bbox, _success, _error) {
                var _url = '/api/saveComponentBB';

                myHttp.post(_url, JSON.stringify({'data':info, 'fdd_id':fdd_id, 'fd_id':fd_id, 'bbox': bbox})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            removeAllBBox: function(fdd_id, _success, _error) {
                var _url = '/api/removeAllBBox';

                myHttp.post(_url, JSON.stringify({'fdd_id':fdd_id})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },


            removeLastBBox: function(eleId, _success, _error) {
                var _url = '/api/removeLastBBox';

                myHttp.post(_url, JSON.stringify({'eleId':eleId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },
            
            updateStatusBBox: function(eleId,action,type, _success, _error) {
                    var _url = '/api/updatStatusBbox';
    

                myHttp.post(_url, JSON.stringify({'eleId':eleId, 'action':action, 'type': type})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },


            gestAssetStats: function(assetId, type_usage, _success, _error) {
                var _url = '/api/gestAssetStats';


                myHttp.post(_url, JSON.stringify({'assetId':assetId, 'type_usage': type_usage})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

            runAiReport: function(assetId, _success, _error) {
                var _url = '/api/runFastAiReport';


                myHttp.post(_url, JSON.stringify({'assetId':assetId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

          

            //each file extension could be separate by a comma
            getAssetDataDetailsByAssetIdAndFileExtensions: function(_assetId, _fileExtensions, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetails/byFacilityId/'+_assetId+'/byFileExtensions/' + _fileExtensions);
                var _errorMsg =  "I wasn't able to get the AssetDataDetails about the assetId: "+_assetId+" and fileExtensions: " + _fileExtensions;

                serviceUtility.runGet("getAssetDataDetailsByAssetIdAndFileExtensions", _url, _errorMsg, _res => _success(_res.listFacilityDataDetailsDto), _error);
            },

        }
    }])

