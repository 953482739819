const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('dashboardCmp', {
    controller: DashboardController,
    templateUrl: function(utility) {
        if (utility.isFromMobile()) {
            return 'views/dashboard/dashboard-mobile.html';
        } else {
            return 'views/dashboard/dashboard.html'
        }    
    } ,
    controllerAs: 'dashboardCtrl',
    bindings: {}
});



DashboardController.$inject = ['$scope', 'Scopes', 'myLog', 'myAlert','myThrow', 'dashboardServices', 'customerServices', 'assetServices', 'expanseConst', 'utility', '$timeout'];
/* @ngInject */
function DashboardController($scope, Scopes, myLog, myAlert, myThrow, dashboardServices, customerServices, assetServices, expanseConst, utility, $timeout) {

    const _this = this;
    const _thisControllerName = "dashboardCtrl";

    const _identity = JSON.parse(localStorage.getItem('exlite.identity'));
    if (!_identity || !_identity.id) {
        myAlert('Identity missing', 'danger');
        return;
    }
    _this.identity = _identity;

    // todo: remove testing mock
    // make me the inspected company
    // _identity.id = 2;
    // _identity.company.inspectingCompanyId = 1;

    const _mainCtrl = Scopes.get('mainCtrl');

    const _assetStatusTypes$ = new Promise((resolve, reject) => {
        // [{key: "status_type", value: "Status name"}]
        assetServices.getAssetStatusTypes(resolve, reject);
    });

    const _customerStatusTypes$ = new Promise((resolve, reject) => {
        [
            {key:'active', value:'Active'},
            {key:'inactive', value:'Inctive'}
        ]
    });

    _this.expanseConst = expanseConst;
    _this.companyTypes = customerServices.companyType;
    _this.companyType = _getCompanyType(_this.companyTypes, _identity);

    _this.statusTitles = {
        [_this.companyTypes.Inspecting]: 'Customer Status',
        [_this.companyTypes.Inspected]: 'Assets Status'
    };
    _this.statusTitle = _this.statusTitles[_this.companyType];

    _this.assetList$ = new Promise((resolve, reject) => {
        assetServices.getCustomerAssets(
            _identity.id, _this.companyType === _this.companyTypes.Inspecting, _identity.companyId, resolve, reject            
        );
    });


    _this.statusChartData$ = _getStatusChartDataPromise(_this.companyType);
    _this.assetStatusData$ = (_this.companyType === _this.companyTypes.Inspected) ?
        _this.statusChartData$ :
        _getStatusChartDataPromise(_this.companyTypes.Inspected);

    _this.customerStatusChartData$ = _getCustomerStatusChartDataPromise(_this.companyType);
    _this.customerStatusData$ = (_this.companyType === _this.companyTypes.Inspected) ?
        _this.customerStatusChartData$ :
        _getCustomerStatusChartDataPromise(_this.companyTypes.Inspected);
    

    _this.reports$ = _getReportsPromise(_this.companyType);

    _this.assetData$ = _getAssetDataPromise();

    // Inspection company data
    // if (_this.companyType === _this.companyTypes.Inspecting) {
        const assetTypeList$ = new Promise((resolve, reject) => {
            assetServices.getAssetTypes(resolve, reject);
        });

        const customerList$ = new Promise((resolve, reject) => {
            customerServices.getAllCustomers(_identity.companyId, _identity.id, resolve, reject);
        });

        _this.assetTypes$ = Promise
            .all([assetTypeList$, _this.assetList$, customerList$])
            .then(([assetTypeList, assets, customers]) => {                
                var _assets;
                if (_identity.isInspector) {
                    _assets = assets.filter(e => customers.filter(c => c.id == e.companyId).length>0);
                } else {
                    _assets = assets;
                }

                return assetTypeList
                    .map(type => Object.assign(type, {
                        assetNumber: _assets.filter(asset => asset.facilityTypeId === type.id).length
                    }))
                    .filter(type => type.assetNumber);
            });
    // }

    _this.$onInit = () => {
        const _ctrl = Scopes.get(_thisControllerName);
        if (_ctrl) {
            $scope = _ctrl;
        } else {
            Scopes.store(_thisControllerName, $scope);
        }
    };

    function _getStatusChartDataPromise(companyType) {
        let result;
        switch (companyType) {
            case _this.companyTypes.Inspecting: {
                // return customers statuses
                result = new Promise((resolve, reject) => {
                    customerServices.getCustomerStatusData(_identity.id, _identity.companyId, resolve, reject);
                }).then(statusData => {
                    // getting proper chart colors for statuses
                    // const statuses = Object.entries(customerServices.customerStatusTitles).map(([key, value]) => ({
                    //     key, value
                    // })); // get {key: label, title: Title} status values

                    const statuses = [];

                    const statusArr = assetServices.buildAssetStatusArray(statuses, _mainCtrl.getCssAboutAssetStatusChart());
                    statusData.backgroundColor =
                        statusData.backgroundColor.concat(statusArr.map(statusItem => statusItem.chart['bg-color']));

                    return statusData;
                });
                break;
            }
            case _this.companyTypes.Inspected: {
                // return assets statuses
                result = Promise
                    .all([_this.assetList$, _assetStatusTypes$])
                    .then(([assets, statuses]) => {
                        // build empty arr of assetStatus object
                        const assetStatuses = assetServices.buildAssetStatusArray(statuses, _mainCtrl.getCssAboutAssetStatusChart());

                        // compute assetStatus objects with assets
                        const assetStatusComputed = assetServices.computeAssetStatusValues(assets, assetStatuses);

                        // get assetStatus data ready for charting
                        return assetServices.buildAssetStatusChartData(assetStatusComputed);
                    });
                break;
            }
        }

        if (!result) {
            return result;
        }

        return result.then(_res => _res, _err => {
            const message = _err ? _err.data ? _err.data.message ? _err.data.message : _err.data : _err : 'Undefined err';
            myAlert("Error: " + message, 'danger');
            myThrow(_err);
        });
    }


    function _getCustomerStatusChartDataPromise(companyType) {
        let result;
        switch (companyType) {
            case _this.companyTypes.Inspecting: {
                // return customers statuses
                result = new Promise((resolve, reject) => {
                    customerServices.getCustomerStatusData(_identity.id, _identity.companyId, resolve, reject);
                }).then(statusData => {
                    // getting proper chart colors for statuses
                    const statuses = Object.entries(customerServices.customerStatusTitles).map(([key, value]) => ({
                        key, value
                    })); // get {key: label, title: Title} status values

                    const statusArr = assetServices.buildAssetStatusArray(statuses, _mainCtrl.getCssAboutCustomerStatusChart());
                    statusData.backgroundColor =
                        statusData.backgroundColor.concat(statusArr.map(statusItem => statusItem.chart['bg-color']));

                    return statusData;
                });
                break;
            }
            case _this.companyTypes.Inspected: {
                // return assets statuses
                result = Promise
                    .all([_this.assetList$, _customerStatusTypes$])
                    .then(([assets, statuses]) => {
                        // build empty arr of assetStatus object
                        const assetStatuses = assetServices.buildAssetStatusArray(statuses, _mainCtrl.getCssAboutCustomerStatusChart());

                        // compute assetStatus objects with assets
                        const assetStatusComputed = assetServices.computeAssetStatusValues(assets, assetStatuses);

                        // get assetStatus data ready for charting
                        return assetServices.buildAssetStatusChartData(assetStatusComputed);
                    });
                break;
            }
        }

        if (!result) {
            return result;
        }

        return result.then(_res => _res, _err => {
            const message = _err ? _err.data ? _err.data.message ? _err.data.message : _err.data : _err : 'Undefined err';
            myAlert("Error: " + message, 'danger');
            myThrow(_err);
        });
    }


    function _getReportsPromise(companyType) {
        return new Promise((resolve, reject) => {
            customerServices.getReports(
                _identity.id, _identity.companyId, resolve, reject,
                companyType === _this.companyTypes.Inspecting
            );
        });
    }


    function _getAssetDataPromise() {
        return new Promise((resolve, reject) => {
            customerServices.getCustomerAssetData(
               _identity.companyId,  _identity.id, resolve, reject
            );
        });
    }


    // get current customer company type
    function _getCompanyType(_types, _identity) {
        if (!_identity) {
            return _types.No;
        }

        return _identity.company.inspectingCompanyId ?
            _types.Inspected :
            _types.Inspecting;
    }
}

