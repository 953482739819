//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('anomalyFactory', ['myLog', 'utility', 
    function(myLog, utility) {
        function _getMinMaxCorrosionPoint(_list, _minMax) {
            var _ret = 0;
            
            if (_list && _list.length>0) {
                var _listPoints = _list.map(l => {
                    return l.counts?l.counts:0;
                })

                _ret = (_minMax == 'min')?Math.min(..._listPoints):Math.max(..._listPoints);
            }

            return _ret;
        }
        function _getMinMaxCorrosionArea(_list, _minMax) {
            var _ret = 0;
            
            if (_list && _list.length>0) {
                var _listArea = _list.map(l => {
                    return l.maxAnomalyTagArea;
                })

                _ret = (_minMax == 'min')?Math.min(..._listArea):Math.max(..._listArea);
            }

            return _ret;
        }

        return {
            filterListAssetDataDetailsByTag(_listAssetDataDetails, _listTagKey) {
                // if (_filterType == 'level1') {
                return _listAssetDataDetails.filter(a => a.listAnomalyTags.filter(t => _listTagKey.filter(tk =>  tk.keys.filter(k =>  k == t.anomalyTypeKey).length>0).length>0).length >0)
                // } else if (_filterType == 'level3') {
                //     return _listAssetDataDetails.filter(a => a.listAnomalyTags.filter(t => _listTagKey.filter(k =>  k.key == t.anomalyTypeKey).length>0).length>0)
                // }
            },
            sortListAssetDataDetailsByLevel2(_listAssetDataDetails, _field) {
                var _listSorted = _listAssetDataDetails;

                if (_field.key == 'corrosion-number') {
                    _listSorted = _listAssetDataDetails.sort((a, b) => {
                        var _aMax = _getMinMaxCorrosionPoint(a.listAnomalyTags, _field.minMax) 
                        var _bMax = _getMinMaxCorrosionPoint(b.listAnomalyTags, _field.minMax)

                        if (_field.sortType== 'asc') {
                            return _aMax - _bMax;
                        } else {
                            return _bMax - _aMax;
                        }
                    })

                    myLog(_listSorted)
                } else if (_field.key == 'corrosion-area') {
                    _listSorted = _listAssetDataDetails.sort((a, b) => {
                        var _aMax = _getMinMaxCorrosionArea(a.listAnomalyTags, _field.minMax) 
                        var _bMax = _getMinMaxCorrosionArea(b.listAnomalyTags, _field.minMax)

                        if (_field.sortType== 'asc') {
                            return _aMax - _bMax;
                        } else {
                            return _bMax - _aMax;
                        }
                    })

                    myLog(_listSorted)
                }

                return _listSorted;
            },


        };
    }
]);

