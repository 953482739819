const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('assetMapCmp', {
    templateUrl: 'views/dashboard/common/dashboard-asset-map.html',
    controller: AssetMapController,
    controllerAs: 'mapCtrl',
    bindings: {
        assetsPromise: '<',
        assetStatusDataPromise: '<',
    }
});

/* @ngInject */
function AssetMapController($scope, Scopes, myLog, myAlert, googlemaps, utility, assetServices, expanseConst, customerServices) {
    const _this = this;
    const _identity = JSON.parse(localStorage.getItem('exlite.identity'));

    var _clusterMaker;
    let _markers = [];
    let _mapObj;

    _this.assets = [];
    _this.assetsSelected = [];
    _this.filterItems = [];
    _this.filterItemsSelected = [];
    _this.listFacilityStatusTypeByKey = [];
    _this.listFacilityTypeById = [];

    _this.expanseConst = expanseConst;
    _this.companyTypes = customerServices.companyType;
    _this.companyType = _getCompanyType(_this.companyTypes, _identity);

    _this.mapsCanvasId = 'dashboard-map-canvas';

    _this.$onInit = () => {
        _initAssets();
        _initStatusFilter();
    };

    _this.toggleFilterItem = function(item) {
        const index = _this.filterItemsSelected.indexOf(item);
        if(index < 0) {
            _this.filterItemsSelected.push(item);
        } else {
            _this.filterItemsSelected.splice(index, 1);
        }

        // update selected assets list
        _this.assetsSelected = _this.assets.filter(asset =>
            _this.filterItemsSelected.some(status => status.key === asset.status ||
                (!asset.status && status.key === 'unknown')
            )

        );

        _updateMarkers();
    };

    function _initAssets() {
        if (!_this.assetsPromise) {
            return;
        }

        _this.assetsPromise.then(_assets => {
            customerServices.getAllCustomers(_identity.companyId, _identity.id, _customers => {                
                if (_identity.isInspector) {
                    _this.assets = _assets.filter(e => _customers.filter(c => c.id == e.companyId).length>0);
                } else {
                    _this.assets = _assets;
                }
                _this.assetsSelected = [..._this.assets];
                _initMap();
            });
        });

        assetServices.getAssetTypes(_res => {
            _this.listFacilityTypeById = utility.getArrayByField(_res, 'id');            

            _markers.forEach(e => {
                _addAssetTypeToMarker(e, e.asset);
            })
        })
    }

    function _initMap() {
        googlemaps.initGoogleMap(`#${_this.mapsCanvasId}`, _map => {
            _mapObj = _map;
            _markers = googlemaps.putAssetMarkersOnMap(_map, _this.assetsSelected, true);

            _clusterMaker = new MarkerClusterer(_map, _markers)
        });
    }

    function _initStatusFilter() {
        if (!_this.assetStatusDataPromise) {
            return;
        }

        _this.assetStatusDataPromise.then(_statusData => {
            _this.filterItems = _statusData.data.map((data, i) => {
                return {
                    key: _statusData.labels[i],
                    value: _statusData.titles[i],
                    data: _statusData.dataAbs[i]
                };
            });

            _this.listFacilityStatusTypeByKey =  utility.getArrayByField(_this.filterItems, 'key');
            _this.filterItemsSelected = [..._this.filterItems];

            _markers.forEach(e => {
                _addAssetStatusTypeToMarker(e, e.asset);
            })
        });
    }

    function _updateMarkers() {
        _markers.forEach(marker => {
            marker.setMap(null);
        });
        _markers = googlemaps.putAssetMarkersOnMap(_mapObj, _this.assetsSelected, true);

        _markers.forEach(e => {
            _addAssetStatusTypeToMarker(e, e.asset);
            _addAssetTypeToMarker(e, e.asset);
        })

        _clusterMaker.clearMarkers();
        _clusterMaker = new MarkerClusterer(_mapObj, _markers)        
    }

    function _addAssetStatusTypeToMarker(_marker, _asset) {
        _marker.facilityStatusType = _this.listFacilityStatusTypeByKey[_asset.status];
    }

    function _addAssetTypeToMarker(_marker, _asset) {
        _marker.facilityType = _this.listFacilityTypeById[_asset.facilityTypeId];
    }

    _this.editAssetClosedCallback = function(_assetId, _customerId) {
        utility.callFunctionEveryTime('$(".modal-dialog").get(0)', 200, function() {
            $(".modal-dialog").bind("$destroy", function() {

                _markers.some((e, index) => {
                    if (e.asset.id == _assetId) {
                        assetServices.getAsset(_assetId, _res => {
                            _markers.splice(index, 1);    
                            e.setMap(null);

                            var _asset = _res;
                            var _marker = googlemaps.createAssetMarker(_mapObj, _asset.latitude, _asset.longitude, _asset, googlemaps.getAssetStatusDotIcon(_asset), true, googlemaps.getAssetMarkerIcon(_asset));

                            _addAssetStatusTypeToMarker(_marker, _asset);
                            _addAssetTypeToMarker(_marker, _asset);

                            _markers.push(_marker);

                            new google.maps.event.trigger( _marker, 'click' );
    
                            return true;
                        })
                    }
                });
            });

        })
    }

        // get current customer company type
        function _getCompanyType(_types, _identity) {
            if (!_identity) {
                return _types.No;
            }
    
            return _identity.company.inspectingCompanyId ?
                _types.Inspected :
                _types.Inspecting;
        }
    
}
