let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
// =========================================================================
// Base controller for common functions
// =========================================================================

.controller('SigninCtrl', ['$scope', '$state', 'principal', '$rootScope',
            function($scope, $state, principal, $rootScope) {

                this.login = 1;
                this.register = 0;
                this.forgot = 0;

                $scope.signin = function(email,password) {
                    principal.login(email, password);
                    if ($scope.returnToState) $state.go($scope.returnToState.name, $scope.returnToStateParams);
                    else $state.go('equipment_managment.homepageDashboard');
                };

                //Email forgotten password
                $scope.forgottenPassword = function(email){
                    console.log('forgottenPassword:' + email);
                };

            }
])
.controller('HomeCtrl', ['$scope', '$state', 'principal', '$rootScope', 'Scopes', '$http','$timeout','fsValidator','fastLaunchRestUrl','$q','fsMessage','$uibModal','myHttp',
            function($scope, $state, principal, $rootScope, Scopes, $http, $timeout,fsValidator,fastLaunchRestUrl,$q,fsMessage,$uibModal,myHttp) {
                Scopes.store('HomeCtrl', $scope);

                $rootScope.isPilot = false;

                $rootScope.itemPsw = {};



                $scope.dateNoTimezone = function(date) {
                    console.log("jjjjjjjjjjjjj")
                    return  new Date(date.getTime() + date.getTimezoneOffset()*1000)

                }

                $scope.signout = function() {
                    principal.authenticate(null);
                    // localStorage.removeItem("sffd.identity");
                    // myHttp.clean();
                    $state.go('signin');
                };

                // get logged in uer name
                var retrievedObject = localStorage.getItem('sffd.identity');
                var fd_user = JSON.parse(retrievedObject);
                $rootScope.fullNameHeader = fd_user.name;
                $rootScope.idHeader = fd_user.result[0].id;
                var id_user = fd_user.result[0].id;

                $scope.role = fd_user.roles[0];


                //$scope.avatarImageUrl = 'fdfg';
                
                //let ifn = fd_user.result[0].id + '.png';

                //$http.get('/api/upload', {params: { filename: ifn }}).then(function(response) {
                //    $scope.avatarImageBase64 = response.data.img;
                //});



                // let ifn = fd_user.result[0].id + '.png';

                // $http.get('/api/upload', {params: { filename: ifn, _ts: new Date().getTime()}}).then(function(response) {
                //     $scope.avatarImageBase64 = response.data.img;
                // });

                //$scope.avatarImageBase64 = '/upload/profile-pics/' + $rootScope.idHeader + '.png?_ts=' + new Date().getTime();
                $scope.reloadAvatar = function (i) {
                    $scope.avatarImageBase64 = i;
                };


                //Some check about the data
                //Check for the mandatory fields
                //This function return two functions that is possible to call
                //if either there are or there aren't errors
                $scope.validationItem = function(item) {
                    // console.log("validation");
                    var v = fsValidator.newInstance();

                    // if (item.errors) {
                    //     v.setErrors(item.errors);
                    // }

                    v.validate(item.oldPassword, 'oldPsw').isUndefined("can't be undefined");
                    v.validate(item.newPassword, 'newPsw').isUndefined("can't be undefined");
                    v.validate(item.newPasswordRewrited, 'newPswRw').isUndefined("can't be undefined");
                    v.validate(item.newPassword, item.newPasswordRewrited, 'newPswRw').isNotEqualTo("new password is different from new password rewrited");

                    item.errors = v.getErrors();

                    return v;
            }

                $scope.changePsw = function() {
                    console.log("change password");

                    if ($scope.validationItem($rootScope.itemPsw).isSuccess()) {
                        var fltsUrl = fastLaunchRestUrl + '/flCross/user/changepsw/'+id_user;

                        var msForm  = $http({
                            method: "post",
                            url: fltsUrl,
                            headers: {
                                'Content-Type': 'application/json',
                                'old_psw':$rootScope.itemPsw.oldPassword,
                                'new_psw':$rootScope.itemPsw.newPassword
                            }
                        });

                        $q.all([msForm]).then(function(result) {

                            var _fns = [
                                {'btn_msg':'OK', 'btn_color':'btn-primary', 'btn_fn': 'signout()'}
                            ];

                            fsMessage.openModal('sm', 'Change password', "Password changed successfully. Redo login", 'HomeCtrl', JSON.stringify(_fns));


                        }, function(error) {
                            var _je = error.data.jsonErrors.replace(/'/g,'\'');
                            fsValidator.newInstance().setErrors(JSON.parse(_je));
                            $rootScope.itemPsw.errors = fsValidator.getErrors();

                        });

                    }


                }

                // $scope.disableManageAllJobs = function() {
                //     $rootScope.isPilot = true;
                //     $('html').attr("fl-ispilot","yes");
                // }
                // $scope.disableCurrentWorkAssignments = function() {
                //     $rootScope.isPilot = false;
                //     $('html').removeAttr("fl-ispilot");
                // }

                // $scope.$watch(function() {
                //     return $('#_manageAllJobs').attr("class");
                // }, function (newVal, oldVal) {
                //     if(newVal !== oldVal) {
                //        // console.log('changed!', newVal, oldVal);
                //        // console.log($('html').attr("ispilot"));

                //         if ($('html').attr("fl-ispilot")==="yes") {
                //             $('#fastlaunch-assignment-title-pilot').show();
                //             $('#_manageAllJobs').removeClass("active");
                //         } else {
                //             $('#fastlaunch-assignment-title-management').show();
                //             $('#_currentWorkAssignments').removeClass("active");
                //         }
                //     }
                // })

                $scope.showHelpMenu = function() {
                    $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: '/views/fastLaunch_presentation.html',
                        controller: 'HomeCtrl',
                        controllerAs: '$ctrl',
                        size: 'lg'
                    });            
                }

            }

])
.controller('expanseLiteAppCtrl', ['$timeout', '$state', '$scope', 'growlService','Scopes',function($timeout, $state, $scope, growlService,Scopes){
    //Welcome Message

    Scopes.store('expanseLiteAppCtrl', $scope);

    // growlService.growl('Welcome to Expanse', 'info');

    this.idcontent = 'content';

    // Detact Mobile Browser
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        angular.element('html').addClass('ismobile');
    }

    $scope.showMapBg = 1;
    $scope.showBg = function() {
        this.showMapBg = 1;
    }
    $scope.hideBg = function() {
        this.showMapBg = 0;
    }

    this.namePageShown = '';

    this.setNamePageShown = function(_value) {
        this.namePageShown = _value;
    }

    this.getNamePageShown = function() {
        return this.namePageShown;
    }

    // By default template has a boxed layout
    // this.layoutType = localStorage.getItem('ma-layout-status');
    this.layoutType = 0

    // By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
    this.sidebarToggle = {
        left: true,
        right: false
    };

    this.pippo = function(_value) {
        if (_value === undefined) {
            this.layoutType++; 
            this.layoutType %= 2;            
        } else {
            this.layoutType = _value;
        }

        this.sidebarToggle.left = false;

        if (this.layoutType===0) {
            this.idcontent = 'content';
        } else {
            this.idcontent = 'content_1';
        }
        // console.log(this.layoutType, this.sidebarToggle.left);
    }

    //Set left menu closed
    this.pippo(1);

    // For Mainmenu Active Class
    this.$state = $state;

    //Close sidebar on click
    this.sidebarStat = function(event) {
        this.prepareDataInLocalStorage();
        if (!angular.element(event.target).parent().hasClass('active')) {
            this.sidebarToggle.left = false;
        }

    };

    this.prepareDataInLocalStorage = function(){
        localStorage.removeItem('userId');
        localStorage.removeItem('safetyReportId');
        localStorage.removeItem('equipmentFaultId');
        localStorage.setItem('noneditable',false);
    };

    //Listview Search (Check listview pages)
    this.listviewSearchStat = false;

    this.lvSearch = function() {
        this.listviewSearchStat = true;
    };

    //Listview menu toggle in small screens
    this.lvMenuStat = false;

    //Blog
    this.wallCommenting = [];

    this.wallImage = false;
    this.wallVideo = false;
    this.wallLink = false;

    //Skin Switch
    this.currentSkin = '#333D49';// '#123048'; 


    this.skinList = [ 
        'lightblue',
        'bluegray',
        'cyan',
        'teal',
        'green',
        'orange',
        'blue',
        'purple'
    ];

    this.getColorByUser = function() {
        var retrievedObject = localStorage.getItem('sffd.identity');
        var _user = JSON.parse(retrievedObject);

        // if (_user.result[0].is_training) {
        //     this.currentSkin = 'green'
        // } else {
        //     this.currentSkin = 'bluegray';
        // }

        return this.currentSkin;
    }

    this.skinSwitch = function (color) {
        this.currentSkin = color;
    };

}])


// =========================================================================
// Header
// =========================================================================
.controller('headerCtrl', ['$timeout', 'messageService', 'softeqUrl', '$window', function($timeout, messageService, softeqUrl, $window){
    this.getUserLogged = function() {
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        return _identity;
    }

    this.goToSofteqUrl = function() {
        $window.location.href = softeqUrl + '/emplHome/home';
    }

    // Top Search
    this.openSearch = function(){
        angular.element('#header').addClass('search-toggled');
        angular.element('#top-search-wrap').find('input').focus();
    };

    this.closeSearch = function(){
        angular.element('#header').removeClass('search-toggled');
    };

    // Get messages and notification for header
    this.img = messageService.img;
    this.user = messageService.user;
    this.user = messageService.text;

    this.messageResult = messageService.getMessage(this.img, this.user, this.text);


    //Clear Notification
    this.clearNotification = function($event) {
        $event.preventDefault();

        var x = angular.element($event.target).closest('.listview');
        var y = x.find('.lv-item');
        var z = y.size();

        angular.element($event.target).parent().fadeOut();

        x.find('.list-group').prepend('<i class="grid-loading hide-it"></i>');
        x.find('.grid-loading').fadeIn(1500);
        var w = 0;

        y.each(function(){
            var z = $(this);
            $timeout(function(){
                z.addClass('animated fadeOutRightBig').delay(1000).queue(function(){
                    z.remove();
                });
            }, w+=150);
        });

        $timeout(function(){
            angular.element('#notifications').addClass('empty');
        }, (z*150)+200);
    };

    // Clear Local Storage
    this.clearLocalStorage = function() {

        //Get confirmation, if confirmed clear the localStorage
        swal({
            title: "Are you sure?",
            text: "All your saved localStorage values will be removed",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#F44336",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false
        }, function(){
            localStorage.clear();
            swal("Done!", "localStorage is cleared", "success");
        });

    };

    //Fullscreen View
    this.fullScreen = function() {
        //Launch
        function launchIntoFullscreen(element) {
            if(element.requestFullscreen) {
                element.requestFullscreen();
            } else if(element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if(element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if(element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }

        //Exit
        function exitFullscreen() {
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }

        if (exitFullscreen()) {
            launchIntoFullscreen(document.documentElement);
        }
        else {
            launchIntoFullscreen(document.documentElement);
        }
    };

}])

// =========================================================================
// Animation
// =========================================================================

.controller('animateCtrl', [ '$scope', '$q', '$timeout', function($scope,$q,$timeout){
    //animation
    $scope.setAnimation = function(animation, target) {
        var animationDuration;
        if (animation === "hinge") {
            animationDuration = 2100;
        }
        else {
            animationDuration = 1200;
        }

        angular.element('#'+target).addClass(animation);
        angular.element('#'+target).removeClass('hidden');

        return $timeout(function(){
            angular.element('#'+target).removeClass(animation);
            return target;
        }, animationDuration);
    };

    $scope.toggleCards = function(cardIn,cardOut,back) {
        var animIn = 'fadeInRightBig'; //console.log('toggleCards:',cardIn,cardOut,back);
        var animOut = 'fadeOutLeftBig';
        if(back) {
            animIn = 'fadeInLeftBig';
            animOut = 'fadeOutRightBig';
        }

        var outPromise = $scope.setAnimation(animOut, cardOut);
        var inPromise = $scope.setAnimation(animIn, cardIn);
        $q.all([outPromise,inPromise]).then(function(idName) {
            angular.element('#'+idName[0]).addClass('hidden');
            angular.element('#'+idName[1]).removeClass('hidden');
        });
    };

}])

// =========================================================================
// Equipment Management
// =========================================================================

.controller('flightlogCtrl', ['$scope','$q','$http','NgTableParams',function($scope,$q,$http,NgTableParams){
    var param = {'limit':100000, 'extend':['profile']};
    var userUrl = '/api/user?' + $.param(param,true);
    var userReq = $http({ method: 'get', url: userUrl });
    param = {'limit':100000, 'extend':['method_statement']};
    var techlogUrl = '/api/techlog?' + $.param(param,true);
    var techlogReq = $http({ method: 'get', url: techlogUrl });
    var getData = function(params) {
        var limit = params.count();
        var page = params.page();
        var param = {'limit':limit,'page':page, 'extend':['equipment','flight_type']};
        var flightUrl = '/api/flight?' + $.param(param,true);
        var flightReq = $http({ method: 'get', url: flightUrl });


        return $q.all([flightReq,userReq,techlogReq]).then(function(response) {
            var limit = response[0].data.limit;
            var page = response[0].data.page;
            var totalItems = response[0].data.total_items;
            var flightListData = response[0].data.result;
            var userListData = response[1].data.result;
            var techlogListData = response[2].data.result;

            this.flightList = flightListData;
            this.userList = userListData;
            this.techlogList = techlogListData;

            params.count(limit);
            params.page(page);
            params.total(totalItems);

            return flightListData;
        }.bind(this)).catch(function(err) { console.log(err); });
    }.bind(this);

    this.getTechlog = function(techlogId) {
        return this.techlogList.find(function(x) { return x.id === techlogId; }); 
    };

    var  msToTime = function(duration) {
        var milliseconds = parseInt((duration%1000)/100);
        var seconds = parseInt((duration/1000)%60);
        var minutes = parseInt((duration/(1000*60))%60);
        var hours = parseInt((duration/(1000*60*60))%24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return /*hours + ":" +*/ minutes + ":" + seconds + "." + milliseconds;
    };

    this.getDate = function(dateStr) {
        var d = new Date(dateStr);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('-');
    };

    this.getTimeDiff = function(dateStrA,dateStrB) {
        return msToTime((new Date(dateStrA)) - (new Date(dateStrB)));
    };

    this.tableParams = new NgTableParams({ 
        count: 10 
    }, {
        getData: getData
    });
}])

// =========================================================================
// Temp Method Statement List
// =========================================================================

.controller('tempMethodStatementList',['$http','$q','$scope',function($http,$q,$scope) {

    var methodStatementUrl = '/api/method-statement?' + $.param({'limit':10000},true);
    var methodStatementReq = $http({ method: 'get', url: methodStatementUrl });
    var efrUrl = '/api/equipment-fault-report?' + $.param({'limit':10000},true);
    var efrReq = $http({ method: 'get', url: efrUrl });
    var asrUrl = '/api/air-safety-report?' + $.param({'limit':10000},true);
    var asrReq = $http({ method: 'get', url: asrUrl });

    $q.all([methodStatementReq,efrReq,asrReq]).then(function(response) {
        var methodStatementData = response[0].data.result;
        var efrData = response[1].data.result;
        var asrData = response[2].data.result;

        $scope.airSafetyReport = asrData;
        $scope.equipmentFaultReport = efrData;
        $scope.methodStatement = methodStatementData;
    });
}])

// =========================================================================
// Best Selling Widget
// =========================================================================

.controller('bestsellingCtrl', ['bestsellingService',function(bestsellingService){
    // Get Best Selling widget Data
    this.img = bestsellingService.img;
    this.name = bestsellingService.name;
    this.range = bestsellingService.range;

    this.bsResult = bestsellingService.getBestselling(this.img, this.name, this.range);
}])


// =========================================================================
// Todo List Widget
// =========================================================================

.controller('todoCtrl', ['todoService',function(todoService){

    //Get Todo List Widget Data
    this.todo = todoService.todo;

    this.tdResult = todoService.getTodo(this.todo);

    //Add new Item (closed by default)
    this.addTodoStat = false;
}])


// =========================================================================
// Recent Items Widget
// =========================================================================

.controller('recentitemCtrl', ['recentitemService',function(recentitemService){

    //Get Recent Items Widget Data
    this.id = recentitemService.id;
    this.name = recentitemService.name;
    this.parseInt = recentitemService.price;

    this.riResult = recentitemService.getRecentitem(this.id, this.name, this.price);
}])


// =========================================================================
// Recent Posts Widget
// =========================================================================

.controller('recentpostCtrl', ['recentpostService',function(recentpostService){

    //Get Recent Posts Widget Items
    this.img = recentpostService.img;
    this.user = recentpostService.user;
    this.text = recentpostService.text;

    this.rpResult = recentpostService.getRecentpost(this.img, this.user, this.text);
}])


//=================================================
// LOGIN
//=================================================

.controller('loginCtrl', function(){

        //Status

        this.login = 1;
        this.register = 0;
        this.forgot = 0;
        })


    //=================================================
    // CALENDAR
    //=================================================

    .controller('calendarCtrl',['$modal', function($modal){

        //Create and add Action button with dropdown in Calendar header.
        this.month = 'month';

        this.actionMenu = '<ul class="actions actions-alt" id="fc-actions">' +
            '<li class="dropdown" dropdown>' +
                '<a href="" dropdown-toggle><i class="zmdi zmdi-more-vert"></i></a>' +
                    '<ul class="dropdown-menu dropdown-menu-right">' +
                        '<li class="active">' +
                            '<a data-calendar-view="month" href="">Month View</a>' +
                                '</li>' +
                                    '<li>' +
                                        '<a data-calendar-view="basicWeek" href="">Week View</a>' +
                                            '</li>' +
                                                '<li>' +
                                                    '<a data-calendar-view="agendaWeek" href="">Agenda Week View</a>' +
                                                        '</li>' +
                                                            '<li>' +
                                                                '<a data-calendar-view="basicDay" href="">Day View</a>' +
                                                                    '</li>' +
                                                                        '<li>' +
                                                                            '<a data-calendar-view="agendaDay" href="">Agenda Day View</a>' +
                                                                                '</li>' +
                                                                                    '</ul>' +
                                                                                        '</div>' +
                                                                                            '</li>';


                                                                                            //Open new event modal on selecting a day
                                                                                            this.onSelect = function(argStart, argEnd) {
                                                                                                var modalInstance  = $modal.open({
                                                                                                    templateUrl: 'addEvent.html',
                                                                                                    controller: 'addeventCtrl',
                                                                                                    backdrop: 'static',
                                                                                                    keyboard: false,
                                                                                                    resolve: {
                                                                                                        calendarData: function() {
                                                                                                            var x = [argStart, argEnd];
                                                                                                            return x;
                                                                                                        }
                                                                                                    }
                                                                                                });
                                                                                            };
    }])

    //Add event Controller (Modal Instance)
    .controller('addeventCtrl',['$scope', '$modalInstance', 'calendarData', function($scope, $modalInstance, calendarData){

        //Calendar Event Data
        $scope.calendarData = {
            eventStartDate: calendarData[0],
            eventEndDate:  calendarData[1]
        };

        //Tags
        $scope.tags = [
            'bgm-teal',
            'bgm-red',
            'bgm-pink',
            'bgm-blue',
            'bgm-lime',
            'bgm-green',
            'bgm-cyan',
            'bgm-orange',
            'bgm-purple',
            'bgm-gray',
            'bgm-black',
        ];

        //Select Tag
        $scope.currentTag = '';

        $scope.onTagClick = function(tag, $index) {
            $scope.activeState = $index;
            $scope.activeTagColor = tag;
        };

        //Add new event
        $scope.addEvent = function() {
            if ($scope.calendarData.eventName) {

                //Render Event
                $('#calendar').fullCalendar('renderEvent',{
                    title: $scope.calendarData.eventName,
                    start: $scope.calendarData.eventStartDate,
                    end:  $scope.calendarData.eventEndDate,
                    allDay: true,
                    className: $scope.activeTagColor

                },true ); //Stick the event

                $scope.activeState = -1;
                $scope.calendarData.eventName = '';
                $modalInstance.close();
            }
        };

        //Dismiss
        $scope.eventDismiss = function() {
            $modalInstance.dismiss();
        };
    }])

    // =========================================================================
    // COMMON FORMS
    // =========================================================================

    .controller('formCtrl', function(){

        //Input Slider
        this.nouisliderValue = 4;
        this.nouisliderFrom = 25;
        this.nouisliderTo = 80;
        this.nouisliderRed = 35;
        this.nouisliderBlue = 90;
        this.nouisliderCyan = 20;
        this.nouisliderAmber = 60;
        this.nouisliderGreen = 75;

        //Color Picker
        this.color = '#03A9F4';
        this.color2 = '#8BC34A';
        this.color3 = '#F44336';
        this.color4 = '#FFC107';
    })


    // =========================================================================
    // PHOTO GALLERY
    // =========================================================================

    .controller('photoCtrl', function(){

        //Default grid size (2)
        this.photoColumn = 'col-md-2';
        this.photoColumnSize = 2;

        this.photoOptions = [
            { value: 2, column: 6 },
            { value: 3, column: 4 },
            { value: 4, column: 3 },
            { value: 1, column: 12 },
        ];

        //Change grid
        this.photoGrid = function(size) {
            this.photoColumn = 'col-md-'+size;
            this.photoColumnSize = size;
        };

    })


    // =========================================================================
    // ANIMATIONS DEMO
    // =========================================================================
    .controller('animCtrl',['$timeout', function($timeout){

        //Animation List
        this.attentionSeekers = [
            { animation: 'bounce', target: 'attentionSeeker' },
            { animation: 'flash', target: 'attentionSeeker' },
            { animation: 'pulse', target: 'attentionSeeker' },
            { animation: 'rubberBand', target: 'attentionSeeker' },
            { animation: 'shake', target: 'attentionSeeker' },
            { animation: 'swing', target: 'attentionSeeker' },
            { animation: 'tada', target: 'attentionSeeker' },
            { animation: 'wobble', target: 'attentionSeeker' }
        ];
        this.flippers = [
            { animation: 'flip', target: 'flippers' },
            { animation: 'flipInX', target: 'flippers' },
            { animation: 'flipInY', target: 'flippers' },
            { animation: 'flipOutX', target: 'flippers' },
            { animation: 'flipOutY', target: 'flippers'  }
        ];
        this.lightSpeed = [
            { animation: 'lightSpeedIn', target: 'lightSpeed' },
            { animation: 'lightSpeedOut', target: 'lightSpeed' }
        ];
        this.special = [
            { animation: 'hinge', target: 'special' },
            { animation: 'rollIn', target: 'special' },
            { animation: 'rollOut', target: 'special' }
        ];
        this.bouncingEntrance = [
            { animation: 'bounceIn', target: 'bouncingEntrance' },
            { animation: 'bounceInDown', target: 'bouncingEntrance' },
            { animation: 'bounceInLeft', target: 'bouncingEntrance' },
            { animation: 'bounceInRight', target: 'bouncingEntrance' },
            { animation: 'bounceInUp', target: 'bouncingEntrance'  }
        ];
        this.bouncingExits = [
            { animation: 'bounceOut', target: 'bouncingExits' },
            { animation: 'bounceOutDown', target: 'bouncingExits' },
            { animation: 'bounceOutLeft', target: 'bouncingExits' },
            { animation: 'bounceOutRight', target: 'bouncingExits' },
            { animation: 'bounceOutUp', target: 'bouncingExits'  }
        ];
        this.rotatingEntrances = [
            { animation: 'rotateIn', target: 'rotatingEntrances' },
            { animation: 'rotateInDownLeft', target: 'rotatingEntrances' },
            { animation: 'rotateInDownRight', target: 'rotatingEntrances' },
            { animation: 'rotateInUpLeft', target: 'rotatingEntrances' },
            { animation: 'rotateInUpRight', target: 'rotatingEntrances'  }
        ];
        this.rotatingExits = [
            { animation: 'rotateOut', target: 'rotatingExits' },
            { animation: 'rotateOutDownLeft', target: 'rotatingExits' },
            { animation: 'rotateOutDownRight', target: 'rotatingExits' },
            { animation: 'rotateOutUpLeft', target: 'rotatingExits' },
            { animation: 'rotateOutUpRight', target: 'rotatingExits'  }
        ];
        this.fadeingEntrances = [
            { animation: 'fadeIn', target: 'fadeingEntrances' },
            { animation: 'fadeInDown', target: 'fadeingEntrances' },
            { animation: 'fadeInDownBig', target: 'fadeingEntrances' },
            { animation: 'fadeInLeft', target: 'fadeingEntrances' },
            { animation: 'fadeInLeftBig', target: 'fadeingEntrances'  },
            { animation: 'fadeInRight', target: 'fadeingEntrances'  },
            { animation: 'fadeInRightBig', target: 'fadeingEntrances'  },
            { animation: 'fadeInUp', target: 'fadeingEntrances'  },
            { animation: 'fadeInBig', target: 'fadeingEntrances'  }
        ];
        this.fadeingExits = [
            { animation: 'fadeOut', target: 'fadeingExits' },
            { animation: 'fadeOutDown', target: 'fadeingExits' },
            { animation: 'fadeOutDownBig', target: 'fadeingExits' },
            { animation: 'fadeOutLeft', target: 'fadeingExits' },
            { animation: 'fadeOutLeftBig', target: 'fadeingExits'  },
            { animation: 'fadeOutRight', target: 'fadeingExits'  },
            { animation: 'fadeOutRightBig', target: 'fadeingExits'  },
            { animation: 'fadeOutUp', target: 'fadeingExits'  },
            { animation: 'fadeOutUpBig', target: 'fadeingExits'  }
        ];
        this.zoomEntrances = [
            { animation: 'zoomIn', target: 'zoomEntrances' },
            { animation: 'zoomInDown', target: 'zoomEntrances' },
            { animation: 'zoomInLeft', target: 'zoomEntrances' },
            { animation: 'zoomInRight', target: 'zoomEntrances' },
            { animation: 'zoomInUp', target: 'zoomEntrances'  }
        ];
        this.zoomExits = [
            { animation: 'zoomOut', target: 'zoomExits' },
            { animation: 'zoomOutDown', target: 'zoomExits' },
            { animation: 'zoomOutLeft', target: 'zoomExits' },
            { animation: 'zoomOutRight', target: 'zoomExits' },
            { animation: 'zoomOutUp', target: 'zoomExits'  }
        ];

        //Animate
        this.ca = '';

        this.setAnimation = function(animation, target) {
            if (animation === "hinge") {
                animationDuration = 2100;
            }
            else {
                animationDuration = 1200;
            }

            angular.element('#'+target).addClass(animation);

            $timeout(function(){
                angular.element('#'+target).removeClass(animation);
            }, animationDuration);
        };

    }]);

