//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('projectFactory', ['utility', 'ngTableParams', 'projectServices', 'popup', '$filter', 'myAlert', 'fsValidator', 'assetServices',
    function(utility, ngTableParams, projectServices, popup, $filter, myAlert, fsValidator, assetServices)  {

        function _init(_projectObject, _success) {
            utility.callFunctionEveryTime("$('#"+_projectObject.data.listContainerId+"').get(0)", 250, function () {
                // set the project-status' drop down list
                _projectObject.scope.dropDownSettings = {
                    styleActive: true, template: '<b>{{option.name}}</b>', scrollableHeight: '200px', scrollable: true,
                    smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return "Asset list"; }
                };
                _projectObject.data.assetDropDownSettings = _projectObject.scope.dropDownSettings;

                var _status_labels = '<div style="display: inline-flex"> <div class="project-status {{option.key}}" ></div>';
                var _template = _status_labels + ' <div><b>{{option.value}}</b></div></div>';
                _projectObject.scope.projectDropDownSettings = {
                    template: _template, showCheckAll: false, showUncheckAll: false,
                    smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
                };
                _projectObject.data.dropDownSettings = _projectObject.scope.projectDropDownSettings;
                
                _getProjectsByCompanyId(_projectObject, _success);

                projectServices.getProjectStatusTypes(_res => {
                    _projectObject.data.listProjectStatusType = _res;
                    _projectObject.data.listProjectStatusTypeByKey = utility.getArrayByField(_res, 'key');
                })
                projectServices.getProjectTypes(_res => {
                    _projectObject.data.listProjectType = _res;
                    _projectObject.data.listProjectTypeByKey = utility.getArrayByField(_res, 'key');
                })
            })

        }

        // function _getCompanyListAsset(_projectObject, _success) {
        //     assetServices.getCustomerAssets(_projectObject.data.userId, false, _projectObject.data.companyId, _res => {

        //         assetServices.getAssetTypes(_res1 => {
        //             var _listAssetTypeKey = utility.getArrayByField(_res1, 'id');
        //             _projectObject.data.listAsset = _res.filter(e => e.facilityTypeId && _listAssetTypeKey[e.facilityTypeId].key != 'estate' && _listAssetTypeKey[e.facilityTypeId].key != 'networkLink');
        //             _success?_success():undefined;
        //         });
        //     })
        // }


        function _getProjectsByCompanyId(_projectObject, _success) {
            projectServices.getProjectsByCompanyId(_projectObject.data.companyId, _res => {
                _projectObject.data.listProject = _res;
                _projectObject.data.projectTableParams = ngTableParams.default(_projectObject.data.listProject);

                _success?_success():undefined;
            })
        }

        function _getProjectListAsset(_projectObject, _project, _success) {
            projectServices.getProjectFacilitiesByProjectId(_project.id, _res => {
                var _listAsset = _projectObject.data.listAsset.filter(e => _res.find(r => r.facilityId == e.id));
                _success?_success(_listAsset):undefined;
            })
        }

        function _showAssets(_projectObject, _project) {
            _project.showAssets = !_project.showAssets;
            if (!_project.listAsset) {
                _getProjectListAsset(_projectObject, _project, _res => {
                    _project.listAsset = _res;
                });
            }
        }

        function _initPopup(_projectObject) {
            return function(_popup) {
                _popup.data.listAssetAvailableSelected = [];

                if (!_popup.data.project) {
                    _popup.data.project = {
                        begin: new Date(),
                        end: new Date(),
                        companyId: _projectObject.data.companyId
                    };
                    _popup.data.listAssetAvailable = $filter('orderBy')(angular.copy(_projectObject.data.listAsset), 'name', false) ;            
                    _popup.data.listProjectAssets = [];
                    _popup.data.projectStatusSelected=[];
                    _popup.updateData(_popup.data);
                } else {
                    _popup.data.project.begin = new Date(_popup.data.project.begin);
                    _popup.data.project.end = new Date(_popup.data.project.end);
                    _popup.data.projectStatusSelected = [{ key: _popup.data.project.statusKey }],


                    _getProjectListAsset(_projectObject, _projectObject.data.project, _res => {
                        _projectObject.data.project.listAsset = _res;
                        _popup.data.listAssetAvailable = $filter('orderBy')(angular.copy(_projectObject.data.listAsset.filter(e => !_projectObject.data.project.listAsset.find(a => a.id==e.id))), 'name', false) ;            
                        _popup.data.listProjectAssets = _projectObject.data.project.listAsset;
                        _popup.updateData(_popup.data);
                    });
                }

            }
        }


        function _addAsset(_projectObject) {
            return function(_popup) {
                _popup.data.listAssetAvailable = _popup.data.listAssetAvailable.filter(e => !_popup.data.listAssetAvailableSelected.find(a => a.id == e.id));
                _popup.data.listAssetAvailableSelected.forEach(e => _popup.data.listProjectAssets.push(e));
                _popup.data.listAssetAvailableSelected = [];
            }
        }

        function _removeAsset(_projectObject) {
            return function(_popup, _assetId) {
                _popup.data.listAssetAvailable.push(_popup.data.listProjectAssets.find(a => a.id == _assetId));
                _popup.data.listProjectAssets.some((e, index) => {
                    if (e.id == _assetId) {
                        _popup.data.listProjectAssets.splice(index, 1)
                        return true;
                    }
                }) 
                _popup.data.listAssetAvailable = $filter('orderBy')(_popup.data.listAssetAvailable, 'name', false) 
            }
        }

        function _createPopup(_projectObject) {
            var _fns = [
                { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear'},
                { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true, 'btn_validation': 'validationProjectFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveProjectFromPopup' },
            ];

            var _config = {
                'funzione': {
                    initPopup: _initPopup(_projectObject),
                    addAsset: _addAsset(_projectObject),
                    removeAsset: _removeAsset(_projectObject),
                },
                'size': 'lg',
                'title': 'New Project',
                'ctrl': _projectObject.data.controllerName,
                'data': JSON.stringify(_projectObject.data),
                'fns': JSON.stringify(_fns),
                'htmlPage': 'views/asset/popup/project_popup.html',
                'innerStatus': undefined
            }
            popup.openModalBody(_config);
        }        

        function _saveProject(_projectObject, _project, _success, _error) {
            projectServices.saveProject(_projectObject.data.userId, _project, _res => {
                myAlert("Project saved", 'info');
                _success ? _success(_res) : null;  //Call the _success function only if it is defined
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
                _error ? _error(_err) : null;  //Call the _error function only if it is defined
            })
        }            

        function _saveProjectFromPopup(_projectObject, _popup) {
            _popup.data.project.statusKey = _popup.data.projectStatusSelected[0].key;
            _popup.data.project.listProjectFacilityDto = [];
            _popup.data.project.descriptionText = $("#_project_description").get(0).textContent;

            _popup.data.listProjectAssets.forEach(e => {
                _popup.data.project.listProjectFacilityDto.push({
                    projectId: _popup.data.project.id,
                    facilityId: e.id
                })
            })

            _saveProject(_projectObject, _popup.data.project, _res => {
                var _project = _res;
                _project.begin = new Date(_project.begin)
                _project.end = new Date(_project.end)

                _getProjectListAsset(_projectObject, _project, _res => {
                    _project.listAsset = _res;
                    _popup.data.project = _project;
                    
                    //Update the listProject 
                    var _index=-1;
                    _projectObject.data.listProject.some((e, index) => {if (e.id == _project.id) _index=index; return true;});
                    if (_index==-1) {
                        _projectObject.data.listProject.unshift(_project);
                    } else {
                        _projectObject.data.listProject[_index] = _project;
                    }
                    _projectObject.data.projectTableParams = ngTableParams.default(_projectObject.data.listProject);

                    _popup.success(_popup.data);
                })
            }, _err => {
                _popup.error(_err);
    
            });    
        }

        function _validationProject(_projectObject, _project) {
            var _ret = false;            
            var _v = fsValidator.newInstance();

            _v.validate(_project.name, 'name').isEmpty("This field can't be empty")
            _v.validate(_project.statusKey, 'statusKey').isEmpty("This field can't be empty");
            _v.validate(_project.typeKey, 'typeKey').isEmpty("This field can't be empty");
            _v.validate(_project.begin, 'begin').isEmpty("This field can't be empty");
            _v.validate(_project.end, 'end').isEmpty("This field can't be empty");
            _v.validate(_project.begin, _project.end, 'begin').isGreaterThan("The Begin date can't be greater than the End one");
            _v.validate(_project.end, _project.begin, 'end').isSmallerThan("The End date can't be smaller than the Begin one");

            _project.errors = _v.getErrors();

            _v.success(function () {
                _ret = true;
            }).errors(function () {
                _ret = _project;
            })

            return _ret
        }


        function _validationProjectFromPopup(_projectObject, _popup) {
            _popup.data.project.statusKey = _popup.data.projectStatusSelected.length>0?_popup.data.projectStatusSelected[0].key:undefined;
            var _ret = _validationProject(_projectObject, _popup.data.project);

            if (_ret == true || _ret == false) {
                return _ret;
            } else {
                _popup.data.project = _ret;
                return _popup.data;
            }
        }        


//-------------------------------------------------------------functions to be created: begin------------------------------------------------------
        function _createFunctionInit(_projectObject) {
            _projectObject.init = function(_success) {
                _init(_projectObject, _success);
            }
        }

        function _createFunctionGetProjectsByCompanyId(_projectObject) {
            _projectObject.getProjectsByCompanyId = function(_companyId, _listAsset, _success) {
                _projectObject.data.companyId = _companyId;
                _projectObject.data.listAsset = _listAsset,
                _getProjectsByCompanyId(_projectObject, _success);
            }
        }

        function _createFunctionShowAssets(_projectObject) {
            _projectObject.showAssets = function(_project) {
                _showAssets(_projectObject, _project);
            }
        }

        function _createFunctionEditProject(_projectObject) {
            _projectObject.editProject = function(_project) {
                _projectObject.data.project = _project;
                _createPopup(_projectObject);
            }
        }

        function _createFunctionSaveProjectFromPopup(_projectObject) {
            _projectObject.saveProjectFromPopup = function(_popup) {
                _saveProjectFromPopup(_projectObject, _popup);
            }
        }

        function _createFunctionValidationProjectFromPopup(_projectObject) {
            _projectObject.validationProjectFromPopup = function(_popup) {
                return _validationProjectFromPopup(_projectObject, _popup);
            }
        }
//-------------------------------------------------------------functions to be created: end--------------------------------------------------------


        /**
         * Build the projectObject that contains all
         * the data and function about the Project
         */
        function _buildProjectObject(_config) {            
            var _projectObject = {};

            //Will contain all the data about the Project
            _projectObject.data = {
                listContainerId: _config.listContainerId,
                companyId: _config.companyId,
                listAsset: _config.listAsset,
                controllerName: _config.controllerName,
                userId: _config.userId,
            };            

            _projectObject.scope = _config.scope;
            _createFunctionInit(_projectObject)
            _createFunctionGetProjectsByCompanyId(_projectObject);
            _createFunctionShowAssets(_projectObject);
            _createFunctionEditProject(_projectObject);
            _createFunctionSaveProjectFromPopup(_projectObject);
            _createFunctionValidationProjectFromPopup(_projectObject);    

            return _projectObject;
        }


        return {
            /**
             * Create a new instance of projectObject that contains all
             * the data and function about the Project
             * @returns 
             */
            newInstance: function(_config) {
                return _buildProjectObject(_config);
            }
        }
    }
]);

