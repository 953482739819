//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('assetDataFactory', ['assetDataServices', 'expanseConst', '$sce',  'myAlert', 'anomalyServices', 'componentServices', '$window', 'popup', '$mdDialog', 'popupConfirm',
    function(assetDataServices,  expanseConst, $sce,  myAlert,  anomalyServices, componentServices, $window, popup, $mdDialog, popupConfirm)  {
        var _isDeleteDatasetOn = false;
        
        var _assetDataTypes = {
            imagery: 'static_imagery',
            thermal: 'thermal_imagery',
            video: 'video',
            photogrammetry: '3d_photogrammetry',
            document: 'documents',
            report: 'report',
            image360Degrees: '360-image',
            lidar: 'lidar',
            spinModel: 'spin-model',
            matterport: 'matterport',
            orthomosaic: 'orthomosaic',
        }

        var _assetDataTaskList = {
            deleteAssetData: 'delete_asset-data',
            renameAssetData: 'rename_asset-data',
            downloadAssetData: 'download_asset-data',
            runPostProcessingAssetData: 'run-post-processing_asset-data',
            completeAsset: 'complete-asset',
        }

        var _assetDataTaskFunctions = [
            {
                task: _assetDataTaskList.deleteAssetData,
                name: 'Delete',
                fn: '_deleteAssetData',
                icon: '/img/icons/delete_select.png',
                isShownIfSelected: false,
                typesEnabled: [_assetDataTypes.report]
            },
            {
                task: _assetDataTaskList.renameAssetData,
                name: 'Rename',
                fn: '_renameAssetData',
                icon: '/img/icons/edit.png',
                isShownIfSelected: false,
                typesEnabled: [_assetDataTypes.report, _assetDataTypes.spinModel, _assetDataTypes.imagery, _assetDataTypes.thermal, _assetDataTypes.photogrammetry, _assetDataTypes.matterport, _assetDataTypes.orthomosaic]
            },
            {
                task: _assetDataTaskList.downloadAssetData,
                name: 'Download',
                fn: '_downloadAssetData',
                icon: '/img/icons/download.png',
                isShownIfSelected: true,
                typesEnabled: [_assetDataTypes.lidar]
            },
            {
                task: _assetDataTaskList.runPostProcessingAssetData,
                name: 'Post Processing',
                fn: '_runPostProcessingAssetData',
                icon: '/img/icons/download.png',
                isShownIfSelected: false,
                typesEnabled: [_assetDataTypes.imagery, _assetDataTypes.thermal]
            },
            {
                task: _assetDataTaskList.runCompleteAsset,
                name: 'Resume Upload',
                fn: '_runCompleteAsset',
                icon: '/img/icons/download.png',
                isShownIfSelected: false,
                typesEnabled: [_assetDataTypes.report, _assetDataTypes.spinModel, _assetDataTypes.imagery, _assetDataTypes.thermal, _assetDataTypes.photogrammetry, _assetDataTypes.matterport]
            }
        ]
            
        

        function _getMaxAnomalyArea(_list) {
            var _max = 0;
            
            if (_list && _list.length>0) {
                var _listArea = _list.map(l => {
                    return (l.drX-l.ulX)*(l.drY-l.ulY);
                })

                _max = Math.max(..._listArea)
            }

            return _max;
        }


        function _downloadAssetData(_this, _assetData) {
            if (_assetData.fileType == _assetDataTypes.lidar) {
                
                var _detail = _this.listAssetDataDetails.filter(e => e.facilityDataId == _assetData.id)[0];
                $window.open(_this.buildSrcUrl(_detail, _assetData).url, '_blank');    
                // assetDataServices.getPresignedUrl(_assetData.id, 1000*1*60, _res => {
                //     $window.open($sce.trustAsResourceUrl(_url), '_blank'); 
                // });
            }
        }

        function _deleteAssetData(_this, _assetData) {
            _fns.deleteAssetData(_this, _assetData);
        } 

        function _runPostProcessingAssetData(_this, _assetData) {
            _fns.runPostProcessingAssetData(_this, _assetData);
        }

        function _runCompleteAsset(_this, _assetData) {
            _fns.runCompleteAsset(_this, _assetData);
        }

        function _renameAssetData(_this, _assetData) {
            var _fns = [
                { 'btn_identifier':'_1_2', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear'},
                { 'btn_identifier':'_1_1', 'btn_msg': 'Save', 'btn_color': 'btn-primary', 'btn_fn': 'renameAssetDataFromPopup' }
            ];

            var _popupData = {
                assetData: _assetData,
            }

            if (_assetData.fileType == _assetDataTypes.report) {
                _popupData.fieldToRename = _assetData.name;
                _popupData.fieldName = 'Name';
            } else if (_assetData.fileType == _assetDataTypes.spinModel || _assetData.fileType == _assetDataTypes.imagery || _assetData.fileType == _assetDataTypes.thermal) {
                _popupData.fieldToRename = _assetData.name?_assetData.name:_assetData.description;
                _popupData.fieldName = 'Description';
            }


            var _config = {
                size: 'sm',
                title: 'Rename Dataset',
                ctrl: 'assetDataCtrl',
                data: JSON.stringify(_popupData),
                fns: JSON.stringify(_fns),
                htmlPage: 'views/asset-data/popup/asset-data_rename-assetdata_popup.html',
            }
            popup.openModalBody(_config);      

        } 


        var _fns =  {
            buildSrcUrl: function(_assetDataDetail, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId, _tagKey) {    
                var _ret = {
                    thumbUrl: undefined,
                    hdUrl: undefined,
                    mdUrl: undefined
                }
                
                if (_assetDataDetail) {
               
                    if (_assetData) {
                        if (_assetDataDetail.dataType == _assetDataTypes.video) {
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                
                        } else if (_assetDataDetail.dataType == _assetDataTypes.imagery) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                            _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            if (_tagKey) {
                                _ret.tagUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_tagKey+'/'+_assetDataDetail.fileName);
                            }
                        } else if (_assetDataDetail.dataType == _assetDataTypes.thermal) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);                        
                            _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);                            
                        }        
                    }
                }
                
                return _ret;
            },
            getAssetDataDetails: function(_assetDataId, _success, _error) {
                //Get all the asset-data-details about _assetDataId
                const _assetDataDetailsPromise = new Promise((resolve, reject) => {
                    assetDataServices.getAssetDataDetails(_assetDataId, resolve, reject);
                });

                //Get all the asset-data-details tags about an asset-data
                const _assetDataDetailsTagPromise = new Promise((resolve, reject) => {
                    assetDataServices.getAssetDataDetailsTag(_assetDataId, resolve, reject);
                });

                //Get all the anomaly tags about an asset-data
                const _anomalyTagPromise = new Promise((resolve, reject) => {
                    anomalyServices.getAnomalyTag(_assetDataId, resolve, reject);
                });

                //Get all the anomaly tags area about an asset-data
                const _anomalyTagAreaPromise = new Promise((resolve, reject) => {
                    anomalyServices.getAnomalyTagArea(_assetDataId, resolve, reject);
                });

                //Get all the component tags about an asset-data
                const _componentTagPromise = new Promise((resolve, reject) => {
                    componentServices.getComponentTag(_assetDataId, resolve, reject);
                });
                
                //Get all the asset-data-methane about an asset-data
                const _assetDataMethanePromise = new Promise((resolve, reject) => {
                    assetDataServices.getAssetDataMethane(_assetDataId, resolve, reject);
                });


                var  result = Promise
                .all([_assetDataDetailsPromise, _assetDataDetailsTagPromise, _anomalyTagPromise, _anomalyTagAreaPromise, _componentTagPromise, _assetDataMethanePromise])
                .then(([_assetDataDetails, _assetDataDetailsTag, _anomalyTag, _anomalyTagArea, _componentTag, _assetDataMethane]) => {
                    //add to each asset-data-details all the tags belonging to it
                    _assetDataDetails.forEach(e => e.listTags = _assetDataDetailsTag.filter(t => t.facilityDataDetailsId == e.id));    

                    //add to each anomaly tag the max area about the anomaly tag areas belonging to it
                    _anomalyTag.forEach(a => a.maxAnomalyTagArea = _getMaxAnomalyArea(_anomalyTagArea.filter(at => at.anomalyTagId == a.id)));


                    //add to each asset-data-details all the anomaly/component tags belonging to it
                    _assetDataDetails.forEach(e => {
                        e.listAnomalyTags = _anomalyTag.filter(t => t.facilityDataDetailsId == e.id)
                        if (!e.listAnomalyTags || e.listAnomalyTags.length == 0) {
                            e.listAnomalyTags = [{
                                anomalyTypeKey: 'none',
                                key: 'none',
                                facilityDataDetailsId: e.id,
                                maxAnomalyTagArea: 0
                            }]
                            // e.listAnomalyTags = [{
                            //     anomalyTypeKey: 'corrosion_none',
                            //     facilityDataDetailsId: e.id,
                            //     maxAnomalyTagArea: 0
                            // }]
                        }

                        e.listComponentTags = _componentTag.filter(t => t.facilityDataDetailsId == e.id)
                        if (!e.listComponentTags || e.listComponentTags.length == 0) {
                            e.listComponentTags = [{
                                key: 'none',
                                fddttKey: 'no-filter3',
                                facilityDataDetailsId: e.id,
                                maxAnomalyTagArea: 0
                            }]
                        } 

                    });    

                    //add to each asset-data-details all the methane data belonging to it
                    _assetDataDetails.forEach(e => e.listMethane = _assetDataMethane.filter(m => m.fddId == e.id));    

                    return _assetDataDetails;
                });                


                return result.then(_res => {
                    _success(_res)
                }, _err => {
                    // const message = _err ? _err.data ? _err.data.message ? _err.data.message : _err.data : _err : 'Undefined err';
                    myAlert("Error: " + _err.data.message, 'danger');
                    // myThrow(_err);
                    _error(_err)
                });
            },
            toggleDeleteDataset: function() {
                _isDeleteDatasetOn = !_isDeleteDatasetOn;

                return _isDeleteDatasetOn;
            },
            deleteDataset: function(_listDataset, _success, _error) {
                assetDataServices.deleteDataset(_listDataset, _res => {
                    _success(_res)
                }, _err => {
                    _error(_err)
                })
            },
            getAssetDataTaskList: function() {
                return _assetDataTaskList;
            },
            isAssetDataTaskFunctionAvailable: function(_type) {
                return _assetDataTaskFunctions.filter(e => e.typesEnabled.filter(t => t ==_type).length>0);
            },
            runAssetDataTaskFunction: function(_assetDataScope, _task, _assetData) {
                eval(_task.fn)(_assetDataScope, _assetData);
            },
            renameAssetData: function(_this, _popup, _assetData, _userId) {
                var _title = 'Rename data set';
                var _msg = 'Do you want to rename the data set? ';
                var _alertMsg = 'Dataset renamed';

                switch (_assetData.fileType) {
                    case _assetDataTypes.report:
                        _title = 'Rename the Engineer Report'
                        _msg = 'Do you want to rename the Engineer Report?';    
                        _alertMsg = 'Engineer Report renamed';
                        break;
                    case _assetDataTypes.spinModel:
                        _title = 'Rename the Spin Model'
                        _msg = 'Do you want to rename the Spin Model?';    
                        _alertMsg = 'Spin Model renamed';
                        break;
                    case _assetDataTypes.imagery:
                        _title = 'Rename the Static Imagery dataset'
                        _msg = 'Do you want to rename the Static Imagery dataset?';    
                        _alertMsg = 'Static Imagery dataset renamed';
                        break;
                    case _assetDataTypes.thermal:
                        _title = 'Rename the Thermal Imagery dataset'
                        _msg = 'Do you want to rename the Thermal Imagery dataset?';    
                        _alertMsg = 'Thermal Imagery dataset renamed';
                        break;
                    case _assetDataTypes.photogrammetry:
                        _title = 'Rename the 3D Photogrammetry'
                        _msg = 'Do you want to rename the 3D Photogrammetry?';    
                        _alertMsg = '3D Photogrammetry renamed';
                        break;
                    case _assetDataTypes.matterport:
                        _title = 'Rename the Matterport'
                        _msg = 'Do you want to rename the Matterport?';    
                        _alertMsg = 'Matterport renamed';
                        break;
                    case _assetDataTypes.orthomosaic:
                        _title = 'Rename the Orthomosasic'
                        _msg = 'Do you want to rename the Orthomosasic?';    
                        _alertMsg = 'Orthomosasic renamed';
                        break;

                        
                }


                _popup.updateData(_popup.data)
                var _confirm = popupConfirm(_title, _msg)

                $mdDialog.show(_confirm).then(_yes => {

                    if (_assetData.fileType == _assetDataTypes.report) {
                        _assetData.name = _popup.data.fieldToRename;
                        assetDataServices.renameEngineerReport(_userId, _assetData, _res => {
                            _this.assetDataResetAndRefresh();
                            
                            myAlert(_alertMsg, 'info')
                        })
                    } else if (_assetData.fileType == _assetDataTypes.spinModel || _assetData.fileType == _assetDataTypes.imagery || _assetData.fileType == _assetDataTypes.thermal ||
                            _assetData.fileType == _assetDataTypes.photogrammetry || _assetData.fileType == _assetDataTypes.matterport || _assetData.fileType == _assetDataTypes.orthomosaic) {
                        if (_assetData.name) {
                            _assetData.name = _popup.data.fieldToRename;
                        } else {
                            _assetData.description = _popup.data.fieldToRename;
                        }
                        assetDataServices.saveAssetData(_userId, _assetData, _res => {
                            _this.assetDataResetAndRefresh();
                            
                            myAlert(_alertMsg, 'info')
                        })
                    }
                })
            },
            deleteAssetData: function(_this, _assetData) {
                var _title = 'Delete data set';
                var _msg = 'Do you want to delete the data set? ';

                if (_assetData.fileType == _assetDataTypes.report) {
                    _title = 'Delete the Engineer Report'
                    _msg = 'Do you want to delete the Engineer Report?';
                }

                var _confirm = popupConfirm(_title, _msg)

                $mdDialog.show(_confirm).then(_yes => {
                    if (_assetData.fileType == _assetDataTypes.report) {
                        assetDataServices.deleteEngineerReport(_this.identity.id, _assetData, _res => {
                            _this.assetDataResetAndRefresh();
                            
                            myAlert('Engineer Report deleted', 'info')
                        })
                    }            
                });

            },
            runPostProcessingAssetData: function(_this, _assetData) {
                myAlert("Run Post Processing Function", 'info');    

                var Data = {"facility_data_id": _assetData.id}
        
                assetDataServices.runPostProcessing(Data, _res => {
                    _this.assetDataResetAndRefresh()                   
                    myAlert("Post Processing Function Completed", 'info');  
                })    
            },

            runCompleteAsset: function(_this, _assetData) {
                myAlert("Run Complete Asset Function Function", 'info');    

                _this.uploadAssetDataPopup(false, _assetData.id)  
            }

        };

        return _fns;
    }
]);

