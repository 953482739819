const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('dashboardAssetDataCmp', {
    templateUrl: 'views/dashboard/inspection-cmp/insp-asset-data.html',
    controller: DashboardAssetDataController,
    controllerAs: 'assetDataCtrl',
    bindings: {
        assetDataPromise: '<'
    }
});

/* @ngInject */
function DashboardAssetDataController($scope, Scopes, myLog, myAlert, utility, assetServices, customerServices, popup) {
    const _this = this;

    _this.assetData = [];
    _this.listAssetDataFileTypeByKey = [];

    const _identity = JSON.parse(localStorage.getItem('exlite.identity'));
    if (!_identity || !_identity.id) {
        myAlert('Identity missing', 'danger');
        return;
    }
    _this.identity = _identity;

    const _assetStatusTypes$ = new Promise((resolve, reject) => {
        // [{key: "status_type", value: "Status name"}]
        assetServices.getAssetStatusTypes(resolve, reject);
    });

    const _customerStatusTypes$ = new Promise((resolve, reject) => {
        [
            {key:'active', value:'Active'},
            {key:'inactive', value:'Inctive'}
        ]
    });

    _this.companyTypes = customerServices.companyType;
    _this.companyType = _getCompanyType(_this.companyTypes, _identity);

    _this.userType = _identity.rolesType;


    


    _this.$onInit = () => {
        $scope._initAssetData();
    };

    var _controllerName = 'testController';
    Scopes.store(_controllerName, $scope);

    function _getAssetDataFileTypes(_success, _error) {
        var _array = [];

        assetServices.getAssetDataFileTypes(_res => {
            
            _success ? _success(_res) : null;

            myLog("Asset data file types list:", _res);
        }, _err => {
            _error ? _error(_err) : null;

            myAlert("Error: " + _err.data.message, 'danger');
            myThrow(_err);
        });
    }


    _getAssetDataFileTypes(_res => {
        _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');

    });

    function dynamicSort(property) {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder == -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }

    $scope._initAssetData = function() {

        if(!_this.assetDataPromise) {
            return;
        }

        _this.assetDataPromise.then(assetData => {
            assetData = assetData.filter(a=>(a.file_type!='spin-model' && a.file_type!='checklist'))
            _this.assetData = assetData
                .sort((a,b) => b.createdDate - a.createdDate);
        });

        customerServices.getAllCustomers(_identity.companyId, _identity.id, _res => {
            _this.listCustomer = _res;

            _this.listCustomer = _this.listCustomer.sort(dynamicSort("name"));

            _this.listCustomer.forEach(function (element) {
                element.showDetails = false;
                element.emailList = [];
            });  

            _this.listInspectors = [];
            if (_this.identity.rolesType == 'SKY_FUTURES_ADMIN' || _this.identity.rolesType == 'ACCOUNT_OWNER' || _this.identity.rolesType == 'COMPANY_ADMIN' ){
    
                _this.listCustomer.forEach(function (element) {
    
                    customerServices.getAllInspectors(_identity.companyId, _identity.id, element.id, _res => {
    
                        if (_res != null){
                            _res['listInspectors'].forEach(function (ins) {
                                _this.listInspectors.push({'text':ins['login'], 'customerId': element.id})
                            })
                        }
    
                        }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })
                });
            }

            }, _err => {
            myAlert("Error: " + _err.data.message, 'danger');
            myThrow(_err);
        })  
        
    }
    

    _this.getIconNameByFileUploadType = function(file_type) {

        if (typeof _this.listAssetDataFileTypeByKey[file_type] != 'undefined'){
            return _this.listAssetDataFileTypeByKey[file_type].imgName;
        }
        
    } 

    _this.changeStatus = function(id, status){

        var objIndex = _this.assetData.findIndex((obj => obj.id == id));
        _this.assetData[objIndex].status = status;

        console.log(id, objIndex)

        assetServices.changeAssetDataStatus(id, status, _res => {

            myLog("Asset status updataed");
        }, _err => {
            _error ? _error(_err) : null;

            myAlert("Error: " + _err.data.message, 'danger');
            myThrow(_err);
        });

    }

    $scope.changeEmailList = function (_popup) {

         if (typeof _popup != "undefined")
         {
            // if (_popup.innerStatus.validated == true)
            // {

                for (var i = 0; i < _popup.data.customerList.length; i++) {               
                // if (typeof _popup.data.listEmails != "undefined" && _popup.data.listEmails != "")
                // {
                    var element = _popup.data.customerList[i];
                    var emails =[];

                    element.emailList.forEach(function (singleemail) {
                        emails.push(singleemail['text']);
                    })
                    // define single email validator here
                    var re = /\S+@\S+\.\S+/; 

                        
                    // angular.foreach(emails, function() {
                    var validityArr = emails.map(function(str){
                        return re.test(str.trim());
                    }); // sample return is [true, true, true, false, false, false]
                    console.log(emails, validityArr); 
                    var atLeastOneInvalid = false;
                    angular.forEach(validityArr, function(value) {
                    if(value === false)
                        atLeastOneInvalid = true; 
                    }); 


                    //check if the  mail belongs to inspectors
                    var emailFinalList = []
                    emails.forEach(function (e){
                        var check = _this.listInspectors.filter(ins => (ins['text'] == e && ins['customerId'] == element.id))
                        if (check.length >0){
                            emailFinalList.push(e)
                        }
                    })

                    // if (emailFinalList.length == 0){
                    //     atLeastOneInvalid = true;
                    // }
                    

                    if(!atLeastOneInvalid && (_this.identity.rolesType == 'SKY_FUTURES_ADMIN' || _this.identity.rolesType == 'ACCOUNT_OWNER' || _this.identity.rolesType == 'COMPANY_ADMIN' )) { 
                        // ^ all I need is to call the angular email checker here, I think.
                        assetServices.updateNotificationList(emailFinalList, _popup.data.companyId,  element.id, _res => {
 
                            myLog("Asset status updataed");
                        }, _err => {
                            _error ? _error(_err) : null;
                
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });
                    } //else {
                    // // ctrl.$setValidity('multipleEmails', false);
                    //     myAlert("Email format not correct", 'danger');
                    // }
                // }

                
            }
            $scope._initAssetData()
        }
    }

    // _this.emailValidator = function(){
    //     return function(_popup){

    //         if (typeof _popup != "undefined") {
                
    //             if (typeof _popup.data.listEmails != "undefined" && _popup.data.listEmails != "") {
    //                 var emails = _popup.data.listEmails.split(',');
    //                 // define single email validator here
    //                 var re = /\S+@\S+\.\S+/; 
                        
    //                 // angular.foreach(emails, function() {
    //                 var validityArr = emails.map(function(str){
    //                     return re.test(str.trim());
    //                 }); // sample return is [true, true, true, false, false, false]
    //                 console.log(emails, validityArr); 
    //                 var atLeastOneInvalid = false;
    //                 angular.forEach(validityArr, function(value) {
    //                 if(value === false)
    //                     atLeastOneInvalid = true; 
    //                 }); 
    //                 if(!atLeastOneInvalid) { 
    //                 // ^ all I need is to call the angular email checker here, I think.
    //                 // ctrl.$setValidity('multipleEmails', true);
    //                 if (_this.identity.rolesType == 'SKY_FUTURES_ADMIN' || _this.identity.rolesType == 'ACCOUNT_OWNER' || _this.identity.rolesType == 'COMPANY_ADMIN' ){
    //                     _popup.innerStatus.validated = true;
    //                 }
    //                 return _popup.data.listEmails;
    //                 } else {
            
    //                 // ctrl.$setValidity('multipleEmails', false);
    //                 _popup.innerStatus.validated = false;
    //                 return undefined;
    //                 }
    //             }
    //             else{
    //                 if (_this.identity.rolesType == 'SKY_FUTURES_ADMIN' || _this.identity.rolesType == 'ACCOUNT_OWNER' || _this.identity.rolesType == 'COMPANY_ADMIN' ){
    //                     _popup.innerStatus.validated = true;
    //                 }
    //                 else{
    //                     _popup.innerStatus.validated = false;
    //                 }
    //                 return _popup.data.listEmails;
    //             }
    //         }
    //         else{
    //             return undefined
    //         }
    //     }
    // }

    _this.showInventoryItemDetails= function(_inventoryItem) {
        
        return function(_inventoryItem) {
            // $("#_asset_panel").scrollTop( $("#_structure_fields").offset().top );  
            _inventoryItem.showDetails = !_inventoryItem.showDetails;                    

        }
    }

    _this.loadTags = function(query, customerID) {
        return function (query, customerID) { 
            return _this.listInspectors.filter(e => (e['text'].includes(query) && e['customerId'] == customerID));
            
        };
    };

    _this.checkUser = function(){
        return function (){
            var dis = true;
            if (_this.identity.rolesType == 'SKY_FUTURES_ADMIN' || _this.identity.rolesType == 'ACCOUNT_OWNER' || _this.identity.rolesType == 'COMPANY_ADMIN' ){
                dis = false;
            }
            return dis
        }
    }


    _this.emailPopup = function(){

        assetServices.getNotificationList(_identity.companyId, _res => {

            _this.listEmails;
            if (_res != null){
                _this.listEmails = _res['listmails'].map(ele => ele['email_address']).toString()

                _this.listCustomer.forEach(function (element) {
                    var emailCustomerTo = _res['listmails'].filter(ele => ele['customer_to'] == element.id)
                    if (typeof emailCustomerTo[0] != 'undefined'){
                        element.emailList = [];
                        emailCustomerTo.forEach(function (emailcusto) {
                            element.emailList.push(emailcusto['email_address'])
                        });                     
                    }                
                });  
            }

            var Data = {
                listEmails: _this.listEmails,
                companyId: _identity.companyId,
                companyType: _this.companyType,
                customerList: _this.listCustomer,
                listInspectors: _this.listInspectors
            }

            // 'btn_visible_if': '$innerStatus.validated',
    
            var _fns = [
                { 'btn_identifier': '_1_1', 'btn_close': true, 'btn_msg': 'Save',  'btn_color': 'btn-light', 'btn_fn': 'changeEmailList'},
                { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true,  'btn_msg': 'Close', 'btn_color': 'btn-clear'},
            ];
            
                
            var _config = {
                'funzione': {
                    // emailValidator: _this.emailValidator(),
                    changeEmailList: $scope.changeEmailList(),
                    showInventoryItemDetails: _this.showInventoryItemDetails(),
                    loadTags: _this.loadTags(),
                    _initAssetData: $scope._initAssetData(),
                    checkUser: _this.checkUser()
                },
                'size': 'lg',
                'title': 'Send Email Notification',
                'ctrl':'testController',
                'fns': JSON.stringify(_fns),
                'data': JSON.stringify(Data),
                'htmlPage': '/views/dashboard/inspection-cmp/popup/email_notification_list.html',
                'innerStatus': {
                    'validated': false
                }
            }
    
            popup.openModalBody(_config);

             

    
        });

        

    }

    // get current customer company type
    function _getCompanyType(_types, _identity) {
        if (!_identity) {
            return _types.No;
        }

        return _identity.company.inspectingCompanyId ?
            _types.Inspected :
            _types.Inspecting;
    }
   
}
