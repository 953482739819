//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('compassViewerFactory', ['$timeout', 'myHttp', 'myLog',  'utility', 'myAlert', 'googlemaps', 'assetServices', 'assetDataServices',
    function($timeout, myHttp, myLog, utility, myAlert, googlemaps, assetServices, assetDataServices)  {

        function _getGeoPositions() {
            var _geoPositions = [
                {position: 'N', order: 1},
                {position: 'NE', order: 2},
                {position: 'E', order: 3},
                {position: 'SE', order: 4},
                {position: 'S', order: 5},
                {position: 'SW', order: 6},
                {position: 'W', order: 7},
                {position: 'NW', order: 8},
            ];

            return {
                getPositionsClockwise: function(__defaultValue) {
                    var _ret = []
                    var _defaultValue = (__defaultValue != undefined)?__defaultValue:true;
                    _geoPositions.forEach(e => _ret.push({position: e.position, value: _defaultValue}))

                    return _ret;
                }
            };
        }

        function _createCompassViewerMap(_cvObject, _success) {

            googlemaps.initGoogleMap("#"+_cvObject.data.canvasId, _m => {
                //move the compass-container element into the google-map inner div 
                //this is necessary to show the compass-container when show google-map on fullscreen
                utility.callFunctionEveryTime(' $(".compass-container").get(0)', 200, function() {
                    var _element = $(".compass-container").detach();
                    $("#"+_cvObject.data.canvasId+" >div").append(_element)    
                })

                var _map = _m;
                _cvObject.data.map = _map;

                _setImagesGeoPosition(_cvObject.data.listAssetDataDetails, _cvObject.data.asset, _cvObject.data.geoPositions);

                _cvObject.data.listTagsInAssetDataDetails = _getTagsByAssetDataDetails(_cvObject.data.listAssetDataDetails, _cvObject.data.listAssetDataDetailsTagTypes)
                _cvObject.data.listTagsInAssetDataDetails.forEach(e => e.isSelected = true)    

                _updateImagesVisible(_cvObject, _cvObject.data.listAssetDataDetails, _cvObject.data.asset, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails);

                _cvObject.data.listImageMarker = _putImageMarkersOnMap(_cvObject, _cvObject.data.scope, _map, _cvObject.data.listAssetDataDetails);

                _fitAllMarkers(_cvObject);

                _success?_success():undefined;
            });    
        }        

        function _collectInfo(_cvObject) {
            //Get all the assetDataDetails' records of KMZ-KML type, about the the assetId 
            assetDataServices.getAssetDataDetailsByAssetIdAndFileExtensions(_cvObject.data.asset.id, 'kml,kmz', _res => {
                _cvObject.data.listAssetDataDetailsOfKmzKml = _res;
            })

            //Get the asset's status types
            assetServices.getAssetStatusTypes(_res => {
                _cvObject.data.listAssetStatusTypeByKey = utility.getArrayByField(_res, 'key');
            })


        }

        function _updateTagsByTagType(_tags, _assetDataDetails, _listTags, _listTagTypesByKey, _tagType) {
            _listTags.forEach(t => {                
                var _tag;
                
                switch (_tagType) {
                    case 'anomaly':
                        _tag = _listTagTypesByKey[t.anomalyTypeKey]                        
                        break;
                    case 'component':
                        _tag = _listTagTypesByKey[t.componentTypeId]
                        break;                
                }

                if (_tag) {
                    var _tagTmp = _tags[_tag.key];
                    if (!_tagTmp) {
                        _tagTmp = _tag;
                        _tagTmp.count = 0;
                        _tagTmp.listAssetDataDetails = [_assetDataDetails]
                        _tags[_tag.key] = _tagTmp;
                    } else {
                        _tagTmp.listAssetDataDetails.push(_assetDataDetails);
                    }

                    _tagTmp.count += 1;
                }
            })
        }


        function _getTagsByAssetDataDetails(_listAssetDataDetails, _listAssetDataDetailsTagTypes) {
            var _ret = [];
            var _tags = [];
            var _listAnomalyTagTypesByKey = utility.getArrayByField(_listAssetDataDetailsTagTypes.filter(e => e.tagType=='anomaly'), 'key');
            var _listComponentTagTypesByKey = utility.getArrayByField(_listAssetDataDetailsTagTypes.filter(e => e.tagType=='component'), 'key');

            //select all the elements with tags
            var _listADDWithTags = [];
            _listADDWithTags = _listADDWithTags.concat(_listAssetDataDetails.filter(e => e.listAnomalyTags[0].key!='none'))
            _listADDWithTags = _listADDWithTags.concat(_listAssetDataDetails.filter(e => e.listComponentTags[0].key!='none'))

            _listADDWithTags.forEach(e => {
                // e.listAnomalyTags.forEach(at => {
                //     var _anomalyTag = _listAnomalyTagTypesByKey[at.anomalyTypeKey]
                //     if (_anomalyTag) {
                //         var _tag = _tags[_anomalyTag.key];
                //         if (!_tag) {
                //             _tag = _anomalyTag;
                //             _tag.count = 0;
                //             _tag.listAssetDataDetails = [e]
                //             _tags[_anomalyTag.key] = _tag;
                //         } else {
                //             _tag.listAssetDataDetails.push(e);
                //         }

                //         _tag.count += 1;
                //     }
                // })
                _updateTagsByTagType(_tags, e, e.listAnomalyTags, _listAnomalyTagTypesByKey, 'anomaly')
                _updateTagsByTagType(_tags, e, e.listComponentTags, _listComponentTagTypesByKey, 'component')
            })

            _ret.push({
                key: 'none',
                value: 'No Tag',
                count: _listAssetDataDetails.filter(e => (e.listAnomalyTags[0].key=='none' && e.listComponentTags[0].key=='none')).length,
                listAssetDataDetails: _listAssetDataDetails.filter(e => (e.listAnomalyTags[0].key=='none' && e.listComponentTags[0].key=='none'))
            })    

            Object.keys(_tags).forEach(k => {
                _ret.push(_tags[k]);
            })

            return _ret;
        }

        function _setImagesGeoPosition(_listAssetDataDetails, _asset, _geoPositions) {

            _listAssetDataDetails.forEach(e => {
                if (!e.geoPosition) {
                    e.geoPosition =  utility.getGeoPosition(e.latitude, e.longitude, _asset.latitude, _asset.longitude)
                }
            });
        }

        function _hasImageTagSelected(_listTag, _listTagsInAssetDataDetails, _tagType) {
            var _ret = false;

            _listTag.find(t => {
                var _key;
                switch (_tagType) {
                    case 'anomaly':
                        _key = t.anomalyTypeKey
                        break;
                    case 'component':
                        _key = t.componentTypeId
                        break;
                }

                _listTagsInAssetDataDetails.filter(e => e.isSelected).find(ta => {
                    if (_key == ta.key) {
                        _ret = true;
                        return true;    
                    }
                })
                return _ret;
            })

            return _ret;
        }

        function _updateImagesVisible(_cvObject, _listAssetDataDetails, _asset, _geoPositions, _listTagsInAssetDataDetails) {
            var _listAssetDataDetailsFiltered = [];            
            // var _geoPositionsByPosition = utility.getArrayByField(_geoPositions, 'position');

            _listAssetDataDetails.forEach(e => {

                // var _geoPositionValue = _geoPositionsByPosition[e.geoPosition].value;
                // var _tagAnomalyValue = _hasImageTagSelected(e.listAnomalyTags, _listTagsInAssetDataDetails, 'anomaly');
                // var _tagComponentValue = _hasImageTagSelected(e.listComponentTags, _listTagsInAssetDataDetails, 'component');

                // e.isVisible = _geoPositionValue && (_tagAnomalyValue || _tagComponentValue);
                e.isVisible = _isImageVisible(e, _geoPositions, _listTagsInAssetDataDetails)

                if (e.isVisible) {
                    _listAssetDataDetailsFiltered.push(e);
                }
            })

            _cvObject.data.listAssetDataDetailsFiltered = _listAssetDataDetailsFiltered;
        }

        function _isImageVisible(_assetDataDetails, _geoPositions, _listTagsInAssetDataDetails) {
            var _geoPositionsByPosition = utility.getArrayByField(_geoPositions, 'position');

            var _geoPositionValue = _geoPositionsByPosition[_assetDataDetails.geoPosition].value;
            var _tagAnomalyValue = _hasImageTagSelected(_assetDataDetails.listAnomalyTags, _listTagsInAssetDataDetails, 'anomaly');
            var _tagComponentValue = _hasImageTagSelected(_assetDataDetails.listComponentTags, _listTagsInAssetDataDetails, 'component');

            return _geoPositionValue && (_tagAnomalyValue || _tagComponentValue);
        }


        function _putImageMarkersOnMap(_cvObject, _scope, _map, _listAssetDataDetails, _geoPosition, _tag) {
            var _markers = [];

            if (!_geoPosition) {
                if (!_tag) {
                    _listAssetDataDetails.filter(e => e.isVisible).forEach(e => _markers.push(_createImageMarker(_scope, _map, e.latitude, e.longitude, e)))
                } else {
                    _tag.listAssetDataDetails.filter(e => _isImageVisible(e, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails))
                        .forEach(e => _markers.push(_createImageMarker(_scope, _map, e.latitude, e.longitude, e)));
                }
            } else {
                // _listAssetDataDetails.filter(e => e.geoPosition == _geoPosition.position).forEach(e => _markers.push(_createImageMarker(_scope, _map, e.latitude, e.longitude, e)));
                _listAssetDataDetails.filter(e => e.geoPosition == _geoPosition.position && _isImageVisible(e, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails))
                    .forEach(e => _markers.push(_createImageMarker(_scope, _map, e.latitude, e.longitude, e)));
            }

            // //Set out the map zoom based on the markers position
            // googlemaps.fitBounds(_map, _markers);

            return _markers
        }

        function _createImageMarker(_scope, _map, _lat, _lng, _assetDataDetails) {
            const _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                icon: '/img/icons/markers/marker-photos.png',
                title: _assetDataDetails.fileName,
                lat: _lat,
                log: _lng,
                id: _assetDataDetails.id,
                geoPosition: _assetDataDetails.geoPosition,
                pathImg: ""+_scope.buildSrcUrl(_assetDataDetails).mdUrl
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
            });

            return _marker;
        }


        // function _switchGeoPosition(_cardinal_point) {
        function _switchGeoPosition(_cvObject, _geoPosition) {
            _geoPosition.value = !_geoPosition.value;

            //Add/Remove the markers respect to asset-data-details's isVisible value
            if (_geoPosition.value) {
                //add markers about new asset-data-details shown
                _cvObject.data.listImageMarker = _cvObject.data.listImageMarker.concat(_putImageMarkersOnMap(_cvObject, _cvObject.data.scope, _cvObject.data.map, _cvObject.data.listAssetDataDetails, _geoPosition))
                console.log('add.........', _cvObject.data.listImageMarker.length)
            } else {
                //remove markers about new asset-data-details hidden
                _cvObject.data.listImageMarker = _deleteMarkers(_cvObject.data.listImageMarker, _geoPosition);
                console.log('del........', _cvObject.data.listImageMarker.length)
            }

            _fitAllMarkers(_cvObject);

            _updateImagesVisible(_cvObject, _cvObject.data.listAssetDataDetails, _cvObject.data.asset, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails);
        }

        function _deleteMarkers(_listMarker, _geoPosition, _tag) {            
            var _ret; 
            
            if (_geoPosition) {
                _ret= _listMarker.filter(e => e.geoPosition != _geoPosition.position);
                _listMarker.filter(e => e.geoPosition == _geoPosition.position).forEach(e => e.setMap(null));        
            } else if (_tag) {
                _ret= _listMarker.filter(e => _tag.listAssetDataDetails.filter(t => e.id == t.id).length==0);
                _listMarker.filter(e => _tag.listAssetDataDetails.filter(t => e.id == t.id).length>0).forEach(e => e.setMap(null));        
            }

            return _ret;
        }        

        function _showCompassViewerMarkerInfo(_cvObject, _assetDataDetails) {
            _cvObject.data.listImageMarker.some(e => {
                if (e.id == _assetDataDetails.id) {
                    googlemaps.setInfoWindow(_cvObject.data.map, e, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
                }
            })
        }

        /**
         * Retrieve from db all the customer's assets 
         * Mind that "assets, estates and networkLink" are all asset types
         * @param {*} _cvObject 
         * @param {*} _customerId 
         */
        function _getAssets(_cvObject, _customerId, _userId, _success)  {
            //check if I already fetched the data
            if (!_cvObject.data.listAsset && !_cvObject.data.listNetworkLink) {
                assetServices.getCustomerAssets(_userId, false, _customerId, _res => {

                    _cvObject.data.listAsset = _res.filter(e => e.facilityTypeId && _cvObject.data.listAssetTypeKey[e.facilityTypeId].key != 'estate' && _cvObject.data.listAssetTypeKey[e.facilityTypeId].key != 'networkLink');
                    _cvObject.data.listNetworkLink = _res.filter(e => e.facilityTypeId && _cvObject.data.listAssetTypeKey[e.facilityTypeId].key == 'networkLink');                        

                    _success(_cvObject.data.listAsset, _cvObject.data.listNetworkLink);
                })
            } else {
                _success(_cvObject.data.listAsset, _cvObject.data.listNetworkLink);
            }
        }

        // function _setAssetMarkerIcon(_marker, _asset, _listAssetTypeKey) {
        //     if (_asset.facilityTypeId) {
        //         var _ret = 'marker-' + _listAssetTypeKey[_asset.facilityTypeId].key + "_" + (_asset.status?_asset.status:'unknown') + '.png';

        //         _marker.setIcon('/img/icons/markers/' + _ret);
        //     } else {
        //         return null;
        //     }
        // }

        function _createAssetMarker(_cvObject, _scope, _map, _lat, _lng, _asset, _listAssetTypeKey, _listAssetStatusTypeByKey) {
            var _icon = '/img/icons/markers/marker-' + _listAssetTypeKey[_asset.facilityTypeId].key + "_" + (_asset.status?_asset.status:'unknown') + '.png';

            const _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                icon: _icon,
                title: _asset.name,
                lat: _lat,
                log: _lng,
                id: _asset.id,
                facilityType: _listAssetTypeKey[_asset.facilityTypeId],
                facilityStatusType: _listAssetStatusTypeByKey[(_asset.status?_asset.status:'unknown')],
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                googlemaps.setInfoWindow(_map, _marker, _asset, _icon, 'views/asset-data/google-map_popup/asset-estate_info.html');
            });
            
            return _marker;
        }

        function _toggleDisplayAssets(_cvObject) {        
            _cvObject.data.flagDisplayAssets = !_cvObject.data.flagDisplayAssets;

            if (_cvObject.data.flagDisplayAssets) {
                // assetServices.getAssetStatusTypes(_res => {
                    // _cvObject.data.listAssetStatusTypeByKey = utility.getArrayByField(_res, 'key');

                    //I'm gonna create the assets' markers
                    _getAssets(_cvObject, _cvObject.data.customerId, _cvObject.data.userId, (_listAsset, _listNetworkLink) => {
                        var _markers = [];

                        _listAsset.forEach(e => _markers.push(_createAssetMarker(_cvObject, _cvObject.data.scope, _cvObject.data.map, e.latitude, e.longitude, e, _cvObject.data.listAssetTypeKey, _cvObject.data.listAssetStatusTypeByKey)));

                        //Set out the map zoom based on the markers position
                        googlemaps.fitBounds(_cvObject.data.map, _markers);

                        _cvObject.data.listAssetMarker = _markers;
                    });
                // });

            } else {
                //I'm gonna delete the assets' markers
                if (_cvObject.data.listAssetMarker) {
                    _cvObject.data.listAssetMarker.forEach(e => e.setMap(null));
                    _cvObject.data.listAssetMarker = undefined;
                }

                _fitAllMarkers(_cvObject);
            }
        }


        function _putNetworkLinkMarkersOnMap(_map, _listNetworkLink, _listAsset, _listAssetTypeKey, _success) {
            var _listMarker = [];
            var _listNetworkLinkElaborated = [];

            if (_listNetworkLink && _listNetworkLink.length > 0) {
                var _listAssetById = utility.getArrayByField(_listAsset, 'id');

                _listNetworkLink.forEach((e, _index) => {
                    if (!_listNetworkLink[_index].listLineCoordination) {
                        assetServices.getNetworkLink(e.id, _res => {
                            _listNetworkLinkElaborated.push(_listNetworkLink[_index]);  
                            
                            var _listCoordinate = [];

                            _res.forEach(c => {
                                var _asset = _listAssetById[c.facilityId];

                                _listCoordinate.push({
                                    lat: _asset.latitude,
                                    lng: _asset.longitude,
                                    asset: _asset
                                })
                            })                            
                            _listNetworkLink[_index].listLineCoordination = _listCoordinate;

                            _listMarker = _listMarker.concat(_putNetworkLinkMarkerOnMap(_map, e, _listCoordinate, _listAssetById, _listAssetTypeKey))
                            // var _listCoordinate = [];

                            // _res.forEach(c => {
                            //     var _asset = _listAssetById[c.facilityId];

                            //     _listCoordinate.push({
                            //         lat: _asset.latitude,
                            //         lng: _asset.longitude,
                            //         asset: _asset
                            //     })
                            // })

                            // _listNetworkLink[_index].listLineCoordination = _listCoordinate;

                            // if (_listCoordinate.length > 0) {
                            //     //Create a marker based on an asset
                            //     //and put the object returned, from the function, into a list of marker
                            //     _listMarker.push(_createNetworkLinkMarker(_map, e.latitude, e.longitude, e));

                            //     _listCoordinate.forEach(c => {
                            //         var _marker = googlemaps.createAssetMarker(_map, c.lat, c.lng, c.asset);
                            //         _marker.type="marker"
                            //         _marker.icon = '/img/icons/markers/marker-' + _listAssetTypeKey[c.asset.facilityTypeId].key + "_" + (c.asset.status?c.asset.status:'unknown') + '.png',

                            //         _listMarker.push(_marker)

                            //     })
                            // }

                            if (_listNetworkLink.length == _listNetworkLinkElaborated.length) {
                                _success(_listMarker);
                            }
                        })
                    } else {
                        _listNetworkLinkElaborated.push(_listNetworkLink[_index]);                                
                        _listMarker = _listMarker.concat(_putNetworkLinkMarkerOnMap(_map, e, e.listLineCoordination, _listAssetById, _listAssetTypeKey))

                        if (_listNetworkLink.length == _listNetworkLinkElaborated.length) {
                            _success(_listMarker);
                        }
                    }
                })
            }
        }

        function _putNetworkLinkMarkerOnMap(_map, _networkLink, _listCoordinate, _listAssetById, _listAssetTypeKey) {
            var _listMarker = [];

            if (_listCoordinate.length > 0) {
                //Create a marker based on an asset
                //and put the object returned, from the function, into a list of marker
                _listMarker.push(_createNetworkLinkMarker(_map, _networkLink.latitude, _networkLink.longitude, _networkLink));

                _listCoordinate.forEach(c => {
                    var _marker = googlemaps.createAssetMarker(_map, c.lat, c.lng, c.asset);
                    _marker.type="marker"
                    _marker.icon = '/img/icons/markers/marker-' + _listAssetTypeKey[c.asset.facilityTypeId].key + "_" + (c.asset.status?c.asset.status:'unknown') + '.png',

                    _listMarker.push(_marker)

                })
            }

            return _listMarker;
        }

        function _createNetworkLinkMarker(_map, _lat, _lng, _networkLink, _icon) {
            var _color;

            switch (_networkLink.status) {
                case 'good_condition':
                    _color = '#59B031';
                    break;
                case 'maintenance_schedule':
                    _color = '#FFD600';
                    break;
                case 'low_schedule':
                    _color = '#F18A16';
                    break;
                case 'urgent_attention':
                    _color = '#FF0000';
                    break;
                case 'unknown':
                    _color = '#7E8C8D';
                    break;
            }


            var _networkLinkLine = new google.maps.Polyline({
                path: _networkLink.listLineCoordination,
                strokeColor: _color,
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: _color,
                fillOpacity: 0.35,
                type:"link",
                map: _map
            });

            return _networkLinkLine;
        }        

        function _toggleDisplayLinks(_cvObject) {
            _cvObject.data.flagDisplayLinks = !_cvObject.data.flagDisplayLinks;

            if (_cvObject.data.flagDisplayLinks) {
                //I'm gonna create the networkLinks' markers
                _getAssets(_cvObject, _cvObject.data.customerId, _cvObject.data.userId, (_listAsset, _listNetworkLink) => {
                    var _markers = [];

                    _putNetworkLinkMarkersOnMap(_cvObject.data.map, _listNetworkLink, _listAsset, _cvObject.data.listAssetTypeKey, _res => {
                        _cvObject.data.listLinkMarker = _res;
                        //Set out the map zoom based on the markers position
                        googlemaps.fitBoundPolylines(_cvObject.data.map, _cvObject.data.listLinkMarker.filter(e => e.type=='link'));
                    });
                });
            } else {
                //I'm gonna delete the networkLinks' markers
                if (_cvObject.data.listLinkMarker) {
                    _cvObject.data.listLinkMarker.forEach(e => e.setMap(null));
                    _cvObject.data.listLinkMarker = undefined;
                }
                _fitAllMarkers(_cvObject);
            }
        }

        function _fitAllMarkers(_cvObject) {
            var _markers = [];
            if (_cvObject.data.listAssetMarker) _markers = _markers.concat(_cvObject.data.listAssetMarker);
            if (_cvObject.data.listLinkMarker) _markers = _markers.concat(_cvObject.data.listLinkMarker.filter(e => e.type!='link'));
            if (_cvObject.data.listImageMarker) _markers = _markers.concat(_cvObject.data.listImageMarker);
            if (_cvObject.data.listkmlMarker) {
                _cvObject.data.listkmlMarker.forEach(e => _markers.push(e));
            }

            //Set out the map zoom based on the markers position
            googlemaps.fitBounds(_cvObject.data.map, _markers);

        }

        function _toggleDisplayImages(_cvObject)  {
            _cvObject.data.flagDisplayImages = !_cvObject.data.flagDisplayImages;

            if (_cvObject.data.flagDisplayImages) {
                _updateImagesVisible(_cvObject, _cvObject.data.listAssetDataDetails, _cvObject.data.asset, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails);

                _cvObject.data.listImageMarker = _putImageMarkersOnMap(_cvObject, _cvObject.data.scope, _cvObject.data.map, _cvObject.data.listAssetDataDetails);
            } else {
                _cvObject.data.listAssetDataDetailsFiltered = [];
                _cvObject.data.listImageMarker.forEach(e => e.setMap(null));
                _cvObject.data.listImageMarker = undefined;
            }

            _fitAllMarkers(_cvObject);
        }



        function _toggleImagesWithAnomalyTag(_cvObject, _tag) {
            _tag.isSelected = !_tag.isSelected;

            if (_tag.isSelected) {
                //add markers about new asset-data-details shown
                _cvObject.data.listImageMarker = _cvObject.data.listImageMarker.concat(_putImageMarkersOnMap(_cvObject, _cvObject.data.scope, _cvObject.data.map, _cvObject.data.listAssetDataDetails, undefined, _tag))

            } else {
                //remove markers about new asset-data-details hidden
                _cvObject.data.listImageMarker = _deleteMarkers(_cvObject.data.listImageMarker, undefined, _tag);
            }
            _fitAllMarkers(_cvObject);
            _updateImagesVisible(_cvObject, _cvObject.data.listAssetDataDetails, _cvObject.data.asset, _cvObject.data.geoPositions, _cvObject.data.listTagsInAssetDataDetails);
        }

        function _toggleKmzKmlFiles(_cvObject, _file) {
            _file.isSelected = !_file.isSelected;

            if (_file.isSelected) {
                if (!_cvObject.data.listkmlMarker) {
                    _cvObject.data.listkmlMarker = [];
                }

                var _assetData = _cvObject.data.listAssetData.find(e => {if (e.id == _file.facilityDataId) {return true}});
                var _urlS3 = _cvObject.data.scope.buildSrcUrl(_file, _assetData).url;
    
                var _kmlLayer = new google.maps.KmlLayer(_urlS3, {
                    suppressInfoWindows: false,
                    preserveViewport: true,
                    map: _cvObject.data.map,
                    id: _file.id,
                    type: 'kml-kmz'
                  });  
                _cvObject.data.listkmlMarker.push(_kmlLayer)          

                google.maps.event.addListener(_kmlLayer, 'defaultviewport_changed', function(kmlEvent) {
                    _fitAllMarkers(_cvObject);
                });

            } else {
                var _index
                _cvObject.data.listkmlMarker.find((e, index) => {if (e.id == _file.id) {_index=index; return true}}).setMap(null);
                _cvObject.data.listkmlMarker.splice(_index, 1);
                _fitAllMarkers(_cvObject);
            }

        }

        function _updateListAsset(_cvObject, _asset) {
            _cvObject.data.listAsset.some((e, index) => {
                if (e.id == _asset.id) {
                    _cvObject.data.listAsset[index] = _asset;
                    return true;
                }
            })
        }

        function _updateListAssetMarker(_cvObject, _asset) {
            _cvObject.data.listAssetMarker.some((e, index) => {
                if (e.id == _asset.id) {
                    e.setMap(null);
                    _cvObject.data.listAssetMarker.push(_createAssetMarker(_cvObject, _cvObject.data.scope, _cvObject.data.map, _asset.latitude, _asset.longitude, _asset, _cvObject.data.listAssetTypeKey, _cvObject.data.listAssetStatusTypeByKey));
                    return true;
                }
            })
        }


//-------------------------------------------------------------functions to be created: begin------------------------------------------------------
        function _createFunctionSwitchGeoPosition(_cvObject) {
            _cvObject.switchGeoPosition = function(_geoPosition) {
                _switchGeoPosition(_cvObject, _geoPosition);
            }
        }

        function _createFunctionShowCompassViewerMarkerInfo(_cvObject) {
            _cvObject.showCompassViewerMarkerInfo = function(_assetDataDetails) {
                _showCompassViewerMarkerInfo(_cvObject, _assetDataDetails);
            }
        }

        function _createFunctionToggleDisplayAssets(_cvObject) {
            _cvObject.toggleDisplayAssets = function() {
    
                _toggleDisplayAssets(_cvObject);

                if (_cvObject.data.flagDisplayLinks) {
                    _toggleDisplayLinks(_cvObject);
                }
            }
        }

        function _createFunctionToggleDisplayLinks(_cvObject) {
            _cvObject.toggleDisplayLinks = function() {
                _toggleDisplayLinks(_cvObject);
            }
        }

        function _createFunctionToggleDisplayImages(_cvObject) {
            _cvObject.toggleDisplayImages = function() {
                _toggleDisplayImages(_cvObject);
            }
        }

        function _createFunctionToggleImagesWithAnomalyTag(_cvObject) {
            _cvObject.toggleImagesWithAnomalyTag = function(_tag) {
                _toggleImagesWithAnomalyTag(_cvObject, _tag)
            }
        }

        function _createFunctionToggleKmzKmlFiles(_cvObject) {
            _cvObject.toggleKmzKmlFiles = function(_file) {
                _toggleKmzKmlFiles(_cvObject, _file)
            }
        }

        function _createFunctionUpdateListAsset(_cvObject) {
            _cvObject.updateListAsset = function(_asset) {
                _updateListAsset(_cvObject, _asset)
            }
        }

        function _createFunctionUpdateListAssetMarker(_cvObject) {
            _cvObject.updateListAssetMarker = function(_asset) {
                _updateListAssetMarker(_cvObject, _asset)
            }
        }

//-------------------------------------------------------------functions to be created: end--------------------------------------------------------

        /**
         * Build the cvObject that contains all
         * the data and function about CompassViewer
         */
        function _buildCVObject(_config) {
            var _cvObject = {};

            //Will contain all the data about CompassViewer
            _cvObject.data = {
                flagDisplayAssets: false,
                flagDisplayLinks: false,
                flagDisplayImages: true,
                geoPositions: _getGeoPositions().getPositionsClockwise(),
                asset: _config.asset, 
                listAssetDataDetails: angular.copy(_config.listAssetDataDetails),
                canvasId: _config.canvasId,
                scope: _config.scope,
                listAssetTypeKey: _config.listAssetTypeKey,
                userId: _config.userId,
                customerId: _config.customerId,
                listAssetDataDetailsTagTypes: _config.listAssetDataDetailsTagTypes,
                listAssetData: _config.listAssetData,
            };

            _createCompassViewerMap(_cvObject)
            _collectInfo(_cvObject);


            _createFunctionSwitchGeoPosition(_cvObject);
            _createFunctionShowCompassViewerMarkerInfo(_cvObject);
            _createFunctionToggleDisplayAssets(_cvObject);
            _createFunctionToggleDisplayLinks(_cvObject);
            _createFunctionToggleDisplayImages(_cvObject);
            _createFunctionToggleImagesWithAnomalyTag(_cvObject);
            _createFunctionToggleKmzKmlFiles(_cvObject);
            _createFunctionUpdateListAsset(_cvObject);
            _createFunctionUpdateListAssetMarker(_cvObject);

            return _cvObject;
        }


        return {
            /**
             * Create a new instance of cvObject that contains all
             * the data and function about CompassViewer
             * @returns 
             */
            newInstance: function(_config) {
                return _buildCVObject(_config);
            },
        }
    }
]);

