let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp



    //====================================
    // ALERT
    //====================================

    .controller('AlertDemoCtrl', ['$scope', function ($scope) {
        $scope.alerts = [
            { type: 'info', msg: "Well done! You successfully read this important alert message." },
            { type: 'success', msg: "Well done! You successfully read this important alert message." },
            { type: 'warning', msg: "Warning! Better check yourself, you're not looking too good." },
            { type: 'danger', msg: "Oh snap! Change a few things up and try submitting again." }
        ];

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        };
    }])


    //====================================
    // BUTTONS
    //====================================

    .controller('ButtonsDemoCtrl', ['$scope', function ($scope) {
        $scope.singleModel = 1;

        $scope.radioModel = 'Middle';

        $scope.checkModel = {
            left: false,
            middle: true,
            right: false
        };
    }])


    //====================================
    // CAROUSEL
    //====================================

    .controller('CarouselDemoCtrl', ['$scope', function ($scope) {
        $scope.myInterval = 0;
        $scope.slides = [
            {
                img: 'c-1.jpg',
                title: 'First Slide Label',
                text: 'Some sample text goes here...'
            },
            {
                img: 'c-2.jpg',
                title: 'Second Slide Label',
                text: 'Some sample text goes here...'
            },
            {
                img: 'c-3.jpg'
            }
        ];

    }])


    //====================================
    // CAROUSEL
    //====================================

    .controller('CollapseDemoCtrl', ['$scope', function ($scope) {
        $scope.isCollapsed = false;
    }])


    //====================================
    // DROPDOWN
    //====================================

    .controller('UibDropdownDemoCtrl', ['$scope', function ($scope) {
        $scope.items = [
            { name: 'The first choice!', icon: 'home' },
            { name: 'And another choice', icon: 'account' },
            { name: 'But wait! A third!', icon: 'email' },
            { name: 'And fourth on here', icon: 'pin' }
        ];
    }])


    //====================================
    // MODAL
    //====================================
    .controller('ModalDemoCtrl', ['$scope', '$uibModal', '$log', 'Scopes', '$rootScope', '$timeout', 'utility', function ($scope, $uibModal, $log, Scopes, $rootScope, $timeout, utility) {

        Scopes.store('ModalDemoCtrl', $scope);
        $scope.modalContentx = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales orci ante, sed ornare eros vestibulum ut. Ut accumsan vitae eros sit amet tristique. Nullam scelerisque nunc enim, non dignissim nibh faucibus ullamcorper. Fusce pulvinar libero vel ligula iaculis ullamcorper. Integer dapibus, mi ac tempor varius, purus nibh mattis erat, vitae porta nunc nisi non tellus. Vivamus mollis ante non massa egestas fringilla. Vestibulum egestas consectetur nunc at ultricies. Morbi quis consectetur nunc.';
        $scope.field;
        var txt;

        this.buildOpenPopup = function(_main_ctrl, _model, _field_name, _object) {
            // $scope.newOpenTextArea('lg','/views/textarea.html', _main_ctrl, _field_name, _modal, _object);
            $scope.newOpenTextArea('lg','/template/textarea.html', _main_ctrl, _field_name, _model, _object);

        }

        this.pressedKeyElementFocused = function(_main_ctrl, _model, _field_name, _object) {
            // _object = JSON.parse(_object);
            var _ctrl = Scopes.get(_main_ctrl);

            $timeout(function() {document.activeElement.textContent = "";});

            $timeout(function() {
                if (!_ctrl.elementFocused || _ctrl.elementFocused != _field_name) {
                    _ctrl.elementFocused = _field_name;

                    _object[_field_name] = "";
                    // var st = "Scopes.get('"+_main_ctrl+"')"+"."+_object+"."+_field_name;

                    // Scopes.get('ModalDemoCtrl').newOpenTextArea('lg','/views/textarea.html', _main_ctrl, _field_name , eval(st), _object);
                    Scopes.get('ModalDemoCtrl').newOpenTextArea('lg','/template/textarea.html', _main_ctrl, _field_name , _model, _object);

                    utility.callFunctionEveryTime("$('.ta-scroll-window > .ta-bind').get(0)", 250, function() {
                        $('.ta-scroll-window > .ta-bind').addClass('scrollbar style-1')
                    })
        
                }
            }, _ctrl.elementFocusedTimeout*20+100);
            _ctrl.elementFocusedTimeout +=2;
        }
        // Create Modal
        // this you've odne before on the python version.
        // this will work just have to update css and inner html and pass the template variable when this is called ($scope.open)
        function modalInstances(animation, size, backdrop, keyboard, url) {
            var modalInstance = $uibModal.open({
                animation: animation,
                templateUrl: url,
                /*template: '<div>ya mom</div>',*/
                controller: 'ModalInstanceCtrl',
                size: size,
                backdrop: backdrop,
                keyboard: keyboard,
                resolve: {
                    content: function () {
                        //return $scope.modalContent;
                        return $scope.htmlVariable;
                    }
                }
            });
        }

        function modalHtmlInstances(animation, size, backdrop, keyboard, url, txt, field, ctrllr) {

            // send txt to this template to fill it with text from clicked Textarea
            var modalInstance = $uibModal.open({
                animation: animation,
                templateUrl: url,
                /*template: '<div>ya mom</div>',*/
                controller: 'ModalInstanceCtrl',
                size: size,
                backdrop: backdrop,
                keyboard: keyboard,
                resolve: {
                    content: function () {
                        return txt;
                    },
                    field: function () {
                        return field;
                    },
                    ctrllr: function () {
                        return ctrllr;
                    }
                }
            });
        }

        function modalTextInstances(animation, size, backdrop, keyboard, url, txt, field, ctrllr) {
            var _txt = txt;

            // send txt to this template to fill it with text from clicked Textarea
            var modalInstance = $uibModal.open({
                animation: animation,
                templateUrl: url,
                /*template: '<div>ya mom</div>',*/
                controller: 'ModalInstanceCtrl',
                size: size,
                backdrop: backdrop,
                keyboard: keyboard,
                resolve: {
                    content: function () {
                        return txt;
                    },
                    field: function () {
                        return field;
                    },
                    ctrllr: function () {
                        return ctrllr;
                    }
                }
            });

            $timeout(function () {
                var t = $('#flTextAngularArea').find('*[id*=taText]')
                t.focus();

                var range = document.createRange();
                range.selectNodeContents(t[0]);
                range.collapse(false);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }, 700);

        }

        function modalRATextInstances(animation, size, backdrop, keyboard, url, txt, field, ctrllr) {

            // send txt to this template to fill it with text from clicked Textarea
            var modalInstance = $uibModal.open({
                animation: animation,
                templateUrl: url,
                /*template: '<div>ya mom</div>',*/
                controller: 'ModalInstanceCtrl',
                size: size,
                backdrop: backdrop,
                keyboard: keyboard,
                resolve: {
                    content: function () {
                        return txt;
                    },
                    field: function () {
                        return field;
                    },
                    ctrllr: function () {
                        return ctrllr;
                    }
                }
            });
        }

        function newModalTextInstances(animation, size, backdrop, keyboard, url, model, field, ctrllr, object) {
            // var _modal = modal;

            // send txt to this template to fill it with text from clicked Textarea
            var modalInstance = $uibModal.open({
                animation: animation,
                templateUrl: url,
                /*template: '<div>ya mom</div>',*/
                controller: 'newModalInstanceCtrl',
                size: size,
                backdrop: backdrop,
                keyboard: keyboard,
                resolve: {
                    object: function () {
                        return object;
                    },
                    content: function () {
                        return model;
                    },
                    field: function () {
                        return field;
                    },
                    ctrllr: function () {
                        return ctrllr;
                    }
                }
            });

            $timeout(function () {
                var t = $('#flTextAngularArea').find('*[id*=taText]')
                t.focus();

                var range = document.createRange();
                range.selectNodeContents(t[0]);
                range.collapse(false);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }, 500);

        }        

        //Custom Sizes
        $scope.open = function (size, url) {
            modalInstances(true, size, true, true, url)
        };

        //Without Animation
        $scope.openWithoutAnimation = function () {
            modalInstances(false, '', true, true)
        };

        //Prevent Outside Click
        $scope.openStatic = function (url) {
            modalInstances(true, '', 'static', true, url);
        };

        //Disable Keyboard
        $scope.openKeyboard = function () {
            modalInstances(true, '', true, false);
        };

        // Text Area Modal
        $scope.newOpenTextArea = function (size, url, ctrllr, field, model, object) {

            $scope.field = field;
            newModalTextInstances(true, size, false, false, url, model, field, ctrllr, object);
        };

        // Text Area Modal
        $scope.openTextArea = function (size, url, ctrllr, field, txt) {

            $scope.field = field;
            modalTextInstances(true, size, true, true, url, txt, field, ctrllr);
        };

        $scope.openRATextArea = function (size, url, ctrllr, field, txt) {
            ////debugger;
            console.log('$rootScope.currentHazardID', $rootScope.currentHazardID);
            $scope.field = field;
            let txt_real = '';
            angular.forEach($rootScope.raList, function (hfield, hfieldName) {
                ////debugger;
                angular.forEach(hfield, function (afield, afieldName) {
                    ////debugger;

                    if (afieldName == 'id') {

                        //let xx = ((afield == $rootScope.currentHazardID) ? txt_real = (txt.$viewValue,
                        //    (txt.$viewValue === undefined)) ? (txt_real = hfield[field]) : '' : '');

                        if (afield == $rootScope.currentHazardID) {
                            txt_real = txt.$viewValue;
                            let x = (txt.$viewValue === undefined) ? txt_real = hfield[field] : '';
                        }
                    }
                });

            });
            modalRATextInstances(true, size, true, true, url, txt_real, field, ctrllr);
        };

        $scope.openTextAreaUpdate = function (txt) {
            Scopes.get(ctrllr).updateTextArea($scope.field, txt);
        };

        $scope.openManageTelemetryModal = function (url) {
            //alert(referenceNumber + " " + pilotId);
            var modalInstance = $uibModal.open({
                templateUrl: url,
                size: "lg",
                controller: function ($scope, $modalInstance) {
                    //$scope.ok = function () {
                    //    alert("todo");
                    //    $modalInstance.close();
                    //};
                    //$scope.cancel = function () {
                    //    $modalInstance.dismiss('cancel');
                    //};
                    $scope.close = function () {
                        $modalInstance.close();
                    };
                }
            });
        };

        $scope.openFlightPlaybackModal = function (url) {
            //alert(referenceNumber + " " + pilotId);
            var modalInstance = $uibModal.open({
                templateUrl: url,
                size: "lg",
                controller: function ($scope, $modalInstance) {
                    //$scope.ok = function () {
                    //    alert("todo");
                    //    $modalInstance.close();
                    //};
                    //$scope.cancel = function () {
                    //    $modalInstance.dismiss('cancel');
                    //};
                    $scope.close = function () {
                        $modalInstance.close();
                    };
                }
            });
        };
    }])

    // Please note that $modalInstance represents a modal window (instance) dependency.
    // It is not the same as the $modal service used above.

    .controller('ModalInstanceCtrl', ['$scope', '$uibModalInstance', 'content', 'field', 'ctrllr', 'Scopes', 'focus', function ($scope, $uibModalInstance, content, field, ctrllr, Scopes, focus) {

        //$scope.modalContent = content;
        $scope.htmlVariable = content;
        Scopes.store('ModalInstanceCtrl', $scope);

        $scope.ok = function (txt) {
            //$uibModalInstance.close($scope.modalContent);
            $uibModalInstance.close($scope.htmlVariable);
            //debugger;
            Scopes.get(ctrllr).updateTextArea(field, txt);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            focus(field);
        };

        $scope.close = function () {
            $uibModalInstance.close();
            focus(field);
        };
    }])

    .controller('newModalInstanceCtrl', 
        ['$scope', '$uibModalInstance', 'content', 'field', 'ctrllr', 'Scopes', 'focus', 'object',
        function ($scope, $uibModalInstance, content, field, ctrllr, Scopes, focus, object) {

        $scope.htmlVariable = content;

        Scopes.store('ModalInstanceCtrl', $scope);

        var _modalDemoCtrl = "ModalDemoCtrl";

        function updateTextArea(field, txt, object) {
            // Scopes.get(ctrllr)[object][field] = txt;
            // var st = "Scopes.get('"+ctrllr+"')."+object+"."+field+" = '"+txt+"'";

            // eval(st);
            object[field] = txt;
        };   

        function resetElementFocused() {
            Scopes.get(ctrllr).elementFocused = "";
            Scopes.get(ctrllr).elementFocusedTimeout = 0;
        }


        $scope.ok = function (txt) {
            $uibModalInstance.close($scope.htmlVariable);
            updateTextArea(field, txt, object);
            resetElementFocused();

        };

        $scope.cancel = function () {
            // var _x = Scopes.get('ModalInstanceCtrl').htmlVariable;
            $uibModalInstance.close($scope.htmlVariable);
            object[field] = $scope.htmlVariable;
            // updateTextArea(field, "dslkdsj lk"+Math.random(), object);
            resetElementFocused();
            // object[field] = $scope.htmlVariable;
            // resetElementFocused();
            // $uibModalInstance.dismiss('cancel');
            // focus(field);
        };

        $scope.close = function () {
            // resetElementFocused();
            // $uibModalInstance.close();
            // focus(field);
            $uibModalInstance.close($scope.htmlVariable);
            object[field] = $scope.htmlVariable;
            resetElementFocused();
        };
    }])
    //====================================
    // PAGINATION
    //====================================

    .controller('PaginationDemoCtrl', ['$scope', '$log', function ($scope, $log) {
        $scope.totalItems = 64;
        $scope.currentPage = 4;

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.maxSize = 5;
        $scope.bigTotalItems = 175;
        $scope.bigCurrentPage = 1;
    }])


    //====================================
    // POPOVER
    //====================================

    .controller('PopoverDemoCtrl', ['$scope', function ($scope) {
        $scope.dynamicPopover = {
            templateUrl: 'myPopoverTemplate.html',
        };
    }])

    //====================================
    // PROGRESSBAR
    //====================================

    .controller('ProgressDemoCtrl', ['$scope', function ($scope) {
        $scope.max = 200;

        $scope.random = function () {
            var value = Math.floor((Math.random() * 100) + 1);
            var type;

            if (value < 25) {
                type = 'success';
            }
            else if (value < 50) {
                type = 'info';
            }
            else if (value < 75) {
                type = 'warning';
            }
            else {
                type = 'danger';
            }

            $scope.showWarning = (type === 'danger' || type === 'warning');

            $scope.dynamic = value;
            $scope.type = type;
        };

        $scope.random();

        $scope.randomStacked = function () {
            $scope.stacked = [];
            var types = ['success', 'info', 'warning', 'danger'];

            for (var i = 0, n = Math.floor((Math.random() * 4) + 1) ; i < n; i++) {
                var index = Math.floor((Math.random() * 4));
                $scope.stacked.push({
                    value: Math.floor((Math.random() * 30) + 1),
                    type: types[index]
                });
            }
        };

        $scope.randomStacked();
    }])


    //====================================
    // TABS
    //====================================

    .controller('TabsDemoCtrl', ['$scope', '$window', function ($scope, $window) {
        $scope.tabs = [
            {
                title: 'Home',
                content: 'In hac habitasse platea dictumst. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Nam eget dui. In ac felis quis tortor malesuada pretium. Phasellus consectetuer vestibulum elit. Duis lobortis massa imperdiet quam. Pellentesque commodo eros a enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Phasellus a est. Pellentesque commodo eros a enim. Cras ultricies mi eu turpis hendrerit fringilla. Donec mollis hendrerit risus. Vestibulum turpis sem, aliquet eget, lobortis pellentesque, rutrum eu, nisl. Praesent egestas neque eu enim. In hac habitasse platea dictumst.'
            },
            {
                title: 'Profile',
                content: 'Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nulla sit amet est. Praesent ac massa at ligula laoreet iaculis. Vivamus aliquet elit ac nisl. Nulla porta dolor. Cras dapibus. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.',
            },
            {
                title: 'Messages',
                content: 'Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.Morbi mattis ullamcorper velit. Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.',
            },
            {
                title: 'Settings',
                content: 'Praesent turpis. Phasellus magna. Fusce vulputate eleifend sapien. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Donec elit libero, sodales nec, volutpat a, suscipit non, turpis.',
            }
        ];

    }])


    //====================================
    // TOOLTIPS
    //====================================

    .controller('TooltipDemoCtrl', ['$scope', '$sce', function ($scope, $sce) {
        $scope.htmlTooltip = $sce.trustAsHtml('I\'ve been made <b>bold</b>!');
    }])


    //====================================
    // DATE PICKER
    //====================================
    .controller('DatepickerDemoCtrl', ['$scope', function ($scope) {
        $scope.today = function () {
            $scope.dt = new Date();
        };
        $scope.today();


        $scope.toggleMin = function () {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        $scope.open = function ($event, opened) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope[opened] = true;
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
    }])


    //===============================================
    // MESSAGE MODAL POP-UP WHIT OK, CANCEL BUTTONS
    //===============================================

    .controller('MessageCtrl', function ($uibModal, $log, $document, Scopes, $parse) {
        var $ctrl = this;
        $ctrl.items = ['item1', 'item2', 'item3'];

        $ctrl.animationsEnabled = true;

        Scopes.store('MessageCtrl', $ctrl);

        $ctrl.open = function (size, title, msg, ctrl, fnOk, fnCancel) {
            // var _fnBuilded = buildFn(fnOk, arguments, $ctrl.open.length);
            var _fnBuilded = fnOk;

            var modalInstance = $uibModal.open({
                animation: $ctrl.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: '/views/sfModalContent.html',
                controller: 'MessageInstanceCtrl',
                controllerAs: '$ctrl',
                size: size,
                // appendTo: parentElem,
                resolve: {
                    items: function () {
                        return $ctrl.items;
                    },
                    scope: function () {
                        return {
                            _title: title,
                            _msg: msg,
                            _ctrl: ctrl,
                            _fnOk: fnOk,
                            _fnCancel: fnCancel
                        };
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $ctrl.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });
        };

        //Build the function to run
        //fn: function name 
        //args: arguments that come to $ctrl.open
        //startParams: point where begin the fn parameters
        // var buildFn = function (fn, args, startParams) {
        //     var _fn = fn;
        //     if (args.length > startParams) {
        //         _fn += "(";
        //         for (var i = startParams; i < args.length; i++) {
        //             _fn += args[i];
        //             if (i < args.length - 1) _fn += ",";
        //         }
        //         _fn += ")";
        //     } else {
        //         _fn += "()";
        //     }

        //     return _fn;
        // }

        $ctrl.toggleAnimation = function () {
            $ctrl.animationsEnabled = !$ctrl.animationsEnabled;
        };
    })

    .controller('MessageInstanceCtrl', function ($uibModalInstance, items, scope, Scopes) {
        var $ctrl = this;

        $ctrl.title = scope._title;
        $ctrl.msg = scope._msg;

        $ctrl.items = items;
        $ctrl.selected = {
            item: $ctrl.items[0]
        };

        $ctrl.ok = function () {
            // $uibModalInstance.close($ctrl.selected.item);

            //Call the controller where there's the function to run
            var x = Scopes.get(scope._ctrl);

            //Inject a function to call the function to run
            x._msgRunOk = function () {
                eval("x." + scope._fnOk);
            }

            //Run the function
            x._msgRunOk();
            $uibModalInstance.dismiss('ok');

        };

        $ctrl.cancel = function () {
            if (scope._fnCancel) {
                //Call the controller where there's the function to run
                var x = Scopes.get(scope._ctrl);

                //Inject a function to call the function to run
                x._msgRunCancel = function () {
                    eval("x." + scope._fnCancel);
                }

                //Run the function
                x._msgRunCancel();

            }
            $uibModalInstance.dismiss('cancel');
        };
    })



    // .controller('MsgVarCtrl', function ($uibModalInstance, scope, Scopes) {
    //     var $ctrl = this;

    //     $ctrl.title = scope._title;
    //     $ctrl.msg = scope._msg;
    //     $ctrl.fns = JSON.parse(scope._fns);
    //     $ctrl.htmlPage = scope._htmlPage;
    //     $ctrl.scope = Scopes.get(scope._ctrl);

    //     console.log(scope._fns);

    //     $ctrl.fn = function (_fn, _close) {
    //         // console.log("xxxx", _fn);

    //         // if (_fn) {
    //         //Call the controller where there's the function to run
    //         var x = Scopes.get(scope._ctrl);

    //         //Inject a function to call the function to run
    //         x._msgRunOk = function () {
    //             eval("x." + _fn);
    //         }

    //         //Run the function
    //         x._msgRunOk();
    //         // }

    //         if (_close == undefined || _close) {
    //             $uibModalInstance.dismiss('fn');
    //         }
    //     };

    //     $ctrl.get = function (_var) {
    //         var x = Scopes.get(scope._ctrl);
    //         x._getVar = function () {
    //            return eval("x." + _var);
    //         }

    //         return x._getVar();
    //     }
    // })


    .controller('RaMethodCtrl', function ($uibModalInstance) {
        var $ctrl = this;


        $ctrl.ok = function () {
            $uibModalInstance.dismiss('ok');
        }
    })
    

// $ctrl.openComponentModal = function () {
//   var modalInstance = $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     component: 'modalComponent',
//     resolve: {
//       items: function () {
//         return $ctrl.items;
//       }
//     }
//   });

//   modalInstance.result.then(function (selectedItem) {
//     $ctrl.selected = selectedItem;
//   }, function () {
//     $log.info('modal-component dismissed at: ' + new Date());
//   });
// };

// $ctrl.openMultipleModals = function () {
//   $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     ariaLabelledBy: 'modal-title-bottom',
//     ariaDescribedBy: 'modal-body-bottom',
//     templateUrl: 'stackedModal.html',
//     size: 'sm',
//     controller: function($scope) {
//       $scope.name = 'bottom';  
//     }
//   });

//   $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     ariaLabelledBy: 'modal-title-top',
//     ariaDescribedBy: 'modal-body-top',
//     templateUrl: 'stackedModal.html',
//     size: 'sm',
//     controller: function($scope) {
//       $scope.name = 'top';  
//     }
//   });
// };
