//Write here all the services about ....entity
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("autodeskServices", ['myHttp', 'buildUrl', 'myLog', function(myHttp, buildUrl, myLog) {
        return  {
            getAuthToken: function(_success, _error) {
                var _url = buildUrl('/autodesk/getAuthToken');

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.token);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Auth Token'";
                    _error(_err);
                } );    
            },
        }
    }])

