//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('catalogueFactory', ['myLog', 'utility', 'aiMachineServices', 'appFactory', 'productServices', 
    function(myLog, utility, aiMachineServices, appFactory, productServices) {

        function _getListAiMachineByCompanyIdAndFacilityTypeId(_companyId, _facilityTypeId, _success, _error) {
            //Get all the AI-Machines about a Company, FacilityType and Anomalies
            const _aiMachineAnomalyPromise = new Promise((resolve, reject) => {
                aiMachineServices.getAiMachineByTypeAndCompanyIdAndFacilityTypeId(appFactory.getListAiMachineType().anomaly, _companyId, _facilityTypeId, resolve, reject);
            });
            //Get all the AI-Machines about a Company, FacilityType and Products
            const _aiMachineProductPromise = new Promise((resolve, reject) => {
                aiMachineServices.getAiMachineByTypeAndCompanyIdAndFacilityTypeId(appFactory.getListAiMachineType().product, _companyId, _facilityTypeId, resolve, reject);
            });
            //Get all the AI-Machines about a Company, FacilityType and Objects
            const _aiMachineObjectPromise = new Promise((resolve, reject) => {
                aiMachineServices.getAiMachineByTypeAndCompanyIdAndFacilityTypeId(appFactory.getListAiMachineType().object, _companyId, _facilityTypeId, resolve, reject);
            });

            var  result = Promise
            .all([_aiMachineAnomalyPromise, _aiMachineProductPromise, _aiMachineObjectPromise])
            .then(([_anomalyRes, _productRes, _objectRes]) => {
                var _listAiMachine = [];
                var _item;

                _anomalyRes.forEach(e => {
                    _item = e;
                    _item.type = appFactory.getListAiMachineType().anomaly;
                    _listAiMachine.push(_item);
                })

                _productRes.forEach(e => {
                    _item = e;
                    _item.type = appFactory.getListAiMachineType().product;
                    _listAiMachine.push(_item);
                })

                _objectRes.forEach(e => {
                    _item = e;
                    _item.type = appFactory.getListAiMachineType().object;
                    _listAiMachine.push(_item);
                })

                return _listAiMachine;
            });

            return result.then(_res => {
                _success(_res)
            }, _err => {
                _error(_err)
            });
        }

        function _getListProductGroupByCompanyId(_companyId, _success, _error) {
            //Get all the Product Group available for all the companies
            const _productGroudSystemPromise = new Promise((resolve, reject) => {
                productServices.getProductGroupByCompanyId(1, resolve, reject);
            });
            //Get all the Product Group available for the Company
            const _productGroudCompanyPromise = new Promise((resolve, reject) => {
                productServices.getProductGroupByCompanyId(_companyId, resolve, reject);
            });

            var  result = Promise
            .all([_productGroudSystemPromise, _productGroudCompanyPromise])
            .then(([_systemRes, _companyRes]) => {
                var _listProductGroup = [];
                var _item;

                // if (_companyId != 1) {
                //     _systemRes.forEach(e => {
                //         _item = e;
                //         _item.type = appFactory.getListProductGroupType().system;
                //         _listProductGroup.push(_item);
                //     })
                // }

                _companyRes.forEach(e => {
                    _item = e;
                    _item.type = appFactory.getListProductGroupType().company;
                    _listProductGroup.push(_item);
                })

                return _listProductGroup;
            });

            return result.then(_res => {
                _success(_res)
            }, _err => {
                _error(_err)
            });
        }


        return {
            //Get all the AI-Machine about a Company, FacilityType and products, anomalies, objects
            getListAiMachineByCompanyIdAndFacilityTypeId(_companyId, _facilityTypeId, _success, _error) {
                _getListAiMachineByCompanyIdAndFacilityTypeId(_companyId, _facilityTypeId, _res => {
                    _success(_res)
                }, _err => {
                    myLog("Error ", _err.data);
                    _error(_err)
                });
            },

            //Get all the AI-Machine available for all the companies, about the FacilityType and products, anomalies, objects
            //CompanyId=1 represent the AI-Machine available for all the companies
            getListAiMachineBySystemAndFacilityTypeId(_facilityTypeId, _success, _error) {
                _getListAiMachineByCompanyIdAndFacilityTypeId(1, _facilityTypeId, _res => {
                    _success(_res)
                }, _err => {
                    myLog("Error ", _err.data);
                    _error(_err)
                });
            },

            //Get all the Product Group available for the Company and for everyone
            getListProductGroupByCompanyId(_companyId, _success, _error) {
                _getListProductGroupByCompanyId(_companyId, _res => {
                    _success(_res)
                }, _err => {
                    myLog("Error ", _err.data);
                    _error(_err)
                });
            },

        };
    }
]);

