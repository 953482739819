//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('componentFactory', ['myLog', 'utility', 
    function(myLog, utility) {
        return {
            filterListAssetDataDetailsByTag(_listAssetDataDetails, _listTagKey) {
                return _listAssetDataDetails.filter(a => a.listComponentTags.filter(t => _listTagKey.filter(k =>  k.key == t.fddttKey).length>0).length>0)
            },

        };
    }
]);

