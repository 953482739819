//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("classManagmentServices", ['myLog', 'buildUrl', 'serviceUtility', 'myHttp',  function(myLog, buildUrl, serviceUtility, myHttp) {

        var _functions  = {
            // getBomByCompanyId: function(_companyId, _success, _error) {
            //     var _url = buildUrl( '/expanse/bom/byCompanyId/' + _companyId);
            //     var _errorMsg =  "I wasn't able to get the BOM's Items with company_id: '"+_companyId+"'";

            //     serviceUtility.runGet("getBomByCompanyId", _url, _errorMsg, _res => _success(_res.listBomDto), _error);
            // },
            saveClass: function(Data, _success, _error) {
                var _url = '/api/saveClassManagment';

                myHttp.post(_url, JSON.stringify(Data)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );
            },

             //Get all the component and anomalies present in the dataset for the selected custoemer
             getListComponentAnomalies: function(_companyId, _facilityTypeId, _success, _error) {

                var _url = '/api/retrieveAvailableObjectsByFacilityType?companyId=' + _companyId + "&facilityDataType=" + _facilityTypeId;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
                    
            },



        };

        //inherited some useful function from serviceUtility
        // return angular.extend(_functions, serviceUtility.inheritedUtilities(_functions));
        return _functions;
    }]);
