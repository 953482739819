//Write here all the services about ....entity
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("userServices", ['myHttp', 'buildUrl', 'myLog', function(myHttp, buildUrl, myLog) {
        return  {
            getUser: function(_userId, _success, _error) {
                var _url = buildUrl('/expanse/user/withRoleType/byId/' + _userId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listUserDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the user about the id: '"+_userId+"'";
                    _error(_err);
                } );    
            },
            getCustomerUsers: function(_customerId, _success, _error) {
                var _url = buildUrl('/expanse/user/byCustomer/' + _customerId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listUserDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the users about the customer: '"+_customerId+"'";
                    _error(_err);
                } );    
            },
            //Get user's information, from the db, with respect the _token
            getUserByToken: function(_token, _success, _error) {
                var _url = buildUrl('/expanse/user/byToken/' + _token);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.userDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the user about the token: '"+_token+"'";
                    _error(_err);
                } );    
            },
            //check if the user logged in is an INSPECTOR
            isInspector: function(_userId, _success, _error) {
                var _url = buildUrl('/expanse/user/isInspector/' + _userId);

                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the user about the id: '"+_userId+"'";
                    _error(_err);
                } );    
            },
        }
    }])

