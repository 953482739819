//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('checklistFactory', ['myLog', 'myAlert', 'myThrow', 'utility', 'popup', 'assetDataServices',
    function($myLog, myAlert, myThrow, utility, popup, assetDataServices)  {
        var _this;
        var _scope;
        var _asset;
        var _customer;
        var _controllerName;
        var _video = null
        // var patData = null;


        var getVideoData = function getVideoData(x, y, w, h) {
            var hiddenCanvas = document.createElement('canvas');
            hiddenCanvas.width = _video.width;
            hiddenCanvas.height = _video.height;
            var ctx = hiddenCanvas.getContext('2d');
            // ctx.drawImage(_video, 0, 0, _video.width, _video.height);
            ctx.drawImage(_video, 0, 0, 100, 56);
            return ctx.getImageData(x, y, w, h);
        };



        return {
            init: function(_data) {
                _this = _data.this;
                _scope = _data.scope;
                _asset = _data.asset;
                _customer = _data.customer;
                _controllerName = _data.controllerName;

                return this;
            },
            makeSnapshot: function() {
                if (_video) {
                    var _id = "#_snapshot_"+_this.checklistInfo.pictureId;
                    var patCanvas = document.querySelector(_id);
                    if (!patCanvas) return;
        
                    patCanvas.width = _video.width;
                    patCanvas.height = _video.height;
                    var ctxPat = patCanvas.getContext('2d');
        
                    var idata = getVideoData(_scope.patOpts.x, _scope.patOpts.y, _scope.patOpts.w, _scope.patOpts.h);
                    patCanvas.width = 100;
                    patCanvas.height = 56;
    
                    ctxPat.putImageData(idata, 0, 0);
        
                    // sendSnapshotToServer(patCanvas.toDataURL());
        
                    // var patData = idata;
    
                    var _length = _this.checklistInfo.checklistSelected.listPictures.length;
                    _this.checklistInfo.checklistSelected.listPictures[_length-1] = "not empty";
                }
            },
            showChecklist: function(_assetData) {            
                _scope.patOpts = {x: 0, y: 0, w: 25, h: 25};
                _scope.channel = {};
                _scope.webcamError = false;
                _scope.onError = function (err) {
                            _scope.webcamError = err;
                };
            
                _scope.onSuccess = function () {
                    // The video element contains the captured camera data
                    _video = _scope.channel.video;
                        _scope.patOpts.w = _video.width;
                        _scope.patOpts.h = _video.height;
                };
            
                _scope.onStream = function (stream) {
                    // You could do something manually with the stream.
                };
    
                _scope.stopWebcam = function() {
                    _this.checklistInfo.showTakePicture = false;
                    _scope.$broadcast('$destroy');
                }
    
                _this.viewShown = _this.views.checklist;
    
    
                _assetData.createdAtFormatted = utility.formatDate(_assetData.createdAt, 'dd/mm/yyyy')
                _this.checklistInfo = {
                    assetData: _assetData,
                    asset: _asset,
                    customer: _customer,
                    numbers: {
                        total: 0,
                        nPassed: 0,
                        nFailed: 0
                    },
                    answerTypes: [
                        {key: "PASS", value: "PASS"},
                        {key: "FAIL", value: "FAIL"}
                    ],
                    classificationTypes: []
                }
    
                for (var i=0; i<25; i++) {
                    _this.checklistInfo.classificationTypes.push({key: i, value: i});
                }
    
    
                // assetDataServices.getChecklist(_assetData.id, _res => {
                //     var _checklist = _res;
                //     var _facilityDataRefId = _checklist[0].facilityDataRefId;
        
                //     const _assetDataRefPromise = new Promise((resolve, reject) => {
                //         assetDataServices.getAssetDataById(_facilityDataRefId, resolve, reject);
                //     });
        
                //     const _assetDataDetailsPromise = new Promise((resolve, reject) => {
                //         assetDataServices.getAssetDataDetails(_facilityDataRefId, resolve, reject);
                //     });
        
                //     var  result = Promise
                //     .all([, _assetDataRefPromise, _assetDataDetailsPromise])
                //     .then(([, _assetDataRef, _assetDataDetails]) => {
        
                //         var _listChecklist = [];
                //         var _nPassed = 0;
                //         var _nFailed = 0;
        
                //         _checklist.forEach(e => {
                //             var _item = e;
                //             _item.answer = _item.answer.toUpperCase();
                //             if (_item.answer.startsWith("NA")) _item.answer = "NA";
        
                //             if (_item.answer == 'PASS') _nPassed += 1;
                //             if (_item.answer == 'FAIL') _nFailed += 1;
        
                //             _item.listAssetDataDetails = _assetDataDetails.filter(a => a.id == e.facilityDataDetailsId || a.id == e.facilityDataDetailsId2 || a.id == e.facilityDataDetailsId3)
        
                //             _listChecklist.push(_item);
                //         })
        
                //         _this.checklistInfo.numbers.total = _listChecklist.length;
                //         _this.checklistInfo.numbers.nPassed = _nPassed;
                //         _this.checklistInfo.numbers.nFailed = _nFailed;
        
                //         _this.checklistInfo.listChecklist= _listChecklist;
        
                //         _this.checklistInfo.assetDataRef = _assetDataRef;
        
                //         return _this.checklistInfo;
                //     })
        
                    
                //     result.then(_res => {
        
                //         _this.checklistInfo = _res;
        
                //     }, _err => {
                //         myAlert("Error: " + _err.data.message, 'danger');
                //     });
        
    
                // }, _err => {
                //     myAlert("Error: " + _err.data.message, 'danger');
    
                // });
            },    
            checklistTakeAPicture: function(_checklist) {
                if (!_this.checklistInfo.showTakePicture) {
                    _scope.$broadcast('START_WEBCAM');
                }
    
                _this.checklistInfo.showTakePicture = true;
    
                if (!_checklist.listPictures) {
                    _checklist.listPictures = [];
                }
                
                if (_checklist.listPictures.filter(e => e == "empty").length == 0) _checklist.listPictures.push("empty");
                
                _this.checklistInfo.pictureId = _checklist.id+"_"+_checklist.listPictures.length;
                _this.checklistInfo.checklistSelected = _checklist;
            },
            showIQPopup: function() {
                var _fns = [];
                var _data = {};
    
                // _this.assetStructureTableParamsRef =  _this.getAssetStructureTableParams();
                var _config = {
                    'size': 'md',
                    'title': 'Image Quality Statistics',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_data),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset-data/popup/asset-data_checklist-iq-popup.html',
                }
                popup.openModalBody(_config);            
            },
            showAIPopup: function() {
                var _fns = [];
                var _data = {};
    
                // _this.assetStructureTableParamsRef =  _this.getAssetStructureTableParams();
                var _config = {
                    'size': 'md',
                    'title': 'AI Analysis',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_data),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset-data/popup/asset-data_checklist-ai-popup.html',
                }
                popup.openModalBody(_config);            
            }            
        };
    }
]);



