//Write in this controller all the function/properties used in the whole application
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
.controller('mainCtrl', ['$scope', 'Scopes', 'myLog', 'myHttp', '$timeout', 'buildUrl', 'expanseConst','Idle', 'Keepalive','featureService', 'appFactory', 'rolesService', 'utility',
    function($scope, Scopes, myLog, myHttp, $timeout, buildUrl, expanseConst,Idle, Keepalive, featureService, appFactory, rolesService, utility){
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        var _this = this;


        //-------------------------- private area Begin -----------------------------------
        var _controllerName = "mainCtrl";
        
        //list of css classes that I want extract and put into $scope.listCssExtracted array
        //available for others controllers
        var _cssElementsToExtract = {
                                    '.asset-status-chart.unknown': 'unknown',
                                    '.asset-status-chart.good_condition': 'good_condition',
                                    '.asset-status-chart.maintenance_schedule': 'maintenance_schedule',
                                    '.asset-status-chart.low_schedule': 'low_schedule',
                                    '.asset-status-chart.urgent_attention': 'urgent_attention',
                                    '.customer-status-chart.active': 'active',
                                    '.customer-status-chart.inactive': 'inactive',
                                };
        //-------------------------- private area End-- -----------------------------------

        //-------------------------- _this area Begin -----------------------------------
        _this.isInspector = _identity.isInspector;
        _this.isMobile = utility.isFromMobile();
        _this.isInfoInAssetDataScreenVisible = false;
        //-------------------------- _this area End-- -----------------------------------

        //-------------------------- $scope area Begin -----------------------------------
        $scope.listCssExtracted = [];   //contain the list of css classes extracted from app.css
        $scope.isSessionTimeoutActive = true;
        $scope.listFeatureKey = appFactory.getListFeatureKey();
        $scope.listRolesTypeKey = appFactory.getListRolesTypeKey();
        //-------------------------- $scope area End-- -----------------------------------

        //Save all the $scope objects
        //that could be requested by others controllers
        Scopes.store(_controllerName, $scope);

        $scope.isFeatureEnabled = function(_featureKey) {
            return featureService.isEnabled(_featureKey);
        }

        $scope.hasRole = function(_rolesTypeKey) {
            return rolesService.hasRole(_rolesTypeKey);
        }


        //Catch the IdleTimeout event
        //When the session timeout is over, I'll call the logout url
        $scope.$on('IdleTimeout', function() {
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them

            //If some upload is going on
            //I'll restart the idle-time 
            //Ex. if the idle-time is set as 1 hour,
            //when the idle event will be triggered and some file is uploading
            //I'll check the idle event after another hour
            //I'll do this until the uploading has completed.
            var _ctrl = Scopes.get('assetDataUploadCtrl');
            if (_ctrl && _ctrl._assetDataUploadCtrl.uploadProcedureStatus == 'upload') {
                Keepalive.ping();
                Idle.watch();                
            } else {
                if ($scope.isSessionTimeoutActive) {
                    console.log("----------------TIMEOUT---------------------------")
    
                    //Call the logout url
                    if (_identity.isInspector) {
                        window.location.replace(expanseConst.softeqUrl + "empl/logout");
                    } else {
                        window.location.replace(expanseConst.softeqCustomerUrl + "customer/logout");
                    }
                } else {
                    Idle.watch();                
                }    
            }
        });

        //This function set up, if it exists, the theme selected from the user
        //All the main colors can be changed in this function
        //And extract the css element equal to the elements into _cssElementsToExtract list
        //This elements extracted can be used into other controllers
        this.setOrExtractTheme = function() {
            // $("html").get(0).style.setProperty("--header-bg-color","white");     

            // _identity = JSON.parse(localStorage.getItem('exlite.identity'));
            
            var _indexCss;
            var _indexStyle;
            var _sss = document.styleSheets; //get all the css files declared into index.html
            var _ss;
            var _style;
            var _selectorText;
            var _item = {};

            myHttp.get(buildUrl("/exAdministration/getThemeIconConfig/" + _identity.companyId)).success(res => {
                _identity.listThemeIconConfig = res;

                //Set all the information gathered into "exlite.identity"
                localStorage.setItem('exlite.identity', angular.toJson(_identity));
            })


            var _listConfig;
            var _url = buildUrl('/exAdministration/getThemeConfig/'+ _identity.companyId);

            myHttp.get(_url).success(res => {
                if (res && res.length >0 ) {
                    _listConfig = res;

                    var _listConfigByKey = [];
                    var _props = {};
                    var _className;
                    var _classNameOld = _listConfig[0].className;
    
                    _listConfig.forEach(e => {
                        if (_classNameOld != e.className) {
                            _listConfigByKey[_classNameOld] = _props;                            
                            _classNameOld = e.className;
                            _props = {};
                        }
    
                        _props[e.propertyName] = e.value;
                        _className = e.className;
    
                    })
    
                    _listConfigByKey[_className] = _props;
    
                    myLog("_listConfigByKey", _listConfigByKey);    
                }


                if (_sss.length > 0) {
                    for (var i=0; i<_sss.length; i++) { //Looking for the app.css file that contain main.scss definition
                        _ss = _sss[i];
                        myLog(_ss.href);
        
                        if (_ss.href && _ss.href.endsWith('css/app.css')) {
                            _indexCss = i;
                        }
                    }
        
                    _ss = _sss[_indexCss];
        
                    //cssRules contain all the elements defined into main.scss
                    //Looking for a specific element into .css founded into previous loop
                    //and then change some properties value
                    for (var i=0; i < _ss.cssRules.length; i++) {
                        
                        if (_ss.cssRules[i]) {
                            _selectorText = _ss.cssRules[i].selectorText;
                            _style = _ss.cssRules[i].style;
    
                            // if (_listConfig && _listConfig.length > 0 && _selectorText && _listConfigByKey[s]) {
                            if (_listConfig && _listConfig.length > 0 && _selectorText) {
                                _selectorText.split(',').forEach(s => {
                                    var _s = s.trim();
                                    if (_listConfigByKey[_s]) {
                                        Object.keys(_listConfigByKey[_s]).forEach(e => {
                                            _style.setProperty(e,_listConfigByKey[_s][e],'important');
    
                                        })
    
                                    }
                                })
                            }        
                        }
                    }
    
                    for (var i=0; i < _ss.cssRules.length; i++) {
                        if (_ss.cssRules[i]) {
                            _selectorText = _ss.cssRules[i].selectorText;
                            _style = _ss.cssRules[i].style;
    
                            //looking for an _cssElementsToExtract element 
                            //if I find it, I'm going to put it into $scope.listCssExtracted array
                            if (_selectorText && _cssElementsToExtract[_selectorText]) {
                                _item = { 
                                    'name': _selectorText,
                                    'value': _ss.cssRules[i].style
                                }
                                
                                $scope.listCssExtracted.push(_item);
                            }    
                        }
                    }

                }
            })



        }

        //return the listCssExtracted
        $scope.getCssExtractedList = function() {
            return $scope.listCssExtracted;
        }

        //return a list of all asset-status-chart css class 
        $scope.getCssAboutAssetStatusChart = function() {
            var _array = [];
            var _cssClass = '.asset-status-chart';
            var _item = {};
            var _st;

            $scope.listCssExtracted.filter(e => e.name.indexOf(_cssClass) != -1 ).forEach(e => {
                _st = e.name.replace(_cssClass + '.', '');

                _item = {
                    'name': _st,
                    'value': e.value
                };

                _array.push(_item);
            });

            return _array;
        }

        //return a list of all customer-status-chart css class 
        $scope.getCssAboutCustomerStatusChart = function() {
            var _array = [];
            var _cssClass = '.customer-status-chart';
            var _item = {};
            var _st;

            $scope.listCssExtracted.filter(e => e.name.indexOf(_cssClass) != -1 ).forEach(e => {
                _st = e.name.replace(_cssClass + '.', '');

                _item = {
                    'name': _st,
                    'value': e.value
                };

                _array.push(_item);
            });

            return _array;
        }
        
        _this.toggleInfoInAssetDataScreen = function() {
            _this.isInfoInAssetDataScreenVisible = !_this.isInfoInAssetDataScreenVisible;
        }
      
}]);
 