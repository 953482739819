//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('Scopes', ['$rootScope', 'myLog', 
    function($rootScope, myLog) {
        var mem = {};

        return {
            store: function(key, value) {
                $rootScope.$emit('scope.stored', key);
                mem[key] = value;
                myLog("store ", key);
            },
            get: function(key) {
                myLog("get ", key);
                return mem[key];
            },
            remove: function(key) {
                myLog("remove ", key);
                delete mem[key];
            },
            show: function() {
                myLog(mem);
            },
            cleanFromScope: function(_scope) {
                var _keys = Object.keys(_scope);

                _keys.forEach(e => {
                    if (e.indexOf('$') != -1) {
                        delete _scope[e];        
                    }
                })

                return _scope;
            },
            add: function(_scope, _elements) {
                var _keys = Object.keys(_elements);

                _keys.forEach(e => {
                    _scope[e] = _elements[e];
                })

                return _scope;
            } 

        };
    }
]);

expanseLiteApp.factory('focus', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    return function(name) {
        $timeout(function() {
            $rootScope.$broadcast('focusOn', name);
        });
    };
}]);

expanseLiteApp.factory('appFactory', ['utility',
    function(utility) {
        var _listFeatureKey = {
            leaksMap: 'leaks-map',
            azimuth: 'azimuth',
            naviswork3D: 'naviswork-3d',
            forensicPointAsset: 'forensic-point-asset',
            forensicPointEstate: 'forensic-point-estate',
            assetListAdd: 'asset-list-add',
            temperatureThresholdEstate: 'temperature-threshold-estate',
            methaneThresholdEstate: 'methane-threshold-estate',
            vegetationPercentageEstate: 'vegetation-percentage-estate',
            ocr: 'ocr',
            barQrcodeReader: 'barcode-qrcode-reader',
            fingerprinting: 'fingerprinting',
            cableLabelDetection: 'cable-label-detection',
            catalogues: 'catalogues',
            spinModel: 'spin-model',
            truView: 'truview',
            userNet3d: 'usernet-3d',
            nokiaReport: 'nokia-report',
            v6Analysis: 'v6-analysis',
            autodeskRevit:'autodesk-revit',
            humanEye: 'human-eye',
            labelAnalysis: 'lable-analysis',
            objTracking: 'obj-tracking',
            autodeskAutocadOnline: 'autodesk-autocad-online',
            autoInventory: 'auto-inventory',
            deleteDataset: 'delete-dataset',
            riskManagement: 'risk-management',
            exportAsset: 'export-asset',
            exportAssetAnalyzed: 'export-asset-analyzed',  
            exportAssetDropbox: 'export-asset-dropbox',           
            exportInventory: 'export-inventory',
            exportPredictedStatistics: 'export-predicted-statistics',
            autoInventoryB: 'auto-inventoryb',
            signalPropagationMap: 'signal-propagation-map',
            orthomosaic: 'orthomosaic',
            googleEarth: 'google-earth',
            masterList: 'master-list',
            iscChecklist: 'isc-checklist',
            tiltAngle: 'tilt-angle',
            editor3DModel: 'editor-3d-model',
            aiFilter: 'ai-filter',
            mhdReport: 'mhd-report',
         }

        var _listAiMachineType = {
            anomaly: 'anomaly',
            product: 'product',
            object: 'object',
        }

        var _listProductGroupType = {
            system: 'system',
            company: 'company'
        }

        var _levelsType = [
            {type:'top', name:'Head Tower'}, 
            {type:'middle', name:'Middle Tower'}, 
            {type:'bottom', name:'Base Tower'}
        ]

        var _listRolesTypeKey = {
            account:'ACCOUNT',
            accountOwner:'ACCOUNT_OWNER',
            companyAdmin:'COMPANY_ADMIN',
            dataCapture:'DATA_CAPTURE',
            inspection:'INSPECTION',
            inspector:'INSPECTOR',
            inspectorViewer:'INSPECTOR_VIEWER',
            skyFuturesAdmin:'SKY_FUTURES_ADMIN',
            viewer:'VIEWER'
        }

        return {
            removeMapById: function(_maps, _canvasId) {
                var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId);
    
                if (_index != undefined) {
                    _maps.splice(_index, 1);
                }
            },
            addMapById: function(_maps, _m, _canvasId) {
                _maps.push({ id: _canvasId, map: _m });
            },
            getMapById: function(_maps, _canvasId) {
                var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId);
    
                if (_index != undefined) {
                    return _maps[_index].map;
                } else {
                    return null;
                }
            },
            getListFeatureKey: function() {
                return _listFeatureKey;
            },
            getListAiMachineType: function() {
                return _listAiMachineType;
            },
            getListProductGroupType: function() {
                return _listProductGroupType;
            },
            getSpinModelLevelsType: function() {
                return _levelsType;
            },
            getListRolesTypeKey: function() {
                return _listRolesTypeKey;
            }
        }
    }
]);


