//Write in this controller all the code about AssetData
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.controller('headerCtrl', 
    ['$scope', 'Scopes', 'myLog', 'myAlert', '$state', '$location', 'myHttp', 'buildUrl', 'expanseConst', 'appFactory', 'rolesService', 'utility',
    function($scope, Scopes, myLog,  myAlert, $state, $location, myHttp, buildUrl, expanseConst, appFactory, rolesService, utility) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        var _this = this;

        //-------------------------- private area Begin -----------------------------------
        var _controllerName = "headerCtrl";
        //-------------------------- private area End-- -----------------------------------

        //-------------------------- _this area Begin -----------------------------------
        _this.companyName;
        _this.companyLogoPath;
        //-------------------------- _this area End-- -----------------------------------

        //-------------------------- $scope area Begin -----------------------------------
        $scope.customer_id;
        $scope.asset_id;
        $scope.assetDataEnable = false;
        $scope.views = {                                     //header views available
            'dashboard': 'dashboard',
            'customerAsset': 'customerAsset',
            'assetData': 'assetData'
        };
        $scope.listRolesTypeKey = appFactory.getListRolesTypeKey();

        $scope.hasRole = function(_rolesTypeKey) {
            return rolesService.hasRole(_rolesTypeKey);
        }

        // $scope.viewShown = $scope.views.dashboard;      //hold info about what view, about header, is shown at the moment
        //-------------------------- $scope area End-- -----------------------------------

        //Write here the code that you need when the controller is created
        //The $scope will be put and get from store.
        //In this way it will be possible to cache some information
        //and it will be possible to use some function in others controllers
        this.init = function() {
            //Get the $scope about this controller from the store;
            var _ctrl = Scopes.get(_controllerName);

            //if the controller $scope is in the store, I'm going to use it
            //otherwise the $scope will be saved into the store
            if (_ctrl) {
                // $scope = _ctrl;
                $scope = Scopes.add($scope, Scopes.cleanFromScope(_ctrl));

            } else {
                //Save all the $scope objects
                //that could be requested by others controllers
                Scopes.store(_controllerName, $scope);

                var _loginCtrl = Scopes.get('loginCtrl');

                var _redirect;

                if (_loginCtrl) {
                    _redirect = _loginCtrl.redirect;
                } else {
                    // var _s = $location.absUrl().split('/')
                    // _redirect = _s[_s.length-2] + '.' + _s[_s.length-1]
                    if ($location.absUrl().indexOf('dashboard') != -1) {
                        _redirect = "dashboard.main";
                    } else if ($location.absUrl().indexOf('customer_asset') != -1) {
                        _redirect = "customer-asset.main";
                    } else if ($location.absUrl().indexOf('asset_data') != -1) {
                        _redirect = "asset-data.main";
                    } else if ($location.absUrl().indexOf('asset_data_upload') != -1) {
                        _redirect = "asset-data-upload.main";
                    }                    
                }

                // if (utility.isFromMobile()) {
                //     _redirect = "dashboard-mobile.main";
                // }

                switch(_redirect) {
                    case 'dashboard.main': 
                        $scope.viewShown = 'dashboard';
                        break
                    case 'dashboard-mobile.main': 
                        $scope.viewShown = 'dashboard';
                        break
                    case 'customer-asset.main':
                        $scope.viewShown = 'customerAsset';
                        break
                    case 'asset-data.main':
                        $scope.viewShown = 'assetData';
                        break
                    case 'asset-data-upload.main':
                        $scope.viewShown = 'assetDataUpload';
                        break
                }
    
            }

            _this.companyName = _identity.company.name;
            _this.rolesType = _identity.rolesType;


            $scope.addActive();

            $scope.enableOptions();
            $scope.disableFunctionalityForDataCaptureRolesType();

            //Call _getCompanyLogo just one time
            // if (!$scope.isGetComapnyLogoRunning) {
                _getCompanyLogo();
                _getUserImg();
            
                _setUserMenuInfo();
                _setUserMenuInfo();
                _setUserMenuAction();
            // }




            _this.expanseConst = expanseConst;

        }

        function _setUserMenuInfo() {
            $(".account .info .name").text(_identity.firstName.toLowerCase() + " " + _identity.lastName.toLowerCase());
            if (_identity.rolesTypeValue) {
                $(".account .info .role").text(_identity.rolesTypeValue.toLowerCase());
            }
            
        }
        
        function _setUserMenuAction() {
            $('#user-block ul li.account').on('click', function(){
                $(this).toggleClass('open');
                $('#other-options').removeClass('open');
            });

            $('#other-options').on('click', function(){
                event.stopPropagation();
                $(this).toggleClass('open');
            });

            $("#user-links a[href]:not(.no-change)").each(function() {
                console.log(this.href);
                this.href = expanseConst.softeqUrl + this.href.replace(this.origin+"/",'');
            })

            var _companyListHref = $(".company-list").attr("href")
            if (_this.rolesType == 'SKY_FUTURES_ADMIN') {
                $(".company-list").attr("href",_companyListHref + "inspecting-companies-list")
            } else {
                $(".company-list").attr("href",_companyListHref + "list")
            }
        }

        function _getUserImg() {
            myHttp.get(buildUrl("/exAdministration/getFileStorageItem/byId/" + _identity.photoId)).success(res => {
                if (res && res.path) {
                    var _path = expanseConst.cfExpanseUrl + res.path.substring(0, res.path.length-4) + "_thumbnail_small" + res.path.substring(res.path.length-4)
                    $(".avatar").css("background-image", "url("+_path+")");
                }
            });

        }


        function _getCompanyLogo() {
            $scope.isGetComapnyLogoRunning = true;
            _identity.companyLogoPath = undefined;
            _identity.companyFaviconPath = undefined;

            myHttp.get(buildUrl("/exAdministration/getFileStorageItem/companyLogo/byCompanyId/" + _identity.companyId)).success(res => {

                if (res && res.path) {
                    _identity.companyLogoPath = expanseConst.cfExpanseUrl + res.path;
                    _this.companyLogoPath = _identity.companyLogoPath;

                    $("#_header_company_logo_img").attr('src',_this.companyLogoPath);
                    $("#_header_company_logo_img").css("visibility", "visible");
                } else {
                    $("#_header_company_logo_text").text(_this.companyName);
                    $("#_header_company_logo_text").css("visibility", "visible");
                }

                $("title").text(_this.companyName);
            });

            //set the browser tab icon
            myHttp.get(buildUrl("/exAdministration/getFileStorageItem/companyFavicon/byCompanyId/" + _identity.companyId)).success(res => {
                if (res && res.path) {
                    _identity.companyFaviconPath = expanseConst.cfExpanseUrl + res.path;
                    _this.companyFaviconPath = _identity.companyFaviconPath;

                    //set the browser tab icon
                    $('link[rel="icon"]').attr('href', _this.companyFaviconPath);
                }
            });
 
        }

        $scope.removeActive = function() {
            $('#_'+$scope.viewShown).removeClass('is-active');
        }

        $scope.addActive = function() {
            $('#_'+$scope.viewShown).addClass('is-active');
        }

        $scope.enableOptions = function() {
            if ($scope.customer_id && $scope.asset_id) {
                $('#_'+$scope.views.assetData).removeClass('is-disabled');
                $scope.assetDataEnable = true;
            } else {
                $('#_'+$scope.views.assetData).addClass('is-disabled');
                $scope.assetDataEnable = false;
            }
        }

        $scope.disableFunctionalityForDataCaptureRolesType = function() {
            if ($scope.hasRole($scope.listRolesTypeKey.dataCapture)) {
                $("#_headerAnalysis").hide();
                $("#_headerReport").hide();
                $("#_headerMenuReport").hide();
            }
        }


        $scope.goDashboard = function() {
            $scope.removeActive();
            $scope.viewShown = $scope.views.dashboard;
            var _target = "dashboard"+(utility.isFromMobile()?"-mobile.main":".main");
            $state.go(_target);
            $scope.addActive();
        }

        $scope.goCustomerAsset = function() {
            $scope.removeActive();
            $scope.viewShown = $scope.views.customerAsset;
            var _target = "customer-asset"+(utility.isFromMobile()?"-mobile.main":".main");
            $state.go(_target, {customer_id:$scope.customer_id, asset_id:$scope.asset_id});
            $scope.addActive();
        }

        $scope.goAssetData = function() {
            if ($scope.assetDataEnable) {
                $scope.removeActive();
                $scope.viewShown = $scope.views.assetData;
                var _target = "asset-data"+(utility.isFromMobile()?"-mobile.main":".main");
                $state.go(_target, {customer_id:$scope.customer_id, asset_id:$scope.asset_id});
                $scope.addActive();    
            }
        }

        $scope.goAssetDataUpload = function(fd_id = 0, clflag=false) {
            $scope.removeActive();
            $scope.viewShown = $scope.views.assetDataUpload;
            $state.go("asset-data-upload.main", {customer_id:$scope.customer_id, asset_id:$scope.asset_id, facility_data_id_selected:fd_id, checklist_flag:clflag});
            $scope.addActive();    
        }

        $scope.setAssetId = function(_assetId) {
            $scope.asset_id = _assetId;
            $scope.enableOptions();
        }

        $scope.setCustomerId = function(_customerId) {
            if ($scope.customer_id && $scope.customer_id != _customerId) {
                $scope.asset_id = undefined;
            }
            $scope.customer_id = _customerId;
            $scope.enableOptions();
        } 

        function _isFromMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        }
    }
]);
