//Write in this file all the UI services
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp

    // BOOTSTRAP GROWL - show an alert
    .service('myAlert', function (myLog) {
        //Show an alert message
        //_message: message to show
        //_type: message type (warning, info, danger, success). Default 'info'
        //_delay: how long the message is shown (in milliseconds).
        //          Default 5 secs. If _type is 'danger', _delay will be 0
        //          Set 0 if you want an infinite time
        return function (_message, _type, _delay) {
            myLog(_message);
            var __type = 'info';
            var __delay = 5000;

            if (_type) {
                __type = _type;
                // if (_type == 'danger') {
                //     __delay = 5000;
                // }
            }
            if (_delay || _delay == 0) {
                __delay = _delay;
            }

            return $.growl({
                message: _message
            }, {
                    icon_type: 'image',
                    type: __type,
                    allow_dismiss: true,
                    placement: {
                        from: 'top',
                        align: 'center'
                    },
                    delay: __delay,
                    offset: {
                        y: 0
                    }
                });

            // $.notify.success(_message)
        }
    })

    //This service create a Chart object
    .service('buildChart', function () {
        //Default option used into all the charts
        var _options = {
            plugins: {
                labels: {
                    // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                    render: 'percentage',
                    fontColor: '#000',
                    //   position: 'outside',
                    fontSize: 12,
                    fontStyle: 'bold',
                    arc: true
                }
            },
            layout: {
                padding: {
                    top: 10
                }
            },
            maintainAspectRatio: false,
            cutoutPercentage: 70,
            legend: {
                boxWidth: 20,
                position: 'bottom',
                display: false
            },
            scales: {
                yAxes: [{
                    display: false,
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
        var _datasetConfigInner = {
            borderWidth: 1,
            borderColor: '#192a40'
        }

        return {
            //Create a chart with the parameters:
            //_ctx              - is the html element that will contain the chart
            //_type             - type of chart
            //_data             - data shown into the chart
            //_labels           - labels  shown into the chart
            //_backgroundColor  - background colors array used into the chart graph
            default: function (_ctx, _type, _data, _labels, _backgroundColor, _optionsCustom, _datasetConfigCustom) {
                var _datasetConfig = (_datasetConfigCustom?_datasetConfigCustom:_datasetConfigInner);
                _datasetConfig.data = _data;
                _datasetConfig.backgroundColor= _backgroundColor;


                var _ret  =  new Chart(_ctx, {
                    type: _type,
                    data: { 
                        datasets: [_datasetConfig],
                        // datasets: [{
                        //     data: _data,
                        //     backgroundColor: _backgroundColor,
                        //     borderWidth: 1,
                        //     borderColor: '#192a40'

                        // }],
                        // These labels appear in the legend and in the tooltips when hovering different arcs
                        labels: _labels
                    },
                    options: _optionsCustom?_optionsCustom:_options
                });

                return _ret;
            }
        }
    })

    //Service to open a popup
    .service('popup', ['$uibModal', '$interpolate', function ($uibModal, $interpolate) {
        var $ctrl = this;
        $ctrl.items = [];

        return {
            //open a popup where it is possible set up the following info:
            //title: is a string
            //message: is a string
            //buttons: is a json object

            openModal: function (size, title, msg, ctrl, fns) {
                return $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: '/views_TO-DELETE/sfModalContentVar.html',
                    controller: 'PopupCtrl',
                    controllerAs: '$ctrl',
                    backdrop: false,
                    keyboard: false,
                    size: size,
                    resolve: {
                        scope: function () {
                            return {
                                _title: title,
                                _msg: msg,
                                _ctrl: ctrl,
                                _fns: fns
                            };
                        }
                    }
                });
            },
            //open a modal popup where it possible to call an external .html
            //that represent the popup body
            openModalBody: function (_config) {
                return $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: '/template/popup/modal-with-body.html',
                    controller: 'PopupCtrl',
                    controllerAs: '$ctrl',
                    backdrop: false,
                    keyboard: false,
                    size: _config.size,
                    resolve: {
                        scope: function () {
                            return {
                                _title: _config.title,
                                _ctrl: _config.ctrl,
                                _data: _config.data,
                                _fns: _config.fns,
                                _htmlPage: _config.htmlPage,
                                _innerStatus: _config.innerStatus,
                                _funzione: _config.funzione
                            };
                        }
                    }
                });
            }

        }
    }])

    //open a confirm dialog
    .service('popupConfirm', function ($mdDialog) {
        return function (_title, _textContent) {
            var confirm = $mdDialog.confirm()
                .title(_title)
                .htmlContent(_textContent)
                //   .ariaLabel('Lucky day')
                //   .targetEvent(ev)
                .ok('Yes')
                .cancel('No');

            return confirm;

        }

    })

    .service('googlemaps', function ($timeout, $templateRequest, $compile, $interpolate, utility) {
        var _canvasIds = [];

        function _index(_map) {
            // if (_map.__gm.W) {
            //     return _map.__gm.W.id;
            // } else if (_map.__gm.ba) {
            //     return _map.__gm.ba.id
            // } else {
            //     return -1;
            // }
            var _ret = Object.keys(_map.__gm).filter(e => {
                try{ if (_map.__gm[e].className && _map.__gm[e].className.indexOf('google-map') != -1) return true  } catch(err){} 
            })

            if (_ret.length > 0) {
                return _map.__gm[_ret[0]].id;
            } else {
                return -1;
            }
        }

        function _initGoogleMap(_canvasId, _success) {
            if ($(_canvasId).get(0)) {

                var _map = new google.maps.Map($(_canvasId).get(0), {
                    center: {
                        lat: 51.5287352,
                        lng: -0.3817734
                    },
                    zoom: 3,
                    mapTypeId: google.maps.MapTypeId.SATELLITE,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.DEFAULT,
                        position: google.maps.ControlPosition.LEFT_BOTTOM
                    },
                    streetViewControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    }
                });

                // if (_canvasIds[_map.__gm.W.id]) { 
                //     _canvasIds[_map.__gm.W.id] = _canvasId;
                // } 
                if (!_canvasIds[_index(_map)]) { 
                    _canvasIds[_index(_map)] = _canvasId;
                } 
                

                _success(_map); 

            } else {
                $timeout(function () {
                    _initGoogleMap(_canvasId, _success);
                }, 250);
            }
        }

        function _rotatePoint(_point, _origin, _angle) {
            var _angleRad = _angle * Math.PI / 180.0;
            return {
                x: Math.cos(_angleRad) * (_point.x - _origin.x) - Math.sin(_angleRad) * (_point.y - _origin.y) + _origin.x,
                y: Math.sin(_angleRad) * (_point.x - _origin.x) + Math.cos(_angleRad) * (_point.y - _origin.y) + _origin.y
            };
        }            

        function _calculateStepOnMap(_map, _step) {
            var __step = _step?_step:1;
            var _metersPerPx = 156543.03392 * Math.cos(_map.getCenter().lat() * Math.PI / 180) / Math.pow(2, _map.getZoom())

            // var _m = 156543.03392 * __step  * Math.cos(_map.getCenter().lat() * Math.PI / 180) / Math.pow(2, _map.getZoom());
            return (0.00001 * _metersPerPx * __step)/_metersPerPx;
        }

        const googleMapsMethods = {
            initGoogleMap: function (_canvasId, _success) {
                _initGoogleMap(_canvasId, _map => {
                    _success(_map);
                });
            },

            /**
             * Create a marker based on the asset. Create a popup from html
             * @param _map
             * @param _lat
             * @param _lng
             * @param _asset
             * @param _icon
             * @param _useDots indicates whether simplified marker icons should be used
             * @param _infoWindowIcon an icon to be displayed in the infoWindow
             * @returns {google.maps.Marker}
             */
            createAssetMarker: function (_map, _lat, _lng, _asset, _icon, _useDots, _infoWindowIcon) {
                if (_useDots) {
                    _icon = {
                        url: _icon,
                        scaledSize: new google.maps.Size(16, 16),
                        origin: new google.maps.Point(0,0),
                        anchor: new google.maps.Point(8, 8)
                    };
                }

                const _marker = new google.maps.Marker({
                    map: _map,
                    position: new google.maps.LatLng(_lat, _lng),
                    icon: _icon,
                    title: _asset.name,
                    lat: _lat,
                    log: _lng,
                    asset: _asset
                });

                //add a click listener on the marker
                _marker.addListener('click', function (_event) {
                    //Create a popup when a marker is clicked on
                    //The popup is based on an external .html file
                    if (!_infoWindowIcon) {
                        _infoWindowIcon = _icon;
                    }


                    if (_useDots) {
                        googleMapsMethods.setInfoWindow(_map, _marker, _asset, _infoWindowIcon, 'views/dashboard/google-map_popup/asset-estate_info.html');
                    } else {
                        googleMapsMethods.setInfoWindow(_map, _marker, _asset, _infoWindowIcon, 'views/asset/google-map_popup/asset-estate_info.html');
                    }
                });

                return _marker;
            },

            /**
             * Create a google marker for each asset into _listAsset
             * and put the marker into _map
             * The map zoom is set out based on the markers position
             * @param _map
             * @param _listAsset
             * @param _useDots indicates whether simplified icons should be used
             * @returns {Array}
             */
            putAssetMarkersOnMap: function(_map, _listAsset, _useDots) {
                const _listMarker = [];

                if (_listAsset && _listAsset.length > 0) {
                    _listAsset.forEach(e => {
                        //Create a marker based on an asset
                        //and put the object returned, from the function, into a list of marker
                        _listMarker.push(
                            googleMapsMethods.createAssetMarker(
                                _map, e.latitude, e.longitude, e,
                                !_useDots ? googleMapsMethods.getAssetMarkerIcon(e) : googleMapsMethods.getAssetStatusDotIcon(e),
                                _useDots,
                                googleMapsMethods.getAssetMarkerIcon(e)
                            )
                        );
                    });

                    //Set out the map zoom based on the markers position
                    googleMapsMethods.fitBounds(_map, _listMarker);
                }

                return _listMarker;
            },

            /**
             *  Return the icon file name that will be used into a google marker
             *  The file name is based on the type of asset and the asset status
             * @param _asset
             * @returns {string}
             */
            getAssetMarkerIcon: function(_asset) {
                var _ret = 'marker-wind-turbine.png';

                switch (_asset.status) {
                    case 'good_condition':
                        _ret = 'marker-wind-turbine_green.png';
                        break;
                    case 'maintenance_schedule':
                        _ret = 'marker-wind-turbine_yellow.png';
                        break;
                    case 'low_schedule':
                        _ret = 'marker-wind-turbine_yellow.png';
                        break;
                    case 'urgent_attention':
                        _ret = 'marker-wind-turbine_red.png';
                        break;
                    case 'unknown':
                        _ret = 'marker-wind-turbine_unknown.png';
                        break;
                    default:
                        _ret = ""
                }

                return '/img/icons/markers/' + _ret;
            },

            /**
             *  Return the dot icon file name that will be used as a Google maps marker
             *  The file name is based on the type of asset and the asset status
             * @param _asset
             * @returns {string}
             */
            getAssetStatusDotIcon: function(_asset) {
                var _ret = 'unknown-condition.png';

                switch (_asset.status) {
                    case 'good_condition':
                        _ret = 'good-condition.png';
                        break;
                    case 'maintenance_schedule':
                        _ret = 'medium-priority.png';
                        break;
                    case 'low_schedule':
                        _ret = 'low-priority.png';
                        break;
                    case 'urgent_attention':
                        _ret = 'critical-condition.png';
                        break;
                }

                return '/img/icons/dots/' + _ret;
            },

            setInfoWindow: function (_map, _marker, _item, _icon, _templateUrl) {
                if (!_marker.infoWindowRef) {
                    var _infowindow = new google.maps.InfoWindow({});
                    _marker.infoWindowRef = _infowindow;

                    _infowindow.open(_map, _marker);
                    _infowindow.setPosition(new google.maps.LatLng(_marker.lat, _marker.log));

                    //Change the image used to close the infowindow
                    utility.callFunctionEveryTime('$(".gm-style-iw.gm-style-iw-c button img:not(.already-opened)").get(0)',200, function() {
                        $(".gm-style-iw.gm-style-iw-c button img").attr('src', '/img/icons/cancel-popup.png');
                        $(".gm-style-iw.gm-style-iw-c button img").addClass("already-opened")
                    })
    
                    $templateRequest(_templateUrl).then(res => {
                        // var _element = $(_canvasIds[_map.__gm.W.id]);
                        var _element = $(_canvasIds[_index(_map)]);
                        var _scope = angular.element(_element).scope();
                        _scope.marker = _marker;
                        _scope.item = _item;

                        var _templateCompiled = $compile($interpolate(res)({ item: _item, marker: _marker, icon: _icon }))(_scope);

                        _infowindow.setContent(_templateCompiled[0])

                    //     _templateCompiled(this, function (_clone) {
                    //         var _scope = angular.element($("#content").context).scope();
                    //         var _x = $compile($interpolate(res)({ item: _item, marker: _marker, icon: _icon }))(_scope);

                    //         _infowindow.setContent(_x[0])
    
                    //         // _infowindow.setContent(_clone[0].outerHTML);
                    //    });
    
                    });
    
                    _infowindow.addListener("closeclick", function() {
                        _marker.infoWindowRef = undefined;
                    })

                }


            },
            fitBounds: function (_map, _listMarker, _callback) {
                var _bounds = new google.maps.LatLngBounds();

                _listMarker.forEach(e => {
                    try {
                        _bounds.extend(e.getPosition());
                    } catch(ex) {
                        try {
                            _bounds.union(e.getDefaultViewport());
                        } catch(ex){}
                    }

                });

                $timeout(function() {
                    _map.fitBounds(_bounds);
                    _callback?_callback(_map, _listMarker):null;
                }, 10);
            },
            fitBoundAreas: function (_map, _listMarker) {
                var _bounds = new google.maps.LatLngBounds();

                _listMarker.forEach(e => {
                    var _b = new google.maps.LatLngBounds();

                    e.getPath().forEach(p => {
                        _b.extend(new google.maps.LatLng(p.lat(), p.lng()));
                    });

                    _bounds.extend(_b.getCenter());
                });

                $timeout(function() {
                    _map.fitBounds(_bounds);
                }, 10);
            },
            fitBoundPolylines: function (_map, _listMarker) {
                var _bounds = new google.maps.LatLngBounds();

                _listMarker.forEach(e => {
                    e.getPath().forEach(p => {
                        _bounds.extend(new google.maps.LatLng(p.lat(), p.lng()));
                    });
                });

                $timeout(function() {
                    _map.fitBounds(_bounds);
                }, 10);
            },
            //Create a triangle on the map that looks like a radar
            createTriangle: function(_map, _lat, _lng, _latZoom, _lngZoom) {
                var _latSize = 0.000245 * (_latZoom?_latZoom:1);
                var _lngSize = 0.000190 * (_lngZoom?_lngZoom:1);

                var _tr = [
                    {lat: _lat, lng: _lng},
                    {lat: _lat+_latSize, lng: _lng-_lngSize},
                    {lat: _lat+_latSize, lng: _lng+_lngSize},
                ];

                // Construct the polygon.
                var _polygon = new google.maps.Polygon({
                    paths: _tr,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                });                
                _polygon.setMap(_map);

                return _polygon;
            },
            rotatePolygon: function(_polygon,_angle) {
                var _this = this;
                _this.map = _polygon.getMap();
    
                // if (_prj) {
                utility.callFunctionWithScopeEveryTime(this, ".map.getProjection()", 300, function() {
                    var _prj = _this.map.getProjection();
                    var _origin = _prj.fromLatLngToPoint(_polygon.getPath().getAt(0)); //rotate around first point
    
                    var _coords = _polygon.getPath().getArray().map(function(_latLng){
                       var _point = _prj.fromLatLngToPoint(_latLng);
                       var _rotatedLatLng =  _prj.fromPointToLatLng(_rotatePoint(_point,_origin,_angle));
                       return {lat: _rotatedLatLng.lat(), lng: _rotatedLatLng.lng()};
                    });

                    _polygon.setPath(_coords);                        

                })
                // } else {
                //     $timeout(function() {googleMapsMethods.rotatePolygon(_polygon, _angle)}, 400);
                // }
    
            },
            moveMapToLeft: function(_map, _step) {
                if (_map.getCenter().lng()>=0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat(), _map.getCenter().lng() - _calculateStepOnMap(_map, _step)));
                if (_map.getCenter().lng()<0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat(), _map.getCenter().lng() + _calculateStepOnMap(_map, _step)));
            },
            moveMapToRight: function(_map, _step) {
                if (_map.getCenter().lng()>=0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat(), _map.getCenter().lng() + _calculateStepOnMap(_map, _step)));
                if (_map.getCenter().lng()<0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat(), _map.getCenter().lng() - _calculateStepOnMap(_map, _step)));
            },
            moveMapToUp: function(_map, _step) {
                if (_map.getCenter().lat()>=0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat() - _calculateStepOnMap(_map, _step), _map.getCenter().lng()));
                if (_map.getCenter().lat()<0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat() + _calculateStepOnMap(_map, _step), _map.getCenter().lng()));
            },
            moveMapToDown: function(_map, _step) {
                if (_map.getCenter().lat()>=0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat() + _calculateStepOnMap(_map, _step), _map.getCenter().lng()));
                if (_map.getCenter().lat()<0) _map.setCenter(new google.maps.LatLng(_map.getCenter().lat() - _calculateStepOnMap(_map, _step), _map.getCenter().lng()));
            },
            zoomIn: function(_map, _max) {
                var __max = _max?_max:21;
                var _zoom = _map.getZoom();
                if (_zoom < __max) _map.setZoom(_zoom + 1);                
            },
            zoomOut: function(_map, _min) {
                var __min = _min?_min:0
                var _zoom = _map.getZoom();
                if (_zoom > __min) _map.setZoom(_zoom - 1);                
            },
            //this function move a location on the map, to a x,y position on the screen
            moveTo: function(_map, _position, _x, _y, _zoom) {
                function _move(_map, _position, _x, _y) {
                    var _overlay = new google.maps.OverlayView();
                    _overlay.draw = function() {};
                    _overlay.setMap(_map);
                    
                    var _proj = _overlay.getProjection();
                    if (!_proj) {
                        $timeout(function() {
                            _move(_map, _position, _x, _y)
                        }, 200)        
                    } else {
                        var _p = _proj.fromLatLngToContainerPixel(_position);
                        _map.panBy(_p.x-_x, _p.y-_y);             

                        if (_zoom) _map.setZoom(_zoom);
                        return true;
                    }
                }

                _move(_map, _position, _x, _y);

            }
        };

        return googleMapsMethods;
    })

    .service('singleUploader', function (myLog, myAlert, $timeout, utility) {
        var _mainArray = [];
        var _id; 



        // var _previousFile;
        // var _progressMsg;
        // var _dataToBackend;
        // var _dzMethods = {};

        // var _dzOptions = {
        //     method: "POST",
        //     paramName: 'file',
        //     maxFiles: 1,
        //     thumbnailWidth: 1,
        //     thumbnailHeight: 1,
        //     autoProcessQueue: false,
        //     parallelUploads: 1,
        //     uploadMultiple: false,
        // };


        // var _dzCallbacks = {
        function _getCallbacks(_id) {
            var id = _id;
            return {
                uploadprogress: function (_file, _progress, _byteSent) {
                    $(_mainArray[id].dzOptions.progressbarid + ' div').width(_progress+'%');

                    if (_progress == 100) {
                        _mainArray[id].progressMsg = myAlert('Sending to the server. Wait please', 'success', 0)
                    }
                },
                addedfile: function (file) {

                    // console.log(_dzMethods);
                    if (_mainArray[id].previousFile) {
                        try {
                            _mainArray[id].dzMethods.removeFile(_mainArray[id].previousFile);                        
                        } catch (error) {}                
                    }
                        
                    _mainArray[id].previousFile = file;
                },
                success: function (file, xhr) {
                    _mainArray[id].dzMethods.removeAllFiles(true);
                    _mainArray[id].dzMethods.autoProcessQueue = false;

                    _mainArray[id].progressMsg.close();
                    $timeout(function() {
                        myAlert('Uploading completed successfully', 'success');
                    }, 100);

                    _mainArray[id].dzOptions.successCallback(file);
                },
                sending: function (file, xhr, data) {
                    _mainArray[id].dataToBackend.fileName = file.name;
                    _mainArray[id].dataToBackend.contentType = file.type;

                    data.append('Data', JSON.stringify(_mainArray[id].dataToBackend));
                },
                error: function(_msg) {
                    myAlert('Error uploading. Something went wrong ', 'danger', 10000)
                }
            }
        }


        function _createNewItem() {
            return {
                'previousFile': undefined,
                 'progressMsg': undefined,
                 'dataToBackend': undefined,
                 'successCallbackData': undefined,
                 'dzMethods': {},
                 'dzOptions':  {
                    method: "POST",
                    paramName: 'file',
                    maxFiles: 1,
                    thumbnailWidth: 1,
                    thumbnailHeight: 1,
                    autoProcessQueue: false,
                    parallelUploads: 1,
                    uploadMultiple: false,
                },
                'dzCallbacks': _getCallbacks(_id)
            }
        }

        function _setOptions(_options) {
            if (_options ) {
                if (!_mainArray[_id]) {
                    _mainArray[_id] =  _createNewItem();
                    _mainArray[_id].dzCallbacks.id = _id;

                    Object.keys(_options).forEach(e => {
                        _mainArray[_id].dzOptions[e] = _options[e];
                    })
    
                    myLog(_mainArray[_id].dzOptions);    
                }
            }
        }


        return function (_idParam, _options) {
            _id = _idParam;
            _setOptions(_options);

            return {
                getMethods: function (_id) {
                    return _mainArray[_id].dzMethods;
                },
                setMethods: function (_id, _methods) {
                    _mainArray[_id].dzMethods = _methods;
                    var _idProgressBar = _mainArray[_id].dzOptions.progressbarid + ' div';

                    utility.callFunctionEveryTime("$('"+_idProgressBar+"').get(0)", 200, function() {
                        $(_mainArray[_id].dzOptions.progressbarid + ' div').width('0%');
                    })

                },
                getOptions: function (_id) {
                    return _mainArray[_id].dzOptions;
                },
                getCallbacks: function (_id) {
                    return _mainArray[_id].dzCallbacks;
                },
                setDataToSendBackend: function(_id, _data) {
                    _mainArray[_id].dataToBackend = _data;
                },
                setProgressbarCallback: function(_id, _fn) {
                    _mainArray[_id].progressbarCallback = _fn;
                },
                setSuccessCallback: function(_fn, _data) {
                    _mainArray[_id].dzMethods.successCallback = _fn;
                    _mainArray[_id].successCallbackData = _data;
                }
            }
        }


    })

    //service about image utilities
    .service('utilityImage', function (myLog, utility) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        return {
            getIconPath: function(_path) {
                var _index = utility.getIndexArrayElementByField(_identity.listThemeIconConfig, 'iconFrom', _path);

                if (_index != undefined) {
                    return _identity.listThemeIconConfig[_index].iconTo; 
                } else {
                    return _path;
                }
            }
        }
    })

    //Utility to draw objects on html canvas
    .service('canvasDraw', function (utility, popupConfirm, $mdDialog) {
        var canvas;
        var ctx;
        var startX;
        var startY;
        var mtt;
        var callback;
        var _objects;
        var _sizes = {};

        function _initSizes() {
            _sizes.width = ctx.canvas.width;
            _sizes.height = ctx.canvas.height;
            _sizes.newWidth = ctx.canvas.width;
            _sizes.newHeight = ctx.canvas.height;
        }

        function _getCanvasSizeRatio() {
            // x: ctx.canvas.width/ctx.canvas.scrollWidth,
            // y: ctx.canvas.height/ctx.canvas.scrollHeight

            return {
                x: _sizes.width/_sizes.newWidth,
                y: _sizes.height/_sizes.newHeight
            }
        }

        //Check if the shape is selected or some other point to modify the shape
        function _checkObjectOrDraggingPointsTouched(_this, _dragPoints, x, y) {
            var _pointsDraggable = 0;

            _dragPoints.forEach(e => {
                e.isDragging = e.isHit(x,y);
                _pointsDraggable += 1*e.isDragging;
            })    

            if (_pointsDraggable > 1) {
                _dragPoints.filter(e => e.isDragging).forEach((e, _index) => {
                    if (_index > 0) {
                        e.isDragging = false;
                    }
                })
                _this.isDragging = false;
            } else if (_pointsDraggable == 1 && _this.isDragging) {
                _this.isDragging = false;
            }    
            
            if (_pointsDraggable>0) {
                _this.isModifying = true;
            }

            return (_pointsDraggable>0 || _this.isDragging);            
        }

        function _renderCenter(_ctx, _object, _color) {
            var _x = _object.getCenter().x;
            var _y = _object.getCenter().y;

            if (_ctx.canvas.id != ctx.canvas.id) {
                _x *=  _ctx.canvas.width/ctx.canvas.width;
                _y *=  _ctx.canvas.height/ctx.canvas.height;
            }

            _ctx.save();

            var _colorStroke = '#005d0075';
            var _colorStyle = '#005d00';

            if (_color) {
                _colorStroke = _color+'75';
                _colorStyle = _color;
            }

            _ctx.beginPath();
            _ctx.arc(_x, _y, 5, 0, 2 * Math.PI, false);
            _ctx.strokeStyle = _colorStroke;
            _ctx.stroke();
            _ctx.fillStyle = _colorStyle;
            _ctx.fill();

            _ctx.restore();
        }

        //build a rectangle
        var Rectangle = function (x, y, width, height, name) {
            this.x = x;
            this.y = y;
            this.width = width;
            this.height = height;
            this.isDragging = false;
            this.name = name;

            //render the rectangle on the canvas;
            this.render = function (_ctx) {
                var _x = this.x;
                var _y = this.y;
                var _width = this.width;
                var _height = this.height;

                if (_ctx.canvas.id != ctx.canvas.id) {
                    _x *=  _ctx.canvas.width/ctx.canvas.width;
                    _y *=  _ctx.canvas.height/ctx.canvas.height;
                    _width *=  _ctx.canvas.width/ctx.canvas.width;
                    _height *=  _ctx.canvas.height/ctx.canvas.height;
                }

                _ctx.save();

                _ctx.beginPath();
                _ctx.rect(_x, _y, _width, _height);
                _ctx.strokeStyle = '#000';
                _ctx.stroke();
                _ctx.fillStyle = '#ff000047';
                _ctx.fill();

                _ctx.restore();
            }

            //check if the spot selected on the screen tauch this object
            this.isHit = function(x, y) {
                x = x * _getCanvasSizeRatio().x;
                y = y * _getCanvasSizeRatio().y;

                return (x > this.x && y > this.y && x < this.x + this.width && y < this.y + this.height);
            }      
            
            this.updatePosition = function(x, y) {
                this.x = x;
                this.y = y;
            }
        }

        // ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle, anticlockwise)
        var Ellipse = function (_x, _y, _rx, _ry, _rotation, _modifiable) {
            var x = _x;
            var y = _y;
            var rx = _rx;
            var ry = _ry;
            var rotation = _rotation;
            this.isDragging = false;
            this.isModifying = false;
            var isModifiable = _modifiable!=undefined?_modifiable:false;
            var _dragPoints = [];
            var _dragPointsAngle = [0, -90, 135];
            

            _createDragPoints();

            function _getPointOnEdge(_angle) {
                var beta = rotation * Math.PI/180;
                _angle = _angle * Math.PI/180;

                return {
                    dx: rx * Math.cos (beta) *Math.cos (_angle) - ry *  Math.sin (beta) * Math.sin(_angle),
                    dy: rx *  Math.cos(_angle)* Math.sin (beta) + ry * Math.sin(_angle) *Math.cos (beta)
                }
            }

            function _createDragPoints() {
                _dragPoints = [];

                _dragPoints.push(new Rectangle(x-5 + _getPointOnEdge(0).dx, y-5 + _getPointOnEdge(0).dy, 10, 10, 'dragX'))
                _dragPoints.push(new Rectangle(x-5 + _getPointOnEdge(-90).dx, y-5 + _getPointOnEdge(-90).dy, 10, 10, 'dragY'))
                _dragPoints.push(new Rectangle(x-5 + _getPointOnEdge(135).dx, y-5 + _getPointOnEdge(135).dy, 10, 10, 'rotate'))
            }

            function _removeDragPoints() {
                _dragPoints = [];
            }

            function _isHit(_x, _y) {
                _x = _x * _getCanvasSizeRatio().x;
                _y = _y * _getCanvasSizeRatio().y;

                rotation = rotation || 0;
                var cos = Math.cos(rotation*Math.PI/180),
                    sin = Math.sin(rotation*Math.PI/180);
                var dx  = (_x - x),
                    dy  = (_y - y);
                var tdx = cos * dx + sin * dy,
                    tdy = sin * dx - cos * dy;
            
                return (tdx * tdx) / (rx * rx) + (tdy * tdy) / (ry* ry) <= 1;
            }

            function _getCenter() {
                return {x: x, y: y};
            }

            this.getTypeName = function() {
                return 'ellipse';
            }

            this.getInfo = function() {
                return {
                    canvasSize: {
                        width:_sizes.width,
                        height:_sizes.height
                    },
                    x:x, 
                    y:y, 
                    rx:rx, 
                    ry:ry, 
                    rotation:rotation,
                    type: 'ellipse'
                }
            }

            this.render = function(_ctx) {
                var _x = x;
                var _y = y;
                var _rx = rx;
                var _ry = ry;

                if (_ctx.canvas.id != ctx.canvas.id) {
                    _x *=  _ctx.canvas.width/ctx.canvas.width;
                    _y *=  _ctx.canvas.height/ctx.canvas.height;
                    _rx *=  _ctx.canvas.width/ctx.canvas.width;
                    _ry *=  _ctx.canvas.height/ctx.canvas.height;
                }

                _ctx.save();

                _ctx.beginPath();
                _ctx.ellipse(_x, _y, _rx, _ry, rotation*Math.PI/180, 0, Math.PI*2);
                _ctx.strokeStyle = '#000';
                _ctx.stroke();
                _ctx.fillStyle = "#ff800069";
                _ctx.fill();

                _dragPoints.forEach(e => e.render(_ctx));

                _ctx.restore();
            } 

            this.renderCenter = function(_ctx, _color) {
                _renderCenter(_ctx, this, _color);
            }

            this.getCenter = function() {
                return _getCenter();
            }

            this.isHit = function(_x, _y) {
                this.isDragging = _isHit(_x, _y)

                return _checkObjectOrDraggingPointsTouched(this, _dragPoints, _x, _y);
            }


            function _calculateDegrees(_x, _y) {
                var degrees = Math.atan2((_y - y)/ry, (_x - x)/rx) * 180 / Math.PI;
                degrees = (degrees - 135) % 360;
    
                console.log(degrees, _x, _y);
                return degrees
            }
    
            this.updatePosition = function(dx, dy, _x, _y) {
                if (this.isDragging) {
                    if (this.isModifying) {
                        _dragPoints.filter(e=> e.isDragging).forEach(e => {
                            switch (e.name) {
                                case 'dragX':
                                    rx += dx*_getCanvasSizeRatio().x;
                                    e.updatePosition(x-5 + _getPointOnEdge(0).dx, y-5 + _getPointOnEdge(0).dy);
                                    break;
                                case 'dragY':
                                    ry -= dy*_getCanvasSizeRatio().y;
                                    e.updatePosition(x-5 + _getPointOnEdge(-90).dx, y-5 + _getPointOnEdge(-90).dy);
                                    break;                                
                                case 'rotate':
                                    rotation = _calculateDegrees(_x*_getCanvasSizeRatio().x, _y*_getCanvasSizeRatio().y);
                                    e.updatePosition(x-5 + _getPointOnEdge(135).dx, y-5 + _getPointOnEdge(135).dy);
                                    break;
                            }
                        })

                        _dragPoints.forEach((e, index)  => e.updatePosition(x-5 + _getPointOnEdge(_dragPointsAngle[index]).dx, y-5 + _getPointOnEdge(_dragPointsAngle[index]).dy));
                    } else {
                        x += dx*_getCanvasSizeRatio().x;
                        y += dy*_getCanvasSizeRatio().y;    
                        
                        _createDragPoints();
                    }
                }
            }
            
            this.setRotation = function(_rotation) {
                rotation = _rotation;
            }

            this.resetDragging = function() {
                this.isDragging = false;
                this.isModifying = false;
                _dragPoints.forEach(e => e.isDragging = false);
            }

            this.setModifiable = function(_modifiable) {
                isModifiable = _modifiable;
                if (_modifiable) {
                    _createDragPoints();
                } else {
                    _removeDragPoints();
                }
            }        

            this.changeCursor = function(_x, _y) {
                var found = false;
                _dragPoints.forEach((e, index) => {
                    if (e.isHit(_x, _y) || e.isDragging) {

                        found = true;
                        switch (e.name) {
                            case 'dragX':
                                canvas.style.cursor='w-resize';
                                break;
                            case 'dragY':
                                canvas.style.cursor='n-resize';
                                break;                                
                            case 'rotate':
                                canvas.style.cursor='pointer';
                                break;
                        }
                    }
                })

                if (_isHit(_x, _y) && found == false) {
                    found = true;
                    canvas.style.cursor='move';
                }

                return found;
            }
        }



        //build a polygon
        var Polygon = function (_points, _modifiable) {
            this.isDragging = false;
            this.isModifying = false;
            var points = _points;
            var isModifiable = _modifiable!=undefined?_modifiable:false;
            var _vertices = [];

            _createVertices();


            function _getPointsByRectangles() {
                var _points = [];
                _vertices.forEach(e => _points.push({ x: e.x + e.width / 2, y: e.y + e.height / 2 }));
                var _first = _vertices[0];
                _points.push({ x: _first.x + _first.width / 2, y: _first.y + _first.height / 2 })

                return _points;
            }

            function _createVertices() {
                _vertices = [];
                if (isModifiable) {
                    points.forEach((p, index) => {
                        if (index>0) _vertices.push(new Rectangle(p.x-5,p.y-5, 10, 10))
                    })    
                }
            }

            function _removeVertices() {
                _vertices = [];
            }

            function _getCenter() {
                var minX, maxX, minY, maxY;

                points.forEach(p => {
                    minX = (p.x < minX || minX == null) ? p.x : minX;
                    maxX = (p.x > maxX || maxX == null) ? p.x : maxX;
                    minY = (p.y < minY || minY == null) ? p.y : minY;
                    maxY = (p.y > maxY || maxY == null) ? p.y : maxY;
                })
                return {x:(minX + maxX) / 2, y:(minY + maxY) / 2};
            }

            //check if the spot selected on the screen tauch this object
            function _isHit(x, y) {
                x = x * _getCanvasSizeRatio().x;
                y = y * _getCanvasSizeRatio().y;

                //check if a point fall in the polygon area
                function _pointIsInPoly(p, polygon) {
                    var isInside = false;
                    var minX = polygon[0].x, maxX = polygon[0].x;
                    var minY = polygon[0].y, maxY = polygon[0].y;
                    for (var n = 1; n < polygon.length; n++) {
                        var q = polygon[n];
                        minX = Math.min(q.x, minX);
                        maxX = Math.max(q.x, maxX);
                        minY = Math.min(q.y, minY);
                        maxY = Math.max(q.y, maxY);
                    }

                    if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
                        return false;
                    }

                    var i = 0, j = polygon.length - 1;
                    for (i, j; i < polygon.length; j = i++) {
                        if ((polygon[i].y > p.y) != (polygon[j].y > p.y) &&
                            p.x < (polygon[j].x - polygon[i].x) * (p.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x) {
                            isInside = !isInside;
                        }
                    }

                    return isInside;
                }
         
                return _pointIsInPoly({ x: x, y: y }, points);
            }              

            this.getTypeName = function() {
                return 'polygon';
            }
            this.getInfo = function() {
                return {
                    canvasSize: {
                        width:_sizes.width,
                        height:_sizes.height
                    },
                    points: points,
                    type: 'polygon'
                }
            }

            this.setPoints = function(_points) {
                points = _points;
            }

            this.setModifiable = function(_modifiable) {
                isModifiable = _modifiable;
                if (_modifiable) {
                    _createVertices();
                } else {
                    _removeVertices();
                }
            }        

            this.getModifiable = function() {
                return isModifiable;
            }

            //render the polygon on the canvas
            this.render = function (_ctx) {                
                var _points = angular.copy(points)

                if (_ctx.canvas.id != ctx.canvas.id) {
                    _points.forEach(p=> {
                        p.x *=  _ctx.canvas.width/ctx.canvas.width;
                        p.y *=  _ctx.canvas.height/ctx.canvas.height;
                    })
                }

                _ctx.save();

                _ctx.beginPath();
                _ctx.moveTo(_points[0].x, _points[0].y);
                _points.forEach(p => {
                    _ctx.lineTo(p.x, p.y);
                })

                _ctx.closePath();
                _ctx.strokeStyle = '#000';
                _ctx.stroke();
                _ctx.fillStyle = "#ff800069";
                _ctx.fill();

                _vertices.forEach(e => e.render(_ctx));

                _ctx.restore();
            }          

            this.renderCenter = function(_ctx, _color) {
                _renderCenter(_ctx, this, _color);
            } 

            this.getCenter = function() {
                return _getCenter();
            }

            this.isHit = function(x, y) {
                this.isDragging = _isHit(x,y);

                return _checkObjectOrDraggingPointsTouched(this, _vertices, x, y);
            }


            this.updatePosition = function(dx, dy) {
                if (this.isDragging) {
                    if (this.isModifying ) {
                        _vertices.filter(e => e.isDragging).forEach(e => {
                            e.x += (dx*_getCanvasSizeRatio().x);
                            e.y += (dy*_getCanvasSizeRatio().y);
                        });

                        this.setPoints(_getPointsByRectangles());                    
                    } else {
                        points.forEach(p => {
                            p.x += (dx*_getCanvasSizeRatio().x);
                            p.y += (dy*_getCanvasSizeRatio().y);
                        })
    
                        // if (isModifiable) {
                            _vertices.forEach(e => {
                                e.x += (dx*_getCanvasSizeRatio().x);
                                e.y += (dy*_getCanvasSizeRatio().y);
                            })
                        // }    
                    }
                }
            }

            this.resetDragging = function() {
                this.isDragging = false;
                this.isModifying = false;
                // if (isModifiable) 
                _vertices.forEach(e => e.isDragging = false);
            }

            this.changeCursor = function(_x, _y) {
                var found = false;
                _vertices.forEach(e => {
                    if (e.isHit(_x, _y) || e.isDragging) {
                        found = true;
                        canvas.style.cursor='ne-resize';
                    }
                })

                if (_isHit(_x, _y) && found == false) {
                    found = true;
                    canvas.style.cursor='move';
                }

                return found;
            }

        }


        //catch the mouse's events on the canvas
        var MouseTouchTracker = function (canvas, _callback) {

            function processEvent(evt) {
                var rect = canvas.getBoundingClientRect();
                var offsetTop = rect.top;
                var offsetLeft = rect.left;

                if (evt.touches) {
                    return {
                        x: (evt.touches[0].clientX - offsetLeft),
                        y: (evt.touches[0].clientY - offsetTop)
                    }
                } else {
                    return {
                        x: (evt.clientX - offsetLeft),
                        y: (evt.clientY - offsetTop)
                    }
                }
            }

            function onDown(evt) {
                evt.preventDefault();
                var coords = processEvent(evt);
                _callback('down', coords.x, coords.y, evt);
            }

            function onUp(evt) {
                evt.preventDefault();
                var coords = processEvent(evt);
                _callback('up', coords.x, coords.y, evt);
            }

            function onMove(evt) {
                evt.preventDefault();
                var coords = processEvent(evt);
                _callback('move', coords.x, coords.y, evt);
            }

            function onWheel(evt) {
                evt.preventDefault();
                var coords = processEvent(evt);
                _callback('wheel', coords.x, coords.y, evt);
            }


            canvas.ontouchmove = onMove;
            canvas.onmousemove = onMove;

            canvas.ontouchstart = onDown;
            canvas.onmousedown = onDown;
            canvas.ontouchend = onUp;
            canvas.onmouseup = onUp;
            canvas.onwheel = onWheel;
        }

        function _mouseListenerFunction(evtType, x, y, evt) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            switch (evtType) {
                case 'wheel':
                    break;

                case 'down':
                    startX = x;
                    startY = y;

                    _objects.forEach(e => e.isDragging =  e.isHit(x,y))
                    if (_objects.filter(e => e.isDragging).length > 1) {
                        _objects.filter(e => e.isDragging).forEach((e, index) => {
                            if (index >0) {
                                e.resetDragging();
                            }
                        })
                    }
                    break;

                case 'up':
                    if (evt.button == 0) {
                        _objects.forEach(e => e.resetDragging());
                    } else if (evt.button == 2) {
                        evtType = 'rightUp';
                        _objects.some((e, index) => {
                            if (e.isHit(x,y)) {
                                var _confirm = popupConfirm('Delete the shape', 'Do you want delete the shape selected? ')
                                $mdDialog.show(_confirm).then(_yes => {
                                    _objects.splice(index, 1);
                                    _renderAllObjects();
                                    callback?callback(evtType, x, y, evt):null;
                                });

                                return true;
                            }
                        })                        
                    }
                    break;

                case 'move':
                    var dx = (x - startX);
                    var dy = (y - startY);
                    startX = x;
                    startY = y;

                    var found = false;
                    _objects.some(e => {
                        if (e.changeCursor(x, y)) {
                            found = true;
                            return true;
                        }
                    });

                    if (!found) canvas.style.cursor='default';

                    _objects.filter(e => e.isDragging).forEach(e => e.updatePosition(dx, dy, x, y));
                    break;
            }

            _objects.forEach(e => e.render(ctx));

            //call the callback function set from the user, if it is defined
            if (evtType != 'rightUp') {
                callback?callback(evtType, x, y, evt):null;
            }
        }

        function _renderAllObjects() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            _objects.forEach(e => e.render(ctx));
        }


        return {
            init: function(_canvasId) {
                canvas = document.getElementById(_canvasId);
                ctx = canvas.getContext('2d');
                startX = 0;
                startY = 0;
                _objects = [];
                _initSizes();
                
                mtt = new MouseTouchTracker(canvas, _mouseListenerFunction);
            },
            setMouseListenerCallback: function(_callback) {
                callback = _callback;

                return mtt;                
            },
            refreshSizes: function() {
                _initSizes();
            }, 
            updateNewSizes: function(width, height) {
                _sizes.newWidth = width;
                _sizes.newHeight = height;
            },
            updateSizes: function(width, height) {
                _sizes.width = width;
                _sizes.height = height;
                
                var _canvasAntennasCenterId = canvas.id;

                $("#" + _canvasAntennasCenterId).width(width);
                $("#" + _canvasAntennasCenterId).height(height);
                $("#" + _canvasAntennasCenterId).attr('width', width);
                $("#" + _canvasAntennasCenterId).attr('height', height);    
            },
            getRatio: function(_ctx) {
                if (_ctx.canvas.id != ctx.canvas.id) {
                    return {
                        dx:_ctx.canvas.width/ctx.canvas.width,
                        dy:_ctx.canvas.height/ctx.canvas.height
                    }
                } else {
                    return {
                        dx:1,
                        dy:1
                    }

                }
                
            },
            createPointer: function() {
                var pointer = new Pointer()
                _objects.push(pointer);
                return pointer;
            },
            createPolygon: function(_points, _modifiable) {
                var obj = new Polygon(_points, _modifiable)
                _objects.push(obj);
                return obj;
            },
            createEllipse: function(x, y, rx, ry, rotation) {
                var obj = new Ellipse(x, y, rx, ry, rotation);
                _objects.push(obj);
                return obj;
            },
            setObjectModifiable: function(_modifiable) {
                _objects[0].setModifiable(_modifiable)
                _objects[0].render(ctx);
            },
            getObjects: function() {
                return angular.copy(_objects);
            },
            deleteAllObjects: function() {
                _objects = [];
            },
            renderAllObjects: function() {
                _renderAllObjects();
            }


        }
    })

    .service('htmlEvents', function () {
        return {
            dispatchEventWheel: function(_elementId, evt) {
                var event = new Event('wheel');
                event.deltaY = evt.deltaY;
                event.pageX = evt.pageX;
                event.pageY = evt.pageY;

                var elem = document.getElementById(_elementId);
                elem.dispatchEvent(event);
            },
            dispatchEventMouseDown: function(_elementId, evt) {
                var event = new Event('mousedown');
                event.pageX = evt.pageX;
                event.pageY = evt.pageY;
                var elem = document.getElementById(_elementId);
                elem.dispatchEvent(event);    
            }, 
            dispatchEventMouseUp: function(_elementId, evt) {
                var event = new Event('mouseup');
                event.pageX = evt.pageX;
                event.pageY = evt.pageY;
                var elem = document.getElementById(_elementId);
                elem.dispatchEvent(event);    
            },
            dispatchEventMouseMove: function(_elementId, evt) {
                var event = new Event('mousemove');
                event.pageX = evt.pageX;
                event.pageY = evt.pageY;
                var elem = document.getElementById(_elementId);
                elem.dispatchEvent(event);    
            }
        }
    });
