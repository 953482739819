//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('image360Factory', ['$timeout', 'myHttp', 'myLog',  'utility', 'myAlert','googlemaps',
    function($timeout, myHttp, myLog, utility, myAlert, googlemaps)  {
        var _instanceTypes = {
            map: 'map',
            image360: 'image360',
        }

        function _createMarker(_map, _fdd, _callback) {

            var _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_fdd.latitude, _fdd.longitude),
                title: _fdd.fileName,
                lat: _fdd.latitude,
                log: _fdd.longitude,
                fdd: _fdd,
            });

            _marker.addListener('click', function() {
                _callback?_callback(_marker):null;
            })

            return _marker;
        }

        function _destroyViewer(_viewer) {
            try {
                _viewer.destroy();
            } catch(_err) {
            }
            _viewer = undefined;

            return _viewer;
        }

        function _updateInstance(_instance, _listFdd, _instanceType) {
            if (_instanceType == _instanceTypes.image360) {
                if (_instance.listFdd.length > _listFdd.length) {
                    //filter off all of the _listFdd's elements from _instance.listFdd
                    var _list = _instance.listFdd.filter(e => !_listFdd.find(e1 => e1.id == e.id));

                    //if the 360-image showned doesn't belong anymore to instance.listFdd 
                    //I'm going to destroy the _instance.viewer
                    if (_list.find(e => e.id == _instance.fddSelected.id)) {
                        _instance.viewer = _destroyViewer(_instance.viewer);
                    }
                }
            } else if (_instanceType == _instanceTypes.map) {
                if (_instance.instanceDx.listFdd.length > _listFdd.length) {
                    //filter off all of the _listFdd's elements from _instance.listFdd
                    var _list = _instance.instanceDx.listFdd.filter(e => !_listFdd.find(e1 => e1.id == e.id));

                    //if the 360-image showned doesn't belong anymore to instance.listFdd 
                    //I'm going to destroy the _instance.viewer
                    if (_list.find(e => e.id == _instance.instanceDx.fddSelected.id)) {
                        _instance.instanceDx.viewer = _destroyViewer(_instance.instanceDx.viewer);
                        _instance.instanceSx.radar.setMap(null);
                    }

                    //remove the markers that belong to _list
                    _list.forEach(e => {
                        _instance.instanceSx.markers.find((m, _index) => {
                            if (m.fdd.id == e.id) {
                                m.setMap(null)
                                _instance.instanceSx.markers.splice(_index,1);

                                return true;
                            }
                        })
                    })


                } else if (_instance.instanceDx.listFdd.length < _listFdd.length) {
                    if (_instanceType == _instanceTypes.map) {
                        var _list = _listFdd.filter(e => !_instance.instanceDx.listFdd.find(e1 => e1.id == e.id));
                        _list.forEach(e => {
                            _instance.instanceSx.markers.push(_createMarker(_instance.instanceSx.map, e, _res => {
                                _instance.instanceDx.createViewer(_instance.instanceDx, _res.fdd);
                                _instance.instanceDx.scope.$apply();
                            }));
                        })
                    }
                }
            }
        }



    return {
        getInstanceType: function() {
            return {
                image360Image360: 'image360_image360',
                mapImage360: 'map_image360',
            }
        },
        getViewType: function() {
            return {
                image360Image360: {name:'360° to 360°', instanceKey:'image360_image360'},
                mapImage360: {name:'Map to 360°', instanceKey:'map_image360'},
            }
        },
        createViewer: function(_instance, _fdd, _callback) {
            _instance.fddSelected = _fdd;
            _instance.compassDegrees = 0;

            if (_instance.viewer) {
                _instance.viewer = this.destroyViewer(_instance.viewer);
            }

            utility.callFunctionEveryTime(' $("#'+_instance.containerId+'").get(0)', 200, function() {
                var _viewer = new PhotoSphereViewer.Viewer({
                    panorama: _instance.buildSrcUrl(_fdd).hdUrl,
                    container: _instance.containerId,
                    caption: '',
                    loadingImg: 'https://photo-sphere-viewer.js.org/assets/photosphere-logo.gif',
                    defaultZoomLvl:0,
                    touchmoveTwoFingers: true,
                    // mousewheelCtrlKey: true,
                });

                _callback ?_callback(_viewer):null;
            });
        },
        destroyViewer: function(_viewer) {
            return _destroyViewer(_viewer);
        },
        showFirstImage: function(_instance, _listFdd, _callback) {
            if (_listFdd && _listFdd.length>0) {
                _instance.listFdd = _listFdd;
                if (!_instance.viewer) {
                    this.createViewer(_instance, _listFdd[0], _viewer => {
                        _instance.viewer = _viewer;

                        _callback ?_callback(_listFdd[0]):null;
                    });
                }
            }            
        }, 
        addEventPositionUpdated: function(_instance, _callback) {
            _instance.viewer.on('position-updated', function(e, _position) {
                _instance.compassDegrees = (_position.longitude / ((2*Math.PI)/360)).toFixed(2);
                _instance.scope.$apply(); //force to update the $scope

                _callback ?_callback(_instance.compassDegrees):null;
            })
        },
        image360ShowFirstImage: function(_instance, _listFdd, _views, _viewShown, _mapCanvasId) {
            var _this = this;

            if (_viewShown == _views.image360) {
                _updateInstance(_instance, _listFdd, _instanceTypes.image360);

                this.showFirstImage(_instance, _listFdd, () => {
                    this.addEventPositionUpdated(_instance)
                });
            } else {
                if (_instance.type == this.getInstanceType().image360Image360) {
                    _updateInstance(_instance.instanceSx, _listFdd, _instanceTypes.image360);
                    _updateInstance(_instance.instanceDx, _listFdd, _instanceTypes.image360);
                } else {
                    _updateInstance(_instance, _listFdd, _instanceTypes.map);
                }

                if (_instance.type == this.getInstanceType().image360Image360) {
                    this.showFirstImage(_instance.instanceSx, _listFdd, () => {
                        this.addEventPositionUpdated(_instance.instanceSx);
                    });    
                } 

                if (_instance.type == this.getInstanceType().image360Image360) {
                    this.showFirstImage(_instance.instanceDx, _listFdd, () => {
                        this.addEventPositionUpdated(_instance.instanceDx);
                    });    
                } else {
                    this.showFirstImage(_instance.instanceDx, _listFdd, _fdd => {                    
                        utility.callFunctionWithScopeEveryTime(_instance.instanceSx, '.map', 300, function() {
                            _this.createRadar(_instance.instanceSx, _fdd);          

                            _this.addEventPositionUpdated(_instance.instanceDx, _degrees => {
                                _this.rotateRadar(_instance.instanceSx, _degrees);
                            });    
                        })
                    });
                }
            }
        },
        createMap: function(_mapCanvasId, _instance, _listFdd, _callback) {
            googlemaps.initGoogleMap(_mapCanvasId, _m => {                
                _instance.map = _m;
                _instance.markers = [];

                _listFdd.forEach(e => {
                    _instance.markers.push(_createMarker(_m, e, _callback));
                })

                googlemaps.fitBounds(_m, _instance.markers, () => {
                    // _m.setZoom(17);
                    _m.setTilt(0);
                })
            });
        },
        createRadar: function(_instance,  _fdd) {
            if (_instance.radar) _instance.radar.setMap(null);

            _instance.radar = googlemaps.createTriangle(_instance.map, _fdd.latitude, _fdd.longitude,1.5,1);
            _instance.radarDegrees = 0;
        },
        rotateRadar: function(_instance, _degrees) {
            googlemaps.rotatePolygon(_instance.radar, _degrees-_instance.radarDegrees)
            _instance.radarDegrees = _degrees;
        },


    }
    
}])