//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('matterportFactory', ['assetDataServices','popup', 'myAlert', 'fsValidator',
    function(assetDataServices, popup, myAlert, fsValidator) { 

        function _saveMatterport(_matterport, _assetId, _identity, _success, _error) {
            _matterport.facilityId = _assetId;
            assetDataServices.saveMatterport(_identity.id, _matterport, _res => {

                myAlert("Matterport saved", 'info');

                _success ? _success(_res) : null;  //Call the _success function only if it is defined
            }, _err => {
                // myAlert("Error: " + _err.data.message, 'danger');

                // if (myHttp.analizeBackendError(_err) && _err.data.backendError == "_concurrent_access") {

                //     var _confirm = popupConfirm('Concurrent Access Problem', 'Asset has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                //     $mdDialog.show(_confirm).then(function () {

                //         assetServices.getAsset(_asset.id, _res => {
                //             _updateAssetAfterActions(_this.listAsset, _asset, _res);

                //             _success ? _success(_res, true) : null;  //Call the _success function only if it is defined
                //         })
                //     }, function () {
                //     });


                // } else {
                //     _error ? _error(_err) : null;  //Call the _error function only if it is defined
                // }
            })
        }        

        function _validationMatterport(_matterport) {
            var _ret = false;

            _validationItems('matterport', _matterport).success(function () {
                _ret = true;
            }).errors(function () {
                _ret = _matterport;
            })

            return _ret
        }

        function _validationItems(_type, _item) {
            var v = fsValidator.newInstance();

            switch (_type) {
                case 'matterport':
                    v.validate(_item.description, 'description').isEmpty("This field can't be empty")
                    v.validate(_item.matterportUrl, 'matterportUrl').isEmpty("This field can't be empty")
                    break;
            }

            _item.errors = v.getErrors();

            return v;
        }

        return {
            getMatterportUrl: function(_facilityDataId, _success, _error) {
                assetDataServices.getAssetDataById(_facilityDataId, _res => {
                    _success(_res.matterportUrl);
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    _error(_err);
                })
            },
            newMatterportPopup: function(_controllerName) {
                var _fns = [
                    { 'btn_identifier':'_1_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_fn': null },
                    { 'btn_identifier':'_1_2', 'btn_close': false, 'btn_confirm': true, 'btn_validation': 'validationMatterportFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-primary', 'btn_fn': 'insertNewMatterport' },
                ];
    
                var _popupData = { 
                    matterport:{}
                }
    
                var _config = {
                    size: 'md',
                    title: 'Insert Matterport link',
                    ctrl: _controllerName,
                    data: JSON.stringify(_popupData),
                    fns: JSON.stringify(_fns),
                    htmlPage: 'views/asset-data/popup/asset-data_matterport_popup.html',
                }
                popup.openModalBody(_config);          
            },
            insertNewMatterport: function(_popup, _assetId, _identity, _success, _error) {
                _saveMatterport(_popup.data.matterport, _assetId, _identity, (_res, _updatated) => {

                    _popup.data.matterport = _res;
                    if (!_updatated) {
                        //comunicate to the popup that the saving customer task was correctly completed
                        _popup.success(_popup.data)
                    } else {
                        _popup.data.matterport = _res;
                        _popup.updateData(_popup.data);
                    }
                    _success ? _success(_res) : null;  //Call the _success function only if it is defined
                }, _err => {
                    _popup.error(_err);
                    _error ? _error(_err) : null;  //Call the _error function only if it is defined
                });    
            },
            validationMatterport: function(_popup) {
                var _ret = _validationMatterport(_popup.data.matterport);

                if (_ret == true || _ret == false) {
                    return _ret;
                } else {
                    _popup.data.matterport = _ret;
                    return _popup.data;
                }
    
            } 

        }
    }]);