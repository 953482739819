//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("inventoryServices", ['buildUrl', 'serviceUtility',  function(buildUrl, serviceUtility) {

        var _functions  = {
            getInventoryItemByAssetId: function(_assetId, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryItem/byFacilityId/' + _assetId);
                var _errorMsg =  "I wasn't able to get the Inventory Items with asset_id: '"+_assetId+"'";

                serviceUtility.runGet("getInventoryItemByAssetId", _url, _errorMsg, _res => _success(_res.listInventoryItemDto), _error);
            },
            getInventoryStatusType: function(_success, _error) {
                var _url = buildUrl( '/expanse/inventoryStatusTypes');
                var _errorMsg = "I wasn't able to get the Inventory Status Types";

                serviceUtility.runGet("getInventoryStatusType", _url, _errorMsg, _res => _success(_res.listInventoryStatusTypeDto), _error);
            },
            saveInventoryItem: function(_userId, _inventoryItem, _success, _error) {
                var _url = buildUrl('/expanse/inventoryItem/' + _userId);
                var _errorMsg = "I wasn't able to save this inventory item";

                serviceUtility.runPost("saveInventoryItem", _url, _inventoryItem, _errorMsg, _res => _success(_res.inventoryItemDto), _error);
            },
            saveInventoryItems: function(_userId, _listInventoryItem, _success, _error) {
                var _url = buildUrl('/expanse/inventoryItems/' + _userId);
                var _errorMsg = "I wasn't able to save this inventory item list";

                serviceUtility.runPost("saveInventoryItems", _url, _listInventoryItem, _errorMsg, _res => _success(_res.listInventoryItemDto), _error);
            },
            saveInventoryFastItems: function(_userId, _listInventoryItem, _success, _error) {
                var _url = buildUrl('/expanse/inventoryFastItems/' + _userId);
                var _errorMsg = "I wasn't able to save this inventory item list";

                serviceUtility.runPost("saveInventoryFastItems", _url, _listInventoryItem, _errorMsg, _res => _success(_res.listInventoryItemDto), _error);
            },
            deleteInventoryItemById: function(_inventoryItemId, _success, _error) {
                var _url = buildUrl('/expanse/inventoryItem/' + _inventoryItemId);
                var _errorMsg = "I wasn't able to delete the Inventory Item by id: "+_inventoryItemId;

                serviceUtility.runDelete("deleteInventoryItemById", _url, _errorMsg, _success, _error);
            },
            getFacilityDataDetailsTagForInventory: function(_assetDataId, _inspectingCompanyId, _success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetailsTag/inventoryItemTagMap/byFacilityDataId/'+_assetDataId+'/byInspectingCompanyId/' + _inspectingCompanyId);
                var _errorMsg = "I wasn't able to get the FacilityDataDetailsTag list for auto Inventory ";

                serviceUtility.runGet("getFacilityDataDetailsTagForInventory", _url, _errorMsg, _res => _success(_res.listFacilityDataDetailsTagDto), _error);
            },
            getComponentTagForInventory: function(_assetDataId, _inspectingCompanyId, _success, _error) {
                var _url = buildUrl( '/expanse/componentTag/inventoryItemTagMap/byFacilityDataId/'+_assetDataId+'/byInspectingCompanyId/' + _inspectingCompanyId);
                var _errorMsg = "I wasn't able to get the ComponentTag list for auto Inventory ";

                serviceUtility.runGet("getComponentTagForInventory", _url, _errorMsg, _res => _success(_res.listComponentTagDto), _error);
            },
            getInventoryItemTagMap: function(_inspectingCompanyId, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryItemTagMap/byInspectingCompanyId/' + _inspectingCompanyId);
                var _errorMsg = "I wasn't able to get the Inventory Item Tag Map";

                serviceUtility.runGet("getInventoryItemTagMap", _url, _errorMsg, _res => _success(_res.listInventoryItemTagMapDto), _error);
            },
            getInventoryItemExtraInfo: function(_inventoryItemId, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryItemExtraInfo/byInventoryItemId/' + _inventoryItemId);
                var _errorMsg = "I wasn't able to get the Inventory Item Extra Info about inventory_item_id: " + _inventoryItemId;

                serviceUtility.runGet("getInventoryItemExtraInfo", _url, _errorMsg, _res => _success(_res.listInventoryItemExtraInfoDto), _error);
            },
            getInventoryItemExtraInfoLabel: function(_inspectingCompanyId, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryItemExtraInfoLabel/byCompanyId/' + _inspectingCompanyId);
                var _errorMsg = "I wasn't able to get the Inventory Item Extra Info Lable about inspecting_company_id: " + _inspectingCompanyId;

                serviceUtility.runGet("getInventoryItemExtraInfoLabel", _url, _errorMsg, _res => _success(_res.listInventoryItemExtraInfoLabelDto), _error);
            },
            saveInventoryItemExtraInfo: function(_listInventoryItemExtraInfo, _success, _error) {
                var _url = buildUrl('/expanse/inventoryItemExtraInfo/');
                var _errorMsg = "I wasn't able to save the inventory item extra info";

                serviceUtility.runPost("saveInventoryItemExtraInfo", _url, _listInventoryItemExtraInfo, _errorMsg, _res => _success(_res.listInventoryItemExtraInfoDto), _error);
            },
            getInventoryFieldByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryField/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg = "I wasn't able to get the Inventory Fields with company_id: '"+_inspectingCompanyId+"' and facility_type: '"+_facilityTypeKey+"'";

                serviceUtility.runGet("getInventoryFieldByCompanyIdAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listInventoryFieldDto), _error);
            },
            getInventoryGroupByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/expanse/inventoryGroup/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg = "I wasn't able to get the Inventory Group with company_id: '"+_inspectingCompanyId+"' and facility_type: '"+_facilityTypeKey+"'";

                serviceUtility.runGet("getInventoryGroupByCompanyIdAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listInventoryGroupDto), _error);
            },
            getProductGroupInventoryGroupByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/expanse/productGroupInventoryGroup/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg = "I wasn't able to get the Product Group Inventory Group with company_id: '"+_inspectingCompanyId+"' and facility_type: '"+_facilityTypeKey+"'";

                serviceUtility.runGet("getProductGroupInventoryGroupByCompanyIdAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listProductGroupInventoryGroupDto), _error);
            },

        };

        //inherited some useful function from serviceUtility
        // return angular.extend(_functions, serviceUtility.inheritedUtilities(_functions));
        return _functions;
    }]);
