//Add in this file all the AngularJS components (Controller, Directive, Service, Config, Factory, Value, Constant)
//that compose the application

// require('./js/app_TO-DELETE.js');
// require('./js/config_TO-DELETE.js');
require('./js/controllers_TO-DELETE/main.js');
require('./js/services_TO-DELETE.js');
require('./js/directives_TO-DELETE.js');
require('./js/controllers_TO-DELETE/ui-bootstrap.js');
// require('./js/controllers_TO-DELETE/table.js');

// require('./js/controllers_TO-DELETE/login.js');
// require('./js/controllers_TO-DELETE/dashboard.js');
// require('./js/controllers_TO-DELETE/customerWorkflowMain.js');
// require('./js/controllers_TO-DELETE/customerWorkflowReviewData.js');
// require('./js/controllers_TO-DELETE/customerWorkflowAssetDataPix4d.js');

// require('./js/servicesProperties_TO-DELETE.js');

//Application modules
require('./js/modules/appModule.js'); 
require('./js/factories/appFactory.js');
require('./js/constants/appConstant.js');
require('./js/directives/appDirective.js');

//Configs
require('./js/configs/appConfig.js');
require('./js/configs/appRouter.js');

//Services
require('./js/services/appService.js');
require('./js/services/appUIService.js');
require('./js/services/customerService.js'); 
require('./js/services/assetService.js');
require('./js/services/userService.js');
require('./js/services/assetDataService.js');
require('./js/services/assetDataUploadService.js');
require('./js/services/dashboardService.js');
require('./js/services/inspectionService.js');
require('./js/services/structureService.js');
require('./js/services/inspectionReportService.js');
require('./js/services/anomalyService.js');
require('./js/services/componentService.js');
require('./js/services/mainService.js');
require('./js/services/aiMachineService.js');
require('./js/services/productService.js');
require('./js/services/spinModelService.js');
require('./js/services/autodeskService.js');
require('./js/services/inventoryService.js');
require('./js/services/bomService.js');
require('./js/services/classManagmentService.js');
require('./js/services/azimuthService.js');
require('./js/services/dynamicCADService.js');
require('./js/services/projectService.js');


//Factoris
require('./js/factories/assetDataFactory.js');
require('./js/factories/assetDataByTagsFactory.js');
require('./js/factories/assetDataMethaneFactory.js');
require('./js/factories/inspectionReportFactory.js');
require('./js/factories/anomalyFactory.js');
require('./js/factories/componentFactory.js');
require('./js/factories/catalogueFactory.js');
require('./js/factories/inventoryFactory.js');
require('./js/factories/checklistFactory.js');
require('./js/factories/signalPropagationFactory.js');
require('./js/factories/image360Factory.js'); 
require('./js/factories/dynamicCADFactory.js');
require('./js/factories/bomFactory.js');
require('./js/factories/classManagmentFactory.js');
require('./js/factories/matterportFactory.js');
require('./js/factories/azimuthFactory.js');
require('./js/factories/tiltAngleFactory.js');
require('./js/factories/aiBuilderFactory.js');
require('./js/factories/compassViewerFactory.js');
require('./js/factories/projectFactory.js');



//Controllers
require('./js/controllers/loginController.js');
require('./js/controllers/mainController.js');
require('./js/controllers/appControllers.js');
require('./js/controllers/customerController.js');
require('./js/controllers/assetController.js');
require('./js/controllers/userController.js');
require('./js/controllers/assetDataController.js');
require('./js/controllers/assetDataUploadController.js');
require('./js/controllers/headerController.js');
require('./js/controllers/directiveController.js');

require('./js/components/dashboard');
require('./js/filters/filters');




// require('./js/modules/template.js');
// require('./js/modules/ui.js');
// require('./js/modules/charts/flot.js');
// require('./js/modules/charts/other-charts.js');
// require('./js/modules/form.js');
// require('./js/modules/media.js');
// require('./js/modules/components.js');
// require('./js/modules/calendar.js');
// require('./js/modules/demo.js');


// require('./js/directives/sfCalendar.js');
// require('./js/directives/sfDateInput.js');
// require('./js/directives/sfMultipleSelect.js');

