const expanseLiteApp = require('../../../modules/appModule.js');

expanseLiteApp.component('ownerLastReportsCmp', {
    templateUrl: 'views/dashboard/asset-owner/owner-latest-reports.html',
    controller: DashboardOwnerLastReportsController,
    controllerAs: 'repsCtrl',
    bindings: {
        reportsPromise: '<'
    }
});

/* @ngInject */
function DashboardOwnerLastReportsController(customerServices) {
    const _this = this;

    _this.reports = [];

    _this.$onInit = () => {
        _initReports();
    };

    function _initReports() {
        if(!_this.reportsPromise) {
            return;
        }

        _this.reportsPromise.then(reports => {
            console.log('reeeps', reports);
            _this.reports = reports
                .sort((a,b) => (b.lastUpdateTime || b.createdDate) - (a.lastUpdateTime || a.createdDate))
                .map(report =>
                    Object.assign(report,
                        {
                            severities: customerServices.getSeveritiesByLocations(report.moreData.ic.locations)
                        }
                    ));
        });
    }

    function _getCircleInfo(inspCompId) {
        return customerServices.getLocationsSeveritiesByInspectingComponent(inspCompId);
    }
}
