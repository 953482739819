//Write here all the configs about the whole application

let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.config(function(IdleProvider, KeepaliveProvider, expanseConst) {
    console.log("Idle and Timeout session config: "+expanseConst.idleTime+"s, "+expanseConst.timeoutTime+"s")

    IdleProvider.idle(expanseConst.idleTime); //time in sec. Set how long the application can stay idle
    IdleProvider.timeout(expanseConst.timeoutTime); //time in sec. Set how much time, after the idle time, take to set the declare the application timeout
    KeepaliveProvider.interval(expanseConst.idleTime-1); //time in sec
    KeepaliveProvider.http('www.google.it'); // URL that makes sure session is alive  
    
  });

expanseLiteApp.run(function(Idle, Keepalive){
	// start watching when the app runs. also starts the Keepalive service by default.
    Keepalive.stop();
	Idle.watch();
});

//Config angular-loading-bar
expanseLiteApp.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = true;
}])


// Config the  textAngular tool to show
expanseLiteApp.config(['$provide', function ($provide) {

    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function (taRegisterTool, taOptions) {

        taOptions.toolbar = [
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
            ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
            ['html', 'wordcount', 'charcount']
        ];

        return taOptions;
    }])

}])
