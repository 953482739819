//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("assetServices", ['myHttp', 'buildUrl', 'myLog', 'utility', 'serviceUtility', function(myHttp, buildUrl, myLog, utility, serviceUtility) {
        return  {
            /**
             * Get all customer's assets from backend
             * @param _customerId customer's identify
             * @param _success callback function called if the request succeeded
             * @param _error callback function called if the request goes wrong
             * @param _isInspectingCompany defines if inspected customer's assets should be fetched
             */
            getCustomerAssets: function(_userId, _isInspectingCompany, _customerId, _success, _error) {
                let _url;
                if (_isInspectingCompany) {
                    _url = buildUrl( '/expanse/facility/byInspectingCompany/' + _customerId+"/byUserId/"+_userId);
                } else {
                    _url = buildUrl( '/expanse/facility/byCustomer/' + _customerId +"/byUserId/"+_userId);
                }

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the assets about the customer: '"+_customerId+"'";
                    _error(_err);
                } );
            },

            getAsset: function(_assetId, _success, _error) {
                var _url = buildUrl( '/expanse/facility/' + _assetId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset with id: '"+_assetId+"'";
                    _error(_err);
                } );
            },

            saveAsset: function(_userId, _asset, _success, _error) {
                var _url = buildUrl('/expanse/facility/' + _userId);

                myHttp.post(_url, JSON.stringify(_asset)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this asset";
                    }

                    _error(_err);
                } );
            },

            saveNetworkLink: function(_userId, _asset, _success, _error) {
                var _url = buildUrl('/expanse/networkLink/' + _userId);

                myHttp.post(_url, JSON.stringify(_asset)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this asset";
                    }

                    _error(_err);
                } );
            },

            saveAsset3dModel: function(_userId, _assetId, _asset3dModel, _success, _error) {
                var _url = buildUrl('/expanse/facilityModel/assetId/' + _assetId + '/' + _userId);

                myHttp.post(_url, JSON.stringify(_asset3dModel)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.facilityModelDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this 3D model";
                    }

                    _error(_err);
                } );
            },

            saveAssetStructure: function(_userId, _assetStructure, _success, _error) {
                var _url = buildUrl('/expanse/structure/' + _userId);

                myHttp.post(_url, JSON.stringify(_assetStructure)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.structureDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this structure";
                    }

                    _error(_err);
                } );
            },

            getAssetStructures: function(_assetId, _success, _error) {
                var _url = buildUrl('/expanse/structures/byFacility/'+_assetId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listStructureDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset's structure about assetid:"+_assetId;
                    _error(_err);
                } );
            },

            //Get asset status types
            getAssetStatusTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/facilityStatusTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityStatusTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset status types";
                    _error(_err);
                } );
            },

            getAssetStatusProject: function(_success, _error) {
                var _url = buildUrl('/expanse/facilityStatusProject/');
                var _errorMsg = "I wasn't able to get the asset status project";

                serviceUtility.runGet("getAssetStatusProject", _url, _errorMsg, _res => _success(_res.listFacilityStatusProjectDto), _error);
            },

            getAssetTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/facilityTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset types";
                    _error(_err);
                } );
            },

            getSiteTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/siteTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listSiteTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the site types";
                    _error(_err);
                } );
            },

            getStructureTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/structureTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listStructureTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the structure types";
                    _error(_err);
                } );
            },

            getStructureTypeByCompanyId: function(_companyId, _success, _error) {
                var _url = buildUrl('/expanse/structureTypes/byCompanyId/'+_companyId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listStructureTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the structure types about companyId: " + _companyId;
                    _error(_err);
                } );
            },            

            getAsset3dModelsByAssetType: function(_companyId, _assetTypeId, _success, _error) {
                var _url = buildUrl('/expanse/facilityModels/byCompany/'+_companyId+'/byFacilityType/'+_assetTypeId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityModelDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset 3D Models for asset-type-id:" + _assetTypeId;
                    _error(_err);
                } );
            },

            getAsset3dPhotogrammetryByAssetId: function(_assetId, _success, _error) {
                var _url = buildUrl('/expanse/facilityData/3DPhotogrammetry/byFacility/'+_assetId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset 3D Photogrammetry for asset-id:" + _assetId;
                    _error(_err);
                } );
            },

            getEstateLinkAssets: function(_estateId, _success, _error) {
                var _url = buildUrl('/expanse/estateLink/'+_estateId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listEstateLinkDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset links of estate:" + _estateId;
                    _error(_err);
                } );
            },

            getNetworkLink: function(_networkLinkId, _success, _error) {
                var _url = buildUrl('/expanse/networkLink/'+_networkLinkId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listNetworkLinkDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset links of networkLink:" + _networkLinkId;
                    _error(_err);
                } );
            },

            getNetworkLinkInds: function(_success, _error) {
                var _url = buildUrl('/expanse/networkLinkInds');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listNetworkLinkIndDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset link inds" ;
                    _error(_err);
                } );
            },

            getNetworkLinkTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/networkLinkTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listNetworkLinkTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset link types" ;
                    _error(_err);
                } );
            },

            getEstateAssets: function(_estateId, _success, _error) {
                var _url = buildUrl('/expanse/estateAssets/'+_estateId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the assets of estate:" + _estateId;
                    _error(_err);
                } );
            },

            getNetworkLinkAssets: function(_networkLinkId, _success, _error) {
                var _url = buildUrl('/expanse/networkLinkAssets/'+_networkLinkId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the assets of networkLink:" + _networkLinkId;
                    _error(_err);
                } );
            },


            getEstateAreaCoordinate: function(_estateId, _success, _error) {
                var _url = buildUrl('/expanse/facilityAreaCoordination/byFacilityId/'+_estateId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityAreaCoordinationDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the estate's area coordination about estateId:" + _estateId;
                    _error(_err);
                } );
            },

            getAssetSourceTypes: function(_success, _error) {
                var _url = buildUrl('/expanse/facilityDataSourceTypes');

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataSourceTypeDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data source types";
                    _error(_err);
                } );
            },


            //Thomas Integration
            saveAssetForensicPoint: function(_userId, _asseForensicPoint, _success, _error) {
                var _url = buildUrl('/expanse/forensicPoint/' + _userId);

                myHttp.post(_url, JSON.stringify(_asseForensicPoint)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.forensicPoint);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this Forensic Point";
                    }

                    _error(_err);
                } );
            },

            saveAssetNewForensicPoint: function(_userId, _asseForensicPoint, _success, _error) {
                var _url = buildUrl('/expanse/newForensicPoint/' + _userId);

                myHttp.post(_url, JSON.stringify(_asseForensicPoint)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.forensicPoint);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to save this Forensic Point";
                    }

                    _error(_err);
                } );
            },

            getAssetForensicPoint: function(_assetId, _success, _error) {
                var _url = buildUrl('/expanse/forensicPoint/byFacility/'+_assetId);

                myHttp.get(_url).success( _res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.listForensicPointDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset's ForensicPoint about assetid:"+_assetId;
                    _error(_err);
                } );
            },

            removeAssetForensicPoint: function(_assetId, _success, _error) {
                var _url = '/api/removeFpList';

                myHttp.post(_url, JSON.stringify({'facility_id': _assetId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to remove all Forensic Point";
                    }

                    _error(_err);
                } );
            },

            getFileUploadTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/fileUploadTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFileUploadTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the fileUploadTypes api";
                    _error(_err);
                } );    
            },
            
            getAssetDataFileTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/facilityDataFileTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataFileTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data file types list";
                    _error(_err);
                } );    
            },

            // create3DModelBulk: function(_listAsset, _companyId, _success, _error) {
            //     var _url = '/api/create3DModelBulk';

            //     myHttp.post(_url, JSON.stringify({'listAsset': _listAsset, 'companyId': _companyId})).success(_res => {
            //         myLog("Result from " + _url, _res);
            //         _success(_res);

            //     }).error( _err => {
            //         myLog("Error from " + _url, _err.data);
            //         _error(_err);
            //     } );    
            // },  
            

            objTracking: function(AssetData, FacilityDataDetailsId, ObjToTrack, _success, _error) {
                var _url = '/api/objTracking';

                myHttp.post(_url, JSON.stringify({'AssetData': AssetData, 'FacilityDataDetailsId': FacilityDataDetailsId, 'ObjToTrack': ObjToTrack})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  


            objTrackingGetComponent: function(FacilityDataDetailsId, _success, _error) {
                var _url = '/api/objTrackingGetComponent';

                myHttp.post(_url, JSON.stringify({'FacilityDataDetailsId': FacilityDataDetailsId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  

            masterListRetrieve: function(companyId, _success, _error) {
               
                var _url = buildUrl('/expanse/masterList/byCompanyId/' + companyId);

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  

            iscTemplateRetrieve: function(companyId, _success, _error) {
               
                var _url = buildUrl('/expanse/iscTemplateList/byCompanyId/' + companyId);

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  


            getFacilityStatusPhase: function(_success, _error) {
               
                var _url = buildUrl('/expanse/facilityStatusPhase');

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  

            saveISCTemplate: function(listISCTemplateQuestion, customerId, templateName, assetStatusPhaseSelected, _success, _error) {
                var _url = '/api/saveISCTemplate';

                myHttp.post(_url, JSON.stringify({'listQuestion': listISCTemplateQuestion, 'customerId': customerId, 'templateName':templateName, 'assetStatusPhase': assetStatusPhaseSelected})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            }, 

            getTemplateQuestions: function(templateID, _success, _error){
                var _url = '/api/getISCTemplateQuestion';

                myHttp.post(_url, JSON.stringify({'templateID': templateID})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );   
            },


            createISCQA: function(templateID, AssetId, _success, _error) {
                var _url = '/api/createChecklistISCTemplate';

                myHttp.post(_url, JSON.stringify({'templateID': templateID, 'AssetId': AssetId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            }, 
            
            getIscQuestionCategory: function(_success, _error) {
               
                var _url = buildUrl('/expanse/iscQuestionCategory');

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            },  

            reportCreationMhD: function(reportType, customerId, _success, _error) {
                var _url = '/api/reportCreation';

                myHttp.post(_url, JSON.stringify({'reportType': reportType, 'customerId': customerId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );    
            }, 


            
            


            // exportInventory: function(assetId, companyId,_success, _error) {
            //     var _url = '/api/exportInventory';

            //     myHttp.post(_url, JSON.stringify({'assetId': assetId, 'companyId':  companyId})).success(_res => {
            //         myLog("Result from " + _url, _res);
            //         _success(_res);

            //     }).error( _err => {
            //         myLog("Error from " + _url, _err.data);
            //         _error(_err);
            //     } );    
            // },  

            /**
             * Return a zero-filled array of asset status info
             * @param _assetStatusTypes
             * @param _listCssExtracted
             * @returns Array of asset statuses info
             *     name        - key value of the _assetStatusTypes parameter
             *     percentage  - is the percentage value of the assets/estates in that status
             *     count       - is the number of assets/estates in that status\
             *     chart       - contains that will be used into the chart
             *     bg-color    - background color of the element into the chart that represent that status
             */
            buildAssetStatusArray: function(_assetStatusTypes, _listCssExtracted) {
                const _assetStatus = [];

                if (_assetStatusTypes && _assetStatusTypes.length > 0 &&
                    _listCssExtracted && _listCssExtracted.length > 0) {

                    //create the array _listCssExtracted where the index and value are respectevely
                    //the fields 'name' and 'value' of the _listCssExtracted elements
                    const _listCssExtractedByKey = utility.getArrayByField(_listCssExtracted, 'name', 'value');

                    //Create an element for each status type
                    _assetStatusTypes.forEach(e => {
                        const _item = {
                            'name': e.key,
                            'titleName': e.value,
                            'percentage': 0,
                            'count': 0,
                            'chart': {
                                'bg-color': _listCssExtractedByKey[e.key].backgroundColor
                            }
                        };

                        _assetStatus.push(_item);
                    });
                }

                return _assetStatus;
            },

            /**
             * Return an array of asset status info with percentage and count
             * @param _listAsset list of assets
             * @param _assetStatus list of statuses (immutable)
             * @returns Array of asset statuses info
             *     name        - key value of the _assetStatusTypes parameter
             *     percentage  - is the percentage value of the assets/estates in that status
             *     count       - is the number of assets/estates in that status\
             *     chart       - contains that will be used into the chart
             *     bg-color    - background color of the element into the chart that represent that status
             */
            computeAssetStatusValues: function(_listAsset, _assetStatus) {
                const _array = [];

                if (_listAsset && _listAsset.length > 0) {
                    //Create this array, by an _assetStatus copy,
                    //to make easier to access the _assetStatus elements
                    const _assetStatusByKey = utility.getArrayByField(angular.copy(_assetStatus), 'name');
                    const _assetsNumber = _listAsset.length;  //this is the number of assets

                    //for each type of asset status count the number of assets in that status
                    _listAsset.forEach(e => {
                        const _status = e.status || 'unknown';
                        _assetStatusByKey[_status].count += 1;
                    });

                    //compute the percentage for each asset status type
                    _assetStatus.forEach(e => {
                        _assetStatusByKey[e.name].percentage = Math.round((_assetStatusByKey[e.name].count / _assetsNumber) * 100);
                        _array.push(_assetStatusByKey[e.name]);
                    });
                }
                return _array;
            },

            /**
             * Prepare asset status data to be used in a chart
             * @param _assetStatusComputed computed status data
             * @returns {{backgroundColor: Array, data: Array, labels: Array}}
             */
            buildAssetStatusChartData: function(_assetStatusComputed) {
                const labels = [];
                const data = [];
                const backgroundColor = [];
                const titles = [];
                const dataAbs = [];

                //from _assetStatusComputed parameter
                //extracts _data, _labels and _backgroundColor
                //for the chart
                _assetStatusComputed.forEach(e => {
                    data.push(e.percentage);
                    labels.push(e.name);
                    backgroundColor.push(e.chart['bg-color']);
                    titles.push(e.titleName);
                    dataAbs.push(e.count);
                });

                return {
                    data, labels, backgroundColor, titles, dataAbs
                };
            },


            changeAssetDataStatus: function(id, status, _success, _error) {
                var _url = '/api/changeAssetStatus';

                myHttp.post(_url, JSON.stringify({'AssetDataId': id, 'AssetStatus': status})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );  
                
            },  


            updateNotificationList: function(listEmails, companyId, customerToId,  _success, _error) {
                var _url = '/api/updateNotificationList';

                myHttp.post(_url, JSON.stringify({'listEmails': listEmails, 'companyId': companyId, 'customerTo': customerToId})).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );  
            }, 


            getNotificationList: function(companyId, _success, _error) {
                var _url = '/api/updateNotificationList?companyId=' + companyId;

                myHttp.get(_url).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _error(_err);
                } );  
            }, 

            getAllObservationReportMHD: function(_companyId, _userId, _isInspector, _success, _error) {
                var _url = buildUrl( '/mhd/allObservationReport/'+_companyId+"/"+_userId+"/"+_isInspector);
                var _errorMsg = "I wasn't able to create the allObservationReport.csv report";

                serviceUtility.runGet("getAllObservationReportMHD", _url, _errorMsg, _res => _success(_res), _error);
            },
            getSeverityByPoleReportMHD: function(_companyId, _userId, _isInspector, _success, _error) {
                var _url = buildUrl( '/mhd/severityByPoleReport/'+_companyId+"/"+_userId+"/"+_isInspector);
                var _errorMsg = "I wasn't able to create the severityByPoleReport.csv report";

                serviceUtility.runGet("getSeverityByPoleReportMHD", _url, _errorMsg, _res => _success(_res), _error);
            },
            getIncrementalInspectionReportMHD: function(_companyId, _userId, _isInspector, _begin, _end, _success, _error) {
                var _url = buildUrl( '/mhd/incrementalInspectionReport/'+_companyId+"/"+_userId+"/"+_isInspector+"/"+_begin+"/"+_end);
                var _errorMsg = "I wasn't able to create the incrementalInspectionReport.csv report";

                serviceUtility.runGet("getIncrementalInspectionReportMHD", _url, _errorMsg, _res => _success(_res), _error);
            },
            getPoleTopClassificationReportMHD: function(_companyId, _userId, _success, _error) {
                var _url = buildUrl( '/mhd/poleTopClassificationReport/'+_companyId+"/"+_userId);
                var _errorMsg = "I wasn't able to create the poleTopClassificationReport.csv report";

                serviceUtility.runGet("getPoleTopClassificationReportMHD", _url, _errorMsg, _res => _success(_res), _error);
            },

        };
    }]);