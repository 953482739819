//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("dynamicCADServices", ['buildUrl', 'serviceUtility', 'myHttp', function(buildUrl, serviceUtility, myHttp) {

        var _functions  = {
            getModel3DById: function(_id, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/model3D/byId/' + _id);
                var _errorMsg =  "I wasn't able to get the 3D Model with id: '"+_id+"'";

                serviceUtility.runGet("getModel3DById", _url, _errorMsg, _res => _success(_res.model3D), _error);
            },
            getModel3DObjectByModel3DId: function(_model3DId, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/model3DObjects/byModel3DId/' + _model3DId);
                var _errorMsg =  "I wasn't able to get the 3D Model Objects with model3D_id: '"+_model3DId+"'";

                serviceUtility.runGet("getModel3DObjectByModel3DId", _url, _errorMsg, _res => _success(_res.listModel3DObject), _error);
            },
            getObject3DByCompanyAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/objects3D/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg =  "I wasn't able to get the 3D Objects about company_id: '"+_inspectingCompanyId+" and facility_type_key: '"+_facilityTypeKey;

                serviceUtility.runGet("getObject3DByCompanyAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listObject3D), _error);
            },
            getObjects3DTypeByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/objects3DType/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg =  "I wasn't able to get the 3D Objects Type about company_id: '"+_inspectingCompanyId+" and facility_type_key: '"+_facilityTypeKey;

                serviceUtility.runGet("getObjects3DTypeByCompanyIdAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listObject3DType), _error);
            },
            getObjects3DHierarchyByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/objects3DHierarchy/byCompanyId/'+_inspectingCompanyId+'/byFacilityTypeKey/' + _facilityTypeKey);
                var _errorMsg =  "I wasn't able to get the 3D Objects Hierarchy about company_id: '"+_inspectingCompanyId+" and facility_type_key: '"+_facilityTypeKey;

                serviceUtility.runGet("getObjects3DHierarchyByCompanyIdAndFacilityTypeKey", _url, _errorMsg, _res => _success(_res.listObject3DHierarchy), _error);
            },
            saveModel3DById: function(_userId, _model3D, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/model3D/' + _userId);
                var _errorMsg = "I wasn't able to save the 3D Model";

                serviceUtility.runPost("saveModel3DById", _url, _model3D, _errorMsg, _res => _success(_res.model3D), _error);
            },
            saveModel3DObjects: function(_userId, _model3DId, _listModel3DObject, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/model3DObjects/model3DId/' + _model3DId + '/' + _userId);
                var _errorMsg = "I wasn't able to save the 3D Model Objects";

                serviceUtility.runPost("saveModel3DObjects", _url, _listModel3DObject, _errorMsg, _res => _success(_res.listModel3DObject), _error);
            },
            saveModel3DInfo: function(_userId, _model3DInfo, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/model3DInfo/' + _userId);
                var _errorMsg = "I wasn't able to save the 3D Model";

                serviceUtility.runPost("saveModel3DInfo", _url, _model3DInfo, _errorMsg, _res => _success(_res), _error);
            },
            cloneModel3D: function(_userId, _data, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/cloneModel3D/' + _userId);
                var _errorMsg = "I wasn't able to clone the 3D Model";

                serviceUtility.runPost("cloneModel3D", _url, _data, _errorMsg, _res => _success(_res), _error);
            },
            confirmModel3DAfter: function(_userId, _data, _success, _error) {
                var _url = buildUrl( '/dynamicCAD/confirmModel3DAfter/' + _userId);
                var _errorMsg = "I wasn't able to confirm the after 3D-Model as the main 3D-Model";

                serviceUtility.runPost("confirmModel3DAfter", _url, _data, _errorMsg, _res => _success(_res), _error);
            },
            sendFileToAWSS3: function(_path, _file, _success, _error) {
                myHttp.post('api/createPresignedPost', JSON.stringify({'filename':_path})).success(_presignedURL => {
                        
                    myHttp.put(_presignedURL, _file, 'application/zip', null).success(_resUpl => {
                        _success(_resUpl);
                    });
                });
            },
            deleteFileToAWSS3: function(_path, _success, _error) {
                var _url = buildUrl( '/awss3/deleteObject');
                var _errorMsg = "I wasn't able to the 3D-Model from AWS S3";
                var _data = {key: _path}

                serviceUtility.runPost("deleteFileToAWSS3", _url,  _data, _errorMsg, _res => _success(_res), _error);
            }
            

        };

        return _functions;
    }]);
