//Write in this controller all the code about CUSTOMER
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.controller('customerCtrl',
    ['$scope', '$stateParams', 'Scopes', 'myLog', 'myAlert', 'myHttp', 'expanseConst', 'customerServices', 'userServices', 'myThrow', 'popup', 'utility', 'utilityImage', 'fsValidator', '$mdDialog', 'popupConfirm', 
        function ($scope, $stateParams, Scopes, myLog, myAlert, myHttp, expanseConst, customerServices, userServices, myThrow, popup, utility, utilityImage, fsValidator, $mdDialog, popupConfirm) {
            //Get the information about the user logged
            var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

            var _this = this;

            //-------------------------- private area Begin -----------------------------------
            var _controllerName = "customerCtrl";
            var _customerTypeInspected = 'INSPECTED';
            //-------------------------- private area End-- -----------------------------------

            //-------------------------- _this area Begin -----------------------------------
            _this.utilityImage = utilityImage;
            _this.expanseConst = expanseConst;              //Expose expanse's consts service
            _this.listCustomer;                             //List of  user's customers
            _this.customerSelected;                         //Customer selected            
            _this.isInspector = _identity.isInspector;
            _this.IdAccount = _identity.id;
            //-------------------------- _this area End-- -----------------------------------

            //-------------------------- $scope area Begin -----------------------------------
            $scope.customerIdSelected;                       //Customer id selected
            //-------------------------- $scope area End-- -----------------------------------

            //Write here the code that you need when the controller is created
            //The $scope will be put and get from store.
            //In this way it will be possible to cache some information
            //and it will be possible to use some function in others controllers
            _this.init = function () {
                //Get the $scope about this controller from the store;
                // var _ctrl = Scopes.get(_controllerName);

                // //if the controller $scope is in the store, I'm going to use it
                // //otherwise the $scope will be saved into the store
                // if (_ctrl) {
                //     Scopes.add($scope, Scopes.cleanFromScope(_ctrl));
                // } else {
                //     //Save all the $scope objects
                //     //that could be requested by others controllers
                //     Scopes.store(_controllerName, $scope);

                // }

                Scopes.store(_controllerName, $scope);
 
                _this.isCustomerListShown = false;


                if (_identity.isInspector) {
                    customerServices.getAllCustomers(_identity.companyId, _identity.id, _res => {
                        _res.sort((_a,_b) => {
                            if (_a.name > _b.name) {
                                return 1;
                            } else if  (_a.name < _b.name) {
                                return -1;
                            }
                            
                            return 0;
                        } )
    
                        _this.listCustomer = _res;
    
                        //select from _res the customer previously selected by the user 
                        if ($stateParams.customer_id) {
                            $scope.customerIdSelected = $stateParams.customer_id;
    
                        } else {
                            if (_res.length > 0) {
                                $scope.customerIdSelected = _this.listCustomer[0].id;
                            }
                        }
    
                        _initCustomer();
                    })    
                } else {
                    $scope.customerIdSelected = _identity.companyId;

                    customerServices.getCustomer($scope.customerIdSelected, _res => {
                        _this.listCustomer = [];
                        _this.listCustomer.push(_res)

                        _initCustomer();
                    })    

                }
            }

            _this.toggleCustomerList = function() {
                _this.isCustomerListShown = !_this.isCustomerListShown;
                $(".customer-list").height($("#_customer_asset_container").height()*0.5);
            }

            function _initCustomer() {
                _this.setCustomerSelected(utility.getArrayElementByField(_this.listCustomer, 'id', $scope.customerIdSelected)); 
                _this.getCustomerUsers($scope.customerIdSelected)  
                
                var _assetCtrl = Scopes.get("assetCtrl");
                if (_assetCtrl && _assetCtrl._assetCtrl) {
                    _assetCtrl._assetCtrl.getCustomerAssetsEstates($scope.customerIdSelected)
                }
            }

            //Get the user's companyId
            _this.getCompanyId = function()  {
                myLog("get companyId from _identity", _identity);
                return _identity.companyId;
            }

            //Get all the company's customers
            //and put them into listCustomer variable
            _this.getAllCustomers = function (_companyId) {
                if (_companyId) {
                    myLog("Get all customers with companyId: ", _companyId);

                    customerServices.getAllCustomers(_companyId, _identity.id, _res => {
                        _this.listCustomer = _res;
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })

                } else {
                    myAlert("Error: No company was requested", 'danger');
                    myThrow(_controllerName + ": _companyId parameter undefined or null");
                }
            }

            function _addCustomerLogo(_customer, _success) {
                customerServices.getCustomerLogo(_customer.id, _res => {
                    if (_res) {
                        _success(expanseConst.cfExpanseUrl + _res);
                    } else {
                        _success(undefined);
                    }
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                })
            }            
            _this.getCustomerSelected = function () { 
                return _this.customerSelected;
            }

            _this.setCustomerSelected = function (_customer) {
                $scope.customerIdSelected = _customer.id;
                _this.customerSelected = _customer;

                _addCustomerLogo(_customer, _res => {
                    _customer.customerLogoPath = _res;
                });

                Scopes.get('headerCtrl').setCustomerId(_customer.id);
            }


            function validationItems(_item) {
                var v = fsValidator.newInstance();
    
                v.validate(_item.name, 'name').isEmpty("This field can't be empty")
                v.validate(_item.name, 'name').isThereSomeCharacter(['/','*'], 'Chars /,* are not allowed in this field')

                _item.errors = v.getErrors();

                return v;
    
            }

            $scope.validationCustomerFromPopup = function(_popup) {
                return validationCustomer(_popup.data);
            }

            function validationCustomer(_customer) {
                var _ret = false;

                validationItems(_customer).success(function() {
                    _ret = true;
                }).errors(function() {
                    _ret = _customer;
                })

                return _ret
            }            

            $scope.saveCustomerFromPopup = function(_popup) {
                _saveCustomer(_popup.data, (_res, _updatated) => {

                    if (!_updatated) {
                        //comunicate to the popup that the saving customer task was correctly completed 
                        _popup.success(_res)                    
                    } else {
                        _popup.updateData(_res, {'title': _res.name});
                    }
                }, _err => {
                    _popup.error(_err);

                });
            }

            //Check if exists anothe customer with same name
            function checkExistCustomerWithName(_listCustomer, _customer) {
                var _found = false;

                //if it is a new customer, I'll check if there's another customer with the same name                
                if (_listCustomer && !_customer.id) {
                    var _name_new = utility.removeWhiteSpaces(_customer.name).toLowerCase();

                    _listCustomer.some(e => {
                        if (utility.removeWhiteSpaces(e.name).toLowerCase() == _name_new) {
                            _found = true;
                            return true;
                        }
                    })
                }                

                return _found;
            }

            //Update some customer info
            //Update or add a customer into customers list
            //Update the object _this.customerSelected
            //Add, if there are, the customer's users previously gotten from backend
            function updateCustomerInfoAfterActions(_listCustomer, _customerOld, _customerNew) {
                //get the customer index into the array
                var _index = utility.getIndexArrayElementByField(_listCustomer, 'id', _customerNew.id);
                
                if (_index != undefined) {
                    //update the customer at _index position into the customers list
                    _listCustomer[_index] = _customerNew;
                } else {
                    //add the new customer because it wasn't find into customers list
                    _listCustomer.push(_customerNew);
                }

                _this.setCustomerSelected(_customerNew);

                //Add the customer's users list only if it is instantiated.
                //All this, because this method can be called in others situation where it isn't necessary add customer's users list
                if (_customerOld.users && _index) {
                    _listCustomer[_index].users = _customerOld.users;
                }

            }

            // _this.saveCustomer = function (_customer, _success, _error) {
            function _saveCustomer(_customer, _success, _error) {
                    //Check for other customers with same _customer name
                if (!checkExistCustomerWithName(_this.listCustomer, _customer)) {

                    customerServices.saveCustomer(_identity.id, _customer, _res => {
                        
                        updateCustomerInfoAfterActions(_this.listCustomer, _customer, _res);

                        myAlert("Customer saved", 'info');
                            
                        _success?_success(_res):null;  //Call the _success function only if it is defined
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');

                        if (myHttp.analizeBackendError(_err) && _err.data.backendError=="_concurrent_access") {

                            var _confirm = popupConfirm('Concurrent Access Problem', 'Customer has been changed from another user. <br> Do you want reload the data in order to change and save them? Click Yes')
                            $mdDialog.show(_confirm).then(function() {

                                customerServices.getCustomer(_customer.id, _res => {
                                    updateCustomerInfoAfterActions(_this.listCustomer, _customer, _res);

                                    _success?_success(_res, true):null;  //Call the _success function only if it is defined
                                })
                            }, function() {
                            });
        
        
                        } else {                            
                            _error?_error(_err):null;  //Call the _error function only if it is defined
                        }
                    })    
                } else {
                    myAlert("Error: " + "Another Customer with the same name already exists. Use another name.", 'danger');
                }
            }

            _this.getCustomerUsers = function(_customerId) {
                //get the array index about a customer, with id equal to _customerId, from listCustomer
                var _index = utility.getIndexArrayElementByField(_this.listCustomer, 'id', _customerId);
                var _customer = _this.listCustomer[_index];

                if (!_customer.users) {
                    userServices.getCustomerUsers(_customerId, _res => {
                        _customer.users = _res;
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })
                }

            }

            //Set up and open the customer popup
            //usefull to edit/add a customer 
            function editCustomerPopup(_customer) {
                var _fns = [
                    { 'btn_identifier':'_1', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.customer_section == 'company-info'" },
                    { 'btn_identifier':'_2', 'btn_close': true, 'btn_confirm': true, 'btn_validation': 'validationCustomerFromPopup', 'btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'saveCustomerFromPopup', 'btn_visible_if':"$innerStatus.customer_section == 'company-info'" }
                    // { 'btn_identifier':'_3','btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_fn': null, 'btn_visible_if':'$innerStatus.customer_section == "users"'  }
                    // { 'btn_identifier':'_4','btn_msg': 'Add', 'btn_color': 'btn-light', 'btn_fn': 'ciccio', 'btn_visible_if':'$innerStatus.customer_section == "users"'  },
                    // { 'btn_identifier':'_5','btn_msg': 'Save', 'btn_color': 'btn-light', 'btn_fn': 'ciccio', 'btn_visible_if':'$innerStatus.customer_section == "users"'  }
                    
                ];
                var _config = {
                    'size': 'lg',
                    'title': _customer.name?_customer.name:'New Customer',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_customer),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/customer/popup/customer_popup.html',
                    'innerStatus': {'customer_section':'company-info'}
                }
                popup.openModalBody(_config);       
            }

            //This function is used to edit an existent customer or add a new one
            //If any customer with _customerId is found into listCustomer,
            //a popup with all the fields blank will be shown,
            //otherwise a popup with the customer info will be shown
            _this.editCustomer = function (_customerId) {
                
                if (_customerId) { //Edit an existent customer
                    // //get a customer, with id equal to _customerId, from listCustomer
                    _customer = utility.getArrayElementByField(_this.listCustomer, 'id', _customerId);

                    customerServices.getCustomer(_customer.id, _res => {
                        updateCustomerInfoAfterActions(_this.listCustomer, _customer, _res);

                        _addCustomerLogo(_res, _resLogo => {
                            if (_resLogo != 'undefined') {
                                _res.customerLogoPath = _resLogo;
                            }   
                            editCustomerPopup(_res);
                        });
                    })
                } else { //Create a new customer
                    //create an empty customer
                    var _customer = {
                        active: false,
                        type: _customerTypeInspected,
                        inspectingCompanyId: _identity.companyId
                    };

                    editCustomerPopup(_customer);
                }
            }

            _this.checkStatus = function(_customer) {
                var _confirm = popupConfirm('Status has changed.','Would you save the changing?')
                $mdDialog.show(_confirm).then(function() {
                    myLog("Saving the customer status")

                    _saveCustomer(_customer);
                }, function() {
                    _customer.active = !_customer.active;
                    myLog("no I don't want save the status")
                });
    
            }
        }
    ]);