const expanseLiteApp = require('../../../modules/appModule.js');

expanseLiteApp.component('inspLastReportsCmp', {
    templateUrl: 'views/dashboard/inspection-cmp/insp-latest-reports.html',
    controller: DashboardInspLastReportsController,
    controllerAs: 'repsCtrl',
    bindings: {
        reportsPromise: '<'
    }
});

/* @ngInject */
function DashboardInspLastReportsController($scope, Scopes, myLog, myAlert) {
    const _this = this;

    _this.reports = [];

    _this.$onInit = () => {
        _initReports();
    };

    function _initReports() {
        if(!_this.reportsPromise) {
            return;
        }

        _this.reportsPromise.then(reports => {
            _this.reports = reports
                .sort((a,b) => b.createdDate - a.createdDate);
        });
    }
}
