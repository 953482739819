//Write here all the services about AssetData
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("anomalyServices", ['myHttp', 'buildUrl', 'myLog', function(myHttp, buildUrl, myLog) {
        return  {
            //get all the anomaly tags with isFddTag flag false 
            //isFddTag is true if the tag is used with facilty_data_details_tag
            getAnomalyTag: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/anomalyTag/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAnomalyTagDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the anomaly tag about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },

            //get all the anomaly tags area with isFddTag flag false 
            //isFddTag is true if the tag is used with facilty_data_details_tag
            getAnomalyTagArea: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/anomalyTagArea/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAnomalyTagAreaDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the anomaly tag area about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },


        }
    }])

