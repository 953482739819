//Write here all the services about Inspection
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("aiMachineServices", ['myHttp', 'buildUrl', 'myLog', function(myHttp, buildUrl, myLog) {
        return  {
            getAiMachineByTypeAndCompanyIdAndFacilityTypeId: function(_type, _companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/byType/'+_type+'/byCompanyId/'+_companyId+'/byFacilityTypeId/' + _facilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAiMachineDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Ai Machine list about (type: "+_type+", companyId: "+_companyId+", facilityTypeId: "+_facilityTypeId+")";
                    _error(_err);
                } );    
            },
        }
    }])

