//Write here all the services about AssetData
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("inspectionReportServices", ['myHttp', 'buildUrl', 'myLog', function(myHttp, buildUrl, myLog) {
        return  {
            getInspectionReports: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/inspectionReport/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listInspectionReportDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Inspection Reports info about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },
            getInspectionAnswers: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/inspectionReport/inspectionAnswer/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listInspectionAnswerDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Inspection Answers info about the asset data: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },
            getInspectionAnswersPrevious: function(_assetDataId, _inspectionQuestionId, _success, _error) {
                var _url = buildUrl( '/expanse/inspectionReport/inspectionAnswerPrevious/byFacilityDataId/'+_assetDataId+'/byInspectionQuestionId/'+_inspectionQuestionId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listInspectionAnswerDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Inspection Answers previous the Inspection Report: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },
            getInspectionQuestions: function(_success, _error) {
                var _url = buildUrl( '/expanse/inspectionQuestions');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listInspectionQuestionDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Inspection Questions ";
                    _error(_err);
                } );    
            },
            getInspectionQuestionsByReportId: function(_reportId, _success, _error) {
                var _url = buildUrl( '/expanse/inspectionQuestion/byFacilityDataId/' + _reportId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listInspectionQuestionDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Inspection Questions about id: " + _reportId;
                    _error(_err);
                } );    
            },

        }
    }])

