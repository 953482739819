const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('dashboardAssetTypesCmp', {
    templateUrl: 'views/dashboard/inspection-cmp/insp-asset-types.html',
    controller: DashboardAssetTypesController,
    controllerAs: 'assetTypesCtrl',
    bindings: {
        assetTypesPromise: '<'
    }
});

/* @ngInject */
function DashboardAssetTypesController($scope, Scopes, myLog, myAlert, utility) {
    const _this = this;
    _this.isMobile = utility.isFromMobile();

    _this.types = [];

    _this.$onInit = () => {
        _initAssetTypes();
    };

    function _initAssetTypes() {
        if(!_this.assetTypesPromise) {
            return;
        }

        _this.assetTypesPromise.then(types => {
            const sum = types.reduce((sum, type) => sum + type.assetNumber, 0);

            // count percent for each type
            _this.types = types
                .sort((a,b) => b.assetNumber - a.assetNumber)
                .map(type => (Object.assign(type, {
                    percent: Math.round(type.assetNumber / sum * 100)
                })));
        });
    }
}
