//Write in this controller all the code about AssetData
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.controller('assetDataCtrl',
    ['$scope', '$stateParams', 'Scopes', 'myLog', 'myAlert','myThrow', 'assetDataServices', 'assetDataFactory', 'appFactory', 'customerServices', 'assetServices', 'inspectionServices', 
    'structureServices', 'ngTableParams', 'ngTableEventsChannel', 'expanseConst', 'utility', '$sce', 'popup', '$window', '$timeout', '$http', 'myHttp', 'googlemaps','$base64','assetDataByTagsFactory', 'assetDataMethaneFactory',
    'inspectionReportFactory', 'anomalyFactory', 'buildChart', 'componentFactory','tiltAngleFactory', 'aiBuilderFactory',  'azimuthFactory', 'fsValidator','matterportFactory', 'featureService', '$cacheFactory','iframeComunication',    
    'spinModelServices', 'autodeskServices', 'inventoryServices', 'inventoryFactory', 'catalogueFactory', 'productServices', 'popupConfirm', '$mdDialog', 'checklistFactory', 'image360Factory', 'rolesService', 'dynamicCADFactory',
    'compassViewerFactory', 'htmlEvents',  'projectServices', 
        function ( $scope, $stateParams, Scopes, myLog, myAlert, myThrow, assetDataServices, assetDataFactory, appFactory, customerServices, assetServices, inspectionServices, 
            structureServices, ngTableParams, ngTableEventsChannel, expanseConst, utility, $sce, popup, $window, $timeout, $http, myHttp, googlemaps,$base64,assetDataByTagsFactory, assetDataMethaneFactory,
            inspectionReportFactory, anomalyFactory, buildChart, componentFactory, tiltAngleFactory, aiBuilderFactory, azimuthFactory, fsValidator, matterportFactory, featureService, $cacheFactory, iframeComunication,
            spinModelServices, autodeskServices, inventoryServices, inventoryFactory, catalogueFactory, productServices, popupConfirm, $mdDialog, checklistFactory, image360Factory, rolesService, dynamicCADFactory,
            compassViewerFactory, htmlEvents, projectServices) {


            //Get the information about the user logged
            var _identity = JSON.parse(localStorage.getItem('exlite.identity'));
            var _this = this;

            //----------------------------------------------------------------------------- private area Begin ------------------------------------------------------------------------------------------
            var _controllerName = "assetDataCtrl";
            var _customerId;
            var _assetId;
            var _structureId;
            var _customer;
            var _asset;
            var _listAssetStructure = [];
            var _sourceTypeKey;
            var _description;
            var _maps = [];                                     //array contains all the google maps created
            var _listCompassViewerMarker = [];
            var _listThermalImageryMarker = [];
            var _noTag = {
                key: 'none',
                value: 'Image Without Tag',
                id: 0
            }
            
            // import entire SDK

            //----------------------------------------------------------------------------- private area End ------------------------------------------------------------------------------------------

            //----------------------------------------------------------------------------- _this area Begin ------------------------------------------------------------------------------------------
            _this.identity = _identity;
            _this.expanseConst = expanseConst;              //Expose expanse's consts service
            _this.listAssetData = [];
            _this.listAssetDataGroupedByFiletype = [];
            _this.listAssetDataFileType = [];
            _this.listAssetDataFileTypeByKey = [];
            _this.zoomActive = false;
            _this.imgIndex = 0;
            _this.imgIndex2 = 0;
            _this.nextPageNum = 1;
            _this.views = {
                singleImage: 'singleImage',
                multiImage: 'multiImage',
                singlePhotogrammetry: 'singlePhotogrammetry',
                doublePhotogrammetry: 'doublePhotogrammetry',
                compassViewer: 'compassViewer',
                thermalImage: 'thermalImage',
                doubleImage: 'doubleImage',
                assetMapper: 'assetMapper',
                reviewer3d: 'reviewer',
                cityscale3d: 'cityscale',
                assetDataByTags: 'assetDataByTags',
                assetDataMethane: 'assetDataMethane',
                inspectionReport: 'inspectionReport',
                image360: 'image360',
                image360SplitScreen: 'image360SplitScreen',
                matterport: 'matterport',
                naviswork: 'naviswork',
                kml: 'kml',
                azimuth: 'azimuth',
                dwg: 'dwg',
                editor2DCAD: 'editor2DCAD',
                truView: 'truView',
                userNet3D: 'userNet3D',
                spinModel: 'spinModel',
                nokiaReport: 'nokiaReport',
                autodeskViewer: 'autodeskViewer',
                inventory: 'inventory',
                inventoryFast: 'inventoryFast',
                checklist: 'checklist',
                riskManagement: 'riskManagement',
                orthomosaic: 'orthomosaic',
                dynamicCad: 'dynamic-cad',
                aifilter: 'aifilter',
                aibuilder: 'aibuilder',
                aiassistedlabel: 'aiassistedlabel',
                // display360DegreesSplitScreen: 'display360DegreesSplitScreen',
            };
            _this.viewUsedByAssetDataType = {
                singleImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared','orthomosaic'],
                multiImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared','orthomosaic'],
                singlePhotogrammetry: ['3d_photogrammetry'],
                doublePhotogrammetry: ['3d_photogrammetry'],
                compassViewer: ['static_imagery', 'thermal_imagery'],
                thermalImage: ['thermal_imagery'],
                doubleImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared','orthomosaic'],
                assetMapper: [],
                reviewer3d: [],
                cityscale3d: [],
                assetDataByTags: ['static_imagery'],
                assetDataMethane: ['methane_doc'],
                inspectionReport: ['inspection_report'],
                kml: ['kml','kmz'],
                kmz: ['kmz','kml'],
                autodeskRevit: ['autodesk-revit'],
                dwg: ['dwg'],
                matterport: ['matterport'],
                spinModel: ['spin-model'],
                checklist: ['checklist'],
                image360: ['360-image'],
                image360SplitScreen: ['360-image'],
                orthomosaic: ['orthomosaic']
            };


            _this.assetDataListViews = {
                list: 'list',
                group: 'group',
                project: 'project',
            };
            _this.assetDataTypes = {
                imagery: 'static_imagery',
                thermal: 'thermal_imagery',
                video: 'video',
                photogrammetry: '3d_photogrammetry',
                document: 'documents',
                report: 'report',
                methaneDoc: 'methane_doc',
                inspectionReport: 'inspection_report',
                image360: '360-image',
                audio: 'audio',
                infrared: 'infrared',
                kml: 'kml',
                kmz: 'kmz',
                dwg: 'dwg',
                matterport: 'matterport',
                spinModel: 'spin-model',
                lidar: 'lidar',
                autodeskRevit: 'autodesk-revit',
                checklist: 'checklist',
                orthomosaic: 'orthomosaic'
            }
            _this.assetDataTypesKeys = [
                {'key': 'imagery', 'value': 'static_imagery'},
                {'key': 'thermal', 'value': 'thermal_imagery'},
                {'key': 'video', 'value': 'video'},
                {'key': 'photogrammetry', 'value': '3d_photogrammetry'},
                {'key': 'document', 'value': 'documents'},
                {'key': 'report', 'value': 'report'},
                {'key': 'methaneDoc', 'value': 'methane_doc'},
                {'key': 'inspectionReport', 'value': 'inspection_report'},
                {'key': 'image360', 'value': '360-image'},
                {'key': 'audio', 'value': 'audio'},
                {'key': 'infrared', 'value': 'infrared'},
                {'key': 'kml', 'value': 'kml'},
                {'key': 'kmz', 'value': 'kmz'},
                {'key': 'dwg', 'value': 'dwg'},
                {'key': 'matterport', 'value': 'matterport'},
                {'key': 'spinModel', 'value': 'spin-model'},
                {'key': 'lidar', 'value':'lidar'},
                {'key': 'autodeskRevit', 'value': 'autodesk-revit'},
                {'key': 'checklist', 'value': 'checklist'}
            ]


            _this.assetDataSaveTypes = {
                generated: 'generated',
                uploaded: 'uploaded'
            }

            _this.toolName = {
                photogrammetry: 'photogrammetry',
                report: 'report',
                compassViewer: 'compassViewer',
                azimuth: 'azimuth',
                spinModel: 'spinModel',
                ocr: 'ocr',
                barQrcodeReader: 'barcode-qrcode-reader',
                split: 'split',
                tiltAngle: 'tiltAngle',
                fingerprinting: 'fingerprinting',
                cableLabelDetection: 'cableLabelDetection',
                humanEye: 'humanEye',
                objTracking: 'objTrackcing',
                exportAsset: 'exportAsset',
                exportAssetAnalyzed: 'exportAssetAnalyzed',
                exportAssetDropbox: 'exportAssetDropbox',
                exportInventory: 'exportInventory',
                exportPredictedStatistics: 'exportPredictedStatistics',
                inventory: 'inventory',
                inventoryFast: 'inventoryFast',
                labelAnalysis: 'labelAnalysis',
                autoInventoryB: 'autoInventoryB',
                orthomosaic: 'orthomosaic',
                iscChecklist: 'iscChecklist',
                aiFilter: 'aiFilter',
                aiBuilder: 'aiBuilder',
            }

            _this.callbackByAssetDataTypes = {
                '360-image': '_this.image360ShowFirstImage()'
            }

            _this.assetType = {
                estate: 24
            }

            _this.viewShown = undefined;
            _this.oldViewShown;
            _this.assetDataListViewShown = _this.assetDataListViews.list;
            _this.listAssetDataSelectd = [];
            _this.listAssetDataChecked = [];
            _this.listAssetDataDetails = [];
            _this.listAssetDataDetailsNotFiltered = [];
            _this.listTagsIntoAssetDataDetails = [];            
            _this.listTagsAnomalyLevel1 = [
                {corrosion: [
                    {
                        isSelected: true,
                        keys: ['corrosion_none'],
                        value: 'Image Without Corrosion'
                    },
                    {
                        isSelected: true,
                        keys: ['corrosion_1', 'corrosion_2'],
                        value: 'Low'
                    },
                    {
                        isSelected: true,
                        keys: ['corrosion_3', 'corrosion_4'],
                        value: 'Medium'
                    },
                    {
                        isSelected: true,
                        keys: ['corrosion_5'],
                        value: 'High'
                    },
                ]}
            ];
            _this.listTagsAnomalyLevel2 = [
                {
                    key: 'corrosion-number',
                    value: 'Number of Corrosion Spots',
                    sortType: 'desc'
                },
                {
                    key: 'corrosion-area',
                    value: 'Area of Corrosion Spots',
                    sortType: 'desc'
                },
                {
                    key: 'custom',
                    value: 'Custom Priority',
                    sortType: 'desc'
                },
            ]
            _this.tagAnomalyLevel2 = undefined;
            _this.tagAnomalyLevel2Info = {
                key: _this.tagAnomalyLevel2,
                sortType: 'desc',
                minMax: 'max'
            }
            _this.listTagsComponentLevel3 = [
                {
                    groupName: '',
                    showGroupName: false,
                    filters: [
                        {
                            isSelected: true,
                            key: 'no-filter3',
                            value: 'Image Without Tag'    
                        },
                    ]
                },
                {
                    groupName: '',
                    showGroupName: false,
                    filters: [
                        {
                            isSelected: true,
                            key: 'exposure',
                            value: 'Exposure'    
                        },
                    ]
                }, 
                {
                    groupName: 'Structure',
                    showGroupName: true,
                    filters: [
                        {
                            isSelected: true,
                            key: 'bolt',
                            value: 'Bolt'    
                        },
                        {
                            isSelected: true,
                            key: 'stairs',
                            value: 'Stairs'    
                        },
                        {
                            isSelected: true,
                            key: 'ladder',
                            value: 'Ladder'    
                        },
                        {
                            isSelected: true,
                            key: 'docking_grated',
                            value: 'Docking Grated'    
                        },
                        {
                            isSelected: true,
                            key: 'docking_ungrated',
                            value: 'Docking Ungrated'    
                        },
                        {
                            isSelected: true,
                            key: 'handrails',
                            value: 'Handrails'    
                        },
                    ]
                },
                {
                    groupName: 'Pressurized',
                    showGroupName: true,
                    filters: [
                        {
                            isSelected: true,
                            key: 'flanges',
                            value: 'Flange'    
                        },
                        {
                            isSelected: true,
                            key: 'storage-tank',
                            value: 'Tank'    
                        },
                        {
                            isSelected: true,
                            key: 'valves',
                            value: 'Valves'    
                        },
                    ]
                },
            ];

            _this.listThermalImage = [];
            _this.sce = $sce;
            _this.isAllAssetDataDetailsSelected;
            _this.compassViewerCanvasId = '_compassViewerCanvas';           //compass-viewer html canvas id
            _this.thermalImagesCanvasId = '_thermalImagesCanvas';
            _this.assetDataByTagsCanvasId = '_byTagsCanvas';
            _this.assetDataMethaneCanvasId = '_methaneCanvas';
            _this.inspectionReportCanvasId = '_inspectionReportCanvas';
            _this.inspectionReportDetailMapId = '_inspectionReportDetailMap';
            _this.spinModelCanvasId = '_spinModelCanvas';
            _this.image360CanvasId = '_image360Canvas';
            _this.geoPosition = [];
            _this.isshownSplit = false;                                     //Say if "split" screen is shown or not
            _this.isCompassViewerSelected = false;
            _this.isshownAssetDataByTags = false;
            _this.isshownAssetDataMethane = false;
            _this.model_dropdown = []
            _this.tiltAngleTriggered = false;
            _this.aiBuilderTriggered = false;
            _this.AiToolButtons = [];

            _this.listAssetStatusPhase = [];

            _this.currentCompoFddTags = [];
            _this.currentAnoFddTags = []

            _this.listLeakTags = ['leak-green', 'leak-yellow', 'leak-red'];

            _this.utilityRef = utility;

            var _status_labels = '<div style="display: inline-flex"> <div class="asset-status {{option.key}}" ></div>';
            var _template = _status_labels + ' <div><b>{{option.value}}</b></div></div>';

            _this.dropDownSettingsAssetStatus = {
                template: _template, showCheckAll: false, showUncheckAll: false,
                smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
            };

            _this.autoAllocation = {
                value: false
            };
            _this.v6Analysis = {
                value: false
            };

            _this.viewFromAssetDataTool = _viewFromAssetDataTool();
            _this.keyListenerToArrowsInSingleImageScreen = _keyListenerToArrowsInSingleImageScreen();

            //function used into popup
            _this.isFeatureEnabled = function() {
                return function(_featureKey) {

                    console.log(_featureKey, featureService.isEnabled(_featureKey))
                    return featureService.isEnabled(_featureKey);
                } 
            }

            _this.levelsType = appFactory.getSpinModelLevelsType();
            _this.levelsTypeByKey = utility.getArrayByField(appFactory.getSpinModelLevelsType(), 'type');

            _this.humanEyeViz = false;
            _this.humanEyeList;

            _this.labelViz = false;
            _this.labelList;

            _this.tagSelected =  [];
            _this.selectionObject = [];
            _this.listColors = ["#DC143C","#00008B","#7FFF00","#8A2BE2","#7FFFD4","#FF8C00",'#FFD700','#F8F8FF','#E6E6FA','#FFFACD', '#F08080', '#90EE90','#FFA07A','#FF4500', '#6B8E23','#000080', '#A0522D','#C0C0C0', '#87CEEB','#FFFF00' ]
            _this._newTagsTmp = [];
            _this.filterViz = 'Single'

            _this.spinModelWithCompass = false;
            //----------------------------------------------------------------------------- _this area End ------------------------------------------------------------------------------------------

            //----------------------------------------------------------------------------- $scope area Begin ---------------------------------------------------------------------------------------
            $scope.thermalImageryThreshold_u = 50;
            $scope.thermalImageryThreshold_l = 50;

            $scope.slider = {
                min: 0,
                max: 100,
                options: {
                    floor: 0,
                    ceil: 100,
                    step: 1
                }
            };

            $scope.methaneMinValue;
            $scope.methaneMaxValue;

            $scope.methaneImageryThreshold_u = undefined;
            $scope.methaneImageryThreshold_l = undefined;

            $scope.slider_methane = {
                min: $scope.methaneImageryThreshold_l,
                max: $scope.methaneImageryThreshold_u,
                options: {
                    floor: 0,
                    ceil: 150,
                    step: 1
                }
            };

            $scope.methaneAnomalyViz = {
                cb1: false,
                cb2: true
              };

            $scope.listFeatureKey = appFactory.getListFeatureKey();


            //function used into html and this controller
            $scope.isFeatureEnabled = function(_featureKey) {
                // console.log(_featureKey, featureService.isEnabled(_featureKey))
                return featureService.isEnabled(_featureKey);
            }

            $scope.dropDownSettingsInventoryItemExtraInfoLabel = {
                styleActive: true, template: '<b>{{option.value}}</b>', scrollableHeight: '200px', scrollable: true,
                smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.key; }
            };

            var _project_status_labels = '<div style="display: inline-flex"> <div class="project-status {{option.key}}" ></div>';
            var _project_template = _project_status_labels + ' <div><b>{{option.value}}</b></div></div>';
            $scope.projectDropDownSettings = {
                template: _project_template, showCheckAll: false, showUncheckAll: false,
                smartButtonMaxItems: 1, selectionLimit: 1, closeOnSelect: true, smartButtonTextConverter: function (itemText, originalItem) { return originalItem.value; }
            };


            $scope.listRolesTypeKey = appFactory.getListRolesTypeKey();

            $scope.hasRole = function(_rolesTypeKey) {
                return rolesService.hasRole(_rolesTypeKey);
            }
    
            //----------------------------------------------------------------------------- $scope area End ---------------------------------------------------------------------------------------

            _this.getAllCustomers = function(_success) {
                if (_identity.isInspector) {
                    customerServices.getAllCustomers(_identity.companyId, _identity.id, _res => {
                        _success(_res);
                    })    
                } else {
                    customerServices.getCustomer(_identity.companyId, _res => {
                        _success(_res);
                    })    
                }
            }            


            //Write here the code that you need when the controller is created
            //The $scope will be put and get from store.
            //In this way it will be possible to cache some information
            //and it will be possible to use some function in others controllers
            this.init = function (_isIFrame) {                
                var _ctrl = Scopes.get(_controllerName); 

                if (_ctrl) {
                    $scope.assetMapperMessageRef = _ctrl.assetMapperMessageRef;
                }

                Scopes.store(_controllerName, $scope);

                if (!_isIFrame) {
                    if ($stateParams.customer_id && $stateParams.asset_id) {
                        _customerId = $stateParams.customer_id;
                        _assetId = $stateParams.asset_id;
    
                        utility.callFunctionEveryTime("$('#header').get(0)", 200, function() {
                            Scopes.get('headerCtrl').customer_id = _customerId;
                            Scopes.get('headerCtrl').asset_id = _assetId;    
                        })
                    }
    
                    _this.getAllCustomers(_resA => {
                        if ((_identity.isInspector && _resA.filter(e => e.id == _customerId).length>0) || 
                            (!_identity.isInspector && _resA.id==_customerId)) {

                            customerServices.getCustomer(_customerId, _res => {
                                _customer = _res;
                                _this.customer = _res;
    
                                _this.getAssetsAssetData(_assetId);
                            }, _err => {
                                myAlert("Error: " + _err.data.message, 'danger');
                                myThrow(_err);    
                            })    
                        } else {
                            myAlert("Error: Customer required doesn't exist", 'danger');
                        }
                    });    
    
                    
                    assetServices.getAsset(_assetId, _res => {
                        _asset = _res;
                        _this.asset = _res;
    
                        _this.assetTypeId = _asset.facilityTypeId;
    
                            $scope.thermalImageryThreshold_l = _asset.temperatureThresholdLower?_asset.temperatureThresholdLower:0;
                            $scope.thermalImageryThreshold_u = _asset.temperatureThreshold?_asset.temperatureThreshold:100;
        
                            $scope.slider.min = $scope.thermalImageryThreshold_l;
                            $scope.slider.max = $scope.thermalImageryThreshold_u;
                            $scope.slider.options.onChange = function() {
                                _this.updateThermalImageryIconMarker();
                            }

                            $scope.methaneImageryThreshold_l = _asset.methaneThresholdLower?_asset.methaneThresholdLower:0;  
                            $scope.methaneImageryThreshold_u = _asset.methaneThreshold?_asset.methaneThreshold:100;
    
                            $scope.slider_methane.min = $scope.methaneImageryThreshold_l;
                            $scope.slider_methane.max = $scope.methaneImageryThreshold_u; 
                            $scope.slider_methane.options.onChange = function() {
                                _this.updateThermalImageryIconMarker();
                            }
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })
    
                    assetServices.getAssetStructures(_assetId, _res => {
                        _listAssetStructure = _res;
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })


                    // _this.getAssetsAssetData(_assetId);

                    assetServices.getAssetTypes(_res => {
                        _this.listAssetTypeKey = utility.getArrayByField(_res, 'id');
                    })
    
                }



                assetDataServices.getAssetDataDetailsTagTypes(_res => {
                    //add a fake id to make easier to sort out the list
                    _res.forEach(e => e.id = 1)
                    _this.listAssetDataDetailsTagTypes = _res;

                    _this.listAssetDataDetailsTagTypes.push({id:_noTag.id, key:_noTag.key, value:_noTag.value})

                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);    
                })


                _getAssetDataFileTypes(_res => {
                    _this.listAssetDataFileType = _res;
                    _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');

                });

                assetServices.getFacilityStatusPhase(_res => {
                    _this.listAssetStatusPhase = _res.listFacilityStatusPhaseDto;
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });

                

                assetServices.getAssetTypes(_res => {
                    _this.listAssetTypeByKey = utility.getArrayByField(_res, 'id');
    
                }, _err => {
                    _error ? _error(_err) : null;
                    myAlert("Error: " + _err.data.message, 'danger');
                });

                projectServices.getProjectTypes(_res => {
                    _this.listProjectTypeByKey = utility.getArrayByField(_res, 'key');
                })

                projectServices.getProjectStatusTypes(_res => {
                    _this.listProjectStatusType = _res;
                    _this.listProjectStatusTypeByKey = utility.getArrayByField(_res, 'key');
                })

            }

            _this.getAsset = function () {
                return _asset;
            }


            _this.isAssetDataToolEnabled = function(_tool) {
                var _ret = false;
                if (_tool == _this.toolName.photogrammetry) {
                    if (_this.listAssetDataDetails.length > 5 && _this.listAssetDataSelectd.length == 1 &&
                        _this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        _ret = true;
                    }
                } else if (_tool == _this.toolName.report) {
                    if (_this.viewShown==_this.views.multiImage && _identity.isInspector &&
                        _this.listAssetDataDetails.filter(e => e.isSelected).length > 0) {
                        _ret = true;
                    }
                } else if (_tool == _this.toolName.compassViewer) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.azimuth) {
                    if (_this.assetDataDetailsSelected && _this.assetDataDetailsSelected.id && _this.listAssetDataSelectd.length>0 &&
                        _this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.spinModel) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.ocr) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.barQrcodeReader) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.nokiaReport) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length == 2 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.split) {
                    if (_this.listAssetDataSelectd.filter(e => {
                            return e.fileType==_this.assetDataTypes.imagery || e.fileType==_this.assetDataTypes.thermal ||  e.fileType==_this.assetDataTypes.video || e.fileType==_this.assetDataTypes.audio
                                || e.fileType==_this.assetDataTypes.image360
                        }).length >= 1 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.tiltAngle) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 && _this.asset.facilityTypeId == 9 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.fingerprinting) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.cableLabelDetection) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.humanEye) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.objTracking) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                
                } else if (_tool == _this.toolName.exportAsset) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    } 
                } else if (_tool == _this.toolName.exportAssetAnalyzed) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    } 
                } else if (_tool == _this.toolName.exportAssetDropbox) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    } 
                } else if (_tool == _this.toolName.exportInventory) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.exportPredictedStatistics) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.labelAnalysis) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.autoInventoryB) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.aiFilter) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.aiBuilder) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery}).length>0 ) {
                        return true;
                    }
                } else if (_tool == _this.toolName.inventory) {
                        if (_this.listAssetDataSelectd.filter(e => {return (e.fileType==_this.assetDataTypes.imagery || e.fileType == _this.assetDataTypes.spinModel)}).length>0 ) {
                            return true;
                    }
                } else if (_tool == _this.toolName.orthomosaic) {
                    if (_this.listAssetDataSelectd.filter(e => {return e.fileType==_this.assetDataTypes.imagery  || e.fileType == _this.assetDataTypes.thermal}).length>0 ) {
                        return true;
                    }
                }
                

                return _ret;
            }

            function _viewFromAssetDataTool() {
                var _prevView = undefined;
                var _prevViewValue = false;
                var _viewByAssetData = undefined;

                function _taskToDoAfterViewChanged(_viewShown) {
                    if (_viewShown == _this.views.doubleImage && _this.listAssetDataDetails.length>0) {
                        _this.showImg(_this.listAssetDataDetails[_this.imgIndex2], 2)
                    } else if (_viewShown == _this.views.singleImage && _this.listAssetDataDetails.length>0) {
                        _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
                    }
                }

        
                return {
                    reset: function() {
                        _prevView = undefined;
                        _prevViewValue = false;
                        _viewByAssetData = undefined;        
                    },
                    //Use this function to set the view to show
                    setViewByAssetData: function(_view) {
                        _viewByAssetData = _view
                        return _view;
                    },
                    //Use this function to change the view based on dataset's type,
                    //in one into the "views" and "tools" lists
                    //When the user close the feature selected from "views"/"tools" lists
                    //it'll go back the the previeous view (the one based on the dataset's type)
                    changeTo: function(_view) {
                        var _viewShown = undefined;
                        _this.wheelZoomRef = undefined;

                        if (!_prevView) {
                            _viewByAssetData = _this.viewShown
                        }
                        if (_view == _prevView) {
                            _prevViewValue = !_prevViewValue
                            _prevView = undefined;
                        } else {
                            _prevView = _view;
                            _prevViewValue = true;
                        }
                        
                        if (_prevView) {
                            _viewShown = _prevView;
                        } else {
                            _viewShown = _viewByAssetData;
                        }

                        _this.viewShown = _viewShown

                        _taskToDoAfterViewChanged(_viewShown);

                        return _viewShown;
                    }
                }
            }


            function _getAssetDataFileTypes(_success, _error) {
                var _array = [];

                assetDataServices.getAssetDataFileTypes(_res => {
                    _success ? _success(_res) : null;

                    myLog("Asset data file types list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }

            function _getAssetDataGroupedByFiletype(_listAssetData) {
                var _listGrouped = [];

                _listAssetData.forEach(e => {
                    var _index = utility.getIndexArrayElementByField(_listGrouped, 'fileType', e.fileType);

                    if (_index != undefined) {
                        if (_listGrouped[_index].createdAt<e.createdAt) {
                            _listGrouped[_index].createdAt = e.createdAt; //if exist, update the createdAt date
                        }
                    } else  {
                        //if not exist, create a new element into _listGrouped
                        _listGrouped.push({
                            fileType: e.fileType,
                            createdAt: e.createdAt,
                            listAssetData: []
                        })
                    }
                })

                //get for each asset-data fileType, the list of asset-data
                _listGrouped.forEach(e => {
                    e.listAssetData = _listAssetData.filter(a => a.fileType == e.fileType);
                })

                return _listGrouped;
            }

            _this.saveProjectFacilityStatus = function(_projectFacility, _newStatusKey) {
                _projectFacility.statusKey = _newStatusKey;
                projectServices.saveProjectFacility(_identity.id, _projectFacility, _res => {
                    myAlert("Asset Project's status updated", 'info');
                    _projectFacility = _res;
                }) 
            }

            function _getAssetDataGroupedByProject(_listAssetData, _assetId, _success) {
                var _listGrouped = [];

                projectServices.getProjectsByFacilityId(_assetId, _res => {
                    _listGrouped = _res;

                    projectServices.getProjectFacilitiesByFacilityId(_assetId, _res1 => {
                        var _listProjectFacility = _res1;

                        var _groupWithoutProject ={
                            id:-1,
                            name: "Datasets Without Project",
                            begin:"",
                            end:"",
                            listAssetData: [],
                        };
    
                        _listGrouped.forEach(g => {
                            g.listAssetData=[]
                            console.log("group....", g.begin, g.end);
                        });
    
                        _listAssetData.forEach(e => {
                            var _createdAt = utility.formatDate(e.createdAt, 'yyyymmdd')
                            // var _group = _listGrouped.find(g => g.begin<=_createdAt && _createdAt<=g.end);
                            var _group = _listGrouped.find(g => {
                                var _begin = utility.formatDate(g.begin, 'yyyymmdd')
                                var _end = utility.formatDate(g.end, 'yyyymmdd')
    
                                return _begin<=_createdAt && _createdAt<=_end
                            });
                            if (_group) {
                                _group.listAssetData.push(e);
                            } else {
                                _groupWithoutProject.listAssetData.push(e);
                            }
                        })
    
                        var _listTmp = _listAssetData.filter(e => _groupWithoutProject.listAssetData.find(g => g.id==e.id));
                        var _list = [];
                        _listTmp.forEach((e, index) => {
                            if (_listGrouped.length>0) {
                                var _g = _listGrouped[0];
                                var _createdAt = utility.formatDate(e.createdAt, 'yyyymmdd')
                                var _end = utility.formatDate(_g.end, 'yyyymmdd')
    
                                if (_createdAt<_end) {
                                    var _projectFacility = _listProjectFacility.find(pf => pf.projectId==_g.id);

                                    var _item = {
                                        isProject: true,
                                        group: _g,
                                        showAssetData: true,
                                        projectFacility: _projectFacility,
                                        projectStatusSelected: [{key: _projectFacility.statusKey}]
                                    }
                                    _listGrouped.splice(0,1)
                                    _list.push(_item);    
                                } 
                                _list.push(e);
                            } else {
                                _list.push(e);  
                            }
                        })
    
                        if (_listGrouped.length>0) {
                            _listGrouped.forEach(g => {
                                var _projectFacility = _listProjectFacility.find(pf => pf.projectId==g.id);

                                var _item = {
                                    isProject: true,
                                    group: g,
                                    showAssetData: true,
                                    projectFacility: _projectFacility,
                                    projectStatusSelected: [{key: _projectFacility.statusKey}]
                                }
                                _list.push(_item);    
                            })
                        }
                        _success(_list);                        
                    });
                })
            }


            _this.getAssetsAssetData = function (_assetId, _success) {
                //check first if the asset_id in the url belongs to the customer's asset
                assetServices.getCustomerAssets(_identity.id, false, _customer.id, _resA => {
                    if (_resA.filter(e => e.id == $stateParams.asset_id).length>0) {
                        assetDataServices.getAssetsAssetData(_assetId, _identity.isInspector, _res => {
                            _this.listAssetData = _res;
        
                            _this.listAssetDataGroupedByFiletype = _getAssetDataGroupedByFiletype(_this.listAssetData);

                            _getAssetDataGroupedByProject(_this.listAssetData, _assetId, _res => {
                                _this.assetsAssetDataGroupedByProject = _res;
                            })                               

                            if (_this.assetDataListViewShown == _this.assetDataListViews.list) {
                                _this.assetsAssetDataTableParams = ngTableParams.withConfig({ count: 25 }, _this.listAssetData);
                            } else if (_this.assetDataListViewShown == _this.assetDataListViews.group) {
                                _this.assetsAssetDataGroupedTableParams = ngTableParams.withConfig({ count: 25 }, _this.listAssetDataGroupedByFiletype);    
                            } else if (_this.assetDataListViewShown == _this.assetDataListViews.project) {
                                _this.assetsAssetDataGroupedByProjectTableParams = ngTableParams.withConfig({ count: 25 }, _this.assetsAssetDataGroupedByProject);    
                            }
            
        
                            myLog("AssetData list:", _res);                
                            
                            _success?_success(_res):null;
                        }, _err => {
        
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);
                        });    
                    } else {
                        myAlert("Error: Asset required doesn't exist", 'danger');
                    }
                })
            }

            _this.getAssetsAssetDataTableParams = function () {
                _this.assetsAssetDataTableParams = ngTableParams.withConfig();

                _this.assetsAssetDataGroupedTableParams = ngTableParams.withConfig();

                _this.assetsAssetDataGroupedByProjectTableParams = ngTableParams.withConfig();
            }

            function _getThermalImages(_assetDataId, _success, _error) {
                assetDataServices.getThermalImages(_assetDataId, _res => {
                    _success(_res);

                    myLog("ThermalImages list:", _res);
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }


            _this.retrieveTags = function(_assetDataDetailsID){

                assetDataServices.retrieveTagAreas(_assetDataDetailsID, _res => {
                    _res = JSON.parse(_res)
                    _this.currentCompoFddTagsAIFilter = _res['listCompoTags'];
                    _this.currentCompoFddTagsAIFilter = _this.currentCompoFddTagsAIFilter.filter(element => element.type_usage != 'training');

                    _this.currentAnoFddTags = _res['listAnoTags'];
                    _this.currentAnoFddTags = _this.currentAnoFddTags.filter(element => element.type_usage != 'training');
                    
                    _this.drawBoundingBoxes();

                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }



            _this.showImg = function (_assetDataDetails, _indexNumber) {

                try {
                    tiltAngleFactory.clearScreen('_img_selected', 'canvas', _this.tiltAngleTriggered);                    
                } catch (error) {                
                }
                _this.tiltAngleTriggered = false;
                _this.image_angle =
                _this.zoomActive = true;
                
                _this.retrieveTags(_assetDataDetails.id)


                if (_assetDataDetails) {
                    _this.isFullscreen = false;

                    assetDataServices.retrieveTagAreas(_assetDataDetails.id, _res => {
                        _res = JSON.parse(_res)
                        _this.currentCompoFddTagsAIFilter = _res['listCompoTags'];
                        _this.currentCompoFddTagsAIFilter = _this.currentCompoFddTagsAIFilter.filter(element => element.type_usage != 'training');

                        _this.currentAnoFddTags = _res['listAnoTags'];
                        _this.currentAnoFddTags = _this.currentAnoFddTags.filter(element => element.type_usage != 'training');

                        _this.drawBoundingBoxes();

    
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    });
    
                    if (!_indexNumber || _indexNumber == 1) {
                        _this.listAssetDataDetails[_this.imgIndex].isThumbSelected = false;
                        _this.assetDataDetailsSelected = _assetDataDetails;
                        _this.imgIndex = utility.getIndexArrayElementByField(_this.listAssetDataDetails, 'id', _assetDataDetails.id);
                        _this.listAssetDataDetails[_this.imgIndex].isThumbSelected = true;
                    } else {
                        _this.listAssetDataDetails[_this.imgIndex2].isThumb2Selected = false;
                        _this.assetDataDetailsSelected2 = _assetDataDetails;
                        _this.imgIndex2 = utility.getIndexArrayElementByField(_this.listAssetDataDetails, 'id', _assetDataDetails.id);
                        _this.listAssetDataDetails[_this.imgIndex2].isThumb2Selected = true;
                    }

                    if (!_this.wheelZoomRef) {
                        var _idImg = undefined;
                        var _idImg2 = undefined;

                        if (_this.viewShown == _this.views.singleImage || _this.viewShown == _this.views.inventory || _this.viewShown == _this.views.aifilter || _this.viewShown == _this.views.aibuilder || _this.viewShown == _this.views.aiassistedlabel) {
                            _idImg = '_img_selected'
                        } else if (_this.viewShown == _this.views.doubleImage) {
                            _idImg = '_split_img_selected'
                            _idImg2 = '_split_img_selected2'
                        }

                        utility.callFunctionEveryTime('$("#'+_idImg+'").get(0)', 200, function() {
                            _this.wheelZoomRef = wheelzoom(document.getElementById(_idImg));

            
                        })

                        if (_idImg2) {
                            utility.callFunctionEveryTime('$("#'+_idImg2+'").get(0)', 200, function() {
                                wheelzoom(document.getElementById(_idImg2));
                            })    
                        }
                    }
    
                    if (_assetDataDetails.dataType == _this.assetDataTypes.video) {
                        if (!_indexNumber || _indexNumber == 1) {
                            $('#_video_selected').load();
                        } else {
                            $('#_video_selected2').load();
                        }                    
                    }    

                    try {
                        if(!$scope.$$phase) {
                            $scope.$apply()
                        }
                    } catch(erroe) {}
                }
                var _data = {
                    'FacilityDataDetails': _assetDataDetails
                }

                if (_this.viewShown == _this.views.aibuilder){
                    _this.loadAIBuilderBoxes()
                }

                // if (_this.viewShown == _this.views.aifilter) {
                //     utility.callFunctionEveryTime('$("#_img_selected").get(0)', 200, function() {
                //         _this.drawBoundingBoxesListener();
                //     })
                // }

            }

            $scope.goFullscreen = function () {

                if (Fullscreen.isEnabled())
                    Fullscreen.cancel();
                else
                    Fullscreen.all();
            }

            _this.previousImg = function (_listAssetDataDetails, _indexNumber) {
                if (!_indexNumber || _indexNumber == 1) {
                    _listAssetDataDetails[_this.imgIndex].isThumbSelected = false;
                    _this.imgIndex -= 1;

                    if (_this.imgIndex < 0) {
                        _this.imgIndex = _listAssetDataDetails.length - 1;
                    }
                    
                    _listAssetDataDetails[_this.imgIndex].isThumbSelected = true;
                    _this.showImg(_listAssetDataDetails[_this.imgIndex])    
                } else {
                    _listAssetDataDetails[_this.imgIndex2].isThumb2Selected = false;
                    _this.imgIndex2 -= 1;

                    if (_this.imgIndex2 < 0) {
                        _this.imgIndex2 = _listAssetDataDetails.length - 1;
                    }
    
                    _listAssetDataDetails[_this.imgIndex2].isThumb2Selected = true;
                    _this.showImg(_listAssetDataDetails[_this.imgIndex2], 2)    
                }
            }

            _this.nextImg = function (_listAssetDataDetails, _indexNumber) {
                if (!_indexNumber || _indexNumber == 1) {
                    _listAssetDataDetails[_this.imgIndex].isThumbSelected = false;
                    _this.imgIndex += 1;

                    if (_this.imgIndex > _listAssetDataDetails.length - 1) {
                        _this.imgIndex = 0;
                    }

                    _listAssetDataDetails[_this.imgIndex].isThumbSelected = true;
                    _this.showImg(_listAssetDataDetails[_this.imgIndex])
                } else {
                    _listAssetDataDetails[_this.imgIndex2].isThumb2Selected = false;
                    _this.imgIndex2 += 1;

                    if (_this.imgIndex2 > _listAssetDataDetails.length - 1) {
                        _this.imgIndex2 = 0;
                    }

                    _listAssetDataDetails[_this.imgIndex2].isThumb2Selected = true;
                    _this.showImg(_listAssetDataDetails[_this.imgIndex2], 2)
                }

            }

            _this.showPanorama = function (_assetDataDetails, _indexNumber) {
                // _this.assetDataDetailsSelected = _assetDataDetails;
                
                if (_assetDataDetails) {
                    _this.isFullscreen = false;
                    _this.listAssetDataDetails[0].isThumbSelected = false;
                    _this.assetDataDetailsSelected = _assetDataDetails;
                    _this.listAssetDataDetails[0].isThumbSelected = true;
                    // _this.zoomActive = false;
    
                    var _idImg = '_img_selected_ortho'

                    utility.callFunctionEveryTime('$("#'+_idImg+'").get(0)', 200, function() {
                        _this.wheelZoomRef = wheelzoom(document.getElementById(_idImg));

                        utility.callFunctionEveryTime('$("#'+_idImg+'[style*=\'background-size\']").get(0)', 200, function() { 
                            $("#"+_idImg).height('100%');
                            $("#"+_idImg).width('100%');
                        })
                        // document.getElementById(_idImg).style["height"] = "100%";
                        // document.getElementById(_idImg).style.setProperty('height', '100%');
                        // var dog = document.getElementById(_idImg);
                        // moving= false;
                        // dog.addEventListener("mousedown", initialClick, false);

                    })

 
                }
            }   


            //This function is called when change page into the assets table into asset-data screen
            //After changed the page number, I want to call the function setAssetDataViewConfig to enable/disable the asset-data shown
            //To be sure the function is called when the table is totaly loaded
            //I'm going to check the tbody element of the table.
            //When the value is equal to the value of previous tbody, that means the table is loaded
            function _refreshAssetsAssetDataTable(_nextPage) {
                var _tbody = '"--"';

                // if (_this.assetDataListViewShown == _this.assetDataListViews.list) {
                //     utility.callFunctionEveryTime("$('#_assetDataPage').get(0).value==" + _nextPage, 200, function() {
                //         utility.callFunctionEveryTime('$("#_asset_table tbody").get(0) && $.md5($("#_asset_table tbody").get(0).innerHTML) == ' + _tbody , 200
                //         , function() { //function called when the condition is true
                //             console.log("----------------loaded completed")
        
                //             if (_this.viewShown) {
                //                 _this.setAssetDataViewConfig(null, true);
                //             }
                //         }, function() { //function called when the condition is false. The return value is used to change the condition
                //             console.log("----------------change condition")
        
                //             _tbody = '"'+$.md5($("#_asset_table tbody").get(0).innerHTML)+'"';
        
                //             return '$("#_asset_table tbody").get(0) && $.md5($("#_asset_table tbody").get(0).innerHTML) == ' + _tbody;
                //         })
                //     })    
                // }

            }

            _this.changePage = function (_nextPage) {
                _this.assetsAssetDataTableParams.page(_nextPage);
                _refreshAssetsAssetDataTable(_nextPage);
            }


            _this.showSingleImage = function() {
                if (_this.viewShown == _this.views.aifilter || _this.viewShown == _this.views.aiassistedlabel){
                    _this.filterViz = 'Single'
                }
                else {
                    _this.viewShown = _this.views.singleImage;
                }
            }

            _this.showMultiImage = function() {
                if (_this.viewShown == _this.views.aifilter || _this.viewShown == _this.views.aiassistedlabel){
                    _this.filterViz = 'Multi'
                }
                else {
                    _this.viewShown = _this.views.multiImage;

                    if (_this.isAllAssetDataDetailsSelected == undefined) {
                        _this.allAssetDataDetailsSelected(_this.listAssetDataDetails);
                    }
                    ;
                }
            }

            _this.setAssetDataListView = function(_view) {
                _this.assetDataListViewShown = _view;

                if (_view == _this.assetDataListViews.list) {
                    _this.assetsAssetDataTableParams = ngTableParams.withConfig({ count: 25 }, _this.listAssetData);
                } else if (_view == _this.assetDataListViews.group) {
                    _this.assetsAssetDataGroupedTableParams = ngTableParams.withConfig({ count: 25 }, _this.listAssetDataGroupedByFiletype);    
                } else if (_view == _this.assetDataListViews.project) {
                    _this.assetsAssetDataGroupedByProjectTableParams = ngTableParams.withConfig({ count: 25 }, _this.assetsAssetDataGroupedByProject);    
                }
            }

            _this.showAssetData = function (_assetDataGroup) {
                _assetDataGroup.showAssetData = !_assetDataGroup.showAssetData;
            }


            _this.showStataticInfo = function (_assetData) {
                _this.listAssetData.forEach(e => {                   
                    if (e.id == _assetData.id){
                        e.showStataticInfo = !e.showStataticInfo;
                    }                    
                });
            }

            _this.chekinfoview = function (_assetData){
                _this.listAssetData.forEach(e => {
                    e.showStataticInfo = false;
                });
            }

            function _updateImgIndex(_index, _listAssetDataDetails) {
                if (_this.imgIndex > _this.listAssetDataDetails.length-1) {
                    _this.imgIndex = 0;
                } 

                if (_listAssetDataDetails && _listAssetDataDetails.length>0) {
                    _listAssetDataDetails[_this.imgIndex].isThumbSelected = true;
                    if (_this.imgIndex2) {
                        _listAssetDataDetails[_this.imgIndex].isThumbSelected = true;
                    }    
                }
            }

            function _updateImgIndexByThumbSelected(_listAssetDataDetails) {

                if (_listAssetDataDetails.filter(x=> x.isThumbSelected == true).length == 0){
                    _this.imgIndex = 0;
                }

                _listAssetDataDetails.some((e, index) => {
                    if (e.isThumbSelected) {
                        _this.imgIndex = index;
                    }
                })

                _listAssetDataDetails.some((e, index) => {
                    if (e.isThumb2Selected) {
                        _this.imgIndex2 = index;
                    }
                })

            }


            _this.assetDataSelected = function(_assetData, _callback) {                
        
                _assetData.isSelected = !_assetData.isSelected;
                if (_assetData.isSelected) { //if select a new asset-data
                    _this.listAssetDataSelectd.push(_assetData);

                    if (!_this.isDeleteDatasetOn) {

                        if (_assetData.fileType == _this.assetDataTypes.imagery || _assetData.fileType == _this.assetDataTypes.thermal || _assetData.fileType == _this.assetDataTypes.video ||
                            _assetData.fileType == _this.assetDataTypes.audio  || _assetData.fileType == _this.assetDataTypes.methaneDoc || _assetData.fileType == _this.assetDataTypes.kml || 
                            _assetData.fileType == _this.assetDataTypes.kmz || _assetData.fileType == _this.assetDataTypes.dwg || _assetData.fileType == _this.assetDataTypes.spinModel ||
                            _assetData.fileType == _this.assetDataTypes.lidar || _assetData.fileType == _this.assetDataTypes.autodeskRevit || _assetData.fileType == _this.assetDataTypes.checklist ||
                            _assetData.fileType == 'context_capture' ||  _assetData.fileType == _this.assetDataTypes.image360 || _assetData.fileType == _this.assetDataTypes.orthomosaic) {
    
                            var _assetDataId = _assetData.id
                            if (_assetData.fileType == _this.assetDataTypes.spinModel) {
                                _assetDataId = _assetData.spinModelFdId;
                            }
    
                            assetDataFactory.getAssetDataDetails(_assetDataId, _res => {
                                    _res.forEach(e => {
                                        e.isSelected = true;
    
                                        //add a none tag. This allows me to filter the element without any tag
                                        if (e.listTags.length==0) {e.listTags.push({tagKey:_noTag.key, value:_noTag.value})}
                                    });
    
                                    _this.listAssetDataDetailsNotFiltered.push.apply(_this.listAssetDataDetailsNotFiltered, _res)
    
                                    //extract the list of tags beloning to the asset-data-details
                                    var _tagsTmp =  assetDataByTagsFactory.getListTagsIntoAssetDataDetails(_this.listAssetDataDetailsNotFiltered, _this.listAssetDataDetailsTagTypes);
                                    _this._newTagsTmp = [];
                                    _this._newTagsTmp = _tagsTmp.filter(tt => _this.listTagsIntoAssetDataDetails.filter(t => t.key == tt.key).length == 0);
                                    _this._newTagsTmp.forEach(tt => tt.isSelected = true);   
                                    _this._newTagsTmp.forEach((tt, index) => {
                                        // tt.color = _this.listColors[index]
                                        var compoCount = 0;

                                        _this.listAssetDataDetailsNotFiltered.forEach(t => {
                                            t.listTags.forEach(s => {
                                                if (s.tagKey == tt.key){
                                                    compoCount ++;
                                                }
                                            })                                        
                                        })
                                        tt.compoCount = compoCount;

                                        var classColor
                                        _this.listAssetDataDetailsNotFiltered.forEach(t => {
                                            t.listTags.forEach(s => {
                                                if (s.tagKey == tt.key){
                                                    classColor = s.classColor;
                                                }
                                            })                                        
                                        })
                                        console.log(classColor)
                                        tt.classColor = classColor;

                                        //get only test labels
                                        _this.listAssetDataDetailsNotFiltered.forEach(t => {
                                            t.listTags.forEach(s => {
                                                if (s.tagKey == tt.key){
                                                    if (s.typeUsage == 'test'){
                                                        tt.typeUsage = 'test'
                                                    }
                                                }
                                            })                                        
                                        })
                                    })

                                    // _this._newTagsTmp = _this._newTagsTmp.filter(tt => tt.typeUsage == 'test');



                                    _this.listTagsIntoAssetDataDetails.push.apply(_this.listTagsIntoAssetDataDetails, _this._newTagsTmp);

                                    _this.listTagsIntoAssetDataDetails = assetDataByTagsFactory.sortListTagsIntoAssetDataDetails(_this.listTagsIntoAssetDataDetails)
    
                                    _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();    
    
                                    _updateImgIndex(_this.imgIndex, _this.listAssetDataDetails)

                                    _callback ? eval(_callback):null;
        
                                    if (_this.viewShown == _this.views.singleImage || _this.viewShown == _this.views.multiImage || _this.viewShown == _this.views.doubleImage) {
                                        _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
        
                                        if (_this.viewShown == _this.views.doubleImage) {
                                            _this.showImg(_this.listAssetDataDetails[_this.imgIndex2], 2)
                                        }       
                                    } else if (_this.viewShown == _this.views.assetDataByTags) {
        
                                        if (_map == undefined) {
                                            _map = appFactory.getMapById(_maps, _this.assetDataByTagsCanvasId);
        
                                            _putMarkersOnAssetDataByTagMap(_map, _this.listLeakTags)
                                        }                
                                    } else if (_this.viewShown == _this.views.compassViewer) {
        
                                        _updateCompassViewerGeoPosition(_this.listAssetDataDetails, _asset);
                                        var _index = utility.getIndexArrayElementByField(_maps, 'id', _this.compassViewerCanvasId);
                                        if (_index != undefined) {
                                            var _map = _maps[_index].map;
        
                                            //Add the markers about the asset-data's asset-data-details selected, on the compass-viewer map
                                            _putCompassViewerMarkersOnMap(_map, _res, _listCompassViewerMarker)   
                                            googlemaps.fitBounds(_map, _listCompassViewerMarker)                 
                                        }
    
                                    } else if (_this.viewShown == _this.views.assetDataMethane) {
    
                                        _map = appFactory.getMapById(_maps, _this.assetDataMethaneCanvasId);
                                        if (_map == undefined) {
                                            _createAssetDataMethaneMap(_this.assetDataMethaneCanvasId, _map => {                    
                                                _putMarkersOnAssetDataMethaneMap(_map, )
                                            });        
                                        } else {
                                            _putMarkersOnAssetDataMethaneMap(_map, )
                                        }
                        
                                    } else if (_assetData.fileType == _this.assetDataTypes.thermal) {
                                        _getThermalImages(_assetData.id, _res1 => {
                                            //add all the _res element to listThermalImage array
                                            _this.listThermalImage.push.apply(_this.listThermalImage, _res1);
        
                                            if ( _this.viewShown == _this.views.thermalImage) {
                                                var _index = utility.getIndexArrayElementByField(_maps, 'id', _this.thermalImagesCanvasId);
        
                                                if (_index != undefined) {
                                                    var _map = _maps[_index].map;
                
                                                    //Add the markers about the asset-data's asset-data-details selected, on the compass-viewer map
                                                    _putThermalImageryMarkersOnMap(_map, _res, _this.listThermalImage,  _listThermalImageryMarker)   
                                                    googlemaps.fitBounds(_map, _listThermalImageryMarker)                 
                                                }    
                                            }
            
                                        });  
    
                                    } else if (_assetData.fileType == _this.assetDataTypes.kml || _assetData.fileType == _this.assetDataTypes.kmz) {
                                        _showKmlMap('_kml_image_map', _assetData);
                                    
                                    } else if (_assetData.fileType == _this.assetDataTypes.dwg) {
                                        _showDwgFile( _assetData.autodeskUrl);
                                    } else if (_assetData.fileType == _this.assetDataTypes.spinModel) {
                                        _this.alignImages();                   
                                    } else if (_assetData.fileType == _this.assetDataTypes.lidar && _assetData.dataProcessed && _assetData.truviewDataId && _assetData.truviewSiteId ) {
                                        _this.showTruView(_assetData)
                                    } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit && _assetData.dataProcessed && _assetData.autodeskUrl ) {
                                        _this.showAutodeskViewer(_assetData)
                                    } else if (_assetData.fileType == _this.assetDataTypes.checklist) {
                                        _this.checklistFactory.showChecklist(_assetData);
                                        _this.checklistISCRetrrieve(_assetData)
                                    } else if (_assetData.fileType == _this.assetDataTypes.orthomosaic) {
                                        console.log('OK')
                                        _this.showPanorama(_this.listAssetDataDetails[_this.imgIndex])
                                    //    k _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
                                    } else if (_assetData.fileType == 'context_capture') {
                                        _this.initContextCapture(_assetData);
                                    }
                                  
                            })    
                        } else if (_assetData.fileType == _this.assetDataTypes.inspectionReport) {
                            _showInspectionReport(_assetData);
                        } else if (_assetData.fileType == _this.assetDataTypes.matterport) {
                            _getMatterportData(_assetData.id); 
                        }

                    }



    
                } else { //if unselect an asset-data

                    var _assetDataId = _assetData.id

                    _this.listAssetDataSelectd = _this.listAssetDataSelectd.filter(e => e.id != _assetDataId);

                    if (!_this.isDeleteDatasetOn) {


                        if (_assetData.fileType == _this.assetDataTypes.spinModel) {
                            _assetDataId = _assetData.spinModelFdId;
                        }
                        
                        var  _listAssetDataDetailsUnselected = _this.listAssetDataDetailsNotFiltered.filter(e => e.facilityDataId == _assetDataId)
                        _this.listAssetDataDetailsNotFiltered = _this.listAssetDataDetailsNotFiltered.filter(e => e.facilityDataId != _assetDataId)

                        //extract the list of tags beloning to the asset-data-details
                        var _tagsTmp =  assetDataByTagsFactory.getListTagsIntoAssetDataDetails(_this.listAssetDataDetailsNotFiltered, _this.listAssetDataDetailsTagTypes);
                        _this.listTagsIntoAssetDataDetails = _this.listTagsIntoAssetDataDetails.filter(t => _tagsTmp.filter(tt => tt.key == t.key).length>0);

                        _this.listTagsIntoAssetDataDetails = assetDataByTagsFactory.sortListTagsIntoAssetDataDetails(_this.listTagsIntoAssetDataDetails)

                        _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();


                        _updateImgIndex(_this.imgIndex, _this.listAssetDataDetails)

                        _callback ? eval(_callback):null;


                        if (_this.viewShown == _this.views.singleImage || _this.viewShown == _this.views.multiImage || _this.viewShown == _this.views.multiImage) {
                            _this.showImg(_this.listAssetDataDetails[_this.imgIndex])

                            if (_this.viewShown == _this.views.doubleImage) {
                                _this.showImg(_this.listAssetDataDetails[_this.imgIndex2], 2)
                            }
                        } else if (_this.viewShown == _this.views.assetDataByTags) {

                            if (_map == undefined) {
                                _this.listMarkersOnAssetDataByTagMap = assetDataByTagsFactory.removeMarkers(_this.listMarkersOnAssetDataByTagMap, _listAssetDataDetailsUnselected)

                                _map = appFactory.getMapById(_maps, _this.assetDataByTagsCanvasId);
                                _putMarkersOnAssetDataByTagMap(_map, _this.listLeakTags);
                            }                
                        } else if (_this.viewShown == _this.views.assetDataMethane) {

                            _map = appFactory.getMapById(_maps, _this.assetDataMethaneCanvasId);

                            _this.listMarkersOnAssetDataMethaneMap = assetDataMethaneFactory.removeMarkers(_map, _this.listMarkersOnAssetDataMethaneMap, _listAssetDataDetailsUnselected)

                            var values = assetDataMethaneFactory.findExtrema(_this.listAssetDataDetails);

                            $scope.methaneMinValue = values[1] ;
                            $scope.methaneMaxValue = values[0] ;

                            if (($scope.methaneMinValue - 10) < 0){
                                $scope.slider_methane.options.floor = 0
                            }
                            else{
                                $scope.slider_methane.options.floor = $scope.methaneMinValue - 10; 
                            }
                
                            $scope.slider_methane.options.ceil = $scope.methaneMaxValue + 10;

                            if (_this.listAssetDataSelectd.length == 0) {
                                appFactory.removeMapById(_maps, _this.assetDataMethaneCanvasId);
                            }
                        } else if (_this.viewShown == _this.views.compassViewer) {

                            _updateCompassViewerGeoPosition(_this.listAssetDataDetails, _asset);
                            var _index = utility.getIndexArrayElementByField(_maps, 'id', _this.compassViewerCanvasId)

                            if (_index != undefined) {
                                var _map = _maps[_index].map;

                                //remove the markers about the asset-data's asset-data-details unselected, on the compass-viewer map
                                // _listCompassViewerMarker = _deleteMarkers(_listCompassViewerMarker, _this.listAssetDataDetails);
                                googlemaps.fitBounds(_map, _listCompassViewerMarker)                 
                            }
                            
                        }

                        if (_assetData.fileType == _this.assetDataTypes.thermal) {
                            //filter off all the thermal images belonging to the asset-data unselected
                            _this.listThermalImage = _this.listThermalImage.filter(e => e.facilityDataId != _assetData.id);

                            var _index = utility.getIndexArrayElementByField(_maps, 'id', _this.thermalImagesCanvasId);

                            if (_index != undefined) {
                                var _map = _maps[_index].map;

                                //remove the markers about the asset-data's asset-data-details unselected, on the compass-viewer map
                                // _listThermalImageryMarker = _deleteMarkers(_listThermalImageryMarker, _this.listAssetDataDetails);
                                googlemaps.fitBounds(_map, _listThermalImageryMarker)                 
                            }
                        }

                        if (_assetData.fileType == _this.assetDataTypes.image360Degrees || _assetData.fileType == _this.assetDataTypes.view360Degrees) {
                            if (PSV) {
                                try {
                                    PSV.destroy();
                                } catch(_err) {
                                    PSV = undefined;
                                }
                            }
                        }
                    }

                }
            }

            //I'm going to delete the shareToekn after _timeSecond seconds;
            function _deletePix4dShareToken(__shareToken, _timeSecond) {
                var _shareToekn = __shareToken;
                $timeout(function() {
                    assetDataServices.deletePix4dShareToken(_shareToekn, _res => {
                        console.log("-----------------------> pix4d share token delete")
                    });
                }, _timeSecond*1000);
            }

            _this.getFirstPhotogrammetrySelected = function() {
                if (_this.listAssetDataSelectd.length > 0 && _this.listAssetDataSelectd[0].fileType == _this.assetDataTypes.photogrammetry) {
                    var _fd = this.listAssetDataSelectd[0];
                    assetDataServices.generatePix4dProjectShareToken(_fd.id, _res => {
                        var _url = "https://cloud.pix4d.com/dataset/"+_res.pix4dProjectId+'/map?shareToken='+_res.shareToken;
                        _this.pix4dShareTokenFirstSelected = $sce.trustAsResourceUrl(_url);

                        //I'm going to delete the shareToekn after 30 minutes;
                        //Some Pix4d feature require the existence of the shareToken
                        //This is why we delete it after 30 minutes and not after some seconds
                        _deletePix4dShareToken(_res.shareToken, 60*30);
                    })
                } else {
                    return null;
                }
            }

            _this.getSecondPhotogrammetrySelected = function() {
                if (_this.listAssetDataSelectd.length > 1 && _this.listAssetDataSelectd[1].fileType == _this.assetDataTypes.photogrammetry) {
                    var _fd = this.listAssetDataSelectd[1];
                    assetDataServices.generatePix4dProjectShareToken(_fd.id, _res => {
                        var _url = "https://cloud.pix4d.com/dataset/"+_res.pix4dProjectId+'/map?shareToken='+_res.shareToken;
                        _this.pix4dShareTokenSecondSelected = $sce.trustAsResourceUrl(_url);

                        //I'm going to delete the shareToekn after 30 minutes;
                        //Some Pix4d feature require the existence of the shareToken
                        //This is why we delete it after 30 minutes and not after some seconds
                        _deletePix4dShareToken(_res.shareToken, 60*30);
                    })
                } else {
                    return null;
                }
            }

            _this.getDoublePhotogrammetrySelected = function() {
                _this.getFirstPhotogrammetrySelected();
                _this.getSecondPhotogrammetrySelected();
            }

            _this.buildSrcUrl = function(_assetDataDetail, _assetDataParam) {
                    var _ret = {
                    thumbUrl: undefined,
                    hdUrl: undefined,
                    mdUrl: undefined,
                    url: undefined
                }

                if (_assetDataDetail) {
                    var _assetData = _assetDataParam;

                    if (!_assetDataParam) {
                        _assetData = _this.listAssetDataSelectd[utility.getIndexArrayElementByField(_this.listAssetDataSelectd, 'id', _assetDataDetail.facilityDataId)];
                    }
                    
                    if (_assetData) {
                        if (_assetDataDetail.dataType == _this.assetDataTypes.video) {
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                        } else if (_assetDataDetail.dataType == _this.assetDataTypes.audio) {
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                        } else if (_assetDataDetail.dataType == _this.assetDataTypes.image360) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);
                            _ret.hdUrl = expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName;
                        } else if (_assetDataDetail.dataType == _this.assetDataTypes.imagery || _assetDataDetail.dataType == _this.assetDataTypes.spinModel || _assetDataDetail.dataType == _this.assetDataTypes.image360) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                            _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);

                            //verify mdurl
                            // if (_this.tagSelected.length < 1){
                            //     _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            // }
                            // else
                            // {
                            //     if (_this.tagSelected.length > 0){
                            //             _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/' +_assetDataDetail.fileName);
                            //         // _this.tagSelected.forEach((value, index) => {
                            //         //     // var tempMid = _this.listTagsIntoAssetDataDetails.find(element => element.key == value)
                            //         //     // var ele = _this.listAssetDataDetailsTagTypes.filter(element => element.value == tempMid.value)
                                        
                            //         //     // if (ele.length > 1){
                            //         //     //     var tempTag = ele.filter(element => element.key.length > 3);
                            //         //     // }
                            //         //     // else{
                            //         //     //     var tempTag = ele;
                            //         //     // }

                            //         //     if(_this.currentCompoFddTags.filter(element => element.component_type_id == value).length >0 || _this.currentAnoFddTags.filter(element => element.anomaly_type_key == value).length >0){
                            //         //         _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/' +_assetDataDetail.fileName);
                            //         //         _this.drawBoundingBoxes(value);
                            //         //         //write if already not done yet for the considered nomaly
                                            
                            //         //     }
                            //         //     else{
                            //         //         _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/' +_assetDataDetail.fileName);
                            //         //     }
                            //         // })
                            //         // if (tempTag[0].key){
                            //         //     _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/' + tempTag[0].key + '/' +_assetDataDetail.fileName);
                            //         // }
                            //         // else{
                            //         //     _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            //         // }
                            //     }
                            //     else{
                            //         _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            //     }
                            //     // _this.drawBoundingBoxes();
                            // }
                            //verify mdurl



                            // if (_this.viewShown == _this.views.aifilter) {
                            //     // _this.drawBoundingBoxes();
                            // }
                            // _this.drawBoundingBoxes();
                            _ret.inventoryUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/inventory/'+_assetDataDetail.fileName);
                            _ret.autoInventoryUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/auto-inventory/'+_assetDataDetail.fileName);
                            _ret.cleanUrl = $sce.trustAsResourceUrl('/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name);
                            _ret.basicUrl = $sce.trustAsResourceUrl('/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);

                        } else if (_assetDataDetail.dataType == _this.assetDataTypes.thermal) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);                        
                            _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                        } else if (_assetDataDetail.dataType == _this.assetDataTypes.kml || _assetData.fileType == _this.assetDataTypes.kmz || 
                            _assetData.fileType == _this.assetDataTypes.document) {
                            _ret.url = expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName;
                        } else if (_assetData.fileType == _this.assetDataTypes.lidar) {
                            _ret.url = expanseConst.expanseS3Url+'lite/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName;     
                        } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit) {
                            _ret.url = expanseConst.expanseS3Url+'lite/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName;
                            
                        } else if (_assetData.fileType == _this.assetDataTypes.orthomosaic) {
                            _ret.thumbUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/thumb/'+_assetDataDetail.fileName);
                            _ret.mdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/medium/'+_assetDataDetail.fileName);
                            _ret.hdUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+'/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[_assetDataDetail.dataType].s3Name+'/'+_assetDataDetail.fileName);
                        }         
                    }
                }
                
                return _ret;
            }


            $scope.generate3dPhotogrammetryRun = function(_popup) {
                _popup.updateData(_popup.data);
                _popup.close();


                var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                var _msg = '3D Model Generation in progress';

                popup.openModal('sm', 'Info', _msg, _controllerName, JSON.stringify(_fns));

                var _data = {
                    userId: _identity.id,
                    facilityDataId: _this.listAssetDataSelectd[0].id,
                    customerId: _customerId,
                    description: _popup.data.description
                }

                assetDataServices.generate3DPhotogrammetry(_data, _res => {
                    _assetDataResetAndRefresh();
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                })
            }
    
    
            _this.generate3dPhotogrammetry = function() {
                if (_this.isAssetDataToolEnabled(_this.toolName.photogrammetry)) {
                    assetDataServices.check3dPhotogrammetry(_this.listAssetDataSelectd[0].id, _res => {
                        if (_res.count3dPhotogrammetryProcessing == 0) {
                            var _fns = [
                                { 'btn_identifier':'_1_2', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_fn': null },
                                { 'btn_identifier':'_1_1', 'btn_close': false, 'btn_msg': 'Generate model', 'btn_color': 'btn-primary', 'btn_fn': 'generate3dPhotogrammetryRun' }
                            ];

                            var _popupData = {
                                fileNumber: _this.listAssetDataDetails.length
                            }
            
                            var _config = {
                                size: 'md',
                                title: 'Generate Photogrammetry',
                                ctrl: _controllerName,
                                data: JSON.stringify(_popupData),
                                fns: JSON.stringify(_fns),
                                htmlPage: 'views/asset-data/popup/asset-data_photogrammetry_popup.html',
                            }
                            popup.openModalBody(_config);      


        
                        } else {
                            var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                            var _msg = 'This Asset is already in processing';
        
                            popup.openModal('sm', 'Warning', _msg, _controllerName, JSON.stringify(_fns));
        
                        }
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);
                    })
                }
            }

            function _isViewShownUsedByAssetDataType(_viewShown, _assetDataType) {
                if (_viewShown) {
                    return _this.viewUsedByAssetDataType[_viewShown].filter(e => e == _assetDataType).length > 0;
                } else {
                    return true;
                }
            }

            _this.isAssetDataTaskFunctionAvailable = function(_assetData) {
                var _list =  assetDataFactory.isAssetDataTaskFunctionAvailable(_assetData.fileType)
                var _taskList = assetDataFactory.getAssetDataTaskList();                

                return _list.filter(e => {
                    if (e.task == _taskList.runPostProcessingAssetData || e.task == _taskList.runCompleteAsset) {
                        if (_assetData.dataProcessed == 0 && _assetData.fileType != _this.assetDataTypes.lidar && _assetData.fileType != _this.assetDataTypes.checklist) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            }

            _this.isAssetDataListElementEnabledForUsersType = function(_assetData) {
                var _ret = true;

                if (_assetData.fileType == _this.assetDataTypes.report && $scope.hasRole($scope.listRolesTypeKey.dataCapture)) {
                    _ret = false;
                }

                return _ret;
            }

            _this.isAssetDataListCheckboxAvailable = function(_assetData) {
                var _ret = false;

                if (!_assetData.isSelected && !_this.isDeleteDatasetOn) {
                    if (_assetData.fileType != _this.assetDataTypes.document && 
                        _assetData.fileType != _this.assetDataTypes.report && 
                        _isViewShownUsedByAssetDataType(_this.viewShown, _assetData.fileType)) {

                        if (_assetData.fileType == _this.assetDataTypes.photogrammetry) {
                            if (_assetData.dataProcessed && _this.listAssetDataSelectd.length<2 &&
                                ((_assetData.saveType == _this.assetDataSaveTypes.generated && _assetData.pix4dMeshUrl) || (_assetData.saveType == _this.assetDataSaveTypes.uploaded))) {
                                _ret = true;
                            }
                        } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit) {
                            if (_assetData.dataProcessed && _assetData.autodeskUrl) {
                                _ret = true;
                            }
    
                        //***************************
                        // temporarely comment this code, because the client need to download the lidar file from S3 
                        // Without selecting the dataset, the downloading doesn't work
                        //***************************
                        // } else if (_assetData.fileType == _this.assetDataTypes.lidar) {
                        //     if (_assetData.dataProcessed && _assetData.truviewDataId && _assetData.truviewSiteId) {
                        //         _ret = true;
                        //     }
                        } else {
                            _ret = true;
                        }
                    } 
                } else {
                    _ret = true
                }

                return _ret;
            }

            
            //return the message to show into a tooltip about an asset-data
            _this.assetDataListShowTooltip = function(_assetData) {
                var _ret = '';
                // var _ret = _assetData.description?_assetData.description:'';

                if (_assetData.fileType == _this.assetDataTypes.photogrammetry && _assetData.saveType == _this.assetDataSaveTypes.generated) {
                    if (!_assetData.dataProcessed) {
                        _ret = '3D Photogrammetry generation is ongoing';
                    } else if (_assetData.dataProcessed && !_assetData.pix4dMeshUrl ) {
                        _ret = '3D Photogrammetry corrupted';
                    }
                } else if (_assetData.fileType == _this.assetDataTypes.lidar) {
                    if (!_assetData.dataProcessed) {
                        _ret = 'Lidar generation is ongoing';
                    } else if (_assetData.dataProcessed && _assetData.truviewDataId && !_assetData.truviewSiteId) {
                        _ret = 'Lidar corrupted';
                    }
                } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit) {
                    if (_assetData.dataProcessed==1) {
                        _ret = 'Autodesk Revit generation is ongoing';
                    } else if (_assetData.dataProcessed==2 && !_assetData.autodeskUrl) {
                        _ret = 'Autodesk Revit corrupted';
                    }
                }

                return _ret;
            }

            //declare the css class of an asset-data element
            //about its state
            _this.assetDataListElementStateClass = function(_assetData) {
                var _ret = true;

                if (_assetData.fileType == _this.assetDataTypes.photogrammetry ) {
                    if (_assetData.saveType == _this.assetDataSaveTypes.generated) {
                        if (!_assetData.dataProcessed) {
                            _ret = 'on-going';
                        } else if (_assetData.dataProcessed && !_assetData.pix4dMeshUrl) {
                            _ret = 'error';
                        }
                    } else {
                        if (!_assetData.dataProcessed) {
                            _ret = 'not-available';
                        }
                    }
                } else if (_assetData.fileType == _this.assetDataTypes.lidar ) {
                    if (!_assetData.dataProcessed) {
                        _ret = 'on-going';
                    } else if (_assetData.dataProcessed && _assetData.truviewDataId && !_assetData.truviewSiteId) {
                        _ret = 'error';
                    }
                } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit ) {
                    if (!_assetData.dataProcessed) {
                        _ret = 'on-going';
                    } else if (_assetData.dataProcessed && !_assetData.autodeskUrl) {
                        _ret = 'error';
                    }
                }

                return _ret;
            }

            function _keyListenerToArrowsInSingleImageScreen() {
                var _isCtrl = false;
                var _view = undefined

                return  {
                    add: function(_viewParam) {
                        _view = _viewParam

                        if (!_view) {
                            $(document).keydown(e => {
                                if (e.keyCode === 17 && _view) { //right arrow
                                    _isCtrl = true;
                                }

                                if (_isCtrl) {
                                    if (e.keyCode === 39) { //right arrow into split screen
                                        _this.nextImg(_this.listAssetDataDetails, 2)
                                    } else if (e.keyCode === 37) { //left arrow into split screen
                                        _this.previousImg(_this.listAssetDataDetails, 2)
                                    }
    
                                } else {
                                    if (e.keyCode === 39 ) { //right arrow
                                        _this.nextImg(_this.listAssetDataDetails)
                                    } else if (e.keyCode === 37) { //left arrow
                                        _this.previousImg(_this.listAssetDataDetails)
                                    }    
                                }


                            })    
                        } else {
                            $(document).keyup(e => {
                                if (e.keyCode === 17) { //right arrow
                                    _isCtrl = false;
                                }
                            })
                        }    
                    },
                    remove: function(_viewParam) {
                        _view = _viewParam

                        if (!_view) {
                            $(document).off("keydown")
                        } else {
                            $(document).off("keyup")
                            _view = undefined;
                        }
        
                    }
                }
            }

            function _removeKeyListenerToArrowsInSingleImageScreen(_view) {
            }

            _this.setAssetDataViewConfig = function(_assetData, _refreshAll) {
                var _viewShown = undefined;
                _this.tagSelected = [];

                if (!_this.isDeleteDatasetOn) {
                    _this.keyListenerToArrowsInSingleImageScreen.remove();

                    //determine, depending on what assed-data is selected, which view to show into asset-data screen
                    if (_this.listAssetDataSelectd.length > 0) {
                        if (_assetData && _assetData.isSelected) {
                            if (_assetData.fileType == _this.assetDataTypes.imagery || _assetData.fileType == _this.assetDataTypes.thermal || _assetData.fileType == _this.assetDataTypes.video || 
                                _assetData.fileType == _this.assetDataTypes.audio) {
                                if (_this.viewShown != _this.views.assetDataByTags) {
                                    if (_this.listAssetDataSelectd.filter(e => e.fileType != _this.assetDataTypes.thermal).length == 0) {
                                        _viewShown = _this.views.thermalImage;                            
        
                                    } else if (_this.viewShown != _this.views.singleImage 
                                        && _this.viewShown != _this.views.multiImage && _this.viewShown != _this.views.compassViewer && _this.viewShown != _this.views.doubleImage
                                        && _this.viewShown != _this.views.assetDataByTags) {
                                        _viewShown = _this.views.singleImage;
    
                                        _this.keyListenerToArrowsInSingleImageScreen.add()
                                    }     
                                }
                            } else if (_assetData.fileType == _this.assetDataTypes.photogrammetry) {
                                if (_this.listAssetDataSelectd.length == 1) {
                                    _viewShown = _this.views.singlePhotogrammetry;
                                } else if (_this.listAssetDataSelectd.length == 2) {
                                    _viewShown = _this.views.doublePhotogrammetry;
                                }
                            
                            } else if (_assetData.fileType == _this.assetDataTypes.matterport) {
                                _viewShown = _this.views.matterport;  
                            } else if (_assetData.fileType == _this.assetDataTypes.methaneDoc) {
                                _viewShown = _this.views.assetDataMethane;                            
                            } else if (_assetData.fileType == _this.assetDataTypes.inspectionReport) {
                                _viewShown = _this.views.inspectionReport;
                            } else if (_assetData.fileType == _this.assetDataTypes.image360 || _assetData.fileType == _this.assetDataTypes.view360Degrees) {   
                                _viewShown = _this.views.image360;
                            } else if (_assetData.fileType == _this.assetDataTypes.kml || _assetData.fileType == _this.assetDataTypes.kmz) {
                                _viewShown = _this.views.kml;                            
                            } else if (_assetData.fileType == _this.assetDataTypes.dwg) {
                                _viewShown = _this.views.dwg;                            
                            } else if (_assetData.fileType == _this.assetDataTypes.spinModel) {
                                _viewShown = _this.views.spinModel;                            
                            } else if (_assetData.fileType == _this.assetDataTypes.autodeskRevit) {
                                _viewShown = _this.views.autodeskRevit;                            
                            } else if (_assetData.fileType == _this.assetDataTypes.orthomosaic) {
                                _viewShown = _this.views.orthomosaic;                            
                            }
                        } else if (_assetData && !_assetData.isSelected) {
                            if (_assetData.fileType == _this.assetDataTypes.photogrammetry && _this.listAssetDataSelectd.length == 1) {
                                _viewShown = _this.views.singlePhotogrammetry;
                            }
                        } 
    
    
                        if (_assetData && ((!_this.viewShown && _viewShown) || (_this.viewShown != _viewShown && _assetData.fileType == _this.assetDataTypes.photogrammetry))) {
                            _this.viewShown = _viewShown;
                            _this.viewFromAssetDataTool.setViewByAssetData(_viewShown);
                        }
                    } else {
                        _this.wheelZoomRef = undefined;
                        _this.viewFromAssetDataTool.reset();
                        _this.viewShown = undefined;    
                    }
    
                    //update the viewConfig value about the _assetData
                    if (_assetData) {
                        _assetData.viewConfig = {
                            isAssetDataListCheckboxAvailable: _this.isAssetDataListCheckboxAvailable(_assetData),
                            assetDataListShowTooltip: _this.assetDataListShowTooltip(_assetData),
                            assetDataListElementStateClass: _this.assetDataListElementStateClass(_assetData),
                            isAssetDataListElementEnabledForUsersType: _this.isAssetDataListElementEnabledForUsersType(_assetData)
                        }
                    }
    
                    //update the viewConfig value about all the asset-data
                    if (_refreshAll) {
                        _this.listAssetData.forEach(e => {
                            e.viewConfig = {
                                isAssetDataListCheckboxAvailable: _this.isAssetDataListCheckboxAvailable(e),
                                assetDataListShowTooltip: _this.assetDataListShowTooltip(e),
                                assetDataListElementStateClass: _this.assetDataListElementStateClass(e),
                                isAssetDataListElementEnabledForUsersType: _this.isAssetDataListElementEnabledForUsersType(e)
                            }
                        })
                    } 
    
    
    
                    if (_assetData) {
                        return _assetData.viewConfig;
                    } else {
                        return null;
                    }
                }



            }

            _this.allAssetDataDetailsSelected = function(_listAssetDataDetails) {                
                _this.isAllAssetDataDetailsSelected = !_this.isAllAssetDataDetailsSelected;           
                
                _listAssetDataDetails.forEach(e => e.isSelected = _this.isAllAssetDataDetailsSelected);
            }

            _this.setAssetDataDetailsSelected = function(_assetDataDetails) {
                _assetDataDetails.isSelected = !_assetDataDetails.isSelected;
                var _assetDataDetailsSelected = _this.listAssetDataDetails.filter(e => e.isSelected).length;

                if (_assetDataDetailsSelected == _this.listAssetDataDetails.length) {
                    _this.isAllAssetDataDetailsSelected = true;
                } else {
                    _this.isAllAssetDataDetailsSelected = false;
                }
            }

            $scope.validationGenerateFormalReportFromPopup = function (_popup) {
                var _ret = false;
    
                var v = fsValidator.newInstance();
                v.validate(_popup.data.name, 'name').isUndefined("This field can't be empty")
                _popup.data.errors = v.getErrors();
    
                v.success(function () {
                    _ret = true;
                })
    
                return _ret;
            }

            _this.generateFormalReport = function() {
                if (_this.isAssetDataToolEnabled(_this.toolName.report)) {
                    var _fns = [
                        { 'btn_identifier':'_1_2', 'btn_msg': 'Add more data', 'btn_color': 'btn-clear', 'btn_fn': null },
                        { 'btn_identifier':'_1_1', 'btn_msg': 'Start analysis', 'btn_color': 'btn-primary', 'btn_fn': 'generateFormalReportRun', 'btn_confirm':true, 'btn_validation': 'validationGenerateFormalReportFromPopup'}
                    ];

                    var _popupData = {
                        fileNumber: _this.listAssetDataDetails.filter(e => e.isSelected).length
                    }
    
                    var _config = {
                        size: 'lg',
                        title: 'Confirm Analysis',
                        ctrl: _controllerName,
                        data: JSON.stringify(_popupData),
                        fns: JSON.stringify(_fns),
                        htmlPage: 'views/asset-data/popup/asset-data_analysis_popup.html',
                    }
                    popup.openModalBody(_config);      

                }            
            }
    
    
            $scope.generateFormalReportRun = function(_popup) {
                var _listElement = [];
                
                var _structure = utility.getArrayElementByField(_listAssetStructure, 'id', _this.listAssetDataSelectd[0].structureId);

                _this.listAssetDataDetails.forEach(e => {
                    if (e.isSelected) {
                        var _structureId = utility.getArrayElementByField(_this.listAssetDataSelectd,'id', e.facilityDataId).structureId;
                        var _element;
                        if (_this.listAssetDataFileTypeByKey[e.dataType].s3Name == 'images') {
                            _element = ['lite/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_structureId+'/asset_data_'+e.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[e.dataType].s3Name+'/medium/'+e.fileName, e.id];
                        } else {
                            _element = ['lite/customer_'+_customerId+'/asset_'+_assetId+'/structure_'+_structureId+'/asset_data_'+e.facilityDataId+'/'+_this.listAssetDataFileTypeByKey[e.dataType].s3Name+'/'+e.fileName, e.id];
                        }
                        
                        _listElement.push(_element);
                    }
                })
    
                var liteBody = {
                    companyPublicId: _customer.publicId,
                    facilityPublicId: _asset.publicId,
                    structurePublicId: _structure.publicId,
                    customerId: _customer.id,
                    assetId: _asset.id,
                    s3Keys: _listElement,
                    name: ' ' + _popup.data.name,
                }
    
                $scope.liteBodyStr = JSON.stringify(liteBody);
                $scope.urlSofteq = expanseConst.softeqUrl + '/report/create/init?liteToken=' + _identity.liteToken;
    
                $("#_form").attr("action", $scope.urlSofteq);
                $("#_hidden_liteBody").attr("value", $scope.liteBodyStr)
    
                $timeout(function() { $("#_form").submit(); }, 400);
            }

            _this.runAssetData = function(_assetData) {
                if (_assetData.fileType == _this.assetDataTypes.report) {
                    inspectionServices.getInspectionComponentByReportId(_assetData.id, _res => {
                        if (_identity.isInspector) {
                            $window.open(expanseConst.softeqUrl + 'report/' + _assetData.publicId + '/anomalies/' + _res.publicId + '?customer_id=' + _customer.id + '&inspecting_company_id=' + _customer.inspectingCompanyId + '&asset_id=' + _asset.id, '_self');
                        } else {
                            $window.open(expanseConst.softeqCustomerUrl + 'report/view/' + _assetData.publicId + '?liteToken='+_identity.liteToken, '_self');
                        }

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);        
                    })
                } else if (_assetData.fileType == _this.assetDataTypes.document) {
                    _this.seeAssetDocuments(_assetData)
                }
            } 

            // function _validationItems(_type, _item) {
            //     var v = fsValidator.newInstance();

            //     switch (_type) {
            //         case 'upload-first-page':
            //             v.validate(_item.structureId, 'structureId').isUndefined("This field can't be empty")
            //             break;
            //     }

            //     _item.errors = v.getErrors();

            //     return v;

            // }            

            // function _validationUploadDataFirstPage(_firstPage) {
            //     var _ret = false;

            //     _validationItems('upload-first-page', _firstPage).success(function () {
            //         _ret = true;
            //     }).errors(function () {
            //         _ret = _firstPage;
            //     })

            //     return _ret
            // }


            // $scope.validationUploadAssetDataFirstPagePopup = function (_popup) {
            //     var _ret = _validationUploadDataFirstPage(_popup.data.uploadData);

            //     if (_ret == true || _ret == false) {
            //         return _ret;
            //     } else {
            //         _popup.data.uploadData = _ret;
            //         return _popup.data;
            //     }

            // }

            // _this.sendFile = function(Data, file) {
            //     $http({
            //         method: 'POST',
            //         url: 'api/create_presigned_post',
            //         data: Data              

            //     }).then(function(response) {
            //         console.log(response)

            //         assetDataServices.s3upload(response.data, file);
            //     })

            // }


            // _this.testUpload = function () {
            //     console.log('OK')             


            //     for (var file of document.getElementById("myFileInput").files) {

            //         var file_load= file;
            //         var fileName = file_load.name;
            //         console.log(fileName)

            //         var Data = {'filename': fileName}

            //         _this.sendFile(Data, file_load)

                    
            //     }

            //                     // var AWS = require('aws-sdk');

            //     // AWS.config.update({
            //     //     accessKeyId: 'AKIAJVCNDO567LAB24AQ',
            //     //     secretAccessKey: '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',
            //     //     region:'eu-central-1'
            //     //     //region:'ap-northeast-1'
            //     //   });

            //     // var test_s3 = new AWS.S3();

            // //     var data = test_s3.getSignedUrl("putObject", {
            // //         Bucket:  S3_BUCKET,
            // //         Key: fileName,
            // //         ContentType: fileType
            // //       });

            // //     var t0 = performance.now()                
                  

            // //     var file_load= document.getElementById("myFileInput").files[0];

            // //     $http({
            // //         method: 'PUT',
            // //         url: data,
            // //         data: file_load,
            // //         headers: {'Content-Type': 'image/jpeg'}
            // //     }).then(function(response) {
            // //         console.log(response)
            // //         var t1 = performance.now()
            // //         console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.")
            // //     })
           
            //     // const fileType = 'image/jpeg'; // the content-type of the file

            //     // const S3_BUCKET = 'upload22test';
            //     // // const S3_BUCKET = 'uploadtokio'; 

            //     // var data = test_s3.createPresignedPost({
            //     //     Bucket: S3_BUCKET,
            //     //     Fields: {
            //     //         key: fileName, // totally random
            //     //     },
            //     //     Conditions: [
            //     //         { "bucket": "bucket" },
            //     //         ["content-length-range", 	0, 1000000], // content length restrictions: 0-1MB
            //     //         ["eq", "$key", fileName],
            //     //         {"success_action_status": "200"}
            //     //     ],
            //     //     ContentType: fileType
                    
            //     // });
            
            //     // data.fields["x-amz-meta-userid"] = 'AKIAJVCNDO567LAB24AQ'; // Don't forget to add this field too

            //     // const formData = new FormData();
            //     // formData.append("Content-Type", document.getElementById("myFileInput").files[0].type);
            //     // Object.entries(data.fields).forEach(([k, v]) => {
            //     //     formData.append(k, v);
            //     // });
            //     // formData.append("file", document.getElementById("myFileInput").files[0]); // must be the last onev

            //     // console.log(document.getElementById("myFileInput").files[0].type)

            //     // // Display the key/value pairs
            //     // for (var pair of formData.entries()) {
            //     //     console.log(pair[0]+ ', ' + pair[1]); 
            //     // }
            //     // var t0 = performance.now()  

            //     // $http({
            //     //     method: 'POST',
            //     //     url: data.url,
            //     //     body: formData,
            //     //     headers: {'Content-Type': 'multipart/form-data'},                    

            //     // }).then(function(response) {
            //     //     console.log(response)
            //     //     var t1 = performance.now()
            //     //     console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.")
            //     // })

            // }


            // _this.uploadFile = function(e){
            //     $scope.user.file = e;
            //     console.log('OK')
            //   }
              


            _this.uploadAssetDataPopup = function(cflag, id = 0) {

                var _headerCtrl = Scopes.get("headerCtrl");
                _headerCtrl.goAssetDataUpload(id,cflag);

                // var _uploadData = {
                //     uploadData: {description:''},
                //     listStructure: _listAssetStructure,
                //     autoAllocation: _this.autoAllocation,
                //     v6Analysis: _this.v6Analysis,
                //     assetType: _this.assetTypeId,
                //     listFeatureKey: $scope.listFeatureKey,
                //     assetTypeKey: _this.listAssetTypeByKey[_asset.facilityTypeId].key
                //     // listAssetDataFileTypeByKey: _this.listAssetDataFileTypeByKey
                //     // params: $scope.get_param.query(),
                //     // uploaderFlow: $scope.uploader.flow
                // };

                //     var _fns = [
                //         { 'btn_identifier':'_1_1', 'btn_close': false, 'btn_msg': 'Remove All Files', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_data_section == 'upload'" },
                //         { 'btn_identifier':'_1_2', 'btn_close': false, 'btn_validation': 'validationUploadAssetDataFirstPagePopup', 'btn_msg': 'Next', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_data_section == 'upload'", 'btn_fn': 'uploadAssetDataNextFromPopup' },
    
                //         { 'btn_identifier':'_2_1', 'btn_close': false, 'btn_msg': 'Back', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_data_section == 'analysis'", 'btn_fn': 'uploadAssetDataBackFromPopup'  },
                //         { 'btn_identifier':'_2_2', 'btn_close': false, 'btn_msg': 'Next', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_data_section == 'analysis'", 'btn_fn': 'uploadAssetDataNextFromPopup'  },
    
                //         { 'btn_identifier':'_3_1', 'btn_close': false, 'btn_msg': 'Back', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_data_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'uploadAssetDataBackFromPopup'  },
                //         { 'btn_identifier':'_3_2', 'btn_close': false, 'btn_msg': 'Run Upload & Analysis', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_data_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'uploadAssetDataRunFromPopup' },                                           
                //         { 'btn_identifier':'_3_3', 'btn_close': true, 'btn_msg': 'Close', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_data_section == 'results' && $innerStatus.isUploadCompleted", 'btn_fn': 'uploadAssetDataCloseFromPopup' },                   
                //         { 'btn_identifier':'_3_4', 'btn_close': true, 'btn_no_check':true, 'btn_msg': 'Confirm & Analysis', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_data_section == 'results' && $innerStatus.isUploadConfirmed", 'btn_fn': 'uploadConfirmStatus' },                   
                //     ];    


                // // <div flow-init="get_param" flow-name="uploader.flow" flow-complete="getresults_flow()" flow-progress="flow_progress()" flow-files-submitted="upload_count_file()">

                // var _config = {
                //     funzione: {
                //         initUploadAssetDataPopupData: _this.initUploadAssetDataPopupData(),
                //         getresults_flow: $scope.getresults_flow(),
                //         upload_count_file: $scope.upload_count_file(),
                //         check_double_files: $scope.check_double_files(),
                //         flow_progress: $scope.flow_progress(),
                //         getIconNameByFileUploadType: _this.getIconNameByFileUploadType(),
                //         setAnalysisConfElementSelected: _this.setAnalysisConfElementSelected(),
                //         utility: _this.utility(),
                //         getTotalFileInfo: _this.getTotalFileInfo(),
                //         getDataTypeDropDownMenuAll: _this.getDataTypeDropDownMenuAll(),
                //         getFacilityDataTypes: _this.getFacilityDataTypes(),
                //         changeautoallocation: _this.changeautoallocation(),
                //         changeV6analysis: _this.changeV6analysis(),
                //         isFeatureEnabled: _this.isFeatureEnabled()
                //       // getUploaderFlow: $scope.getUploaderFlow()
                //     },
                //     size: 'lg',
                //     title: 'Upload New Data',
                //     ctrl: _controllerName,
                //     data: JSON.stringify(_uploadData),
                //     fns: JSON.stringify(_fns),
                //     htmlPage: 'views/asset-data/popup/asset-data_upload_popup.html',
                //     innerStatus: {
                //         asset_data_section:'upload',
                //         c: false,
                //         isUploading: false,
                //         isUploadConfirmed: false,
                //     }
                // }
                // popup.openModalBody(_config);       
            }
    
            // $scope.uploadAssetDataCloseFromPopup = function(_popup) {
            //     // _this.getAssetsAssetData(_assetId);
            //     _assetDataResetAndRefresh();
            // }

            // $scope.uploadAssetDataNextFromPopup = function(_popup) {
            //     switch (_popup.innerStatus.asset_data_section) {
            //         case 'upload':
            //             _popup.innerStatus.asset_data_section = 'analysis';
            //             break;
            //         case 'analysis':
            //             _popup.innerStatus.asset_data_section = 'results';
            //             break;
            //     }
            // }

            // $scope.uploadAssetDataBackFromPopup = function(_popup) {
            //     switch (_popup.innerStatus.asset_data_section) {
            //         case 'analysis':
            //             _popup.innerStatus.asset_data_section = 'upload';
            //             break;
            //         case 'results':
            //             _popup.innerStatus.asset_data_section = 'analysis';
            //             break;
            //     }
            // }

            // $scope.uploadAssetDataRunFromPopup = function(_popup) {
            //     _structureId = _popup.data.uploadData.structureId;
            //     _sourceTypeKey = _popup.data.uploadData.sourceTypeKey;
            //     _description = _popup.data.uploadData.description;
  
            //     myLog("uploadData", _popup.data.uploadData);
            //     $scope.upload_fun(_popup);
            // } 

            // _this.getIconNameByFileUploadType = function() {
            //     return function(_popup, _name) {
            //         var _extention = _name.split('.').pop().toLowerCase();
            //         return _this.listAssetDataFileTypeByKey[_popup.data.listFileTypeByKey[_extention].value].imgName;
            //     } 
            // } 

            // _this.setAnalysisConfElementSelected = function() {
            //     return function(_popup, _element) {
            //         _element.isSelected = !_element.isSelected;
                    
            //     }
            // }

            // _this.utility = function() {
            //     return function() {
            //         return utility;
            //     }
            // }

            // _this.getTotalFileInfo = function() {
            //     return function(_popup) {
            //         var _ret = {
            //                 size: 0,
            //                 number: 0
            //             }
            //         if (_popup.data.uploaderFlow ) {
            //             _popup.data.uploaderFlow.files.forEach(e => {
            //                 _ret.size += e.size;
            //                 _ret.number += 1;
            //             })
            //         } 
                    
            //         return _ret;
            //     }
            // }


            // _this.changeautoallocation = function() {
            //     return function()  {
            //         _this.autoAllocation.value = !_this.autoAllocation.value;
            //     }
            // }

            // _this.changeV6analysis = function() {
            //     return function()  {
            //         _this.v6Analysis.value = !_this.v6Analysis.value;
            //     }
            // }


            // _this.getFacilityDataTypes = function(){
            //     return function(_popup) {
            //         var data_type_list = [];
            //         assetDataServices.getAssetDataFileTypes(_res => {                      
            //             for (var k = 0; k <_res.length; k++) {
            //                 var record = {'value': _res[k].value, 'key': _res[k].key }
            //                 data_type_list.push(record)
            //             } 
            //             _popup.data_type_list = data_type_list;
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })
                   
            //         return data_type_list
            //     } 
            // } 
            
            // _this.getDataTypeDropDownMenuAll = function() {
            //     return function(_popup, _name) {
     
            //         for (var k = 0; k < _popup.data.uploaderFlow.files.length; k++) {                          
            //             var _extention = _popup.data.uploaderFlow.files[k].file.name.split('.').pop().toLowerCase();
            //             var file_value = _this.listAssetDataFileTypeByKey[_popup.data.listFileTypeByKey[_extention].value];
            //             _this.model_dropdown[_popup.data.uploaderFlow.files[k].file.name] =  _popup.data_type_list.find(x => x.key === file_value.key)
            //             _popup.myModel = _this.model_dropdown;
            //         }
            //         return ''
            //     } 
            // } 
            

            // $scope.uploadConfirmStatus = function(_popup) {
                
            //     var file_data_type =[]

            //     for (var i = 0; i < _popup.data.uploaderFlow.files.length; i++) {
            //         if (typeof _popup.myModel[_popup.data.uploaderFlow.files[i].file.name] != "undefined"){
            //             file_data_type[i] = {'key': _popup.myModel[_popup.data.uploaderFlow.files[i].file.name].key, 'file_name': _popup.data.uploaderFlow.files[i].file.name}
                
            //         }
            //     }

            //     var Data = {
            //         'Customer': 'customer_' + _customerId,
            //         'Asset': 'asset_' + _assetId,
            //         'Component': 'structure_' + _structureId ,
            //         'file_info': file_data_type,
            //         'info_uploaded': _popup.data_file_info_uploaded,
            //         'fac_det_id': $scope.file_list_facility_id
            //     }

            //     $http({
            //         method: 'POST',
            //         url: '/api/confirmUploadStatus',
            //         data: Data
            //     }).then(function(response) {

            //         // $scope.object_selection = '1'
            //         // $scope.counter_progress = 1
            //         // $scope.start_upload = 1;
            //         // var progress = 0;

            //         //  PLF Function
            //         // ------------------------------------------------------
            //         $scope.file_list_facility_id = response.data
            //         // var _listPLF = [];

            //         // // get all the processes selected to run
            //         // _popup.data.listGeneration.forEach(e => {
            //         //     if (e.isSelected) {_listPLF.push(e.key)}
            //         // })
            //         // _popup.data.listComparison.forEach(e => {
            //         //     if (e.isSelected) {_listPLF.push(e.key)}
            //         // })


            //         // run the batch processing
            //         $scope.run_plf_processing();

            //         $timeout(function() {
            //             _assetDataResetAndRefresh();
            //         }, 1000)
            //     });
            // } 

            // //reset all the main objects used into asset-data screen
            // //and refresh the page, reloading all the asset-data about the asset selected
            // function _assetDataResetAndRefresh() {
            //     _this.listAssetDataSelectd = [];
            //     _this.listAssetDataDetails = [];
            //     _this.listAssetDataDetailsNotFiltered = [];
            //     _this.listTagsIntoAssetDataDetails = [];
            //     _this.viewShown = undefined;

            //     _this.getAssetsAssetData(_assetId);
            // }


            // _this.initUploadAssetDataPopupData = function () {
            //     return function (_popup) {
            //         // var _listFileType = [
            //         //     {key: 'image/jpeg', facilityDataFileType: 'static_imagery'},
            //         //     {key: 'image/png', facilityDataFileType: 'static_imagery'},
            //         //     {key: 'video/mp4', facilityDataFileType: 'video'},
            //         //     {key: 'application/pdf', facilityDataFileType: 'documents'},
            //         //     {key: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', facilityDataFileType: 'documents'},                        
            //         // ]

            //         //Get all the file types uploadable linked with facility_data_file_type
            //         //Them will be used to get the file type icon 
            //         assetDataServices.getFileUploadTypes(_res => {
            //             _popup.data.listFileTypeByKey = utility.getArrayByField(_res, 'key');
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })
            //reset all the main objects used into asset-data screen
            //and refresh the page, reloading all the asset-data about the asset selected
            function _assetDataResetAndRefresh() {
                _this.listAssetDataSelectd = [];
                _this.listAssetDataDetails = [];
                _this.listAssetDataDetailsNotFiltered = [];
                _this.listTagsIntoAssetDataDetails = [];
                _this.viewShown = undefined;

                _this.getAssetsAssetData(_assetId, _res => {
                    _this.changePage(_this.nextPageNum);
                });
            }

            _this.assetDataResetAndRefresh = function() {
                _assetDataResetAndRefresh();
            }


            //         // var _listGeneration = [
            //         //     {key:'photogrammetry-model', value:'Photogrammetry Model'},
            //         //     {key:'hot-spot-analysis-report', value:'Hot Spot Analysis Report'},
            //         //     {key:'semi-automated-inspection-report', value:'Semi-automated Inspection Report'},
            //         //     {key:'base-inspection-report', value:'Base Inspection Report'},
            //         //     {key:'thermal-orthomosaic', value:'Thermal Orthomosaic'},
            //         //     {key:'visual-orthomosaic', value:'Visual Orthomosaic'},
            //         //     {key:'bullet-time-model', value:'Bullet Time Model'},
            //         // ];

            //         // var _listComparison = [
            //         //     {key:'satellite-change-detection', value:'Satellite Change Detection'},
            //         //     {key:'survey-analysis', value:'Survey Analysis'},
            //         //     {key:'finger-printing-analysis', value:'FingerPrinting Analysis'},
            //         //     {key:'component-inventory', value:'Component Inventory'},
            //         //     {key:'forensic-point-change-detection', value:'Forensic Point Detection'},
            //         // ]
                    
            //         // var _listComponentAi = [
            //         //     {key:'nodding-donkey', value:'Pumb Jacks'},
            //         //     {key:'vertical-tank', value:'Heaters'},
            //         //     {key:'chimney', value:'Chimney'},
            //         //     {key:'electrical-component', value:'Electrical Component'},
            //         //     {key:'solar-panel', value:'Solar Panel'},
            //         // ]

            //         //Get the list of company's PLF for Generation
            //         //If no PLF availbale for the customer, Inspection2's will be dislpayed
            //         assetDataServices.getPlfFunctions(_customerId, 'Generation', _asset.facilityTypeId, _res => {
            //             _popup.data.listGeneration = _res;                     
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })

            //         //Get the list of company's PLF for Comparison
            //         //If no PLF availbale for the customer, Inspection2's will be dislpayed
            //         assetDataServices.getPlfFunctions(_customerId, 'Comparison', _asset.facilityTypeId, _res => {
            //             _popup.data.listComparison = _res;                     
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })


            //         //Get the list of company's components runnable by AI Machine
            //         //If the company doesn't have any, I'll get the same about SKY-FUTURES
            //         assetDataServices.getAiComponent(_customerId, _asset.facilityTypeId, _res => {
            //             _popup.data.listComponentAi = _res;
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })

            //         // var _listAnomalyAi = [
            //         //     {key:'corrosion', value:'Corrosion Detection'},
            //         //     {key:'leaks-detected-liqui', value:'Leak Detection (Liquids)'}
            //         // ]

            //         //Get the list of company's anomalies runnable by AI Machine
            //         //If the company doesn't have any, I'll get the same about SKY-FUTURES
            //         assetDataServices.getAiAnomaly(_customerId, _asset.facilityTypeId, _res => {
            //             _popup.data.listAnomalyAi = _res;
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);        
            //         })

            //         // _popup.data.listGeneration = _listGeneration;
            //         // _popup.data.listComparison = _listComparison;
            //         // _popup.data.listComponentAi = _listComponentAi;
            //         // _popup.data.listAnomalyAi = _listAnomalyAi;

            //         _popup.updateData(_popup.data);

            //         assetServices.getAssetSourceTypes(_res => {
            //             _popup.data.listAssetSourceType = _res;

            //             _popup.updateData(_popup.data);

            //             myLog("assetSourceTypes", _res)
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err);    
            //         })
            //     }
            // }
            function _assetDataResetAndRefresh() {
                _this.listAssetDataSelectd = [];
                _this.listAssetDataDetails = [];
                _this.listAssetDataDetailsNotFiltered = [];
                _this.listTagsIntoAssetDataDetails = [];
                _this.viewShown = undefined;

                _this.getAssetsAssetData(_assetId);
            }


            // _this.SpinModelSelection = function (_position, _listAssetData) {

            //     //SPIN MODEL CODE FOR ASSET REVIEW SCREEN
            //     if(_position == 'A'){
            //         for (var k = 0; k < _listAssetData.length; k++) {
            //             if(_listAssetData[k].isSelected){
            //                 _this.setAssetDataViewConfig(_listAssetData[k], true);
            //                 _this.listAssetDataDetails = [];
            //                 _this.getAssetDataDetails(_listAssetData[k].id, _res => {
            //                     _res.forEach(e => e.isSelected = true);
            //                     _this.listAssetDataDetails.push.apply(_this.listAssetDataDetails, _res)
    
            //                     _updateImgIndex(_this.imgIndex, _this.listAssetDataDetails)
            //                     _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
            //                 }) 
    
    
            //             }
            //         }
            //     }
            //     else{
    
            //         var _data = {
            //             'facilityDataId': _listAssetData,
            //             'facilityTypeId':  _asset.facilityTypeId, 
            //             'position': _position
            //         }
    
            //         assetDataServices.getSpinModelClustering(_data, _res => {
    
            //             console.log(_this.listAssetDataDetails)
            //             console.log('done',_res)
            //             var updated_list = [];
            //             for (var k = 0; k < _this.listAssetDataDetails.length; k++) {
            //                 if (_res.indexOf(_this.listAssetDataDetails[k].id) >  -1){
            //                     console.log('present') 
            //                     updated_list.push(_this.listAssetDataDetails[k]);
            //                 }
            //             } 
            //             _this.listAssetDataDetails = updated_list;
    
            //         }, _err => {
            //             myAlert("Error: " + _err.data.message, 'danger');
            //             myThrow(_err); 
            //         })
    
            //     }
            // }

            function _aiFilterCanvasEventWheel(e) {
                htmlEvents.dispatchEventWheel('_img_selected', e);

                $timeout(function () {
                    var size = $("#_img_selected").css("background-size").split(" ");
                    $("#canvas").width(size[0]);
                    $("#canvas").height(size[1]);

                    var _canvasAntennasCenter = {position: {}};
                    _canvasAntennasCenter.position.left =  $("#canvas").position().left;
                    _canvasAntennasCenter.position.top =  $("#canvas").position().top;
        
                    var position = $("#_img_selected").css("background-position").split(" ");
                    // $("#canvas").css("left", _canvasAntennasCenter.position.left + Number(position[0].replace("px",""))+"px");
                    // $("#canvas").css("top", _canvasAntennasCenter.position.top + Number(position[1].replace("px",""))+"px");
                    $("#canvas").css("left", $("#_img_selected").get(0).offsetLeft + Number(position[0].replace("px",""))+"px");
                    $("#canvas").css("top", $("#_img_selected").get(0).offsetTop + Number(position[1].replace("px",""))+"px");
                }, 10)
            }

            function _aiFilterCanvasEventMouseDown(e) {
                htmlEvents.dispatchEventMouseDown('_img_selected', e);

                mouseX=parseInt(e.clientX-offsetX);
                mouseY=parseInt(e.clientY-offsetY);

                // check if a button was clicked under the mouse
                var b=findButton(mouseX,mouseY);
                if(b){
                    assetDataServices.updateStatusBBox(b.id, b.action, b.type, _res => {
                        myAlert("Element Status Updated", 'info');     
                        _this.retrieveTags(_this.listAssetDataDetails[_this.imgIndex].id)    
                        _this.getAssetStats()     
                    }, _err => {
                        myAlert("Error: " + _err.data["Error Message"], 'danger');
                        myThrow(_err);        
                    })
                }

            };

            function _aiFilterCanvasEventMouseUp(e) {
                htmlEvents.dispatchEventMouseUp('_img_selected', e);
            };
            function _aiFilterCanvasEventMouseMove(e) {
                htmlEvents.dispatchEventMouseMove('_img_selected', e);

                var position = $("#_img_selected").css("background-position").split(" ");
                // $("#canvas").css("left", _canvasAntennasCenter.position.left + Number(position[0].replace("px",""))+"px");
                // $("#canvas").css("top", _canvasAntennasCenter.position.top + Number(position[1].replace("px",""))+"px");
                $("#canvas").css("left", $("#_img_selected").get(0).offsetLeft + Number(position[0].replace("px",""))+"px");
                $("#canvas").css("top", $("#_img_selected").get(0).offsetTop + Number(position[1].replace("px",""))+"px");
            };


            _this.drawBoundingBoxesListener = function() {
                var _index = 0;
            
                $( "#_img_selected" ).load( function(e) {
                    if (_index ==0) {
                        _index++;
                        _this.drawBoundingBoxes();
                    }
                })
                _this.drawBoundingBoxes();
            }

            _this.rejectedLabel = 0;
            _this.toBeLabelled = 0;
            _this.correctLabel = 0;

            _this.getAssetStats = function() {

                      assetDataServices.gestAssetStats(_this.listAssetDataDetails[_this.imgIndex].facilityDataId, 'test', _res => {
                        _this.rejectedLabel = _res['rejectedLabel']
                        _this.toBeLabelled = _res['toBeLabelled']
                        _this.correctLabel = _res['correctLabel']

                        _this.servereInfoTooltip = 
                        '<html>' +
                        '<head>' +
                        '</head>' +
                        '<body>' +
                        '<div style="color: #333; width: 300%">' +
                        '<div>' + 'Number of Remaining Lables =  '+ _this.toBeLabelled  + '</div>' +
                        '<div>' + 'Number of Accepted Lables =  '+ _this.correctLabel  + '</div>' +
                        '<div>' + 'Number of Rejected Lables =  '+ _this.rejectedLabel  + '</div>' +
                        '</div>' +
                        '</body>' +
                        '</html>';
            
                        return                    
                    }, _err => {
                        myAlert("Error: " + _err.data["Error Message"], 'danger');
                        myThrow(_err);        
                    })                
                
            }


            _this.servereInfoTooltip = 
            '<html>' +
            '<head>' +
            '</head>' +
            '<body>' +
            '<div style="color: #333; width: 300%">' +
            '<div>' + 'Number of Remaining Lables =  '+ _this.toBeLabelled  + '</div>' +
            '<div>' + 'Number of Accepted Lables =  '+ _this.correctLabel  + '</div>' +
            '<div>' + 'Number of Rejected Lables =  '+ _this.rejectedLabel  + '</div>' +
            '</div>' +
            '</body>' +
            '</html>';

            var trustedFormat = {};
            _this.getPopoverContent = function(content) {
                return trustedFormat[content] || (trustedFormat[content] = $sce.trustAsHtml(content)); 
            }
            

            function makeButton(id,x,y,w,h,label,fill,stroke,labelcolor, action, type){
                return({
                    id:id,
                    x:x, y:y, w:w, h:h,
                    fill:fill, stroke:stroke, labelcolor:labelcolor,
                    label:label,
                    action: action,
                    type: type
                    // click:clickFn,
                    // release:releaseFn
                });
            }

            function drawButton(ctx){
                for(var i=0;i<_this.AiToolButtons.length;i++){
                    var b = _this.AiToolButtons[i]
                    ctx.clearRect(b.x-1,b.y-1,b.w+2,b.h+2);
                    ctx.fillStyle=b.fill;
                    ctx.fillRect(b.x,b.y,b.w,b.h);
                    ctx.strokeStyle=b.stroke;
                    ctx.strokeRect(b.x,b.y,b.w,b.h);
                    ctx.textAlign='center';
                    ctx.textBaseline='middle';
                    ctx.fillStyle=b.labelcolor;
                    ctx.fillText(b.label,b.x+b.w/2,b.y+b.h/2);
                }
            }

            function findButton(mx,my){
                for(var i=0;i<_this.AiToolButtons.length;i++){
                    var b=_this.AiToolButtons[i];
                    if(mx>b.x && mx<b.x+b.w && my>b.y && my<b.y+b.h){
                        return(_this.AiToolButtons[i]);
                    }
                }
                return(null);
            }


            _this.drawBoundingBoxes =  function () {
                        var img = document.getElementById('_img_selected');
                        if (img && img.naturalHeight != 0 && img.naturalWidth != 0){

                            var _containerWidth = $("#_obj_container").width();
                            // var _imageWidth = $("#_img_selected").width();
                            $("#canvas").width(img.width);
                            $("#canvas").height(img.height);
                            $("#canvas").css('top', img.top);
                            $("#canvas").css('left', img.left);
                            $("#_obj_center").width(img.width);
                            $("#_obj_sx").width((_containerWidth - img.width)/2);
                            $("#_obj_dx").width((_containerWidth - img.width)/2);
                            
                
                            var canvas = document.getElementById("canvas");

                            canvas.removeEventListener("wheel", _aiFilterCanvasEventWheel);
                            canvas.removeEventListener("mousedown", _aiFilterCanvasEventMouseDown);
                            canvas.removeEventListener("mouseup", _aiFilterCanvasEventMouseUp);
                            canvas.removeEventListener("mousemove", _aiFilterCanvasEventMouseMove);

                            canvas.removeEventListener("wheel", _aiFilterCanvasEventWheel);
                            canvas.removeEventListener("mousedown", handleMouseDown);
                            canvas.removeEventListener("mouseup", handleMouseUp);
                            canvas.removeEventListener("mousemove", handleMouseMove)

                            canvas.addEventListener("wheel", _aiFilterCanvasEventWheel);
                            canvas.addEventListener("mousedown", _aiFilterCanvasEventMouseDown);
                            canvas.addEventListener("mouseup", _aiFilterCanvasEventMouseUp);
                            canvas.addEventListener("mousemove", _aiFilterCanvasEventMouseMove);


                            canvas.width = img.width;
                            canvas.height = img.height;
                            var ctx = canvas.getContext('2d');
                            ctx.clearRect(0, 0, img.width, img.height);

                            var hCoeff = img.naturalHeight / img.height;
                            var vCoeff = img.naturalWidth / img.width;
                            this.AiToolButtons = []

                            _this.tagSelected.forEach((value, index) => {
                                var bbToDraw = _this.currentCompoFddTagsAIFilter.filter(element => element.component_type_id == value)
                                bbToDraw = bbToDraw.concat(_this.currentAnoFddTags.filter(element => element.anomaly_type_key == value))

                                for (var j=0; j<bbToDraw.length; j++){
                                    if (_this.viewShown == 'aifilter' && bbToDraw[j].status==1){
                                        ctx.beginPath();
                                        ctx.strokeStyle = bbToDraw[j].class_color;
                                        var x1 = (bbToDraw[j].ul_x)/vCoeff;
                                        var y1 = (bbToDraw[j].ul_y)/hCoeff;
                                        var x2 = (bbToDraw[j].dr_x - bbToDraw[j].ul_x)/vCoeff;
                                        var y2 = (bbToDraw[j].dr_y - bbToDraw[j].ul_y)/hCoeff;
                                        ctx.rect(x1, y1, x2, y2);                 
                                        ctx.stroke(); // outline the shape that's been describeds
                                        ctx.closePath();
                                    }

                                    if (_this.viewShown == 'aiassistedlabel' && bbToDraw[j].status==0){
                                        ctx.beginPath();
                                        ctx.strokeStyle = bbToDraw[j].class_color;
                                        var x1 = (bbToDraw[j].ul_x)/vCoeff;
                                        var y1 = (bbToDraw[j].ul_y)/hCoeff;
                                        var x2 = (bbToDraw[j].dr_x - bbToDraw[j].ul_x)/vCoeff;
                                        var y2 = (bbToDraw[j].dr_y - bbToDraw[j].ul_y)/hCoeff;
                                        ctx.rect(x1, y1, x2, y2);                 
                                        ctx.stroke(); // outline the shape that's been describeds
                                        ctx.closePath();
                                    }

                                    if (_this.viewShown == 'aiassistedlabel'){
                                        var type;
                                        var id;
                                        if (bbToDraw[j].hasOwnProperty('component_type_id')){
                                            type = 'component'
                                            id = bbToDraw[j].compo_tag_area_id
                                        }
                                        else{
                                            type = 'anomaly'
                                            id = bbToDraw[j].ano_tag_area_id
                                        }
                                        var button1 = makeButton(id,x1,y1-20,20,20,'V','lightgreen','lightgreen','black','confirm', type)
                                        var button2 = makeButton(id,x1+20,y1-20,20,20,'X','#FFCCCB','#FFCCCB','black','reject', type)
                                        
                                        this.AiToolButtons.push(button1)
                                        this.AiToolButtons.push(button2)
                                        
                                    }
                                }
                                drawButton(ctx)

                            })

                            //force the canvas to show the shapes
                            $("#canvas").get(0).dispatchEvent(new Event('mousemove'))                            
                        }
            }

            _this.draw_image =  function () {
                if (_this.zoomActive) {
                    _this.zoomActive = !_this.zoomActive;
                }

                var img = document.getElementById('_img_selected');
                var canvas = document.getElementById("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, img.width, img.height);

                $scope.vertical_angle = 0;
                $scope.image_angle = 0;

                var isMoving = false;
                var index = 0 

                var list_point = [];
                function getMousePos(canvas, evt) {
                    var rect = canvas.getBoundingClientRect();
                    return {
                      x: evt.clientX - rect.left,
                      y: evt.clientY - rect.top
                    };
                }
                function radians_to_degrees(radians)
                {
                    var pi = Math.PI;
                    return radians * (180/pi);
                }
                function angleOf(x1, x2, y1, y2) {
                    var deltaY = (y1 - y2);
                    var deltaX = (x2 - x1);
                    var result = radians_to_degrees(Math.atan2(deltaY, deltaX));
                    return result
                }
                function findxy(res, e) {
                    if (res == 'down') {

                        if (list_point.length == 3 ){
                            var closest = list_point.reduce(function(prev, curr) {
                                return (Math.sqrt(Math.pow((curr['x'] - e['x']),2) + Math.pow((curr['y'] - e['y']),2)) < Math.sqrt(Math.pow((prev['x'] - e['x']),2) + Math.pow((prev['y'] - e['y']),2))  ? curr : prev);
                            });

                            index = list_point.findIndex(p => p.x === closest.x && p.y === closest.y);
                            list_point[index] = e;
                            isMoving = true;

                        return
                        }

                        if (list_point.length >2){
                            return
                        }
                        ctx.strokeStyle = "#00F";
                        ctx.beginPath();
                        ctx.arc(e.x, e.y, 5, 0, 2 * Math.PI, false);
                        ctx.fillStyle = 'green';
                        ctx.fill();
                        ctx.stroke(); // outline the shape that's been describeds
                        ctx.closePath();      
                        list_point.push(e);      
                        if (list_point.length == 3){
                            ctx.beginPath();
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[1].y);
                            ctx.moveTo(list_point[1].x, list_point[1].y);
                            ctx.lineTo(list_point[2].x, list_point[2].y);
                            var fl_angle = angleOf(list_point[1].x, list_point[0].x, list_point[1].y, list_point[0].y);
                            var sl_angle = angleOf(list_point[1].x, list_point[2].x, list_point[1].y, list_point[2].y);
                            
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath(); 

                            $scope.image_angle = (fl_angle- sl_angle).toFixed(2);
                            $scope.vertical_angle = (90 - fl_angle).toFixed(2);

                        }       
                    }
                    if (res == 'up' && isMoving == true) {
                        console.log(index)
                        isMoving = false;
                    }

                    if (res == 'move' && isMoving == true) {
                        ctx.clearRect(0, 0, img.width, img.height);
                       
                        list_point[index] = e;
                        ctx.strokeStyle = "#00F";
                        for (var i = 0; i < list_point.length; i++) {
                            ctx.beginPath();
                            ctx.arc(list_point[i].x,list_point[i].y, 5, 0, 2 * Math.PI, false);
                            ctx.fillStyle = 'green';
                            ctx.fill();
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath();
                        }     
   
                        if (list_point.length == 3){
                            ctx.beginPath();
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[1].y);
                            ctx.moveTo(list_point[1].x, list_point[1].y);
                            ctx.lineTo(list_point[2].x, list_point[2].y);
                            var fl_angle = angleOf(list_point[1].x, list_point[0].x, list_point[1].y, list_point[0].y);
                            var sl_angle = angleOf(list_point[1].x, list_point[2].x, list_point[1].y, list_point[2].y);
                            
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath(); 

                            $scope.image_angle = (fl_angle- sl_angle).toFixed(2);
                            $scope.vertical_angle = (90 - fl_angle).toFixed(2);

                        }
                    } 

                }
           
                canvas.addEventListener("mousemove", function (e) {
                    var pos = getMousePos(canvas, e);
                    findxy('move',pos)
                }, false);
                canvas.addEventListener("mousedown", function (e) {
                    var pos = getMousePos(canvas, e);
                    findxy('down', pos)
                }, false);
                canvas.addEventListener("mouseup", function (e) {
                    findxy('up', e)
                    console.log('angles', $scope.image_angle)
                    $scope.$apply();
                }, false);
                canvas.addEventListener("mouseout", function (e) {
                }, false);
                var base_image = new Image();
                base_image.src = img.src;
               
                if( base_image.height / base_image.width * canvas.width > canvas.height)
                {
                     // fill based on less image section loss if width matched
                  list_point = [];
                  var width = canvas.width;
                  var height = base_image.height / base_image.width * width;
                  var offset = (height - canvas.height) / 2;
                  ctx.beginPath();
                  ctx.drawImage(base_image, 0, -offset, img.width, img.height);
                  ctx.stroke(); // outline the shape that's been describeds
                  ctx.closePath();  
                  
                }
                else
                {
                  // fill based on less image section loss if height matched
                  list_point = [];
                  var height = canvas.height;
                  var width = base_image.width / base_image.height * height;
                  var offset = (width - canvas.width) / 2;
                  ctx.beginPath();
                  ctx.drawImage(base_image, -offset , 0, img.width, img.height);
                  ctx.stroke(); // outline the shape that's been describeds
                  ctx.closePath();  
                }
                
                $scope.image_angle = (fl_angle- sl_angle).toFixed(2);
                $scope.vertical_angle = (90 - fl_angle).toFixed(2);
            }



            _this.rollAngleDraw =  function () {
                if (_this.zoomActive) {
                    _this.zoomActive = !_this.zoomActive;
                }

                var img = document.getElementById('_img_selected');
                var canvas = document.getElementById("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, img.width, img.height);

                $scope.roll_angle = 0;
                var isMoving = false;
                var index = 0 


                var list_point = [];
                function getMousePos(canvas, evt) {
                    var rect = canvas.getBoundingClientRect();
                    return {
                      x: evt.clientX - rect.left,
                      y: evt.clientY - rect.top
                    };
                }
                function radians_to_degrees(radians)
                {
                    var pi = Math.PI;
                    return radians * (180/pi);
                }
                function angleOf(x1, x2, y1, y2) {
                    var deltaY = (y1 - y2);
                    var deltaX = (x2 - x1);
                    var result = radians_to_degrees(Math.atan2(deltaY, deltaX));
                    return result
                }
                function findxy(res, e) {
                    if (res == 'down') {

                        if (list_point.length == 2 ){

                            var closest = list_point.reduce(function(prev, curr) {
                                return (Math.sqrt(Math.pow((curr['x'] - e['x']),2) + Math.pow((curr['y'] - e['y']),2)) < Math.sqrt(Math.pow((prev['x'] - e['x']),2) + Math.pow((prev['y'] - e['y']),2))  ? curr : prev);
                            });

                            index = list_point.findIndex(p => p.x === closest.x && p.y === closest.y);
                            
                            list_point[index] = e;
                            isMoving = true;

                        return
                        }

                        if (list_point.length >1){
                            return
                        }
                        ctx.strokeStyle = "#00F";
                        ctx.beginPath();
                        ctx.arc(e.x, e.y, 5, 0, 2 * Math.PI, false);
                        ctx.fillStyle = 'green';
                        ctx.fill();
                        ctx.stroke(); // outline the shape that's been describeds
                        ctx.closePath();      
                        list_point.push(e);      
                        if (list_point.length == 2){
                            ctx.beginPath();
                            ctx.strokeStyle = "#00F"
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[1].y);
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath(); 
 
                            ctx.beginPath();
                            ctx.strokeStyle = "#F00"
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[0].y);
                            ctx.stroke(); // outline the shape that's been describeds

                            ctx.closePath();

                            $scope.roll_angle = angleOf(list_point[0].x, list_point[1].x, list_point[0].y, list_point[1].y).toFixed(2);
                        }       
                    }
                    if (res == 'up' && isMoving == true) {
                        console.log(index)
                        isMoving = false;
                    }

                    if (res == 'move' && isMoving == true) {
                        ctx.clearRect(0, 0, img.width, img.height);
                       
                        list_point[index] = e;
                        ctx.strokeStyle = "#00F";
                        for (var i = 0; i < list_point.length; i++) {
                            ctx.beginPath();
                            ctx.arc(list_point[i].x,list_point[i].y, 5, 0, 2 * Math.PI, false);
                            ctx.fillStyle = 'green';
                            ctx.fill();
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath();
                        }     
   
                        if (list_point.length == 2){
                            ctx.beginPath();
                            ctx.strokeStyle = "#00F"
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[1].y);
                            ctx.stroke(); // outline the shape that's been describeds
                            ctx.closePath(); 
 
                            ctx.beginPath();
                            ctx.strokeStyle = "#F00"
                            ctx.moveTo(list_point[0].x, list_point[0].y);
                            ctx.lineTo(list_point[1].x, list_point[0].y);
                            ctx.stroke(); // outline the shape that's been describeds

                            ctx.closePath();

                            $scope.roll_angle = angleOf(list_point[0].x, list_point[1].x, list_point[0].y, list_point[1].y).toFixed(2);
                        }
                    } 

                }
           
                canvas.addEventListener("mousemove", function (e) {
                    var pos = getMousePos(canvas, e);
                    findxy('move',pos)
                }, false);
                canvas.addEventListener("mousedown", function (e) {
                    var pos = getMousePos(canvas, e);
                    findxy('down', pos)
                }, false);
                canvas.addEventListener("mouseup", function (e) {
                    findxy('up', e)
                    console.log('angles', $scope.image_angle)
                    $scope.$apply();
                }, false);
                canvas.addEventListener("mouseout", function (e) {
                }, false);
                var base_image = new Image();
                base_image.src = img.src;
               
                if( base_image.height / base_image.width * canvas.width > canvas.height)
                {
                     // fill based on less image section loss if width matched
                  list_point = [];
                  var width = canvas.width;
                  var height = base_image.height / base_image.width * width;
                  var offset = (height - canvas.height) / 2;
                  ctx.beginPath();
                  ctx.drawImage(base_image, 0, -offset, img.width, img.height);
                  ctx.stroke(); // outline the shape that's been describeds
                  ctx.closePath();  
                  
                }
                else
                {
                  // fill based on less image section loss if height matched
                  list_point = [];
                  var height = canvas.height;
                  var width = base_image.width / base_image.height * height;
                  var offset = (width - canvas.width) / 2;
                  ctx.beginPath();
                  ctx.drawImage(base_image, -offset , 0, img.width, img.height);
                  ctx.stroke(); // outline the shape that's been describeds
                  ctx.closePath();  
                }
            }

//------------------------------------------------------------COMPASS VIEWER: begin--------------------------------------------------------------
        _this.switchCompassViewer = function(_isIFrame) {            
            if (_this.isAssetDataToolEnabled(_this.toolName.compassViewer)) {
                _this.viewShown = _this.viewFromAssetDataTool.changeTo(_this.views.compassViewer)

                if (_this.viewShown == _this.views.compassViewer) {
                    _this.cvObject = compassViewerFactory.newInstance({
                        scope: _this, 
                        asset: _asset, 
                        listAssetDataDetails:_this.listAssetDataDetailsNotFiltered, 
                        canvasId: _this.compassViewerCanvasId,
                        listAssetTypeKey: _this.listAssetTypeKey,
                        userId: _identity.id,
                        customerId: _customerId,
                        listAssetDataDetailsTagTypes: _this.listAssetDataDetailsTagTypes,
                        listAssetData: _this.listAssetData,
                    });    
                }

                if (!_isIFrame) {
                    //refresh the checks about what asset-data list's checkbox is visible or not
                    _this.setAssetDataViewConfig(null, true); 
                }
            }
        }

        //Manage the call of the editAsset function in the assetController from the assetDataController
        _this.editAssetFromMarker = function(_assetId, _companyId) {
            var _assetCtrl = Scopes.get("assetCtrl");

            _assetCtrl._assetCtrl.editAssetFromMarker(_assetId, _companyId);
        }

        //Manage the call of the editAssetClosed function in the assetController from the assetDataController
        _this.editAssetClosedFromMarkerCallback = function(_assetId, _companyId) {
            var _assetCtrl = Scopes.get("assetCtrl");
            
            //set the callback function when the edit asset popup is closed
            _assetCtrl._assetCtrl.editAssetClosedFromMarkerCallback(_assetId, _companyId, _res => {
                _this.cvObject.updateListAsset(_res);
                _this.cvObject.updateListAssetMarker(_res);
            });
        }
//------------------------------------------------------------COMPASS VIEWER: end----------------------------------------------------------------


        // _this.isshownReviewer = false
        _this.show3DReviewer = function() {
            var _application = 'EMPLOYEE';
            if (!_identity.isInspector) {
                _application = 'CUSTOMER';
            } 

            var _url;

            if (_identity.isInspector) {
                // _url = expanseConst.softeqUrl + 'iframe/model3d?facilityId='+_asset.publicId+'&application='+_application+'&environment='+expanseConst.viewer3dEnv+'&explorer=true&baseUrl='+expanseConst.softeqUrl
                //Temporary changing
                //The right url to call is the one commented
                //I'm call this other url, because it can show the side bar, when a point on the 3d-Reviewer has been selected
                _url = expanseConst.softeqUrl + 'facility/explore/'+_asset.publicId+"?withoutElem=page-header";
            } else {
                // _url = expanseConst.softeqUrl + 'customer/iframe/model3d?facilityId='+_asset.publicId+'&application='+_application+'&environment='+expanseConst.viewer3dEnv+'&explorer=true&baseUrl='+expanseConst.softeqUrl
                //Temporary changing
                //The right url to call is the one commented
                //I'm call this other url, because it can show the side bar, when a point on the 3d-Reviewer has been selected
                _url = expanseConst.softeqUrl + 'customer/facility/explore/'+_asset.publicId+"?withoutElem=page-header";
            }
            _this.reviewerUrl = $sce.trustAsResourceUrl(_url)

            _this.viewFromAssetDataTool.changeTo(_this.views.reviewer3d);
        }         

        _this.show3DCityscale = function() {
            _this.cityscaleUrl = $sce.trustAsResourceUrl('https://demo.f4map.com/#lat=51.5213621&lon=-0.1205359&zoom=16')
            _this.viewFromAssetDataTool.changeTo(_this.views.cityscale3d);
        }        

        _this.showTruView = function(_assetData) {
            // _this.truViewUrl = $sce.trustAsResourceUrl('https://hdsemea.truview-cloud.com/scan/6ee6ab62-c7e7-4f84-bad1-8584748a0db1')
            _this.truViewUrl = $sce.trustAsResourceUrl('https://insp2demo.truview-cloud.com/embed/map/'+_assetData.truviewSiteId+'?tools=true&minimap=false')
            
            _this.viewFromAssetDataTool.changeTo(_this.views.truView);
        }        

        _this.show3DUserNet = function() {
            _this.userNet3DUrl = $sce.trustAsResourceUrl('https://3dusernet.com/project_launch/view/FzWFyqnZMxsBndX')
            _this.viewFromAssetDataTool.changeTo(_this.views.userNet3D);
        }        


        // function _initDisplay360DegreesSplitScreen() {
        //     googlemaps.initGoogleMap("#"+_this.display360DegreesSplitScreenCanvasId, _m => {
        //         var _markers = [];
        //         _this.listAssetDataDetails.forEach(e => {

        //             const _marker = new google.maps.Marker({
        //                 map: _m,
        //                 position: new google.maps.LatLng(e.latitude, e.longitude),
        //                 icon: '/img/icons/markers/marker-photos.png',
        //                 title: e.fileName,
        //                 lat: e.latitude,
        //                 log: e.longitude,
        //             });
                    
        //             // //add a click listener on the marker
        //             // _marker.addListener('click', function () {
        //             // });
        
        //             _markers.push(_marker);
        //         })

        //         googlemaps.fitBounds(_m, _markers);
        //         $timeout(function() {
        //             _m.setCenter(new google.maps.LatLng(51.48536270807277, -0.12600202423033735));
        //             _m.setTilt(80);
        //         }, 300)

        //     })
        // }

        //Activate/Deactivate the "split" screen
        //The previous "view" is shown when the "split" screen is deactivate
        _this.showSplit = function() {
            if (_this.isAssetDataToolEnabled(_this.toolName.split)) {
                if (_this.viewShown == _this.views.image360 || _this.viewShown == _this.views.image360SplitScreen) {
                    _this.viewShown = _this.viewFromAssetDataTool.changeTo(_this.views.image360SplitScreen);
                } else {
                    _this.viewShown = _this.viewFromAssetDataTool.changeTo(_this.views.doubleImage);

                    if (_this.viewShown == _this.views.doubleImage) {
                        _this.keyListenerToArrowsInSingleImageScreen.add(_this.views.doubleImage) 
                    } else {
                        _this.keyListenerToArrowsInSingleImageScreen.remove(_this.views.doubleImage) 
                    }        
                } 
            }
        }   




        var assetMapperModelData = {}
        var assetMapperIFrameNative;

        _this.showAssetMapper = function() {
            _this.viewShown = _this.viewFromAssetDataTool.changeTo(_this.views.assetMapper);

            if (_this.viewShown == _this.views.assetMapper) {

                //remove old event listener
                window.removeEventListener('message', $scope.assetMapperMessageRef, false )    
                
                $scope.assetMapperMessageRef = _this.assetMapperMessage()

                //create new event listener
                window.addEventListener('message', $scope.assetMapperMessageRef)   

                $http.get(expanseConst.softeqUrl + "facility/info/"+_asset.publicId+"?liteToken=" + _identity.liteToken, {
                    withCredentials: true,
                    mode: 'cors',
                    contentType: 'text/plain',
                }
                ).success(res => {

                    assetMapperModelData = {
                        pathToAssetMapperModel: res.pathToAssetMapperModel,
                        latitude: res.latitude,
                        longtitude: res.longitude,
                        name: res.name,
                        publicId: res.publicId,
                        operationResult: res.operationResult,
                        assetType: res.assetType
                    }
                    
                    _this.getAssetMapperIframeUrl("#_asset-mapper");
                }).error(err => {
                    console.log(err)
                })
            }
        }    

        _this.getAssetMapperIframeUrl = function(_id) {
            var _element = "$('"+_id+"').get(0)"
            utility.callFunctionEveryTime(_element, 200, function() {

                $(_id).attr("src", $sce.trustAsResourceUrl(expanseConst.softeqUrl + "iframe/assetMapper?application=EMPLOYEE&amp;environment=QA&amp;baseUrl=https%3A%2F%2Fexpanseai.com"))
            })
        }
                

        _this.assetMapperMessage = function() {
            return function(event) {
                var data;

                try {
                    data = JSON.parse(event.data);
                } catch(error){}

                if (!data) {
                    return;
                }

    
                switch (data.command) {
                    case "initialise": 
                        myLog("initialise")
                        assetMapperIFrameNative = $('#_asset-mapper').get(0);
                        assetMapperIFrameNative.contentWindow.postMessage(
                            JSON.stringify({
                                command: 'initialise'
                            }),
                            '*'
                        );
                        break;
                    case "assetMapperModelData":
                        analyzeStore.setAssetMapperModelData(data.data);
                        break;
                    case "getAllMapData": 
                        myLog("getAllMapData")
                        assetMapperIFrameNative = $('#_asset-mapper').get(0);
                        assetMapperIFrameNative.contentWindow.postMessage(
                            JSON.stringify({
                                command: 'fillMapData',
                                asset: assetMapperModelData
                            }),
                            '*'
                        );
                        break;
                }
            }
        }        


        _this.createThermalImageryMap = function() {
            var _map;

            var _index = utility.getIndexArrayElementByField(_maps, 'id', _this.thermalImagesCanvasId);

            if (_index != undefined) {
                _maps.splice(_index, 1);
            }

            googlemaps.initGoogleMap("#"+_this.thermalImagesCanvasId, _m => {
                //push the just created google map into _maps array
                _maps.push({ id: _this.thermalImagesCanvasId, map: _m });
                _map = _m;

                _listThermalImageryMarker = [];

                if (_this.listThermalImage && _this.listThermalImage.length > 0) {
                    _putThermalImageryMarkersOnMap(_map, _this.listAssetDataDetails, _this.listThermalImage, _listThermalImageryMarker)
                } else if (_this.listAssetDataDetails && _this.listAssetDataDetails.length > 0) {
                    var _assetDataIds = [];
                    _this.listAssetDataDetails.forEach(e => {
                        if (!_assetDataIds[e.facilityDataId]) {
                            _assetDataIds[e.facilityDataId] = e.facilityDataId;
                        }
                    })

                    _assetDataIds.forEach(e => {
                        _getThermalImages(e, _res => {
                            _this.listThermalImage.push.apply(_this.listThermalImage, _res);

                            _putThermalImageryMarkersOnMap(_map, _this.listAssetDataDetails.filter(a => a.facilityDataId == e), _this.listThermalImage, _listThermalImageryMarker)
                        })
                    })
                }
            });    
        }


        function _putThermalImageryMarkersOnMap(_map, _listAssetDataDetails, _listThermalImage, _listThermalImageryMarker) {
            
            _listAssetDataDetails.forEach(e => {
                _listThermalImageryMarker.push(_createThermalImageryMarker(_map, e.latitude, e.longitude, e, utility.getArrayElementByField(_listThermalImage, 'facilityDataDetailsId', e.id)));
            })

            //Set out the map zoom based on the markers position
            googlemaps.fitBounds(_map, _listThermalImageryMarker);
        }

        function _createThermalImageryMarker(_map, _lat, _lng, _assetDataDetails, _thermal_image) {
            var _icon = "marker-";

            if (!_thermal_image) {
                _icon += "grey";
            } else {
                if (_thermal_image.hotSpot <= $scope.thermalImageryThreshold_l) {
                    _icon += "green";
                } 
                if (_thermal_image.hotSpot > $scope.thermalImageryThreshold_l && _thermal_image.hotSpot <= $scope.thermalImageryThreshold_u ) { 
                    _icon += "yellow";
                }
                if (_thermal_image.hotSpot > $scope.thermalImageryThreshold_u ) {
                    _icon += "red";
                }    
            }

            const _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                icon: '/img/icons/markers/' + _icon +".png",
                label: _thermal_image?''+_thermal_image.hotSpot+'°':'-',
                title: _assetDataDetails.fileName,
                lat: _lat,
                log: _lng,
                id: _assetDataDetails.id,
                degrees: _thermal_image?_thermal_image.hotSpot:"-",
                pathImg: ""+_this.buildSrcUrl(_assetDataDetails).mdUrl
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                //The popup is based on an external .html file
                var Data = {
                    'AssetDataDetails': _assetDataDetails,
                    'TemperatureT': $scope.thermalImageryThreshold_u
                }

                assetDataServices.GetThermalImage(Data, _res => {

                    _marker.pathImg = _this.expanseConst.cfAssetDataUrl + '/' + _res
                    googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/thermal-imagery_info.html');               
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

                // $http({
                //     method: 'POST',
                //     url: '/api/GetThermalImage',
                //     data: Data
                // }).then(function(response) {

                //     _marker.pathImg = _this.expanseConst.cfAssetDataUrl + '/' + response.data
                //     googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/thermal-imagery_info.html');

                // });

            });

            return _marker;
        }

        _this.showThermalImageryMarkerInfo = function(_assetDataDetails) {
            var _map = _maps[utility.getIndexArrayElementByField(_maps, 'id', _this.thermalImagesCanvasId)].map;
            var _marker = _listThermalImageryMarker[utility.getIndexArrayElementByField(_listThermalImageryMarker, 'id', _assetDataDetails.id)]

            //add a click listener on the marker
            
            //Create a popup when a marker is clicked on
            //The popup is based on an external .html file
            var Data = {
                'AssetDataDetails': _assetDataDetails,
                'TemperatureT': $scope.thermalImageryThreshold_u
            }

            assetDataServices.GetThermalImage(Data, _res => {

                _marker.pathImg = _this.expanseConst.cfAssetDataUrl + '/' + _res
                googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/thermal-imagery_info.html');               
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //     method: 'POST',
            //     url: '/api/GetThermalImage',
            //     data: Data
            // }).then(function(response) {

            //     _marker.pathImg = _this.expanseConst.cfAssetDataUrl + '/' + response.data
            //     googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/thermal-imagery_info.html');
            // });
        }


        _this.updateTheMethaneIconMarker = function(){

            var _map = appFactory.getMapById(_maps, _this.assetDataMethaneCanvasId)          
            var list_marker =  _this.listMarkersOnAssetDataMethaneMap;  
            _this.listMarkersOnAssetDataMethaneMap =  assetDataMethaneFactory.removeMarkersDynamically(_map, list_marker, _this.listAssetDataDetails, _this.listAssetDataSelectd, $scope.slider_methane.min, $scope.slider_methane.max, $scope.methaneAnomalyViz.cb1);

        }


        _this.updateThermalImageryIconMarker = function() {
            console.log($scope.thermalImageryThreshold_u )

            $scope.thermalImageryThreshold_u = $scope.slider.max;
            $scope.thermalImageryThreshold_l = $scope.slider.min;

            _listThermalImageryMarker.forEach(e => {
                var _icon = "marker-";

                if (e.degrees <= $scope.thermalImageryThreshold_l) {
                    _icon += "green";
                } 
                if (e.degrees > $scope.thermalImageryThreshold_l && e.degrees <= $scope.thermalImageryThreshold_u ) { 
                    _icon += "yellow";
                }
                if (e.degrees > $scope.thermalImageryThreshold_u ) {
                    _icon += "red";
                }    

                e.setIcon('/img/icons/markers/' + _icon +".png")
            })
        }

        _this.showAssetDataFromDashboardMarker = function(_assetId, _customerId) {
            var _headerCtrl = Scopes.get("headerCtrl");

            _headerCtrl.setAssetId(_assetId);
            _headerCtrl.setCustomerId(_customerId);
            _headerCtrl.goAssetData();
        }

        //=========================================== Spin Model begin========================================================
        // _this.getAssetDataDetails = function (_assetDataId, _success, _error) {
        //     assetDataServices.getAssetDataDetails(_assetDataId, _res => {
        //         _success(_res);
                
        //         myLog("AssetDataDetails list:", _res);
        //     }, _err => {
        //         _error(_err);
        //         myAlert("Error: " + _err.data.message, 'danger');
        //         myThrow(_err);
        //     });
        // }


        // _this.SpinModelSelection = function (_position, _listAssetData) {
            
        //     //SPIN MODEL CODE FOR ASSET REVIEW SCREEN
        //     _this.listAssetDataDetails =  []
        //     if(_position == 'All'){
        //         for (var k = 0; k < _listAssetData.length; k++) {
        //             if(_listAssetData[k].isSelected){
        //                 _this.setAssetDataViewConfig(_listAssetData[k], true);
        //                 // _this.showAssetData(_listAssetData[k])
        //                 _this.listAssetDataDetails = [];
        //                 _this.getAssetDataDetails(_listAssetData[k].id, _res => {
        //                     _res.forEach(e => e.isSelected = true);
        //                     _this.listAssetDataDetails.push.apply(_this.listAssetDataDetails, _res)
        //                     _updateImgIndex(_this.imgIndex, _this.listAssetDataDetails)
        //                     _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
        //                 }) 


        //             }
        //         }
        //     }
        //     else{
        //         var _list_facility_id = []


        //         for (var k = 0; k < _listAssetData.length; k++) {
        //             if(_listAssetData[k].isSelected){
        //                 _list_facility_id.push(parseInt(_listAssetData[k].id));
        //                 _this.getAssetDataDetails(_listAssetData[k].id, _res => {
        //                     _res.forEach(e => e.isSelected = true);
        //                     _this.listAssetDataDetails.push.apply(_this.listAssetDataDetails, _res);
        //                 });
        //             }
        //         }

        //         var _data = {
        //             'facilityDataId': _list_facility_id,
        //             'facilityTypeId':  _asset.facilityTypeId, 
        //             'spin_model_value': _position
        //         }

        //         assetDataServices.getSpinModelClustering(_data, _res => {

        //             console.log(_this.listAssetDataDetails)
        //             console.log('done',_res)
        //             var updated_list = [];
        //             for (var k = 0; k < _this.listAssetDataDetails.length; k++) {
        //                 if (_res.indexOf(_this.listAssetDataDetails[k].id) >  -1){
        //                     console.log('present') 
        //                     updated_list.push(_this.listAssetDataDetails[k]);
        //                 }
        //             } 
        //             _this.listAssetDataDetails = updated_list;

        //         }, _err => {
        //             myAlert("Error: " + _err.data.message, 'danger');
        //             myThrow(_err); 
        //         })

        //     }
        // }




        //=========================================== SPIN MODEL end==========================================================

        //=========================================== TILT ANGLE begin========================================================


        _this.initTiltAngle = function() {
            var _facilityDataDetailsId = $stateParams['fdd_id'];

            tiltAngleFactory.getTitlAngleImageUrl(_facilityDataDetailsId, _res => {
                this.titlAngleImageUrl = $sce.trustAsResourceUrl(_res)
            });
        }

        _this.draw_image =  function () {
            $scope.image_angle = 0;

            if (_this.tiltAngleTriggered){
                tiltAngleFactory.clearScreen('_img_selected', 'canvas', _this.tiltAngleTriggered);
                _this.tiltAngleTriggered = false;
            }
            else{

                _this.tiltAngleTriggered = true;

                //deatcivate the zoom of the canvas
                if (_this.zoomActive) {
                    _this.zoomActive = !_this.zoomAsctive;
                }

                // update the image_angle variable
                tiltAngleFactory.analyseImage('_img_selected', 'canvas');
                $scope.$on('tilt-angle-changed', function(){
                    $scope.image_angle = tiltAngleFactory.data.list;
                    $scope.$apply();
                })      
            }    

        }

        _this.saveTiltAngle = function(){
            var _facilityDataDetailsId = _this.assetDataDetailsSelected.id;
            myAlert("Saving Tilt Angle Image...", 'info'); 

            tiltAngleFactory.saveTiltAngleImage(_facilityDataDetailsId, _res => {
                _assetDataResetAndRefresh();     
            });   
        }


        //=========================================== TILT ANGLE end==========================================================
        

        //=========================================== FINGERPRINTING start==========================================================
        var progressBarFinger;
        var temp_image = '';
        var ii = 0;
        $scope.listThumbFing = [];

        _this.finger_counter = 0;

        _this.getPreviousImage_finger = function() {
            return function (_popup) {
                _popup.data.finger_counter -= 1;
                if (_popup.data.finger_counter < 0) {
                    _popup.data.finger_counter = _popup.data.listThumbFing.length - 1;
                }
                _popup.updateData(_popup.data);
            }
        }

        _this.getNextImage_finger = function() {
            return function (_popup) {
                _popup.data.finger_counter += 1;
                if (_popup.data.finger_counter > _popup.data.listThumbFing.length - 1) {
                    _popup.data.finger_counter = 0;
                }
                _popup.updateData(_popup.data);
            }
        }

        $scope.reset_finger_dataset = function(_popup) {
                //reset the storage container of FP process
                _popup.data.listThumbFing = [];
                _popup.data.imgFingerSelected = [];
        }

        $scope.include_images_dataset_db_update = function(_popup) {
            _popup.data.listThumbFing_selected = [];

                for (var i = 0; i < _popup.data.listThumbFing.length; i++) {
                    if (_popup.data.listThumbFing[i].img_selected && _popup.data.listThumbFing[i].img_selected == true) {

                        _popup.data.listThumbFing_selected.push(_popup.data.listThumbFing[i].img_fing_name);
                    }
                }
                var Data = {
                    Ref_image: _this.assetDataDetailsSelected.fileName,
                    Ref_fac_data: _this.assetDataDetailsSelected.facilityDataId,
                    List_images_included: _popup.data.listThumbFing_selected,
                    userId: _identity.id,
                }

                assetDataServices.Fingerprinting_update_db(Data, _res => {
                    console.log('positive')
                    _popup.data.listThumbFing = [];

                    _popup.data.update_tag();
                    _popup.data.listThumb = [];
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

                // $http({
                //     method: 'POST',
                //     url: '/api/Fingerprinting_update_db',
                //     data: Data
                // }).then(function(response) {
                //     console.log('positive')
                //     _popup.data.listThumbFing = [];

                //     _popup.data.update_tag();
                //     _popup.data.listThumb = [];
                // }, function(error) {
                //     console.log(error);
                // });
        }

        $scope.include_images_dataset = function(_popup) {
                //include the selected images into the current dataset of imagse
                _popup.data.thumb_image_show = 1;

                $scope.include_images_dataset_db_update(_popup);
        }


        _this.finger_popup = function() {
            //Functin to show the popup of the Fingerpringitn algo
            _this.listThumbFing_selected = [];

            var _uploadData ={
                imgFingerSelected: _this.imgFingerSelected,
                listThumbFing: $scope.listThumbFing,
                finger_phase: _this.finger_phase = '1',
                finger_counter: _this.finger_counter
            }

            var _fns = [
                { 'btn_msg': 'Include in the Data Set', 'btn_color': 'btn-primary', 'btn_fn': 'include_images_dataset' },
                { 'btn_msg': 'Cancel', 'btn_color': 'btn-primary', 'btn_fn': 'reset_finger_dataset' }
            ];
            

            var _config = {
                'funzione': {
                    initFingerPopupData: _this.initFingerPopupData(),
                    finger_db_retrieval: _this.finger_db_retrieval(),
                    fingerprinting: _this.fingerprinting(),
                    retrieve_single_finger_spinning: _this.retrieve_single_finger_spinning(),
                    finger_thumb_creation: _this.finger_thumb_creation(),
                    retrieve_single_image_f: _this.retrieve_single_image_f(),
                    updateListThumb_finger: _this.updateListThumb_finger(),
                    getPreviousImage_finger: _this.getPreviousImage_finger(),
                    getNextImage_finger: _this.getNextImage_finger(),
                },
                'size': 'xlg',
                'title': 'Generate Fingerprinting',
                'ctrl': _controllerName,
                'fns': JSON.stringify(_fns),
                'data': JSON.stringify(_uploadData),
                'htmlPage': 'views/asset-data/popup/asset-data-fingerprinting-sending-popup.html'
            }

            popup.openModalBody(_config);
        }

        _this.fingerprinting_gps = function() {
            _this.finger_popup();
        }



        _this.initFingerPopupData = function () {
            return function (_popup) {

                $scope.closet_images = [];
                $scope.counting_fingerprinting = 0;
                //Functionto extract the subdataset of possible closest images according to GPS
                var Data = {
                    AssetData: _this.assetDataDetailsSelected.facilityDataId,
                    AssetDataDetails: _this.assetDataDetailsSelected.id,
                    FileName: _this.assetDataDetailsSelected.fileName
                }

                assetDataServices.Fingerprinting_gps(Data, _res => {
                    _popup.data.finger_phase = 2;
                    _popup.data.sub_dataset = _res.sub_dataset;
                    _popup.data.finger_db = _res.finger_db;
                    _popup.data.full_dataset = _res.size_dataset;
                    _popup.updateData(_popup.data); 

                    _popup.funzione.finger_db_retrieval(_popup);
                    _popup.funzione.fingerprinting(_popup);
                    _popup.updateData(_popup.data);
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

                // $http({
                //     method: 'POST',
                //     url: '/api/Fingerprinting_gps',
                //     data: Data
                // }).then(function(response) {
                //     _popup.data.finger_phase = 2;
                //     _popup.data.sub_dataset = response.data.sub_dataset;
                //     _popup.data.finger_db = response.data.finger_db;
                //     _popup.data.full_dataset = response.data.size_dataset;
                //     _popup.updateData(_popup.data); 

                //     _popup.funzione.finger_db_retrieval(_popup);
                //     _popup.funzione.fingerprinting(_popup);
                //     _popup.updateData(_popup.data);
    
                // }, function(error) {
                //     console.log(error);
                //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                //     popup.openModal('sm', 'Errors warning', error.data, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
                // });

   
            }
        }

        
        _this.finger_db_retrieval = function() {
            return function (_popup) {
                //Function to monitor the current progress of Fingerpringint algorithm 
                var Data = {
                    Finger_id: _popup.data.finger_db
                }

                assetDataServices.get_straming_fp(Data, _res => {
                    var finger_resp = _res.i_name;
                    var image_proc_path = _res.image_proc_path;

                    _popup.data.image_name_finger = finger_resp;
                    if (finger_resp != '') {
                        if (temp_image != image_proc_path) {
                            _popup.funzione.retrieve_single_finger_spinning(_popup, image_proc_path);
                            _popup.data.counting_fingerprinting++;
                        }

                        $timeout(function() {
                            var progress = 100 - (( _popup.data.sub_dataset.length - (_popup.data.sub_dataset.indexOf(image_proc_path) + 1)) * 100 /  _popup.data.sub_dataset.length);
                            progressBarFinger.style.width = progress + '%';
                            console.log(progress)
                            _popup.funzione.finger_db_retrieval(_popup);
                            _popup.data.temp_image = image_proc_path;
                            _popup.updateData(_popup.data);

                        }, 1000);

                    } else {
                        delete _this.imgFingerSelected;
                        $("#finger_image").remove();
                        $("#myProgress2").remove();
                    }
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })


                // $http({
                //     method: 'POST',
                //     url: '/api/get_straming_fp',
                //     data: Data
                // }).then(function(response) {

                //         var finger_resp = response.data.i_name;
                //         var image_proc_path = response.data.image_proc_path;

                //         _popup.data.image_name_finger = finger_resp;
                //         if (finger_resp != '') {
                //             if (temp_image != image_proc_path) {
                //                 _popup.funzione.retrieve_single_finger_spinning(_popup, image_proc_path);
                //                 _popup.data.counting_fingerprinting++;
                //             }

                //             $timeout(function() {
                //                 var progress = 100 - (( _popup.data.sub_dataset.length - (_popup.data.sub_dataset.indexOf(image_proc_path) + 1)) * 100 /  _popup.data.sub_dataset.length);
                //                 progressBarFinger.style.width = progress + '%';
                //                 console.log(progress)
                //                 _popup.funzione.finger_db_retrieval(_popup);
                //                 _popup.data.temp_image = image_proc_path;
                //                 _popup.updateData(_popup.data);

                //             }, 1000);

                //         } else {
                //             delete _this.imgFingerSelected;
                //             $("#finger_image").remove();
                //             $("#myProgress2").remove();
                //         }
                //     },
                //     function(error) {
                //         console.log(error);
                //     });
                }
        }


        
        _this.retrieve_single_finger_spinning = function() {
            //Function to show the images currently analyzed
            return function (_popup, image_name) {
                var Data = { key_image: image_name }

                assetDataServices.retrieve_single_image_finger(Data, _res => {
                    _popup.data.count_images = 1;
                    var image = new Image();
                    image.src = 'data:image/jpg;base64,' + _res.image_str;
                    _popup.data.imgFingerSelected = image.src;
                    _popup.updateData(_popup.data);
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

                // $http({
                //         method: 'post',
                //         url: '/api/retrieve_single_image_finger',
                //         data: Data
                //     }

                // ).then(function(response) {
                //     _popup.data.count_images = 1;
                //     var image = new Image();
                //     image.src = 'data:image/jpg;base64,' + response.data.image_str;
                //     _popup.data.imgFingerSelected = image.src;
                //     _popup.updateData(_popup.data);
                // }, function(error) {
                //     console.log(error);
                // });
            }
        }

        _this.fingerprinting = function() {
            return function (_popup) {
                //Function to call the actual FIgerprinting API
                var Data = {
                    AssetDataDetails: _this.assetDataDetailsSelected.id,
                    subset: _popup.data.sub_dataset,
                    finger_db:_popup.data.finger_db
                }

                assetDataServices.Fingerprinting(Data, _res => {
                    _popup.data.closet_images = _res.closet_images;
                    _popup.data.counting_fingerprinting = _popup.data.sub_dataset.length;
                    _popup.updateData(_popup.data);
                    _popup.funzione.finger_thumb_creation(_popup);
                    delete _popup.data.imgFingerSelected;
                    _popup.updateData(_popup.data);
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

                // $http({
                //     method: 'POST',
                //     url: '/api/Fingerprinting',
                //     data: Data
                // }).then(function(response) {
                //     _popup.data.closet_images = response.data.closet_images;
                //     _popup.data.counting_fingerprinting = _popup.data.sub_dataset.length;
                //     _popup.updateData(_popup.data);
                //     _popup.funzione.finger_thumb_creation(_popup);
                //     delete _popup.data.imgFingerSelected;
                //     _popup.updateData(_popup.data);
                // }, function(error) {

                //     console.log(error);
                //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                //     fsMessage.openModal('sm', 'Errors warning', error, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
                // });
            }
        }

        _this.updateListThumb_finger = function(){
            return function (_popup, _name, _img) {
                _popup.data.listThumbFing.forEach(e => {
                    if (e.img_fing_name == _name) {
                        e.img_fing_src = _img;
                    }
                })

                _popup.updateData(_popup.data);
            }
        }

        _this.finger_thumb_creation = function() {
            //Functin to create the Fingerprinting Thumbnail
            return function (_popup) {
                _popup.data.finger_counter = 0;
                _popup.data.listThumbFing = [];
                _popup.data.finger_phase = 3;
                ii = 0;

                _popup.updateData(_popup.data);

                for (var i = 0; i < _popup.data.closet_images.length; i++) {
                    var _thumb = {};
                    var closest = _popup.data.closet_images[i];
                    _thumb.img_fing_name = closest[0];
                    _thumb.img_fing_date = closest[2];
                    _thumb.id = i;
                    _thumb.value = 'false';
                    _popup.data.listThumbFing.push(_thumb);
                    _popup.funzione.retrieve_single_image_f(_popup, closest[0], function(name, image) {
                        _popup.funzione.updateListThumb_finger(_popup, name, image);
                    })
                    _popup.updateData(_popup.data);

                }
            }
        }

        _this.retrieve_single_image_f = function() {
            return function (_popup, image_name, _function) {
                var Data = { 
                    key_image: image_name
                }

                assetDataServices.retrieve_single_image_finger(Data, _res => {
                    _popup.data.count_images = 1;
                    _popup.updateData(_popup.data);
                    var image = new Image();
                    image.src = 'data:image/jpg;base64,' + _res.image_str;

                    _function(_res.name, image.src)
                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })

            //     $http({
            //             method: 'post',
            //             url: '/api/retrieve_single_image_finger',
            //             data: Data
            //         }

            //     // ).then(function(response) {
            //     //     _popup.data.count_images = 1;
            //     //     _popup.updateData(_popup.data);
            //     //     var image = new Image();
            //     //     image.src = 'data:image/jpg;base64,' + response.data.image_str;

            //     //     _function(response.data.name, image.src)


            //     // }, function(error) {
            //     //     console.log(error);
            //     // });
            }
        }


        //=========================================== FINGERPRINTING END==========================================================


        //=========================================== CABELE LABLE START==========================================================


        _this.cable_label_detector = function () {

            var Data = {
                AssetData: _this.assetDataDetailsSelected.facilityDataId,
                AssetDataDetails: _this.assetDataDetailsSelected.id,
                FileName: _this.assetDataDetailsSelected.fileName
            }

            assetDataServices.saveTiltAngleImage(Data, _res => {
                var image = new Image();
                image.src = 'data:image/jpg;base64,' + _res.image_str;

                    var _uploadData ={
                        lable_src: image.src,
                        labels: _res.labels,
                        type_of_cable: _res.type_of_cable
                    }                

                    var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                    
                    var _config = {
                        'size': 'xlg',
                        'title': 'Label Detector',
                        'ctrl': _controllerName,
                        'fns': JSON.stringify(_fns),
                        'data': JSON.stringify(_uploadData),
                        'htmlPage': 'views/asset-data/popup/asset-data_color_label.html'
                    }

                    popup.openModalBody(_config);
                    myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //     method: 'POST',
            //     url: '/api/cableLabelDetector',
            //     data: Data
            // }).then(function(response) {
            //    console.log(response)
                
            //    var image = new Image();
            //    image.src = 'data:image/jpg;base64,' + response.data.image_str;

            //     var _uploadData ={
            //         lable_src: image.src,
            //         labels: response.data.labels,
            //         type_of_cable: response.data.type_of_cable
            //     }                

            //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                
            //     var _config = {
            //         'size': 'xlg',
            //         'title': 'Label Detector',
            //         'ctrl': _controllerName,
            //         'fns': JSON.stringify(_fns),
            //         'data': JSON.stringify(_uploadData),
            //         'htmlPage': 'views/asset-data/popup/asset-data_color_label.html'
            //     }

            //     popup.openModalBody(_config);

            // }, function(error) {
            //     console.log(error);
            //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            //     popup.openModal('sm', 'Errors warning', error.data, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
            // });

         }

        //=========================================== CABELE LABLE END==========================================================


        //=========================================== LABEL ANALYSIS START==========================================================
        _this.cancelLabelViz = function () {
            _this.labelViz = false;
        }      

        _this.labelAnalysis = function () {

            _this.labelViz = true;
            myAlert("Processing Started", 'info');    


            
            var Data = {
                AssetData: _this.assetDataDetailsSelected.facilityDataId,
                ListAssetDataDetails: _this.assetDataDetailsSelected,
            }

            assetDataServices.labelAnalysis(Data, _res => {
                
                _this.labelList = _res.param_list
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //     method: 'POST',
            //     url: '/api/labelAnalysis',
            //     data: Data
            // }).then(function(response) {
            //    console.log(response)

            //    _this.labelList = response.data.param_list


            //    myAlert("Processing Complete", 'info');    
                

            // }, function(error) {
            //     console.log(error);
            //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            //     popup.openModal('sm', 'Errors warning', error.data, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
            // });

         }
        //=========================================== LABEL ANALYSIS END==========================================================




        //=========================================== HUMAN EYE START==========================================================

        _this.humanEye = function () {

            _this.humanEyeViz = true;
            myAlert("Processing Started", 'info');    

            var Data = {
                AssetData: _this.assetDataDetailsSelected.facilityDataId,
                ListAssetDataDetails: _this.assetDataDetailsSelected,
            }

            assetDataServices.humanEye(Data, _res => {
                
                _this.humanEyeList = _res.param_list
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
            // $http({
            //     method: 'POST',
            //     url: '/api/humanEye',
            //     data: Data
            // }).then(function(response) {
            //    console.log(response)

            //    _this.humanEyeList = response.data.param_list


            //    myAlert("Processing Complete", 'info');    
                

            // }, function(error) {
            //     console.log(error);
            //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            //     popup.openModal('sm', 'Errors warning', error.data, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
            // });

         }


        //=========================================== HUMAN EYE END==========================================================


        //=========================================== autoInventoryB START==========================================================
        _this.autoInventoryBAnalysis = function () {

            myAlert("Processing Started", 'info');    

            var Data = {
                FacilityDataDetailsId: _this.assetDataDetailsSelected.id,
                AssetData:_this.assetDataDetailsSelected.facilityDataId

            }

            assetDataServices.autoInventoryB(Data, _res => {
                const blob = new Blob([_res], {type: 'text/csv'})
                var idx_filename = response.headers()["content-disposition"].indexOf("filename=") +10;
                var fileName = response.headers()["content-disposition"].slice(idx_filename).slice(0,-1)
                saveAs(blob, fileName);
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //     method: 'POST',
            //     url: '/api/autoInventoryB',
            //     data: Data
            // }).then(function(response) {

            //     //save the returned csv into something to download for the users
            //     const blob = new Blob([response.data], {type: 'text/csv'})
            //     var idx_filename = response.headers()["content-disposition"].indexOf("filename=") +10;
            //     var fileName = response.headers()["content-disposition"].slice(idx_filename).slice(0,-1)
            //     saveAs(blob, fileName);

            // }, function(error) {
            //     console.log(error);
            //     var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            //     popup.openModal('sm', 'Errors warning', error.data, 'customerWorkflowMainCtrl', JSON.stringify(_fns));
            // });

         }
        //=========================================== autoInventoryB END==========================================================



        //=========================================== OBJ TRACKING START==========================================================
        _this.ObjectTrakingPopUp = function(){

            var Data = {
                FacilityDataDetailsId: _this.assetDataDetailsSelected.id,
            }

            assetServices.objTrackingGetComponent(_this.assetDataDetailsSelected.id, _res => {
                var _uploadData ={
                    componentList: _res.ComponentList,
                    selected: ''
                }                

                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_close': false, 'btn_msg': 'Run', 'btn_color': 'btn-light', 'btn_fn': 'objTracking'},
                    { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true,  'btn_msg': 'Close', 'btn_color': 'btn-light' },
                ];
                
                var _config = {
                    size: 'lg',
                    title: 'Object Tracking',
                    ctrl: _controllerName,
                    fns: JSON.stringify(_fns),
                    data: JSON.stringify(_uploadData),
                    htmlPage: 'views/asset-data/popup/asset-data_objTracking.html'
                }

                popup.openModalBody(_config);

            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })

        }

        $scope.objTracking = function (_popup) {

            myAlert("Object Traacking Processing Started", 'info');    

            assetServices.objTracking(_this.assetDataDetailsSelected.facilityDataId, _this.assetDataDetailsSelected.id, _popup.data.selected, _res => {
                myAlert("Processing Complete", 'info');
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })
         }


        //=========================================== OBJ TRACKING END==========================================================


        //=========================================== EXPORT ASSET/INVENTORY START ==========================================================

        _this.exportPredictedStatistics = function () {

            //Export  Asset Function
            myAlert("Exporting Asset Process Started", 'info');    

            var Data = {'assetId': _this.asset.id, 'companyId':  _this.asset.companyId}


            assetDataServices.exportPredictedStatistics(Data, _res => {
                const blob = new Blob([_res], {type: "application/zip"})
                var fileName = response.headers()['filename'];
                saveAs(blob, fileName);   
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })


            // $http({
            //         method: 'post',
            //         url: '/api/exportPredictedStatistics',
            //         data: Data,
            //         responseType: 'arraybuffer'                   
            //     }

            // ).then(function(response) {
                
            //     const blob = new Blob([response.data], {type: "application/zip"})
            //     var fileName = response.headers()['filename'];
            //     saveAs(blob, fileName);
             
            // }, function(error) {
            //     console.log(error);
            // })
         }


         const showLoading = function(message) {

            Swal.fire({
                title: message,
                html: `
                <img src="/img/icons/ajax-loading-icon-2.jpg" width="64" />
                <button type="button" class="btn btn-cancel swl-cstm-btn-cancel" style="color: #0093ee; border: 1px solid #0093ee; background-color: #fff" >Cancel</button>`,
                showCancelButton: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                  const cancel = document.querySelector('.btn-cancel')
                  cancel.addEventListener('click', () => {
                      Swal.close();
                  })
                }
             })
        };
        
        _this.exportAsset = function () {
            
            if (_this.listAssetDataSelectd.length < 2){ 
                showLoading('Please Wait - Processing Download');
                //Export  Asset Function            
                var assSelected = []
                for (var i = 0; i < _this.listAssetDataSelectd.length; i++) {
                    assSelected.push(_this.listAssetDataSelectd[i].id)
                }
                    
                var Data = {'assetId': assSelected, 'companyId':  _this.asset.companyId}
                

                assetDataServices.exportDataset(Data, _res => {
                
                    var downUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+_res.location_name)
                    $window.open(downUrl, '_blank');                    
                    swal.close()

                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })
            }
            else{
                myAlert("Please select 1 Asset Data only", 'info');  
            }
         }

         _this.exportAssetAnalyzed = function () {
            
            if (_this.listAssetDataSelectd.length < 2){ 
                showLoading('Please Wait - Processing Download');
                    
                var Data = {'assetId': _this.asset.id, 'companyId':  _this.asset.companyId}            

                assetDataServices.exportDatasetAnalyzed(Data, _res => {
                
                    var downUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+_res.location_name)
                    $window.open(downUrl, '_blank');                    
                    swal.close()

                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })
            }
            else{
                myAlert("Please select 1 Asset Data only", 'info');  
            }
         }

         _this.exportAssetDropbox = function () {
            
            if (_this.listAssetDataSelectd.length < 2){ 
                showLoading('Please Wait - Processing Download');
                    
                var Data = {'assetId': _this.asset.id, 'companyId':  _this.asset.companyId}            

                assetDataServices.exportDatasetAnalyzedDropBox(Data, _res => {
                
                    // var downUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+_res.location_name)
                    // $window.open(downUrl, '_blank');                    
                    swal.close()

                    myAlert("Processing Complete", 'info');                    
                }, _err => {
                    myAlert("Error: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })
            }
            else{
                myAlert("Please select 1 Asset Data only", 'info');  
            }
         }

         _this.exportInventory = function () {

            // Function to Export the Inventory of an Assets
            myAlert("Exporting Inventory Process Started", 'info');    

            var Data = {'assetId': _this.asset.id, 'companyId':  _this.asset.companyId}

            assetDataServices.exportInventory(Data, _res => {
                const blob = new Blob([_res], {type: 'text/csv'})
                var idx_filename = response.headers()["content-disposition"].indexOf("filename=") +10;
                var fileName = response.headers()["content-disposition"].slice(idx_filename).slice(0,-1)
                saveAs(blob, fileName);         
                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //         method: 'post',
            //         url: '/api/exportInventory',
            //         data: Data
            //         // headers: {
            //         //     'Authorization': 'Basic ' + base64encodedData
            //         // }              
            //     }

            // ).then(function(response) {
                
            //     const blob = new Blob([response.data], {type: 'text/csv'})
            //     var idx_filename = response.headers()["content-disposition"].indexOf("filename=") +10;
            //     var fileName = response.headers()["content-disposition"].slice(idx_filename).slice(0,-1)
            //     saveAs(blob, fileName);
             
            // }, function(error) {
            //     console.log(error);
            // })
         }

         $scope.changeDetection = function (_popup) {


            _popup.setInnerStatus('process_status', 'results')
            // _popup.data.lable_src = undefined;
            _popup.data.running = true;
            
            var Data = {
                urlImage1: _popup.data.beforeUrl,// PUT HERE URL FIRST IMAGE, 
                urlImage2: _popup.data.afterUrl,// PUT HERE URL SECOND IMAGE,
                _threshold: $scope.thresholdChanges
            }

            assetDataServices.imageChangeDetection(Data, _res => {
                _popup.data.running = false;

                var image = new Image();
                image.src = 'data:image/jpg;base64,' + _res.image_str;
                _popup.data.lable_src= image.src;

                myAlert("Processing Complete", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })

            // $http({
            //         method: 'post',
            //         url: '/api/imageChangeDetection',
            //         data: Data               
            //     }
            // ).then(function(response) {
                
            //     _popup.data.running = false;

            //     var image = new Image();
            //     image.src = 'data:image/jpg;base64,' + response.data.image_str;

            //     _popup.data.lable_src= image.src;
                
            // }, function(error) {
            //     console.log(error);
            // })
         }

         $scope.thresholdChanges = 50;
         _this.updateThresholdChanges = function() {
             return function (_threshold) {
                 $scope.thresholdChanges = _threshold;
             }
         }

        _this.visualComparison = function () {
            var _beforeUrl = $sce.valueOf(_this.buildSrcUrl(JSON.parse(_this.beforeSelected)).mdUrl);
            var _afterUrl = $sce.valueOf(_this.buildSrcUrl(JSON.parse(_this.afterSelected)).mdUrl);

            var _Data = {
                _threshold: 50,
                beforeUrl: _beforeUrl.substring(_beforeUrl.indexOf("lite/")+4,_beforeUrl.length),
                afterUrl: _afterUrl.substring(_afterUrl.indexOf("lite/")+4,_afterUrl.length),
            }
            
            var _fns = [
                { 'btn_identifier': '_1_1', 'btn_close': false, 'btn_msg': 'Run', 'btn_color': 'btn-light', 'btn_fn': 'changeDetection'},
                { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true,  'btn_msg': 'Close', 'btn_color': 'btn-light' },
            ];
            
            var _config = {
                funzione: {
                    updateThresholdChanges: _this.updateThresholdChanges(),
                },
                size: 'lg',
                title: 'Object Tracking',
                ctrl: _controllerName,
                fns: JSON.stringify(_fns),
                data: JSON.stringify(_Data),
                htmlPage: 'views/asset-data/popup/asset-data_change-detection.html',
                innerStatus: {
                    process_status:'threshold',
                }
            }

            popup.openModalBody(_config);
         }


        //=========================================== EXPORT ASSET/INVENTORY END==========================================================



        //===========================================AI FILTER START ==========================================================

        // helper method
        _this.retriveTagSingle = function(tag){

            var compoCount;
            if (tag.tagType == 'component'){
                
                compoCount = _this.currentCompoFddTagsAIFilter.filter(element => element.component_type_id == tag.key)   
                if (_this.viewShown == 'aiassistedlabel'){
                    compoCount = compoCount.filter(element => element.status == 0)
                }

                if (_this.viewShown == 'aifilter'){
                    compoCount = compoCount.filter(element => element.status == 1)
                }

            }
            else{
                compoCount = _this.currentAnoFddTags.filter(element => element.anomaly_type_key == tag.key)

                if (_this.viewShown == 'aiassistedlabel'){
                    compoCount = compoCount.filter(element => element.status == 0)
                }

                if (_this.viewShown == 'aifilter'){
                    compoCount = compoCount.filter(element => element.status == 1)
                }
            }

            // var compoCount = 0;

            // console.log(this.assetDataDetailsSelected.id)
            // _this.listAssetDataDetailsNotFiltered.forEach(t => {
            //     if (t.id == this.assetDataDetailsSelected.id){
            //         t.listTags.forEach(s => {
            //             if (s.tagKey == tag){
            //                 compoCount ++;
            //             }
            //         })  
            //     }                                      
            // })
            return compoCount.length            

        }

        _this.aiFilter = function () {

            _this.wheelZoomRef = undefined;
            _this.aiFilterImageNamePrev = undefined;
            _this.tagSelected = []

            // utility.callFunctionEveryTime("$('#_ai_filter').get(0)", 300, function() {                
            //     utility.callFunctionEveryTime("$('#_img_selected').get(0)", 300, function() {
            // _this.showImg(_this.listAssetDataDetails[_this.imgIndex]);
            //     })
            // });

            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags(); 
            aiBuilderFactory.clearScreen('_img_selected', 'canvas', true);

            _this.listTagsIntoAssetDataDetails.forEach((t, index) => {
                // if (t.key != 'none'){ 
                t.isSelected = false;
                t.color = _this.listColors[index]
                // }
            })

            _this.retrieveTags(_this.listAssetDataDetails[_this.imgIndex].id)
            _this.getAssetStats()

            // Get the list of company's components runnable by AI Machine
            //If the company doesn't have any, I'll get the same about SKY-FUTURES
            assetDataServices.getAiComponentByFacilityByCompany(this.customer.id, _asset.facilityTypeId, _res => {
                _this.listComponentAi = _res;
                    //Get the list of company's anomalies runnable by AI Machine
                //If the company doesn't have any, I'll get the same about SKY-FUTURES
                assetDataServices.getAiAnomalyByFacilityByCompany(this.customer.id, _asset.facilityTypeId, _res => {
                    _this.listAnomalyAi = _res;
                    _this.listAssetDataDetailsTagTypes2 = _this.listComponentAi.concat(_this.listAnomalyAi)
                    _this.listAssetDataDetailsTagTypes2.push({id:_noTag.id, key:_noTag.key, value:_noTag.value})


                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);        
                })

            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
                myThrow(_err);        
            })

            


        }
        
        // // helper method
        // _this.retriveTagSingle = function(tag){
            
        //         // var compoCount = 0;
                
        //         // console.log(this.assetDataDetailsSelected.id)
        //         // _this.listAssetDataDetailsNotFiltered.forEach(t => {
        //         //     if (t.id == this.assetDataDetailsSelected.id){
        //         //         t.listTags.forEach(s => {
        //         //             if (s.tagKey == tag){
        //         //                 compoCount ++;
        //         //             }
        //         //         })  
        //         //     }                                      
        //         // })
        //         var compoCount = _this.currentCompoFddTags.filter(element => element.component_type_id == tag)
        //         compoCount = compoCount.concat(_this.currentAnoFddTags.filter(element => element.anomaly_type_key == tag))

        //         return compoCount.length            

        // }
    
        // _this.aiFilter = function () {        
        //     _this.viewShown = _this.views.aifilter;
        //     _this.wheelZoomRef = undefined;
        //     _this.aiFilterImageNamePrev = undefined;

        //     utility.callFunctionEveryTime("$('#_ai_filter').get(0)", 300, function() {                
        //         utility.callFunctionEveryTime("$('#_img_selected').get(0)", 300, function() {
        //             _this.showImg(_this.listAssetDataDetails[_this.imgIndex]);
        //         })
        //     });

        //     _this.listTagsIntoAssetDataDetails.forEach(t => {
        //         // if (t.key != 'none'){        
        //         t.isSelected = false;
        //         // }
        //     })


        //  }


        //===========================================AI FILTER END ==========================================================



        //===========================================UPLOAD CHECKLIST START==========================================================



        _this.uploadCheckListPopup = function(customerId) {

            $scope.customerEdit = customerId;
            $scope.listNewAssetCreated = []
            var _uploadData = {
                uploadData: {description:''},
                listStructure: _listAssetStructure,
            };

            var _fns = [
                { 'btn_identifier':'_1_1', 'btn_close': false, 'btn_msg': 'Remove All Files', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_section == 'upload'"},
                { 'btn_identifier':'_1_2', 'btn_close': false, 'btn_msg': 'Next', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'upload'", 'btn_fn': 'uploadAssetNextFromPopupCheck' },

                { 'btn_identifier':'_2_1', 'btn_close': false, 'btn_msg': 'Back', 'btn_color': 'btn-clear', 'btn_visible_if':"$innerStatus.asset_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'uploadAssetBackFromPopupCheck'  },
                { 'btn_identifier':'_2_2', 'btn_close': false, 'btn_msg': 'Run Upload & Analysis', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'results' && !$innerStatus.isUploading", 'btn_fn': 'uploadAssetDataRunFromPopupCheck' },                   
                { 'btn_identifier':'_3_2', 'btn_close': true, 'btn_msg': 'Close', 'btn_color': 'btn-light', 'btn_visible_if':"$innerStatus.asset_section == 'results' && $innerStatus.isUploadConfirmed", 'btn_fn': 'uploadAssetDataCloseFromPopupCheck' },                   

            ];

            var _config = {
                funzione: {
                    getresults_flow: $scope.getresults_flow(),
                },
                size: 'lg',
                title: 'Upload New Data',
                ctrl: _controllerName,
                data: JSON.stringify(_uploadData),
                fns: JSON.stringify(_fns),
                htmlPage: 'views/asset/popup/asset_upload_popup.html',
                innerStatus: {
                    asset_section:'upload',
                    isUploadCompleted: false,
                    isUploading: false,
                    isUploadConfirmed: false,
                }
            }
            popup.openModalBody(_config);       
        }

        $scope.uploadAssetDataCloseFromPopupCheck = function(_popup) {
            _assetDataResetAndRefresh();
        }


            $scope.uploadAssetNextFromPopupCheck = function(_popup) {
                _structureId = _popup.data.uploadData.structureId;
                _sourceTypeKey = _popup.data.uploadData.sourceTypeKey;
                _description = _popup.data.uploadData.description;
                switch (_popup.innerStatus.asset_section) {
                    case 'upload':
                        _popup.innerStatus.asset_section = 'results';
                        break;
                }
            }


            $scope.uploadAssetBackFromPopupCheck = function(_popup) {
                switch (_popup.innerStatus.asset_section) {
                    
                    case 'results':
                        _popup.innerStatus.asset_section = 'upload';
                        break;
                }
            }

            $scope.uploadAssetDataRunFromPopupCheck = function(_popup) {
                myLog("uploadData", _popup.data.uploadData);
                $scope.upload_funCheck(_popup);
            } 

            $scope.uploadConfirmStatusCheck = function(_popup) {
                _popup.updateData(_popup.data);
            } 
            
            
            $scope.confirm_uploadCheck = function(value, _uploader) {
                if (value == 1) {
                    _uploader.upload();
                }
            }


            $scope.upload_funCheck = function(_popup) {

                //Disable the session timeout 
                //This because of big files to upload
                Scopes.get("mainCtrl").isSessionTimeoutActive = false;
    
                _popup.innerStatus.isUploading = true;
    
                //reset the total number of file type
                $scope.counter = 0;
                $scope.tot_file = 0;
                $scope.n_images = 0;
                    
    
                var _uploader = _popup.data.uploaderFlow;    
    
                _uploader.opts.testChunks = false;
                _uploader.opts.simultaneousUploads = 1;    
    
                _uploader.opts.target = '/api/upload_flow_chekclist';

                $scope.creation_facility_record(_uploader, true);

                _popup.innerStatus.isUploadConfirmed = true;
                _popup.innerStatus.isUploadCompleted = true;

                

            }   

        //===========================================UPLOAD CHECKLIST END==========================================================


        //=========================================== oRTHOMOSAIC START==========================================================
        
        _this.orthoMosaicGen = function () {

            if (_this.listAssetDataSelectd.length < 2){ 

                var Data = {
                    facilityDataId: _this.listAssetDataSelectd[0].id,
                    companyId: _this.asset.companyId,
                    orthotype: 'panorama',
                    assetDataDetailsSelected: _this.listAssetDataDetails.filter(e => e.isSelected),
                    nAssetData: _this.listAssetDataDetails.filter(e => e.isSelected).length
                }

                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_close': false, 'btn_msg': 'Run', 'btn_visible_if':"$innerStatus.statusSection == 'generationReady'", 'btn_color': 'btn-light', 'btn_fn': 'orthomomsaicGeneration'},
                    { 'btn_identifier': '_1_2', 'btn_close': true, 'btn_confirm': true,  'btn_msg': 'Close', 'btn_color': 'btn-light' },
                ];
                
                    
                var _config = {
                    'funzione': {
                        initOrthomosaicValidation: _this.initOrthomosaicValidation(),
                    },
                    'size': 'lg',
                    'title': 'Orthomosaic Generation',
                    'ctrl': _controllerName,
                    'fns': JSON.stringify(_fns),
                    'data': JSON.stringify(Data),
                    'htmlPage': 'views/asset-data/popup/asset-data_orthomosaic.html',
                    'innerStatus': {
                        'statusSection':'validation',
                    }
                }

                popup.openModalBody(_config);
            }
            else{
                myAlert("Plese select just one Asset", 'info');  
            }
         }


        _this.initOrthomosaicValidation = function(){

            return function (_popup) {

                //Function to quicklycheck the presennce of Anomalies in the data
                var Data = {
                    'facilityDataId': _this.listAssetDataSelectd[0].id,
                    'companyId':  _this.asset.companyId,
                    'assetDataDetailsSelected': _this.listAssetDataDetails.filter(e => e.isSelected),
            }
                
                assetDataServices.orthoMosaicVal(Data, _resV => {
                    _popup.innerStatus.statusSection = 'generationReady';
                }, _err => {
                    _popup.innerStatus.statusSection = 'generationReady';
                    myAlert("Warning: " + _err.data["Error Message"], 'danger');
                    myThrow(_err);        
                })    
            }

        }
        
        $scope.orthomomsaicGeneration = function (_popup) {

            //Function to run the Orthtomosaic Creration 
            myAlert("Orthomosaic Creation Startetd", 'info'); 

            showLoading('Orthomosaic Creation');

            var Data = {
                'facilityDataId': _this.listAssetDataSelectd[0].id,
                'companyId':  _this.asset.companyId,
                'assetDataDetailsSelected': _this.listAssetDataDetails.filter(e => e.isSelected),
                'typeOrtho': _popup.data.orthotype
            }
            
            assetDataServices.orthoMosaicGen(Data, _res => {   
                myAlert("Orthomosaic Generated", 'info'); 
                swal.close()
                $timeout(function() {
                    _popup.close();
                    _assetDataResetAndRefresh();
                }, 1000)                    
            }, _err => {
                swal.close();
                _popup.close();
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
                                

        }

        //=========================================== oRTHOMOSAIC END==========================================================



        //=========================================== CHANGE DATA TYPE START==========================================================
         _this.changeDataType = function(){

            var fdd_ids = [];
            _this.listAssetDataDetails.forEach(e => {
                if (e.isSelected) {
                    fdd_ids.push(e.id);
                }
            })

            var Data = {
                'assetId': _this.listAssetDataSelectd[0].id, 
                'companyId':  _this.asset.companyId,
                'new_data_type': 'thermal_imagery',
                'list_fdd_ids':  fdd_ids,
                'assetDataTypesKeys': _this.assetDataTypesKeys
            }

            var _fns = [
                { 'btn_identifier': '_1_1', 'btn_close': true, 'btn_confirm': true,  'btn_msg': 'Cancel', 'btn_color': 'btn-clear' },
                { 'btn_identifier': '_1_2', 'btn_close': false, 'btn_msg': 'Convert', 'btn_color': 'btn-light', 'btn_fn': 'changeDataType'},  
            ];
               
            var _config = {
                size: 'lg',
                title: 'Change Data Type',
                ctrl: _controllerName,
                fns: JSON.stringify(_fns),
                data: JSON.stringify(Data),
                htmlPage: 'views/asset-data/popup/asset-data_changeTypes.html'
            }

            popup.openModalBody(_config);

 
        }

        $scope.changeDataType = function (_popup) {

            myAlert("Change Data Type Started", 'info');    

            var Data = {
                'assetId': _popup.data.assetId,
                'companyId':  _popup.data.companyId,
                'new_data_type': _popup.data.assetDataTypesKeysID,
                'list_fdd_ids':  _popup.data.list_fdd_ids,
            }


            assetDataServices.updateDataType(Data, _res => {
                myAlert("Change Data Type Completed", 'info');    
                _assetDataResetAndRefresh()
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
         }


        //=========================================== CHANGE DATA TYPE END==========================================================

        //=========================================== POST PROCESSING START==========================================================
        
        _this.runPostProcessing = function (id) {

            myAlert("Run Post Processing Function", 'info');    

            var Data = {"facility_data_id": id}


            assetDataServices.runPostProcessing(Data, _res => {
                myAlert("Post Processing Function Completed", 'info');  
                _assetDataResetAndRefresh()                   
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
         }



        _this.removeAssetData = function (id) {

            myAlert("Remove Data Set Started", 'info');    

            var Data = {"facility_data_id": id}

            assetDataServices.removeAssetData(Data, _res => {
                myAlert("Remove Data Set Completed", 'info');    
                _assetDataResetAndRefresh()                 
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
         }


        _this.completeAsset = function (id) {

            //function to inlude furrthere data to the dataset
            _this.uploadAssetDataPopup(false, id)

         }

        //=========================================== POST PROCESSING END==========================================================

        


        //=========================================== Matterport begin========================================================
        _this.initMatterport = function() {
            var _facilityDataId = $stateParams['fd_id'];

            matterportFactory.getMatterportUrl(_facilityDataId, _res => {
                _this.matterportUrl = $sce.trustAsResourceUrl(_res)
                console.log(_res);
            })
        }

        function _getMatterportData(_facilityDataId) {
            matterportFactory.getMatterportUrl(_facilityDataId, _res => {
                _this.matterportUrl = $sce.trustAsResourceUrl(_res)
                console.log(_res);
            })
        }

        $scope.insertNewMatterport = function(_popup) {
            matterportFactory.insertNewMatterport(_popup, _assetId, _identity, _res => {
                _this.getAssetsAssetData(_assetId);
            }, _err => {
            })
        }

        _this.newMatterportPopup = function() {
            matterportFactory.newMatterportPopup(_controllerName);
        }


        $scope.validationMatterportFromPopup = function (_popup) {
            return matterportFactory.validationMatterport(_popup)
        }
        //=========================================== Matterport end==========================================================
      



        //=========================================== Azimuth begin===========================================================
        
       _this.initAzimuth = function(_fddIdParam, _isIframe) {
           if (_this.isAssetDataToolEnabled(_this.toolName.azimuth) || _isIframe) {
                var _fddId;
                _this.azimuthInfo = undefined
                _this.azimuthImageUrl = ""
        
                if (_fddIdParam) {
                    if (_this.assetDataDetailsSelected && _this.assetDataDetailsSelected.id && _this.listAssetDataSelectd.length>0) {

                        _this.viewFromAssetDataTool.changeTo(_this.views.azimuth)
        
                        _fddId = _fddIdParam;
                    }
                } else {
                    _fddId = $stateParams['fdd_id'];
                }
    
                if (_fddId || _this.isshownAzimuth) {
                    utility.callFunctionEveryTime("$('#"+azimuthFactory.getHtmlIds().imageId+"').get(0)", 200, function () {
    
                        azimuthFactory.boot(_this.wheelZoomRef, $scope, _fddId, _resBoot => {
                            _this.azimuthInfo = _resBoot;
                            _this.azimuthInfo.compassDegrees = 0;
    
                            var _assetData = _this.azimuthInfo.facilityData;
                            _this.listAssetDataFileTypeByKey = _this.azimuthInfo.listAssetDataFileTypeByKey;
                            _customerId = _this.azimuthInfo.company.id;
                            _assetId = _this.azimuthInfo.facility.id
                            var _fdd = _this.azimuthInfo.facilityDataDetails;
    
                            _this.azimuthImageUrl = _this.buildSrcUrl(_fdd, _assetData).hdUrl;

                            azimuthFactory.createMap();
    
                            utility.callFunctionEveryTime("$('#"+_this.azimuthInfo.htmlIds.imageId+"').attr('src').length>0", 200, function () {
                                azimuthFactory.setting(_resSetting => {
            
                                    var _azimuthDegrees = azimuthFactory.calculateAzimuthByNorth(_fdd.latitude, _fdd.longitude);
                                    azimuthFactory.rotateCompassNeedle(_azimuthDegrees)
                                    azimuthFactory.rotateImage(_this.azimuthInfo.gimbalYawDegree);
            
                                    azimuthFactory.sendAzimuthInfoMessage();            
                                });    
                            })
    
                        });
                    })
                }    
            }
        }

        _this.azimuthRotateImage = function(_gimbalYawDegree) {
            azimuthFactory.rotateImage(_gimbalYawDegree);
        }

        _this.toggleMapImageOverlapped = function() {
            azimuthFactory.toggleMapImageOverlapped();

            $timeout(function() {
                azimuthFactory.createMap();
                if (_this.azimuthInfo.isMapImageOverlapped) {
                    azimuthFactory.imageOpacity(0.5) 
                    // $("#"+_this.azimuthInfo.htmlIds.canvasNotesId).css('left','0px');

                } else {
                    // $("#"+_this.azimuthInfo.htmlIds.canvasNotesId).css('left',-(1920-$("#_azimuth_image").width())/2);

                    azimuthFactory.imageOpacity(1) 
                }
            }, 300);
        }

        _this.azimuthImageOpacity = function(_opacity) {
            azimuthFactory.imageOpacity(_opacity)
        }

        _this.azimuthMoveMapToLeft = function() {
            azimuthFactory.moveMapToLeft();
        }
        _this.azimuthMoveMapToRight = function() {
            azimuthFactory.moveMapToRight();
        }
        _this.azimuthMoveMapToUp = function() {
            azimuthFactory.moveMapToUp();
        }
        _this.azimuthMoveMapToDown = function() {
            azimuthFactory.moveMapToDown();
        }
        _this.azimuthZoomIn = function() {
            azimuthFactory.zoomIn();
        }
        _this.azimuthZoomOut = function() {
            azimuthFactory.zoomOut();
        }


        _this.azimuthSave = function() {
            azimuthFactory.save(_identity.id, _this.azimuthImageUrl, _res => {
                _this.listAssetDataDetails.push(_res.facilityDataDetailsImage);
                myAlert("Azimuth info, saved", 'info');
            }, _err => {
                myAlert("Error: I wasn't able to save the azimuth info" , 'danger');
            });
        }
        _this.azimuthCalculateAddMarker = function() {
            azimuthFactory.buildNotePopup(_this);
        }
        $scope.getPointTextFromPopup = function(_popup) {
            azimuthFactory.calculateAddMarker(_popup.data.note);
        }
        _this.azimuthGimbalYawDegreeModal = function() {
            azimuthFactory.gimbalYawDegreeModal();
        }
        _this.azimuthSwitchToView = function(_view) {
            azimuthFactory.switchToView(_view);
        }
        _this.azimuthAddAntennasCenterPolygon = function() {
            azimuthFactory.addAntennasCenterPolygon();
        }
        _this.azimuthAddAntennasCenterEllipse = function() {
            azimuthFactory.addAntennasCenterEllipse();
        }
        _this.azimuthSetACPointerModifiable = function() {
            azimuthFactory.setACPointerModifiable();
        }
        _this.azimuthBackward = function() {
            azimuthFactory.moveCompassACBackward();
        }
        _this.azimuthForward = function() {
            azimuthFactory.moveCompassACForward(); 
        }
        _this.azimuthAnthennaCenterDeleteAllObjects = function() {
            azimuthFactory.anthennaCenterDeleteAllObjects();
        }
        _this.azimuthRemoveMarkers = function() {
            azimuthFactory.removeMarkers();
        }
       //=========================================== Azimuth end=============================================================



        //===================================================== Methods about asset-data with tags shown on google-map: begin ========================================================
        function _createAssetDataByTagsMap(_canvasId, _callback) {
            _this.listMarkersOnAssetDataByTagMap = [];
            appFactory.removeMapById(_maps, _canvasId)

            assetDataByTagsFactory.createMap(_canvasId, _m => {
                appFactory.addMapById(_maps, _m, _canvasId);

                if (_callback) _callback(_m);
            })
        }
        
        function _putMarkersOnAssetDataByTagMap(_map, _listTags) {
            _this.listAssetDataDetailsWithTag = assetDataByTagsFactory.filterListAssetDataDetailsWithTag(_this.listAssetDataDetails, _listTags)
            _this.listMarkersOnAssetDataByTagMap =  assetDataByTagsFactory.putMarkersOnMap(_map, _this.listMarkersOnAssetDataByTagMap, _this.listAssetDataDetails, _this.listAssetDataSelectd, _listTags, _this.listAssetDataFileTypeByKey, _customerId, _assetId);

        }

        _this.showAssetDataByTagsMarkerInfo = function(_assetDataDetails) {
            var _map = appFactory.getMapById(_maps, _this.assetDataByTagsCanvasId);

            assetDataByTagsFactory.showMarkerInfo(_map, _assetDataDetails, _this.listMarkersOnAssetDataByTagMap)
        }       

        _this.showAssetDataByTags = function(_listTags) {
            _this.isshownAssetDataByTags = assetDataByTagsFactory.showToggle();

            if (_this.isshownAssetDataByTags) {
                _this.oldViewShown = _this.viewShown;
                _this.viewShown = _this.views.assetDataByTags;

                _createAssetDataByTagsMap(_this.assetDataByTagsCanvasId, _map => {                    
                    _putMarkersOnAssetDataByTagMap(_map, _listTags)
                });
            } else {
                _this.viewShown = _this.oldViewShown;
            }

            _this.setAssetDataViewConfig(null, true);
        }        
        //===================================================== Methods about asset-data with tags shown on google-map: end ========================================================

        //===================================================== Methods about asset-data-kml shown on google-map: begin ========================================================
        function _showKmlMap(_canvasId, _assetData) {

            googlemaps.initGoogleMap("#"+_canvasId, _m => {

                var _sourceUrls = _this.buildSrcUrl(_this.listAssetDataDetails[0]);

                var kmlLayer = new google.maps.KmlLayer(_sourceUrls.url, {
                    suppressInfoWindows: true,
                    preserveViewport: false,
                    map: _m
                  });                
            });    
        }
        //===================================================== Methods about asset-data-kml shown on google-map: end ==========================================================

        //===================================================== Methods about asset-data-DWG shown: begin ========================================================
        function _showDwgFile(autodesk_urn) {
            var acces_token;
            var viewer;

            _this.viewShown = _this.views.dwg;


            utility.callFunctionEveryTime("$('#forgeViewer').get(0)", 200, function() {
                assetDataServices.getAccessTokenAutodesk(100, _res => {
                    acces_token = _res;
                    var options = {
                        env: 'AutodeskProduction',
                        api: 'derivativeV2',  // for models uploaded to EMEA change this option to 'derivativeV2_EU'
                        getAccessToken: function(onTokenReady) {
                            var token = acces_token;
                            var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                            onTokenReady(token, timeInSeconds);
                        }
                    };
    
                    Autodesk.Viewing.Initializer(options, function() {
    
                        var htmlDiv = document.getElementById('forgeViewer');
                        viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                        var startedCode = viewer.start();
                        if (startedCode > 0) {
                            console.error('Failed to create a Viewer: WebGL not supported.');
                            return;
                        }
    
                        console.log('Initialization complete, loading a model next...');
    
                    });
    
        
                    var htmlDiv = document.getElementById('forgeViewer');
                    viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, {});
    
                    var urn = 'urn:'.concat(autodesk_urn);
                    var documentId = urn;
    
                    Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
    
                    function onDocumentLoadSuccess(viewerDocument) {
                        var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
                        viewer.loadDocumentNode(viewerDocument, defaultModel);
                    }
    
                    function onDocumentLoadFailure() {
                        console.error('Failed fetching Forge manifest');
                    }
    
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);    
                });
            })


        }
        //===================================================== Methods about asset-data-DWG shown: end ==========================================================








        //===================================================== Methods about asset-data-methane shown on google-map: begin ========================================================
        function _createAssetDataMethaneMap(_canvasId, _callback) {
            _this.listMarkersOnAssetDataMethaneMap = [];
            appFactory.removeMapById(_maps, _canvasId)

            assetDataMethaneFactory.createMap(_canvasId, _m => {
                appFactory.addMapById(_maps, _m, _canvasId);

                if (_callback) _callback(_m);
            })
        }
        
        function _putMarkersOnAssetDataMethaneMap(_map) {

            var values = assetDataMethaneFactory.findExtrema(_this.listAssetDataDetails);

            $scope.methaneMinValue = values[1] ;
            $scope.methaneMaxValue = values[0] ;

            $scope.slider_methane.options.onChange = _this.updateTheMethaneIconMarker;
            if (($scope.methaneMinValue - 10) < 0){
                $scope.slider_methane.options.floor = 0
            }
            else{
                $scope.slider_methane.options.floor = $scope.methaneMinValue - 10; 
            }

            $scope.slider_methane.options.ceil = $scope.methaneMaxValue + 10;

            _this.listMarkersOnAssetDataMethaneMap =  assetDataMethaneFactory.putMarkersOnMap(_map, _this.listMarkersOnAssetDataMethaneMap, _this.listAssetDataDetails, _this.listAssetDataSelectd, $scope.slider_methane.min, $scope.slider_methane.max);
        }

        _this.showAssetDataMethaneMarkerInfo = function(_assetDataDetails) {
            var _map = appFactory.getMapById(_maps, _this.assetDataByTagsCanvasId);

            assetDataMethaneFactory.showMarkerInfo(_map, _assetDataDetails, _this.listMarkersOnAssetDataMethaneMap)
        }       

        //===================================================== Methods about asset-data-methane shown on google-map: end ========================================================

        //===================================================== Methods about inspection-report: begin ========================================================
        function _showInspectionReport(_assetData) {
            _this.inspectionReportObj = {};
            _this.reportInfo = undefined;

            inspectionReportFactory.getReportInfo(_assetData, _asset, _res => {
                _this.reportInfo = _res
                _this.reportInfo.asset = _asset;

                //get info about the previous inspection-report selected
                inspectionReportFactory.getReportInfoPrevious(_assetData, _asset, _resPrev => {
                    _this.reportInfo.listInspectionReportObj.forEach(e => {
                        _resPrev.listInspectionReportObj.some(p => {
                            if (e.question.id == p.question.id) {
                                e.answerPrev = p.answer;
                                return true;
                            }
                        })
                    })

                    myLog("kjhslkjhds")
                })

                //Create inspection report map about the asset/estate
                if (_asset.facilityTypeId == _this.assetType.estate) {
                    inspectionReportFactory.getEstateAssets(_asset.id, _res1 => {
                        _this.reportInfo.listAsset = _res1;
                        _createInspectionReportMap(_this.reportInfo)
                    })
                } else {
                    _createInspectionReportMap(_this.reportInfo)
                }
                
            })

        }

        function _createInspectionReportMap(_reportInfo) {
            appFactory.removeMapById(_maps, _this.inspectionReportCanvasId)
            inspectionReportFactory.createMap(_this.inspectionReportCanvasId, _m => {
                appFactory.addMapById(_maps, _m, _this.inspectionReportCanvasId);

                var _listAssetTmp = [];
                
                if (_reportInfo.asset.facilityTypeId == _this.assetType.estate) {
                    _listAssetTmp.push.apply(_listAssetTmp, _reportInfo.listAsset);
                } else {
                    _listAssetTmp.push(_reportInfo.asset);
                }

                assetServices.getAssetTypes(_res => {
                    _this.listAssetTypeKey = utility.getArrayByField(_res, 'id');

                    var _markers = googlemaps.putAssetMarkersOnMap(_m, _listAssetTmp)

                    _markers.forEach(e => {
                        _setAssetMarkerIcon(e, e.asset);
                    })
    
                }, _err => {
                    _error ? _error(_err) : null;
                    myAlert("Error: " + _err.data.message, 'danger');
                });


            })

        }

        function _setAssetMarkerIcon(_marker, _asset) {
            if (_asset.facilityTypeId) {
                var _ret = 'marker-' + _this.listAssetTypeKey[_asset.facilityTypeId].imgName.replace('.png','') + "_" + (_asset.status?_asset.status:'unknown') + '.png';

                _marker.setIcon('/img/icons/markers/' + _ret);
            } else {
                return null;
            }
        }

        _this.inspectionReportToggleDetails = function(_inspectionReportObj) {
            _inspectionReportObj.isDetailsVisible = !_inspectionReportObj.isDetailsVisible;

            if (_inspectionReportObj.isDetailsVisible) {
                var _assetData = utility.getArrayElementByField(_this.listAssetData, 'id', _inspectionReportObj.inspectionReport.facilityDataIdRef)

                inspectionReportFactory.addDetails(_inspectionReportObj, _assetData, _this.listAssetDataFileTypeByKey, _customerId, _asset, _res => {
                    _inspectionReportObj = _res;

                    if (_inspectionReportObj.question.viewType == 'thumbs' ||
                        _inspectionReportObj.question.viewType == 'ocr' || 
                        _inspectionReportObj.question.viewType == 'thumbs-tag') {
                        _this.inspectionReportShowImg(_inspectionReportObj, _inspectionReportObj.details[0]);
                    } else if (_inspectionReportObj.question.viewType == 'temperature-graph' ) {
                        _this.inspectionAnswerShowGraph(_inspectionReportObj);
                    } else if (_inspectionReportObj.question.viewType == 'map' || 
                                _inspectionReportObj.question.viewType == 'map-thumbs' ||
                                _inspectionReportObj.question.viewType == 'map-methane') {
                            _this.createInspectioReportDetailsMap(_inspectionReportObj)
                    }


                })
            }
        } 

        _this.createInspectioReportDetailsMap = function(_inspectionReportObj) {
            var _canvasId = _this.inspectionReportDetailMapId + '_' + _inspectionReportObj.question.id;

            appFactory.removeMapById(_maps, _canvasId)
            inspectionReportFactory.createMap(_canvasId, _m => {
                appFactory.addMapById(_maps, _m, _canvasId);

                inspectionReportFactory.putMarkersOnMap(_inspectionReportObj, _m, )
            });    

        }

        _this.inspectionAnswerShowGraph = function(_inspectionReportObj) {
            //call the buildChart service to create the chart shown into html
            utility.callFunctionEveryTime("$('#_previous_answer_graph').get(0)", 200, function() {

                var _options = {
                    plugins: {
                        labels: {
                            render: 'value',
                            fontColor: '#fff',
                            fontSize: 12,
                            fontStyle: 'bold',
                        }
                    },
                    layout: {
                        padding: {
                            top: 50
                        }
                    },
                    maintainAspectRatio: false,
                    cutoutPercentage: 70,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            stacked: true
                        }],
                        xAxes: [{
                            display: true,
                            stacked: true
                        }]
                    }
                }

                var _datasetConfig = {
                    borderWidth: 2,
                    borderColor: '#192a40',
                    barThickness: 0.1,
                    maxBarThickness: 0.1,
                }
        

                var _data = [];
                var _labels = [];
                var _backgroundColor=[];

                _data.push(Number(_inspectionReportObj.answer.value).toFixed(2));
                _labels.push(utility.formatDate(_inspectionReportObj.answer.createdAt, 'dd/MM/yyyy hh:mm:ss'));
                _backgroundColor.push(_inspectionReportObj.answer.status);

                _inspectionReportObj.previousAnswers.forEach(e => {
                    _data.push(Number(e.value).toFixed(2));
                    _labels.push(utility.formatDate(e.createdAt, 'dd/MM/yyyy hh:mm:ss'));
                    _backgroundColor.push(e.status);
                }) 
                buildChart.default($('#_previous_answer_graph').get(0), 'bar', _data, _labels, _backgroundColor, _options, _datasetConfig);
            })
        }

        _this.inspectionReportShowImg = function(_inspectionReportObj, _detail) {
            _inspectionReportObj.detailsSelected = _detail;

            if (!_inspectionReportObj.zoomInitialized) {
                _inspectionReportObj.zoomInitialized = true;

                var _imgId = '_img_selected_'+_inspectionReportObj.question.id;
                utility.callFunctionEveryTime('$("#'+_imgId+'").get(0)', 200, function() {
                    _this.wheelZoomRef = wheelzoom(document.getElementById(_imgId));
                })
            }
        }


        _this.showInspectionReportDetailsMapMarkerInfo = function(_inspectionReportObj, _assetDataDetails) {
            var _canvasId = _this.inspectionReportDetailMapId + '_' + _inspectionReportObj.question.id;

            _inspectionReportObj.listMarker.some(e => {
                if (e.id == _assetDataDetails.id) {
                    var _map = appFactory.getMapById(_maps, _canvasId);

                    googlemaps.setInfoWindow(_map, e, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
                }
            })
        }

        _this.updateInspectionReportMethaneMarker = function(_inspectionReportObj,  _threshold) {
            inspectionReportFactory.updateMethaneMarkers(_inspectionReportObj, _threshold)
        }

        //===================================================== Methods about inspection-report: end ==========================================================

        //FUNCTION FOR RADIO BUTTON FOR BB
        _this.filterListAssetDataDetailsByTagsRadio = function(_tag, _filterType, tagSelectedNew) {

            const index = _this.selectionObject.indexOf(_tag);
            if (index > -1) { // only splice array when item is found
                _this.selectionObject.splice(index, 1); // 2nd parameter means remove one item only
            }
            else{
                _this.selectionObject.push(_tag)
            }

            const index2 = _this.tagSelected.indexOf(tagSelectedNew);
            if (index2 > -1) { // only splice array when item is found
                _this.tagSelected.splice(index2, 1); // 2nd parameter means remove one item only
            }
            else{
                _this.tagSelected.push(tagSelectedNew)
            }


            // for (var i=0; i<_this.listTagsIntoAssetDataDetails.length; i++){
            //     if (_this.listTagsIntoAssetDataDetails[i].isSelected){
            //         _this.listTagsIntoAssetDataDetails[i].isSelected = false
            //     }
            // }

            for (var i=0; i<_this.listTagsIntoAssetDataDetails.length; i++){
                if (_this.listTagsIntoAssetDataDetails[i].key == tagSelectedNew){
                    _this.listTagsIntoAssetDataDetails[i].isSelected = !_this.listTagsIntoAssetDataDetails[i].isSelected
                }
            }

            console.log(_this.listTagsIntoAssetDataDetails.filter(t => t.isSelected==true))

            if (_filterType == 'fdd' || _filterType=="level1") {
                // _tag.isSelected = !_tag.isSelected;
                console.log('')
            } else if (_filterType=="level2") {
                _this.tagAnomalyLevel2 = _tag.key;
            } else if (_filterType=="level3") {
                _tag.isSelected = !_tag.isSelected;
            }

            // _this.tagSelected = tagSelected;

            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();
        }
        //FUNCTION FOR RADIO BUTTON FOR BB END

        _this.filterListAssetDataDetailsByTags = function(_tag, _filterType) {

            if (_filterType == 'fdd' || _filterType=="level1") {
                _tag.isSelected = !_tag.isSelected;
            } else if (_filterType=="level2") {
                _this.tagAnomalyLevel2 = _tag.key;
            } else if (_filterType=="level3") {
                _tag.isSelected = !_tag.isSelected;
            }

            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();
        }
        function _filterListAssetDataDetailsByTags() {
            var _list =  assetDataByTagsFactory.filterListAssetDataDetailsByTag(_this.listAssetDataDetailsNotFiltered, _this.listTagsIntoAssetDataDetails.filter(t => t.isSelected==true));
        
            if (_this.tagSelected.length==0 && _this.viewShown == 'aifilter'){
                _list = _this.listAssetDataDetailsNotFiltered;
            }

            //Verify if it is worth to add the next if above
            if (_this.tagSelected.length==0 && _this.viewShown == 'aiassistedlabel'){
                _list = _this.listAssetDataDetailsNotFiltered;
            }



            //I've commented this because at the moment we have commented the "Filter Levels" into the AssetData screen
            // _list = anomalyFactory.filterListAssetDataDetailsByTag(_list,  _this.listTagsAnomalyLevel1[0].corrosion.filter(t => t.isSelected==true));

            // _this.tagAnomalyLevel2Info.key = _this.tagAnomalyLevel2

            // _list = anomalyFactory.sortListAssetDataDetailsByLevel2(_list, _this.tagAnomalyLevel2Info);

            // var _listTagsComponentLevel3Selected = [];
            // _this.listTagsComponentLevel3.forEach(e => {
            //     _listTagsComponentLevel3Selected.push.apply(_listTagsComponentLevel3Selected, e.filters.filter(t => t.isSelected==true))
            // })
            // _list = componentFactory.filterListAssetDataDetailsByTag(_list,  _listTagsComponentLevel3Selected);
            
            if (_this.viewShown == _this.views.aifilter || _this.viewShown == _this.views.aiassistedlabel) {
                _list.forEach(e=>{
                    if(e.id != _this.assetDataDetailsSelected.id){e.isThumbSelected = false}
                    else{
                        e.isThumbSelected = true
                    }
                })
            }


            _updateImgIndexByThumbSelected(_list)

            if (_this.viewShown == _this.views.aifilter || _this.viewShown == _this.views.aiassistedlabel) {
                _this.listAssetDataDetails = _list;
                _this.drawBoundingBoxes();
                // _this.showImg(_list[_this.imgIndex]);
            }

            return _list;
        }

        _this.toggleTagsAnomalyLevel2SortType = function() {
            if (_this.tagAnomalyLevel2Info.sortType=="asc") {
                _this.tagAnomalyLevel2Info.sortType = "desc";
            } else {
                _this.tagAnomalyLevel2Info.sortType = "asc";
            }

            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();
        }

        _this.toggleTagsAnomalyLevel2MinMaz = function() {
            if (_this.tagAnomalyLevel2Info.minMax=="min") {
                _this.tagAnomalyLevel2Info.minMax = "max";
            } else {
                _this.tagAnomalyLevel2Info.minMax = "min";
            }

            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags();
        }




          _this.isshownMatterport = false;
          _this.matterportUrl = ""
          _this.showMaterport = function() {
            _this.isshownMatterport = !_this.isshownMatterport;

            if (_this.isshownMatterport ) {
                if (_asset.id == 700) {
                    _this.matterportUrl = $sce.trustAsResourceUrl("https://my.matterport.com/show/?m=ew67gHC4BNA")
                } else if (_asset.id == 749) {
                    _this.matterportUrl = $sce.trustAsResourceUrl("https://my.matterport.com/show/?m=c9asaniBL2y")                                
                } else {
                }
                _this.oldViewShown = _this.viewShown;
                _this.viewShown = _this.views.matterport;
            } else {
                _this.viewShown = _this.oldViewShown;
            }
        }   

        _this.isshownNaviswork = false
        _this.navisworkUrl = $sce.trustAsResourceUrl('https://viewer.autodesk.com/id/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTU3OTA4NzUzMzMzMF8wOTc3MjIwNTI1NDM2NzYzXzE1NzkwODc1MzM4OTAubndk?designtype=nwd&sheetId=Y2Q5ZjQxYWYtZDU3Ni00N2EzLWIxNjUtOTVlOTVmOWE5ODlm')
        _this.showNaviswork = function() {
            _this.isshownNaviswork = !_this.isshownNaviswork;

            if (_this.isshownNaviswork ) {
                _this.oldViewShown = _this.viewShown;
                _this.viewShown = _this.views.naviswork;
            } else {
                _this.viewShown = _this.oldViewShown;
            }
        }        

        _this.toggleMenu = function(_idMenu) {
            if (_idMenu == '_bbfilter_menu'){
                if ($("#"+_idMenu).hasClass("open")) {
                    _this.tagSelected = [];
                }
            }
            if ($("#"+_idMenu).hasClass("open")) {
                $("#"+_idMenu).removeClass("open")                
            } else {
                $("#"+_idMenu).addClass("open")                
            }
        }

        _this.extractText = function() {
            if (_this.isAssetDataToolEnabled(_this.toolName.ocr)) {
                $("#root").addClass("blur")
                var _warning = myAlert('OCR running. Wait please', 'success', 0)

                assetDataServices.getTextFromImageById(_this.assetDataDetailsSelected.id, _res => {
                    _warning.close();
                    $("#root").removeClass("blur")

                    var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                    var _msg = _res[0];

                    popup.openModal('md', 'Text extract from the image', _msg, _controllerName, JSON.stringify(_fns));

                }, _err => {
                    _warning.close();
                    $("#root").removeClass("blur")

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);    
                })
            }
        }

        _this.extractInfoFromBarcodeAndQrcode = function() {
            if (_this.isAssetDataToolEnabled(_this.toolName.barQrcodeReader)) {
                $("#root").addClass("blur")
                var _warning = myAlert('Barcode/Qrcode running. Wait please', 'success', 0)

                var _imgPath = "lite"+this.buildSrcUrl(_this.assetDataDetailsSelected).basicUrl;
                var _data = {s3Key: _imgPath};
                assetDataServices.extractInfoFromBarcodeAndQrcode(_data, _res => {
                    _warning.close();
                    $("#root").removeClass("blur")

                    var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
                    var _msg = '<div class="extractInfoFromBarcodeAndQrcode">';

                    _msg += "Barcode/Qrcode founded: "+_res.length+"<br><br>";
                    _res.forEach(e => {
                        _msg += "<b>Type: </b> "+e.type+"<br>";
                        _msg += "<b>Value: </b> "+e.text+"<br><br>";
                    })
                    _msg += "</div>"

                    popup.openModal('md', 'Info extract from the image', _msg, _controllerName, JSON.stringify(_fns));

                }, _err => {
                    _warning.close();
                    $("#root").removeClass("blur")

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);    
                })
            }
        }        



        //----------------------------------------------- Align images begin ---------------------------------------------------------------
        _this.spinModelCompassMoveMapToLeft = function(_map) {
            googlemaps.moveMapToLeft(_map)
        }
        _this.spinModelCompassMoveMapToRight = function(_map) {
            googlemaps.moveMapToRight(_map);
        }
        _this.spinModelCompassMoveMapToUp = function(_map) {
            googlemaps.moveMapToUp(_map);
        }
        _this.spinModelCompassMoveMapToDown = function(_map) {
            googlemaps.moveMapToDown(_map);
        }
        _this.spinModelCompassZoomIn = function(_map) {
            googlemaps.zoomIn(_map);
        }
        _this.spinModelCompassZoomOut = function(_map) {
            googlemaps.zoomOut(_map);
        }

        function _calculateCompassDegrees(_x, _y, _spinModelCompassInfo) {
            var degrees = Math.atan2(_y - _spinModelCompassInfo.centerY, _x - _spinModelCompassInfo.centerX) * 180/ Math.PI;
            degrees =  (degrees + 450) % 360;

            return degrees
        }

        function _initSpinModelCompassNeedleDragging(_spinModelCompassInfo) {
            // var _spinModelCompassInfo = {}
            var _canvasId = "_spinmodel_compass_canvas";
            var _compassId = "_spinmodel_compass"
            var isDrawing = false;

            utility.callFunctionEveryTime("$('#"+_canvasId+"').get(0)", 200, function () {
                _spinModelCompassInfo.canvas = $("#"+_canvasId).get(0);
                _spinModelCompassInfo.c = _spinModelCompassInfo.canvas.getContext("2d");

                utility.callFunctionEveryTime("$('#"+_compassId+"').width()>0", 200, function () {
                    _spinModelCompassInfo.radius = $("#"+_compassId).width() / 2;

                    var cw = $("#"+_compassId).width();
                    var ch = $("#"+_compassId).height();

                    $("#"+_canvasId).attr('width',cw)
                    $("#"+_canvasId).attr('height',ch)

                    _spinModelCompassInfo.centerX = cw / 2 ;
                    _spinModelCompassInfo.centerY = ch / 2 ;

                    _spinModelCompassInfo.points = {
                        'array': [
                            { 'x': _spinModelCompassInfo.centerX, 'y': _spinModelCompassInfo.centerY },
                        ],
                        'size': 1
                    }
                    drawPath(_spinModelCompassInfo)

            
                    _spinModelCompassInfo.canvas.addEventListener('mousedown', (e) => {
                            _spinModelCompassInfo.points = {
                                'array': [
                                    { 'x': _spinModelCompassInfo.centerX, 'y': _spinModelCompassInfo.centerY },

                                ],
                                'size': 1
                            }
                            _spinModelCompassInfo.lineN = 1
                            _spinModelCompassInfo.points['size'] = _spinModelCompassInfo.points['array'].length
                        isDrawing = true
                    })

                    _spinModelCompassInfo.canvas.addEventListener('mousemove', (e) => {
                        if (isDrawing) {

                                _spinModelCompassInfo.points['array'][_spinModelCompassInfo.points['size']] = {
                                    'x': e.offsetX,
                                    'y': e.offsetY,
                                };
                                var p = _spinModelCompassInfo.points['array'][_spinModelCompassInfo.points['size']];

                                drawPath(_spinModelCompassInfo)

                                _spinModelCompassInfo.compassDegrees = _calculateCompassDegrees(e.offsetX, e.offsetY, _spinModelCompassInfo).toFixed(2);

                                $scope.$apply()
                        }
                    })
                    _spinModelCompassInfo.canvas.addEventListener('mouseup', (e) => {
                        isDrawing = false

                            _spinModelCompassInfo.points['array'].pop()

                    })
                })
            })            
        }

        function drawPath(_spinModelCompassInfo) {
            var canvas = _spinModelCompassInfo.canvas
            var c = _spinModelCompassInfo.c
            var r = _spinModelCompassInfo.radius;

            c.clearRect(0, 0, canvas.width, canvas.height)
            c.lineTo(0,0,100,100);


            _spinModelCompassInfo.points['array'].forEach((value, index) => {



                if (index % 2 == 0) {
                    c.beginPath()
                    c.lineCap = 'round'
                    c.moveTo(value.x, value.y)
                } else {

                    var center = _spinModelCompassInfo.points['array'][0];
                    var p = _spinModelCompassInfo.points['array'][1];

                    var theta = Math.atan2(p.y - center.y, p.x - center.x);

                    c.lineTo(center.x + r * Math.cos(theta), center.y + r * Math.sin(theta));

                    c.lineWidth = 3
                    c.strokeStyle = '#ff0000'
                    c.stroke()
                }
            })
        }        

        function _resetSpinModelCompassMap(_info) {
            _info.mapInfo.markers[0].setMap(null);
            _info.mapInfo.map.setCenter(new google.maps.LatLng(_info.mapInfo.centralPointLatitude, _info.mapInfo.centralPointLongitude));
            _info.mapInfo.map.setZoom(_info.mapInfo.zoom);
            _info.mapInfo.spinModelPolygon.setMap(_info.mapInfo.map);
            googlemaps.rotatePolygon(_info.mapInfo.spinModelPolygon, (_info.compassDegrees - _info.mapInfo.compassDegreesOld))

        }

        function _initSpinModelCompassMap(_mapInfo) {
            _mapInfo.spinModelPolygon.setMap(null);
            _mapInfo.compassDegreesOld = _this.spinModelDegrees;
            _mapInfo.markers = [];
            _mapInfo.markers.push( new google.maps.Marker({
                map: _mapInfo.map,
                position: new google.maps.LatLng(_mapInfo.centralPointLatitude, _mapInfo.centralPointLongitude),
            }));
            googlemaps.fitBounds(_mapInfo.map, _mapInfo.markers)        
        }

        _this.toggleSpinModelWithCompass = function() {    
            _this.spinModelWithCompass = !_this.spinModelWithCompass;

            if (_this.spinModelWithCompass) {
                _this.image360InteractInfo.imgWidth = $(".360-image-selected").width() + 'px';
                _this.image360InteractInfo.imgHeight = $(".360-image-selected").height() + 'px';

                _initSpinModelCompassNeedleDragging(_this.image360InteractInfo.compassInfo);
                _initSpinModelCompassMap(_this.image360InteractInfo.mapInfo)
            } else {
                $("[id^='_360-image-']").css('background-size', _this.image360InteractInfo.imgWidth + " " + _this.image360InteractInfo.imgHeight);
                _resetSpinModelCompassMap(_this.image360InteractInfo)
            }
        }   


        _this.toggleSpinModelCompass = function() {
            _this.spinModelCompass = !_this.spinModelCompass;
        }   

        _this.editSpinModelOffSite = function(_assetDataSpinModelOffSite, _assetData, _info) {
            if (!_assetDataSpinModelOffSite) {
                _assetDataSpinModelOffSite = angular.copy(_assetData);
                _assetDataSpinModelOffSite.id = undefined;
                var _descr = _assetDataSpinModelOffSite.description;
                _assetDataSpinModelOffSite.description = _descr?_descr+' - ':'';
            }            
            var _spinModelOffSiteAngle = _assetData.spinModelOffSiteAngle?_assetData.spinModelOffSiteAngle:0;
            var _sourceA = Number(_info.compassDegrees);
            var _targetA = Number(_info.compassInfo.compassDegrees) + _spinModelOffSiteAngle;
            var _a = _targetA - _sourceA;
            _assetDataSpinModelOffSite.spinModelOffSiteAngle =  (_a + 540) % 360 - 180;
            
            if (_assetDataSpinModelOffSite.description.indexOf(' (') != -1) {
                _assetDataSpinModelOffSite.description = _assetDataSpinModelOffSite.description.substring(0, _assetDataSpinModelOffSite.description.indexOf(' ('))
            }
            _assetDataSpinModelOffSite.description += ' ('+_assetDataSpinModelOffSite.spinModelOffSiteAngle.toFixed(2)+'° adjusted)'

            spinModelServices.saveSpinModelOffSite(_identity.id, _assetDataSpinModelOffSite, _res => {
                if (!_this.assetDataSpinModelOffSite) {
                    myAlert('New spin model created with the new adjusted compass bearing', 'info')
                } else {
                    myAlert('New spin model updated with the new adjusted compass bearing', 'info')
                }
                _this.assetDataSpinModelOffSite = _res;
                _this.getAssetsAssetData(_res.facilityId, _res => {
                    _res.find(e => {
                        if (e.id == _this.listAssetDataSelectd[0].id) {
                            e.isSelected = true;
                            return true;
                        }
                    })
                });
            })
        }
        

        function _spinModelMap() {
            utility.callFunctionEveryTime("$('#"+_this.spinModelCanvasId+"').get(0)", 200, function() {
                googlemaps.initGoogleMap("#"+_this.spinModelCanvasId, _m => {
                    

                    console.log("maps: ", _this.spinModelCanvasId)
                    var _assetDataSelected = _this.listAssetDataSelectd[0];
                    _m.setCenter(new google.maps.LatLng(_assetDataSelected.spinModelLatitude, _assetDataSelected.spinModelLongitude));
                    _m.setZoom(17);



                    // // Define the LatLng coordinates for the polygon's path.                    
                    // var _lat = _assetDataSelected.spinModelLatitude;
                    // var _lng = _assetDataSelected.spinModelLongitude;
                    // var _tr = [
                    //     {lat: _lat, lng: _lng},
                    //     {lat: _lat+0.000491, lng: _lng+(-0.000375)},
                    //     {lat: _lat+0.000491, lng: _lng+0.000375},
                    // ];
    
                    // // Construct the polygon.
                    // _this.spinModelPolygon = new google.maps.Polygon({
                    // paths: _tr,
                    // strokeColor: '#FF0000',
                    // strokeOpacity: 0.8,
                    // strokeWeight: 2,
                    // fillColor: '#FF0000',
                    // fillOpacity: 0.35
                    // });                
                    // _this.spinModelPolygon.setMap(_m);
                    _this.image360InteractInfo.mapInfo = {
                        spinModelPolygon: googlemaps.createTriangle(_m, _assetDataSelected.spinModelLatitude, _assetDataSelected.spinModelLongitude),
                        map: _m,
                        zoom: _m.getZoom(),
                        centralPointLatitude: _assetDataSelected.spinModelLatitude,
                        centralPointLongitude: _assetDataSelected.spinModelLongitude,
                    }
                });    

            })            
        }

        function _checkAverageHight(_clusterAverageHight, _levelAverageHight) {
            var _min = _levelAverageHight - _levelAverageHight*0.0005;
            var _max = _levelAverageHight + _levelAverageHight*0.0005;

            return (_min <= _clusterAverageHight) && (_clusterAverageHight <= _max);
        }

        //build an object with all the informations
        //about the spin-model's levels position
        function _buildSpinModelLevels(_assetData, _clusters, _success, _error) {
            var _levels = []; 
            var _middleLevelsNumber = 0;

            for (var i=0; i<3; i++) {
                var _item = {
                    isAvailable: false,
                    number: 0,
                    levelIndex: undefined,
                    sublevels:[]
                }

                _levels.push(_item);
            }

            spinModelServices.getSpinModelByAssetDataId(_assetData.id, _res => {
                var _spinModelLevelsInfo = _res;
                _spinModelLevelsInfo.levelsInfo = JSON.parse(_res.levelsInfo);

                _clusters.forEach((c, _index) => {
                    _spinModelLevelsInfo.levelsInfo.levels.forEach(e => {
                        if (_checkAverageHight(c.info.averageHight, e.averageHight)) {
                            var _levelIndex = undefined;

                            if (e.levelType=='top' || e.levelType=='bottom') {
                                _levelIndex = e.levelType=='top'?0:2;

                                _levels[_levelIndex].isAvailable = true;
                                _levels[_levelIndex].number = 1;
                                _levels[_levelIndex].levelIndex = _index;
                                _levels[_levelIndex].levelType = e.levelType;
                                _levels[_levelIndex].levelName = e.levelName;
                                
                            } else if (e.levelType=='middle') {
                                _levelIndex = 1;
                                _middleLevelsNumber++;
                                var _subItem = {
                                    levelIndex: _index,
                                    levelName: e.levelName
                                }

                                _levels[_levelIndex].isAvailable = true;
                                _levels[_levelIndex].number = _middleLevelsNumber;
                                _levels[_levelIndex].levelType = e.levelType;
                                _levels[_levelIndex].sublevels.push(_subItem)
                            }

                        }
                    })    
                })

                _success(_levels)
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');

                _error(_err)
            })
        }

 
        _this.alignImages = function() {
            _this.spinModelWithCompass = false;
            _this.spinModelCompass = true;

            $(document).off("keydown")
            $(document).off("keyup")


            _this.listImage360 = []
            _this.image360InteractInfo = {
                isMousedown:false,
                isCtrlDown:false,
                startX:-1, 
                startY:-1, 
                heightTrigger: 100,   //set the minimun height occurs between screenY and startY to enable the vertical trigger
                widthTrigger: 50,   //set the minimun width occurs between screenX and startX to enable the horizontal trigger
                numberImagesLoaded: 0,
                numberImages: 0,
                percentageImagesLoaded: 0,
                levelSelected: 0,
                prevLevelSelected: 0,
                levels:0,
                autoRotateClockwise: false,
                autoRotateAntiClockwise: false,
                autoRotateSpeed: 0.3,
                autoRotateSpeedValues: {
                    options: {
                        showTicksValues: true,
                        stepsArray: [{value:0.3},{value:0.4},{value:0.5},{value:0.6},{value:0.7},{value:0.8},{value:0.9},{value:1.0}]
                    }
                },
                compassInfo: {}
            }

            _this.clusters = []
            _this.listImage360All = [];
            _this.spinModelLevels = [];

            //check and remove the percentageWatcher
            if (_this.percentageWatcher) {
                _this.percentageWatcher();
            }

            _this.spinModelDegrees = 0;
            _spinModelMap();

            _this.assetData = undefined;
            _this.assetDataSpinModelOffSite = undefined;

            $timeout(function() {
                _this.assetData = _this.listAssetDataSelectd[0];

                _this.image360InteractInfo.centralPointLatitude = _this.assetData.spinModelLatitude;
                _this.image360InteractInfo.centralPointLongitude = _this.assetData.spinModelLongitude;
    
                _this.clusters = _clusterListImage360ByAltitude(_this.listAssetDataDetails, _this.assetData.maxHighCluster)
    
                _this.clusters.forEach((c, index) => {
                    var _centralPoint = getLatLngCenter(c.list);

                    _this.clusters[index].list = _sortListImage360ByGeoPosition(_this.assetData, c.list, _this.image360InteractInfo, _centralPoint.latitude, _centralPoint.longitude)
    
                    //I'm checking if the images have the metadata about the gimbal yaw degree
                    var _hasImage360Degree = _hasListImage360GimbalYawDegree(c.list);
                    _this.clusters[index].info.compassBearingType = _hasImage360Degree ? 'CB':'AZ';

                    _this.clusters[index].info.prevIndexImgSelected = 0;
                    _this.clusters[index].info.indexImgSelected = 0;
    
                    _this.image360InteractInfo.numberImages += c.list.length;
                    _this.listImage360All.push.apply(_this.listImage360All, _this.clusters[index].list)
                })

                _buildSpinModelLevels(_this.assetData, _this.clusters, _res => {
                    _this.spinModelLevels = _res;
                });
    
                _this.image360InteractInfo.levels = _this.clusters.length;
    
                _this.image360InteractInfo.compassDegrees = _this.clusters[_this.image360InteractInfo.levelSelected].list[0].centralPointDegrees;
                _this.image360InteractInfo.backBearing = _this.clusters[_this.image360InteractInfo.levelSelected].list[0].backBearing;
                _this.image360InteractInfo.altitude = _this.clusters[_this.image360InteractInfo.levelSelected].list[0].altitude;
                _showFirstImage(_this.clusters[_this.image360InteractInfo.levelSelected].list)
    
                _this.percentageWatcher =  _image360Watcher(_this.clusters, _this.image360InteractInfo);
    
            }, 500)


        }

        function _clusterListImage360ByAltitude(_list, _k) {
            var _lists = [];
            var _averages = [];
            var _levels = 0;
            _list.forEach(_e => {
                var _element = _e.altitude;
                var _averageItem = {
                    value: undefined,
                    listIndex: undefined
                }

                if (_levels == 0) {
                    _levels = 1;
                    _lists[0] = [];
                    _lists[0].push(_e);
                    _averageItem.value = _element;
                    _averageItem.listIndex = _levels - 1;
                    _averages.push(_averageItem);
                } else {
                    var _found = false;
                    _averages.some((a, _index) => {
                        var _factor = Math.abs(_element - a.value);
                        if (_factor < _k) {
                            _found = true;
                            _averages[_index].value = (_averages[_index].value+_element)/2;
                            _lists[_index].push(_e);
                            return true;
                        }
                    })

                    if (!_found) {
                        _levels += 1;
                        _averageItem.value = _element;
                        _averageItem.listIndex = _levels-1;
                        _averages.push(_averageItem)

                        _lists[_levels-1] = [];
                        _lists[_levels-1].push(_e)
                    }
                }
            })

            _averages.sort((a, b) => {return b.value-a.value});
            
            var _clusters = [];            

            _averages.forEach((a, index) => {
                var _cluster = {
                    list: _lists[a.listIndex],
                    info: {
                        averageHight: a.value
                    }
                }
                _clusters.push(_cluster)

                console.log("level " + index, " average: "+a.value)
                _lists[a.listIndex].forEach(e => console.log(e.altitude))        
                console.log("--------------------------")    

            })

            return _clusters;
        }


        function _showFirstImage(_list) {
            var _img = _list[0];
            var _idFirstImage = "#_360-image-"+_img.id;



            utility.callFunctionEveryTime("$('"+_idFirstImage+"').get(0)", 200, function() {
                $("#_360-image-" + _img.id).show();
                $("#_360-image-"+_img.id).addClass("360-image-selected");
            })
        }


        function _updateWheelzoom(_clusters, _info) {
                var _list = _clusters[_info.levelSelected].list;
                var _levelInfo = _clusters[_info.levelSelected].info;
                if (!_list[_levelInfo.indexImgSelected].isZoomActive) {
                    _list[_levelInfo.indexImgSelected].isZoomActive = true
                    wheelzoom($(".360-image-selected"));
                }    
        }

        _this.zoomToggle = function(_clusters, _info, _isAutoRotate) {
            var _list = _clusters[_info.levelSelected].list;
            var _levelInfo = _clusters[_info.levelSelected].info;
            wheelzoom($(".360-image-selected"));                   
            _list[_levelInfo.indexImgSelected].isZoomActive = true;

            $("#_image360-canvas-bottom").mousemove(
                $.debounce(300, function() {
                    _updateWheelzoom(_clusters, _info)
                })
            )    
            $("#_spinModel-arrow-left").click( 
                $.debounce(300, function() {
                    _updateWheelzoom(_clusters, _info)
                })
            )
            $("#_spinModel-arrow-right").click( 
                $.debounce(300, function() {
                    _updateWheelzoom(_clusters, _info)
                })
            )

            if (_isAutoRotate) {
                $timeout(function() {
                    _updateWheelzoom(_clusters, _info)
                }, 300)                
            }
        }

        //check if the images has the GimbalYawDegree information 
        function _hasListImage360GimbalYawDegree(_list) {
            var _found = false;

            _list.some(e => {
                if (e.gimbalYawDegree == undefined || e.gimbalYawDegree == null) {
                    _found = true;
                    return true;
                }
            })

            return !_found;
        }


        function rad2degr(rad) { return rad * 180 / Math.PI; }
        function degr2rad(degr) { return degr * Math.PI / 180; }

        /**
         * @param latLngInDeg array of arrays with latitude and longtitude
         *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
         *   [longtitude2] ...]
         *
         * @return array with the center latitude longtitude pairs in 
         *   degrees.
         */
        function getLatLngCenter(_list) {
            var LATIDX = 0;
            var LNGIDX = 1;
            var sumX = 0;
            var sumY = 0;
            var sumZ = 0;

            for (var i=0; i<_list.length; i++) {
                var lat = degr2rad(_list[i].latitude);
                var lng = degr2rad(_list[i].longitude);
                // sum of cartesian coordinates
                sumX += Math.cos(lat) * Math.cos(lng);
                sumY += Math.cos(lat) * Math.sin(lng);
                sumZ += Math.sin(lat);
            }

            var avgX = sumX / _list.length;
            var avgY = sumY / _list.length;
            var avgZ = sumZ / _list.length;

            // convert average x, y, z coordinate to latitude and longtitude
            var lng = Math.atan2(avgY, avgX);
            var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
            var lat = Math.atan2(avgZ, hyp);

            var _ret = {
                latitude:rad2degr(lat), 
                longitude:rad2degr(lng)
            }
            // return ([rad2degr(lat), rad2degr(lng)]);
            return _ret
        }        
        
        //Sort the list of 360-images with respect the Geo Position 
        //starting the upper point and procede in clockwise way
        function _sortListImage360ByGeoPosition(_assetData, _list, _info, _centralPointLatitude, _centralPointLongitude) {
            var _listImage360 = []
            var _hasImage360Degree = _hasListImage360GimbalYawDegree(_list);

            _list.forEach(e => {
                var _element = e;
                var _spinModelOffSiteAngle = _assetData.spinModelOffSiteAngle?_assetData.spinModelOffSiteAngle:0;
                if (_hasImage360Degree) {
                    _element.centralPointDegrees = ((e.gimbalYawDegree + _spinModelOffSiteAngle + 360) % 360).toFixed(2);
                } else {
                    _element.centralPointDegrees = ((Number(azimuthFactory.calculateAzimuthByPoint(_info.centralPointLatitude, _info.centralPointLongitude, e.latitude, e.longitude)) + _spinModelOffSiteAngle + 180) % 360).toFixed(2);
                }
                _element.altitude = e.altitude.toFixed(2);
                _element.backBearing = ((180 + Number(_element.centralPointDegrees)) % 360).toFixed(2);

                _listImage360.push(_element)
            })

            _listImage360.sort((a, b) => {return a.centralPointDegrees-b.centralPointDegrees})
            
            console.log(_listImage360)

            return _listImage360;
        }

        function _image360Watcher(_clusters, _info) {
            var _percentageWatcher = $scope.$watch("_assetDataCtrl.image360InteractInfo.percentageImagesLoaded", function(_new, _old) {
                if (_new == 100) {
                    $("#_image360-container").removeClass("blur");
                    $("#_image360-progress-bar").remove();
                    $("#_image360-canvas-bottom").removeClass("not-allowed");
                    _this.image360AddListeners(_clusters, _info);


                    _this.zoomToggle(_clusters, _info);




                    $timeout(function() {
                        $(".360-image-selected").mousedown(_handler => {
                            if (_info.isCtrlDown) {
                                $("#_image360-canvas-bottom").trigger("mousedown", [_handler.screenX, _handler.screenY]);
                            }
                        })
                        $(".360-image-selected").mouseup(_handler => {
                            if (_info.isCtrlDown) {
                                $("#_image360-canvas-bottom").triggerHandler("mouseup", [_handler.screenX, _handler.screenY]);
                            }
                        })
            
                        $(".360-image-selected").mousemove(_handler => {
                            if (_info.isCtrlDown) {
                                $("#_image360-canvas-bottom").triggerHandler("mousemove", [_handler.screenX, _handler.screenY]);
                            }
                        });
                    }, 1000)        


                }
            })

            return _percentageWatcher;
        }


        _this.image360AddListeners = function(_clusters, _info) {

            utility.callFunctionEveryTime("$('#_image360-canvas-bottom').get(0)", 200, function() {
                
                    $(document).keydown(e => {
                        _info.isCtrlDown = true
                        if (!_info.isMousedown) {
                            $('.360-image-selected').addClass("grab");
                        } else {
                            $('.360-image-selected').addClass("grabbing");
                        }
                    })
        
                    $(document).keyup(e => {
                        _info.isCtrlDown = false
                            $('.360-image-selected').removeClass("grab");
                            $('.360-image-selected').removeClass("grabbing");
                    })
    

                    $("#_image360-canvas-bottom").mousedown((_handler, screenX, screenY) => {
                        if (_info.isCtrlDown) {
                            _info.isMousedown = true
                            _info.startX = screenX
                            _info.startY = screenY
                        }
                    })
                    $("#_image360-canvas-bottom").mouseup((_handler, screenX, screenY) => {
                        if (_info.isCtrlDown) {
                            _info.isMousedown = false
                            _info.startX = -1
                            _info.startY = -1
                        }
                    })



                    $("#_image360-canvas-bottom").mousemove((_handler, screenX, screenY) => {
                        var _levelInfo = _clusters[_info.levelSelected].info;
                        var _list = _clusters[_info.levelSelected].list;

                        if (_info.isMousedown && _info.isCtrlDown) {

                            //ctrl+mouse dragged toward right
                            if (screenX-_info.startX > _info.widthTrigger) {
                                _info.startX = screenX
                                _this.spinModelMoveRight(_clusters, _info);

                            //ctrl+mouse dragged toward left
                            } else if (_info.startX - screenX > _info.widthTrigger) {
                                _info.startX = screenX
                                _this.spinModelMoveLeft(_clusters, _info);
                            }

                            //ctrl+mouse dragged toward down
                            if (screenY-_info.startY > _info.heightTrigger) {
                                if (_info.levelSelected < _info.levels-1) {
                                    _info.prevLevelSelected = _info.levelSelected
                                    _info.levelSelected += 1

                                    _info.startY = screenY
                                    _spinModelMoveUpDownPostElaboration(_clusters, _info, _levelInfo, _list);
                                }
                            //ctrl+mouse dragged toward up
                            } else if (_info.startY - screenY > _info.heightTrigger)   {
                                if (_info.levelSelected > 0) {
                                    _info.prevLevelSelected = _info.levelSelected
                                    _info.levelSelected -= 1

                                    _info.startY = screenY
                                    _spinModelMoveUpDownPostElaboration(_clusters, _info, _levelInfo, _list);
                                }
                            }


                        }

                    })
            })
        }

        _this.spinModelAsset2DGoTo = function(_clusters, _info, _levelIndex) {
            var _levelInfo = _clusters[_info.levelSelected].info;
            var _list = _clusters[_info.levelSelected].list;

            _info.prevLevelSelected = _info.levelSelected
            _info.levelSelected = _levelIndex

            _spinModelMoveUpDownPostElaboration(_clusters, _info, _levelInfo, _list);
        }

        _this.spinModelAutoRotateClockwiseSwitch = function(_info) {
            _info.autoRotateClockwise = !_info.autoRotateClockwise;
            _info.autoRotateAntiClockwise = false;

            _spinModelAutoRotateClockwise(_this.clusters, _info);

            if (!_info.autoRotateClockwise) {
                _this.zoomToggle(_this.clusters, _info, true);
            }
        }

        function _spinModelAutoRotateClockwise(_clusters, _info) {

            if (_info.autoRotateClockwise) {
                $timeout(function() {
                    _this.spinModelMoveRight(_clusters, _info, true);
                    _spinModelAutoRotateClockwise(_clusters, _info);
                }, _info.autoRotateSpeed*1000)
            }
        }

        _this.spinModelAutoRotateStop = function(_info) {
            _info.autoRotateAntiClockwise = false;
            _info.autoRotateClockwise = false;

        } 

        _this.spinModelAutoRotateAntiClockwiseSwitch = function(_info) {
            _info.autoRotateAntiClockwise = !_info.autoRotateAntiClockwise;
            _info.autoRotateClockwise = false;

            _spinModelAutoRotateAntiClockwise(_this.clusters, _info);

            if (!_info.autoRotateAntiClockwise) {
                _this.zoomToggle(_this.clusters, _info, true);
            }
        }

        function _spinModelAutoRotateAntiClockwise(_clusters, _info) {

            if (_info.autoRotateAntiClockwise) {
                $timeout(function() {
                    _this.spinModelMoveLeft(_clusters, _info, true);
                    _spinModelAutoRotateAntiClockwise(_clusters, _info);
                }, _info.autoRotateSpeed*1000)
            }
        }


        _this.spinModelMoveRight = function(_clusters, _info, _isAutoRotate) {

            var _levelInfo = _clusters[_info.levelSelected].info;
            var _list = _clusters[_info.levelSelected].list;

            _levelInfo.prevIndexImgSelected = _levelInfo.indexImgSelected
            _levelInfo.indexImgSelected += 1

            if (_levelInfo.indexImgSelected >= _list.length) {
                _levelInfo.indexImgSelected = 0
            }

            _info.compassDegrees = _list[_levelInfo.indexImgSelected].centralPointDegrees
            _info.backBearing = _list[_levelInfo.indexImgSelected].backBearing;
            _info.altitude = _list[_levelInfo.indexImgSelected].altitude;
            $("#_compass_needle").css({'transform': 'rotate('+_info.compassDegrees+'deg)'});
            $("#_spinmodel_compass_needle").css({'transform': 'rotate('+_info.compassDegrees+'deg)'});

            _image360ChangeImage(_clusters, _info)
        }

        _this.spinModelMoveLeft = function(_clusters, _info, _isAutoRotate) {
            
            var _levelInfo = _clusters[_info.levelSelected].info;
            var _list = _clusters[_info.levelSelected].list;

            _levelInfo.prevIndexImgSelected = _levelInfo.indexImgSelected
            _levelInfo.indexImgSelected -= 1

            if (_levelInfo.indexImgSelected < 0) {
                _levelInfo.indexImgSelected = _list.length-1
            }


            _info.compassDegrees = _list[_levelInfo.indexImgSelected].centralPointDegrees
            _info.backBearing = _list[_levelInfo.indexImgSelected].backBearing;
            _info.altitude = _list[_levelInfo.indexImgSelected].altitude;

            $("#_compass_needle").css({'transform': 'rotate('+_info.compassDegrees+'deg)'});
            $("#_spinmodel_compass_needle").css({'transform': 'rotate('+_info.compassDegrees+'deg)'});
            

            _image360ChangeImage(_clusters, _info)
        }


        function _spinModelMoveUpDownPostElaboration(_clusters, _info, _levelInfo, _list) {
            _levelInfo = _clusters[_info.levelSelected].info;
            _list = _clusters[_info.levelSelected].list;

            _setLevelInfoRespectPrevLevel(_clusters, _info)
            _image360ChangeImage(_clusters, _info)
            _info.compassDegrees = _list[_levelInfo.indexImgSelected].centralPointDegrees
            $("#_compass_needle").css({'transform': 'rotate('+_info.compassDegrees+'deg)'});
        }

        function _setLevelInfoRespectPrevLevel(_clusters, _info) {
            var _prevLevelInfo = _clusters[_info.prevLevelSelected].info
            var _prevList = _clusters[_info.prevLevelSelected].list
            var _list = _clusters[_info.levelSelected].list

            var _prevDegrees = parseFloat(_prevList[_prevLevelInfo.indexImgSelected].centralPointDegrees)
            var _indexImgSelected = 0;
            _list.some((e, index) => {
                if (parseFloat(e.centralPointDegrees) >= _prevDegrees) {
                    _indexImgSelected = index;
                    return true;
                }
            })

            _clusters[_info.levelSelected].info.indexImgSelected = _indexImgSelected;
        }

        function _image360ChangeImage(_clusters, _info) {
            var _prevList;
            var _prevLevelInfo;
            var _prevIndexImgSelected;

            var _list;
            var _levelInfo;
            var _indexImgSelected;

            if (_info.prevLevelSelected != _info.levelSelected) {
                _prevList = _clusters[_info.prevLevelSelected].list
                _list = _clusters[_info.levelSelected].list

                _prevLevelInfo = _clusters[_info.prevLevelSelected].info
                _levelInfo = _clusters[_info.levelSelected].info

                _prevIndexImgSelected = _prevLevelInfo.indexImgSelected
                _indexImgSelected = _levelInfo.indexImgSelected

                _info.prevLevelSelected = _info.levelSelected
            } else {
                _prevList = _clusters[_info.levelSelected].list
                _list = _clusters[_info.levelSelected].list
                _levelInfo = _clusters[_info.levelSelected].info
                _prevIndexImgSelected = _levelInfo.prevIndexImgSelected
                _indexImgSelected = _levelInfo.indexImgSelected
            }


            $(".360-image-selected").off("mousedown")
            $(".360-image-selected").off("mouseup")
            $(".360-image-selected").off("mousemove")

            $("#_360-image-"+_prevList[_prevIndexImgSelected].id).hide();
            $("#_360-image-"+_prevList[_prevIndexImgSelected].id).removeClass("360-image-selected");

            $("#_360-image-"+_list[_indexImgSelected].id).show();
            $("#_360-image-"+_list[_indexImgSelected].id).addClass("360-image-selected");


            $(".360-image-selected").mousedown(_handler => {
                if (_info.isCtrlDown) {
                    $("#_image360-canvas-bottom").trigger("mousedown", [_handler.screenX, _handler.screenY]);
                }
            })
            $(".360-image-selected").mouseup(_handler => {
                if (_info.isCtrlDown) {
                    $("#_image360-canvas-bottom").triggerHandler("mouseup", [_handler.screenX, _handler.screenY]);
                }
            })

            $(".360-image-selected").mousemove(_handler => {
                if (_info.isCtrlDown) {
                    $("#_image360-canvas-bottom").triggerHandler("mousemove", [_handler.screenX, _handler.screenY]);
                }
            });
    

            if (!_this.spinModelWithCompass) {
                googlemaps.rotatePolygon(_this.image360InteractInfo.mapInfo.spinModelPolygon, (_info.compassDegrees - _this.spinModelDegrees))
            }

            _this.spinModelDegrees = _info.compassDegrees;
        }



        _this.image360Loaded = function(_info) {
            _info.numberImagesLoaded += 1
            _info.percentageImagesLoaded = ((_info.numberImagesLoaded)*100)/_info.numberImages
        }



        _this.generateSpinModel = function() {
            if (_this.isAssetDataToolEnabled(_this.toolName.spinModel)) {
                var _fns = [
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel',    'btn_color': 'btn-clear',   'btn_visible_if': "$innerStatus.section == 'map'" },
                    { 'btn_identifier': '_1_2', 'btn_msg': 'Next',      'btn_color': 'btn-light',   'btn_visible_if': "$innerStatus.section == 'map'", 'btn_fn': 'generateSpinModelNext', 'btn_close': false},
                    { 'btn_identifier': '_2_1', 'btn_msg': 'Cancel',    'btn_color': 'btn-clear',   'btn_visible_if': "$innerStatus.section == 'levels'" },
                    { 'btn_identifier': '_2_2', 'btn_msg': 'Back',      'btn_color': 'btn-light',   'btn_visible_if': "$innerStatus.section == 'levels'", 'btn_fn': 'generateSpinModelBack', 'btn_close': false},
                    { 'btn_identifier': '_2_3', 'btn_msg': 'Save',      'btn_color': 'btn-light',   'btn_visible_if': "$innerStatus.section == 'levels'", 'btn_fn': 'generateSpinModelFromPopup', 'btn_close': true, 'btn_confirm':true, 'btn_validation': 'validationSaveSpinModelFromPopup'},
                ];


                var _assetData = _this.listAssetDataSelectd[0]
                _assetData.latitude = _asset.latitude
                _assetData.longitude = _asset.longitude
                var _levels = _clusterListImage360ByAltitude(_this.listAssetDataDetails, 2.1)
                

                var _data = {
                    assetData: _assetData,
                    listAssetDataDetails: _this.listAssetDataDetails,
                    maxHighCluster: 2.1,
                    levels: _levels,
                    levelsInfo: _buildSpinModelLevelsInfo(_levels)
                }

                var _config = {
                    'funzione': {
                        btnCreateSpingModelLocationMap: _this.createSpingModelLocationMap(),
                        btnLevelListImage360ByAltitude: _this.levelListImage360ByAltitude(),
                        btnBuildSrcUrl: _this.getBuildSrcUrl()
                    },
                    'size': 'lg',
                    'title': 'Generate Spin Model',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_data),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset-data/popup/asset-data_spin-model_popup.html',
                    'innerStatus': {
                        'section': 'map'
                    }
                }
                popup.openModalBody(_config);
            }
        }        

        
        $scope.validationSaveSpinModelFromPopup = function (_popup) {
            var _ret = false;

            var v = fsValidator.newInstance();
            v.validate(_popup.data.description, 'description').isUndefined("This field can't be empty")
            _popup.data.errors = v.getErrors();

            v.success(function () {
                _ret = true;
            })

            return _ret;
        }

        $scope.generateSpinModelFromPopup = function(_popup) {
            var _assetData = _popup.data.assetData;
            var _newAssetData = {}

            _newAssetData.facilityId = _assetData.facilityId;
            _newAssetData.fileType="spin-model";
            _newAssetData.size = 0;
            _newAssetData.fileNumber = 1;
            _newAssetData.saveType = 'generated';
            _newAssetData.structureId = _assetData.structureId;
            _newAssetData.spinModelLatitude = _assetData.latitude
            _newAssetData.spinModelLongitude = _assetData.longitude
            _newAssetData.spinModelFdId = _assetData.id
            _newAssetData.maxHighCluster = _popup.data.maxHighCluster
            _newAssetData.description = _popup.data.description
            

            assetDataServices.saveAssetData(_identity.id, _newAssetData, _res => {
                var _spinModel = {
                    facilityDataId: _res.id,
                    levelsNumber: _popup.data.levelsInfo.length,
                }
                var _levelsInfo= {
                    levels: []
                }

                _popup.data.levelsInfo.forEach(e => {
                    var _item = {
                        averageHight: e.averageHight,
                        levelType: e.value,
                        levelName: _this.levelsTypeByKey[e.value].name
                    }
                    _levelsInfo.levels.push(_item)
                })
                _spinModel.levelsInfo = JSON.stringify(_levelsInfo)

                spinModelServices.saveSpinModel(_identity.id, _spinModel, _resSM => {
                    //comunicate to the popup that the saving spin-model task was correctly completed
                    _popup.success(_popup.data)

                    myAlert("Spin Model generated", 'info');
                    _assetDataResetAndRefresh();
                }, _errSM => {
                    myAlert("Error: " + _errSM.data.message, 'danger');
                })
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })
        }

        $scope.generateSpinModelNext = function(_popup) {
            switch (_popup.getInnerStatus('section')) {
                case 'map':
                    _popup.setInnerStatus('section', 'levels')
                    break;
            }
        }

        $scope.generateSpinModelBack = function(_popup) {
            switch (_popup.getInnerStatus('section')) {
                case 'levels':
                    _popup.setInnerStatus('section', 'map')
                    break;
            }
        }

        _this.getBuildSrcUrl = function() {
            return function(_details) {
                return _this.buildSrcUrl(_details)
            }
        }

        function _buildSpinModelLevelsInfo(_levels) {
            var _levelsInfo = []

            for(var i=0; i<_levels.length; i++) {
                var _item = {
                    list: angular.copy(_this.levelsType),
                    value: 'middle',
                    averageHight: _levels[i].info.averageHight
                }

                _levelsInfo[i] = _item
            }

            return _levelsInfo;
        }

        _this.levelListImage360ByAltitude = function() {
            return function(_popup) {
                _popup.data.levels = _clusterListImage360ByAltitude(_popup.data.listAssetDataDetails, _popup.data.maxHighCluster)

                _popup.data.levelsInfo = _buildSpinModelLevelsInfo(_popup.data.levels)
            }
        }

        _this.createSpingModelLocationMap = function() {
            return function (_canvasId, _popup) {
                var _assetData = _popup.data.assetData;

                //delete from _maps array, if exist, the previous google map object
                    var _index = utility.getIndexArrayElementByField(_maps, 'id', _canvasId);
                    if (_index != undefined) {
                        _maps.splice(_index, 1);
                    }

                    var _map;

                utility.callFunctionEveryTime("$('#_spinModelLocationPopup').get(0)", 200, function() {
                    googlemaps.initGoogleMap('#_spinModelLocationPopup', _m => {
                        //push the just created google map into _maps array
                        _maps.push({ id: _canvasId, map: _m });
                        _map = _m;
                
                        _map.setCenter(new google.maps.LatLng(_assetData.latitude, _assetData.longitude));
                
                        var _marker = _createSpinModelLocationMarker(_map, _assetData.latitude, _assetData.longitude)

                        var _listMarker = [_marker];
                        googlemaps.fitBounds(_map, _listMarker)
                
                        google.maps.event.addListener(_map, 'click', function (_event) {
                            _marker.setMap(null)
                            _marker = _createSpinModelLocationMarker(_map, _event.latLng.lat(), _event.latLng.lng())
                
                
                            _assetData.latitude = _event.latLng.lat();
                            _assetData.longitude = _event.latLng.lng();
                
                            $scope.$apply();
                        })
                    })    
                })
            }
        }
        
        function _createSpinModelLocationMarker(_map, _lat, _lng) {
        
            var _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                // icon: _icon,
                title: 'xxxx',
                lat: _lat,
                log: _lng
        
            });
        
        
            return _marker;
        }

        //----------------------------------------------- Align images end -----------------------------------------------------------------




        
        




        //----------------------------------------------- Nokia Report begin -----------------------------------------------------------------
        _this.showNokiaReport = function() {
            if (_this.isAssetDataToolEnabled(_this.toolName.nokiaReport)) {
                _this.viewShown = _this.viewFromAssetDataTool.changeTo(_this.views.nokiaReport)
                
                if (_this.viewShown == _this.views.nokiaReport) {
                    _createReport()
                }
            }
        }        

        _this.listFirst = [];
        _this.listSecond = [];            
        function _createReport() {
            var _indexFirst = 0;
            var _indexSecond = 1;

            if (_this.listAssetDataSelectd[0].createdAt > _this.listAssetDataSelectd[1].createdAt) {
                _indexFirst = 1;
                _indexSecond = 0;
            }

            _this.listFirst = _sortList(_this.listAssetDataDetails.filter(e => {return e.facilityDataId == _this.listAssetDataSelectd[_indexFirst].id}), _asset.latitude, _asset.longitude);
            _this.listSecond = _sortList(_this.listAssetDataDetails.filter(e => {return e.facilityDataId == _this.listAssetDataSelectd[_indexSecond].id}), _asset.latitude, _asset.longitude);


            _this.firstAssetData = _this.listAssetDataSelectd[_indexFirst];
            _this.secondAssetData = _this.listAssetDataSelectd[_indexSecond];


            //remove old event listener
            window.removeEventListener('message', $scope.assetMapperMessageRef, false )    
            
            $scope.assetMapperMessageRef = _this.assetMapperMessage()

            //create new event listener
            window.addEventListener('message', $scope.assetMapperMessageRef)   
            
            


            $http.get(expanseConst.softeqUrl + "facility/info/"+_asset.publicId+"?liteToken=" + _identity.liteToken, {
                withCredentials: true,
                mode: 'cors',
                contentType: 'text/plain',
            }
            ).success(res => {

                assetMapperModelData = {
                    pathToAssetMapperModel: res.pathToAssetMapperModel,
                    latitude: res.latitude,
                    longtitude: res.longitude,
                    name: res.name,
                    publicId: res.publicId,
                    operationResult: res.operationResult,
                }
                
                _this.getAssetMapperIframeUrl("#_asset-mapper");
            }).error(err => {
                console.log(err)
            })

        }

        _this.nokiaReportTasks = [
            {
                group: 'Removal Instructions',
                tasks: [
                    {name:"1) Remove Huawei 3G Antennas from head frame 2", passed:false, failed: false},
                    {name:'2) Remove all associated power cabling', passed:false, failed: false},
                    {name:"3) Remove all comm's cabling and un-plug from patch panel 9", passed:false, failed: false},
                ]
            },
            {
                group: 'Installation Instructions',
                tasks: [
                    {name:'1) Install Nokia Airspace 5G Antennas onto head frame 2', passed:false, failed: false},
                    {name:'2) Check Azimuth of each antenna', passed:false, failed: false},
                    {name:'3) Check Tilt Antenna', passed:false, failed: false},
                    {name:'4) Run power cabling to UPS unit 2', passed:false, failed: false},
                    {name:"5) Run comm's cables to patch panel 9: Sockets 12,14 and 15", passed:false, failed: false},
                ]
            }
        ]

        /* View in fullscreen */
        _this.fullscreenShowned = false;
        _this.openFullscreen = function(_src) {
            _this.fullscreenShowned = true;
            $("#_nr_fullscreen").attr("src",_src)
            var elem = document.getElementById("_nr_fullscreen")

            if (elem.requestFullscreen) {
            elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
            }
        }

        function _sortList(_list, _lat, _lng) {
            var _listImage360 = [];

            _list.forEach(e => {
                var _element = e;
                _element.degrees = azimuthFactory.calculateAzimuthByPoint(_lat, _lng, e.latitude, e.longitude)
    
                _listImage360.push(_element)
            })

            _listImage360.sort((a, b) => {return a.degrees-b.degrees})

            return _listImage360
        }        

        _this.nokiaReportSelectImg = function(_details, _type) {
            _details.isSelected = !_details.isSelected;

            _this.beforeId = _details.isSelected?_details.id:undefined;
        }

        _this.nokiaReportForensic = function() {
            var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            var _msg = 'This function is not enabled for this account';

            popup.openModal('sm', 'Forensic Point Compare', _msg, _controllerName, JSON.stringify(_fns));
        }

        _this.nokiaReportSave = function() {
            var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            var _msg = 'This function is not enabled for this account';

            popup.openModal('sm', 'Save Report', _msg, _controllerName, JSON.stringify(_fns));
        }
        //----------------------------------------------- Nokia Report end -------------------------------------------------------------------


        //----------------------------------------------- Documents begin -------------------------------------------------------------------    
            _this.openAssetDocument = function() {
                return function(_assetData, _detail) {
                    $window.open(_this.buildSrcUrl(_detail, _assetData).url, '_blank');
                }
            }
    
            _this.seeAssetDocuments = function(_assetData) {
                assetDataFactory.getAssetDataDetails(_assetData.id, _res => {
                    var _listAssetDataDetails = _res;

                    _listAssetDataDetails.forEach(e => {
                        var _st = e.fileName;
                        e.fileExtension = _st.substring(_st.lastIndexOf(".")+1, _st.length)
                    })
        
                    var _fns = [
                        { 'btn_identifier': '_1_1', 'btn_msg': 'Cancel',    'btn_color': 'btn-clear'},
                    ];
        
        
                    var _data = {
                        assetData: _assetData,
                        listAssetDataDetails: _listAssetDataDetails,
                    }
        
                    var _config = {
                        'funzione': {
                            // btnBuildSrcUrl: _this.getBuildSrcUrl(),
                            openAssetDocument: _this.openAssetDocument()
                        },
                        'size': 'lg',
                        'title': 'Documents',
                        'ctrl': _controllerName,
                        'data': JSON.stringify(_data),
                        'fns': JSON.stringify(_fns),
                        'htmlPage': 'views/asset-data/popup/asset-data_documents_popup.html',
                    }
                    popup.openModalBody(_config);            
                });
    
            }

        //----------------------------------------------- Documents end ---------------------------------------------------------------------


        _this.downloadAssetData = function(_assetData) {
            var _detail = _this.listAssetDataDetails.filter(e => e.facilityDataId == _assetData.id)[0];
            $window.open(_this.buildSrcUrl(_detail, _assetData).url, '_blank'); 

//             assetDataServices.getPresignedUrl(_assetData.id, 1000*20*60, _res => {
//                 $window.open($sce.trustAsResourceUrl(_res), '_blank'); 
//             });
        }

        //=========================================== IFRAME SPIN MODEL begin===========================================================
        _this.initSpinModelFrame = function() {
            var _fdId = $stateParams['fd_id'];
                assetDataServices.getAssetDataById(_fdId, _resFd => {
                    assetServices.getAsset(_resFd.facilityId, _resF => {
                        _assetId = _resF.id;

                        customerServices.getCustomer(_resF.companyId, _resC => {
                            _customerId = _resC.id;

                            assetDataServices.getAssetDataFileTypes(_res => {
                                _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');

                                _this.assetDataSelected( _resFd); 
                            }, _err => {
                                _error(_err)
                            })
                        }, _err => {
                            _error(_err)
                        })
                    }, _err => {
                        _error(_err)
                    })
                })
        }
        //=========================================== IFRAME SPIN MODEL end=============================================================


        //=========================================== IFRAME COMPASS VIEWER begin ===========================================================
        _this.initCompassViewerFrame = function() {
            _this.viewShown = _this.views.compassViewer
            var _listAssetData = [];

            var _msg = {
                command: 'initCompassViewerFrame'
            }
            iframeComunication.sendMessage(JSON.stringify(_msg));

            
            //create new event listener
            window.addEventListener('message', function(event) {
                var data;

                try {
                    data = JSON.parse(event.data);
                } catch(error){}

                if (!data) {
                    return;
                }

                switch (data.command) {
                    case "sendRefsCompassViewerFrame": 
                        _getInfoCompassViewerFrame(_listAssetData, data.listAssetData);
                        break;
                }
            })   
        }

        function _getInfoCompassViewerFrame(_listAssetData, _listAssetDataFromIFrame) {
            //Check if some _listAssetDataFromIFrame's element has been removed
            //and update the compass-viewer map
            _listAssetData.forEach((a, index) => {
                if (_listAssetDataFromIFrame.filter(e => e.fd_id == a.id).length==0) {
                    _this.assetDataSelected( a); 
                    _listAssetData.splice(index, 1);
                }
                
            })

            //Check if some _listAssetDataFromIFrame's element has been added
            //and update the compass-viewer map
            _listAssetDataFromIFrame.forEach(e => {                

                if (_listAssetData.filter(a => e.fd_id == a.id).length==0) {
                    assetDataServices.getAssetDataById(e.fd_id, _resFd => {
                        _listAssetData.push(_resFd);
    
                        //Get the _asset, _customerId just one time
                        if (!_asset) {
                            assetServices.getAsset(_resFd.facilityId, _resF => {
                                _asset = _resF;
                                _assetId = _resF.id;
            
                                customerServices.getCustomer(_resF.companyId, _resC => {
                                    _customerId = _resC.id;
            
                                    assetDataServices.getAssetDataFileTypes(_res => {
                                        _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');
            
                                        _this.assetDataSelected( _resFd); 
        
                                        _this.switchCompassViewer(true);
                                    }, _err => {
                                        _error(_err)
                                    })
                                }, _err => {
                                    _error(_err)
                                })
                            }, _err => {
                                _error(_err)
                            })
    
                        } else {
                            _this.assetDataSelected( _resFd); 
                        }
                    })    
    
                }
            })
        }

        //=========================================== IFRAME COMPASS VIEWER end =============================================================


        //=========================================== AUTODESK REVIT VIEWER begin =============================================================
        var viewer;
        var md_ViewerDocument;
        var md_viewables;


        _this.showAutodeskViewer = function(_assetData) {
            _this.viewShown='autodeskViewer'
            _this.viewSelectedId = undefined;
            _this.viewSelectedIdPrec = undefined;

            var _options;

            autodeskServices.getAuthToken(_res => {
                _options = {
                    env: 'AutodeskProduction',
                    api: 'derivativeV2',  // for models uploaded to EMEA change this option to 'derivativeV2_EU'
                    getAccessToken: function(onTokenReady) {
                        var token = _res;
                        var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                        onTokenReady(token, timeInSeconds);
                    }
                };

                _autodeskInitialize(_options, _assetData.autodeskUrl);
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })
    
                    
        }

        function _autodeskInitialize(_options, _documentId) {
            Autodesk.Viewing.Initializer(_options, function() {
                utility.callFunctionEveryTime("$('#forgeViewer').get(0)", 200, function() {
                    var htmlDiv = document.getElementById('forgeViewer');
                    viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                    var startedCode = viewer.start();
                    if (startedCode > 0) {
                        console.error('Failed to create a Viewer: WebGL not supported.');
                        return;
                    }
                
                    console.log('Initialization complete, loading a model next...');
                    
                    Autodesk.Viewing.Document.load("urn:"+_documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
                    
                })
            });    
        }
    
        function onDocumentLoadSuccess(viewerDocument) {
            var viewerapp = viewerDocument.getRoot();
            
            md_ViewerDocument=viewerDocument; // Hold the viewerDocument in a global variable so that we can access it within SelectViewable() 
            md_viewables = viewerapp.search({'type':'geometry'});

            if (md_viewables.length === 0) {
                console.error('Document contains no viewables.');
                return;
            }

            viewer.loadDocumentNode(viewerDocument, md_viewables[0]);



            if (md_viewables.length > 1) {
                _this.md_viewables = [];
                md_viewables.forEach((e, index) => {
                    var _item = {
                        id: e.id,
                        name: e.data.name
                    }

                    if (index != 0) _this.md_viewables.push(_item);
                });

                $scope.$apply();

            }
        }
        
        function onDocumentLoadFailure() {
            console.error('Failed fetching Forge manifest');
        }                    

        _this.selectViewable = function() {
            
            // Load another viewable from selectedIndex of drop-down.
            var _index
            if (md_viewables && _this.viewSelectedId != _this.viewSelectedIdPrec) {
                _this.viewSelectedIdPrec = _this.viewSelectedId;

                md_viewables.some((e, index) => {
                    if (e.id == _this.viewSelectedId) {
                        _index = index;
                        return true;
                    }
                })
                viewer.loadDocumentNode(md_ViewerDocument, md_viewables[_index]);
    
            }
        }
        //=========================================== AUTODESK REVIT VIEWER end =============================================================

        //=========================================== INVENTORY VIEWER begin =============================================================
        _this.inventory = function() {
            _this.viewShown = _this.viewFromAssetDataTool.setViewByAssetData(_this.views.inventory)

            _this.inventoryInit(_this.views.inventory);            
        }

        _this.inventoryFast = function() {
            _this.viewShown = _this.viewFromAssetDataTool.setViewByAssetData(_this.views.inventoryFast)

            _this.inventoryInit(_this.views.inventoryFast);            
        }

        _this.inventoryFastReset = function() {
            _this.inventoryData.listInventoryFastItem = inventoryFactory.inventoryFastReset(_this.inventoryData)
            _this.inventoryData.getInventoryFastItemTableParams.init(_this.inventoryData.listInventoryFastItem);
            _this.inventoryData.getInventoryFastItemTableParams.refresh();
}

        _this.initInventoryFastIFrame = function() {
            var _assetId = Number($stateParams['asset_id']);
            var _inspectingCompanyId = Number($stateParams['inspecting_company_id']);

            assetServices.getAsset(_assetId, _res => {
                var _config = {
                    asset: _res,
                    inspectingCompanyId: _inspectingCompanyId
                }

                _this.inventoryInit(_this.views.inventoryFast, true, _config);            
            })
        }


        _this.inventoryInit = function(_inventoryType, _isIframe, _iframeConfig) {
            //This object hold all the info about the inventory
            _this.inventoryData = {
                controllerName: _controllerName,
                hasInventoryFolder: false,
                viewType: undefined,
                inspectingCompanyId: _identity.companyId,
                asset: _this.asset,
                inventoryType: _inventoryType,
                inventoryItemsShowned: false,
                isIframe: _isIframe,

                getInventoryStatusTypeByKey: inventoryFactory.getInventoryStatusTypeByKey(),
                getInventoryItemTableParams: inventoryFactory.getInventoryItemTableParams(),
                getListProduct: inventoryFactory.getListProduct(_inventoryType),
                setInventoryProductName: inventoryFactory.setInventoryProductName(_inventoryType),
                showInventoryItemDetails: inventoryFactory.showInventoryItemDetails(),
                addInventoryItemExtraInfo: inventoryFactory.addInventoryItemExtraInfo(),
                getInventoryItemExtraInfoLabelByKey: inventoryFactory.getInventoryItemExtraInfoLabelByKey(),
                getProductIndicatorTypeByKey: inventoryFactory.getProductIndicatorTypeByKey(),
                getInventoryGroupById: inventoryFactory.getInventoryGroupById(),
                getStructureById: inventoryFactory.getStructureById(),
            };

            if (_isIframe) {
                _this.inventoryData.asset = _iframeConfig.asset;
                _this.inventoryData.inspectingCompanyId = _iframeConfig.inspectingCompanyId;
            }


            //Get all the info used into the inventory screen                
            inventoryFactory.init(_inventoryType, _this.inventoryData.asset.id, _this.inventoryData.inspectingCompanyId, 'wind-turbines', _res => {
                _this.inventoryData.listInventoryItem= _res.listInventoryItem;
                _this.inventoryData.listInventoryStatusType= _res.listInventoryStatusType;
                _this.inventoryData.listInventoryItemExtraInfoLabel= _res.listInventoryItemExtraInfoLabel;
                _this.inventoryData.listInventoryGroup= _res.listInventoryGroup;
                _this.inventoryData.listProductGroupInventoryGroup= _res.listProductGroupInventoryGroup;
                _this.inventoryData.arrayInventoryFieldByInventoryGroupId= _res.arrayInventoryFieldByInventoryGroupId;
                _this.inventoryData.listProductGroup= _res.listProductGroup;
                _this.inventoryData.listProductIndicatorType= _res.listProductIndicatorType;
                _this.inventoryData.listAssetStructure = _res.listAssetStructure;

                _this.inventoryData.getInventoryItemTableParams.init(_this.inventoryData.listInventoryItem);
                _this.inventoryData.getInventoryItemTableParams.refresh();

                if (_inventoryType == _this.views.inventoryFast) {
                    _this.inventoryData.listAllProduct = _res.listAllProduct;

                    _this.inventoryData.listInventoryFastItem = inventoryFactory.inventoryFastInit(_this.inventoryData)
                    _this.inventoryData.getInventoryFastItemTableParams = inventoryFactory.getInventoryFastItemTableParams()
                    // _this.inventoryData.listInventoryFastItem.forEach(e => e.getListProduct = inventoryFactory.getListProduct(_inventoryType))
    
                    _this.inventoryData.getInventoryFastItemTableParams.init(_this.inventoryData.listInventoryFastItem);
                    _this.inventoryData.getInventoryFastItemTableParams.refresh();
                }        
            });


            if (_this.listAssetDataSelectd && _this.listAssetDataSelectd.length==1) {

                var _assetData = _this.listAssetDataSelectd[0];
                var _fileType = _assetData.fileType;
                var _assetDataId;
                _this.inventoryData.viewType = _fileType;

                //build the Spin Model or the Static Imagery screen, based on the AssetData.fileType
                if (_fileType=='spin-model') {
                    _assetDataId = _assetData.spinModelFdId;

                    assetDataServices.getAssetDataById(_assetDataId, _res => {
                        _this.inventoryData.hasInventoryFolder = _res.hasInventory;
                    })

                    $timeout(function() {
                        _this.alignImages();                   
                    }, 500)    
                } else if (_fileType=='static_imagery') {
                    _assetDataId = _assetData.id;
                    _this.inventoryData.hasInventoryFolder = _assetData.hasInventory;
                    _this.wheelZoomRef = undefined;

                    $timeout(function() {
                        _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
                    }, 500)    
                }

                //Get all the Objects Detected from the DNNs into the imagery
                inventoryFactory.getObjectsDetected(_assetDataId, _identity.companyId, _res => { 
                    _this.inventoryData.listObjectDetected = _res;

                    switch (_fileType) {
                        case 'static_imagery':
                            _this.setInventoryAssetDataDetailsSelected(_this.listAssetDataDetails, _this.inventoryData)
                            break;
                        case 'spin-model':
                            $scope.$watch("_assetDataCtrl.image360InteractInfo.percentageImagesLoaded", function(_new, _old) {
                                if (_new == 100) {
                                    _this.setInventoryAssetDataDetailsSelectedWithSpinModel(_this.clusters, _this.image360InteractInfo, _this.inventoryData)
                                }
                            })
                            break;
                    }

                    _getDatasetObjectsDetected(_assetDataId,  _res, _this.listAssetDataDetails);
                    
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);        
                })
            } 

            //Get all the InventoryItem Tag Map about the inspecting company
            inventoryServices.getInventoryItemTagMap(_identity.companyId, _res => {
                _this.inventoryData.listInventoryItemTagMap = _res;

                myLog("Inventory Item Tag Map list:", _res);
            }, _err => {

                myAlert("Error: " + _err.data.message, 'danger');
                myThrow(_err);
            });
        }




        var _showMarkerAreaRef 

        _this.editInventoryItem = function(_inventoryItem, _inventoryData) {
            inventoryFactory.editInventoryItem(_identity.companyId, _inventoryItem, _inventoryData);

            utility.callFunctionEveryTime("$('#_lapixxx').get(0)", 300, function() {    
                $("body>#_lapixxx_src").remove(); //remove the previous element moved under body
          
                $("#_lapixxx_src").css("position", "absolute")
                $("#_lapixxx_src").width($("body").width()/2)
                $("#_lapixxx_src").height($("body").height()/2)
                $("#_lapixxx_src").css("top", $("body").height())
                $("#_lapixxx_src").appendTo($("body"))
                $("#_lapixxx_src").css("visibility", "visible")
                var sourceImage = document.getElementById("_lapixxx_src");                
                    setSourceImage(sourceImage);
    
                    var sampleImage = document.getElementById("_lapixxx");

                    var _markers = undefined;
                    if (_this.inventoryData.inventoryItemSelected.imageMarkers) {
                        _markers = JSON.parse(_this.inventoryData.inventoryItemSelected.imageMarkers);
                    }                        

                    if (_this.inventoryData.inventoryItemSelected.imageUrl) {
                        renderImageSrc(sampleImage, sourceImage, _markers);
                    }

                    sampleImage.removeEventListener("click", _showMarkerAreaRef );

                    sampleImage.addEventListener("click", _showMarkerAreaRef=function() {
                        _markers = undefined;
                        if (_this.inventoryData.inventoryItemSelected.imageMarkers) {
                            _markers = JSON.parse(_this.inventoryData.inventoryItemSelected.imageMarkers);
                        }                        

                        showMarkerArea(sampleImage, sourceImage, _markers)
                    });
      
              })            
        }

        _this.deleteInventoryItem = function(_inventoryItem, _inventoryData) {
            inventoryFactory.deleteInventoryItem(_inventoryItem, _inventoryData);
            
        }

        _this.addInventoryItem = function (_inventoryData) {
            inventoryFactory.addInventoryItem(_inventoryData)
        }

        //Close the add/edit screen's section of inventory's item 
        _this.clearInventoryItem = function(_inventoryData) {
            inventoryFactory.clearInventoryItem(_inventoryData);
        }

        _this.saveInventoryItem = function (_inventoryData) {
            inventoryFactory.saveInventoryItem(_inventoryData, _identity.companyId)
        }

        //Close the Inventory screen
        _this.closeInventory = function(_inventoryData, _customerId) {
            inventoryFactory.closeInventory(_inventoryData, _res => {
                _this.viewShown = undefined;
                _this.listAssetDataSelectd.forEach(e => {
                    _this.assetDataSelected(e)
                    _this.setAssetDataViewConfig(e, true)
                });
            })
        }


        function _setInventoryItemForeignKeys(_listInventoryItem) {
            if (_this.inventoryData.listAssetStructure && _this.inventoryData.listInventoryStatusType && _this.inventoryData.listProductIndicatorType) {
                _listInventoryItem.forEach(e => {
                    e.structureName = _this.getStructureById(_this.inventoryData.listAssetStructure, e.structureId).name;
                    e.statusValue = _this.getInventoryStatusTypeByKey(_this.inventoryData.listInventoryStatusType, e.status).value;
                    e.indicatorValue = _this.inventoryData.functions.getProductIndicatorTypeByKey(_this.inventoryData.listProductIndicatorType, e.productIndicatorKey).value;
                })
            } else {
                $timeout(function() {
                    _setInventoryItemForeignKeys(_listInventoryItem)
                }, 300)
            }

        }


        function _getDatasetObjectsDetected(_assetDataId, _listObjectDetected, _listAssetDataDetails) {
            if ($scope.isFeatureEnabled($scope.listFeatureKey.autoInventory)) {
                inventoryFactory.getDatasetObjectsDetected(_assetDataId, _listObjectDetected, _res => {
                    _this.inventoryData.listDatasetObjectDetected = _res;
    
                    _this.inventoryData.listDatasetObjectDetectedToShow = [];
    
                    _res.forEach(e => {
                        if (utility.getIndexArrayElementByField(_this.inventoryData.listDatasetObjectDetectedToShow, 'color', e.color) == undefined) {
                            _this.inventoryData.listDatasetObjectDetectedToShow.push(e)
                        }
    
                    })
    
                    _listAssetDataDetails.forEach(e => {
                        var _ods = _listObjectDetected.filter(f => f.facilityDataDetailsId == e.id);
                        //  utility.getArrayElementByField(_listObjectDetected, 'facilityDataDetailsId', e.id);
    
                        if (_ods ) {
                            var _found = false;
                            _ods.some(o => {
                                if (utility.getIndexArrayElementByField(_res, 'id', o.id) != undefined) {
                                    _found = true;
                                    return true;
                                }
                            })
    
                            e.hasAutoInventoryImage = _found;
                        }
                    })
    
                    console.log("")
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                })                    
            }
        }


        _this.setInventoryAssetDataDetailsSelected = function (_listAssetDataDetails, _inventoryData) {
            _inventoryData.assetDataDetailsSelected = _listAssetDataDetails[_this.imgIndex]
        }

        _this.setInventoryAssetDataDetailsSelectedWithSpinModel = function (_clusters, _info, _inventoryData) {
            var _levelInfo = _clusters[_info.levelSelected].info;
            var _list = _clusters[_info.levelSelected].list;
    
            _inventoryData.assetDataDetailsSelected = _list[_levelInfo.indexImgSelected]
        }

        _this.addImageToInventoryItem = function (_inventoryData, _fdd) {
            _inventoryData.inventoryItemSelected.imageUrl = _this.buildSrcUrl(_fdd).cleanUrl + '/medium/'+_fdd.fileName;
            _inventoryData.inventoryItemSelected.imageMarkers = undefined;

        }


        _this.addObjectsToInventoryItem = function (_inventoryData, _fddId) {
            var _confirm = popupConfirm('Add Objects to Inventory Item', 'Do you want add the objects detected into the Inventory? ')
            $mdDialog.show(_confirm).then(_yes => {
                var _listInventoryItem = [];

                _inventoryData.listObjectDetected.forEach(e => {
                    if (e.facilityDataDetailsId == _fddId && e.isSelected) {
                        var _structure = utility.getArrayElementByField(_inventoryData.listAssetStructure,'structureTypeKey','complete-asset');
                        if (!_structure) {
                            _structure = utility.getArrayElementByField(_inventoryData.listAssetStructure,'name','Complete Asset');
                        }

                        var _inventoryItem = {
                            facilityId: _asset.id,
                            structureId: _structure.id,
                            productName: e.tagValue,
                            quantity: e.counts,
                            status: 'installed',
                        }
                        
                        _listInventoryItem.push(_inventoryItem);    
                    }
                })

                if (_listInventoryItem.length>0) {
                    inventoryServices.saveInventoryItems(_identity.id, _listInventoryItem, _res => {
                        myAlert("Objects added into Inventory", 'info');
    
                        _res.forEach(e => {
                            _updateInventoryItemAfterActions(_inventoryData.listInventoryItem, e)
                        })
    
                        _inventoryData.getInventoryItemTableParams.refresh();
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                    })    
                }
            }, _no => {
            });
        }

        _this.isObjectDetectedAvailableToAutoInventory = function(_listInventoryItemTagMap, _objectDetected) {
            if (_listInventoryItemTagMap) {
                return _listInventoryItemTagMap.filter(e => e.fddtKey == _objectDetected.tagKey && e.isAutoInventoryAvailable).length==1
            } else {
                return false;
            }
        }

        _this.objectDetectedSelected = function(_objectDetected) {
            _objectDetected.isSelected = !_objectDetected.isSelected;
        }

        _this.checkObjectsDetectedAvailableToAutoInventory = function(_listInventoryItemTagMap, _listObjectDetected, _fddId) {
            if (_listInventoryItemTagMap && _listInventoryItemTagMap.length>0 && _listObjectDetected) {
                return _listObjectDetected.filter(e => {
                    var _inventoryItemTagMap = utility.getArrayElementByField(_listInventoryItemTagMap,'fddtKey', e.tagKey);
    
                    if (e.facilityDataDetailsId == _fddId && _inventoryItemTagMap && _inventoryItemTagMap.isAutoInventoryAvailable) {
                        return true;
                    }
                     
                }).length>0;    
            } else {
                return false;
            }
        }

        function _updateInventoryItemAfterActions(_listInventoryItem, _inventoryItemNew) {
            //get the asset index into the array
            var _index = utility.getIndexArrayElementByField(_listInventoryItem, 'id', _inventoryItemNew.id);

            if (_index != undefined) {
                //update the customer at _index position into the customers list
                _listInventoryItem[_index] = _inventoryItemNew;
            } else {
                //add the new customer because it wasn't find into customers list
                _listInventoryItem.push(_inventoryItemNew);
            }


            inventoryServices.getInventoryItemExtraInfoLabel(_identity.companyId, _res => {
                _this.inventoryData.listInventoryItemExtraInfoLabel = _res;


                myLog("Inventory Item Extra Info Label's list:", _res);
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
                myThrow(_err);
            });
        }

        _this.saveInventoryFast = function (_inventoryData) {
            inventoryFactory.saveInventoryFastItem(_inventoryData, _identity.companyId)
        }

        _this.deleteInventoryFastItem = function(_inventoryData, _index) {
            inventoryFactory.deleteInventoryFastItem(_inventoryData, _index);
        }

        _this.addInventoryFastItem = function(_inventoryData) {
            inventoryFactory.addInventoryFastItem(_inventoryData);
        }

        _this.showInventoryItemsIntoInventoryFast = function(_inventoryData) {
            inventoryFactory.showInventoryItemsIntoInventoryFast(_inventoryData);
        }

        //=========================================== INVENTORY VIEWER end ===============================================================

        //=========================================== DYNAMIC CAD EDITOR begin =============================================================
        _this.showDynamicCADEditor = function(_asset) { 
            _this.viewShown = _this.viewFromAssetDataTool.setViewByAssetData(_this.views.dynamicCad)
            
            // _this.assetSelected = _asset;
            // $scope.assetIdSelected = _asset.id;

            _this.dynamicCADInfo = {
                asset: _this.asset,
                inspectingCompanyId: _identity.companyId,
                dataSource: undefined,
                towerSelected: undefined,
                headframeSelected: undefined,     
                antennaSelected: undefined, 
                objectSelected: {
                    translation: {
                        axisYPrev:0,
                        axisY:0    
                    },
                    rotation: {
                        axisXPrev:0,
                        axisX:0,    
                        axisYPrev:0,
                        axisY:0    
                    }
                },        
                isPropertiesPanelShown: true,
            }

            dynamicCADFactory.init('_editor_3d_editor_player', $scope, _this.dynamicCADInfo, _res => {

                // _this.dynamicCADInfo.dropdownMenuList = [
                //     {optionName:'a1'},
                //     {optionName:'a2'},
                //     {
                //         optionName:'a3',
                //         submenu: [
                //             {optionName:'a3 s1'},
                //             {optionName:'a3 s2'}
                //         ]
                //     }
                // ]                
                if (_res.sceneLoadedFromDB) $scope.$apply()
            });

            if (_this.listAssetDataSelectd && _this.listAssetDataSelectd.length==1) {

                var _assetData = _this.listAssetDataSelectd[0];
                var _fileType = _assetData.fileType;
                var _assetDataId;
                _this.dynamicCADInfo.viewType = _fileType;

                //build the Spin Model or the Static Imagery screen, based on the AssetData.fileType
                if (_fileType=='spin-model') {
                    _assetDataId = _assetData.spinModelFdId;

                    assetDataServices.getAssetDataById(_assetDataId, _res => {
                        _this.dynamicCADInfo.hasInventoryFolder = _res.hasInventory;
                    })

                    $timeout(function() {
                        _this.alignImages();                   
                    }, 500)    
                } else if (_fileType=='static_imagery') {
                    _assetDataId = _assetData.id;
                    _this.dynamicCADInfo.hasInventoryFolder = _assetData.hasInventory;
                    _this.wheelZoomRef = undefined;

                    $timeout(function() {
                        _this.showImg(_this.listAssetDataDetails[_this.imgIndex])
                    }, 500)    
                }
            } 
        } 

        _this.selectTower = function(_tower) {
            _this.dynamicCADInfo.towerSelected = _tower;
            dynamicCADFactory.changeTower(_tower, _this.dynamicCADInfo, _this.asset.id);
        }

        _this.selectHeadframe = function(_headframe) {
            _this.dynamicCADInfo.headframeSelected = _headframe;
            dynamicCADFactory.changeHeadframe(_headframe, _this.dynamicCADInfo);
        } 

        // _this.selectAntenna = function(_antenna, _attachDotId) {
        _this.selectAntenna = function(_params) {
            _this.dynamicCADInfo.antennaSelected = _params.antenna;
            dynamicCADFactory.selectAntenna(_params.antenna, _params.attachDotId); 
        } 

        _this.deleteAntenna = function(_clickNode) {
            dynamicCADFactory.deleteAntenna(_clickNode);
        }

        _this.transformTranslation = function(_objectSelected) {
            dynamicCADFactory.setTransformTranslation([0, _objectSelected.translation.axisY-_objectSelected.translation.axisYPrev,0])
            _objectSelected.translation.axisYPrev = _objectSelected.translation.axisY
        }


        _this.transformTranslationCompass = function(_object) {
            dynamicCADFactory.setTransformTranslationCompass([0, _object.translation.axisY-_object.translation.axisYPrev,0])
            _object.translation.axisYPrev = _object.translation.axisY
        }

        _this.transformRotation = function(_objectSelected) {
            dynamicCADFactory.setTransformRotation([_objectSelected.rotation.axisX-_objectSelected.rotation.axisXPrev, _objectSelected.rotation.axisY-_objectSelected.rotation.axisYPrev,0])
            _objectSelected.rotation.axisXPrev = _objectSelected.rotation.axisX
            _objectSelected.rotation.axisYPrev = _objectSelected.rotation.axisY
        }

        _this.saveFromEditor3DModel = function() {
            dynamicCADFactory.saveScene(_identity.id, _this.dynamicCADInfo);
        }

        _this.closeEditor3DModel  = function() { 
            _this.viewShown = undefined;
            _this.listAssetDataSelectd.forEach(e => {
                _this.assetDataSelected(e)
                _this.setAssetDataViewConfig(e, true)
            });
        }

        _this.activateCompassBearing = function() {
            dynamicCADFactory.activateCompassBearing();
        }

        _this.deactivateCompassBearing = function() {
            dynamicCADFactory.deactivateCompassBearing();
        }

        _this.dynamicCADGetListObjectsIntoScene = function() {
            dynamicCADFactory.getListObjectsIntoScene(_this.dynamicCADInfo.listObject3D);
        }
        _this.getDynamicCadContectMenuList = function() {
            dynamicCADFactory.getContectMenuList();
        }          
        _this.dynamicCADPropertiesPanelToggle = function() {
            _this.dynamicCADInfo.isPropertiesPanelShown = !_this.dynamicCADInfo.isPropertiesPanelShown;
        }
        _this.moveCompass = function(_event) {
            dynamicCADFactory.moveCompass(_event, _this.dynamicCADInfo);
        }

        //=========================================== DYNAMIC CAD EDITOR end ===============================================================


        //=========================================== CHECK LIST begin ===============================================================
        _this.checklistFactory = _createChecklistFactoryInstance();

        function _createChecklistFactoryInstance() {
            var _initParams = {
                this: _this,
                scope: $scope,
                asset: _asset,
                customer: _customer,
                controllerName: _controllerName
            }

            return  checklistFactory.init(_initParams)
        }
        //=========================================== CHECK LIST end =================================================================

        //=========================================== DELETE DATASET begin =================================================================
        _this.toggleDeleteDataset = function() {
            _this.isDeleteDatasetOn = assetDataFactory.toggleDeleteDataset();

            if (_this.isDeleteDatasetOn) {
                _this.listAssetDataSelectd = [];
                _this.viewShown = undefined;
            } else {
                if (_this.listAssetDataSelectd.length > 0) {
                    var _fns = [
                        { 'btn_msg': 'YES', 'btn_color': 'btn-primary', 'btn_fn': 'deleteDatasetFromPopup' },
                        { 'btn_msg': 'NO', 'btn_color': 'btn-primary', 'btn_fn': null }
                    ];
                    var _msg = 'Do you want delete the elements selected?';
        
                    popup.openModal('sm', 'Warning', _msg, _controllerName, JSON.stringify(_fns));    
                }
            }

            _this.listAssetData.forEach(e => e.isSelected = false);
        }

        $scope.deleteDatasetFromPopup = function() {
            assetDataFactory.deleteDataset(_this.listAssetDataSelectd, _res => {
                myAlert("Datasets deleted", 'info');
                _assetDataResetAndRefresh();
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            });


            // _this.listAssetDataSelectd = [];
        }


        //=========================================== DELETE DATASET end ===================================================================

        //=========================================== RISK MANAGEMENT begin =================================================================
        _this.initRiskManagement = function() {
            _this.viewShown = _this.views.riskManagement;
        }

        _this.riskManagementRun = function() {
            var _fns = [{ 'btn_msg': 'OK', 'btn_color': 'btn-primary', 'btn_fn': null }];
            var _msg = 'This function is not enabled for this account';

            popup.openModal('sm', 'Info', _msg, _controllerName, JSON.stringify(_fns));

        }

        //=========================================== RISK MANAGEMENT end ===================================================================

        //=========================================== CONTEXT CAPTURE begin =================================================================
        _this.initContextCapture = function(_assetData) {
            _this.contextCaptureUrl = $sce.trustAsResourceUrl(_assetData.contextCaptureUrl);
            _this.viewShown = 'context_capture';               
        }
        //=========================================== CONTEXT CAPTURE end ===================================================================

        _this.showGoogleEarth = function() {
            _this.googleEarthUrl = $sce.trustAsResourceUrl('https://earth.google.com/web/data=Mj8KPQo7CiExT1F6LVhWbVJoSlNTOVNpQ2k4ME44Z3owR1ROSjBfVG4SFgoUMEJFOUUzQzJBMjE4N0ExNTI4NTA');
            _this.viewShown = _this.viewFromAssetDataTool.changeTo('google_earth');
        }

        var sourceImage, targetRoot; 
        var maState;

        function setSourceImage(source) {
            sourceImage = source;
            targetRoot = $("body").get(0);
          }


          function renderImageSrc(target, source, state) {
            utility.callFunctionEveryTime('$("#_lapixxx_src").attr("src")!=""', 300, function() {
                    var markerArea = new markerjs2.MarkerArea(source);

                    markerArea.targetRoot = $("body").get(0);

                    markerArea.show();
                    if (state) {
                        markerArea.restoreState(state);
                    }

                    $(".__markerjs2_").css("visibility", "hidden"); 
                    

                    utility.callFunctionWithScopeEveryTime(markerArea, ".target.complete", 300, function() {
                        markerArea.render().then((imgUrl, state) => {
                            target.src = imgUrl;
                            markerArea.close();    
                        });
                    })
                })
        }


        function showMarkerArea(target, source, state) {
            maState=state

            var markerArea = new markerjs2.MarkerArea(source);
            // since the container div is set to position: relative it is now our positioning root
            // end we have to let marker.js know that
            markerArea.targetRoot = targetRoot;

            markerArea.addRenderEventListener((imgURL, state) => {
                target.src = imgURL;

                // save the state of MarkerArea
                maState = state;
                console.log(JSON.stringify(state));

                _this.inventoryData.inventoryItemSelected.imageMarkers = JSON.stringify(state);

            });

            markerArea.show();

            if (maState) {
                console.log("")
                markerArea.restoreState(maState);
                }    

                $(".__markerjs2_").css("top", "25%");        
                $(".__markerjs2_").css("left", "25%");        
                $(".__markerjs2_toolbar-block:first").prepend('<div id="__markerjs2_drag" class="__markerjs2_toolbar_button"><img src="img/icons/move.png"/></div>')
            utility.callFunctionEveryTime("$('#__markerjs2_drag').get(0)", 300, function() {
                $(".__markerjs2_").draggable();
                $(".__markerjs2_").draggable('option','handle','#__markerjs2_drag');
            });

        }

        //=========================================== 360-DEGREES-IMAGES  begin =============================================================
        _this.newInstanceImage360 = function(_image360CanvasId) {
            var _image360Instance = {
                containerId: _image360CanvasId,
                listFdd: _this.listAssetDataDetails,
                buildSrcUrl: _this.getBuildSrcUrl(),
                createViewer: _this.createViewer(),
                scope: $scope,
            };

            return _image360Instance;
        }

        _this.newInstanceImage360SplitScreen = function(_type) {
            _this.destroyViewers(_this.image360SplitScreenInstance)

            var _image360SplitScreenData = {
                type: _type?_type:image360Factory.getInstanceType().image360Image360,
                viewType: image360Factory.getViewType(),
            };
            _image360SplitScreenData.view = Object.values(image360Factory.getViewType()).find(e => e.instanceKey==_image360SplitScreenData.type);


            if (!_type || _type == image360Factory.getInstanceType().image360Image360) {
                _image360SplitScreenData.instanceSx = _this.newInstanceImage360(_this.image360CanvasId+"_sx")                    

            }

            _image360SplitScreenData.instanceDx = _this.newInstanceImage360(_this.image360CanvasId+"_dx")

            if (_type == image360Factory.getInstanceType().mapImage360) {
                _image360SplitScreenData.instanceSx = {};

                image360Factory.createMap('#_image360_split_screen_map', _image360SplitScreenData.instanceSx, _this.listAssetDataDetails, _res => {
                    _image360SplitScreenData.instanceDx.createViewer(_image360SplitScreenData.instanceDx, _res.fdd);
                    $scope.$apply();
                })
            }            

            return _image360SplitScreenData;        
        }

        _this.createViewer = function() {       

            return function(_instance, _fdd) {
                image360Factory.createViewer(_instance, _fdd, _viewer => {
                    _instance.viewer = _viewer;
                    image360Factory.addEventPositionUpdated(_instance, _degrees => {
                        if (_this.viewShown == _this.views.image360SplitScreen &&  _this.image360SplitScreenInstance.type==image360Factory.getInstanceType().mapImage360) {
                            image360Factory.rotateRadar(_this.image360SplitScreenInstance.instanceSx, _degrees);
                        }    
                    });

                    if (_this.viewShown == _this.views.image360SplitScreen &&  _this.image360SplitScreenInstance.type==image360Factory.getInstanceType().mapImage360) {
                        image360Factory.createRadar(_this.image360SplitScreenInstance.instanceSx, _fdd);          
                    }
                })    
            }
        }

        _this.image360ShowFirstImage = function() {
            var _instance = (_this.viewShown == _this.views.image360)?_this.image360Instance:_this.image360SplitScreenInstance;

            image360Factory.image360ShowFirstImage(_instance, _this.listAssetDataDetails, _this.views, _this.viewShown, "#_image360_split_screen_map");
        }

        _this.destroyViewers = function(_instance) {
            if (_instance) {
                if (_instance.instanceSx.viewer) image360Factory.destroyViewer(_instance.instanceSx.viewer)
                if (_instance.instanceDx.viewer) image360Factory.destroyViewer(_instance.instanceDx.viewer)    
            }
        }
        //=========================================== 360-DEGREES-IMAGES  end ===============================================================
          

        _this.runAssetDataTaskFunction = function(_task, _assetData) {
            assetDataFactory.runAssetDataTaskFunction(_this, _task, _assetData);
        }

        _this.assetDataFieldToShow = function(_assetData, _fieldName) {
            var _ret = _assetData[_fieldName];

            if (_assetData.fileType != _this.assetDataTypes.report) {
                if (_assetData.name) {
                    _ret = _assetData.name;
                }
            }

            return _ret;
        }

        $scope.renameAssetDataFromPopup = function(_popup) {            
            assetDataFactory.renameAssetData(_this, _popup, _popup.data.assetData, _identity.id)
        }

        _this.assetDataListShown = true;
        _this.toggleAssetDataList = function() {
            _this.assetDataListShown = !_this.assetDataListShown;
        }

        // _this.moveTablePagination = function(_idTable) {
        //     utility.moveTablePagination(_idTable);
        // }

        //----------------------------------------------- iscChecklistCreation begin -----------------------------------------------------------------
        _this.iscChecklistCreationPopup = function(){
            ///fucntion to select a new checklist and creatae the record in the Db

            // if (_this.isAssetDataToolEnabled(_this.toolName.iscChecklist)){
            //( "#datepicker" ).datepicker();

            assetServices.iscTemplateRetrieve(_this.customer['id'], _resISC => {
                _this.listISCTemplate = _resISC.iscTemplateDto.filter(element => element.facilityTypeId == _this.assetTypeId);

                var _fns = [
                    { 'btn_identifier': '_1_2', 'btn_msg': 'Close',    'btn_color': 'btn-clear'},
                    { 'btn_identifier':'_1_1', 'btn_close': false, 'btn_msg': 'Generate ISC Checklist', 'btn_color': 'btn-primary', 'btn_fn': 'iscChecklistCreation' }
                ];

                var _data = {
                    listISCTemplate: _this.listISCTemplate ,
                }

                var _config = {
                    // 'funzione': {
                    //     // btnBuildSrcUrl: _this.getBuildSrcUrl(),
                    //     iscChecklistCreation: _this.iscChecklistCreation()
                    // },
                    'size': 'lg',
                    'title': 'ISC Checklist Creation',
                    'ctrl': _controllerName,
                    'data': JSON.stringify(_data),
                    'fns': JSON.stringify(_fns),
                    'htmlPage': 'views/asset-data/popup/asset-data_ISCTemplate_selection.html',
                }

                popup.openModalBody(_config);            

            })

            // }
        }


        $scope.iscChecklistCreation = function(_popup){
            //function to save run the creation of checklists
            assetServices.createISCQA(_popup.data.iscTemplateSelected, _assetId, _res => {
            myLog("New CheckList Created:", _res);
            _popup.close();
            _assetDataResetAndRefresh();
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })
        }







        //----------------------------------------------- iscChecklistCreation ends -----------------------------------------------------------------


        //=========================================== CHECK LIST TeMPLATE begin ===============================================================

        function _getAssetStatusTypes(_success, _error) {
            var _array = [];

            assetServices.getAssetStatusTypes(_res => {
                _success ? _success(_res) : null;

                myLog("Assets status types list:", _res);
            }, _err => {
                _error ? _error(_err) : null;

                myAlert("Error: " + _err.data.message, 'danger');
                myThrow(_err);
            });
        }

        $scope.calcDiff = function(firstDate){

            var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds  
            var dateOut1 = new Date(firstDate); // it will work if date1 is in ISO format
            var dateOut2 = new Date();
            return Math.round(Math.abs((dateOut2.getTime() - dateOut1.getTime())/(oneDay)));


        }


        _this.checklistISCRetrrieve = function(_assetData){


            // _getAssetStatusTypes(_res => {
            //     _this.listAssetStatusType = _res;
            //     _this.listAssetStatusTypeByKey = utility.getArrayByField(_res, 'key');

            // });

            _this.checklistInfo = {
                assetData: _assetData,
                creationDate: new Date(_assetData.createdAt),
                asset: _asset,
                customer: _customer,
                numbers: {
                    total: 0,
                    nPassed: 0,
                    nFailed: 0
                },
                answerTypes: [
                    {key: "notdelivered", value: "Not Delivered"},
                    {key: "pending", value: "Pending"},
                    {key: "delivered", value: "Delivered"},
                    {key: "na", value: "N/A"}
                ],
                templateStatus: [
                    {key: 0, value: "Incomplete"},
                    {key: 1, value: "In Progress"},
                    {key: 2, value: "Completed"},
                    {key: 3, value: "N/A"}
                ],

                classificationTypes: [],
                // statusReport:_this.StatusChange(_assetData.dataProcessed)
            }
            _this.StatusChange(_assetData.dataProcessed)
            var daysReport = $scope.calcDiff(_assetData.createdAt)


            // if (_assetData.dataProcessed == 1){
            //     _this.checklistInfo.statusReport = 'good_condition';
            // }
            // else{
            //     if (daysReport < 28){
            //         _this.checklistInfo.statusReport = 'low_schedule';
            //     }
            //     else{
            //         _this.checklistInfo.statusReport = 'urgent_attention';
            //     }
            // }


            //function to retrieve all question for this specific template
            assetDataServices.retrieveQAISC(_assetData.id, _res => {
                _this.viewShown = _this.views.checklist;

                _this.listQA = JSON.parse(_res)

                _this.templateName = _this.listQA[0].template_name;
                //pre-survey
                _this.listQAPreSurvey = _this.listQA.filter(q => q.question_category == "pre-survey")

                //survey
                _this.listQASurvey = _this.listQA.filter(q => q.question_category == "survey")

                //post_survey
                _this.listQAPostSurvey = _this.listQA.filter(q => q.question_category == "post-survey")

                _this.checklistLifePhase = [...new Set(_this.listQA.map(item => item.status_phase))][0];



                _this.checklistInfo.numbers.total = _this.listQA.length;
            })
        }

        _this.StatusChange = function(status){
            if (status == 2){
                _this.checklistInfo.statusReport = 'completed';
            }
            if (status == 1){
                _this.checklistInfo.statusReport = 'inprogress';
            }
            if (status == 0){
                _this.checklistInfo.statusReport = 'incomplete';
            }
            if (status == 3){
                _this.checklistInfo.statusReport = 'na';
            }
        }

        _this.updateISCTemplate = function(){

            //function to save the  QA
            assetDataServices.updateQAISC(_this.listQAPreSurvey, _res => {
                assetDataServices.updateQAISC(_this.listQASurvey, _res => {
                    assetDataServices.updateQAISC(_this.listQAPostSurvey, _res => {
                        assetDataServices.saveAssetData(_identity.id, _this.checklistInfo['assetData'], _res => {

                            _this.checklistISCRetrrieve(_this.checklistInfo['assetData'])
            
                            myAlert("Checklist Saved", 'info');
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                        })
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                    })
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                })            
            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
            })
        }

        _this.evaluateEvidence = function(value){
            if(value == null){
                return 'Missing'
            }
            else{
                return 'Uploaded'
            }
        }

        _this.getFacilityStatusPhase= function(assetStatusPhase){
            
            var objIndex = _this.listAssetStatusPhase.findIndex((obj => obj.key == assetStatusPhase));
            return  _this.listAssetStatusPhase[objIndex].value;                    
            
        }


        //=========================================== CHECK LIST TeMPLATE END ===============================================================

        //=========================================== 2D CAD EDITOR: begin ==================================================================
        _this.init2DCADEditor = function(_assetData) {
            _this.viewShown = _this.views.editor2DCAD;

            _this.editor2dCadObject = editor2DCADFactory.newInstance({
                assetData: _assetData,
                canvasIframeId: 'cadcontent'
            })
            console.log(_assetData)

            // window.addEventListener('message', _event => {
            //     console.log("---------------mainctrl------------------->", _event);
            // })       
        }

        _this.editor2DCADClose = function(_editor2dCadObject) {
            _editor2dCadObject.closeAndSave(function() {
                _this.viewShown = undefined;
            });
        }

        // _this.a = function() {
        //     // $("#_editr_2d_cad").remove();
        //     // console.log("....................saving 2d cad")
        //     var iframe = document.getElementById("cadcontent");

        //     var message = { messageName: "runcommand", commandString: "_LINE 0,0 10000,10000  " }                

        //     iframe.contentWindow.postMessage(message, '*');            
        // }

        // _this.b = function() {
        //     // $("#_editr_2d_cad").remove();
        //     // console.log("....................saving 2d cad")
        //     var iframe = document.getElementById("cadcontent");

        //     var message = { messageName: "runcommand", commandString: "_CIRCLE 0,0 10000   " }                

        //     iframe.contentWindow.postMessage(message, '*');            
        // }

        //=========================================== 2D CAD EDITOR: end ====================================================================



        //=========================================== AIBUILDER LABELS begin========================================================

        var offsetX,
            offsetY,
            ul_x,
            ul_y,
            mouseX,
            mouseY,
            lockAnomaly,
            lockComponent,
            isDown,
            isRectDrawn = false,
            handlesSize = 8,
            currentHandle = false,
            drag = false;


        _this.rectangles = [];
        _this.rectangles_saved = [];

        function drawRectangles() {
            //Function to draw the bbox on the images
            //Function divided in two parts: 
            //1) drawn of current and just drawn rect
            //2) drawn of the saved bbox (bbox already stored into the db)


            var img = document.getElementById('_img_selected');
            var hCoeff = img.naturalHeight / img.height;
            var vCoeff = img.naturalWidth / img.width;

            var canvas = document.getElementById('canvas');  
            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            //1)
            for(var i = 0; i < _this.rectangles.length; i++) {
                isRectDrawn = true;
                var rect = _this.rectangles[i];
                ctx.strokeStyle = 'black';
                ctx.beginPath();
                var x1 = (rect.ul_x);
                var y1 = (rect.ul_y);
                var x2 = (rect.dr_x - rect.ul_x);
                var y2 = (rect.dr_y - rect.ul_y);
                ctx.rect(x1, y1, x2, y2);
                ctx.stroke();
                ctx.closePath();

                ctx.beginPath();
                ctx.arc(x1, y1, 5, 0, 2 * Math.PI, false);
                ctx.fillStyle = '#00B9E6';
                ctx.fill();
                ctx.closePath();
                ctx.beginPath()
                ctx.arc(x1, rect.dr_y, 5, 0, 2 * Math.PI, false);
                ctx.fillStyle = '#00B9E6';
                ctx.fill();
                ctx.closePath();
                ctx.beginPath()
                ctx.arc(rect.dr_x , y1, 5, 0, 2 * Math.PI, false);
                ctx.fillStyle = '#00B9E6';
                ctx.fill();
                ctx.closePath();
                ctx.beginPath()
                ctx.arc(rect.dr_x , rect.dr_y, 5, 0, 2 * Math.PI, false);
                ctx.fillStyle = '#00B9E6';
                ctx.fill();
                ctx.closePath();

                //add the icon to resize the rect
                if (currentHandle) {
                    var dir;
                    switch (currentHandle) {
                        case 'topleft':
                            dir={x:x2>0?'w':'e',y:y2>0?'n':'s'};
                            canvas.style.cursor= dir.y+dir.x+'-resize';
                            break;
                        case 'topright':
                            dir={x:x2>0?'e':'w',y:y2>0?'n':'s'};
                            canvas.style.cursor= dir.y+dir.x+'-resize';
                            break;
                        case 'bottomleft':
                            dir={x:x2>0?'w':'e',y:y2>0?'s':'n'};
                            canvas.style.cursor= dir.y+dir.x+'-resize';
                            break;
                        case 'bottomright':
                            dir={x:x2>0?'e':'w',y:y2>0?'s':'n'};
                            canvas.style.cursor= dir.y+dir.x+'-resize';
                            break;

                        //TBD the possibility to expande the size of the rect instead of just the corners

                        // case 'top':
                        //     canvas.style.cursor= (y2>0?'n':'s')+'-resize';
                        //     break;
                        // case 'left':
                        //     canvas.style.cursor= (x2>0?'w':'e')+'-resize';
                        //     break;
                        // case 'bottom':
                        //     canvas.style.cursor= (y2>0?'s':'n')+'-resize';
                        //     break;
                        // case 'right':
                        //     canvas.style.cursor= (x2>0?'e':'w')+'-resize';
                        //     break;
                    }
                }else canvas.style.cursor='';
            }

            //2)
            for(var i = 0; i < _this.rectangles_saved.length; i++) {
                var isRectViz = true;
                if (_this.rectangles_saved[i].hasOwnProperty('component_type_id')){
                    isRectViz = _this.listComponentAi[_this.listComponentAi.findIndex(({ id }) => id === _this.rectangles_saved[i].component_type_id)].isVisible;
                }
                else{
                    isRectViz = _this.listAnomalyAi[_this.listAnomalyAi.findIndex(({ key }) => key === _this.rectangles_saved[i].anomaly_type_key)].isVisible;      
                }

                if (isRectViz){
                    var rect = _this.rectangles_saved[i];
                    ctx.strokeStyle = rect.class_color;
                    ctx.beginPath();
                    var x1 = (rect.ul_x)/vCoeff;
                    var y1 = (rect.ul_y)/hCoeff;
                    var x2 = (rect.dr_x - rect.ul_x)/vCoeff;
                    var y2 = (rect.dr_y - rect.ul_y)/hCoeff;
                    ctx.rect(x1, y1, x2, y2);
                    ctx.stroke();
                    ctx.closePath();
                }
            }
        }

        function point(x, y) {
            return {
                x: x,
                y: y
            };
        }
        
        function dist(p1, p2) {
            return Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));
        }
        
        function getHandle(mouse) {
            //Function to compute the distance betwen points and rect to understand if the mouse icon has to change
            // when mouse position is closer than handlesSize to one of the rect corner

            var rect = _this.rectangles[0];
            if (rect != null){
                if (dist(mouse, point(rect.ul_x, rect.ul_y)) <= handlesSize) return 'topleft';
                if (dist(mouse, point(rect.dr_x, rect.ul_y)) <= handlesSize) return 'topright';
                if (dist(mouse, point(rect.ul_x, rect.dr_y )) <= handlesSize) return 'bottomleft';
                if (dist(mouse, point(rect.dr_x, rect.dr_y)) <= handlesSize) return 'bottomright';
            }

            //TBD the possibility to expande the size of the rect instead of just the corners

            // if (dist(mouse, point(rect.ul_x + (rect.dr_x - rect.ul_x) / 2, rect.ul_y)) <= handlesSize) return 'top';
            // if (dist(mouse, point(rect.ul_x, rect.ul_y + (rect.dr_y - rect.ul_y) / 2)) <= handlesSize) return 'left';
            // if (dist(mouse, point(rect.ul_x+ (rect.dr_x - rect.ul_x) / 2, rect.dr_y - rect.ul_y)) <= handlesSize) return 'bottom';
            // if (dist(mouse, point(rect.dr_x - rect.ul_x, rect.ul_y+ (rect.dr_y - rect.ul_y) / 2)) <= handlesSize) return 'right';
            return false;
        }
        


        function handleMouseDown(e) {
            //Function to manage the down action of the mouse

            htmlEvents.dispatchEventMouseDown('_img_selected', e);

            if (currentHandle) drag = true;
            
            mouseX = parseInt(e.clientX - offsetX);
            mouseY = parseInt(e.clientY - offsetY);
            
            // Put your mousedown stuff here
            ul_x = mouseX;
            ul_y = mouseY;
            isDown = true;
        }
            
        function handleMouseUp(e) {
            //Function to manage the up action of the mouse
            if (e.button == 0 && e.ctrlKey == true){

                htmlEvents.dispatchEventMouseUp('_img_selected', e);

                mouseX = parseInt(e.clientX - offsetX);
                mouseY = parseInt(e.clientY - offsetY);
                $("#uplog").html("Up: " + mouseX + " / " + mouseY);
                
                isDown = false;
                if (currentHandle == false){_this.rectangles = [];}

                if (Math.abs(ul_x - mouseX) > 10 & Math.abs(ul_y - mouseY) > 10){
                    if (drag == false){
                        var rectangle = {ul_x: ul_x, ul_y: ul_y, dr_x: mouseX, dr_y: mouseY};
                        _this.rectangles.push(rectangle);
                    }
                    drag = false;
                    currentHandle = false;
                    drawRectangles();

                    //if component or anomaly are locked  -> automatically save the rect
                    var idxC = _this.listComponentAi.findIndex(({ isSelected }) => isSelected === true);
                    if (idxC > -1){
                        _this.saveBoundingBoxComponent(_this.listComponentAi[idxC])
                    }

                    var idxA = _this.listAnomalyAi.findIndex(({ isSelected }) => isSelected === true);
                    if (idxA > -1){
                        _this.saveBoundingBoxAnoamly(_this.listAnomalyAi[idxA])
                    }
                }
            }

        }
            
        function handleMouseMove(e) {
            //Function to manage the moving action of the mouse

            if (e.button == 0 && e.ctrlKey == true){

                var previousHandle = currentHandle;

                htmlEvents.dispatchEventMouseMove('_img_selected', e);

                mouseX = parseInt(e.clientX - offsetX);
                mouseY = parseInt(e.clientY - offsetY);

                currentHandle = getHandle(point(mouseX, mouseY));
                console.log(currentHandle)

                if (currentHandle && drag) {
                    drag = true
                    // mouseX = parseInt(e.clientX - offsetX);
                    // mouseY = parseInt(e.clientY - offsetY);

                    switch (currentHandle) {
                        case 'topleft':                  
                            _this.rectangles[0].ul_x = mouseX;
                            _this.rectangles[0].ul_y = mouseY
                            break;
                        case 'topright':
                            _this.rectangles[0].dr_x = mouseX ;
                            _this.rectangles[0].ul_y = mouseY;
                            break;
                        case 'bottomleft':
                            _this.rectangles[0].ul_x = mouseX;
                            _this.rectangles[0].dr_y = mouseY
                            break;
                        case 'bottomright':
                            _this.rectangles[0].dr_x = mouseX;
                            _this.rectangles[0].dr_y = mouseY;
                            break;
                        
                        //TBD the possibility to expande the size of the rect instead of just the corners

                        // case 'top':
                        //     _this.rectangles[0].dr_y = _this.rectangles[0].ul_y - mouseY;
                        //     _this.rectangles[0].ul_y = mouseY;  
                        //     // rect.h += rect.y - mousePos.y;
                        //     // rect.y = mousePos.y;
                        //     break;
            
                        // case 'left':
                        //     _this.rectangles[0].dr_x = _this.rectangles[0].ul_x - mouseX;
                        //     _this.rectangles[0].ul_x = mouseX;
                        //     // rect.w += rect.x - mousePos.x;
                        //     // rect.x = mousePos.x;
                        //     break;
            
                        // case 'bottom':
                        //     _this.rectangles[0].dr_y =  mouseY - _this.rectangles[0].ul_y;
                        //     // rect.h = mousePos.y - rect.y;
                        //     break;
            
                        // case 'right':
                        //     _this.rectangles[0].dr_x =  mouseX - _this.rectangles[0].ul_x;
                        //     // rect.w = mousePos.x - rect.x;
                            break;
                    }
                }

                if (drag || currentHandle != previousHandle) drawRectangles();
                
                // Put your mousemove stuff here
                if (!isDown) {
                    return;
                }
            }
        }       

        _this.dropDownSettingsC = {
            //Function to define the settings of Component dropdown menu
            styleActive: true, template: '<b>{{option.name}}</b>', scrollableHeight: '200px', scrollable: true,
            smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return "Asset Component List"; }
        };


        _this.dropDownSettingsA = {
            //Function to define the settings of Anomaly dropdown menu
            styleActive: true, template: '<b>{{option.value}}</b>', scrollableHeight: '200px', scrollable: true,
            smartButtonMaxItems: 1, smartButtonTextConverter: function (itemText, originalItem) { return "Asset Anomalies List"; }
        };


        _this.loadAIBuilderBoxes = function (){
            //Load Bounding Boxes for the visualized Image. 
            _this.rectangles = []

            $timeout(function() {
                assetDataServices.retrieveTagAreas(_this.assetDataDetailsSelected.id, _res => {
                    _res = JSON.parse(_res)
                    _this.currentCompoFddTagsAIBuilder = _res['listCompoTags'];
                    _this.currentCompoFddTagsAIBuilder = _this.currentCompoFddTagsAIBuilder.filter(element => element.type_usage == 'training');
                    _this.currentAnoFddTags = _res['listAnoTags'];
                    _this.currentAnoFddTags = _this.currentAnoFddTags.filter(element => element.type_usage == 'training');

                    // var bbToDraw = _this.currentCompoFddTags.filter(element => element.type_usage == 'training')
                    // _this.rectangles_saved = bbToDraw.concat(_this.currentAnoFddTags.filter(element => element.type_usage == 'training'))
                    
                    _this.rectangles_saved = _this.currentCompoFddTagsAIBuilder.concat(_this.currentAnoFddTags)

                    //sort by storing date
                    _this.rectangles_saved =_this.rectangles_saved.sort(function(a, b) {
                        return new Date(b.created_at) - new Date(a.created_at);
                       }); 
                    
                    drawRectangles()
                    _this.setDefaultAvailableObject();

                }, _err => {
                    _error(_err);
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            },500)

        }

        _this.setDefaultAvailableObject = function (){

            assetDataServices.retrieveAvailableComponent(_this.assetDataDetailsSelected.facilityDataId, _this.customer.id, _res => {
        

                _res = JSON.parse(_res)
                _this.availableComponents = _res['listCompoTags'].filter(element => element.type_usage == 'training');
                _this.availabletAnomalies = _res['listAnoTags'].filter(element => element.type_usage == 'training');
        
                

                //COLOR SECTION
                // //COMPONENT SECTION
                // var justCompoTypeId = _this.availableComponents.map( row=>row.component_type_id)
                // var uniqueCompoTypeId = [...new Set(justCompoTypeId)].sort()

                // uniqueCompoTypeId.forEach(e => {
                //     var idx = _this.listComponentAi.findIndex(({ id }) => id === e);
                //     var idxCol = _this.availableComponents.findIndex(({ component_type_id }) => component_type_id === e);
                //     _this.listComponentAi[idx].color = _this.availableComponents[idxCol].color;

                //     // //check if already present
                //     // var idxCheck = _this.listAvailableComponents.findIndex(({ id }) => id === _this.listComponentAi[idx].id);
                //     // if (idxCheck == -1){

                //     //     _this.listAvailableComponents.push(_this.listComponentAi[idx])
                //     // }                
                // })

                // //ANOMALY SECTION
                // var justAnoTypeId = _this.availabletAnomalies.map( row=>row.anomaly_type_key)
                // var uniqueAnoTypeId = [...new Set(justAnoTypeId)].sort()

                // uniqueAnoTypeId.forEach(e => {
                //     var idx = _this.listAnomalyAi.findIndex(({ key }) => key === e);
                //     var idxCol = _this.availabletAnomalies.findIndex(({ anomaly_type_key }) => anomaly_type_key === e);
                //     _this.listAnomalyAi[idx].color = _this.availabletAnomalies[idxCol].color;
                    
                //     // //check if already present
                //     // var idxCheck = _this.listAvailableAnomalies.findIndex(({ key }) => key === _this.listAnomalyAi[idx].key);
                //     // if (idxCheck == -1){

                //     //     _this.listAvailableAnomalies.push(_this.listAnomalyAi[idx])
                //     // }
                // })
            })
        
        }

        _this.changeLockComponent = function (value){
            //Define if a Compnent is locked for the labelling

            _this.listAnomalyAi .forEach(e => {
                e.isSelected  = false          
            })

            var idx = _this.listComponentAi.findIndex(({ id }) => id === value.id);

            _this.listComponentAi .forEach(e => {
                if (e.id == value.id){
                    e.isSelected = !e.isSelected;
                    if (e.isSelected == true){
                        lockComponent = _this.listComponentAi[idx];
                    }
                    else{lockComponent = ''}
                }
                else{e.isSelected  = false}                
            })
            
            lockAnomaly = ''
        }

        _this.changeLockAnomaly = function (value){
            //Define if an Anomaly is locked for the labelling

            _this.listComponentAi .forEach(e => {
                e.isSelected  = false          
            })

            var idx = _this.listAnomalyAi.findIndex(({ id }) => id === value.id);

            _this.listAnomalyAi .forEach(e => {
                if (e.key == value.key){
                    e.isSelected = !e.isSelected;
                    if (e.isSelected == true){
                        lockAnomaly = _this.listAnomalyAi[idx];
                    }
                    else{lockAnomaly = ''}
                }
                else{e.isSelected  = false}                
            })

            lockComponent = ''
        }

        _this.changeVizStatusComponent = function (value){
            //Define if a Compnent is visible or not

            _this.listComponentAi .forEach(e => {
                if (e.id == value.id){
                    e.isVisible = !e.isVisible;
                }
            })
            drawRectangles()
        }


        _this.changeVizStatusAnomaly = function (value){
            //Define if an Anomaly is visible or not

            _this.listAnomalyAi .forEach(e => {
                if (e.key == value.key){
                    e.isVisible = !e.isVisible;
                }
            })
            drawRectangles()
        }


        _this.draw_image_aiBuilder =  function () {


            _this.viewShown = _this.views.aibuilder;
            _this.listComponentAi = [];
            _this.listAnomalyAi = [];
            _this.listAvailableComponents = [];
            _this.listAvailableAnomalies = [];

            _this.listTagsIntoAssetDataDetails.forEach(tt => tt.isSelected = true);   
            _this.listAssetDataDetails = _filterListAssetDataDetailsByTags(); 

            aiBuilderFactory.clearScreen('_img_selected', 'canvas', _this.aiBuilderTriggered);
            _this.aiBuilderTriggered = false;


            // Get the list of company's components runnable by AI Machine
            //If the company doesn't have any, I'll get the same about SKY-FUTURES
            assetDataServices.getAiComponentByFacilityByCompany(this.customer.id, _asset.facilityTypeId, _res => {
                _this.listComponentAi = _res;
                _this.listComponentAi .forEach(e => {
                    e.isSelected = false;
                    e.isVisible = true;
                })
                
                //Get the list of company's anomalies runnable by AI Machine
                //If the company doesn't have any, I'll get the same about SKY-FUTURES
                assetDataServices.getAiAnomalyByFacilityByCompany(this.customer.id, _asset.facilityTypeId, _res => {
                    _this.listAnomalyAi = _res;
                    _this.listAnomalyAi .forEach(e => {
                        e.isSelected = false;
                        e.isVisible = true;
                    })
                    _this.loadAIBuilderBoxes();

                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);        
                })

            }, _err => {
                myAlert("Error: " + _err.data.message, 'danger');
                myThrow(_err);        
            })
            
                            
            _this.aiBuilderTriggered = true;

            //deatcivate the zoom of the canvas
            if (_this.zoomActive) {
                _this.zoomActive = !_this.zoomAsctive;
            }
            
            $timeout(function() {
                var img = document.getElementById('_img_selected');
                var canvas = document.getElementById('canvas');  
                canvas.height = img.height; 
                canvas.width = img.width;
                var rect = canvas.getBoundingClientRect();
                offsetX= rect.left;
                offsetY = rect.top;

                var canvas = document.getElementById('canvas');  

                    canvas.addEventListener("wheel", _aiFilterCanvasEventWheel);
                    canvas.addEventListener("mousedown", handleMouseDown);
                    canvas.addEventListener("mouseup", handleMouseUp);
                    canvas.addEventListener("mousemove", handleMouseMove);

                    canvas.removeEventListener("mousedown", _aiFilterCanvasEventMouseDown);
                    canvas.removeEventListener("mouseup", _aiFilterCanvasEventMouseUp);
                    canvas.removeEventListener("mousemove", _aiFilterCanvasEventMouseMove);
    
                    canvas.addEventListener("mouseout", function (e) {
                    }, false);    

                },500)               
            // }    
        }


        _this.updateRect = function(){
            //resize the bbox considering the zooming in/out factor

            var img = document.getElementById('_img_selected');
            var hCoeff = img.naturalHeight / img.height;
            var vCoeff = img.naturalWidth / img.width;

            _this.rectangles[_this.rectangles.length -1]['ul_x'] = _this.rectangles[_this.rectangles.length -1]['ul_x']*vCoeff;
            _this.rectangles[_this.rectangles.length -1]['ul_y'] = _this.rectangles[_this.rectangles.length -1]['ul_y']*hCoeff;
            _this.rectangles[_this.rectangles.length -1]['dr_x'] = _this.rectangles[_this.rectangles.length -1]['dr_x']*vCoeff;
            _this.rectangles[_this.rectangles.length -1]['dr_y'] = _this.rectangles[_this.rectangles.length -1]['dr_y']*hCoeff;
        }
 

        _this.saveBoundingBoxComponent = function(value){
            //Fucntion to save a bounding box as component

            _this.updateRect();

            assetDataServices.saveComponentBB(value, _this.assetDataDetailsSelected.id, _this.assetDataDetailsSelected.facilityDataId, _this.rectangles[_this.rectangles.length -1], _res => {
                myAlert("Component Bounding Box Saved", 'info');          

                _this.rectangles = [];
                // _this.rectangles_saved.push(rectangle);
                // drawRectangles()
                _this.loadAIBuilderBoxes()
                
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            }) 
        }

        _this.saveBoundingBoxAnoamly= function(value){
            //Fucntion to save a bounding box as anomaly

            _this.updateRect();

            assetDataServices.saveAnomalyBB(value, _this.assetDataDetailsSelected.id,_this.assetDataDetailsSelected.facilityDataId, _this.rectangles[_this.rectangles.length -1], _res => {

                _this.rectangles = [];
                // _this.rectangles_saved.push(rectangle);
                // drawRectangles()
                _this.loadAIBuilderBoxes()
                myAlert("Anomaly Bounding Box Saved", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
        }


        $scope.removeAllBBox = function (_popup){
            //Remove all bbox present in the images

            assetDataServices.removeAllBBox(_popup.data.assetDataDetailsSelected, _res => {
                _this.rectangles_saved = []
                drawRectangles()
                _this.listAnomalyAi.forEach(e => {e.color =''}) //check this
                _this.listComponentAi.forEach(e => {e.color =''}) //check this
                myAlert("All Bounding Boxes Removed", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
        }

        _this.removeLastBBox = function(){
            //Remove the last bbox saved 

            assetDataServices.removeLastBBox(_this.rectangles_saved[0], _res => {
                _this.loadAIBuilderBoxes()
                // drawRectangles()
                myAlert("Last Saved Bounding Box Removed", 'info');                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })
        }


        _this.removeallBBoxPopUp = function() {
            //pop up to confirm the removal of all bounding boxes
      
            var _fns = [
                { 'btn_identifier':'_1_2', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear', 'btn_fn': null },
                { 'btn_identifier':'_1_1', 'btn_close': true, 'btn_msg': 'Ok', 'btn_color': 'btn-primary', 'btn_fn': 'removeAllBBox' }
            ];

            var _popupData = {
                assetDataDetailsSelected: _this.assetDataDetailsSelected.id
            }

            var _config = {
                size: 'md',
                title: 'Remove all the created bounding boxes',
                ctrl: _controllerName,
                data: JSON.stringify(_popupData),
                fns: JSON.stringify(_fns),
                htmlPage: 'views/asset-data/popup/asset-data_removeBBox.html',
            }
            popup.openModalBody(_config);      


        }


        //=========================================== AIBUILDER LABELS end==========================================================
        _this.viewShownAITool = ''
        _this.viewShownAI = {                                     //header views available
            'aibuilder': 'aibuilder',
            'aifilter': 'aifilter',
            'assistedlabel': 'assistedlabel'
        };


        _this.addActive = function() {
            $('#_'+_this.viewShown).addClass('is-active');
        }

        _this.removeActive = function() {
            $('#_'+_this.viewShown).removeClass('is-active');
        }


        _this.goAIBuilder = function(){
            console.log('AIB')
            _this.removeActive();
            _this.viewShown = _this.views.aibuilder;
            _this.addActive();
            _this.draw_image_aiBuilder()
        }

        _this.goAiFilter = function(){
            console.log('AIF')
            _this.removeActive();
            _this.viewShown = _this.views.aifilter;
            _this.addActive();
            _this.aiFilter();

        }

        _this.goAssistedFiltering = function(){
            console.log('aassisted')
            _this.removeActive();
            _this.viewShown = _this.views.aiassistedlabel;            
            _this.addActive();
            _this.aiFilter();
        }

        _this.runAITool = function(){

            //TODO ADD THE SELECTION OF THE VIEW PAGE DEPENDING ON THE ROLE
            _this.goAiFilter()

        }

        _this.runAiReport = function(){

            assetDataServices.runAiReport(_this.listAssetDataDetails[_this.imgIndex].facilityDataId, _res => {
                
                
                var downUrl = $sce.trustAsResourceUrl(expanseConst.cfAssetDataUrl+_res.location_name)
                $window.open(downUrl, '_blank');                    
                swal.close()
    
                return                    
            }, _err => {
                myAlert("Error: " + _err.data["Error Message"], 'danger');
                myThrow(_err);        
            })        

        }
    }


]);
