//Write in this controller all the code about ....entity
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.controller('userCtrl', 
    ['$scope', 'Scopes', 'myLog', 'myAlert','userServices',
    function($scope, Scopes, myLog,  myAlert, userServices) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        var _this = this;

        //-------------------------- private area Begin -----------------------------------
        var _controllerName = "userCtrl";
        //-------------------------- private area End-- -----------------------------------

        //-------------------------- _this area Begin -----------------------------------
        //-------------------------- _this area End-- -----------------------------------

        //-------------------------- $scope area Begin -----------------------------------
        //-------------------------- $scope area End-- -----------------------------------

        //Write here the code that you need when the controller is created
        //The $scope will be put and get from store.
        //In this way it will be possible to cache some information
        //and it will be possible to use some function in others controllers
        this.init = function() {
            //Get the $scope about this controller from the store;
            var _ctrl = Scopes.get(_controllerName);

            //if the controller $scope is in the store, I'm going to use it
            //otherwise the $scope will be saved into the store
            if (_ctrl) {
                $scope = _ctrl;
            } else {
                //Save all the $scope objects
                //that could be requested by others controllers
                Scopes.store(_controllerName, $scope);
            }
        }


    }
]);