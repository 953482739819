//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('assetDataByTagsFactory', ['myLog', 'googlemaps', 'assetDataFactory', 'utility', 
    function(myLog, googlemaps, assetDataFactory, utility) {
        var _isShown = false;

        function _createMarker(_map, _lat, _lng, _assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId) {
            const _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                icon: '/img/icons/markers/marker-photos.png',
                title: _assetDataDetails.fileName,
                lat: _lat,
                log: _lng,
                id: _assetDataDetails.id,
                pathImg: ""+assetDataFactory.buildSrcUrl(_assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId).mdUrl
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                //The popup is based on an external .html file
                googlemaps.setInfoWindow(_map, _marker, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
            });

            return _marker;
        }     
        
        function _filterListAssetDataDetailsWithTag(_listAssetDataDetails, _listTags) {
            var _listAssetDataDetailsWithTag = _listAssetDataDetails.filter(e => {
                if (e.listTags && e.listTags.length>0 && _listTags) {
                    var _found = false;
                    _listTags.some(t => {            
                        _found =  utility.getIndexArrayElementByField(e.listTags, 'tagKey', t) != undefined
                        return _found;
                    })
                    return _found;
                }
            })

            return _listAssetDataDetailsWithTag;
        }

        return {
            filterListAssetDataDetailsWithTag: function(_listAssetDataDetails, _listTags) {
                return _filterListAssetDataDetailsWithTag(_listAssetDataDetails, _listTags);
            },
            createMap: function(_idMap, _callback) {    
                myLog("Create a map to asset-data-by-tags")
                googlemaps.initGoogleMap("#"+_idMap, _m => {
                    _callback(_m);
                });    
            },
            showToggle: function() {
                myLog("asset-data-by-tags show switched")
                _isShown = !_isShown;
                return _isShown;

            },
            putMarkersOnMap(_map, _listMarkers, _listAssetDataDetails, _listAssetDataSelectd, _listTags, _listAssetDataFileTypeByKey, _customerId, _assetId) {
                var _listAssetDataDetailsWithTag = _filterListAssetDataDetailsWithTag(_listAssetDataDetails, _listTags);
    
                _listAssetDataDetailsWithTag.forEach(e => {
                    //Create a new marker only if it isn't already into _listMarkers 
                    if (utility.getIndexArrayElementByField(_listMarkers, 'id', e.id) == undefined) {
                        var _assetData = _listAssetDataSelectd[utility.getIndexArrayElementByField(_listAssetDataSelectd, 'id', e.facilityDataId)];
                        _listMarkers.push(_createMarker(_map, e.latitude, e.longitude, e, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId));    
                    }
                })
    
                //Set out the map zoom based on the markers position
                googlemaps.fitBounds(_map, _listMarkers);

                return _listMarkers;
            },
            createMarker: function(_map, _lat, _lng, _assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId) {
                return _createMarker(_map, _lat, _lng, _assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId);
            },
            removeMarkers: function(_listMarkers, _listMarkersToDelete)  {
                if (_listMarkersToDelete) {

                    _listMarkersToDelete.forEach(e => {
                        _listMarkers.some((m, _index) => {

                            if (m.id == e.id) {
                                m.setMap(null);
                                _listMarkers.splice(_index, 1);
                                return true;
                            }
                        })
                    })

                    return _listMarkers;
                }
            },
            showMarkerInfo: function(_map, _assetDataDetails, _listMarkers) {
                _listMarkers.some(e => {
                    if (e.id == _assetDataDetails.id) {
                        googlemaps.setInfoWindow(_map, e, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
                    }
                })
            },
            getListTagsIntoAssetDataDetails(_listAssetDataDetails, _listTagTypes) {
                if (_listTagTypes) {
                    return _listTagTypes.filter(tt => {
                        var _found = false;
                        _listAssetDataDetails.some(d => {
                            if (d.listTags.filter(t => t.tagKey == tt.key).length > 0) {
                                _found = true;
                                return true;
                            }
                        })
                        return _found;
                    })    
                } else {return _listTagTypes}
            },

            filterListAssetDataDetailsByTag(_listAssetDataDetails, _listTagKey) {
                return _listAssetDataDetails.filter(a => a.listTags.filter(t => _listTagKey.filter(tk => tk.key == t.tagKey).length>0).length >0)
            },

            sortListTagsIntoAssetDataDetails(_listTags) {
                var _tmp =  _listTags
                    // .filter(t => t.key!='none')
                    .sort((a,b) => {
                        if (a.id+a.key<b.id+b.key) {return -1}; 
                        if (a.id+a.key>b.id+b.key) {return }; 
                        return 0;
                    });

                // _tmp.push({key:'none', value:'None', isSelected:true});

                return _tmp;
            }

        };
    }
]);

