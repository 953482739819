//Write here all the services about AssetData
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("assetDataUploadServices", ['myHttp', 'buildUrl', 'myLog', '$http', function(myHttp, buildUrl, myLog, $http) {
        return  {
            getAssetsAssetData: function(_assetId, _isInspector, _success, _error) {
                var _url = buildUrl( '/expanse/facilityData/byFacility/' + _assetId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    var _listFacilityData = _res.listFacilityDataDto;

                    _url = buildUrl( '/expanse/report/byFacility/' + _assetId);
                    myHttp.get(_url).success( _res => {    
                        myLog("Result from " + _url, _res);
                        
                        var _item;
                        _res.listReportDto.forEach(e => {
                            if (_isInspector || (!_isInspector && e.status!='DRAFT')) {
                                _item = e;
                                _item.fileType = 'report';
                                _item.createdAt = e.createdDate;

                                _listFacilityData.push(_item)
                            }
                        })
                        _success(_listFacilityData.sort((a,b) => {return a.createdAt<b.createdAt?1:a.createdAt>b.createdAt?-1:0}));
        
                    }).error( _err => {
                        myLog("Error from " + _url, _err.data);
                        _err.data.message = "I wasn't able to get the report data about the asset: '"+_assetId+"'";
                        _error(_err);
                    } );    
    
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data about the asset: '"+_assetId+"'";
                    _error(_err);
                } );    
            },



            getAssetDataDetailsTagTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/facilityDataDetailsTagTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataDetailsTagTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data details tag types ";
                    _error(_err);
                } );    
            },

            getAssetDataFileTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/facilityDataFileTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFacilityDataFileTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the asset data file types list";
                    _error(_err);
                } );    
            },


            getFileUploadTypes: function(_success, _error) {
                var _url = buildUrl( '/expanse/fileUploadTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listFileUploadTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to call the fileUploadTypes api";
                    _error(_err);
                } );    
            },

                   

            getAiComponent: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/componentType/byCompanyId/' + _companyId + '/byFacilityTypeId/' + _facilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listComponentTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the component types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },

            getAiAnomaly: function(_companyId, _facilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/aiMachine/anomalyType/byCompanyId/' + _companyId + '/byFacilityTypeId/' + _facilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listAnomalyTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the anomaly types about companyId:" + _companyId + " and facilityTypeId:" + _facilityTypeId;
                    _error(_err);
                } );    
            },

            getPlfFunctions: function(_companyId, type, FacilityTypeId, _success, _error) {
                var _url = buildUrl( '/expanse/plf/byCompanyId/' + _companyId + '/byType/' + type + '/byFacilityTypeId/' + FacilityTypeId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listPlfDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "No PLF available for companyId:" + _companyId ;
                    _error(_err);
                } );    
            },

            uploadMU:function(info, e, mpu, array_lenght, part_legth,part_number, part_info, _success, _error){
                myHttp.post('/api/getPresignedPostMU', JSON.stringify({'filename':info.s3_path, 'part_number':part_number, 'mpu': mpu})).success(_presignedPartURL => {

                    myHttp.put(_presignedPartURL, e, info.content_type, e).success((_resPart, body, header) => {

                        _success({'ETag': header('etag').split('"')[1], 'PartNumber': parseInt(part_number)+1})
                    }).error( _err => {
                        myLog("Error from " + info.name)
                        console.log('UPLOAD ERROR _url:')
                        _error(_err);
                    })                             

                }).error( _err => {
                    myLog("Error from " + info.name)
                    console.log('UPLOAD updateUploadStatus') 
                    _error(_err);
                }); 
            },


            s3upload: function(_url, file, info, _success, _error) {


                if (file.size < 5000000000) {
                                
                // if (file.size < 5000) {
                    //Function tot create the connenction and upload the files
                    myHttp.post(_url, JSON.stringify({'filename':info.s3_path})).success(_presignedURL => {
                        
                        myHttp.put(_presignedURL, file.file, info.content_type, file).success(_resUpl => {
                            
                            myHttp.post('/api/updateUploadStatus', JSON.stringify({'fddId':info.facility_det_id, 'dataType': info.data_type})).success(_resUpd => {
                            }).error( _err => {
                                myLog("Error from " + info.name)
                                console.log('UPLOAD updateUploadStatus') 
                                _error(_err);
                            });

                            _success(info.name)   

                        }).error( _err => {
                            myLog("Error from " + info.name);    
                            console.log('UPLOAD ERROR _presignedURL: ', info.name)
                            _error(_err);
                        });
                        
                    }).error( _err => {
                        myLog("Error from " + info.name)
                        console.log('UPLOAD ERROR _url:', _url)
                        _error(_err);
                    });
                }
                else
                {

                    var part_info = [];

                    myHttp.post('/api/startMultipartUpload',JSON.stringify({'filename':info.s3_path})).success(_resUpd => {

                        var mpu = _resUpd
                        const fileToSend = file.file;

                        //create the chunkcs
                        const chunkSize = parseInt(fileToSend.size/10);
                        var file_array = []
                        var _sizes = []

                        for (let start = 0; start < fileToSend.size; start += chunkSize) {
                                file_array.push(fileToSend.slice(start, start + chunkSize))
                                _sizes.push(start + chunkSize + 1)
                        }

                        
                        var part_number = 0; //keep track of the part number
                        let requests2 = file_array.reduce((promiseChain, item) => {
                            return promiseChain.then(() => new Promise((resolve) => {

                                this.uploadMU(info, item, mpu, fileToSend.size,_sizes[part_number], part_number, part_info, _resUp => {
                                    //upload on cloud completed
                                    part_info.push(_resUp)      
                                    file.file.percentage = _sizes[part_number] / fileToSend.size *100;
                                    if (file.file.percentage >100){
                                        file.file.percentage  =100;
                                    }
                                    part_number = part_number +1;
                                    resolve();
                    
                                }, _err => {
                                    item.file.failed_upload = true;
                                    _error(_err);
                                    resolve();
                                });
    

                            }));
                        }, Promise.resolve());

                        requests2.then(e => {
                            // _success(info.name)

                            myHttp.post('/api/endMultipartUpload', JSON.stringify({'filename':info.s3_path, 'part_info': part_info, 'mpu': mpu})).success(_resEnd => {
                                console.log('conclude up')
                                _success(info.name)
                            }).error( _err => {
                                myLog("Error from " + info.name)
                                console.log('UPLOAD updateUploadStatus') 
                                _error(_err);
                            });   

                            console.log("Resolution is complete! Let's party.")
                        });




                        // _success(info.name) 
                    
                    })
                    // const chunkSize = 500000000
                    // var count = 0
                    
                    // const fileToSend = file.file;

                    // const url = '/api/startMultipartUpload'
                    // var part_n = 0
                    // for (let start = 0; start < fileToSend.size; start += chunkSize) {
                    //     const chunk = fileToSend.slice(start, start + chunkSize + 1)
                    //     const fd = new FormData()
                    //     let obj = {
                    //         'start':start,
                    //         'info': part_n
                    //     };
                    //     fd.append('json', JSON.stringify(obj));
                    //     fd.append('file', chunk)

                    //     part_n = part_n +1;

                    //     var xhr = new XMLHttpRequest();
                    //     xhr.withCredentials = true;
                        
                    //     xhr.addEventListener("readystatechange", function () {
                    //         if (this.readyState === 4) {
                    //           console.log(this.responseText);
                    //         }
                    //     });

                    //     xhr.open("POST",'/api/startMultipartUpload');                    
                    //     xhr.send(fd);
            
                    // }                     
                    
                }
            },

            prepareUpload: function(_assetData, fileList, _success, _error) {
                //Function to prepare the DB for the Upload. 
                myHttp.post('/api/analyse_facility_data', JSON.stringify(_assetData)).success(_res => {
                    myLog("Result from " + '/api/analyse_facility_data');
                

                    let requests = fileList.files.reduce((promiseChain, item) => {
                        return promiseChain.then(() => new Promise((resolve) => {
                            var info = _res.returing_list.find(x => x.name === item.name);
                        
                            this.s3upload('api/createPresignedPost', item, info, _resUp => {
                                //upload on cloud completed
                                _success(['file',_resUp])
                                item.file.failed_upload = false;
                                resolve();
                
                            }, _err => {
                                item.file.failed_upload = true;
                                _error(_err);
                                resolve();
                            });

                        }));
                    }, Promise.resolve());



                    _success(['list', _res.returing_list])
                 }).error( _err => {
                    myLog("Error from " + 'analyse_facility_data', _err.data);
                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to prepare the  DB for the Upload";
                    }

                    _error(_err);
                } );
            },


            postProcessingUpload: function(fileList, email, companyId, assetName, customrName, clflag,  _listPLF, customerId,  _success, _error) {
                //Function to prepare the DB for the Upload. 
                myHttp.post('/api/postProcessingUpload', JSON.stringify({'fileList':fileList, 'email':email, 'companyId': companyId, 'assetName': assetName, 'customerName': customrName, 'checklistFlag': clflag, 'plf': _listPLF, 'customerToId':customerId})).success(_res => {
                    console.log('done')
                 }).error( _err => {
                    myLog("Error from " + '/api/postProcessingUpload', _err.data);
                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn'table to prepare the  DB for the Upload";
                    }
                    _error(_err);
                } );
            },

            storePlfFunctions: function(facility_data_id, plfComponent, plfFunctions, _customerId, _success, _error) {
                //Function to prepare the DB for the Upload. 
                myHttp.post('/api/storePlfFunctions', JSON.stringify({'facility_data_id':facility_data_id, 'plfComponent':plfComponent, 'plfFunctions':plfFunctions, 'company_id':_customerId})).success(_res => {
                    console.log('done')
                 }).error( _err => {
                    myLog("Error from " + '/api/storePlfFunctions', _err.data);
                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = "I wasn't able to prepare the  DB for the Upload";
                    }
                    _error(_err);
                } );
            },

            getMissingData: function(facilityDataId, _success, _error) {
                var _url = '/api/getMissingData?companyId=' + facilityDataId;
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "No missingData" + facilityDataId ;
                    _error(_err);
                } );    
            },
            


        }
    }])

