//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('bomFactory', ['myLog', 'utility', 'myAlert', 'bomServices', 'ngTableParams',
    function(myLog, utility, myAlert, bomServices, ngTableParams) {
        var _ngTableParams;
        var _listBom;

        return {
            getListBomByCompanyId: function(_companyId, _success, _error) {
                return bomServices.getBomByCompanyId(_companyId, _success, _error)
            },
            getBomTableParams: function () {
                return {
                    init: function (_popup, __listBom) {
                        _listBom = __listBom;
                        _ngTableParams = ngTableParams.default(_listBom);
                        return _ngTableParams;
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            },
            addItem: function(_popup, _customerId) { 
                _popup.data.bomSelected = {
                    companyId: _customerId
                }
                _popup.setInnerStatus('bomSelected',_popup.data.bomSelected);

                utility.callFunctionEveryTime('!$("#_bom_fields").hasClass("ng-hide")', 200, function() {
                    $("#_catalogue_panel").scrollTop( $("#_bom_fields").offset().top );   
                    $("#_item_name").focus();                       
                });    
            },
            editItem: function () {
                return function (_bom, _popup) {
                    _popup.data.bomSelected = angular.copy(_bom)
                    _popup.setInnerStatus('bomSelected',_popup.data.bomSelected);

                    utility.callFunctionEveryTime('!$("#_bom_fields").hasClass("ng-hide")', 200, function() {
                        $("#_catalogue_panel").scrollTop( $("#_bom_fields").offset().top );   
                        $("#_item_name").focus();                       
                    });    
                }
            },
            cancelItem: function(_popup) {
                _popup.data.bomSelected = undefined;
                _popup.setInnerStatus('bomSelected',_popup.data.bomSelected);
            },
            saveItem: function(_popup, _userId) {
                bomServices.saveBom(_popup.data.bomSelected, _userId, _res => {
                    if (_popup.data.bomSelected.id) {
                        var _index = utility.getIndexArrayElementByField(_listBom, 'id', _popup.data.bomSelected.id);
                        _listBom[_index] = _res
                    } else {
                        _listBom.push(_res);
                    }
                    _popup.data.bomSelected = undefined;
                    _popup.setInnerStatus('bomSelected',_popup.data.bomSelected);

                    _ngTableParams.reload();

                    myAlert("BOM updated", 'info');
                })
            }

        };
    }
]);

