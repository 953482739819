//Write here all the services about Inspection
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("productServices", ['myHttp', 'buildUrl', 'myLog', 'serviceUtility', function(myHttp, buildUrl, myLog, serviceUtility) {
        return  {
            getProductByCompanyIdAndProductGroupId: function(_companyId, _productGroupId, _success, _error) {
                var _url = buildUrl( '/expanse/product/byCompanyId/'+_companyId+"/byProductGroupId/"+_productGroupId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listProductDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Product about (companyId: "+_companyId+", productGroupId: "+_productGroupId+")";
                    _error(_err);
                } );    
            },
            getProductGroupByCompanyId: function(_companyId, _success, _error) {
                var _url = buildUrl( '/expanse/productGroup/byCompanyId/'+_companyId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listProductGroupDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Product Group about (companyId: "+_companyId+")";
                    _error(_err);
                } );    
            },
            getProductIndicatorType: function(_success, _error) {
                var _url = buildUrl( '/expanse/productIndicatorTypes');
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.listProductIndicatorTypeDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the Product Indicator Types";
                    _error(_err);
                } );    
            },
            getProductByCompanyId: function(_companyId, _success, _error) {
                var _url = buildUrl( '/expanse/product/byCompanyId/'+_companyId);
                var _errorMsg = "I wasn't able to get the Product about the companyId:"+_companyId;

                serviceUtility.runGet("getProductByCompanyId", _url, _errorMsg, _res => _success(_res.listProductDto), _error);
            } 

        }
    }])

