//Create the main application module 

var expanseLiteApp = angular.module('expanseLiteApp', 
//Write here all the dependences 
[
    // 'ngAnimate',
    'ngResource',
    'ui.router',
    'ui.bootstrap',
    'angular-loading-bar',
    // 'oc.lazyLoad',
    'nouislider',
    'ngTable',
    'ngMaterialDatePicker',
    'localytics.directives',
    'angularFileUpload',
    'textAngular',
    'base64',
    'thatisuday.dropzone',
    'rzModule',
    'uiSwitch',
    'checklist-model',
    'flow',
    'angularjs-dropdown-multiselect',
    'FBAngular',
    // 'pubnub.angular.service',
    'ngIdle',
    'webcam',
    'ngTagsInput',
]);


module.exports = expanseLiteApp;