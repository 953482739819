const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('dashboardCalendarCmp', {
    templateUrl: 'views/dashboard/common/dashboard-calendar.html',
    controller: DashboardCalendarController,
    controllerAs: 'calendarCtrl',
    bindings: {}
});

/* @ngInject */
function DashboardCalendarController($scope, Scopes, myLog, myAlert) {
    const _this = this;

    _this.selectedDate = new Date();
    _this.viewOptions = {
        showWeeks: false,
        startingDay: 1,
        datepickerMode: 'day'
    };

    _this.$onInit = () => {
    };
}
