//This file contains all the directive
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
  .directive('tooltip', function(){
    return {
        restrict: 'A',
        link: function(scope, element, attrs){
            element.hover(function(){
                // on mouseenter
                element.tooltip('show');
            }, function(){
                // on mouseleave
                element.tooltip('hide');
            });
        }
    };
  })

  //this directive is used with ng-include directive to remove 
  //the external div contains ng-include and include-replace attributes
  .directive('includeReplace', function () {
    return {
      require: 'ngInclude',
      restrict: 'A', /* optional */
      link: function (scope, el, attrs) {
        el.replaceWith(el.children());
      }
    };
  })

  //this directive is used to show some information with a label
  .directive('showData', function () {
    return {
      restrict: 'E',
      replace: 'true',
      templateUrl: 'template/html-tag/show-data.html',
      scope: { label: '@', value: '@', myclass: '@'},
      link: function (scope) {
        scope.myclass = scope.myclass?scope.myclass:'myLabel';
      },

    };
  })

  .directive('switchStatus', function () {
    return {
      restrict: 'E',
      replace: 'true',
      templateUrl: 'template/html-tag/switch-status.html',
      scope: { model: '=', activelabel: '@', inactivelabel: '@', inverted: '@' },
      templateUrl: function (elem, attrs) {
        if (attrs.inverted != undefined && attrs.inverted != null) {
          return 'template/html-tag/switch-status-inverted.html';
        } else {
          return 'template/html-tag/switch-status.html';
        }
      }

    };
  })


  .directive('getInput', function () {
    return {
      restrict: 'E',
      replace: 'true',
      scope: { model: '=', error: '=', type: '@', identifier: '@', disabled: '=', myclass: '@', mylabel: '@', mylabelclass: '@', myinputclass: '@'  },
      templateUrl: function (elem, attrs) {
        if (attrs.labelleft != undefined && attrs.labelleft != null) {
          return 'template/html-tag/get-input_label-left.html';
        } else {
          return 'template/html-tag/get-input.html';
        }
      }
    };
  })


  .directive('dropdownList', function () {
    return {
      restrict: 'AE',
      replace: 'true',
      transclude: true,
      scope: { model: '=', change: '=', list: '=', mylabel: '@', mylabelclass: '@', error: '=', namefield: '@', selectedfield: '@', disabled: '=', identifier: '@', converttonumber: '@', myclass: '@', myoptionngclass: '@', emptyoption: '@' },
      link: function (scope) {
        scope.namefield = scope.namefield || 'name';
        scope.selectedfield = scope.selectedfield || 'id';
      },
      templateUrl: function (elem, attrs) {
        if (attrs.converttonumber != undefined && attrs.converttonumber != null) {
          return 'template/html-tag/dropdown-list-to_number.html';
        } else {
          return 'template/html-tag/dropdown-list.html';
        }
      }
    };
  })

  .directive('image360', function () {
    return {
      restrict: 'E',
      replace: 'true',
      transclude: true,
      scope: { ctrl:"=", instance:'=', list: '='},
      templateUrl: function (elem, attrs) {
          return 'template/html-tag/image360.html';
      }
    };
  })

  .directive('compassBearing', function () {
    return {
      restrict: 'E',
      replace: 'true',
      transclude: true,
      scope: { text:'@', degrees:"="},
      templateUrl: function (elem, attrs) {
          return 'template/html-tag/compass-bearing.html';
      }
    };
  })

  .directive('sfRichTextFlat', function() {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: 'template/html-tag/sfRichText-flat.html',
        scope: { model: '=', mylabel: '@', error:'=',  identifier: '@', disabled: '=', mainctrl: '@', fieldname:'@', object:'=', mystyle:'@', myclass:'@', mylabelclass:'@'},

    };
  })

  .directive('dropdownMenu', function() {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: 'template/html-tag/dropdown-menu.html',
        scope: {list:'=', identifier: '@', refctrl: '=', top:"=", left:"="},

    };
  })

  .directive('accordion', function(){
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'template/html-tag/accordion.html',
      scope: { title:'@', isbodyvisible:'=' },
    };
  })

  .directive('dynamicCadTag', function() {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: 'template/html-tag/dynamic-cad-tag.html',
        scope: {info:'=', identifier: '@', refctrl: '='},

    };
  })


// //Use this directive to combine more controllers into a main controller 
// //The directive build an html structure with many div innested 
// //where the most inner div will contain the controller declared into mainController attribute
// //All the html enclosed into my-controller element
// //will be hold into the most inner div
// .directive('myController',  ['$http','$rootScope','$compile', function($http,$rootScope,$compile) {
//     return {
//         restrict: 'E',
//         transclude: true,
//         scope: {mainController:'@', subControllers:'@'},

//         link: function (scope, elm, attrs,ctrl,transclude) {
//           //I'm going to create a structure like this
//           //<div>
//           //  <div ng-controller="subCtrl1">
//           //    <div ng-controller="subCtrl2">
//           //      <div ng-controller="mainCtrl">
//           //          here there will be the html enclosed into my-controller element
//           //      </div>
//           //    </div>
//           //  </div>
//           //</div>
//           var _mainHtml = '<div>';

//           attrs["subControllers"].split(",").forEach(e => {
//             _mainHtml += '<div ng-controller="' + e.trim()+'">';
//           })

//           var _idRandom = Math.random().toString(36).substring(7);
//           _mainHtml += '<div id="_'+_idRandom+'" ng-controller="' + attrs["mainController"]+'"></div>';


//           attrs["subControllers"].split(",").forEach(e => {
//             _mainHtml += '</div>';
//           })

//           _mainHtml += '</div>';

//           var _mainElm = $compile(_mainHtml)(scope);

//           transclude(scope,function(clone) {
//             //Get the most inner element and append 
//             //the html enclosed into my-controller element
//             angular.element(_mainElm[0].querySelector('#_'+_idRandom)).append(clone);

//           });

//           elm.replaceWith($compile(_mainElm)(scope));
//         }
//     };
//   }])

