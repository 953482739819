//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('inventoryFactory', ['inventoryServices', '$timeout', 'myHttp', 'myLog',  'utility', 'myAlert', 'fsValidator', 'popupConfirm', '$mdDialog',
    'assetDataServices', 'componentServices', 'productServices', 'catalogueFactory', 'assetServices', 'ngTableParams', 'dynamicCADServices',
    function(inventoryServices, $timeout, myHttp, myLog, utility, myAlert, fsValidator, popupConfirm, $mdDialog,
            assetDataServices, componentServices, productServices, catalogueFactory, assetServices, ngTableParams, dynamicCADServices)  {

        //return an array where each element is the list of InventoryField elements about an InventoryGroup
        function _getInventoryFieldByCompanyIdAndFacilityTypeKey(_listInventoryField) {
            var _ret = [];
            var _groups = [];

            //get all the inventoryGroupId
            _listInventoryField.forEach(e => {_groups[e.inventoryGroupId] = e.inventoryGroupId;});

            //create an array where each element is the list of InventoryField elements about an InventoryGroup
            _groups.forEach(g => {
                var _item = {
                    inventoryGroupId: g,
                    listInventoryField: _listInventoryField.filter(r => r.inventoryGroupId==g)
                }

                _ret.push(_item);
            })

            return _ret;
        }

        function _createArrayInventoryFieldByProductGroupId(_productGroupId, _arrayInventoryFieldByInventoryGroupId, _listProductGroupInventoryGroup, _inventoryFieldValueJson) {
            var _listInventoryGroup = _listProductGroupInventoryGroup.filter(e => e.productGroupId==_productGroupId);
            var _ret = [];
            
            var _arrayInventoryFieldByInventoryGroupId = angular.copy(_arrayInventoryFieldByInventoryGroupId);

            //set the "value" field to undefined
            _arrayInventoryFieldByInventoryGroupId.forEach(a => {
                a.listInventoryField.forEach(f => f.value = undefined);
            })

            _listInventoryGroup.forEach(e => _ret.push(_arrayInventoryFieldByInventoryGroupId.find(i => i.inventoryGroupId==e.inventoryGroupId)));

            //set the values of all the InventoryField based on _inventoryFieldValueJson
            if (_inventoryFieldValueJson) {
                _ret.forEach(r => {
                    _inventoryFieldValueJson.some(v => {
                        if (v.inventoryGroupId == r.inventoryGroupId) {
                            r.listInventoryField.forEach(rf => {
                                rf.value = v.listInventoryFieldValue.find(vf => vf.inventoryFieldId == rf.id).value;
                            })
                        }
                    })
                })
            }

            myLog("createArrayInventoryFieldByProductGroupId", _ret)

            return _ret;
        }          

        function _setInventoryProductName(__inventoryType) {
            var _prevProductId
            var _inventoryType = __inventoryType;
            return function (_inventoryItem, _inventoryItemPrev, _listProduct, _productId) {
                if (_productId && _productId!=_prevProductId && _listProduct) {
                    _prevProductId = _productId;
                
                    var _index = utility.getIndexArrayElementByField(_listProduct, 'id', _productId);
                    if (_index != undefined) {
                        _inventoryItem.productName = _listProduct[_index].name;                        
                        
                        if (_inventoryType == 'inventory') {
                            if (_productId != _inventoryItemPrev.productId) {
                                _inventoryItem.productIndicatorKey = _listProduct[_index].indicatorKey;
                                _inventoryItem.productPrice = _listProduct[_index].price;
                            } else {
                                _inventoryItem.productIndicatorKey = _inventoryItemPrev.productIndicatorKey;
                                _inventoryItem.productPrice = _inventoryItemPrev.productPrice;
                            }
                        }
                    } else {
                        _inventoryItem.productName = "";
                        if (_inventoryType == 'inventory') {
                            _inventoryItem.productIndicatorKey = undefined;
                            _inventoryItem.productPrice = 0;
                        }
                    }
                } 
            }
        }

        function _getListProduct(__inventoryType) {
            var _prevProductGroupId;
            var _inventoryType = __inventoryType;
            return function(_inventoryData, _inspectingCompanyId, _productGroupId, _success, _error) {
                if ((_productGroupId || isNaN(_productGroupId)) && _productGroupId!=_prevProductGroupId) {
                    _prevProductGroupId = _productGroupId;

                    if (isNaN(_productGroupId)) {
                        _inventoryData.listProduct = undefined;
                        _success ? _success() : null;  //Call the _success function only if it is defined                                
                    } else {
                        productServices.getProductByCompanyIdAndProductGroupId(_inspectingCompanyId, _productGroupId, _res => {
                            _inventoryData.listProduct = _res;
    
                            if (_inventoryType == 'inventory') {
                                var _inventoryFieldValueJsonParsed = undefined;
    
                                if (_inventoryData.inventoryItemSelected.inventoryFieldValueJson) {
                                    _inventoryFieldValueJsonParsed = JSON.parse(_inventoryData.inventoryItemSelected.inventoryFieldValueJson);
                                }
        
                                _inventoryData.inventoryItemSelected.arrayInventoryFieldByProductGroup = 
                                    _createArrayInventoryFieldByProductGroupId(_productGroupId, _inventoryData.arrayInventoryFieldByInventoryGroupId, _inventoryData.listProductGroupInventoryGroup, _inventoryFieldValueJsonParsed);    
                            } 
    
                        })        
    
                    }
                }
            }
        }

        function _saveInventoryItem(_listInventoryItem, _inventoryItem, _inspectingCompanyId, _success, _error) {
            inventoryServices.saveInventoryItem(_inspectingCompanyId, _inventoryItem, _res => {

                _res.count = _inventoryItem.inventoryFieldValueJson?1:0;

                if (_inventoryItem.listInventoryItemExtraInfo) {
                    _inventoryItem.listInventoryItemExtraInfo.forEach(e => {
                        e.inventoryItemId = _res.id;
                    })

                    inventoryServices.saveInventoryItemExtraInfo(_inventoryItem.listInventoryItemExtraInfo, _res1 => {
                        _res.listInventoryItemExtraInfo = _res1
                        _res.count = _res.count + _res1.length;

                        myAlert("Asset's inventory item saved", 'info');
                        _updateInventoryItemAfterActions(_listInventoryItem, _res)

                        _success ? _success(_res) : null;  //Call the _success function only if it is defined        
                    })

                } else {
                    myAlert("Asset's inventory item saved", 'info');
                    _updateInventoryItemAfterActions(_listInventoryItem, _res)

                    _success ? _success(_res) : null;  //Call the _success function only if it is defined    
                }
            })
        }

        function _updateInventoryItemAfterActions(_listInventoryItem, _inventoryItemNew) {
            //get the asset index into the array
            var _index = utility.getIndexArrayElementByField(_listInventoryItem, 'id', _inventoryItemNew.id);

            if (_index != undefined) {
                //update the customer at _index position into the customers list
                _listInventoryItem[_index] = _inventoryItemNew;
            } else {
                //add the new customer because it wasn't find into customers list
                _listInventoryItem.push(_inventoryItemNew);
            }

        }        

        function _getInventoryFieldJson(_arrayInventoryFieldByProductGroup) {                
            var _ret = [];

            if (_arrayInventoryFieldByProductGroup) {
                _arrayInventoryFieldByProductGroup.forEach(g => {
                    var _itemArray = {
                        inventoryGroupId: g.inventoryGroupId,
                        listInventoryFieldValue: []
                    }

                    g.listInventoryField.forEach(e => {
                        var _item= {
                            inventoryFieldId: e.id,
                            inventoryItemId: undefined,
                            value: e.value                        
                        }
    
                        _itemArray.listInventoryFieldValue.push(_item);    
                    })

                    _ret.push(_itemArray);
                })    
            }

            return _ret;
        }        

        function _validationItem(_item, _type) {
            var v = fsValidator.newInstance();

            if (_type=="inventoryItem") {
                v.validate(_item.structureId, 'structureId').isEmpty("This field can't be empty")
                v.validate(_item.productName, 'productName').isEmpty("This field can't be empty")
                // v.validate(_item.productPrice, 'productPrice').isEmpty("This field can't be empty")
                v.validate(_item.quantity, 'quantity').isEmpty("This field can't be empty")
                v.validate(_item.productIndicatorKey, 'productIndicatorKey').isEmpty("This field can't be empty")
                v.validate(_item.status, 'status').isEmpty("This field can't be empty")    
            } else if (_type=="inventoryField") {
                _item.listInventoryField.forEach(e => {
                    if (e.isMandatory) {
                        v.validate(e.value, e.key).isEmpty("This field can't be empty")
                    }
                })
            } else if (_type=="extraField") {
                if (_item.isNewField) {                    
                    v.validate(_item.labelKey, 'labelKey').isEmpty("This field can't be empty")
                }
                v.validate(_item.value, 'value').isEmpty("This field can't be empty")
            }

            _item.errors = v.getErrors();

            return v;
        }        

        function _validationInventoryItem(_inventoryItem) {
            var _ret = false;
            var _validation = true;

            _validationItem(_inventoryItem, 'inventoryItem').success(function () {
                _ret = true;
            }).errors(function () {
                _ret = false;
            })

            //validate of the InventoryField's mandatory elements
            if (_inventoryItem.arrayInventoryFieldByProductGroup) {                
                _inventoryItem.arrayInventoryFieldByProductGroup.forEach(gr => {
                    _validationItem(gr, 'inventoryField').success(function () {
                        _validation = _validation && true;
                    }).errors(function () {
                        _validation = false;
                    })
                })
            }

            if (_inventoryItem.listInventoryItemExtraInfo) {                
                _inventoryItem.listInventoryItemExtraInfo.forEach(ef => {
                    _validationItem(ef, 'extraField').success(function () {
                        _validation = _validation && true;
                    }).errors(function () {
                        _validation = false;
                    })
                })
            }


            if (!(_ret && _validation)) {
                _ret = _inventoryItem;
            }

            return _ret
        }


        function _validationItems(_item) {
            var v = fsValidator.newInstance();

            v.validate(_item.structureId, 'structureId').isEmpty("This field can't be empty")
            v.validate(_item.quantity, 0, 'quantity', 'number').isSmallerThan("This field can't be lesser than 0")

            //this check means that the productName is empty
            //In that case I'm going to check if the productGroupId is also empty
            v.validate(_item.productName, 'productName').isEmpty("This field can't be empty");
            if (v.getErrors()['productName']) {
                
                //this check means that the productGroupId isn't empty
                //In that case I'm going to check if the productId isn't empty
                v.validate(_item.productGroupId, 'productGroupId').isEmpty("This field can't be empty")
                if (!v.getErrors()['productGroupId']) {
                    v.validate(_item.productId, 'productId').isEmpty("This field can't be empty")
                }
            }

            _item.errors = v.getErrors();

            return v;
        }            

        function _validationInventoryFastItem(_inventoryItems) {
            var _ret = true;

            _inventoryItems.forEach(e => {
                if (!e.isDeleted) {
                    _validationItems(e).errors(function () {
                        _ret *= false;
                    })        
                }
            })


            if (!_ret) {
                _ret = _inventoryItems;
            }

            return _ret
        }        

        function _hasDataChanged(_inventoryData) {
            return !angular.equals(_inventoryData.inventoryItemSelected, _inventoryData.inventoryItemSelectedPrev);
        }

        function _getInventoryFastItemEmpty(_inventoryData) {
            var _structureId = utility.getArrayByField(_inventoryData.listAssetStructure, 'structureTypeKey')['complete-asset'].id;
            return {
                itemId: (new Date()).getTime(), //this isn't an inventory field. I need it just to delete this element
                structureId: _structureId, //by default is 'complete-asset'
                productGroupId: undefined,
                productId: undefined,
                productName: undefined,
                quantity: 1,
                facilityId: _inventoryData.asset.id,
                status: 'active',
                productIndicatorKey: 'active',
                isDeleted: 0,
                getListProduct: _getListProduct(_inventoryData.inventoryType),
                setInventoryProductName: _setInventoryProductName('inventoryFast')
            }
        }

        function _setInventoryFastItemDefaultByProduct(_inventoryData, _item, _product, _listProductGroupById) {
            _item.quantity = _product.quantity;
            _item.productId = _product.id;
            _item.productName = _product.name;
            _item.productGroupId = _listProductGroupById[_product.productGroupId].id;
        }

        function _inventoryFastInit(_inventoryData) {
            var _inventoryFastList = [];
            var _productDefaultNumber = _inventoryData.listAllProduct.filter(e => e.isInventoryFastDefault).length;
            var _defaultItemNumber = 9;
            var _itemNumber = _productDefaultNumber>_defaultItemNumber?_productDefaultNumber:_defaultItemNumber;

            for(var i=0; i<_itemNumber; i++) {
                _inventoryFastList.push(_getInventoryFastItemEmpty(_inventoryData));
            }

            var _listProductGroupById = utility.getArrayByField(_inventoryData.listProductGroup, 'id');

            _inventoryData.listAllProduct.filter(e => e.isInventoryFastDefault).forEach((e, _index) => {
                _setInventoryFastItemDefaultByProduct(_inventoryData, _inventoryFastList[_index], e, _listProductGroupById);
            })

            return _inventoryFastList;
        }

        return {
            init: function(_inventoryType, _assetId, _inspectingCompanyId, _facilityTypeKey, _success, _error) {
                var _listHttp = [
                    myHttp.buildPromise(inventoryServices, "getInventoryItemByAssetId", _assetId),
                    myHttp.buildPromise(inventoryServices, "getInventoryStatusType"),
                    myHttp.buildPromise(inventoryServices, "getInventoryItemExtraInfoLabel", _inspectingCompanyId),
                    myHttp.buildPromise(inventoryServices, "getInventoryGroupByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(inventoryServices, "getProductGroupInventoryGroupByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(inventoryServices, "getInventoryFieldByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(catalogueFactory, "getListProductGroupByCompanyId", _inspectingCompanyId),
                    myHttp.buildPromise(productServices, "getProductIndicatorType"),
                    myHttp.buildPromise(assetServices, "getAssetStructures", _assetId),
                    myHttp.buildPromise(assetServices, "getAssetTypes"),
                ];

                if (_inventoryType=='inventoryFast') {
                    _listHttp.push(myHttp.buildPromise(productServices, "getProductByCompanyId", _inspectingCompanyId));
                }

                myHttp.runPromise(_listHttp, _res => {
    
                    var _ret = {
                        listInventoryItem: _res[0],
                        listInventoryStatusType: _res[1],
                        listInventoryItemExtraInfoLabel: _res[2],
                        listInventoryGroup: _res[3],
                        listProductGroupInventoryGroup: _res[4],
                        arrayInventoryFieldByInventoryGroupId: _getInventoryFieldByCompanyIdAndFacilityTypeKey(_res[5]),
                        listProductGroup: _res[6],
                        listProductIndicatorType: _res[7],
                        listAssetStructure: _res[8],
                        listAssetTypeByKey: utility.getArrayByField(_res[9], 'id'),
                    }

                    if (_inventoryType=='inventoryFast') {
                        _ret.listAllProduct = _res[10];
                    }   

                    _success(_ret)
                })
    

            },

            getObjectsDetected: function(_assetDataId, _inspectingCompanyId, _success, _error) {
                //Get all the objects linked to ComponentTag
                const _componentTagPromise = new Promise((resolve, reject) => {
                    inventoryServices.getComponentTagForInventory(_assetDataId, _inspectingCompanyId, resolve, reject);
                });

                //Get all the objects linked to FacilityDataDetailsTag
                const _facilityDataDetailsTagPromise = new Promise((resolve, reject) => {
                    inventoryServices.getFacilityDataDetailsTagForInventory(_assetDataId, _inspectingCompanyId, resolve, reject);
                });

                //Get all the tags type about Component, Anomaly and FacilityDataDetails
                const _facilityDataDetailsTagTypePromise = new Promise((resolve, reject) => {
                    assetDataServices.getAssetDataDetailsTagTypes(resolve, reject);
                });

                var  result = Promise
                .all([_componentTagPromise, _facilityDataDetailsTagPromise, _facilityDataDetailsTagTypePromise])
                .then(([_componentTag, _facilityDataDetailsTag, _facilityDataDetailsTagType]) => {
                    var _ret = [];

                    _componentTag.forEach(e => {
                        var _item = {
                            id: e.id,
                            facilityDataDetailsId: e.facilityDataDetailsId,
                            tagKey: e.fddttKey,
                            tagValue: utility.getArrayElementByField(_facilityDataDetailsTagType, 'key', e.fddttKey).inventoryName,
                            counts: e.counts,
                            extraInfo: undefined,
                        }

                        _ret.push(_item)
                    })    

                    _facilityDataDetailsTag.forEach(e => {
                        var _item = {
                            id: undefined,
                            facilityDataDetailsId: e.facilityDataDetailsId,
                            tagKey: e.tagKey,
                            tagValue: utility.getArrayElementByField(_facilityDataDetailsTagType, 'key', e.tagKey).value,
                            counts: 1,
                            extraInfo: JSON.parse(e.extraInfo)
                        }

                        _ret.push(_item)
                    })    

                    return _ret;
                });                


                return result.then(_res => {
                    _success(_res)
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    _error(_err)
                });
            },
            getDatasetObjectsDetected: function(_assetDataId, _listObjectsDetected, _success, _error) {
                //Get all the objects linked to ComponentTagArea
                const _componentTagAreaPromise = new Promise((resolve, reject) => {
                    componentServices.getComponentTagAreaByFacilityDataId(_assetDataId, resolve, reject);
                });

                var  result = Promise
                .all([_componentTagAreaPromise])
                .then(([_componentTagArea]) => {
                    var _ret = [];

                    _componentTagArea.forEach(e => {
                        var _item = utility.getArrayElementByField(_listObjectsDetected, 'id', e.componentTagId);
                        _item.color = e.color;

                        _ret.push(_item)
                    })    

                    return _ret;
                });                


                return result.then(_res => {
                    _success(_res)
                }, _err => {
                    myAlert("Error: " + _err.data.message, 'danger');
                    _error(_err)
                });
            },

            //return an array where each element is the list of InventoryField elements about an InventoryGroup
            getInventoryFieldByCompanyIdAndFacilityTypeKey: function(_inspectingCompanyId, _facilityTypeKey, _success, _error) {
                inventoryServices.getInventoryFieldByCompanyIdAndFacilityTypeKey(_inspectingCompanyId, _facilityTypeKey, _res => _success(_getInventoryFieldByCompanyIdAndFacilityTypeKey(_res)));
            },
            getInventoryStatusTypeByKey: function() {
                return function (_listInventoryStatusType, _key) {
                    return utility.getArrayElementByField(_listInventoryStatusType, 'key', _key);
                }
            },
            getInventoryItemTableParams: function () {
                var _ngTableParams;
                return {
                    init: function (_list) {
                        _ngTableParams = ngTableParams.default(_list);
                        return _ngTableParams;
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            },
            getInventoryFastItemTableParams: function () {
                var _ngTableParams;
                return {
                    init: function (_list) {
                        _ngTableParams = ngTableParams.withConfig({ count: 100 }, _list);
                        return _ngTableParams;
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            },


            getListProduct: function(_inventoryType) {
                return _getListProduct(_inventoryType);
            },
            createArrayInventoryFieldByProductGroupId: function(_productGroupId, _arrayInventoryFieldByInventoryGroupId, _listProductGroupInventoryGroup, _inventoryFieldValueJson) {
                return _createArrayInventoryFieldByProductGroupId(_productGroupId, _arrayInventoryFieldByInventoryGroupId, _listProductGroupInventoryGroup, _inventoryFieldValueJson);
            },
            setInventoryProductName: function(_inventoryType) {
                return _setInventoryProductName(_inventoryType);
            },
            editInventoryItem: function (_inspectingCompanyId, _inventoryItem, _inventoryData) {
                // return function (_inspectingCompanyId, _inventoryItem, _inventoryData) {               
                    _inventoryItem.listInventoryItemExtraInfoSelected = [];

                    //create a new setInventoryProductName's reference, in order to cleare the cache about this function
                    _inventoryData.setInventoryProductName = _setInventoryProductName(_inventoryData.inventoryType);     
                    
                    _inventoryItem.warranteeStart = new Date(_inventoryItem.warranteeStart);
                    _inventoryData.inventoryItemSelected = angular.copy(_inventoryItem)
                    _inventoryData.inventoryItemSelectedPrev = angular.copy(_inventoryItem)


                    if (_inventoryItem.productGroupId) _getListProduct(_inventoryData, _inspectingCompanyId, _inventoryItem.productGroupId);                    

                    //workaround about synchronization problem of the screen's fields (Product Group, Product, Product Name)
                    $timeout(function() {
                        var _inventoryFieldValueJsonParsed = JSON.parse(_inventoryItem.inventoryFieldValueJson);

                        _inventoryData.inventoryItemSelected = angular.copy(_inventoryItem)

                        if (_inventoryItem.productGroupId) {
                            _getListProduct(_inventoryData, _inspectingCompanyId, _inventoryItem.productGroupId);                        

                            _inventoryData.inventoryItemSelected.arrayInventoryFieldByProductGroup = 
                                _createArrayInventoryFieldByProductGroupId(_inventoryItem.productGroupId, _inventoryData.arrayInventoryFieldByInventoryGroupId, _inventoryData.listProductGroupInventoryGroup, _inventoryFieldValueJsonParsed);

                            _inventoryData.inventoryItemSelectedPrev = angular.copy(_inventoryData.inventoryItemSelected)
                        }

                        if (!_inventoryItem.listInventoryItemExtraInfo) {
                            inventoryServices.getInventoryItemExtraInfo(_inventoryItem.id, _res => {
                                _inventoryData.inventoryItemSelected.listInventoryItemExtraInfo = _res

                                _inventoryData.inventoryItemSelectedPrev = angular.copy(_inventoryData.inventoryItemSelected)
                            })    
                        }

                    }, 50)

                    // utility.callFunctionEveryTime('!$("#_inventory_fields").hasClass("ng-hide")', 200, function() {
                    utility.callFunctionEveryTime('!$("#_inventory_fields")', 200, function() {
                        $("#_asset_panel").scrollTop( $("#_inventory_fields").offset().top );           
                    });    
                // }
            },
            deleteInventoryItem: function (_inventoryItem, _inventoryData) {
                // return function (_inventoryItem, _inventoryData) {
                var _confirm = popupConfirm('Warning', 'Do you want to delete the Inventory Item?')
                $mdDialog.show(_confirm).then(function () {
    
                    inventoryServices.deleteInventoryItemById(_inventoryItem.id, _res => {
                        _inventoryData.listInventoryItem.splice(utility.getIndexArrayElementByField(_inventoryData.listInventoryItem,'id', _inventoryItem.id), 1);                        

                        _inventoryData.getInventoryItemTableParams.refresh();

                        _inventoryData.inventoryItemSelected = false;
                        _inventoryData.inventoryItemSelectedPrev = false;
                    });
                });
                // }
            },
            showInventoryItemDetails: function() {
                return function(_inventoryItem, _listInventoryGroup, _arrayInventoryFieldByInventoryGroupId) {
                    _inventoryItem.showDetails = !_inventoryItem.showDetails;                    

                    if (_inventoryItem.showDetails) {
                        _inventoryItem.listInventoryItemDetails = [];


                        inventoryServices.getInventoryItemExtraInfo(_inventoryItem.id, _res => {
                            _inventoryItem.listInventoryItemExtraInfo = _res

                            if (_res.length>0) {
                                _inventoryItem.listInventoryItemDetails.push({
                                    detailType: 'inventoryItemExtraInfo',
                                    details: _res
                                })    
                            }
                        })

                        _inventoryItem.inventoryFieldValueJsonParsed = undefined;
                        if (_inventoryItem.inventoryFieldValueJson) {
                            var _inventoryFieldValueJsonParsed = JSON.parse(_inventoryItem.inventoryFieldValueJson);

                            if (_inventoryFieldValueJsonParsed.length>0) {
                                _inventoryFieldValueJsonParsed.forEach(g => {
                                    g.inventoryGroup = _listInventoryGroup.filter(ig => ig.id == g.inventoryGroupId)[0];
                                    var _listInventoryField = _arrayInventoryFieldByInventoryGroupId.filter(a => a.inventoryGroupId == g.inventoryGroupId)[0].listInventoryField;
    
                                    g.listInventoryFieldValue.forEach(v => {
                                        v.inventoryField = _listInventoryField.filter(f => f.id == v.inventoryFieldId)[0];
                                    })
                                })
    
                                _inventoryItem.listInventoryItemDetails.push({
                                    detailType: 'inventoryFieldValueJsonParsed',
                                    details: _inventoryFieldValueJsonParsed
                                })    
                            }

                        }
                    }
                }
            },
            addInventoryItemExtraInfo: function() {
                return function(_inventoryItemSelected, _listInventoryItemExtraInfoSelected) {
                    if (!_inventoryItemSelected.listInventoryItemExtraInfo) {
                        _inventoryItemSelected.listInventoryItemExtraInfo = [];
                    }
    
                    if (_listInventoryItemExtraInfoSelected && _listInventoryItemExtraInfoSelected.length>0) {
                        _listInventoryItemExtraInfoSelected.forEach(e => {
                            var _item = {
                                inventoryItemId: _inventoryItemSelected.id,
                                labelKey: e.key,
                                value: undefined,
                                isNewField: false                            
                            }
                            _inventoryItemSelected.listInventoryItemExtraInfo.push(_item);
                        })
                    } else {
                        var _item = {
                            inventoryItemId: _inventoryItemSelected.id,
                            labelKey: undefined,
                            value: undefined,
                            isNewField: true                       
                        }
                        _inventoryItemSelected.listInventoryItemExtraInfo.push(_item);
                    }
                }
            },
            getInventoryGroupById: function() {
                return function(_list, _id) {
                    return _list.filter(e => e.id == _id)[0];
                }
            },
            getInventoryItemExtraInfoLabelByKey: function() {
                return function(_list, _key) {
                    return _list.filter(e => e.key==_key)[0];
                }
            },            
            getProductIndicatorTypeByKey: function() {
                var _empty = {
                    value: ''
                }

                return function(_list, _key) {
                    if (_key) {
                        return _list.filter(e => e.key==_key)[0];
                    } else {
                        return _empty;
                    }
                }
            },
            getStructureById: function() {
                return function (_listStructure, _id) {
                    return utility.getArrayElementByField(_listStructure, 'id', _id);
                }
            },
            addInventoryItem: function(_inventoryData) {
                _inventoryData.inventoryItemSelected = {
                    listInventoryItemExtraInfoSelected: [],
                    facilityId: _inventoryData.asset.id,
                    productName: '',
                    quantity: 1,
                    productPrice: 0,
                }

                //Get the first ProductGroup and Product as default
                if (_inventoryData.listProductGroup && _inventoryData.listProductGroup.length>0) {
                    _inventoryData.inventoryItemSelected.productGroupId = _inventoryData.listProductGroup[0].id;

                    utility.callFunctionWithScopeEveryTime(_inventoryData, '.listProduct', 200, function() {
                        _inventoryData.inventoryItemSelected.productId = _inventoryData.listProduct[0].id;
                        _setInventoryProductName(_inventoryData.inventoryItemSelected, _inventoryData.inventoryItemSelectedPrev, _inventoryData.listProduct, _inventoryData.inventoryItemSelected.productId)    
                    }) 
                } 

                _inventoryData.inventoryItemSelectedPrev = angular.copy(_inventoryData.inventoryItemSelected)


                // utility.callFunctionEveryTime('!$("#_inventory_fields").hasClass("ng-hide")', 200, function() {
                //     $("#_asset_panel").scrollTop( $("#_inventory_fields").offset().top );   zzzzzzzz
    
                // });    
            },
            saveInventoryItem: function(_inventoryData, _inspectingCompanyId, _success, _error) {

                var _productId = _inventoryData.inventoryItemSelected.productId;
                var _productName = _inventoryData.inventoryItemSelected.productName;

                if (_productId && _inventoryData.listProduct) {
                    if (utility.getArrayElementByField(_inventoryData.listProduct, 'id', _productId).name != _productName) {
                        _inventoryData.inventoryItemSelected.productId = undefined;
                        _inventoryData.inventoryItemSelected.productGroupId = undefined;
                    }
                }                
                
                //Put the InventoryField array, with values, into the InventoryItem.invetoryFieldValueJson field as a JSON transformed into String
                _inventoryData.inventoryItemSelected.inventoryFieldValueJson = JSON.stringify(_getInventoryFieldJson(_inventoryData.inventoryItemSelected.arrayInventoryFieldByProductGroup));

                if (_validationInventoryItem(_inventoryData.inventoryItemSelected) == true) {
                    var _confirm = popupConfirm('Warning', 'Do you want to save the Inventory Item?')
                    $mdDialog.show(_confirm).then(function () {
        
                        _saveInventoryItem(_inventoryData.listInventoryItem, _inventoryData.inventoryItemSelected, _inspectingCompanyId, _res => {

                            inventoryServices.getInventoryItemExtraInfoLabel(_inspectingCompanyId, _res1 => {
                                _inventoryData.listInventoryItemExtraInfoLabel = _res1;
                                // _popup.updateData(_popup.data);
                            });
        
                            _inventoryData.getInventoryItemTableParams.refresh();
                            _inventoryData.inventoryItemSelected = false;
                            _inventoryData.inventoryItemSelectedPrev = false;

                            // _popup.success(_popup.data)
                            _success ? _success(_inventoryData) : null;  
                        }, _err => {
                            // _popup.error(_err);
                            _error ? _error(_err) : null;  
                        });    
                    })

                } else {
                    myAlert("There some field not valid. Please check.", "danger")
                }
            },
            closeInventory: function(_inventoryData, _callback, _noCallback) {
                if (_hasDataChanged(_inventoryData)) {
                    var _confirm = popupConfirm('Warning', 'Some data has been changed. Do you want to exit without saving?')
                    $mdDialog.show(_confirm).then(function () {
                        _callback ? _callback() : null;  
                    }, function() {
                        _noCallback ? _noCallback() : null;  
                    });
                } else {
                    _callback ? _callback() : null;  
                }
            },
            clearInventoryItem: function(_inventoryData) {
                _inventoryData.inventoryItemSelected = false;
                _inventoryData.inventoryItemSelectedPrev = false;
                _inventoryData.getListProduct = this.getListProduct();
                _inventoryData.setInventoryProductName = this.setInventoryProductName(_inventoryData.inventoryType);
            },
            autoInventoryFromModel3D: function(_inventoryData) {
                var _inspectingCompanyId = _inventoryData.inspectingCompanyId;
                var _facilityTypeKey = _inventoryData.listAssetTypeByKey[_inventoryData.asset.facilityTypeId].key;

                myHttp.runPromise([
                    myHttp.buildPromise(dynamicCADServices, "getModel3DObjectByModel3DId", _inventoryData.asset.model3dId),
                    myHttp.buildPromise(dynamicCADServices, "getObject3DByCompanyAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(dynamicCADServices, "getObjects3DTypeByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                ], _res => {
    
                    var _listModel3DObject = _res[0];
                    var _listObject3D = utility.getArrayByField(_res[1], 'id');
                    var _listObject3DType = utility.getArrayByField(_res[2], 'id');

                    console.clear()
                    myLog("listModel3DObject", _listModel3DObject);
                    myLog("listObject3D", _listObject3D);

                    _listModel3DObject.forEach(e => {
                        var _obj3D = _listObject3D[e.object3dId];
                            
                        myLog("type:"+_listObject3DType[_obj3D.object3dTypeId].value, "code:"+_obj3D.code, "name:"+_obj3D.name)
                    })
                })
    

            },
            inventoryFastInit: function(_inventoryData) {
                // var _inventoryFastList = [];
                // var _productDefaultNumber = _inventoryData.listAllProduct.filter(e => e.isInventoryFastDefault).length;
                // var _defaultItemNumber = 10;
                // var _itemNumber = _productDefaultNumber>_defaultItemNumber?_productDefaultNumber:_defaultItemNumber;

                // for(var i=0; i<_itemNumber; i++) {
                //     _inventoryFastList.push(_getInventoryFastItemEmpty(_inventoryData));
                // }

                // var _listProductGroupById = utility.getArrayByField(_inventoryData.listProductGroup, 'id');

                // _inventoryData.listAllProduct.filter(e => e.isInventoryFastDefault).forEach((e, _index) => {
                //     _setInventoryFastItemDefaultByProduct(_inventoryData, _inventoryFastList[_index], e, _listProductGroupById);
                // })

                // return _inventoryFastList;
                return _inventoryFastInit(_inventoryData);
            },

            inventoryFastReset:function(_inventoryData) {
                //filter all the items already saved and set them to isDelete=1, because I want to delete them the next time  the user click save
                var _listInventoryFastItemOld = _inventoryData.listInventoryFastItem.filter(e => e.id);
                _listInventoryFastItemOld.forEach(e => e.isDeleted=1);
                var _listInventoryFast = _inventoryFastInit(_inventoryData);

                return _listInventoryFast.concat(_listInventoryFastItemOld);
            },

            saveInventoryFastItem: function(_inventoryData, _inspectingCompanyId, _success, _error) {
                if (_validationInventoryFastItem(_inventoryData.listInventoryFastItem) == true) {
                    var _confirm = popupConfirm('Warning', 'Do you want to save the Inventory Items?')

                    $mdDialog.show(_confirm).then(function () {
                        inventoryServices.saveInventoryFastItems(_inspectingCompanyId, _inventoryData.listInventoryFastItem, _res => {
                            _inventoryData.listInventoryFastItem = _res;
                            _inventoryData.listInventoryFastItem.forEach(e => {
                                e.getListProduct = _getListProduct(_inventoryData.inventoryType);
                                e.setInventoryProductName = _setInventoryProductName('inventoryFast');
                            });
                            _inventoryData.getInventoryFastItemTableParams.init(_inventoryData.listInventoryFastItem);
                            _inventoryData.getInventoryFastItemTableParams.refresh();

                            inventoryServices.getInventoryItemByAssetId(_inventoryData.asset.id, _res => {
                                _inventoryData.listInventoryItem = _res;
                                _inventoryData.getInventoryItemTableParams.init(_inventoryData.listInventoryItem);
                                _inventoryData.getInventoryItemTableParams.refresh();                
                            })

                            myAlert("inventory item saved", 'info');
                        })
                        _success ? _success(_inventoryData) : null;  
                    }, _err => {
                        _error ? _error(_err) : null;  
                    });    
                } else {
                    myAlert("There some items not valid. Please check.", "danger")
                }
            },
            deleteInventoryFastItem: function(_inventoryData, _index) {
                if (_inventoryData.listInventoryFastItem[_index].id) {
                    _inventoryData.listInventoryFastItem[_index].isDeleted = 1;
                } else {
                    _inventoryData.listInventoryFastItem.splice(_index, 1);
                }
                _inventoryData.getInventoryFastItemTableParams.refresh();
            },
            addInventoryFastItem: function(_inventoryData) {
                _inventoryData.listInventoryFastItem.push(_getInventoryFastItemEmpty(_inventoryData));
                _inventoryData.getInventoryFastItemTableParams.init(_inventoryData.listInventoryFastItem);
                _inventoryData.getInventoryFastItemTableParams.refresh();
            },
            showInventoryItemsIntoInventoryFast: function(_inventoryData) {
                _inventoryData.inventoryItemsShowned = !_inventoryData.inventoryItemsShowned;
            }
            // }

        };
    }
]);

