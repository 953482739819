//This controller deals with 
//get all user's information 

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
.controller('loginCtrl', ['$location','Scopes','$scope', '$http','$stateParams','$state','buildUrl','utility','myLog','myAlert','customerServices','userServices','expanseConst','mainServices','$timeout',
	function($location,Scopes,$scope,$http,$stateParams,$state,buildUrl,utility,myLog,myAlert,customerServices,userServices,expanseConst,mainServices,$timeout){

    Scopes.store('loginCtrl', $scope);

    var _token = localStorage.getItem('exlite.liteToken');
    var _redirect = $stateParams.page_name;

    //Get all the information about the user logging in
    //and redirect to the right screen
    userServices.getUserByToken(_token, _res => {  
      if (_res) {
            var _user = _res;
            var _tmp = JSON.parse(localStorage.getItem(expanseConst.userCacheName))
            _user.liteToken = _token;

            if (_tmp) {_user.assetTableParamsState = _tmp.assetTableParamsState;}
            //Set all the information gathered into "exlite.identity"
            localStorage.setItem(expanseConst.userCacheName, angular.toJson(_user));

            //Get user's customer's information
            const _customerPromise = new Promise((resolve, reject) => {
                customerServices.getCustomer(_user.companyId, resolve, reject);
            });
            //check if the user logged in is an INSPECTOR
            const _userPromise = new Promise((resolve, reject) => {
                userServices.isInspector(_user.id, resolve, reject);
            });
            //get the list of features evailable on an environment
            const _featurePromise = new Promise((resolve, reject) => {
                mainServices.getFeatures(resolve, reject);
            });
    
            var  result = Promise
            .all([_customerPromise, _userPromise, _featurePromise])
            .then(([_customerRes, _userRes, _featureRes]) => {
                _user.company = _customerRes;
                _user.isInspector = _userRes;
                _user.listFeature = _featureRes;
    
                //Set all the information gathered into "exlite.identity"

                if (_tmp) {_user.assetTableParamsState = _tmp.assetTableParamsState;}
                localStorage.setItem(expanseConst.userCacheName, angular.toJson(_user));
    
                var _url = $location.absUrl();
                var _urlParsed = _parseUrl(_url)
                $scope.redirect = _urlParsed.getTarget();
    
                $state.go(_urlParsed.getTarget(), _urlParsed.getUrlParams());
            })                
        } else {
            myAlert("Session expired", 'danger');
            $timeout(function() {
                window.location.replace(expanseConst.softeqUrl + "empl/logout");
            }, 2000)
        }
    }, _err => {
        myAlert("Error: " + _err.data.message, 'danger');
        myThrow(_err);    
    })


    //this function parse the URL 
    //and return the TARGET toward redirect
    //and the url parameters
    function _parseUrl(_url) {
        var _target;
        var _urlParams;

        if (_url.includes("/iframe/") ) {
            //---------------------Parse the url about an iframe call: begin-----------------------------------
            _urlParams = utility.parseUrl($location.absUrl()).getArrayParam(); 
            var _index = _urlParams['liteToken'].indexOf("/iframe/");
            localStorage.setItem('exlite.liteToken', _urlParams['liteToken'].substring(0, _index));



            _index = _url.indexOf("/iframe/");
            var _targetWithParams = _url.substring(_index+8, _url.length)
            _index = _targetWithParams.indexOf("?");
            var _index1 = _targetWithParams.indexOf("#");
            _target = _targetWithParams;
            _urlParams = {};

            if (_index > 0 || _index1 > 0) {
                if (_index1<_index) {
                    _target = _targetWithParams.substring(0, _index1);
                } else {
                    _target = _targetWithParams.substring(0, _index);
                }
                _urlParams =  utility.parseUrl(_targetWithParams).getListParam(); 
            }

            _target = "iframe-"+_target;
            //----------------------------------------------------------------------------------------------
        } else {
            //-----------------------Parse the url about a simple call: begin-------------------------------
            _target = _redirect;
            _urlParams = utility.parseUrl($location.absUrl()).getArrayParam();
            //----------------------------------------------------------------------------------------------
        }        

        return {
            getTarget: function() {
                return _target;
            },
            getUrlParams: function() {
                return _urlParams;
            }
        }
    }    

    // //Get user's information, from the db, with respect the _token
    // $http.get(buildUrl("/expanse/user/byToken/" + _token)).success(res => {
    //     var _user = res.userDto;
    //     _user.liteToken = _token;
        
    //     //Get user's customer's information
    //     $http.get(buildUrl("/expanse/customer/" + _user.companyId)).success(res1 => {
    //         _user.company = res1.customerDto;

    //         //check if the user logged in is an INSPECTOR
    //         $http.get(buildUrl("/expanse/user/isInspector/" + _user.id)).success(res2 => {
    //             _user.isInspector = res2;

    //             //Set all the information gathered into "exlite.identity"
    //             localStorage.setItem('exlite.identity', angular.toJson(_user));

    //             $scope.redirect = _redirect;

    //             var _url = $location.absUrl();
    //             if (_url.includes("/iframe/") ) {
    //                 var _urlParams = utility.parseUrl($location.absUrl()).getArrayParam(); 
    //                 var _index = _urlParams['liteToken'].indexOf("/iframe/");
    //                 localStorage.setItem('exlite.liteToken', _urlParams['liteToken'].substring(0, _index));
    
    
    
    //                 _index = _url.indexOf("/iframe/");
    //                 var _targetWithParams = _url.substring(_index+8, _url.length)
    //                 _index = _targetWithParams.indexOf("?");
    //                 var _index1 = _targetWithParams.indexOf("#");
    //                 var _target = _targetWithParams;
    //                 _urlParams = {};
    
    //                 if (_index > 0 || _index1 > 0) {
    //                     if (_index1<_index) {
    //                         _target = _targetWithParams.substring(0, _index1);
    //                     } else {
    //                         _target = _targetWithParams.substring(0, _index);
    //                     }
    //                     _urlParams =  utility.parseUrl(_targetWithParams).getListParam(); 
    //                 }
    
    //                 $state.go("iframe-"+_target, _urlParams);
    //             } else {
    //                 $state.go(_redirect, utility.parseUrl($location.absUrl()).getArrayParam());
    //             }
    //         });


    //     })

    // })


}]);
 
