const expanseLiteApp = require('../../modules/appModule.js');

expanseLiteApp.component('dashboardStatusCmp', {
    templateUrl: 'views/dashboard/common/dashboard-status-chart.html',
    controller: DashboardStatusController,
    controllerAs: 'statusCtrl',
    bindings: {
        companyType: '<',
        companyTypes: '<',
        statusTitle: '<',
        chartDataPromise: '<',
    }
});

/* @ngInject */
function DashboardStatusController($scope, Scopes, myLog, myAlert, buildChart) {
    const _this = this;

    _this.chartCanvasElementId = 'dashboard-status-chart';
    _this.legendItems = [];

    _this.$onInit = () => {
        _initChart();
    };

    function _initChart() {
        if (!_this.chartDataPromise) {
            return;
        }

        const _ctx = document.getElementById(_this.chartCanvasElementId);
        _this.chartDataPromise.then(_chartData => {
            var _chart = buildChart.default(
                _ctx, 'doughnut',
                _chartData.data, _chartData.titles, _chartData.backgroundColor);

            _chart._labels.forEach(e => e.options.fontColor= '#fff');

            _this.legendItems = _chartData.data.map((data, i) => {
                return {
                    key: _chartData.labels[i],
                    value: _chartData.titles[i],
                    data: _chartData.dataAbs[i]
                };
            });
        });
    }
}

