//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('assetDataMethaneFactory', ['myLog', 'googlemaps', 'utility', 
    function(myLog, googlemaps, utility) {
        var _isShown = false;

        // function _createMarker(_map, _lat, _lng, _assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId) {
        function _createMarker(_map, _lat, _lng, _assetDataMethane, slider_min, slider_max) {
                var inco_name= ''
                if (parseInt(_assetDataMethane.ppm)<= slider_min){
                    inco_name = '/img/icons/markers/marker-photos_green2.png'
                }
                if (parseInt(_assetDataMethane.ppm)>= slider_max){
                    inco_name = '/img/icons/markers/marker-photos.png'
                }

                if (parseInt(_assetDataMethane.ppm)> slider_min && parseInt(_assetDataMethane.ppm) < slider_max){
                    inco_name = '/img/icons/markers/marker-photos_yellow.png'
                }


                const _marker = new google.maps.Marker({
                map: _map,
                position: new google.maps.LatLng(_lat, _lng),
                
                icon: inco_name,
                title: "--" +_assetDataMethane.ppm,
                lat: _lat,  
                log: _lng,
                id: _assetDataMethane.id,
                // pathImg: ""+assetDataFactory.buildSrcUrl(_assetDataDetails, _assetData, _listAssetDataFileTypeByKey, _customerId, _assetId).mdUrl
            });

            //add a click listener on the marker
            _marker.addListener('click', function () {
                //Create a popup when a marker is clicked on
                //The popup is based on an external .html file
                googlemaps.setInfoWindow(_map, _marker, _assetDataMethane, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
            });

            return _marker;
        }     
        
        return {
            createMap: function(_idMap, _callback) {    
                myLog("Create a map to asset-data-methane")
                googlemaps.initGoogleMap("#"+_idMap, _m => {
                    _callback(_m);
                });    
            },
            showToggle: function() {
                myLog("asset-data-by-tags show switched")
                _isShown = !_isShown;
                return _isShown;

            },
            putMarkersOnMap(_map, _listMarkers, _listAssetDataDetails, _listAssetDataSelectd, slider_min, slider_max) {
                var _index = 0;
                _listAssetDataDetails.forEach(e => {
                    e.listMethane.forEach(m => {
                        _index++;
                        // if (_index > 10) return true;
                        //Create a new marker only if it isn't already into _listMarkers 
                        if (utility.getIndexArrayElementByField(_listMarkers, 'id', m.id) == undefined) {
                            _listMarkers.push(_createMarker(_map, m.latitude, m.longitude, m, slider_min, slider_max));    
                        }
                    })
                })
    
                //Set out the map zoom based on the markers position
                googlemaps.fitBounds(_map, _listMarkers);

                return _listMarkers;
            },
            createMarker: function(_map, _lat, _lng, _assetDataDetails) {
                return _createMarker(_map, _lat, _lng, _assetDataDetails);
            },
            removeMarkers: function(_map, _listMarkers, _listAssetDataUnselected)  {
                if (_listAssetDataUnselected) {

                    _listAssetDataUnselected.forEach(e => {
                        e.listMethane.forEach(meth => {
                            _listMarkers.some((m, _index) => {

                                if (m.id == meth.id) {
                                    m.setMap(null);
                                    m = undefined;
                                    _listMarkers.splice(_index, 1);
                                    return true;
                                }
                            })    
                        })
                    })

                    //Set out the map zoom based on the markers position
                    // googlemaps.fitBounds(_map, _listMarkers);

                    return _listMarkers;
                }
            },

            removeMarkersDynamically: function(_map,_listMarkers, listAssetDataDetails, _listAssetDataSelectd, slider_min, slider_max, showGreen){
                for(var i=0; i<_listMarkers.length; i++){
                    _listMarkers[i].setMap(null);
                } 

                for(var j=0; j<listAssetDataDetails.length; j++){
                    for(var i=0; i<listAssetDataDetails[j].listMethane.length; i++){
                        if (showGreen && listAssetDataDetails[j].listMethane[i].ppm > slider_min){
                            _listMarkers.push(_createMarker(_map, listAssetDataDetails[j].listMethane[i].latitude, listAssetDataDetails[j].listMethane[i].longitude, listAssetDataDetails[j].listMethane[i], slider_min, slider_max));  
                        }
                        if (!showGreen){
                            _listMarkers.push(_createMarker(_map, listAssetDataDetails[j].listMethane[i].latitude, listAssetDataDetails[j].listMethane[i].longitude, listAssetDataDetails[j].listMethane[i], slider_min, slider_max));  
                        }

                    }
                }
            
                return _listMarkers;

            },
            showMarkerInfo: function(_map, _assetDataDetails, _listMarkers) {
                _listMarkers.some(e => {
                    if (e.id == _assetDataDetails.id) {
                        googlemaps.setInfoWindow(_map, e, _assetDataDetails, null, 'views/asset-data/google-map_popup/compass-viewer_info.html');
                    }
                })
            },
            findExtrema: function(listAssetDataDetails) {
                var max = listAssetDataDetails[0].listMethane[0].ppm;
                var min = listAssetDataDetails[0].listMethane[0].ppm;
                for(var j=0; j<listAssetDataDetails.length; j++){
                    for(var i=0; i<listAssetDataDetails[j].listMethane.length; i++){
                        if (listAssetDataDetails[j].listMethane[i].ppm > max){
                            max = listAssetDataDetails[j].listMethane[i].ppm 
                        }
                        if (listAssetDataDetails[j].listMethane[i].ppm < min){
                            min = listAssetDataDetails[j].listMethane[i].ppm 
                        }
                        
                    }    
                }
                return [max, min]
            }          
        };
    }
]);

