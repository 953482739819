//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('signalPropagationFactory', ['myHttp', 'myLog', 'googlemaps', '$timeout', 'inventoryServices', 'inventoryFactory', 'productServices',
    function(myHttp, myLog, googlemaps, $timeout, inventoryServices, inventoryFactory, productServices)  {

        function _init(_assetId, _inspectingCompanyId, _facilityTypeKey, _success, _error) {
            myHttp.runPromise([
                myHttp.buildPromise(inventoryServices, "getInventoryGroupByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                myHttp.buildPromise(inventoryServices, "getProductGroupInventoryGroupByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                myHttp.buildPromise(inventoryFactory, "getInventoryFieldByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                myHttp.buildPromise(productServices, "getProductGroupByCompanyId", _inspectingCompanyId),
                myHttp.buildPromise(inventoryServices, "getInventoryItemByAssetId", _assetId)], _res => {

                var _ret = {
                    listInventoryGroup: _res[0],
                    listProductGroupInventoryGroup: _res[1],
                    arrayInventoryFieldByInventoryGroupId: _res[2],
                    listProductGroup: _res[3],
                    listInventoryItem: _filterInventoryItemForSignalPropagation(_res[0], _res[1], _res[2], _res[3], _res[4]),
                }

                _success(_ret)
            })
        }


        function _filterInventoryItemForSignalPropagation(_listInventoryGroup, _listProductGroupInventoryGroup, _arrayInventoryFieldByInventoryGroupId, _listProductGroup, _listInventoryItem) {
            _listInventoryItem = angular.copy(_listInventoryItem);

            //get the productgroup enabled for signal propagation
            var _productGroup = _listProductGroup.find(pg => pg.isForSignalPropagation);
            //get the inventorygroup enabled for signal propagation
            var _inventoryGroup = _listInventoryGroup.find(ig => ig.isForSignalPropagation);
            

            //filter all the inventory item with product group enabled with signal propagation
            //and inventoryFieldValueJson not empty
            _listInventoryItem = _listInventoryItem.filter(e => e.productGroupId == _productGroup.id && e.inventoryFieldValueJson);

            //filter the array with inventory group enabled with signal propagation
            var _arrayInventoryFieldByInventoryGroupId = _arrayInventoryFieldByInventoryGroupId.filter(e => e.inventoryGroupId == _inventoryGroup.id)

            var _listProductGroupInventoryGroup = _listProductGroupInventoryGroup.filter(e => e.productGroupId==_productGroup.id && e.inventoryGroupId==_inventoryGroup.id)

            _listInventoryItem.forEach((e, index) => {
                //filter all the inventory field with inventory group enabled with signal propagation
                var _inventoryFieldValueJsonParsed = JSON.parse(e.inventoryFieldValueJson).filter(e => e.inventoryGroupId==_inventoryGroup.id);                                

                e.arrayInventoryFieldByProductGroup =  inventoryFactory.createArrayInventoryFieldByProductGroupId(_productGroup.id, _arrayInventoryFieldByInventoryGroupId, _listProductGroupInventoryGroup, _inventoryFieldValueJsonParsed);
            })

            return _listInventoryItem;
        }

        function _getSignalPropagationDegreeFromInventoryItem(_inventoryItem) {
            var _degrees = 0;

            _inventoryItem.arrayInventoryFieldByProductGroup.forEach(e => {
                var _field = e.listInventoryField.find(i => i.isForSignalPropagationDegrees);
                if (_field) _degrees = Number(_field.value)
            })
            return _degrees;
        }
                
        function _createTriangleByInventoryItem(_map, _asset, _listInventoryItem) {
            _listInventoryItem.forEach(e => {
                // // Define the LatLng coordinates for the polygon's path.                        
                // var _lat = _asset.latitude;
                // var _lng = _asset.longitude;
                // var _tr = [
                //     {lat: _lat, lng: _lng},
                //     {lat: _lat+0.000245, lng: _lng+(-0.000190)},
                //     {lat: _lat+0.000245, lng: _lng+0.000190},
                // ];

                // // Construct the polygon.
                // var _polygon = new google.maps.Polygon({
                //     paths: _tr,
                //     strokeColor: '#FF0000',
                //     strokeOpacity: 0.8,
                //     strokeWeight: 2,
                //     fillColor: '#FF0000',
                //     fillOpacity: 0.35,
                //     title: 'Antenna Info',
                // });                
                // _polygon.setMap(_map);

                var _polygon = googlemaps.createTriangle(_map, _asset.latitude, _asset.longitude);
                

                _polygon.addListener("click", _event => {
                    _polygon.title = 'Antenna Info'
                    googlemaps.setInfoWindow(_map, _polygon, e.arrayInventoryFieldByProductGroup[0], null, 'views/asset/google-map_popup/signal-propagation_info.html');
                    myLog("polygon clicked", _polygon.inventoryItem)
                })                    

                googlemaps.rotatePolygon(_polygon, _getSignalPropagationDegreeFromInventoryItem(e));

            })
        }

        // function _rotatePolygon(polygon,angle) {
        //     var map = polygon.getMap();
        //     var prj = map.getProjection();

        //     if (prj) {
        //         var origin = prj.fromLatLngToPoint(polygon.getPath().getAt(0)); //rotate around first point

        //         var coords = polygon.getPath().getArray().map(function(latLng){
        //            var point = prj.fromLatLngToPoint(latLng);
        //            var rotatedLatLng =  prj.fromPointToLatLng(_rotatePoint(point,origin,angle));
        //            return {lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng()};
        //         });
        //         polygon.setPath(coords);    
        //     } else {
        //         $timeout(function() {_rotatePolygon(polygon, angle)}, 400);
        //     }

        // }

        // function _rotatePoint(point, origin, angle) {
        //     var angleRad = angle * Math.PI / 180.0;
        //     return {
        //         x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
        //         y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
        //     };
        // }    

        return {

            showSignalPropagationMap: function(_signalPropagationMapId, _asset, _inspectingCompanyId, _facilityTypeKey) {
                _init(_asset.id, _inspectingCompanyId, _facilityTypeKey, _res => {

                    googlemaps.initGoogleMap("#"+_signalPropagationMapId, _m => {

                        myLog("maps: ", _signalPropagationMapId)
                        _m.setCenter(new google.maps.LatLng(_asset.latitude, _asset.longitude));
                        _m.setZoom(19);
                            
                        _createTriangleByInventoryItem(_m, _asset, _res.listInventoryItem);
                    });    

                });

            }                    


        };
    }
]);

