//write here all the general controllers
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp

//This controller manage the popup content
.controller('PopupCtrl', function ($uibModalInstance, $scope, scope, Scopes, myLog,  $mdDialog, utility, expanseConst) {
    var $ctrl = this;

    $('#header').addClass('blur');
    $('#main').addClass('blur');

    $ctrl.expanseConst = expanseConst;
    $ctrl.innerStatus = {}
    $ctrl.data = {};
    $ctrl.oldData = {};
    $ctrl.title = scope._title;
    $ctrl.msg = scope._msg;
    $ctrl.fns = JSON.parse(scope._fns);
    $ctrl.htmlPage = scope._htmlPage;
    $ctrl.ctrlName = scope._ctrl;
    $ctrl.scope = Scopes.get(scope._ctrl); //Get the controller scope

    $ctrl.funzione = scope._funzione;

    $ctrl.fnCalled;

    if (scope._data) {
        $ctrl.data = JSON.parse(scope._data);
        $ctrl.oldData = JSON.parse(scope._data);
        console.log('.....>',$ctrl.data)
    }
    if (scope._innerStatus) {
        $ctrl.innerStatus = scope._innerStatus;
    }

    $ctrl.getArrayElementByField = function(_list, _fieldName, _value) {
        return utility.getArrayElementByField(_list, _fieldName, _value);
    }


    $ctrl.fn = function (_fn, _close, _id) {        
        var _validation = true;

        if (_fn) {
            $ctrl.fnCalled = _fn;
            $ctrl.fnId = _id;
            var _btn = utility.getArrayElementByField($ctrl.fns, 'btn_identifier', _id);

            if (_btn.btn_validation) {
                _validation = runFn(_btn.btn_validation, $ctrl);
            }
            
            if (_validation == true) {

                if (_btn.btn_confirm) {
                    var _confirm = confirmBeforeRunFn();

                    $mdDialog.show(_confirm).then(function() {
                        runFn(_fn, $ctrl);               
                    }, function() {
                    })        
                } else {
                    runFn(_fn, $ctrl);
                    callClose(_close, _btn.btn_no_check);

                }
            } else if (_validation != false) {
                $ctrl.data = _validation;
            }

    
        } else {
            callClose(_close, undefined);
        }


    }



    $ctrl.setInnerStatus = function(_key, _value) {
        $ctrl.innerStatus[_key] = _value;
    }

    $ctrl.getInnerStatus = function(_key) {
        return $ctrl.innerStatus[_key];
    }

    function _replaceAll(_str, _find, _replace) {
        if (_str.indexOf(_find) != -1) {
            return _replaceAll(_str.replace(_find, _replace), _find, _replace); 
        } else {
            return _str;
        }
    }

    $ctrl.evaluateInnerStatus = function(_condition, _identifier) {
        if (_condition) {
            var _c = _replaceAll(_condition, '$innerStatus', '$ctrl.innerStatus')

            var _evalute = eval(_c);
    
            if (_evalute) {
                $("#"+_identifier).fadeIn(500);
            } else {
                $("#"+_identifier).fadeOut(500);
            }
    
            return _evalute;    
        } else {
            return true;
        }
    }

    function runFn(_fn, _params) {
        var x = $ctrl.scope; 

        return eval("x."+_fn+"(_params)");
    }

    $ctrl.success = function(_ret) {
        $ctrl.data = _ret;
        $ctrl.oldData = _ret;

        var _btn = utility.getArrayElementByField($ctrl.fns, 'btn_identifier', $ctrl.fnId);

        callClose(_btn?_btn.btn_close:undefined, _btn?_btn.btn_no_check:undefined);
    }

    $ctrl.error = function(_ret) {
        $ctrl.errors = _ret;
    }

    $ctrl.updateData = function(_ret, _config) {

        $ctrl.data = angular.copy(_ret);
        $ctrl.oldData = angular.copy(_ret);

        if (_config) {
            if (_config.title) {
                $ctrl.title = _config.title;
            }
        }
    }

    function runFnAndUpdateData(_fn) {
        var _ret = runFn(_fn, $ctrl)
    }

    function callClose(_close, _no_check) {
        if (_close == undefined || _close) {
            $ctrl.close();
        }    
    }

    $ctrl.close = function(_no_check) {
        myLog('-----close----')

        utility.deleteJsonElement($ctrl.data, 'errors');
        utility.deleteJsonElement($ctrl.oldData, 'errors');

        // if (!angular.equals($ctrl.oldData, $ctrl.data) && _no_check ) {
        if (!angular.equals($ctrl.oldData, $ctrl.data)) {
            var _confirm = confirmBeforeClose();

            $mdDialog.show(_confirm).then(function() {

                $('#header').removeClass('blur');
                $('#main').removeClass('blur');
            
                $uibModalInstance.dismiss('fn');
                $uibModalInstance.close();        
            }, function() {
                myLog("return no")
            });
        } else {
            $('#header').removeClass('blur');
            $('#main').removeClass('blur');

            $uibModalInstance.dismiss('fn');
            $uibModalInstance.close();        
        }
    }

    function confirmBeforeRunFn() {

        var _confirm = $mdDialog.confirm()
              .title('Would you save the data?')
              .ok('Yes')
              .cancel('No');

        return _confirm
    }


    function confirmBeforeClose() {
        // Appending dialog to document.body to cover sidenav in docs app
        var confirm = $mdDialog.confirm()
              .title('Something has been changed.')
              .textContent(' Would you exit anyway?')
              .ok('Yes')
              .cancel('No');
    
        return confirm;

      };

})     

