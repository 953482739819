//Write in this controller all the code about ....entity
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.controller('directiveCtrl', 
    ['$scope', 'Scopes', 'myLog', 'myAlert', 'utility',
    function($scope, Scopes, myLog,  myAlert, utility) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem('exlite.identity'));

        var _this = this;

        //-------------------------- private area Begin -----------------------------------
        var _controllerName = "directiveCtrl";
        //-------------------------- private area End-- -----------------------------------

        //-------------------------- _this area Begin -----------------------------------
        // _this.isAccordionBodyShown = true;
        //-------------------------- _this area End-- -----------------------------------
        $scope.isbodyvisible = true;  //set accordion body visibility

        //Write here the code that you need when the controller is created
        //The $scope will be put and get from store.
        //In this way it will be possible to cache some information
        //and it will be possible to use some function in others controllers
        _this.init = function() {
            Scopes.store(_controllerName, $scope);
        }


        _this.runDropdownMenuOption = function(_idContainer, _ctrl, _fn, _params) {
            $("#"+_idContainer+" .context-menu").removeClass("show");                    

            _ctrl[_fn](_params)
        }

        _this.updateDropdownMenuEvents = function(_id) {
            // utility.callFunctionEveryTime('$(".context-menu").get(0)', 200, function() {
                $(' .dropdown-menu a.dropdown-toggle').off('click');

                $(' .dropdown-menu a.dropdown-toggle').on('click', function(e) {
                    if (!$(this).next().hasClass('show')) {
                        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
                    }
                    var $subMenu = $(this).next(".dropdown-menu");
                    $subMenu.toggleClass('show');
                    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                        $('.dropdown-submenu .show').removeClass("show");
                    });
                    return false;
                });      
            // });

        }

        _this.accordionToggle = function() {
            $scope.isbodyvisible = !$scope.isbodyvisible;
        }
    }
]);