//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

// Scopes factory allows for sharing variables between controllers. 
expanseLiteApp.factory('dynamicCADFactory', ['$timeout', 'myHttp', 'myLog',  'utility', 'myAlert','assetServices', 'dynamicCADServices', 'inventoryServices', 'expanseConst', 'customerServices', 'ngTableParams',
    function($timeout, myHttp, myLog, utility, myAlert,assetServices, dynamicCADServices, inventoryServices, expanseConst, customerServices, ngTableParams)  {
        var _nodeTypes = {model:'MODEL', anchorGroup:'AG'}
        var _object3dTypes = {tower:'tower', headFrame:'head-frame', antenna:'antenna', compassBearing: 'compass-bearing'}

        function _buildS3Path(_dCadObject) {
            var _prefix = 'lite/dynamic_cad/'
            var _postfix = {zip: '.zip',  png: '.png'};
            return {
                customer3DModel: function() {
                    var _asset = _dCadObject.data.asset;
                    var _model3D = _dCadObject.data.model3D
                    var _sessionId = _model3D.lastUpdateTime?_model3D.lastUpdateTime:_model3D.createdDate;
                    //Because the _model3D.lastUpdateTime and _model3D.createdDate are Date field
                    //the milliseconds don't being saved on the db
                    //So, I'm going to remove them
                    var _s = String(_sessionId)
                    var _lastDigits = Number(_s.substring(_s.length-3, _s.length));
                    var _firstDigits = Number(_s.substring(0, _s.length-3));
                    _sessionId = (_lastDigits>499?_firstDigits+1:_firstDigits)*1000;

                    var _ret =  'customers/customer_' + _dCadObject.data.customer.publicId + '/asset_' + _asset.publicId + '/model_3d_' ;
                    _ret += _dCadObject.data.isMain3DModel?_asset.model3dId:_asset.model3dAfterId;

                    return _prefix + _ret + '_' + _sessionId + _postfix.zip;
                },
                antenna: function(_antennaCode) {
                    var _ret = {};
                    
                    _ret.object = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/antenna/' + _antennaCode + _postfix.zip;
                    _ret.thumb = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/antenna/' + _antennaCode + _postfix.png;

                    return _ret;
                },
                headFrame: function(_headFrameCode) {
                    var _ret = {};
                    
                    _ret.object = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/head-frame/' + _headFrameCode + _postfix.zip;
                    _ret.thumb = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/head-frame/' + _headFrameCode + _postfix.png;

                    return _ret;
                },
                tower: function(_towerCode) {
                    var _ret = {};
                    
                    _ret.object = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/tower/' + _towerCode + _postfix.zip;
                    _ret.thumb = _prefix + 'inspecting_companies/company_' + _dCadObject.data.inspectingCompany.publicId + '/tower/' + _towerCode + _postfix.png;

                    return _ret;
                }
            }
        }

        function _getDataSourceInfo(_dCadObject, _success) {
            assetServices.getAssetTypes(_res => {
                var _ret = {};
                var _data = _dCadObject.data;
                var _asset = _dCadObject.data.asset;

                _ret.listAssetTypeByKey = utility.getArrayByField(_res, 'id');
                var _facilityTypeKey = _ret.listAssetTypeByKey[_asset.facilityTypeId].key;
                var _inspectingCompanyId = _data.inspectingCompany.id;

                var _https = [
                    myHttp.buildPromise(dynamicCADServices, "getObject3DByCompanyAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(dynamicCADServices, "getObjects3DTypeByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(dynamicCADServices, "getObjects3DHierarchyByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, _facilityTypeKey),
                    myHttp.buildPromise(inventoryServices, "getInventoryItemByAssetId", _asset.id),       
                    myHttp.buildPromise(inventoryServices, "getInventoryFieldByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, 'wind-turbines'),       
                    myHttp.buildPromise(inventoryServices, "getInventoryGroupByCompanyIdAndFacilityTypeKey", _inspectingCompanyId, 'wind-turbines'),                           
                    myHttp.buildPromise(customerServices, "getCustomer", _data.asset.companyId),                           
                ];

                if (_asset.model3dId && _data.isMain3DModel) {
                    _https.push(myHttp.buildPromise(dynamicCADServices, "getModel3DObjectByModel3DId", _asset.model3dId))
                    _https.push(myHttp.buildPromise(dynamicCADServices, "getModel3DById", _asset.model3dId))
                } else if (_asset.model3dAfterId && !_ret.isMain3DModel) {
                    _https.push(myHttp.buildPromise(dynamicCADServices, "getModel3DObjectByModel3DId", _asset.model3dAfterId))
                    _https.push(myHttp.buildPromise(dynamicCADServices, "getModel3DById", _asset.model3dAfterId))
                }

                myHttp.runPromise(_https, _res => {
    
                    _ret.listObject3D = _res[0];
                    _ret.listObject3DById = utility.getArrayByField(_res[0], 'id');
                    _ret.listObject3DType = _res[1];
                    _ret.listObject3DTypeById = utility.getArrayByField(_res[1], 'id');
                    _ret.listObject3DHierarchy = _res[2];
                    _ret.listInventoryItem = _res[3];
                    _ret.listInventoryFieldById = utility.getArrayByField(_res[4], 'id');
                    _ret.listInventoryGroupById = utility.getArrayByField(_res[5], 'id');
                    _ret.customer = _res[6];

                    if ((_asset.model3dId && _data.isMain3DModel) || (_asset.model3dAfterId && !_data.isMain3DModel)) {
                        _ret.listModel3DObjectByObject3dId = utility.getArrayByField(_res[7], 'object3dId');
                        _ret.model3D = _res[8];
                    }

                    _success(_ret);
                })
    
            })

        }

        /**
         * Initialize the 3D renderer
         * @param {*} _canvasId 
         * @param {*} additionalProperties 
         * @returns 
         */
        function _initRenderer(_canvasId, additionalProperties) {

            var props = (typeof additionalProperties !== 'undefined' && additionalProperties) ? additionalProperties : {};
            var renderer = new THREE.WebGLRenderer(props);
            renderer.shadowMap.enabled = true;
            renderer.shadowMapSoft = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        

            var _canvas = $("#"+_canvasId).get(0);
            renderer.setClearColor(new THREE.Color(0x000000));
            // renderer.setSize($("#"+_canvasId).parent().innerWidth(), $("#"+_canvasId).parent().innerHeight());
            renderer.setSize(_canvas.clientWidth, _canvas.clientHeight);
            renderer.shadowMap.enabled = true;
            document.getElementById(_canvasId).appendChild(renderer.domElement);

            return renderer;
        }

        function _initLabelRenderer(_canvasId) {
            var labelRenderer =  new THREE.CSS2DRenderer();

            var _canvas = $("#"+_canvasId).get(0);
            labelRenderer.setSize(_canvas.clientWidth, _canvas.clientHeight)
            labelRenderer.domElement.style.position = 'absolute'
            labelRenderer.domElement.style.top = '0px'
            // labelRenderer.domElement.style.pointerEvents = 'none'
            document.getElementById(_canvasId).appendChild(labelRenderer.domElement)

            return labelRenderer;
        }
        
        
        /**
         * Initialize a simple camera and point it at the center of a scene
         * 
         * @param {THREE.Vector3} [initialPosition]
         */
        function _initCamera(_canvasId, initialPosition) {
            var position = (initialPosition !== undefined) ? initialPosition : new THREE.Vector3(-30, 40, 30);
        
            var _canvas = $("#"+_canvasId).get(0);
            // var camera = new THREE.PerspectiveCamera(45, $("#"+_canvasId).parent().innerWidth()/$("#"+_canvasId).parent().innerHeight(), 0.1, 1000);
            var camera = new THREE.PerspectiveCamera(45, _canvas.clientWidth/_canvas.clientHeight, 0.1, 1000);
            camera.position.copy(position);
            camera.lookAt(new THREE.Vector3(0, 0, 0));
        
            return camera;
        }


        /**
         * Render the scene
         * 
         * @param {*} renderer 
         * @param {*} scene 
         * @param {*} camera 
         */
        function _render(_dCadObject) {
            // render using requestAnimationFrame
            _dCadObject.renderId =  requestAnimationFrame(function() {
                _render(_dCadObject)
            });

            // if (!_dCadObject.data.isRenderingStopped) {
                _dCadObject.renderer.render(_dCadObject.scene, _dCadObject.camera);
                _dCadObject.labelRenderer.render(_dCadObject.scene, _dCadObject.camera)
            // }
        }

        function _loadJSONFromZipFromRepository(_path, _canvasId, _success, _error) {
            //Create the request to get the file from path
            var request = new XMLHttpRequest();
            request.open('GET', _path, true);
            request.responseType = 'blob';

            request.onerror = function(e) {
                _error(e);
            }

            request.onloadstart = function() {
                $("#"+_canvasId+"_progressbar_container").show();
            }

            //Manage the progress
            request.onprogress = function(e) {
                var _percentage = (e.loaded/e.total)*100;
                $("#"+_canvasId+"_progressbar").width(_percentage+"%");

            }
            //Manage the file loaded            
            request.onload = function() {
                $("#"+_canvasId+"_progressbar_container").hide();

                var reader = new FileReader();

                //Read the file
                reader.onload =  function(e){
                    //Check if the return of loading is at least 5K otherwise 
                    //I'll consider the loading in error
                    if (e.loaded>5*1024) {
                        //Unzip the file and get the content
                        JSZip.loadAsync(e.target.result).then(function (zip) {
                            //get just the first file
                            var _fileName = Object.values(zip.files)[0].name;
                            zip.file(_fileName).async("string").then(function (data) {
                                _success(data)
                            });
                        });                        
                    } else {
                        _error(null);
                    }
                };
                reader.readAsBinaryString(request.response);
            };
            request.send();            
        }

        /**
         * Load an object from the repository 
         * @param {*} _success 
         * @param {*} _error 
         */
         function _loadObject(_path, _canvasId, _success, _error) {
            _loadJSONFromZipFromRepository(_path, _canvasId, _res => {
                var _json = _res;
                var _loadedObjectAsJson = JSON.parse(_json);
                var _loader = new THREE.ObjectLoader();
                var _obj = _loader.parse(_loadedObjectAsJson);
                
                _success?_success(_obj):undefined;

            }, _err => {
                console.log(_err);
                _error?_error(_err):undefined;
            })
        }


        /**
         * Load a scene from the repository and instantiates it
         * @param {*} _success 
         * @param {*} _error 
         */
        function _loadScene(_path, _canvasId, _success, _error) {

            _loadJSONFromZipFromRepository(_path, _canvasId, _res => {

                var _json = _res;
                var _scene;

                if (_json) {
                    var _loadedSceneAsJson = JSON.parse(_json);
                    var _loader = new THREE.ObjectLoader();
                    _scene = _loader.parse(_loadedSceneAsJson);
                }

                _success?_success(_scene):undefined;
            }, _err => {
                console.log(_err);
                _error?_error(_err):undefined;
            })
        }

        /**
         * Add some ligths to the scene
         * 
         * @param {*} _scene 
         */
        function _addLSceneLight(_scene) {
            //I'm going to remove the ambient and spot lights
            //if any of them exists
            _scene.children.forEach(e => {
                if (e.type == 'AmbientLight' || e.type == 'DirectionalLight') {
                    _scene.remove(e)
                }
            })

            // add subtle ambient lighting
            var _ambientLight = new THREE.AmbientLight(0x747474);
            _ambientLight.name = 'ambientLight';
            _scene.add(_ambientLight);
        
            // add spotlight for the shadows
            var _spotLight = new THREE.DirectionalLight(0x747474);
            _spotLight.position.set(-10, 35, 0);
            _spotLight.name = 'spotLight';
            _scene.add(_spotLight);
        }

        /**
         * Create an Orbit Controll to move the scene
         * 
         * @param {*} _camera 
         * @param {*} _renderer 
         */
        function _createOrbitControll(_camera, _renderer) {
            const _orbitControls = new THREE.OrbitControls( _camera, _renderer.domElement );
            _orbitControls.rotateSpeed =0.5;
            _orbitControls.zoomSpeed = 0.5;
            _orbitControls.panSpeed = 0.5;
            _orbitControls.enabled = true;

            return _orbitControls;
        }

        /**
         * Create the interaction object
         * With this object it is possible to add events to every object in the scene
         * 
         * @param {*} _interaction 
         * @param {*} _renderer 
         * @param {*} _scene 
         * @param {*} _camera 
         * @returns 
         */
        function _createInteractionObject(_interaction, _renderer, _scene, _camera) {
            //If the _interaction object was already created
            //I'll destroy it with all its event listener
            if (_interaction) {
                try {
                    _interaction.destroy();
                } catch(e) {};
            }
        
            return  new THREE.Interaction(_renderer, _scene, _camera);
        }


        function _getObjectInfo(_dCadObject, _ev, _node) {
            var _info;
            if (_node) {
                  _info = _getInfoByObjectName(_node.name, _dCadObject);
            }

            _dCadObject.data.objectSelectedInfo = _info;
            _dCadObject.scope.$apply();
            _ev.stopPropagation();
        }

        function _buildContextMenu(_dCadObject, _ev, _node) {
            _dCadObject.contextMenu.build(_node);
            _dCadObject.contextMenu.show(_ev.offsetX, _ev.offsetY);
            _ev.stopPropagation();
        }


        /**
         * Delete an object from the scene
         * If the object is the tower, because it is the root object
         * I'm going to delete all the scene's objects but the lights
         * @param {*} __dCadObject 
         * @param {*} __node 
         * @returns 
         */
        function _deleteObject(__dCadObject, __node) {
            var _dCadObject = __dCadObject;
            var _node = __node;

            return  {
                exec: function() {
                    //hide the context menu
                    _dCadObject.contextMenu.hide();                    

                    if (_parseSceneObjectName(_node.name).object3dType == _object3dTypes.tower) {
                        //if the object to delete is the tower,
                        //I'm going to delete every object from the scene root, but the lights
                        _dCadObject.scene.children.forEach(e => {
                            if (e.type != 'AmbientLight' && e.type != 'DirectionalLight') {
                                _dCadObject.scene.remove(e)
                            }
                        })
                        
                    } else {
                        //delete the object "_node" from the scene
                        _node.parent.remove(_node);
                    }

                    _updateListPickableObjects(_dCadObject);
                }
            }
        }

        function _attachAntenna(_dCadObject, _node, _params) {
            _loadObject(expanseConst.cfExpanseUrl + _buildS3Path(_dCadObject).antenna(_params.antenna.code).object, _dCadObject.data.canvasId, _res => {
                _res.name += '|' + _parseSceneObjectName(_node.name).object3d;
                _node.add(_res)
                _updateListPickableObjects(_dCadObject);

                _dCadObject.data.isRenderingStopped = false;  
                $timeout(function() {_dCadObject.data.isRenderingStopped = true; }, 200);
            })   
        }

        function _replaceAntenna(_dCadObject, _node, _params) {            
            var _agNode = _getNodeHierarchically(_node, [_nodeTypes.anchorGroup])
            _deleteObject(_dCadObject, _node).exec();
            _attachAntenna(_dCadObject, _agNode, _params);
        }

        function _attachHeadFrame(_dCadObject, _node, _params) {
            _loadObject(expanseConst.cfExpanseUrl + _buildS3Path(_dCadObject).headFrame(_params.headFrame.code).object, _dCadObject.data.canvasId, _res => {
                _res.name += '|' + _parseSceneObjectName(_node.name).object3d;
                _node.add(_res)
                _updateListPickableObjects(_dCadObject);

                _dCadObject.data.isRenderingStopped = false;  
                $timeout(function() {_dCadObject.data.isRenderingStopped = true; }, 200);
            })   
        }

        function _replaceHeadFrame(_dCadObject, _node, _params) {            
            var _agNode = _getNodeHierarchically(_node.parent, [_nodeTypes.anchorGroup])
            _deleteObject(_dCadObject, _node).exec();
            _attachHeadFrame(_dCadObject, _agNode, _params);
        }

        function _attachTower(_dCadObject, _params) {
            _loadObject(expanseConst.cfExpanseUrl + _buildS3Path(_dCadObject).tower(_params.tower.code).object, _dCadObject.data.canvasId, _res => {                    
                // _res.name += '|' + (new Date()).getTime();
                _dCadObject.scene.add(_res);
                _updateListPickableObjects(_dCadObject);

                _getCompassBearing(_dCadObject);
                _dCadObject.data.isRenderingStopped = false;  
                $timeout(function() {_dCadObject.data.isRenderingStopped = true; }, 200);
            })   
        }

        function _replaceTower(_dCadObject, _node, _params) {            
            _deleteObject(_dCadObject, _node).exec();
            _attachTower(_dCadObject, _params);
        }

        /**
         * Load and attach, in the right place,
         * an object (like, tower, head-frame, antenna)
         * @param {*} __dCadObject 
         * @param {*} __node 
         * @returns 
         */
        function _attachObject(__dCadObject, __node) {
            var _dCadObject = __dCadObject;
            var _node = __node;

            return  {
                exec: function(_params) {
                    //hide the context menu
                    _dCadObject.contextMenu.hide(); 
                    _dCadObject.data.is3DObjectSelectableAvailable = false;                   

                    if (_node) {
                        //parse the node's name to understand the node type
                        var _nodeInfo = _parseSceneObjectName(_node.name);

                        //if the node type is an anchor-group I have to attach an antenna
                        if (_nodeInfo.nodeType == _nodeTypes.anchorGroup) {
                            // _attachAntenna(_dCadObject, _node, _params);
                            switch (_nodeInfo.object3dType) {
                                case _object3dTypes.headFrame:
                                    _attachAntenna(_dCadObject, _node, _params);                                
                                    break;
                                case _object3dTypes.tower:
                                    _attachHeadFrame(_dCadObject, _node, _params);                                
                                    break;
                            }    
                        } else { 
                            switch (_nodeInfo.object3dType) {
                                case _object3dTypes.antenna:
                                    _replaceAntenna(_dCadObject, _node, _params);                                
                                    break;
                                case _object3dTypes.headFrame:
                                    _replaceHeadFrame(_dCadObject, _node, _params);                                
                                    break;
                                case _object3dTypes.tower:
                                    _replaceTower(_dCadObject, _node, _params);                                
                                    break;
                            }    
                        } 
                    } else {
                        _attachTower(_dCadObject,  _params);                                
                    }
                }
            }
        }



        /**
         * Get a single object fron the scene 
         * with a specific node-type and object-3d-type
         * @param {*} _scene 
         * @param {*} _nodeType 
         * @param {*} _object3DType 
         * @returns 
         */
        function _getObjectInTheScene(_scene, _nodeType, _object3DType) {
            return _getListPickableObjectsFromScene(_scene).filter(e => {
                var _parse = _parseSceneObjectName(e.name);
                if (_parse.nodeType == _nodeType && _parse.object3dType == _object3DType) {
                    return true;
                }
            })[0];
        }

        /**
         * Get the antenna's list, based on the head-frame and tower selected into the scene
         * 
         * @param {*} _dCadObject 
         * @returns 
         */
        function _getListAntennaByHeadFrame(_dCadObject) {
            var _ret = [];
            var _headFrameInScene = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.model, _object3dTypes.headFrame);
            var _towerInScene = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.model, _object3dTypes.tower);

            if (_headFrameInScene && _towerInScene) {
                var _tower = _dCadObject.data.dataSource.towers.filter(t => t.code == _parseSceneObjectName(_towerInScene.name).object3d)[0];
                if (_tower) {
                    var _headframe = _tower.headframes.filter(hf => hf.code == _parseSceneObjectName(_headFrameInScene.name).object3d)[0];
                    if (_headframe) {
                        $.extend(_ret, _ret, _headframe.antennas);
                    }
                }
            }

            return _ret;
        }

        /**
         * Get the head-frame's list, based on the tower selected into the scene
         * 
         * @param {*} _dCadObject 
         * @returns 
         */
         function _getListHeadFrameByTower(_dCadObject) {
            var _ret = [];
            var _towerInScene = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.model, _object3dTypes.tower);

            if (_towerInScene) {
                var _tower = _dCadObject.data.dataSource.towers.filter(t => t.code == _parseSceneObjectName(_towerInScene.name).object3d)[0];
                if (_tower) {
                    $.extend(_ret, _ret, _tower.headframes);
                }
            }

            return _ret;
        }        

        /**
         * Get the tower's list
         * 
         * @param {*} _dCadObject 
         * @returns 
         */
        function _getListTower(_dCadObject) {
            return _dCadObject.data.dataSource.towers;
        }        


        function _showObject3DSelectable(__dCadObject, __node, __obj3DType, __type) {
            var _dCadObject = __dCadObject;
            var _node = __node; 
            var _obj3DType = __obj3DType;
            var _type = __type;

            return  {
                exec: function() {
                    var _list = [];
                    var _fn = _attachObject(_dCadObject, _node);

                    switch (_obj3DType) {
                        case _object3dTypes.antenna:
                            _getListAntennaByHeadFrame(_dCadObject).forEach(e => _list.push({
                                item: e, 
                                image: expanseConst.cfExpanseUrl + _buildS3Path(__dCadObject).antenna(e.code).thumb, 
                                fn: _fn, 
                                params: {antenna: e, type: _type}
                            }))                        
                            break;
                        case _object3dTypes.headFrame:
                            _getListHeadFrameByTower(_dCadObject).forEach(e => _list.push({
                                item: e, 
                                image: expanseConst.cfExpanseUrl + _buildS3Path(__dCadObject).headFrame(e.code).thumb, 
                                fn: _fn, 
                                params: {headFrame: e, type: _type}
                            }))                        
                            break;
                        case _object3dTypes.tower:
                            _getListTower(_dCadObject).forEach(e => _list.push({
                                item: e, 
                                image: expanseConst.cfExpanseUrl + _buildS3Path(__dCadObject).tower(e.code).thumb, 
                                fn: _fn, 
                                params: {tower: e, type: _type}
                            }))                        
                            break;

                    }

                    _dCadObject.data.list3DObjectSelectable = ngTableParams.default(_list);
                    _dCadObject.data.is3DObjectSelectableAvailable = true;
                    _dCadObject.data.list3DObjectSelectableHead = 'List of ' + _obj3DType + 's';
                    _dCadObject.contextMenu.hide();
                }
            }
        }

        function _moveCompassToObjectSeleced(__dCadObject, __node) {
            var _dCadObject = __dCadObject;
            var _node = __node;

            return {
                exec: function() {
                    _dCadObject.contextMenu.hide();

                    // var _cbOfNode = _dCadObject.data.pickableObjects.filter(e => e.name == 'compass-bearing-of-node')[0];
                    // var _cb = _dCadObject.data.pickableObjects.filter(e => e.name == 'compass-bearing')[0];
                    var _cbOfNode = _dCadObject.data.compassBearingOfNode;
                    var _cb = _dCadObject.data.compassBearing;
                    var _nodeInfo = _parseSceneObjectName(_node.name);

                    if (_nodeInfo.nodeType == _nodeTypes.model) {
                        if (_nodeInfo.object3dType == _object3dTypes.antenna || _nodeInfo.object3dType == _object3dTypes.headFrame) {
                            var _agNode = _getNodeHierarchically(_node, [_nodeTypes.anchorGroup]);
                            _agNode.add(_cb);
                            _node.add(_cbOfNode);        
                        } else {
                            var _agCb = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.anchorGroup, _object3dTypes.compassBearing );
                            _agCb.add(_cb);
                            _node.add(_cbOfNode)
                        }

                    } 

                    
                }
            }
        }


        function _getListChanges(_now, _after) {
            var _ret = {};

            if (_after) {
                _ret.listObjectsIntoSceneNow =  _getListObjectsIntoScene(_now.scene, _now.data.listObject3D)
                _ret.listObjectsIntoSceneAfter = _getListObjectsIntoScene(_after.scene, _after.data.listObject3D)
    
                var _listChanges = [];
    
                _ret.listObjectsIntoSceneNow.forEach(n => {
                    if (n.object3dType == 'antenna') {
                        var _found = false;
                        _ret.listObjectsIntoSceneAfter.find(a => {
                            if (n.sameObject3dKey == a.sameObject3dKey) {
                                if (n.object3d != a.object3d) {
                                    _listChanges.push({
                                        note: 'Anthenna changed',
                                        from: n,
                                        to: a
                                    })
                                } 
                                _found = true;
                                return true;
                            }
                        })
    
                        if (!_found) {
                            _listChanges.push({
                                note: 'Anthenna changed',
                                from: n,
                                to: {object3d: 'nothing'}
                            })
                        }
                    }
                })
    
                _ret.listObjectsIntoSceneAfter.forEach(a => {
                    if (a.object3dType == 'antenna') {
                        var _found = false;
                        _ret.listObjectsIntoSceneNow.find(n => {
                            if (n.sameObject3dKey == a.sameObject3dKey) {
                                _found = true;
                                return true;
                            }
                        })
    
                        if (!_found) {
                            _listChanges.push({
                                note: 'Anthenna changed',
                                from: {object3d: 'nothing'},
                                to: a
                            })
                        }
                    }
                })
    
    
                _ret.listChanges = _listChanges;    
            }

            return _ret;
        }        

        function _contextMenu(__dCadObject) {
            var _dCadObject = __dCadObject;
            var _objs;

            return {
                build: function(_node) {
                    // {optionName:'Delete Antenna', fn:_deleteObject(_dCadObject, _node)}                    
                    var _list = [];   

                    //Check if some object into the scene has been clicked with the right mouse's button
                    if (_node) {
                        var _parse = _parseSceneObjectName(_node.name);

                        //If an anchor-group has been clicked, that's mean I can attach an antenna
                        if (_parse.nodeType == _nodeTypes.anchorGroup && _parse.object3dType == _object3dTypes.headFrame) {
                            _list.push({optionName:'Select Antenna', fn:_showObject3DSelectable(_dCadObject, _node, _object3dTypes.antenna)})
                        } else {
                            switch (_parse.object3dType) {
                                case _object3dTypes.antenna:
                                    _list.push({optionName:'Delete Antenna', fn:_deleteObject(_dCadObject, _node)})
                                    _list.push({optionName:'Change Antenna', fn:_showObject3DSelectable(_dCadObject, _node, _object3dTypes.antenna)})
                                    _list.push({optionName:'Move Compass Here', fn:_moveCompassToObjectSeleced(_dCadObject, _node)})
                                    break;
                                case _object3dTypes.headFrame:
                                    _list.push({optionName:'Delete Head-Frame', fn:_deleteObject(_dCadObject, _node)})
                                    _list.push({optionName:'Change Head-Frame', fn:_showObject3DSelectable(_dCadObject, _node, _object3dTypes.headFrame)})
                                    _list.push({optionName:'Move Compass Here', fn:_moveCompassToObjectSeleced(_dCadObject, _node)})
                                    break;
                                case _object3dTypes.tower:
                                    _list.push({optionName:'Delete Tower', fn:_deleteObject(_dCadObject, _node)})
                                    _list.push({optionName:'Change Tower', fn:_showObject3DSelectable(_dCadObject, _node, _object3dTypes.tower, 'replace')})
                                    
                                    var _headFrameInScene = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.model, _object3dTypes.headFrame);
                                    if (!_headFrameInScene) {
                                        var _agTower = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.anchorGroup, _object3dTypes.tower);
                                        _list.push({optionName:'Select Head-Frame', fn:_showObject3DSelectable(_dCadObject, _agTower, _object3dTypes.headFrame, 'attach')})
                                    }
                                    _list.push({optionName:'Move Compass Here', fn:_moveCompassToObjectSeleced(_dCadObject, _node)})
                                    break;
                            }
                        }
                    } else {
                        var _towerInScene = _getObjectInTheScene(_dCadObject.scene, _nodeTypes.model, _object3dTypes.tower);
                        if (!_towerInScene) {
                            _list.push({optionName:'Select Tower', fn:_showObject3DSelectable(_dCadObject, _node, _object3dTypes.tower, 'attach')})
                        }

                    }

                    _dCadObject.dropdownMenuList = _list;

                    _dCadObject.scope.$apply();        

                },
                show: function(_x, _y) {
                    var _info = {};
                    _info.htmlContainerId = _dCadObject.data.canvasId;
                    _info.dynamicCADDropdownMenu = _info.htmlContainerId + "_dynamicCADDropdownMenu";

                    $("#" + _info.dynamicCADDropdownMenu).css("top", _y );
                    $("#" + _info.dynamicCADDropdownMenu).css("left", _x);
                    $("#" + _info.dynamicCADDropdownMenu + " .context-menu .show").removeClass("show")
                    $("#" + _info.dynamicCADDropdownMenu + " .context-menu").addClass("show");                    
                },
                hide: function() {
                    var _info = {};
                    _info.htmlContainerId = _dCadObject.data.canvasId;
                    _info.dynamicCADDropdownMenu = _info.htmlContainerId + "_dynamicCADDropdownMenu";

                    $("#" + _info.dynamicCADDropdownMenu + " .context-menu").removeClass("show");                    
                }
            }
        };

        /**
         * Return the pickable objects list
         * This list is used to look for the scene's objects
         * to interact with
         * @param {*} _scene 
         * @returns 
         */
        function _getListPickableObjectsFromScene(_scene) {
            var _pickableObjects = [];
            _scene.traverse( function( _obj ) {
                if ( _obj.isGroup || _obj.isObject3D ) {
                    var _nodeInfo = _parseSceneObjectName(_obj.name);
                    if (_nodeInfo.nodeType == _nodeTypes.anchorGroup || _nodeInfo.nodeType == _nodeTypes.model || 
                        _obj.name == 'compass-bearing-of-node' || _obj.name == 'compass-bearing') {
                        _pickableObjects.push(_obj);
                    }
    
                };			
            });
                                
            return _pickableObjects;
        }

        /**
         * Update the pickable objects list
         * used from the rayCaster to intersect the scene's objects
         * @param {*} _dCadObject 
         */
        function _updateListPickableObjects(_dCadObject) {
            _dCadObject.data.pickableObjects = _getListPickableObjectsFromScene(_dCadObject.scene);
            utility.scopeApply(_dCadObject.scope);
        }
        
        /**
         * Get the first node of the scene, with a specific type (ex. MODEL, AG),
         * that contains the _obj.
         * In this way I want to get the root object (like, Anthenna, Head-frame, Tower)
         * @param {*} _obj 
         * @param {*} _type 
         * @returns 
         */
        function _getNodeHierarchically(_obj, _types) {
            if (_obj) {
                var _found = false;
                _types.find(t => {
                    if (_obj.name.startsWith(t+'|')) {    
                        _found = true;
                        return true;
                    }
                })

                if (_found) {
                        return _obj;
                } else {
                    return _getNodeHierarchically(_obj.parent, _types); 
                }    
            } else {
                return undefined;
            }
        }

        /**
         * Add a single event to the scene's Objects
         * 
         * @param {*} __eventType 
         * @param {*} _target 
         * @param {*} _dCadObject   
         * @returns 
         */
        function _addEventToSceneObjects(__eventType, _target,  _dCadObject) {
            var _eventType = __eventType;
            var _camera = _dCadObject.camera;
            var _raycaster = _dCadObject.raycaster;
            var _startPoint = new THREE.Vector2();
            var _endPoint = new THREE.Vector2();

            return function(_callback) {

                _target.addEventListener( 'mousedown', function(_event) {
                    _startPoint.x = ( _event.layerX / _target.clientWidth ) * 2 - 1;
                    _startPoint.y = - ( _event.layerY / _target.clientHeight ) * 2 + 1;
                    _dCadObject.data.isRenderingStopped = false;
                });

                _target.addEventListener( 'mouseup', function(_event) {
                    _endPoint.x = ( _event.layerX / _target.clientWidth ) * 2 - 1;
                    _endPoint.y = - ( _event.layerY / _target.clientHeight ) * 2 + 1;
                    _dCadObject.data.isRenderingStopped = true;
                });
                

                _target.addEventListener( _eventType, function(_event) {
                    var _pickableObjects = _dCadObject.data.pickableObjects;
                    var _node;
                    _event.isMouseMoving = !_startPoint.equals(_endPoint);

                    if (_pickableObjects && _pickableObjects.length>0 && !_event.isMouseMoving) {
                        var _pointer = new THREE.Vector2();
    
                        _pointer.x = ( _event.layerX / _target.clientWidth ) * 2 - 1;
                        _pointer.y = - ( _event.layerY / _target.clientHeight ) * 2 + 1;

                        if (_startPoint.x == _pointer.x && _startPoint.y == _pointer.y) {
                            var _intersects = [];
                            _raycaster.setFromCamera( _pointer, _camera );
            
                            _intersects = _raycaster.intersectObjects(_pickableObjects, true);
            
                            if (_intersects.length>0) {
                                _node = _getNodeHierarchically(_intersects[0].object, Object.values(_nodeTypes));        
                            }    
                        }
                    }
                    _callback(_event, _node)    
                } );    
            }
        }

        function _initMeasurementTool(_dCadObject) {
            // var pickableObjects = _dCadObject.data.pickableObjects;
            var ctrlDown = _dCadObject.data.ctrlDown;
            var lineId = 0;
            var line = THREE.Line;
            var drawingLine = false;
            var measurementLabels = [];
            var raycaster = _dCadObject.raycaster;
            var intersects = [];
            var mouse = new THREE.Vector2()
            var _canvas = $("#"+_dCadObject.data.canvasId).get(0);
            var renderer = _dCadObject.labelRenderer;
            var scene = _dCadObject.scene;
            var camera = _dCadObject.camera;
            var ps;
            var geometry1;
        
            window.addEventListener('keydown', function (event) {
                if (event.key === 'Control') {
                    ctrlDown = true
                    renderer.domElement.style.cursor = 'crosshair'
                    _dCadObject.data.isRenderingStopped = false;
                }
            })
            
            window.addEventListener('keyup', function (event) {
                if (event.key === 'Control') {
                    ctrlDown = false
                    renderer.domElement.style.cursor = 'default'

                    $timeout(function() {_dCadObject.data.isRenderingStopped = true;}, 200);

                    if (drawingLine) {
                        //delete the last line because it wasn't committed
                        scene.remove(line)
                        scene.remove(measurementLabels[lineId])
                        drawingLine = false
                    }
                }
            })
            
            var ps;
            var geometry1;
            
            // renderer.domElement.addEventListener('pointerdown', onClick, false)
            _canvas.addEventListener('pointerdown', onClick, false)
            function onClick() {
                if (ctrlDown) {
                    var pickableObjects = _dCadObject.data.pickableObjects;

                    raycaster.setFromCamera(mouse, camera)
                    intersects = raycaster.intersectObjects(pickableObjects, true)
                    if (intersects.length > 0) {
                        if (!drawingLine) {
                            //start the line
                            const points = []
                            points.push(intersects[0].point)
                            points.push(intersects[0].point.clone())
                            const geometry = new THREE.BufferGeometry().setFromPoints(points)
                            
        
                            line = new THREE.LineSegments(
                                geometry,
                                new THREE.LineBasicMaterial({
                                    color: 0xffffff,
                                })
                            )
                           //line.frustumCulled = false
                            scene.add(line)
                            
                            
                            ps = [points[0].x, points[0].y, points[0].z, points[1].x, points[1].y, points[1].z]	                
                            geometry1 = new THREE.LineGeometry();
                            geometry1.setPositions( ps );
                            const material = new THREE.LineMaterial( { color: 0x00ff00, linewidth: 2 } );
                            material.resolution.set( window.innerWidth, window.innerHeight );
                        
                            const lines = new THREE.Line2( geometry1, material );
                            scene.add( lines );
            
                                
                            const measurementDiv = document.createElement('div') 
                            measurementDiv.className = 'dynamic-cad-measurement-label'
                            measurementDiv.innerText = '0.0m'
                            measurementDiv.addEventListener( 'pointerdown', () => { console.log( 'pointerdown ' ) } );
                            
                            const measurementLabel = new THREE.CSS2DObject(measurementDiv)
                            // measurementLabel.name = 'measurementLabel';
                            // measurementLabel.element.style.color = 'white';
                            // measurementLabel.element.style.background = '#00000060';
                            // measurementLabel.element.style.fontSize = '30px';
                            measurementLabel.position.copy(intersects[0].point)
                            // measurementLabel.layers.set(0);
                            measurementLabels[lineId] = measurementLabel

                            scene.add(measurementLabels[lineId])


                            drawingLine = true
                        } else {
                            //finish the line
                            const positions = line.geometry.attributes.position
                                .array 
                            positions[3] = intersects[0].point.x
                            positions[4] = intersects[0].point.y
                            positions[5] = intersects[0].point.z
                            line.geometry.attributes.position.needsUpdate = true
                            lineId++
                            drawingLine = false
                        }
                    }
                }
            }
            
            _canvas.addEventListener('mousemove', onDocumentMouseMove, false)
            function onDocumentMouseMove(event) {
                event.preventDefault()
            
                // _startPoint.x = ( _event.layerX / _target.clientWidth ) * 2 - 1;
                // _startPoint.y = - ( _event.layerY / _target.clientHeight ) * 2 + 1;

                mouse.x = (event.layerX / _canvas.clientWidth) * 2 - 1
                mouse.y = -(event.layerY / _canvas.clientHeight) * 2 + 1

            
                if (drawingLine) {
                    var pickableObjects = _dCadObject.data.pickableObjects;
                    raycaster.setFromCamera(mouse, camera)
                    intersects = raycaster.intersectObjects(pickableObjects, true)
                    if (intersects.length > 0) {
                        const positions = line.geometry.attributes.position
                            .array 
                        const v0 = new THREE.Vector3(
                            positions[0],
                            positions[1],
                            positions[2]
                        )
                        const v1 = new THREE.Vector3(
                            intersects[0].point.x,
                            intersects[0].point.y,
                            intersects[0].point.z
                        )
                        positions[3] = intersects[0].point.x
                        positions[4] = intersects[0].point.y
                        positions[5] = intersects[0].point.z
                        
                        ps[3] = intersects[0].point.x
                        ps[4] = intersects[0].point.y
                        ps[5] = intersects[0].point.z
                        geometry1.setPositions( ps );
                        
                        line.geometry.attributes.position.needsUpdate = true
                        const distance = v0.distanceTo(v1)
                        measurementLabels[lineId].element.innerText =distance.toFixed(2) + 'm'
                        // measurementLabels[lineId].element.getElementsByTagName('div')[0].innerText=distance.toFixed(2) + 'm'
                            
                        measurementLabels[lineId].position.lerpVectors(v0, v1, 0.5)
                    }
                }
            }
        }

        /**
         * Add events to the scene
         * 
         * @param {*} _scene 
         */
        function _addEventsToScene(__dCadObject) {
            var _dCadObject = __dCadObject;

            _dCadObject.raycaster = new THREE.Raycaster();

            var _canvas = $("#"+_dCadObject.data.canvasId).get(0);

            _addEventToSceneObjects('click', _canvas, _dCadObject)(function(_event, _node) {
                if (!_event.isMouseMoving) {
                    _dCadObject.contextMenu.hide();

                    _dCadObject.data.objectSelected = _node; 
                    _getObjectInfo(_dCadObject, _event, _node)
                }
            });


            _addEventToSceneObjects('contextmenu', _canvas, _dCadObject)(function(_event, _node) {
                if (!_event.isMouseMoving) {
                    _buildContextMenu(_dCadObject, _event, _node);
                }
            });
        }

        function _getCompassBearing(_dCadObject) {
            var _cb = _dCadObject.data.pickableObjects.filter(e => e.name == 'compass-bearing')[0];
            var _cbOfNode = _dCadObject.data.pickableObjects.filter(e => e.name == 'compass-bearing-of-node')[0];
            _dCadObject.data.compassBearing = _cb;
            _dCadObject.data.compassBearingOfNode = _cbOfNode;
        }

        //*********************TO BE ROMEVED Begin**********************************************************************************/
        function _moveObjectsTEMP(scene, _canvasId, _dCadObject) {
            // var antenna = scene.getObjectByName('MODEL|antenna|micro-dish-05m');
            // var anchor = scene.getObjectByName('AG|tower|anchor');
            var arm = scene.getObjectByName('MODEL|head-frame|monopole-arm');
            console.log();
            // var antennaanchor = scene.getObjectByName('AG|head-frame|anchor-01');
            // var tower = scene.getObjectByName('MODEL|tower|monopole-25m');


            // tower.add(arm);
            // arm.add(antenna);
            // arm.position.x = anchor.position.x;
            // arm.position.y = anchor.position.y;
            // arm.position.z = anchor.position.z;
        
            // antenna.position.x = antennaanchor.position.x;
            // antenna.position.y = antennaanchor.position.y;
            // antenna.position.z = antennaanchor.position.z;

            _loadObject("https://s3.eu-central-1.amazonaws.com/env.uat/head-frame.zip", _canvasId, _res => {
                var antenna = scene.getObjectByName('MODEL|antenna|micro-dish-05m');
                var anchor = scene.getObjectByName('AG|tower|anchor');
                var antennaanchor = scene.getObjectByName('AG|head-frame|anchor-01');

                if (anchor) {
                    anchor.add(_res);
                    antennaanchor.add(antenna)
                } else {
                    scene.add(_res);
                }

                _updateListPickableObjects(_dCadObject)
            
                _loadObject("https://s3.eu-central-1.amazonaws.com/env.uat/antenna.zip", _canvasId, _res => {
                    var antennaanchor2 = scene.getObjectByName('AG|head-frame|anchor-02');
                    if (antennaanchor2) {
                        antennaanchor2.add(_res)
                    } else {
                        scene.add(_res)
                    }

                    _updateListPickableObjects(_dCadObject)
                })
            });
        }
        //*********************TO BE ROMEVED End**********************************************************************************/



        function _initializeScene(_dCadObject) {
            // point the camera to the center of the scene
            _dCadObject.camera.lookAt(_dCadObject.scene.position);

            /*---------------------------REMOVE THIS Begin-----------------------------------------*/
            // _moveObjectsTEMP(_dCadObject.scene, _dCadObject.data.canvasId, _dCadObject)
            // const size = 100;
            // const divisions = 100;
            // const gridHelper = new THREE.GridHelper( size, divisions );
            // _dCadObject.scene.add( gridHelper );
            /*---------------------------REMOVE THIS End-----------------------------------------*/
         
            // add lights to the scene
            _addLSceneLight(_dCadObject.scene);
            
            // add an Orbit Controll to move the scene
            // _dCadObject.control = _createOrbitControll(_dCadObject.camera, _dCadObject.renderer);
            _dCadObject.control = _createOrbitControll(_dCadObject.camera, _dCadObject.labelRenderer);

            _dCadObject.control.addEventListener('change', function() {
                _dCadObject.data.isRenderingStopped = false;
            });

            // create the interaction object
            _dCadObject.interaction = _createInteractionObject(_dCadObject.interaction, _dCadObject.renderer, _dCadObject.scene, _dCadObject.camera);

            _updateListPickableObjects(_dCadObject);

            // add events to the scene
            _addEventsToScene(_dCadObject);

            _getCompassBearing(_dCadObject);
            // call the scene's renderer 
            // _render(_dCadObject, _dCadObject.renderer, _dCadObject.scene, _dCadObject.camera);            
            _render(_dCadObject);

            _initMeasurementTool(_dCadObject);

            // $timeout(function() {
            //     _dCadObject.data.isRenderingStopped = true;
            // }, 2000)
        } 

        /**
         * Recursive function that build the hierarchical json where each element
         * represent what model is possible to build with D-CAD
         * @param {*} _listObject3DHierarchy 
         * @param {*} _listObject3DType 
         * @param {*} _listObject3D 
         * @param {*} _object3DType 
         * @param {*} _object3DParentId 
         * @returns 
         */
        function _getObject3DByObject3DType(_listObject3DHierarchy, _listObject3DType, _listObject3D, _object3DType, _object3DParentId) {
            var _ret = [];
            var _object3Ds = _listObject3D.filter(e => e.object3dTypeId==_object3DType.id)

            if (_object3DParentId) {
                _object3Ds = _object3Ds.filter(e => _listObject3DHierarchy.find(h => h.object3dId==_object3DParentId && h.object3dAttachedToId==e.id));
            }

            _object3Ds.forEach(o => {
                var _item = {
                    type: _object3DType.key,
                    name: o.name,
                    code: o.code,
                    claraId: o.claraId,
                }
                _ret.push(_item);

                var _obj3DType = _listObject3DType.filter(e => e.parentId==_object3DType.id);
                if (_obj3DType && _obj3DType.length>0) {
                    var _obj3DTypeKey = _obj3DType[0].key.replaceAll('-','') + 's';
                    _item[_obj3DTypeKey] = _getObject3DByObject3DType(_listObject3DHierarchy, _listObject3DType, _listObject3D, _obj3DType[0], o.id);
                } 
            })    

            return _ret;
        }

        /**
         * Get all the info necessary to the D-CAD tool,
         * from the db, and put them into the data
         * Then build the dataSource, that contains all the objects to build a 3D-model.
         * Every dataSource's element is a hierarchical json with the tower object as a root element
         * @param {*} __dCadObject 
         * @param {*} _success 
         */
        function _buildDataSource(__dCadObject, _success) {
            var _dCadObject = __dCadObject;

            _getDataSourceInfo(_dCadObject, _res => {
                _dCadObject.data = $.extend(_dCadObject.data, _res);

                var _root = _res.listObject3DType.filter(e => !e.parentId)[0]

                _success({towers: _getObject3DByObject3DType(_res.listObject3DHierarchy, _res.listObject3DType, _res.listObject3D, _root)});
            })
        }        

        /**MODEL|tower|monopole-25m
         * Each 3D Object comes with an unique name,
         * that is composed by 
         * 1) the type of object (like, MODEL or AG)
         * 2) the type of 3D object (like, TOWER, ANTHENNA, HEAD-FRAME)
         * 3) the 3D object name (like, monopole-25m)
         * 4) if there are more than one of the same 3d object, into the scene,
         *    this will be a unique value to disambiguate among them
         * @param {*} _name 
         * @returns 
         */
        function _parseSceneObjectName(_name) {
            var _ret = {};
            var _elements = _name.split('|');

            _ret.sceneObjectName = _name;
            _ret.nodeType = _elements[0];
            _ret.object3dType = _elements[1];
            _ret.object3d = _elements[2];
            _ret.sameObject3dKey = _elements[3]?_elements[3]:null;

            return _ret;
        }

        /**
         * Collect all the info about an object3d record
         * The object3d record represent the instance of a single 3D Object used into the 3D Model
         * @param {*} _object3d 
         * @param {*} _dCadObject 
         * @returns 
         */
        function _getInfoForInventoryByObject3d(_object3d, _dCadObject) {
            var _ret;

            if (_dCadObject.data.listModel3DObjectByObject3dId) {
                var _m3dObjId = _dCadObject.data.listModel3DObjectByObject3dId[_object3d.id];
      
                if (_m3dObjId) {
                    //find the inventory item about the _object3dId selected
                    _dCadObject.data.listInventoryItem.find(e => {
                        if (e.model3dObjectId == _m3dObjId.id) {

                            //extract all the info from the json field inventoryFieldValueJson 
                            //each element is an array of field/value about an inventory group
                            var _listInventoryFieldValue = JSON.parse(e.inventoryFieldValueJson);
                            if (_listInventoryFieldValue && _listInventoryFieldValue.length>0) {
                                _ret = {inventoryGroup: []};

                                _listInventoryFieldValue.forEach(g => {
                                    var _inventoryGroup = {
                                        groupName: _dCadObject.data.listInventoryGroupById[g.inventoryGroupId].value,
                                        listInventoryField: []
                                    };
                                    g.listInventoryFieldValue.forEach(f => {
                                        if (f.value) {
                                            _inventoryGroup.listInventoryField.push({
                                                label: _dCadObject.data.listInventoryFieldById[f.inventoryFieldId].label,
                                                value: f.value
                                            })        
                                        }
                                    })

                                    if (_inventoryGroup.listInventoryField.length>0) {
                                        _ret.inventoryGroup.push(_inventoryGroup);
                                    }
                                })
                            }

                            return true;
                        }
                    })
                }

            }

            return _ret;
        }

        /**
         * Collect all the info about an object by its own name into the scene
         * 
         * @param {*} _objectName 
         * @param {*} _dCadObject 
         * @returns 
         */
        function _getInfoByObjectName(_objectName, _dCadObject) {
            var _ret = {};

            if (_objectName) {
                //parse the object's name
                $.extend(_ret, _ret, _parseSceneObjectName(_objectName))
                var _listObject3DByCode = utility.getArrayByField(_dCadObject.data.listObject3D, 'code');
                var _object3d = _listObject3DByCode[_ret.object3d];

                _ret.object3dName = _object3d.name;
                _ret.object3dTypeValue = _dCadObject.data.listObject3DTypeById[_object3d.object3dTypeId].value;

                //get the Inventory info linked to the and object by its own object3d record
                _ret.inventory = _getInfoForInventoryByObject3d(_object3d, _dCadObject);
            }

            return _ret;
        }

        /**
         * Create a zipped file, in memory,
         * about the scene
         * @param {*} _scene 
         * @param {*} _fileName 
         * @param {*} _success 
         */
         function _buildSceneFileZipped(_scene, _fileName, _success) {
            //This is a temporary solution
            //I don't know why, but "_scene.toJSON ();" throws an error if
            //in the scene there's an object like Line2
            //To avoid this, I'm going to save the 3D-Model without the Measurement data
            //So, I'm going to remove the Line2 e LineSegments from the scene
            //and put back again after the _scene.toJSON(); command
            //-------------------------BEGIN-------------------------------
            let sceneChildren = [];
            _scene.children.forEach(e => {
                if (e.type=='Line2' || e.type=='LineSegments') {
                    sceneChildren.push(e);
                }
            })

            sceneChildren.forEach(e => _scene.remove(e))
            //-------------------------END-------------------------------

            let json = _scene.toJSON();
            let sceneJson = JSON.stringify(json);          

            //-------------------------BEGIN-------------------------------
            sceneChildren.forEach(e => _scene.add(e))
            //-------------------------END---------------------------------

            var zip = new JSZip();

            zip.file(_fileName, sceneJson);

            zip.generateAsync(
                {
                    type: "blob",
                    compression: "DEFLATE",
                    compressionOptions: {
                    level: 5 //level between 1-9
                }
            }).then(function(_content) {
                _success(_content)
            }); 
        }


        function _mapSceneObjectWithDbObject(_listObject3D, _item) {
            _listObject3D.find(e => {
                if (e.code == _item.object3d) {
                    _item.object3dId = e.id;
                    _item.object3dTypeId = e.object3dTypeId;
                    return true;
                }
            })

            return _item;
        }

        function _getListObjectsIntoScene(_scene, _listObject3D) {
            var _ret = [];
            var _objs = _getListPickableObjectsFromScene(_scene).filter(e => e.name.startsWith(_nodeTypes.model));

            _objs.forEach(e => {
                var _name = e.name;

                _ret.push(_mapSceneObjectWithDbObject(_listObject3D, _parseSceneObjectName(_name)))
            })

            return _ret;
        }        

        function _mapSceneObjecsWithModel3dObjects(_model3dId, _listModel3DObject, _listSceneObjects, _listObject3DTypeById, _success) {
            var _ret = [];
            _listSceneObjects.forEach(e => {
                var _item ={
                    model3dId: _model3dId,
                    object3dId: e.object3dId,
                    sameObject3dKey: e.sameObject3dKey
                }
                var _m3dObj = undefined;
                if (_listModel3DObject) {
                    _m3dObj = _listModel3DObject.find(m => 
                        ((m.object3dId==e.object3dId && m.sameObject3dKey==e.sameObject3dKey) || 
                        (!m.sameObject3dKey && !_listObject3DTypeById[e.object3dTypeId].parentId)));
                }

                if (_m3dObj) {
                    _ret.push(_m3dObj);
                } else {
                    _ret.push(_item);
                }

            })

            _success?_success(_ret):null;
    }        

        function _saveModel3DInfo(_userId, _dCadObject, _model3DInfo, _m3dObj, _success) {

            var _listSceneObjects = _getListObjectsIntoScene(_dCadObject.scene, _dCadObject.data.listObject3D);
            var _model3DId = _model3DInfo.model3D?_model3DInfo.model3D.id:undefined;    

            _mapSceneObjecsWithModel3dObjects(_model3DId, _m3dObj, _listSceneObjects, _dCadObject.data.listObject3DTypeById, _resUpdate => {
                _model3DInfo.listModel3DObject = _resUpdate;
                _model3DInfo.assetId = _dCadObject.data.asset.id;
    
                dynamicCADServices.saveModel3DInfo(_userId, _model3DInfo, _res => {
                    _dCadObject.data.model3D = _res.model3D;
                    if (_dCadObject.data.isMain3DModel) {
                        _dCadObject.data.asset.model3dId = _res.model3D.id;
                    } else {
                        _dCadObject.data.asset.model3dAfterId = _res.model3D.id;
                    }
                        
                    //update this list. The list helps me to get inventory's info
                    dynamicCADServices.getModel3DObjectByModel3DId(_res.model3D.id, _resM3dObj => {
                        _dCadObject.data.listModel3DObjectByObject3dId = utility.getArrayByField(_resM3dObj, 'object3dId');

                        _success?_success():null;
                    })
                })
            })
        }


        function _saveSceneToDb(_dCadObject, _success) {
            var _model3DInfo = {
                model3D: _dCadObject.data.model3D?_dCadObject.data.model3D:{},
                isMain3DModel: _dCadObject.data.isMain3DModel,
            }

            if (_model3DInfo.model3D.id) {
                dynamicCADServices.getModel3DObjectByModel3DId(_model3DInfo.model3D.id, _m3dObj => {
                    _saveModel3DInfo(_dCadObject.data.userId, _dCadObject, _model3DInfo, _m3dObj, _success)
                });    
            } else {
                _saveModel3DInfo(_dCadObject.data.userId, _dCadObject, _model3DInfo, undefined, _success)
            }

        }

        /**
         * Create a zip file about the scene (3D-Model) and upload it on AWS S3
         * and save the info about the objects that compose the 3D-Model into the DB
         * @param {*} _dCadObject 
         */
        function _saveScene(_dCadObject) {
            //Stopping the rendering make the saving faster
            _dCadObject.data.isRenderingStopped = true;
            var _alert = myAlert('Saving (get the json of the scene)', 'info',0);

            // Create the scene's file zipped
            _buildSceneFileZipped(_dCadObject.scene, 'scene.json', _resFile => {
                _alert.close();
                $timeout(function() {_alert = myAlert('Saving (zipping the json)', 'info',0);}, 100) 

                if (_dCadObject.data.model3D) {
                    //If exist a previous 3D-Model, I'm going to delete it before to save the new one
                    dynamicCADServices.deleteFileToAWSS3(_buildS3Path(_dCadObject).customer3DModel(), _resDel => {
                        _saveSceneToDb(_dCadObject, _res => {
                            //Upload the zipped file on AWS S3
                            dynamicCADServices.sendFileToAWSS3(_buildS3Path(_dCadObject).customer3DModel(), _resFile, _res => {
                                _dCadObject.data.isRenderingStopped = false;
                                _alert.close();
                                $timeout(function() {_alert = myAlert('3D Model saved', 'info');}, 100) 
                            });
                        })
                    })
    
                } else {
                    _saveSceneToDb(_dCadObject, _res => {
                        //Upload the zipped file on AWS S3
                        dynamicCADServices.sendFileToAWSS3(_buildS3Path(_dCadObject).customer3DModel(), _resFile, _res => {
                            _dCadObject.data.isRenderingStopped = false;
                            _alert.close();
                            $timeout(function() {_alert = myAlert('3D Model saved', 'info');}, 100) 
                        });
                    })    
                }

            })
        }


        // function _cloneModel3D(_userId, _model3DId, _assetId, _success) {
        function _cloneModel3D(_userId, __dCadObject, _success) {
            var _dCadObject = __dCadObject;

            var _data = {
                model3DId: _dCadObject.data.asset.model3dId,
                assetId: _dCadObject.data.asset.id
            }
            dynamicCADServices.cloneModel3D(_userId, _data, _res => {
                _dCadObject.data.asset = _res.facility;
                _success?_success(_res):null;
            });
        }


        /**
         * Add the initScene method to the dCadObject
         * and initialize the scene
         * 
         * @param {*} _dCadObject 
         */
        function _createFunctionInitScene(_dCadObject) {
            _dCadObject.initScene = function(_canvasId, _path, _success) {
                _dCadObject.lapi = 'michele';

                var _alert = myAlert('Loading the 3D Model', 'info', 0);

                utility.callFunctionEveryTime("$('#"+_canvasId+"').get(0)", 300, function() {

                    var _renderer = _initRenderer(_canvasId, { antialias: true });
                    var _labelRenderer = _initLabelRenderer(_canvasId);
                    var _camera = _initCamera(_canvasId, new THREE.Vector3(20, 40, 110));
                    _camera.lookAt(new THREE.Vector3(20, 130, 0));
                    // set the scene's background color
                    _renderer.setClearColor( 0x747474, 0.9 );

                              
                    _dCadObject.renderer = _renderer;
                    _dCadObject.labelRenderer = _labelRenderer;
                    _dCadObject.camera = _camera;
                    _dCadObject.data.canvasId = _canvasId;

                    // set the camera's position
                    _dCadObject.camera.position.x = -30;
                    _dCadObject.camera.position.y = 40;
                    _dCadObject.camera.position.z = 30;


                    


                    //if there's a model3d or a model3d-after, I'm going to load it
                    if ((_dCadObject.data.isMain3DModel && _dCadObject.data.asset.model3dId) || 
                        (!_dCadObject.data.isMain3DModel && _dCadObject.data.asset.model3dAfterId)) {

                        var _pathScene = expanseConst.cfExpanseUrl + (_path?_path:_buildS3Path(_dCadObject).customer3DModel());
                                        
                        _loadScene(_pathScene, _canvasId, _res => {
                            _dCadObject.scene = _res;
                            
                            _initializeScene(_dCadObject);
                            _alert.close();

                            _success?_success():undefined;
                        });
                    } else {
                        var _scene = new THREE.Scene();
                        _dCadObject.scene = _scene;

                        _initializeScene(_dCadObject);
                        _alert.close();    

                        _success?_success():undefined;
                    }
                })
            }
        }

        /**
         * return the asset object
         * 
         * @param {*} _dCadObject 
         */
        function _createFunctionGetAsset(_dCadObject) {
            _dCadObject.getAsset = function () {
                return _dCadObject.data.asset;
            }
        }

        function _createFunctionInitDataSource(_dCadObject) {
            _dCadObject.initDataSource = function(_success) {
                _buildDataSource(_dCadObject, _res => {
                    _dCadObject.data.dataSource = _res;
                    _success?_success(_res):undefined;
                })
            }
        };

        function _createFunctionPropertiesPanelToggle(_dCadObject) {
            _dCadObject.propertiesPanelToggle = function() {
                _dCadObject.data.isPropertiesPanelShown = !_dCadObject.data.isPropertiesPanelShown;
            }
        }

        function _createFunctionSaveScene(_dCadObject) {
            _dCadObject.saveScene = function() {
                _saveScene(_dCadObject);
            }
        }

        function _createFunctionClose(_dCadObject) {
            _dCadObject.close = function() {
                cancelAnimationFrame(_dCadObject.renderId);
                _dCadObject = undefined;
            }
        }

        function _createFunctionCloneModel3D(_dCadObject) {
            _dCadObject.cloneModel3D = function(_userId,  _success) {
                _cloneModel3D(_userId, _dCadObject, _success);
            }
        }

        function _createFunctionSetListChangesSource(_dCadObject) {
            _dCadObject.setListChangesSource = function(_dCadObjectAfter) {
                _setListChangesSource(_dCadObject, _dCadObjectAfter);
            }
        }

        function _createFunctionCloseSelect3DObjectPanel(_dCadObject) {
            _dCadObject.closeSelect3DObjectPanel = function() {
                _dCadObject.data.is3DObjectSelectableAvailable = false;
            }
        }

        /**
         * Build the dCadObject that contains all
         * the data and function about D-CAD
         */
        function _buildDCadObject(_scope, _asset, _inspectingCompany, _userId, _isMain3DModel) {            
            var _dCadObject = {};

            //Will contain all the data about D-CAD
            _dCadObject.data = {
                asset: _asset,
                userId: _userId,
                inspectingCompany: _inspectingCompany,
                isMain3DModel: _isMain3DModel!=undefined?_isMain3DModel:true,
                isPropertiesPanelShown: true,
            };
            _dCadObject.scope = _scope;
            _dCadObject.contextMenu = _contextMenu(_dCadObject);

            // //*********************TO BE ROMEVED Begin**********************************************************************************/
            // _dCadObject.data.asset.model3dId = 18;
            // _dCadObject.data.isMain3DModel = true;
            // //*********************TO BE ROMEVED end**********************************************************************************/


            _createFunctionInitScene(_dCadObject);
            _createFunctionGetAsset(_dCadObject);
            _createFunctionInitDataSource(_dCadObject);
            _createFunctionPropertiesPanelToggle(_dCadObject);
            _createFunctionSaveScene(_dCadObject);
            _createFunctionClose(_dCadObject);
            _createFunctionCloneModel3D(_dCadObject);
            _createFunctionSetListChangesSource(_dCadObject);
            _createFunctionCloseSelect3DObjectPanel(_dCadObject);
            
            return _dCadObject;
        }


        return {
            /**
             * Create a new instance of dCadObject that contains all
             * the data and function about D-CAD
             * @returns 
             */
            newInstance: function(_scope, _asset, _inspectingCompany, _userId, _isMain3DModel) {
                return _buildDCadObject(_scope, _asset, _inspectingCompany, _userId, _isMain3DModel);
            },
            getListChanges: function(_now, _after) {
                return _getListChanges(_now, _after);
            }
        }
    }
]);

