//Write here all the factories about the asset-data by-tag functionalities

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('classManagmentFactory', ['fsValidator', 'myLog', 'utility', 'myAlert', 'classManagmentServices', 'ngTableParams',
    function(fsValidator, myLog, utility, myAlert, classManagmentServices, ngTableParams) {
        var _ngTableParams;
        var listObjectClasses =[];

        function _validationItems(_type, _item) {
            var v = fsValidator.newInstance();

            switch (_type) {
                case 'upload-first-page':
                v.validate(_item.classManagmentSelected.name, 'name').isEmpty("This field can't be empty")
                v.validate(_item.classManagmentSelected.description, 'description').isEmpty("This field can't be empty")
                v.validate(_item.classManagmentSelected.translation, 'translation').isEmpty("This field can't be empty")
                v.validate(_item.classManagmentSelected.class_color, 'class_color').isEmpty("This field can't be empty")

                break;
            }

            _item.errors = v.getErrors();

            return v;

        } 

        function _validationUploadDataFirstPage(_firstPage) {
            // Function to Validate the Element on the popup page
            var _ret = false;

            _validationItems('upload-first-page', _firstPage).success(function () {
                _ret = true;
            }).errors(function () {
                _ret = false;
            })

            return _ret
        }

        function validationUploadAssetDataFirstPagePopup(_Data) {
            // Function to Validate the Element on the popup page
            var _ret = _validationUploadDataFirstPage(_Data);

            return _ret

        }

        return {
           


            addItem: function(_popup, _customerId) { 
                _popup.data.classManagmentSelected = {
                    companyId: _customerId
                }

                _popup.data.addItem = true;

                _popup.setInnerStatus('classManagmentSelected',_popup.data.classManagmentSelected);

                utility.callFunctionEveryTime('!$("#_classManagment_fields").hasClass("ng-hide")', 200, function() {
                    $("#_catalogue_panel").scrollTop( $("#_classManagment_fields").offset().top );   
                    $("#_item_name").focus();                       
                });    
            },
            editClassItem: function () {
                return function (_class, _popup) {
                    _popup.data.classManagmentSelected = angular.copy(_class)
                    _popup.data.addItem = false;
                    _popup.setInnerStatus('classManagmentSelected',_popup.data.classManagmentSelected);

                    utility.callFunctionEveryTime('!$("#_classManagment_fields").hasClass("ng-hide")', 200, function() {
                        $("#_catalogue_panel").scrollTop( $("#_classManagment_fields").offset().top );   
                        $("#_item_name").focus();                       
                    });    
                }
            },
            cancelItem: function(_popup) {
                _popup.data.classManagmentSelected = undefined;
                _popup.data.addItem = false;
                _popup.setInnerStatus('classManagmentSelected',_popup.data.classManagmentSelected);
            },
            saveItem: function(_popup, _userId) {

                var validation = validationUploadAssetDataFirstPagePopup(_popup.data)

                if (validation == true){
                    var Data = {
                        facility_type_id: _popup.data.facilityTypeSelected,
                        company_id: _popup.data.customerId,
                        classManagmentSelected: _popup.data.classManagmentSelected,
                        created_by_id: _userId
                    }
                    _popup.data.addItem = false;


                    classManagmentServices.saveClass(Data, _res => {

                        if (_res == 'already exist'){
                            myAlert("Item already present", 'danger');

                        }else{
                            if (_popup.data.classManagmentSelected.id) {
                                var _index = utility.getIndexArrayElementByField(listObjectClasses, 'id', _popup.data.classManagmentSelected.id);
                                listObjectClasses[_index] = _res
                                myAlert("Class updated", 'info');
                            }
                            
                            if (_popup.data.classManagmentSelected.key) {
                                var _index = utility.getIndexArrayElementByField(listObjectClasses, 'key', _popup.data.classManagmentSelected.key);
                                listObjectClasses[_index] = _res
                                myAlert("Class updated", 'info');
                            }

                            if (!_popup.data.classManagmentSelected.key & !_popup.data.classManagmentSelected.id) {
                                listObjectClasses.push(_res);
                                myAlert("Class created", 'info');
                            }
                            
                            _ngTableParams.reload();                           
                            
                        }
                        _popup.data.classManagmentSelected = undefined;
                        _popup.data.addItem = false;
                        _popup.setInnerStatus('classManagmentSelected',_popup.data.classManagmentSelected);

                    })
                }
            },

            getObjectClasses: function() {
                var _facilityTypeSelectedPrev;

                return {
                    init: function (_popup) {
                        if( _popup.data.facilityTypeSelected){
                            classManagmentServices.getListComponentAnomalies(_popup.data.customerId,  _popup.data.facilityTypeSelected, _res => {
                                _res = JSON.parse(_res)
                                listObjectClasses = _res['listCompoTags'];
                                listObjectClasses = listObjectClasses.concat(_res['listAnoTags']);
                                // _this.availabletAnomalies = _res['listAnoTags'].filter(element => element.type_usage == 'training');
                                _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                                _popup.data.refreshClassTableParams = false;
                                _ngTableParams = ngTableParams.default(listObjectClasses);
                                // _popup.updateData(_popup.data);
                                return _ngTableParams;
                            }, _err => {
                                myAlert("Error: " + _err.data.message, 'danger');
                            })      
                        }
                    },
                    getData: function () {
                        return _ngTableParams;
                    },
                    refresh: function () {
                        return _ngTableParams.reload();
                    }
                }
            },

            getSystemClassParam: function () {
                var _facilityTypeSelectedPrev;
                return function(_popup) {
                    if (_popup.data.facilityTypeSelected && _facilityTypeSelectedPrev != _popup.data.facilityTypeSelected) {
                        _facilityTypeSelectedPrev = _popup.data.facilityTypeSelected;
                        _popup.data.refreshClassTableParams = true;
                    }
                }
            }

        };
    }
]);

