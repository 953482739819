//Write here all the services about SpinModel
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp
    .service("spinModelServices", ['myHttp', 'buildUrl', 'myLog', 'serviceUtility', function(myHttp, buildUrl, myLog, serviceUtility) {
        return  {
            getSpinModelByAssetDataId: function(_assetDataId, _success, _error) {
                var _url = buildUrl( '/expanse/spinModel/byFacilityDataId/' + _assetDataId);
    
                myHttp.get(_url).success( _res => {    
                    myLog("Result from " + _url, _res);
                    _success(_res.spinModelDto);
    
                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to get the spin model data about assetDataId: '"+_assetDataId+"'";
                    _error(_err);
                } );    
            },
            saveSpinModel: function(_userId, _spinModel, _success, _error) {
                var _url = buildUrl('/expanse/spinModel/' + _userId);

                myHttp.post(_url, JSON.stringify(_spinModel)).success(_res => {
                    myLog("Result from " + _url, _res);
                    _success(_res.spinModelDto);

                }).error( _err => {
                    myLog("Error from " + _url, _err.data);
                    _err.data.message = "I wasn't able to save the spin model data";
                    _error(_err);
                } );
            },
            saveSpinModelOffSite: function(_userId, _spinModelOffSite, _success, _error) {
                var _url = buildUrl('/expanse/spinModelOffSite/' + _userId);
                var _errorMsg = "I wasn't able to save the spin model off-site data";

                serviceUtility.runPost("saveSpinModelOffSite", _url, _spinModelOffSite, _errorMsg, _res => _success(_res.facilityDataDto), _error);
            },


        }
    }])

