require('./dashboard.component.js');

require('./dashboard-status-chart.component');
require('./dashboard-asset-map.component');
require('./dashboard-asset-types.component');
require('./dashboard-asset-data.component');

require('./dashboard-calendar.component');
require('./inspection-cmp/dashboard-insp-last-reports.component');
require('./asset-owner/dashboard-owner-last-reports.component');

