//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("azimuthServices", ['myHttp', 'buildUrl', 'myLog', 'serviceUtility',  function(myHttp, buildUrl, myLog, serviceUtility) {

        var _functions  = {
            saveImage: function(_userId, _image, _fdd, _success, _error) {
                var _url = buildUrl('/awss3/azimuth/image/' + _userId);
                var _errorMsg = "I wasn't able to save the image";
                var _data = {
                    img: JSON.stringify(_image),
                    facilityDataDetailsId: _fdd.id
                }

                serviceUtility.runPost("saveImage", _url, _data, _errorMsg, _res => _success(_res), _error);
            },
            saveCanvasObjects: function(_userId, _list, _fddId, _success, _error) {
                var _url = buildUrl('/expanse/azimuth/fddId/'+_fddId+"/" + _userId);
                var _errorMsg = "I wasn't able to save the azimuth objects";
                serviceUtility.runPost("saveCanvasObjects", _url, _list, _errorMsg, _success, _error);
            },
            getObjectsByFddId: function(_fddId, _success, _error) {
                var _url = buildUrl( '/expanse/azimuth/fddId/'+_fddId);
                var _errorMsg = "I wasn't able to get the Azimuth Objects";

                serviceUtility.runGet("getObjectsByFddId", _url, _errorMsg, _res => _success(_res.listAzimuthObjectDto), _error);
            } 

        };

        //inherited some useful function from serviceUtility
        // return angular.extend(_functions, serviceUtility.inheritedUtilities(_functions));
        return _functions;
    }]);
