//Write in this file
//how to route the application on different views

let expanseLiteApp = require('../modules/appModule.js');

var vendorsPath = 'vendors/';
var bowerPath = 'vendors/bower_components/';

expanseLiteApp
    .config(function($stateProvider, $urlRouterProvider) {
        //Here is where the application come
        //when the user access the application first time.
        $urlRouterProvider.otherwise(function($injector, $location){
            var state = $injector.get('$state');
            var utility = $injector.get('utility');
            var _url = $location.absUrl();

            // if (_url.includes("/iframe/") ) {
            //     var _urlParams = utility.parseUrl($location.absUrl()).getArrayParam(); 
            //     localStorage.setItem('exlite.liteToken', _urlParams['liteToken']);



            //     var _index = _url.indexOf("/iframe/");
            //     var _targetWithParams = _url.substring(_index+8, _url.length)
            //     _index = _targetWithParams.indexOf("?");
            //     var _target = _targetWithParams;
            //     _urlParams = {};

            //     if (_index > 0) {
            //         _target = _targetWithParams.substring(0, _index);
            //         _urlParams =  utility.parseUrl(_targetWithParams).getListParam(); 
            //     }

            //     state.go("iframe-"+_target, _urlParams);
            // } else {

            if (_url.includes("/iframe/") ) {
                var _index = _url.indexOf("/iframe/");
                _url = _url.substring(0, _index)
            }

            // var _urlParams = utility.parseUrl($location.absUrl()).getArrayParam(); 
            var _urlParams = utility.parseUrl(_url).getArrayParam(); 
            localStorage.setItem('exlite.liteToken', _urlParams['liteToken']);


            var _pageName = _urlParams['page_name'];

            var _target = "dashboard"+(utility.isFromMobile()?"-mobile.main":".main");
            //call "login" route
            state.go('login', {page_name: _pageName?_pageName:_target} );
            // }
        });


        $stateProvider

            // .state('example', {
            //     url: '/example',
            //     templateUrl: 'views/asset-data/iframe/iframe_pippo.html'
            // })

            //In "loginCtrl" controller, all the information about
            //the user logged in are taken
            .state('login', {
                url: '/login?{page_name:string}',
                params: {page_name: { value: null}},
                controller: 'loginCtrl'
            })

            .state('dashboard', {
                url: '/dashboard',
                templateUrl: 'views/common.html'
            })

            .state('dashboard.main', {
                url: '/main',
                template: '<dashboard-cmp></dashboard-cmp>'
            })

            .state('dashboard-mobile', {
                url: '/dashboard/mobile',
                templateUrl: 'views/common.html'
            })
            .state('dashboard-mobile.main', {
                url: '/main',
                template: '<dashboard-cmp></dashboard-cmp>'
            })

            
            .state('customer-asset', {
                url: '/customer_asset',
                templateUrl: 'views/common.html'
            })

            .state('customer-asset.main', {
                url: '/customer_asset/main?{customer_id:string}?{asset_id:string}',
                params: {customer_id: { value: null}, asset_id: { value: null}},
                templateUrl: 'views/customer-asset/customer-asset.html'
            })

            .state('customer-asset-mobile', {
                url: '/customer_asset/mobile',
                templateUrl: 'views/common.html'
            })

            .state('customer-asset-mobile.main', {
                url: '/main?{customer_id:string}?{asset_id:string}',
                params: {customer_id: { value: null}, asset_id: { value: null}},
                templateUrl: 'views/customer-asset/customer-asset-mobile.html'
            })


            .state('asset-data', {
                url: '/asset_data',
                templateUrl: 'views/common.html'
            })

            .state('asset-data.main', {
                
                url: '/asset_data/main?{customer_id:string}?{asset_id:string}',
                params: {customer_id: { value: null}, asset_id: {value: null}},
                templateUrl: 'views/asset-data/asset-data.html'
            })

            .state('asset-data-mobile', {
                url: '/asset_data/mobile',
                templateUrl: 'views/common.html'
            })

            .state('asset-data-mobile.main', {
                url: '/main?{customer_id:string}?{asset_id:string}',
                params: {customer_id: { value: null}, asset_id: {value: null}},
                templateUrl: 'views/asset-data/asset-data-mobile.html'
            })


            .state('asset-data-upload', {
                url: '/asset_data_upload',
                templateUrl: 'views/common.html'
            })

            .state('asset-data-upload.main', {
                url: '/asset_data/upload?{customer_id:string}?{asset_id:string}?{facility_data_id_selected:string}?{checklist_flag:string}',
                params: {customer_id: { value: null}, asset_id: {value: null}, facility_data_id_selected: {value: null}, checklist_flag: {value: null} },
                templateUrl: 'views/asset-data/asset-data_upload.html'
            })

        //-------------------------------iframe section: begin----------------------------------------------

        .state('iframe-matterport', {
            url: '/iframe/matterport?{fd_id:string}',
            params: {fd_id: { value: null}},
            templateUrl: 'views/asset-data/iframe/iframe_matterport.html'
        })

        .state('iframe-azimuth', {
            url: '/iframe/azimuth?{fdd_id:string}',
            params: {fdd_id: { value: null}},
            templateUrl: 'views/asset-data/iframe/iframe_azimuth.html'
        })

        .state('iframe-tilt-angle', {
            url: '/iframe/tilt-angle?{fd_id:string}',
            params: {fdd_id: { value: null}},
            templateUrl: 'views/asset-data/iframe/iframe_tilt-angle.html'
        })

        .state('iframe-compass-viewer', {
            url: '/iframe/compass-viewer',
            templateUrl: 'views/asset-data/iframe/iframe_compass-viewer.html'
        })

        .state('iframe-spin-model', {
            url: '/iframe/spin-model?{fd_id:string}',
            params: {fd_id: { value: null}},
            templateUrl: 'views/asset-data/iframe/iframe_spin-model.html'
        })

        .state('iframe-inventory-fast', {
            url: '/iframe/inventory-fast?{inspecting_company_id:string}?{asset_id:string}',
            params: {inspecting_company_id: { value: null}, asset_id: { value: null}},
            templateUrl: 'views/asset-data/iframe/iframe_inventory-fast.html' 
        })
        //-------------------------------iframe section: end------------------------------------------------



    });
