//Write in this file all the constants used in the application
//in PRODUCTION ENVIRONMENT

let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.constant('expanseConst', {
    //idle time in sec
    'idleTime': 1*60*60,  //1 hours 
    //session timeout time in sec
    'timeoutTime': 5,
    //define the environment
    'environment': 'pr',
    //Enable/Disable console logging
    'logging': false,
    //Enable/Disable save logs to backend
    'backendLogging': true,
    //is the cache name about the user object
    'userCacheName': 'exlite.identity',
    //Java rest API Endpoint
    'expanseRestUrl': '/japi',
    'expanseRestUrlSuffix': '',
    //Softeq Expanse Endpoint
    // 'softeqUrl': 'https://expanseinspection.com/',
    'softeqUrl': 'https://inspection2.com/',
    //Softeq Expanse Customer portal Endpoint
    // 'softeqCustomerUrl': 'https://ExpanseInspection.com/customer/',
    'softeqCustomerUrl': 'https://inspection2.com/customer/',
    //Expanse AWS S3 Bucket 
    'expanseS3Url': "https://s3.eu-central-1.amazonaws.com/env.prod/",
    //AWS S3 environment
    'awsS3env': 'pr',
    //Asset Mapper Endpoint
    // 'maps3dServiceUrl': 'https://cf-content.expanseinspection.com/asset-mapper/index.html',
    //Airmap API key
    'airmapApiKey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsX2lkIjoiY3JlZGVudGlhbHxBYU5rYjVTNUJ2d2RiUzVxOW5Bbkl6a1cyUmciLCJhcHBsaWNhdGlvbl9pZCI6ImFwcGxpY2F0aW9ufHlYa1pOZTdmb0RFREp2aTg3ZzNNQVNrbnhHdyIsIm9yZ2FuaXphdGlvbl9pZCI6ImRldmVsb3BlcnxaeTM0ZW5YczNOUVhPZVNhNDk5a2FTNkR4MDlSIiwiaWF0IjoxNDc1MDUzMTI2fQ.cM1mA0mPJig_ALakVlLEQT6T3UMvdL2a8dbuw0rUh-Q',    
    'viewer3dUrl': 'https://viewer3d.sky-futures.com',
    'viewer3dEnv': 'PROD',
    //CloudFront url about customer's logo
    'cfLogoUrl':'https://d2b6vcxfl44ahw.cloudfront.net/customer-logo',
    //CloudFront url about asset data
    'cfAssetDataUrl':'https://d2b6vcxfl44ahw.cloudfront.net/lite',
    //Expanse CloudFront url about customer's logo
    'cfExpanseUrl': 'https://cf-content.inspection2.com/',

});
