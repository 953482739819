//Write in this file all the general services
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    //This service create a url string
    //adding to the _url parameter the expanseConst.expanseRestUrl value
    //that represent the java server side APIs address
    .service("buildUrl", ['expanseConst', function (expanseConst) {
        return function (_url) {
            return expanseConst.expanseRestUrl + _url;
        }
    }])

    //This service show informations on console.log 
    //only if logging is enabled
    .service("myLog", ['expanseConst', function (expanseConst) {
        return function (..._args) {
            if (expanseConst.logging) {
                console.log(_args);
            }
        }
    }])
    

    //This service is used to throw an exception
    //and show the error message 
    //into the console (if the application is running on dev/test/quality environments)
    //or into a server side log file (if the application is running on production environment), calling a specific server side API
    .service('myThrow', function (expanseConst, myHttp, buildUrl, myLog) {
        return function (_ex) {
            var _exJson;

            if (typeof (_ex) == "string") {
                _exJson = {
                    'message': _ex,
                    'stack': "no stack"
                }
            } else if (_ex.status) {
                _exJson = {
                    'message': _ex.data.message,
                    'stack': "no stack",
                    'path': _ex.data.path,
                    'status': _ex.data.status,
                    'error': _ex.data.error
                }
            } else {
                _exJson = {
                    'message': _ex.message,
                    'stack': _ex.stack
                }
            }

            //Check if the log has to be saved on the java backend
            if (expanseConst.logging && expanseConst.backendLogging) {
                var _url = buildUrl('/exAdministration/frontendLogging');
                myHttp.post(_url, JSON.stringify(_exJson));
            }
            myLog(_exJson);

            throw _ex;
        }
    })

    //This service is based on $http service 
    //and do exactly what $http do but that
    //it add some element into the request header    
    //In this case, the Authorization parameter is added to set up the basic authentication
    .service('myHttp', function ($http, $base64, $q, expanseConst) {

        function _getPercentage(e, token_obj){
            //this function computes the upload percentage of the files
            if (token_obj && typeof token_obj.file !== 'undefined'){
                token_obj.file.percentage = e.loaded * 100 / e.total;
            }
        };

        //This function build the request
        function _buildRequest(_method, _url, _data, _contentType = '', token_obj='') {
            //Get user's information from the cache 
            var retrievedObject = localStorage.getItem(expanseConst.userCacheName);            
            var _user;
            var _auth;
            var _header = {};
            var _token;
            var _endpoint;

            if (retrievedObject) {
                _user = JSON.parse(retrievedObject);

                _auth = $base64.encode(_user.login + ":" + _user.password);
                _endpoint = $base64.encode(_url.replaceAll(expanseConst.expanseRestUrl, expanseConst.expanseRestUrlSuffix));
                _token = _endpoint.substr(0,10) + $base64.encode(_user.liteToken + _user.login);
                _endpoint = _endpoint.substr(10, _endpoint.length);

                if (_contentType == ''){
                    _header = {
                        'Authorization': 'Basic ' + _auth,
                        'X-Requested-With': 'XMLHttpRequest',
                        'Access-Control-Allow-Origin': '*',
                        // 'username':_user.login,
                        'liteToken':_user.liteToken,
                        'token': _token,
                        // 'endpoint': $base64.encode(_url.replaceAll(expanseConst.expanseRestUrl,''))
                        'endpoint': _endpoint
                        // "cache-control": "no-cache"
                    };   
                }
                else{
                    _header = {'Content-Type': _contentType}
                } 
            }

            if (_method=='get') {
                if (_url.indexOf("user/byToken") == -1 && !_url.startsWith("api/") && !_url.startsWith("/api/") && !_url.startsWith('https://s3.eu-central-1.amazonaws.com')) {
                    _url = expanseConst.expanseRestUrl + '/expanse/filter'; 
                }
            }

            if (_url.startsWith("/api/") || (_url.startsWith("api/") )) {
                _url =  '/api/verify'; 
            }
                
            var _http = $http({
                method: _method,
                url: _url,
                data: _data,
                headers: _header,
                uploadEventHandlers: { progress: function(e) {
                    _getPercentage(e, token_obj)
                }}
            });            

            var promise = $q.when(_http);

            promise.success = function (fn) {
                promise.then(function (response) {
                    fn(response.data, response.status, response.headers);
                });
                return promise;
            };

            promise.progress = function (evt) {
                promise.then(function (response) {
                    console.log(evt)
                    //var progressPercentage = parseInt(100.0 * progress.loaded / evt.total);
                });
                return 'promise';

 
            };
            promise.error = function (fn) {
                promise.then(null, function (response) {
                    var _err = {};
                    _err.data = response.data? response.data: {};
                    _err.status = response.status;
                    _err.headers = response.headers;

                    // fn(response.data, response.status, response.headers);
                    fn(_err);
                });
                return promise;
            };


            return promise;
        }

        return {
            //make a get request
            get: function (_url) {
                return _buildRequest('get', _url, undefined);
            },
            //make a post request
            post: function (_url, _data) {
                return _buildRequest('post', _url, _data);
            },
            //make a post request
            put: function (_url, _data, _contentType, token_obj) {
                return _buildRequest('put', _url, _data, _contentType, token_obj);
            },
            //make a delete request
            delete: function (_url, _data) {
                return _buildRequest('delete', _url, undefined);
            },
            analizeBackendError: function(_err) {
                if (_err.data && _err.data.backendError) {
                    switch(_err.data.backendError) {
                        case '_concurrent_access':
                            _err.data.message = "Concurrent Access Problem";
                            break;
                    }

                    return true;
                } else {
                    return false;
                }
            },
            buildPromise: function(_service, _fnName, ..._args) {            
                const _promise = new Promise((resolve, reject) => {
                    _args.push(resolve);
                    _args.push(reject);
                    _service[_fnName].apply(this, _args);
                });
    
                return _promise
            },
            runPromise: function(_promises, _success, _error) {
                // var _rets = [];
                var  result = Promise
                .all(_promises)
                .then((_rets) => {
                    return _rets;
                });
    
                return result.then(_res => {
                    _success(_res)
                }, _err => {
                    _error(_err)
                });            
            }            
        }
    })

    //This service make to boilerplate code to run a http method
    .service('serviceUtility', function (myHttp, myLog, myAlert) {
        return {
            inheritedUtilities: function(_serviceFunctions) {
                return {
                    inherited: {
                        //this function return a json object with the name of functions (and their arguments)
                        //belonging to a service
                        getFunctionsInfo: function() {
                            var _infoFunctions = [];
                            //get the name of the functions into the service
                            var _keys = Object.keys(_serviceFunctions);
                            _keys.forEach(k => {
                                var _item = {};
                                _item.name = k;

                                //get the function code as a string without whitespace and in a single row
                                var _fnFlatString = _serviceFunctions[k].toString().replace(/[\n\r\s]+/g, '');
                                //get the arguments of the function
                                var _args = _fnFlatString.substring(_fnFlatString.indexOf("(")+1, _fnFlatString.indexOf("){")).split(",");
                                _item.args = [];
                                _args.forEach(a => _item.args.push({name:a}));
                                _infoFunctions.push(_item);
                            })

                            return _infoFunctions;
                        },
                        removeCallbacksFromFuntionsInfo: function(_functionsInfo) {
                            var _ret = angular.copy(_functionsInfo);
                            _functionsInfo.forEach((f, index) => _ret[index].args = f.args.filter(a => a.name!='_success' && a.name!='_error'))

                            return _ret;
                        } 
                    }
                };
            },
            //Create and exectute a HTTP GET request
            runGet: function(_fnName, _url, _errorMsg, _success, _error ) {
                myHttp.get(_url).success( _res => {
                    myLog("Result calling function '"+_fnName+"' with url '" + _url+"'", _res);
                    
                    if (_success) _success(_res);
                }).error( _err => {
                    myLog("Error calling function '"+_fnName+"' with url '" + _url +"'", _err.data);
                    myAlert("Error: " + _errorMsg, 'danger');
                    _err.data.message = _errorMsg;
    
                    if (_error) _error(_err);
                } );
            },
            //Create and exectute a HTTP POST request
            runPost: function(_fnName, _url, _data, _errorMsg, _success, _error ) {
                myHttp.post(_url, JSON.stringify(_data)).success(_res => {
                    myLog("Result calling function '"+_fnName+"' with url '" + _url+"'", _res);
                    
                    if (_success) _success(_res);
                }).error( _err => {
                    myLog("Error calling function '"+_fnName+"' with url '" + _url +"'", _err.data);

                    if (!myHttp.analizeBackendError(_err)) {
                        _err.data.message = _errorMsg;
                        myAlert("Error: " + _errorMsg, 'danger');
                    } else {
                        myAlert("Error: " + _err.data, 'danger');
                    }

                    if (_error) _error(_err);
                } );
            },
            //Create and exectute a HTTP DELETE request
            runDelete: function(_fnName, _url, _errorMsg, _success, _error ) {
                myHttp.delete(_url).success(_res => {
                    myLog("Function '"+_fnName+"' with url '" + _url+"' executed");

                    if (_success) _success();
                }).error( _err => {
                    myLog("Error calling function '"+_fnName+"' with url '" + _url +"'", _err.data);
                    myAlert("Error: " + _errorMsg, 'danger');
                    _err.data.message = _errorMsg;
    
                    if (_error) _error(_err);
                } );
            },

        }
    })


    //This service create an NgTableParams object
    //The NgTableParams is used by ng-table elements into html
    .service('ngTableParams', ["NgTableParams", "$filter", function (NgTableParams, $filter) {
        return {
            //return a default NgTableParams with a custom dataset
            //default mean: the counts section array is [10, 25, 50, 100]
            //_dataset: is the list of data that will be shown into the table
            default: function (_dataset, _info) {
                return new NgTableParams({}, { dataset: _dataset, my_info: _info});
            },
            withConfig: function (_config, _dataset, _info) {
                return new NgTableParams(_config, { dataset: _dataset, my_info: _info
                    // getData: function(_a, _b, _c)  {
                    //     return _a._settings.dataset;
                    // }
                    // getData: function($defer, params) { 
                    //     console.log();
                    // //     var params = $defer._params;
                    // //     // $filter('filter')(data, _params.term)
                    // //     // var data = _dataset
                    // //     // var orderedData = params.filter() ? $filter('filter')(data, params.filter().term) : data;
                    // //     // $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                    // }
                });
            }
        }
    }])

    //Write in this service all the utility functions
    .service('utility', function ($timeout) {
        var _functions = {
            //return an array where the index is the field called _indexFieldName 
            //and the value is the field called _valueFieldName  of the _list element.
            //If _valueFieldName  is undefined, the entire _list element will be the new array value
            //This type of array if useful as an HashMap
            getArrayByField: function (_list, _indexFieldName, _valueFieldName) {
                var _array = undefined;

                if (_list && _list.length > 0) {
                    _array = [];

                    _list.forEach(e => {
                        if (_valueFieldName) {
                            _array[e[_indexFieldName]] = e[_valueFieldName];
                        } else {
                            _array[e[_indexFieldName]] = e;
                        }
                    })
                }

                return _array;
            },
            //return a copy of the first element into a list
            //where the field called _fieldName has the value equal to _value
            getArrayElementByField: function (_list, _fieldName, _value) {
                var _element = undefined;

                _list.some(e => {
                    if (e[_fieldName] == _value) {
                        _element = angular.copy(e);
                        return true;
                    }
                })

                return _element;
            },
            //return a reference of the first element into a list
            //where the field called _fieldName has the value equal to _value
            getIndexArrayElementByField: function (_list, _fieldName, _value) {
                var _index = undefined;

                _list.some((e, _i) => {
                    if (e[_fieldName] == _value) {
                        _index = _i;
                        return true;
                    }
                })

                return _index;
            },
            //use this function to call the same function, each _time,
            //until the _condition is true, than call the function _success
            //Usually used when there's need to wait until an html element is created
            //(ex. google map initializing)
            callFunctionEveryTime(_condition, _time, _success) {
                function _callFunctionEveryTime( _condition, _success) {
                    if (eval(_condition)) {
                        _success();
                    } else {
                        $timeout(function() {
                            _callFunctionEveryTime(_condition, _success)
                        }, _time)
                    }
                }

                _callFunctionEveryTime(_condition, _success)
            },

            callFunctionWithScopeEveryTime(_scope, _condition, _time, _success) {
                var __scope = _scope
                function _callFunctionEveryTime( _condition, _success) {
                    if (eval("__scope"+_condition)) {
                        _success();
                    } else {
                        $timeout(function() {
                            _callFunctionEveryTime(_condition, _success)
                        }, _time)
                    }
                }

                _callFunctionEveryTime(_condition, _success)
            },

            //Just remove all the white spaces
            removeWhiteSpaces: function(_st) {
                return _st.replace(/\s+/g, '');
            },
            deleteJsonElement: function(_json, _key) {
                return delete _json[_key];
            },
            parseUrl: function(_url) {
                function _cleanUrl(_url) {
                    return  (_url.split('?')[1]).split('#')[0];
                }
                function _getArrayParam(_url) {
                    var _urlCleaned = _cleanUrl(_url).split('&');
                    var _ret = [];
                    _urlCleaned.forEach(e => {
                        var _keyValue = e.split('=');
                        if (_keyValue.length > 0) {
                            _ret[_keyValue[0]] = _keyValue[1];
                        }
                    })

                    return _ret;
                }
                

                return {
                    getArrayParam: function() {
                        return _getArrayParam(_url);
                    },
                    getListParam: function() {
                        // return JSON.stringify(_getArrayParam(_url));
                        var _params = _getArrayParam(_url);
                        var _keys = Object.keys(_params);
                        var _ret = {};
                        _keys.forEach(k => {
                            _ret[k] = _params[k];
                        }) 

                        return _ret;
                    },
                    getJsonParam: function() {
                        return JSON.stringify(_getArrayParam(_url));
                    }
                }
            },
            formatSize(_size) {
                var _unitSize = [Math.pow(1024, 4), Math.pow(1024, 3), Math.pow(1024, 2), Math.pow(1024, 1)];
                var _unitSimbol = ["Tb", "Gb", "Mb", "Kb"];
    
                if (_size) {
                    for (var i = 0; i < _unitSize.length; i++) {
                        // var _d = Math.round(_size/_unitSize[i]);
                        var _d = _size / _unitSize[i];
    
                        if (_d >= 1) {
                            return Math.round(_d * 100) / 100 + _unitSimbol[i];
                        }
                    }
    
                    return _size + "b";
                } else {
                    return null;
                }
            },
            formatDate(_dateString, _pattern) {
                var dateFormat = require('dateformat');
                var _date = new Date(_dateString);
                return dateFormat(_date, _pattern);
            },
            getGeoPosition: function(_lat1, _lon1, _lat2, _lon2) {
                var _dLon = _lon1 - _lon2;
                var _y = Math.sin(_dLon)*Math.cos(_lat1);
                var _x = Math.cos(_lat2)*Math.sin(_lat1) - Math.sin(_lat2)*Math.cos(_lat1)*Math.cos(_dLon);
                var _brng = Math.atan2(_y, _x)*180/Math.PI;
                var _direction_flag;
    
                if (_brng > -22.5 && _brng <22.5) _direction_flag = 'N'
                if (_brng > 22.5 || _brng <67.5) _direction_flag = 'NW'
                if (_brng > 67.5 || _brng <112.5) _direction_flag = 'W'
                if (_brng > 112.5 && _brng <157.5) _direction_flag = 'SW'
                if (_brng > 157.5 || _brng <-157.5) _direction_flag = 'S'
                if (_brng > -157.5 && _brng < -112.5) _direction_flag = 'SE'
                if (_brng > -112.5 && _brng <-67.5) _direction_flag = 'E'
                if (_brng > -67.5 && _brng <-22.5) _direction_flag = 'NE'
    
                return _direction_flag
            },
            getDegreesBetweenGeoPosition: function(_lat1, _lon1, _lat2, _lon2) {
                // var tc1=Math.atan2(
                //     Math.sin(_lon2-_lon1)*Math.cos(_lat2), 
                //     Math.cos(_lat1)*Math.sin(_lat2)-Math.sin(_lat1)*Math.cos(_lat2)*Math.cos(_lon2-_lon1)) % 2*Math.PI
                // // var _dlat = _lat2 - _lat1
                // // var _dlon = _lon2 - _lon1
                // var _y = Math.sin(_lon2-_lon1)*Math.cos(_lat2)
                // var _x = Math.cos(_lat1)*Math.sin(_lat2)-Math.sin(_lat1)*Math.cos(_lat2)*Math.cos(_lon2-_lon1)                    

                // // if (_y > 0) {
                // //     if (_x > 0) tc1 = Math.atan(_y/_x)
                // //     if (_x < 0)  tc1 = 180 - Math.atan(-_y/_x)
                // //     if (_x = 0) tc1 = 90
                // // } else if (_y<0) {
                // //     if (_x > 0)  tc1 = -Math.atan(-_y/_x)
                // //     if (_x < 0)  tc1 = Math.atan(-_y/_x)-180
                // //     if (_x = 0)  tc1 = 270    
                // // } else {
                // //     if (_x > 0)  tc1 = 0
                // //     if (_x < 0)  tc1 = 180
                // //     // if (_x = 0) then [the 2 points are the same]
                // // }

                // return tc1;
                var _dLon = _lon1 - _lon2;
                var _y = Math.sin(_dLon)*Math.cos(_lat1);
                var _x = Math.cos(_lat2)*Math.sin(_lat1) - Math.sin(_lat2)*Math.cos(_lat1)*Math.cos(_dLon);
                return Math.atan2(_y, _x)*180/Math.PI;
            },
            moveTablePagination: function(_idTable, _ignoreMobile) {
                _functions.callFunctionEveryTime("$('#"+_idTable+" div[ng-table-pagination]').get(0)", 300, function() {
                    var _idTableContainer = _idTable + 'Container';
                    if (_functions.isFromMobile() && !_ignoreMobile) {
                        $("#"+_idTableContainer+" .main-table:first").css("height","calc(100% - 105px")
                        $("#"+_idTableContainer+" div[ng-table-pagination]").addClass("main-table external-pagination-mobile")    
                    } else {
                        $("#"+_idTableContainer+" .main-table:first").css("height","calc(100% - 55px")
                        $("#"+_idTableContainer+" div[ng-table-pagination]").addClass("main-table external-pagination")    
                    }
                    $("#"+_idTableContainer+" div[ng-table-pagination]").appendTo($("#"+_idTableContainer))    
                })
            },
            isFromMobile: function() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
            },
            scopeApply: function(_scope) {
                //Check if the $apply is in progress
                if(!_scope.$$phase) {
                    _scope.$apply();
                }
            },
        };

        return _functions;
    })



    .service('fsValidator', ['sfUtil', function(sfUtil) {
        var _errors = {};
    
        function putErrorMsg(f, msg) {
            if (!_errors[f]) {
                //If the field f doesn't have any error, 
                //add it, with the error message msg, to _errors array
                _errors[f] = msg;
            } else {
                //If the field f exists in _error array, 
                //contact the new msg to the existing one
                var m = _errors[f] + ', ' + msg;
                _errors[f] = m;
            }
        };
    
        return {
            newInstance: function() {
                _errors = {};
    
                return this;
            },
            // validate: function(element, fieldName) {
            validate: function(a, b, c, d) {
                var element;
                var fieldName;
                var dateA;
                var dateB;
                var type;
    
                if (arguments.length === 2) {
                    element = a;
                    fieldName = b;
                } else {
                    dateA = a;
                    dateB = b;    
                    fieldName = c;
                    type = d;
                }
                return {
                    isEmpty: function(errorMsg) {
                        if (element == null || element == undefined || ((element instanceof String) && element.length === 0)) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isEmpty: function(errorMsg) {
                        if (element == null || element == undefined || ((element instanceof String) && element.length === 0)) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isUndefined: function(errorMsg) {
                        if (element === undefined) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isGreaterThan: function(errorMsg) {
                        if (type && type!="number") {
                            if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                            if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));    
                        }
                        if (dateA > dateB) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isSmallerThan: function(errorMsg) {
                        if (type && type!="number") {
                            if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                            if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                        }
                        if (dateA < dateB) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isNotEqualTo: function(errorMsg) {
                        if (typeof dateA === 'string') dateA = sfUtil.formatDate(dateA); //new Date(dateA.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                        if (typeof dateB === 'string') dateB = sfUtil.formatDate(dateB); //new Date(dateB.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                        if (dateA !== dateB) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isValidEmail: function(errorMsg) {
                        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
                        if (!re.test(element)) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isValidMinSec: function(errorMsg) {
                        var re = /^\d*\.?\d{1,2}$/;
    
                        if (!re.test(element)) putErrorMsg(fieldName, errorMsg);
    
                        return this;
                    },
                    isValidDate: function(errorMsg) {
                        var dateString = element;
    
                        if (dateString && dateString.trim().length > 0) {
                            var _valid = true;
                            // First check for the pattern
                            if (!/^\d{1,2}\-\d{1,2}\-\d{4}$/.test(dateString))
                                _valid = false;
    
                            // Parse the date parts to integers
                            var parts = dateString.split("-");
                            var day = parseInt(parts[0], 10);
                            var month = parseInt(parts[1], 10);
                            var year = parseInt(parts[2], 10);
    
                            // Check the ranges of month and year
                            if ((_valid) && (year < 1000 || year > 3000 || month == 0 || month > 12))
                                _valid = false;
    
                            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    
                            // Adjust for leap years
                            if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                                monthLength[1] = 29;
    
                            // Check the range of the day
                            _valid = _valid && day > 0 && day <= monthLength[month - 1];
    
                            if (!_valid) putErrorMsg(fieldName, errorMsg);
                        }
                    },
                    isValidDateInsp: function(errorMsg) {
                        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        var dateString = element.split("/");
                        var _valid = true;
                        if (isNaN(dateString[0]) == true || dateString[0] < 0 || dateString[0] > 31) {
                            _valid = false;
                        }
                        if (isNaN(dateString[2]) == true || dateString[2] < 1000 || dateString[2] > 3000) {
                            _valid = false;
                        }
                        if (months.indexOf(dateString[1]) < 0) {
                            _valid = false;
                        }
    
                        if (!_valid) putErrorMsg(fieldName, errorMsg);
    
                    },
                    isZero: function(errorMsg) {
                        var _valid = true;
                        if (element == 0) {
                            _valid = false;
                        }
                        if (!_valid) putErrorMsg(fieldName, errorMsg);
                    },
                    isValidDateTime: function(errorMsg) {
                        var dateTimeString = element;
                        var _valid = false;
                        try {
                            var matches = dateTimeString.match(/^(\d{2})\-(\d{2})\-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/);
                            if (matches === null) {
                                // invalid
                            } else {
                                // now lets check the date sanity
                                var year = parseInt(matches[3], 10);
                                var month = parseInt(matches[2], 10) - 1; // months are 0-11
                                var day = parseInt(matches[1], 10);
                                var hour = parseInt(matches[4], 10);
                                var minute = parseInt(matches[5], 10);
                                var second = parseInt(matches[6], 10);
                                var date = new Date(year, month, day, hour, minute, second);
                                if (date.getFullYear() !== year ||
                                    date.getMonth() != month ||
                                    date.getDate() !== day ||
                                    date.getHours() !== hour ||
                                    date.getMinutes() !== minute ||
                                    date.getSeconds() !== second
                                ) {
                                    // invalid
                                } else {
                                    // valid
                                    _valid = true;
                                }
                            }
                        } catch (e) {
                            // invalid
                        }
                        if (!_valid) putErrorMsg(fieldName, errorMsg);
                    },
                    isThereSomeCharacter: function(_arrayChars, errorMsg) {
                        var _valid = true;
                        if (element && _arrayChars) {
                            for(var i=0; i<_arrayChars.length; i++) {
                                
                                if (element.includes(_arrayChars[i])) {
                                    _valid = false;
                                    break;
                                }
                            }
                        }
    
                        if (!_valid) putErrorMsg(fieldName, errorMsg);
                    }
    
                }
            },
            addErrorMsg: function(fieldName, errorMsg) {
                putErrorMsg(fieldName, errorMsg);
            },            
            setErrors: function(errors) {
                Object.keys(errors).forEach(e => {
                    console.log(e, _errors[e]);
                    // if (_errors[e]) {
                    _errors[e] = errors[e];
                    // }
                })
            },
            getErrors: function() {
                return _errors;
            },
            //run fn if there are errors
            errors: function(fn) {
                if (Object.keys(_errors).length > 0) fn();
    
                return this;
            },
            //run fn if there aren't errors
            success: function(fn) {
                if (Object.keys(_errors).length === 0) fn();
    
                return this;
            },
            isSuccess: function() {
                return (Object.keys(_errors).length === 0);
            }
        }
    }])    

    //Write in this service all the utility about the GEO coordinate
    .service('iframeComunication', function () {
        return {
            sendMessage: function(_message, _endpointParam)  {
                var _endpoint = "*"
                if (_endpointParam) {
                    _endpoint = _endpointParam
                }

                window.parent.postMessage(_message, _endpoint);        
            }
        }
    })

    .service('featureService', function(expanseConst, utility) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem(expanseConst.userCacheName));
        var _listFeatureByKey = utility.getArrayByField(_identity.listFeature, 'key');
        
        return {
            isEnabled: function(_featureKey) {
                var _isEnabled = false;
                var _feature = _listFeatureByKey[_featureKey];
                var _userId = _identity.id;

                if (_feature!=undefined) {
                    if (_feature.featureDetails && _feature.featureDetails.length>0) {
                        _feature.featureDetails.some(d => {
                            if (d.userId == _userId) {
                                _isEnabled = true;
                                return true;
                            }
                        })
                        // var _find = _feature.featureDetails.find(d => d._userId == _userId);
                        
                        // _isEnabled = _find!=undefined;

                    } else {
                        _isEnabled = true;
                    }
                }
                
                return _isEnabled;
            }
        }
    })

    .service('rolesService', function(expanseConst, utility) {
        //Get the information about the user logged
        var _identity = JSON.parse(localStorage.getItem(expanseConst.userCacheName));
        
        return {
            hasRole: function(_rolesTypeKey) {
                return _identity.rolesType == _rolesTypeKey;
            }
        }
    })
