//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js'); 

expanseLiteApp.factory('aiBuilderFactory', ['assetDataServices', 'assetServices', 'iframeComunication', 'utility', 'expanseConst', 'myAlert', '$http', '$q','$interval', '$rootScope', 
    function(assetDataServices, assetServices, iframeComunication, utility, expanseConst, myAlert, $http,$q, $interval, $rootScope) {
        
        var data = this;
        var list_point = [];
        var isMoving = false;
        var events = false;
        var canvas_size;

        var _tiltAngleInfoMessage = {
            tiltDegrees: undefined
        }

        function _drawOnImagae(image_name, canvas_name) {
            if (canvas_name=='canvas'){
                var img = document.getElementById(image_name);

            }

            else{
                var img = document.getElementById(image_name);
            }
            
            var canvas = document.getElementById(canvas_name);
            if (canvas.width ==0){
                canvas.width = img.width;
                canvas.height = img.height;
                console.log(canvas.width,canvas.height )
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, img.width, img.height);
            }


            var rectangles = [];


            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for(var i = 0; i < rectangles.length; i++) {
                var rect = rectangles[i];
                ctx.beginPath();
                ctx.rect(rect.startX, rect.startY, rect.endX, rect.endY);
                ctx.stroke();
                ctx.closePath();
            }
        
            if (events == false){
                canvas.addEventListener("mousemove", function (e) {
                    events = true;
                    var pos = getMousePos(canvas, e);
                    findxy('move',pos)
                }, false);
                canvas.addEventListener("mousedown", function (e) {
                    var pos = getMousePos(canvas, e);
                    findxy('down', pos)
                }, false);
                canvas.addEventListener("mouseup", function (e) {
                    findxy('up', e)
                    _sendTiltInfoMessage() 
                    _tiltAngleInfoMessage.tiltDegrees =  data.list;
                }, false);
                canvas.addEventListener("mouseout", function (e) {
                }, false);
            }
        

            function _sendTiltInfoMessage() {

                var _msg = {
                    command: 'updateTiltAngleFrame', 
                    data:_tiltAngleInfoMessage
                }
                iframeComunication.sendMessage(JSON.stringify(_msg));
            }
        }
        return {

                data: data,
                clearScreen: function(image_name, canvas_name, events_value, _success, _error){
                    list_point = [];
                    events = events_value;
                    isMoving = false;
                    var canvas = document.getElementById(canvas_name);
                    console.log(canvas.width,canvas.height )
                    var ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                    canvas.width = 0;
                    canvas.height = 0;

                },

                analyseImage: function(image_name, canvas_name, _success, _error){
                    if (canvas_name=='canvas'){
                        var img = document.getElementById(image_name);
            
                    }
                    else{
                        var img = document.getElementById(image_name);
                    }            
                    
                    var canvas = document.getElementById(canvas_name);
                    if (canvas.width ==0){
                        canvas.width = img.width;
                        canvas.height = img.height;
                        console.log(canvas.width,canvas.height )
                        canvas_size = [canvas.width,canvas.height];
                        var ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, img.width, img.height);
                    }

                    var fl_angle = 0;
                    var sl_angle = 0;
            
                    isMoving = false;
                    var index = 0 
            
                    list_point = [];
                    function getMousePos(canvas, evt) {
                        var rect = canvas.getBoundingClientRect();
                        return {
                        x: evt.clientX - rect.left,
                        y: evt.clientY - rect.top
                        };
                    }
                    function radians_to_degrees(radians)
                    {
                        var pi = Math.PI;
                        return radians * (180/pi);
                    }
                    function angleOf(x1, x2, y1, y2) {
                        var deltaY = (y1 - y2);
                        var deltaX = (x2 - x1);
                        var result = radians_to_degrees(Math.atan2(deltaY, deltaX));
                        return result
                    }
                    function findxy(res, e) {

                        var canvas = document.getElementById('canvas');
                        var ctx = canvas.getContext('2d');

                        if (res == 'down') {
                            console.log(res, e)
                            if (list_point.length == 3 ){
                                var closest = list_point.reduce(function(prev, curr) {
                                    return (Math.sqrt(Math.pow((curr['x'] - e['x']),2) + Math.pow((curr['y'] - e['y']),2)) < Math.sqrt(Math.pow((prev['x'] - e['x']),2) + Math.pow((prev['y'] - e['y']),2))  ? curr : prev);
                                });
        
                                index = list_point.findIndex(p => p.x === closest.x && p.y === closest.y);
                                list_point[index] = e;
                                if (index == 1){
                                    list_point[0].x = list_point[1].x
                                }
                                if (index == 0){
                                    list_point[1].x = list_point[0].x
                                }
                                isMoving = true;
        
                            return
                            }
            
                            if (list_point.length >2){
                                return
                            }
                            ctx.beginPath();
                            if (list_point.length == 1){
                                ctx.arc(list_point[0].x, e.y, 5, 0, 2 * Math.PI, false);
                                ctx.fillStyle = '#00B9E6';
                                ctx.fill();
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();      
                                list_point.push({x:list_point[0].x, y:e.y}); 
                            } 
                            else{
                                ctx.arc(e.x, e.y, 5, 0, 2 * Math.PI, false);
                                ctx.fillStyle = '#00B9E6';
                                ctx.fill();
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();      
                                list_point.push(e);          
                            }    
                            if (list_point.length == 2){
                                
                                ctx.beginPath();
                                ctx.strokeStyle = "red";
                                ctx.lineWidth = 5;
                                ctx.moveTo(list_point[0].x, list_point[0].y);
                                ctx.lineTo(list_point[1].x, list_point[1].y);
                                list_point.push({x:list_point[0].x + 50, y:list_point[1].y})
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();
                                //draw third point
                                
                                ctx.beginPath();
                                ctx.strokeStyle = "#00B9E6";
                                ctx.lineWidth = 2;
                                ctx.moveTo(list_point[1].x, list_point[1].y);
                                ctx.lineTo(list_point[2].x, list_point[2].y);
                                ctx.arc(list_point[2].x , list_point[2].y , 5, 0, 2 * Math.PI, false);
                                ctx.fillStyle = '#00B9E6';
                                ctx.fill();
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();
                                //-----


                                ctx.lineTo(list_point[2].x, list_point[2].y);
                            
                                fl_angle = angleOf(list_point[1].x, list_point[0].x, list_point[1].y, list_point[0].y);
                                sl_angle = angleOf(list_point[1].x, list_point[2].x, list_point[1].y, list_point[2].y);
                                
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath(); 

                                data.list = (fl_angle- sl_angle).toFixed(2);

                                var dx1=list_point[0].x-list_point[1].x;
                                var dy1=list_point[0].y-list_point[1].y;
                                var dx2=list_point[2].x-list_point[1].x;
                                var dy2=list_point[2].y-list_point[1].y;
                                var a1=Math.atan2(dy1,dx1);
                                var a2=Math.atan2(dy2,dx2);

                                ctx.beginPath();
                                ctx.moveTo(list_point[1].x,list_point[1].y);
                                ctx.arc(list_point[1].x,list_point[1].y,20,a1,a2);
                                ctx.closePath();
                                ctx.fillStyle="red";
                                ctx.globalAlpha=0.5;
                                ctx.fill();
            
            
                            }       
                        }
                        if (res == 'up' && isMoving == true) {
                            isMoving = false;
                        }
            
                        if (res == 'move' && isMoving == true) {
                            var canvas = document.getElementById('canvas');
                            var ctx = canvas.getContext('2d');

                            ctx.clearRect(0, 0, img.width, img.height);
                            
                            list_point[index] = e;
                            if (index == 1){
                                list_point[0].x = list_point[1].x
                            }
                            if (index == 0){
                                list_point[1].x = list_point[0].x
                            }
                            ctx.strokeStyle = "#00B9E6";
                            for (var i = 0; i < list_point.length; i++) {
                                ctx.beginPath();
                                ctx.arc(list_point[i].x,list_point[i].y, 5, 0, 2 * Math.PI, false);
                                ctx.fillStyle = '#00B9E6';
                                ctx.fill();
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();
                            }     

                            if (list_point.length == 3){
                                ctx.beginPath();
                                ctx.strokeStyle = "red";
                                ctx.lineWidth = 5;
                                ctx.moveTo(list_point[0].x, list_point[0].y);
                                ctx.lineTo(list_point[1].x, list_point[1].y);
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath(); 

                                ctx.beginPath();
                                ctx.strokeStyle = "#00B9E6";
                                ctx.lineWidth = 2;
                                ctx.moveTo(list_point[1].x, list_point[1].y);
                                ctx.lineTo(list_point[2].x, list_point[2].y);
                                ctx.fillStyle = '#00B9E6';
                                ctx.fill();
                                ctx.stroke(); // outline the shape that's been describeds
                                ctx.closePath();
                                //-----
                                fl_angle = angleOf(list_point[1].x, list_point[0].x, list_point[1].y, list_point[0].y);
                                sl_angle = angleOf(list_point[1].x, list_point[2].x, list_point[1].y, list_point[2].y);
                                
                                data.list = (fl_angle- sl_angle).toFixed(2);
                                // var vertical_angle = (90 - fl_angle).toFixed(2);
                                var dx1=list_point[0].x-list_point[1].x;
                                var dy1=list_point[0].y-list_point[1].y;
                                var dx2=list_point[2].x-list_point[1].x;
                                var dy2=list_point[2].y-list_point[1].y;
                                var a1=Math.atan2(dy1,dx1);
                                var a2=Math.atan2(dy2,dx2);

                                ctx.beginPath();
                                ctx.moveTo(list_point[1].x,list_point[1].y);
                                ctx.arc(list_point[1].x,list_point[1].y,20,a1,a2);
                                ctx.closePath();
                                ctx.fillStyle="red";
                                ctx.globalAlpha=0.5;
                                ctx.fill();

                            }
                        } 
                    }
            
                    if (events == false){
                        canvas.addEventListener("mousemove", function (e) {
                            events = true;
                            var pos = getMousePos(canvas, e);
                            findxy('move',pos)
                        }, false);
                        canvas.addEventListener("mousedown", function (e) {
                            var pos = getMousePos(canvas, e);
                            findxy('down', pos)
                        }, false);
                        canvas.addEventListener("mouseup", function (e) {
                            findxy('up', e)
                            // data.list=image_angle;
                            $rootScope.$broadcast('context-changed')
                        }, false);
                        canvas.addEventListener("mouseout", function (e) {
                        }, false);
                    }                    
                    data.list = (fl_angle- sl_angle).toFixed(2);
            
                },

                getTitlAngleImageUrl: function(_facilityDataDetailsId, _success, _error) {
                    assetDataServices.getFacilityDataDetailsById(_facilityDataDetailsId, _assetDataDetail => {
                        if (_assetDataDetail) {
                            assetDataServices.getAssetDataById(_assetDataDetail.facilityDataId, _assetData => {
                                                    
                                assetServices.getAsset(_assetData.facilityId, _asset => {
                                    _success(expanseConst.cfAssetDataUrl+'/customer_'+_asset.companyId+'/asset_'+_assetData.facilityId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/'+_assetDataDetail.fileName)
                                    setTimeout(function(){
                                        _drawOnImagae('is_selected_iframe', 'canvas_iframe');         
                                    }, 5);                              
                                
                                },
                                _err => {
                                    _error(_err);
                                    myAlert("Error: " + _err.data.message, 'danger');
                                    myThrow(_err);
                                });
                            },
                            _err => {
                                _error(_err);
                                myAlert("Error: " + _err.data.message, 'danger');
                                myThrow(_err);
                            });
                        }
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        _error(_err);
                    })
                },


                saveTiltAngleImage: function(_facilityDataDetailsId, _success, _error){
                    assetDataServices.getFacilityDataDetailsById(_facilityDataDetailsId, _assetDataDetail => {
                        if (_assetDataDetail) {
                            assetDataServices.getAssetDataById(_assetDataDetail.facilityDataId, _assetData => {
                                                    
                                assetServices.getAsset(_assetData.facilityId, _asset => {
                                    var urlImage = expanseConst.cfAssetDataUrl+'/customer_'+_asset.companyId+'/asset_'+_assetData.facilityId+'/structure_'+_assetData.structureId+'/asset_data_'+_assetDataDetail.facilityDataId+'/images/'+_assetDataDetail.fileName;                             
                                    
                                    var Data = {
                                        'list_point': list_point,
                                        'canvas_size': canvas_size,
                                        'facilityDataDetailsId': _facilityDataDetailsId,
                                        'urlImage': urlImage,
                                        'angle':data.list

                                    };
                                    
                                    assetDataServices.saveTiltAngleImage(Data, _res => {
                
                                        myAlert("Processing Complete", 'info');                    
                                    }, _err => {
                                        myAlert("Error: " + _err.data["Error Message"], 'danger');
                                        myThrow(_err);        
                                    })

                                },
                                _err => {
                                    _error(_err);
                                    myAlert("Error: " + _err.data.message, 'danger');
                                    myThrow(_err);
                                });
                            },
                            _err => {
                                _error(_err);
                                myAlert("Error: " + _err.data.message, 'danger');
                                myThrow(_err);
                            });
                        }
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        _error(_err);
                    })

                }          

            } 




    }]);