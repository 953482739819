//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("projectServices", ['buildUrl', 'serviceUtility', function(buildUrl, serviceUtility) {

        var _functions  = {
            getProjectsByCompanyId: function(_companyId, _success, _error) {
                var _url = buildUrl( '/project/getProjects/byCompanyId/' + _companyId);
                var _errorMsg =  "I wasn't able to get the Projects of the company: '"+_companyId+"'";

                serviceUtility.runGet("getProjectsByCompanyId", _url, _errorMsg, _res => _success(_res.listProject), _error);
            },
            getProjectFacilitiesByProjectId: function(_projectId, _success, _error) {
                var _url = buildUrl( '/project/getProjectFacilities/byProjectId/' + _projectId);
                var _errorMsg =  "I wasn't able to get the Assets of the project: '"+_projectId+"'";

                serviceUtility.runGet("getProjectFacilitiesByProjectId", _url, _errorMsg, _res => _success(_res.listProjectFacility), _error);
            },
            getProjectStatusTypes: function(_success, _error) {
                var _url = buildUrl( '/project/getProjectStatusTypes');
                var _errorMsg =  "I wasn't able to get the project status types";

                serviceUtility.runGet("getProjectStatusTypes", _url, _errorMsg, _res => _success(_res.listProjectStatusType), _error);
            },
            getProjectTypes: function(_success, _error) {
                var _url = buildUrl( '/project/getProjectTypes');
                var _errorMsg =  "I wasn't able to get the project types";

                serviceUtility.runGet("getProjectTypes", _url, _errorMsg, _res => _success(_res.listProjectType), _error);
            },
            saveProject: function(_userId, _project, _success, _error) {
                var _url = buildUrl( '/project/postProject/' + _userId);
                var _errorMsg = "I wasn't able to save the Project";

                serviceUtility.runPost("saveModel3DById", _url, _project, _errorMsg, _res => _success(_res.projectDto), _error);
            },
            getProjectsByFacilityId: function(_facilityId, _success, _error) {
                var _url = buildUrl( '/project/getProjects/byFacilityId/' + _facilityId);
                var _errorMsg =  "I wasn't able to get the projects of the facilityId: '"+_facilityId+"'";

                serviceUtility.runGet("getProjectsByFacilityId", _url, _errorMsg, _res => _success(_res.listProject), _error);
            },
            getProjectFacilitiesByFacilityId: function(_facilityId, _success, _error) {
                var _url = buildUrl( '/project/getProjectFacilities/byFacilityId/' + _facilityId);
                var _errorMsg =  "I wasn't able to get the projects of the facilityId: '"+_facilityId+"'";

                serviceUtility.runGet("getProjectFacilitiesByFacilityId", _url, _errorMsg, _res => _success(_res.listProjectFacility), _error);
            },
            saveProjectFacility: function(_userId, _projectFacility, _success, _error) {
                var _url = buildUrl( '/project/postProjectFacility/' + _userId);
                var _errorMsg = "I wasn't able to save the Project Facility";

                serviceUtility.runPost("saveProjectFacility", _url, _projectFacility, _errorMsg, _res => _success(_res.projectFacility), _error);
            },

        };

        return _functions;
    }]);
