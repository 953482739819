//Write in this controller all the code about AssetData
//
//******************************************* REMEMBER *************************************************
//    EACH CONTROLLER MUST CONTAIN ALL THE PROPERTIES AND FUNCTION ABOUT A SINGLE FUNCTIONAL ENTITY
//------------------------------------------------------------------------------------------------------
//- Add to "$scope" only functions/properties that you need to call from other controllers
//- Add to "this" only functions/properties that you need to call from the View 
//      but you don't want add to "$scope"
//- All the functions/properties that won't be called from the View and from other controllers
//      must be private.
//- All the parameters and variables into a function must have "_" prefix
//- All the function that required to access the backend must be write into a service
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myAlert to show messages to the user
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.controller('assetDataUploadCtrl',
    ['$scope', '$stateParams', 'Scopes', 'myLog', 'myAlert', 'assetDataUploadServices','customerServices', 'assetServices', 
    'expanseConst', 'utility', 'fsValidator', '$sce',
        function ( $scope, $stateParams, Scopes, myLog, myAlert, assetDataUploadServices, customerServices, assetServices,  
           expanseConst, utility, fsValidator, $sce) {

            //Get the information about the user logged
            var _identity = JSON.parse(localStorage.getItem('exlite.identity'));
            var _this = this;

            //----------------------------------------------------------------------------- Variable Section Start ------------------------------------------------------------------------------------------
            var _controllerName = "assetDataUploadCtrl";
            var _customerId;
            var _customer;
            var _assetId;
            var _asset;
            var _noTag = {
                key: 'none',
                value: 'Image Without Tag',
                id: 0
            }

            _this.uploadData = [];

            _this._fileInfo = {
                size: 0,
                size_int: 0,
                number: 0
            }

            _this.perUploaded = 0;
            _this.speedUpload = '';
            _this.sizeUploaded = 0;
            _this.timeLeft= '';

            _this.timeStamp = Date.now();
            _this.prevProgress = 0;
            
            _this.identity = _identity;
            _this.expanseConst = expanseConst;              //Expose expanse's consts service
            _this.listAssetData = [];
            _this.listAssetDataGroupedByFiletype = [];
            _this.listAssetDataFileType = [];
            _this.listAssetDataFileTypeByKey = [];
            _this.zoomActive = false;
            _this.imgIndex = 0;
            _this.imgIndex2 = 0;
            _this.nextPageNum = 1;
            _this.views = {
                singleImage: 'singleImage',
                multiImage: 'multiImage',
                singlePhotogrammetry: 'singlePhotogrammetry',
                doublePhotogrammetry: 'doublePhotogrammetry',
                compassViewer: 'compassViewer',
                thermalImage: 'thermalImage',
                doubleImage: 'doubleImage',
                assetMapper: 'assetMapper',
                reviewer3d: 'reviewer',
                cityscale3d: 'cityscale',
                assetDataByTags: 'assetDataByTags',
                assetDataMethane: 'assetDataMethane',
                inspectionReport: 'inspectionReport',
                image360: 'image360',
                image360SplitScreen: 'image360SplitScreen',
                matterport: 'matterport',
                naviswork: 'naviswork',
                kml: 'kml',
                azimuth: 'azimuth',
                dwg: 'dwg',
                truView: 'truView',
                userNet3D: 'userNet3D',
                spinModel: 'spinModel',
                nokiaReport: 'nokiaReport',
                autodeskViewer: 'autodeskViewer',
                inventory: 'inventory',
                checklist: 'checklist',
                riskManagement: 'riskManagement',
            };
            _this.viewUsedByAssetDataType = {
                singleImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared'],
                multiImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared'],
                singlePhotogrammetry: ['3d_photogrammetry'],
                doublePhotogrammetry: ['3d_photogrammetry'],
                compassViewer: ['static_imagery', 'thermal_imagery'],
                thermalImage: ['thermal_imagery'],
                doubleImage: ['static_imagery', 'thermal_imagery', 'video', 'audio', 'infrared'],
                assetMapper: [],
                reviewer3d: [],
                cityscale3d: [],
                assetDataByTags: ['static_imagery'],
                assetDataMethane: ['methane_doc'],
                inspectionReport: ['inspection_report'],
                kml: ['kml','kmz'],
                kmz: ['kmz','kml'],
                autodeskRevit: ['autodesk-revit'],
                dwg: ['dwg'],
                matterport: ['matterport'],
                spinModel: ['spin-model'],
                checklist: ['checklist'],
                image360: ['360-image'],
                image360SplitScreen: ['360-image']
            };

            _this.assetDataListViews = {
                list: 'list',
                group: 'group'
            };
            _this.assetDataTypes = {
                imagery: 'static_imagery',
                thermal: 'thermal_imagery',
                video: 'video',
                photogrammetry: '3d_photogrammetry',
                document: 'documents',
                report: 'report',
                methaneDoc: 'methane_doc',
                inspectionReport: 'inspection_report',
                image360: '360-image',
                audio: 'audio',
                infrared: 'infrared',
                kml: 'kml',
                kmz: 'kmz',
                dwg: 'dwg',
                matterport: 'matterport',
                spinModel: 'spin-model',
                lidar: 'lidar',
                autodeskRevit: 'autodesk-revit',
                checklist: 'checklist',
            }


            _this.serverLocation = [

                {'value':'Ohio', 'key':'us-east-2'},                
                {'value':'N. California', 'key':'us-west-1'},
                {'value':'Oregon', 'key': 'us-west-2'},
                {'value':'Cape Town', 'key': 'af-south-1'},
                {'value':'Hong Kong', 'key': 'ap-east-1'},
                {'value':'Mumbai', 'key':'ap-south-1'},
                {'value':'Osaka', 'key': 'ap-northeast-3'},
                {'value':'Seoul', 'key': 'ap-northeast-2'},
                {'value':'Singapore', 'key': 'ap-southeast-1'},
                {'value':'Sydney', 'key': 'ap-southeast-2'},
                {'value':'Tokyo', 'key': 'ap-northeast-1'},
                {'value':' Canada', 'key': 'ca-central-1'},
                {'value':'China', 'key': 'cn-northwest-1'},
                {'value':'Frankfurt', 'key': 'eu-central-1'},
                {'value':'Dublin', 'key': 'eu-west-1'},
                {'value':'London', 'key': 'eu-west-2'},
                {'value':'Milan', 'key':'eu-south-1'},
                {'value':'Paris', 'key': 'eu-west-3'},
                {'value':' Stockholm', 'key':'eu-north-1'},
                {'value':' Bahrain', 'key': 'me-south-1'},
                {'value':'Sao Paulo', 'key':'sa-east-1'},
            ]
            

            _this.infoTooltip = 
                '<html>' +
                '<head>' +
                '</head>' +
                '<body>' +
                '<div style="color: #333; opacity: 1;">' +
                    '<div>'+ '<b>' + 'We support the following file formats' + '</b>' + '</div>' +
                    '<br>' +
                    '<div>'+ '- 360° Imagery: JPEG' + '</div>' +
                    '<div>'+ '- Audio: MP3' + '</div>' +
                    '<div>'+ '- AutoCAD drawing: DWG' + '</div>' +
                    '<div>'+ '- BIM Revit: RVT' + '</div>' +
                    '<div>'+ '- CAD Model: OBJ, DAE, STL' + '</div>' +
                    '<div>'+ '- Documents: PDF, Word - DOC, DOCX, Excel - XLS, XLXS, CSV, PowerPoint - PPT, PPTX, TXT ' + '</div>' +
                    '<div>'+ '- Gas Detection (Laser, Sniper) data' + '</div>' +
                    '<div>'+ '- Infrared: IRB' + '</div>' +
                    '<div>'+ '- Map data: KML, KMZ' + '</div>' +
                    '<div>'+ '- Naviswork: NWD, NWF, NWC' + '</div>' +
                    '<div>'+ '- Photogrammetry: Pix4D .ZIP, Textured Mesh, Orthomosaic' + '</div>' +
                    '<div>'+ '- Point Cloud: LAZ, PLY' + '</div>' +
                    '<div>'+ '- Lidar: LAS, LGS, LGSX, TVG, E57' + '</div>' +
                    '<div>'+ '- Satellite Imagery ' + '</div>' +
                    '<div>'+ '- Schematic: JPEG, JPG, PNG' + '</div>' +
                    '<div>'+ '- Static Imagery: JPEG, JPG, PNG, TIFF' + '</div>' +
                    '<div>'+ '- Thermal Imagery: JPEG, PNG, TIFF' + '</div>' +
                    '<div>'+ '- Video: AVI, MP4, MTS, WMV' + '</div>' +
                    '<div>'+ '- Other: XML' + '</div>' + 
                '</div>' +
                '</body>' +
                '</html>';
            
            _this.infoEmailTooltip = 
                '<html>' +
                '<head>' +
                '</head>' +
                '<body>' +
                '<div style="color: #333;">' +
                '<div>' + 'The notification will be sent to the email associated to the user profile'  + '</div>' +
                '</div>' +
                '</body>' +
                '</html>';

            _this.servereInfoTooltip = 
                '<html>' +
                '<head>' +
                '</head>' +
                '<body>' +
                '<div style="color: #333; width: 200%">' +
                '<div>' + 'Select the server in the nearest region to you to optmize the upload speed. If no specific server is chosen, the default server is Frankfurt'  + '</div>' +
                '</div>' +
                '</body>' +
                '</html>';

            // _this.infoEmailTooltip = 
            //     'The notification will be sent to the email associated to the user profile';

            _this.makeUL = function(array) {
                // Create the list element:
                var list = "<li>" + array.join("</li><li>") + "</li>";
            
                // Finally, return the constructed list:
                return list;
            }

            var trustedFormat = {};
            _this.getPopoverContent = function(content) {
                return trustedFormat[content] || (trustedFormat[content] = $sce.trustAsHtml(content)); 
            }

            var trustedEmail = {};
            _this.getPopoverContentEmail = function(email) {
                return trustedEmail[email] || (trustedEmail[email] = $sce.trustAsHtml(email)); 
            }

            _this.viewShown = undefined;
            _this.oldViewShown;

            _this.autoAllocation = {
                value: false
            };
            _this.advancedConfiguration = {
                value: false
            };
            _this.checklistUpload = {
                value: false
            };
            _this.emailNotification = {
                value: false
            };
            //----------------------------------------------------------------------------- Variable Section End ------------------------------------------------------------------------------------------

        
            this.init = function (_isIFrame) {           
                //Initialization Function to retrieve the main info regarding customer/asset/assetData     
                var _ctrl = Scopes.get(_controllerName); 

                if (_ctrl) {
                    $scope.assetMapperMessageRef = _ctrl.assetMapperMessageRef;
                }

                Scopes.store(_controllerName, $scope);

                if (!_isIFrame) {
                    if ($stateParams.customer_id && $stateParams.asset_id) {
                        _customerId = $stateParams.customer_id;
                        _assetId = $stateParams.asset_id;
    
                        utility.callFunctionEveryTime("$('#header').get(0)", 200, function() {
                            Scopes.get('headerCtrl').customer_id = _customerId;
                            Scopes.get('headerCtrl').asset_id = _assetId;    
                        })
                    }
    
                    customerServices.getCustomer(_customerId, _res => {
                        _customer = _res;
                        _this.customer = _res;
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })
    
                    
                    assetServices.getAsset(_assetId, _res => {
                        _asset = _res;
                        _this.asset = _res;
                        _this.assetTypeId = _asset.facilityTypeId;

                        //Get all the file types uploadable linked with facility_data_file_type
                        //Them will be used to get the file type icon 
                        assetDataUploadServices.getFileUploadTypes(_res => {
                            _this.listFileTypeByKey = utility.getArrayByField(_res, 'key');
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);        
                        })

                        //Get the list of company's PLF for Generation
                        //If no PLF availbale for the customer, Inspection2's will be dislpayed
                        assetDataUploadServices.getPlfFunctions(_customerId, 'Generation', _asset.facilityTypeId, _res => {
                            _this.listGeneration = _res;                     
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);        
                        })

                        //Get the list of company's PLF for Comparison
                        //If no PLF availbale for the customer, Inspection2's will be dislpayed
                        assetDataUploadServices.getPlfFunctions(_customerId, 'Comparison', _asset.facilityTypeId, _res => {
                            _this.listComparison = _res;                     
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);        
                        })

                        //Get the list of company's components runnable by AI Machine
                        //If the company doesn't have any, I'll get the same about SKY-FUTURES
                        assetDataUploadServices.getAiComponent(_customerId, _asset.facilityTypeId, _res => {
                            _this.listComponentAi = _res;
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);        
                        })

                        //Get the list of company's anomalies runnable by AI Machine
                        //If the company doesn't have any, I'll get the same about SKY-FUTURES
                        assetDataUploadServices.getAiAnomaly(_customerId, _asset.facilityTypeId, _res => {
                            _this.listAnomalyAi = _res;
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);        
                        })


                        assetServices.getAssetSourceTypes(_res => {
                            _this.listAssetSourceType = _res;
                        }, _err => {
                            myAlert("Error: " + _err.data.message, 'danger');
                            myThrow(_err);    
                        })

                        //in the case of restaarting
                        if (parseInt($stateParams.facility_data_id_selected) != 0){

                            assetDataUploadServices.getMissingData(parseInt($stateParams.facility_data_id_selected), _res => {
                                _this.listMissingData = _res;
                            }, _err => {
                                myAlert("Error: " + _err.data.message, 'danger');
                                myThrow(_err);        
                            })
                        }

                        

                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })
    
                    assetServices.getAssetStructures(_assetId, _res => {
                        _this.listAssetStructure = _res;
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                    })

                }

                assetDataUploadServices.getAssetDataDetailsTagTypes(_res => {
                    //Function to setup the asset tag type    
                    _res.forEach(e => e.id = 1)
                    _this.listAssetDataDetailsTagTypes = _res;

                    _this.listAssetDataDetailsTagTypes.push({id:_noTag.id, key:_noTag.key, value:_noTag.value})
                    }, _err => {
                        myAlert("Error: " + _err.data.message, 'danger');
                        myThrow(_err);    
                })


                _getAssetDataFileTypes(_res => {
                    // Retrieve Data File Type
                    _this.listAssetDataFileType = _res;
                    _this.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');

                });


                assetServices.getAssetTypes(_res => {
                    // Retrieve Asset Type
                    _this.listAssetTypeByKey = utility.getArrayByField(_res, 'id');
    
                }, _err => {
                    _error ? _error(_err) : null;
                    myAlert("Error: " + _err.data.message, 'danger');
                });

                _this.uploadStatus = 'upload';               
            }

            _this.getAsset = function () {
                // Retrieve Aasset
                return _asset;
            }

            function _getAssetDataFileTypes(_success, _error) {
                var _array = [];

                assetDataUploadServices.getAssetDataFileTypes(_res => {
                    _success ? _success(_res) : null;

                    myLog("Asset data file types list:", _res);
                }, _err => {
                    _error ? _error(_err) : null;

                    myAlert("Error: " + _err.data.message, 'danger');
                    myThrow(_err);
                });
            }

            function _validationItems(_type, _item) {
                var v = fsValidator.newInstance();

                switch (_type) {
                    case 'upload-first-page':
                        v.validate(_item.structureId, 'structureId').isUndefined("This field can't be empty")
                        break;
                }

                _item.errors = v.getErrors();

                return v;

            }            

            function _validationUploadDataFirstPage(_firstPage) {
                // Function to Validate the Element on the popup page
                var _ret = false;

                _validationItems('upload-first-page', _firstPage).success(function () {
                    _ret = true;
                }).errors(function () {
                    _ret = false;
                })

                return _ret
            }


            _this.validationUploadAssetDataFirstPagePopup = function () {
                // Function to Validate the Element on the popup page
                var _ret = _validationUploadDataFirstPage(_this.uploadData);

                return _ret

            }

            const showLoading = function(message) {
                Swal.fire({
                    title: message,
                    html: `
                    <img src="/img/icons/ajax-loading-icon-2.jpg" width="64" />
                    <button type="button" class="btn btn-cancel swl-cstm-btn-cancel" style="color: #0093ee; border: 1px solid #0093ee; background-color: #fff" >Cancel</button>`,
                    showCancelButton: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                    const cancel = document.querySelector('.btn-cancel')
                    cancel.addEventListener('click', () => {
                        Swal.close();
                    })
                    }
                })
             };


            function _conclude_upload(_listComponent, _listPLF, checklistId){
                var unique_fdIDs = [...new Set(_this.listUploadedFile.map(item => item.facility_data_id))];

                //Update the PLF Tables
                assetDataUploadServices.storePlfFunctions(unique_fdIDs, _listComponent, _listPLF, _customerId, _resPlf => {
                }, _err => {
                    // _error ? _error(_err) : null;
                    // myAlert("Error: " + _err.data, 'danger');
                    console.log('Errore Store PLF')
                });

                //all file uploaded -> post processing can start
                _this.uploadProcedureStatus = 'concluded';
                if (_this.emailNotification.value){
                    assetDataUploadServices.postProcessingUpload(_this.listUploadedFile, _identity.login, _identity.companyId, _this.asset.name, _this.customer.name, checklistId, _listPLF, _this.customer.id);
                }
                else{
                    assetDataUploadServices.postProcessingUpload(_this.listUploadedFile, '',  _identity.companyId,  _this.asset.name, _this.customer.name, checklistId, _listPLF, _this.customer.id);
                }
                document.getElementById('circle-three').classList.add('numberCircle');
                document.getElementById('circle-three').classList.remove('numberCircle-unselected');
                _this.speedUpload = 0;       
            }
        


            _this.creationFacilityRecord = function() {
                
                showLoading('Please Wait - Upload is Starting');
                _this.uploadProcedureStatus = 'upload'
                //create the facility data required and associate them to each data       
                _this.file_list = [];
                _this.store_rString = randomString(10, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
                for (var i = 0; i < _this.uploaderFlow.files.length; i++) {
                    var string = makeid(5);    
                    string = string.concat('_prefix_', _this.uploaderFlow.files[i].name);
    
                    _this.file_list[i] = [string,  _this.uploaderFlow.files[i].relativePath];
                    _this.uploaderFlow.files[i].name = string;
                }
    
                // parameter to be sent to the backend for the creation of the facility data and facility data details
                var restartDatasetId;
                var checklistId;
                if (!Boolean.valueOf($stateParams.checklist_flag)){
                    checklistId =0
                }
                else{
                    checklistId = parseInt($stateParams.facility_data_id_selected)
                }

                restartDatasetId = parseInt($stateParams.facility_data_id_selected)

                var Data_t = {    

                    list_files: _this.file_list,
                    Customer: 'customer_' + _customerId,
                    Asset: 'asset_' + _assetId,
                    Component: 'structure_' + _this.uploadData.structureId,
                    Comment: 'experiment',

                    Inspection: 1,
                    Root: _this.store_rString,

                    SourceType: _this.uploadData.sourceTypeKey,
                    Description: _this.uploadData.description,
                    U_id: _identity.id,
                    restartDataset: restartDatasetId
                }

                // Get PLF and Component and Store Required PLF
                var _listPLF = [];
                var _listComponent = [];

                // get all the processes selected to run
                _this.listGeneration.forEach(e => {
                    if (e.isSelected) {_listPLF.push(e.key)}
                })
                _this.listComparison.forEach(e => {
                    if (e.isSelected) {_listPLF.push(e.key)}
                })
                _this.listComponentAi.forEach(e => {
                    if (e.isSelected) {_listComponent.push(e.fddttKey)}
                })
                _this.listAnomalyAi.forEach(e => {
                    if (e.isSelected) {_listComponent.push(e.fddttKey)}
                })


                var error_uploads = 0;

                assetDataUploadServices.prepareUpload(Data_t, _this.uploaderFlow, _res => {
                    swal.close()

                    if (_res[0] == 'list'){
                        //return the list of file to be uploaded
                        _this.listUploadedFile = _res[1];
                    }

                    if  (_res[0] == 'file'){
                        //Find index of specific object using findIndex method.    
                        var objIndex = _this.listUploadedFile.findIndex((obj => obj.name == _res[1]));

                        //Update object's name property.
                        _this.listUploadedFile[objIndex].upload_status = true;
                        
                        //check amount of file to be uploaded
                        var countFalse = _this.listUploadedFile.filter(x => x.upload_status === false).length;

                        _this.perUploaded = Math.round(_this.listUploadedFile.filter(x => x.upload_status === true).length/_this.listUploadedFile.length*100)
                        _this.fileUploaded =_this.listUploadedFile.filter(x => x.upload_status === true).length;

                        _this.sizeUploaded += parseInt(_this.uploaderFlow.files[objIndex].file.size);
                        _this.sizeUploadedText = utility.formatSize(_this.sizeUploaded)

                        var time = Date.now() - _this.timeStamp;
                        var chunk = (parseInt(_this.perUploaded) - parseInt(_this.prevProgress))/100 * _this._fileInfo.size_int;
                        var speed = ((chunk / 1024 / 1024) / (time / 1000)).toFixed(2);
                    
                        _this.timeStamp = Date.now();
                        _this.prevProgress = _this.perUploaded;                    
                        _this.speedUpload = speed;
                
                        console.log('remaining data:', countFalse-error_uploads )

                        if ((countFalse-error_uploads) == 0){
                            
                            _conclude_upload(_listComponent, _listPLF, checklistId);
                        }
                    }                                   
    
                }, _err => {
                    var countFalse = _this.listUploadedFile.filter(x => x.upload_status === false).length;
                    error_uploads = error_uploads +1
                    console.log('remaining data:', countFalse-error_uploads)

                    if ((countFalse-error_uploads) == 0){
                        _conclude_upload(_listComponent, _listPLF, checklistId);
                    }
                });
            } 
            _this.progress_monitoring = function() {            
                console.log('-')
            }


            _this.upload_count_file = function() {            
                //Count number and size of all files
                $scope.file_count_to_upload =  _this.uploaderFlow.files.length;

                if (_this.listMissingData){
                    for (var i = 0; i < _this.uploaderFlow.files.length; i++) {
                        var index = _this.listMissingData.indexOf(_this.uploaderFlow.files[i].name)
                        if (index!= -1){
                            _this.listMissingData.splice(index, 1);
                        }
                    }

                }                

                _this._fileInfo = {
                    size: 0,
                    size_int: 0,
                    number: 0
                }
                if (_this.uploaderFlow ) {
                    _this.uploaderFlow.files.forEach(e => {
                        _this._fileInfo.size += e.size;
                        _this._fileInfo.size_int += e.size;
                        _this._fileInfo.number += 1;
                    })
                }                 
        
                _this._fileInfo.size = utility.formatSize(_this._fileInfo.size)
            }

            _this.fileSize = function(filesize){
                return utility.formatSize(filesize)
            }


            _this.utility = function() {
                //Funcion to retrieve the utilities
                return function() {
                    return utility;
                }
            }

            _this.getIconNameByFileUploadType = function(_name) {
                //Function to get the Icons for the uploaded files
                var _extention = _name.split('.').pop().toLowerCase();
                if (_this.listFileTypeByKey[_extention]){
                    return _this.listAssetDataFileTypeByKey[_this.listFileTypeByKey[_extention].value].imgName
                }
                else{
                    return 'rejected.png'
                }
                
            }
            
            _this.removeNotAcceptedFiles = function() { 
                //Functioin to remove not recognied files

                var confirmedUpload = [];
                for (var i = 0; i < _this.uploaderFlow.files.length; i++) {
                    var _extention = _this.uploaderFlow.files[i].name.split('.').pop().toLowerCase();
                    if (_this.listFileTypeByKey[_extention] && _this.uploaderFlow.files[i].name[0] != '.'){
                        confirmedUpload.push( _this.uploaderFlow.files[i]);
                    }
                }
                _this.uploaderFlow.files = confirmedUpload;
            }
              
            _this.uploadAssetDataNextFromPopup = function() {
                // Function to manage the forward movment on the popup
                // if (_this._fileInfo.number < 1000){
                var ret = _this.validationUploadAssetDataFirstPagePopup();
                if (ret == true){
                    switch (_this.uploadStatus) {
                        case 'upload':

                            document.getElementById('circle-one').classList.add('numberCircle');
                            document.getElementById('circle-one').classList.remove('numberCircle-unselected');

                            document.getElementById('line-one').classList.add('nav-bar-selected');
                            document.getElementById('line-one').classList.remove('nav-bar');

                            if (_this.advancedConfiguration.value) {
                                _this.uploadStatus = 'analysis';
                            }
                            else{
                                _this.uploadStatus = 'results';
    
                                document.getElementById('line-two').classList.add('nav-bar-selected');
                                document.getElementById('line-two').classList.remove('nav-bar');
                                _this.uploadProcedureStatus = 'start';
                                _this.uploadStatus = 'results';
                                _this.removeNotAcceptedFiles();

                            }
                            
                            break;
                        case 'analysis':
                            document.getElementById('circle-two').classList.add('numberCircle');
                            document.getElementById('circle-two').classList.remove('numberCircle-unselected');

                            document.getElementById('line-two').classList.add('nav-bar-selected');
                            document.getElementById('line-two').classList.remove('nav-bar');
                            _this.uploadProcedureStatus = 'start';
                            _this.uploadStatus = 'results';
                            _this.removeNotAcceptedFiles();
                            break;
                    }
                }
                // }
                // else{
                //     console.log('ok')
                //     myAlert("Please do not upload more than 1000 images", 'danger');
                // }
            }

            _this.uploadAssetDataBackFromPopup = function() {
                // Function to manage the back movement on the popup
               

                switch (_this.uploadStatus) {
                    case 'analysis':
                        document.getElementById('circle-one').classList.add('numberCircle-unselected');
                        document.getElementById('circle-one').classList.remove('numberCircle');

                        document.getElementById('line-one').classList.add('nav-bar');
                        document.getElementById('line-one').classList.remove('nav-bar-selected');
                        _this.uploadStatus = 'upload';
                        break;
                    case 'results':

                        document.getElementById('line-two').classList.add('nav-bar');
                        document.getElementById('line-two').classList.remove('nav-bar-selected');
                        
                        if (_this.advancedConfiguration.value) {
                            document.getElementById('circle-two').classList.add('numberCircle-unselected');
                            document.getElementById('circle-two').classList.remove('numberCircle');
                            _this.uploadStatus = 'analysis';
                        }
                        else{
                            document.getElementById('circle-one').classList.add('numberCircle-unselected');
                            document.getElementById('circle-one').classList.remove('numberCircle');
    
                            document.getElementById('line-one').classList.add('nav-bar');
                            document.getElementById('line-one').classList.remove('nav-bar-selected');
                            _this.uploadStatus = 'upload';
                        }

                        // _this.uploadStatus = 'analysis';
                        break;
                }
            }

            _this.setAnalysisConfElementSelected = function(_element) {
                // Function select/deselect element with the checkbox
                //TO SELECT JUST ONE ELEMENT
                // var selectedEle = _this.listComponentAi.filter(a => a.isSelected == true)
                // if (selectedEle.length > 0){
                //     if (selectedEle[0].id == _element.id){
                //         _element.isSelected = !_element.isSelected;  
                //     }
                // }
                // else{
                //     _element.isSelected = !_element.isSelected;  
                // }
                    
                //DEFAULT SITUATION
                _element.isSelected = !_element.isSelected;  
            }
            
            _this.uploadAssetDataPopup = function() {
                // Function to manage the header infoo
                var _headerCtrl = Scopes.get("headerCtrl");
                _headerCtrl.goAssetDataUpload();              
            }           

            function makeid(length) {
                // Function to create a unique ID
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
             }
 
            function randomString(length, chars) {
                // Function to Create a random string for the prefix of the images
                var result = '';
                for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
                return result;
            }

            _this.assetDataReviewScreen = function() {
                // Function to close the upload pages
                var _headerCtrl = Scopes.get("headerCtrl");
                _headerCtrl.enableOptions();
                _headerCtrl.goAssetData();

            }

            _this.removeAllSelectedFile = function(){
                _this.uploaderFlow.files = [];

                _this._fileInfo = {
                    size: 0,
                    number: 0
                }
                
            }


            // advanced confifguration
            _this.changeAdvancedConfiguration = function(){
                _this.advancedConfiguration.value = !_this.advancedConfiguration.value;               
            }

            _this.changeChecklistUpload = function(){
                _this.checklistUpload.value = !_this.checklistUpload.value; 
            }

            _this.changeEmailNotification = function(){
                _this.emailNotification.value = !_this.emailNotification.value;               
            }


    }


]);
