//Write here all the services about Asset
//
//******************************************* REMEMBER *************************************************
//- Use myLog instead of console.log (because it could be enabled or disabled in different environments)
//- Use myHttp service instead of $http one
let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp
    .service("bomServices", ['buildUrl', 'serviceUtility',  function(buildUrl, serviceUtility) {

        var _functions  = {
            getBomByCompanyId: function(_companyId, _success, _error) {
                var _url = buildUrl( '/expanse/bom/byCompanyId/' + _companyId);
                var _errorMsg =  "I wasn't able to get the BOM's Items with company_id: '"+_companyId+"'";

                serviceUtility.runGet("getBomByCompanyId", _url, _errorMsg, _res => _success(_res.listBomDto), _error);
            },
            saveBom: function(_bomItem, _userId, _success, _error) {
                var _url = buildUrl('/expanse/bom/' + _userId);
                var _errorMsg = "I wasn't able to save this BOM item";

                serviceUtility.runPost("saveBom", _url, _bomItem, _errorMsg, _res => _success(_res.bomDto), _error);
            },
        };

        //inherited some useful function from serviceUtility
        // return angular.extend(_functions, serviceUtility.inheritedUtilities(_functions));
        return _functions;
    }]);
