//Write here all the factories about the whole application

let expanseLiteApp = require('../modules/appModule.js');

expanseLiteApp.factory('azimuthFactory', ['azimuthServices', 'assetDataServices', 'utility', 'iframeComunication', 'assetServices', 'customerServices', 
    'googlemaps', '$timeout', 'popup', 'myAlert', 'expanseConst', 'canvasDraw','htmlEvents','$mdDialog', 'popupConfirm',
    function (azimuthServices, assetDataServices, utility, iframeComunication, assetServices, customerServices, 
        googlemaps, $timeout, popup, myAlert, expanseConst, canvasDraw, htmlEvents, $mdDialog, popupConfirm) {
        
        var _scope;
        var _azimuthInfo;
        var _azimuthInfoMessage;
        var _views = {
            azimuth: 'azimuth',
            antennasCenter: 'antennasCenter'
        }

        var cw1;
        var ch1;
        var iw1;
        var ih1;
        // var _containerId;
        // var _canvasId;
        // var _canvasNotesId;
        // var _imageId;
        // var _compassId;
        // var _compassOldId;
        // var _compassNeedleId;
        // var _compassNeedleOldId;
        var _map;
        var _marker;
        var _mapOptions;
        var _containerId = "_azimuth_container";
        var _canvasId = "_azimuth_canvas";
        var _canvasNotesId = "_azimuth_canvas_notes";
        var _compassId = "_azimuth_compass";
        var _compassOldId = "_azimuth_compass_old";
        var _compassNeedleId = "_azimuth_compass_needle";
        var _compassNeedleOldId = "_azimuth_compass_needle_old";
        var _imageId = "_azimuth_image";
        var _imageAntennasCenterId = "_azimuth_ac_image";
        var _canvasAntennasCenterId = "_azimuth_ac_canvas";
        var _canvasNoteSave = "_azimuth_canvas_notes_tosave";
        var _canvasNoteSave2 = "_azimuth_canvas_notes_tosave2";
        var _wheelZoomACImageRef;
        var _canvasARRation;
        var _canvasAntennasCenter;

        var _pointers = [];

        var isDrawing = false;
        var isMoving = false;

        function _getHtmlIds() {
            return {
                containerId: _containerId,
                canvasId: _canvasId,
                canvasNotesId: _canvasNotesId,
                compassId: _compassId,
                compassOldId: _compassOldId,
                compassNeedleId: _compassNeedleId,
                compassNeedleOldId: _compassNeedleOldId,
                imageId: _imageId,
                imageAntennasCenterId: _imageAntennasCenterId,
                canvasAntennasCenterId: _canvasAntennasCenterId,
                canvasNoteSave: _canvasNoteSave,
                canvasNoteSave2: _canvasNoteSave2,
            }            
        }

        function _earthRadiusInMeters(latitudeRadians) {
            // latitudeRadians is geodetic, i.e. that reported by GPS.
            // http://en.wikipedia.org/wiki/Earth_radius
            var a = 6378137.0;  // equatorial radius in meters
            var b = 6356752.3;  // polar radius in meters
            var cos = Math.cos(latitudeRadians);
            var sin = Math.sin(latitudeRadians);
            var t1 = a * a * cos;
            var t2 = b * b * sin;
            var t3 = a * cos;
            var t4 = b * sin;
            return Math.sqrt((t1 * t1 + t2 * t2) / (t3 * t3 + t4 * t4));
        }

        function _geocentricLatitude(lat) {
            // Convert geodetic latitude 'lat' to a geocentric latitude 'clat'.
            // Geodetic latitude is the latitude as given by GPS.
            // Geocentric latitude is the angle measured from center of Earth between a point and the equator.
            // https://en.wikipedia.org/wiki/Latitude#Geocentric_latitude
            var e2 = 0.00669437999014;
            var clat = Math.atan((1.0 - e2) * Math.tan(lat));
            return clat;
        }

        function _locationToPoint(c) {
            // Convert (lat, lon, elv) to (x, y, z).
            var lat = c.lat * Math.PI / 180.0;
            var lon = c.lon * Math.PI / 180.0;
            var radius = _earthRadiusInMeters(lat);
            var clat = _geocentricLatitude(lat);

            var cosLon = Math.cos(lon);
            var sinLon = Math.sin(lon);
            var cosLat = Math.cos(clat);
            var sinLat = Math.sin(clat);
            var x = radius * cosLon * cosLat;
            var y = radius * sinLon * cosLat;
            var z = radius * sinLat;

            // We used geocentric latitude to calculate (x,y,z) on the Earth's ellipsoid.
            // Now we use geodetic latitude to calculate normal vector from the surface, to correct for elevation.
            var cosGlat = Math.cos(lat);
            var sinGlat = Math.sin(lat);

            var nx = cosGlat * cosLon;
            var ny = cosGlat * sinLon;
            var nz = sinGlat;

            x += c.elv * nx;
            y += c.elv * ny;
            z += c.elv * nz;

            return { x: x, y: y, z: z, radius: radius, nx: nx, ny: ny, nz: nz };
        }

        function _rotateGlobe(b, a, bradius, aradius) {
            // Get modified coordinates of 'b' by rotating the globe so that 'a' is at lat=0, lon=0.
            var br = { lat: b.lat, lon: (b.lon - a.lon), elv: b.elv };
            var brp = _locationToPoint(br);

            // Rotate brp cartesian coordinates around the z-axis by a.lon degrees,
            // then around the y-axis by a.lat degrees.
            // Though we are decreasing by a.lat degrees, as seen above the y-axis,
            // this is a positive (counterclockwise) rotation (if B's longitude is east of A's).
            // However, from this point of view the x-axis is pointing left.
            // So we will look the other way making the x-axis pointing right, the z-axis
            // pointing up, and the rotation treated as negative.

            var alat = _geocentricLatitude(-a.lat * Math.PI / 180.0);
            var acos = Math.cos(alat);
            var asin = Math.sin(alat);

            var bx = (brp.x * acos) - (brp.z * asin);
            var by = brp.y;
            var bz = (brp.x * asin) + (brp.z * acos);

            return { x: bx, y: by, z: bz, radius: bradius };
        }

        function _azimuth(pointA, pointB) {
            var ap = _locationToPoint(pointA);
            var bp = _locationToPoint(pointB);

            var br = _rotateGlobe(pointB, pointA, bp.radius, ap.radius);
            if (br.z * br.z + br.y * br.y > 1.0e-6) {
                var theta = Math.atan2(br.z, br.y) * 180.0 / Math.PI;
                var azimuth = 90.0 - theta;
                if (azimuth < 0.0) {
                    azimuth += 360.0;
                }
                if (azimuth > 360.0) {
                    azimuth -= 360.0;
                }

                return azimuth;
            }
            // return undefined
            return 0
        }

        function _createPoint(_lat, _lng, _elv) {
            return {
                lat: _lat,
                lon: _lng,
                elv: _elv
            }
        }

        function _setting(_success) {
            utility.callFunctionEveryTime("$('#" + _canvasId + "').get(0)", 200, function () {
                _azimuthInfo.canvas = $("#" + _canvasId).get(0);
                _azimuthInfo.c = _azimuthInfo.canvas.getContext("2d");
                _azimuthInfo.canvasNotes = $("#" + _canvasNotesId).get(0);

                utility.callFunctionEveryTime("$('#" + _compassId + "').width()>0", 200, function () {

                    var _compassX = ($("#" + _containerId).width() - $("#" + _compassId).width()) / 2;
                    $("#" + _compassOldId).css("left", _compassX);
                    $("#" + _compassNeedleOldId).css("left", _compassX);
                    $("#" + _compassId).css("left", _compassX);
                    $("#" + _compassNeedleId).css("left", _compassX);
                    _azimuthInfo.radius = $("#" + _compassId).width() / 2;


                    var cw = $("#" + _compassId).width();
                    var ch = $("#" + _compassId).height();
                    var ctop = Number($("#" + _compassId).css("top").replace("px", ""));
                    var cleft = Number($("#" + _compassId).css("left").replace("px", ""))
                    var ctop = Number($("#" + _compassNeedleId).css("top").replace("px", ""));
                    var cleft = Number($("#" + _compassNeedleId).css("left").replace("px", ""))
                    var itop = $("#" + _imageId).position().top;
                    var ileft = $("#" + _imageId).position().left;

                    _azimuthInfo.centerX = cw / 2 + cleft;
                    _azimuthInfo.centerY = ch / 2 + ctop;


                    // //resize window
                    cw1 = $("#" + _compassId).width();
                    ch1 = $("#" + _compassId).height();
                    iw1 = $("#" + _imageId).width();
                    ih1 = $("#" + _imageId).height();

                    _success()
                })

                var position = $("#"+_canvasId).position();

                _azimuthInfo.points = {
                    'array': [
                        { 'x': _azimuthInfo.centerX-position.left, 'y': _azimuthInfo.centerY - position.top},
                    ],
                    'size': 1
                }
                _azimuthInfo.lineN = 0;


                $(document).keydown(e => {
                    if (e.ctrlKey) {
                        _azimuthInfo.ctrl = true
                    }
                })
                $(document).keyup(e => {
                    if (_azimuthInfo.ctrl) {
                        _azimuthInfo.ctrl = false

                        var cw = $("#" + _compassId).width();
                        var ch = $("#" + _compassId).height();
                        var ctop = Number($("#" + _compassId).css("top").replace("px", ""));
                        var cleft = Number($("#" + _compassId).css("left").replace("px", ""))
                        var itop = $("#" + _imageId).position().top;
                        var ileft = $("#" + _imageId).position().left;
    
    
                        _azimuthInfo.centerX = cw / 2 + cleft;
                        _azimuthInfo.centerY = ch / 2 + ctop;
                        // _azimuthInfo.centerX = 100;
                        // _azimuthInfo.centerY = 100;
                        drawPath(_azimuthInfo, _azimuthInfo.canvas)
    
                        var _xCompass = _azimuthInfo.xCompassNew - _azimuthInfo.xCompass;
                        var _yCompass = _azimuthInfo.yCompassNew - _azimuthInfo.yCompass;
    
                        $("#" + _compassOldId).css("left", (_azimuthInfo.left + _xCompass) + "px");
                        $("#" + _compassOldId).css("top", (_azimuthInfo.top + _yCompass) + "px");
                        $("#" + _compassNeedleOldId).css("left", (_azimuthInfo.left + _xCompass) + "px");
                        $("#" + _compassNeedleOldId).css("top", (_azimuthInfo.top + _yCompass) + "px");
                        $("#" + _compassNeedleId).css("left", (_azimuthInfo.left + _xCompass) + "px");
                        $("#" + _compassNeedleId).css("top", (_azimuthInfo.top + _yCompass) + "px");
                    }
                })



                _azimuthInfo.canvas.addEventListener('mousedown', (e) => {
                    if (!_azimuthInfo.ctrl) {
                        var position = $("#"+_canvasId).position();
                        _azimuthInfo.points = {
                            'array': [
                                { 'x': _azimuthInfo.centerX - position.left, 'y': _azimuthInfo.centerY - position.top},
                            ],
                            'size': 1
                        }
                        _azimuthInfo.lineN = 1
                        _azimuthInfo.points['size'] = _azimuthInfo.points['array'].length
                    } else {
                        _azimuthInfo.xCompass = e.offsetX;
                        _azimuthInfo.yCompass = e.offsetY;
                        _azimuthInfo.top = Number($("#" + _compassId).css("top").replace("px", ""));
                        _azimuthInfo.left = Number($("#" + _compassId).css("left").replace("px", ""));

                    }
                    isDrawing = true
                })

                _azimuthInfo.canvas.addEventListener('mousemove', (e) => {
                    if (isDrawing) {
                        if (!_azimuthInfo.ctrl) {

                            // var _pointRotate = _getPointsRotate(e.offsetX, e.offsetY, _azimuthInfo.points.array[0].x, _azimuthInfo.points.array[0].y, _azimuthInfo.radius, -_azimuthInfo.azimuthDegrees)
                            var position = $("#"+_canvasId).position();

                            isMoving = true
                            _azimuthInfo.points['array'][_azimuthInfo.points['size']] = {
                                'x': e.offsetX ,
                                'y': e.offsetY ,
                                // 'x': _pointRotate.x,
                                // 'y': _pointRotate.y,
                            };

                            // console.log(e.offsetX, e.offsetY)
                            // console.log("--------", _pointRotate)
                            // var p = _azimuthInfo.points['array'][_azimuthInfo.points['size']];

                            drawPath(_azimuthInfo, _azimuthInfo.canvas)

                            //------------------------------------------Ericsson required to rotate the image instead of the compass----------------------------------------------------------------------
                            // _azimuthInfoMessage.compassDegrees =  _calculateDegreesBetweenAzimuthAndCompass(_azimuthInfoMessage.azimuthDegrees,  _calculateCompassDegrees(e.offsetX, e.offsetY))
                            //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            _azimuthInfoMessage.compassDegrees = _calculateDegreesBetweenAzimuthAndCompass(0, _calculateCompassDegrees(e.offsetX , e.offsetY, _azimuthInfo.points.array[1].x, _azimuthInfo.points.array[1].y ))

                            _azimuthInfo.compassDegrees = _azimuthInfoMessage.compassDegrees.toFixed(2);
                            _azimuthInfo.compassFromAzimuth = ((Number(_azimuthInfo.compassDegrees) - Number(_azimuthInfo.azimuthDegrees) + 360) % 360).toFixed(2)
                            _scope.$apply()

                        } else {
                            var _xCompass = e.offsetX - _azimuthInfo.xCompass;
                            var _yCompass = e.offsetY - _azimuthInfo.yCompass;
                            _azimuthInfo.xCompassNew = e.offsetX;
                            _azimuthInfo.yCompassNew = e.offsetY;

                            $("#" + _compassId).css("left", (_azimuthInfo.left + _xCompass) + "px");
                            $("#" + _compassId).css("top", (_azimuthInfo.top + _yCompass) + "px");
                            $("#" + _compassNeedleId).css("left", (_azimuthInfo.left + _xCompass) + "px");
                            $("#" + _compassNeedleId).css("top", (_azimuthInfo.top + _yCompass) + "px");


                        }
                    }
                })
                _azimuthInfo.canvas.addEventListener('mouseup', (e) => {
                    isDrawing = false

                    if (isMoving)
                        isMoving = false
                    else {
                        _azimuthInfo.points['array'].pop()
                    }

                    if (!_azimuthInfo.ctrl) {
                        _sendAzimuthInfoMessage()
                    }
                })

            })
        }



        function _drawNote(_canvas, _x1, _y1, _x2, _y2, _text, _angle, _line) {
            var canvas = _canvas;
            var ctx = canvas.getContext("2d");
        
            var _midX = (_x1 + _x2) / 2;
            var _midY = (_y1 + _y2) / 2;
            _midX = (_x1 + _midX) / 2;
            _midY = (_y1 + _midY) / 2;
            _midX = (_x1 + _midX) / 2;
            _midY = (_y1 + _midY) / 2;


            var _linePos = (_line)*20;

            ctx.font = "20px Arial";
            ctx.fillStyle = "#ffff00";
            ctx.shadowColor = "#000000";
            ctx.shadowBlur = 8;

            if (_angle > 90 && _angle < 270) {
                ctx.translate(_midX, _midY);
                //flip the text horizontally and vertically
                ctx.scale(-1, -1);
                ctx.rotate(_angle*Math.PI/180);
                ctx.textAlign = 'start';
                ctx.fillText(_text, -100, _linePos-30);
            } else {
                ctx.translate(_midX, _midY);
                ctx.rotate(_angle*Math.PI/180);
                ctx.translate(-_midX, -_midY);
                ctx.textAlign = 'start';
                ctx.fillText(_text, _midX, _midY+_linePos-30);
            }


            ctx.setTransform(1, 0, 0, 1, 0, 0);
        }

        function drawPath(_azimuthInfo, _canvas, _color, _noClear, _rotate) {
            var canvas = _canvas
            var c = canvas.getContext("2d");
            var r = _azimuthInfo.radius;

            if (!_noClear) { 
                c.clearRect(0, 0, canvas.width, canvas.height) 
            }

            _azimuthInfo.points['array'].forEach((value, index) => {
                if (index % 2 == 0) {
                    // if (_rotate) {
                    //     c.translate(canvas.width/2, canvas.height/2);
                    //     // ctx.rotate(Math.PI / 2);                        
                    //     c.rotate(-_azimuthInfo.gimbalYawDegree*Math.PI/180);
                    //     c.translate(-canvas.width/2, -canvas.height/2);
                    // }

                    c.beginPath()
                    c.lineCap = 'round'
                    c.moveTo(value.x, value.y)
                } else {

                    var center = _azimuthInfo.points['array'][0];
                    var p = _azimuthInfo.points['array'][1];                    

                    var theta = Math.atan2(p.y - center.y, p.x - center.x);

                    // if (_rotate) {
                    //     c.setTransform(1, 0, 0, 1, 0, 0);
                    //     c.translate(canvas.width/2, canvas.height/2);
                    //     // ctx.rotate(Math.PI / 2);                        
                    //     c.rotate(_azimuthInfo.gimbalYawDegree*Math.PI/180);
                    //     c.translate(-canvas.width/2, -canvas.height/2);
                    // }

                    c.lineTo(center.x + r * Math.cos(theta), center.y + r * Math.sin(theta));

                    _azimuthInfo.points['array'][1].x = center.x + r * Math.cos(theta);
                    _azimuthInfo.points['array'][1].y = center.y + r * Math.sin(theta);
                    // _azimuthInfo.points['array'][1].x = center.x + ((r - (r / 10)) * Math.cos(theta));
                    // _azimuthInfo.points['array'][1].y = center.y + ((r - (r / 10)) * Math.sin(theta));

                    // console.log("======", _azimuthInfo.points.array[1])

                    c.lineWidth = 3
                    c.shadowBlur = 0;
                    c.strokeStyle = _color ? _color : '#ff0000';
                    c.stroke()

                    // c.setTransform(1, 0, 0, 1, 0, 0);
                }
            })

        }



        function _addWindowResizeListener() {
            window.addEventListener("resize", function () {
                //resize compass_needle respect to image
                var iw2 = $("#" + _imageId).width();
                var ih2 = $("#" + _imageId).height();

                var cw2 = (cw1 * iw2) / iw1
                var ch2 = (ch1 * ih2) / ih1

                $("#" + _compassId).width(cw2);
                $("#" + _compassId).height(ch2);
                $("#" + _compassNeedleId).width(cw2);
                $("#" + _compassNeedleId).height(ch2);

                $("#" + _compassOldId).width(cw2);
                $("#" + _compassOldId).height(ch2);
                $("#" + _compassNeedleOldId).width(cw2);
                $("#" + _compassNeedleOldId).height(ch2);

                //calculate the compass_needle's radius
                _azimuthInfo.radius = $("#" + _compassId).width() / 2;

                //calculate the compass_needle's center
                var cw = $("#" + _compassId).width();
                var ch = $("#" + _compassId).height();
                var ctop = Number($("#" + _compassId).css("top").replace("px", ""));
                var cleft = Number($("#" + _compassId).css("left").replace("px", ""))
                var ctop = Number($("#" + _compassNeedleId).css("top").replace("px", ""));
                var cleft = Number($("#" + _compassNeedleId).css("left").replace("px", ""))
                var itop = $("#" + _imageId).offset().top;
                var ileft = $("#" + _imageId).offset().left;

                var conw = $("#" + _containerId).width();
                var conh = $("#" + _containerId).height();

                var position = $("#"+_canvasId).position();

                _azimuthInfo.centerX = cw / 2 + cleft;
                _azimuthInfo.centerY = ch / 2 + ctop;
                _azimuthInfo.points["array"][0].x = _azimuthInfo.centerX - position.left;
                _azimuthInfo.points["array"][0].y = _azimuthInfo.centerY - position.top

                drawPath(_azimuthInfo, _azimuthInfo.canvas)

            });
        }

        function _resizeCavasWithRespectTo(_canvasId, _objectId) {
            var _elem = $("#"+_canvasId);
            var _elemObj = $("#"+_imageId);
            var _elemCtx = _elem.get(0).getContext("2d");

            // _elemCtx.save();

            _elem.width(_elemObj.width());
            _elem.height(_elemObj.height());
            _elem.attr("width", _elemObj.width());
            _elem.attr("height", _elemObj.height());
            _elem.css("top", _elemObj.get(0).offsetTop);
            _elem.css("left", _elemObj.get(0).offsetLeft);            

            // _elemCtx.restore();
        }

        function _calculateAzimuthByPoint(_lat, _lng, _lat1, _lng1) {
            var _originPoint = _createPoint(_lat, _lng, 0);
            var _point = _createPoint(_lat1, _lng1, 0)
            return _azimuth(_originPoint, _point)
        }

        function _calculateAzimuthByNorth(_lat, _lng) {
            return _calculateAzimuthByPoint(90, 0, _lat, _lng);
        }

        // function _calculateCompassDegrees(_x, _y) {
            // var degrees = Math.atan2(_y - _azimuthInfo.centerY, _x - _azimuthInfo.centerX) * 180 / Math.PI;
           // degrees = (degrees + 450) % 360;
        function _calculateCompassDegrees(_x, _y, _centerX, _centerY) {
            var degrees = Math.atan2(_y - _centerY, _x - _centerX) * 180 / Math.PI;
            degrees = (degrees + 270) % 360;

            return degrees
        }

        function _calculateDegreesBetweenAzimuthAndCompass(_azimuthDegrees, _compassDegrees) {
            if (_azimuthDegrees > _compassDegrees) {
                return 360 - _azimuthDegrees + _compassDegrees
            } else {
                return _compassDegrees - _azimuthDegrees
            }
        }

        function _sendAzimuthInfoMessage() {
            var _msg = {
                command: 'updateAzimuthFrame',
                data: _azimuthInfoMessage
            }
            iframeComunication.sendMessage(JSON.stringify(_msg));
        }


        function _getAssetDataDetails(_fddId, _success, _error) {
            assetDataServices.getFacilityDataDetailsById(_fddId, _resFdd => {
                _azimuthInfo.facilityDataDetails = _resFdd;
                _azimuthInfo.gimbalYawDegree = _resFdd.gimbalYawDegree ? ((_resFdd.gimbalYawDegree + 360) % 360) : 0;

                azimuthServices.getObjectsByFddId(_fddId, _resObj => {
                    _azimuthInfo.listObjects = _resObj;
                })

                assetDataServices.getAssetDataById(_resFdd.facilityDataId, _resFd => {
                    _azimuthInfo.facilityData = _resFd;

                    assetServices.getAsset(_resFd.facilityId, _resF => {
                        _azimuthInfo.facility = _resF;

                        customerServices.getCustomer(_resF.companyId, _resC => {
                            _azimuthInfo.company = _resC;

                            assetDataServices.getAssetDataFileTypes(_res => {
                                _azimuthInfo.listAssetDataFileTypeByKey = utility.getArrayByField(_res, 'key');

                                _success()
                            }, _err => {
                                _error(_err)
                            })
                        }, _err => {
                            _error(_err)
                        })
                    }, _err => {
                        _error(_err)
                    })

                }, _err => {
                    _error(_err)
                })
            }, _err => {
                _error(_err)
            })
        };


        function _createClickBindOnMap(_idMap, _map, _success) {
            utility.callFunctionEveryTime("$('#" + _idMap + "').contents().find('div[style*=\"touch-action: pan-x pan-y\"]').get(0)", 200, function () {
                $('#' + _idMap).contents().find('div[style*="touch-action: pan-x pan-y"]').bind("click", function (event) {
                    console.log("--------------", event.offsetX, event.offsetY)

                    var topRight = _map.getProjection().fromLatLngToPoint(_map.getBounds().getNorthEast());
                    var bottomLeft = _map.getProjection().fromLatLngToPoint(_map.getBounds().getSouthWest());
                    var scale = Math.pow(2, _map.getZoom());
                    var worldPoint = new google.maps.Point(event.offsetX / scale + bottomLeft.x, event.offsetY / scale + topRight.y);

                    var _latLng = _map.getProjection().fromPointToLatLng(worldPoint);

                    _success(event, _latLng)
                })
            })
        }

        function _calculateAddMarkerCallback(_event, _latLng) {
            if (_event.isFirstPoint) {
                _azimuthInfo.azimuthPoints = [{
                    lat: _latLng.lat(),
                    lng: _latLng.lng()
                }];
            } else {
                _azimuthInfo.azimuthPoints.push({
                    lat: _latLng.lat(),
                    lng: _latLng.lng()
                });

                var _azimuthCalculate = _calculateAzimuthByPoint(_azimuthInfo.azimuthPoints[0].lat, _azimuthInfo.azimuthPoints[0].lng, _azimuthInfo.azimuthPoints[1].lat, _azimuthInfo.azimuthPoints[1].lng);
                console.log("azimuth calculate " + _azimuthCalculate)

                // drawPath(_azimuthInfo, _azimuthInfo.canvasNotes, '#ffff00', true)

                // var _x1 = _azimuthInfo.points.array[0].x;
                // var _y1 = _azimuthInfo.points.array[0].y;
                // var _x2 = _azimuthInfo.points.array[1].x;
                // var _y2 = _azimuthInfo.points.array[1].y;
                // var _note1 = _azimuthInfo.note ? _azimuthInfo.note : '';
                // var _note2 = 'Azimuth°: ' + _azimuthCalculate.toFixed(2);
                var _note1 = _azimuthInfo.note ? _azimuthInfo.note : '';
                var _note1 = _azimuthCalculate.toFixed(2) + '°';
                _note1 += _azimuthInfo.note ? ' - ' + _azimuthInfo.note : '';

                // _drawNote(_azimuthInfo.canvasNotes, _x1, _y1, _x2, _y2, _note1)
                // _drawNote(_azimuthInfo.canvasNotes, _x1, _y1 + 30, _x2, _y2 + 30, _note2)

                // var _position = $("#"+_canvasId).position();
                var canvas = $("#"+_canvasId);

                var position = canvas.position();
                
                var _rotatePoint =_rotateByCanvasCenter(_canvasId, _azimuthInfo.points.array[0].x+position.left, _azimuthInfo.points.array[0].y+position.top, -_azimuthInfo.gimbalYawDegree);
                var x1 = _rotatePoint.x + canvas.width()/2;
                var y1 = _rotatePoint.y + canvas.height()/2;

                _rotatePoint =_rotateByCanvasCenter(_canvasId, _azimuthInfo.points.array[1].x+position.left, _azimuthInfo.points.array[1].y+position.top, -_azimuthInfo.gimbalYawDegree);
                var x2 = _rotatePoint.x + canvas.width()/2;
                var y2 = _rotatePoint.y + canvas.height()/2;
    


                // add the new azimuth point into _azimuthInfo.azimuths array
                _azimuthInfo.azimuths.push({
                    width: _azimuthInfo.canvasNotes.width,
                    height: _azimuthInfo.canvasNotes.height,
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                    note1: _note1,
                    // note2: _note2,
                    angle: _azimuthInfo.gimbalYawDegree,
                    azimuth: Number(_azimuthInfo.compassDegrees)
                })

                // _azimuthInfo.azimuths.push({
                //     width: _azimuthInfo.canvasNotes.width,
                //     height: _azimuthInfo.canvasNotes.height,
                //     x1: x1,
                //     y1: y1,
                //     x2: x2,
                //     y2: y2,
                //     note1: _note1,
                //     note2: _note2
                // })

                _renderAzimuths(_azimuthInfo.azimuths[_azimuthInfo.azimuths.length-1], _azimuthInfo.canvasNotes);
            }
        }

        function _getPointsRotate(_x, _y, _degrees) {
            // var _radiant = _degrees*Math.PI/180;
            // var _centerX = $("#"+_canvasId).width()/2;
            // var _centerY = $("#"+_canvasId).height()/2;
            // var cos = Math.cos(_radiant)
            // var sin = Math.sin(_radiant)
            // var nx = (cos * (_x - _centerX)) + (sin * (_y - _centerY)) + _centerX,
            // var ny = (cos * (_y - _centerY)) - (sin * (_x - _centerX)) + _centerY;

            // return {x: nx, y: ny}



            // var theta = Math.atan2(_y - _centerY, _x - _centerX);

            // return {x: _centerX + _r * Math.cos(theta), y: _centerY + _r * Math.sin(theta)}

            // var angle = (_degrees*Math.PI/180);
            // var cos = Math.cos(angle);
            // var sin = Math.sin(angle);
        
            // var newx = Math.floor(_x * cos - _y * sin);
            // var newy = Math.floor(_x * sin + _y * cos);      

            // return {x:newx, y:newy}            
        }

        function _renderAzimuths(_azimuth, _canvas) {
            var _points = angular.copy(_azimuthInfo.points);

            // _azimuths.forEach(e => {
                var e = _azimuth;

                var _dx = _canvas.width/e.width;
                var _dy = _canvas.height/e.height;
                // var _p1 = _getPointsRotate(e.x1*_dx, e.y1*_dy, _azimuthInfo.gimbalYawDegree);
                // var _p2 = _getPointsRotate(e.x2*_dx, e.y2*_dy, _azimuthInfo.gimbalYawDegree);
                var _x1 = e.x1*_dx;
                var _y1 = e.y1*_dy;
                var _x2 = e.x2*_dx;
                var _y2 = e.y2*_dy;
                // var _x1 = _p1.x;
                // var _y1 = _p1.y;
                // var _x2 = _p2.x;
                // var _y2 = _p2.y;

                if (_azimuthInfo.points.array.length==0) {
                    _azimuthInfo.points.array.push({x: _x1, y: _y1});
                } else {
                    _azimuthInfo.points.array[0].x = _x1;
                    _azimuthInfo.points.array[0].y = _y1;   
                }

                if (_azimuthInfo.points.array.length==1) {
                    _azimuthInfo.points.array.push({x: _x2, y: _y2});
                } else {
                    _azimuthInfo.points.array[1].x = _x2;
                    _azimuthInfo.points.array[1].y = _y2;    
                }

                drawPath(_azimuthInfo, _azimuthInfo.canvasNotes, '#ffff00', true, true)
                _drawNote(_canvas, _x1, _y1, _x2, _y2, e.note1, (-e.angle+e.azimuth-90)%360, 1)
                // _drawNote(_canvas, _x1, _y1, _x2, _y2, e.note2, (-e.angle+e.azimuth-90)%360, 2)
            // })

            _azimuthInfo.points = angular.copy(_points);
        }

        // const cropCanvas = (sourceCanvas, left, top, width, height) => {
        //     let destCanvas = document.createElement('canvas');
        //     destCanvas.width = width;
        //     destCanvas.height = height;
        //     destCanvas.getContext("2d").drawImage(
        //         sourceCanvas,
        //         left, top, width, height,  // source rect with content to crop
        //         0, 0, width, height);      // newCanvas, same size as source rect
        //     return destCanvas;
        // }

        // const rotateCanvas = (sourceCanvas, left, top, width, height) => {
        //     let destCanvas = document.createElement('canvas');
        //     destCanvas.width = width;
        //     destCanvas.height = height;
        //     destCanvas.getContext("2d").rotate(45 * (Math.PI / 180))
        //     destCanvas.getContext("2d").drawImage(
        //         sourceCanvas,
        //         left, top, width, height,  // source rect with content to crop
        //         0, 0, width, height);      // newCanvas, same size as source rect
        //     return destCanvas;
        // }

        function _saveImage(_azimuthImageUrl, _userId, _success) {
            var canvas = $("#"+_canvasNotesId).get(0);
            var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.


            var canvas1 = $('#'+_canvasNoteSave).get(0);
            var ctx = canvas1.getContext("2d");
            var canvas2 = $('#'+_canvasNoteSave2).get(0);
            var ctx2 = canvas2.getContext("2d");

            var background = new Image();
            background.crossOrigin = "anonymous";
            background.src = _azimuthImageUrl;

            // Make sure the image is loaded first otherwise nothing will draw.
            background.onload = function () {
                var _imgWidth = $("#_azimuth_image").width()
                var _imgHeight = $("#_azimuth_image").height()
                // var _x = (1920 - $("#_azimuth_image").width()) / 2;
                // var _y = 0;

                // ctx.translate(1920 / 2, _imgHeight / 2)
                ctx.translate(0,0)
                // ctx.rotate((Math.PI / 180) * _azimuthInfo.gimbalYawDegree)
                ctx.drawImage(background, 0, 0, _imgWidth, _imgHeight)
                ctx.restore();

                var imageRotate = canvas1.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.

                var backgroundRotate = new Image();
                backgroundRotate.src = imageRotate;

                backgroundRotate.onload = function () {
                    ctx2.drawImage(backgroundRotate, 0, 0);

                    var background1 = new Image();
                    background1.src = image;

                    background1.onload = function () {
                        ctx2.drawImage(background1, 0, 0);

                        // canvas2 = cropCanvas(canvas2, _x, _y, _imgWidth, _imgHeight);
                        // ctx = canvas2.getContext("2d");

                        var image2 = canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.

                        azimuthServices.saveImage(_userId, image2, _azimuthInfo.facilityDataDetails, _res => {
                            myAlert("Azimuth image saved", 'info');
                            _success(_res)
                        })

                    }
                }
            }

        }

        function _setAntennasCenter() {
            $("#" + _canvasAntennasCenterId).offset($("#" + _imageAntennasCenterId).offset());
            $("#" + _canvasAntennasCenterId).width($("#" + _imageAntennasCenterId).width());
            $("#" + _canvasAntennasCenterId).height($("#" + _imageAntennasCenterId).height());
            $("#" + _canvasAntennasCenterId).attr('width', $("#" + _imageAntennasCenterId).width());
            $("#" + _canvasAntennasCenterId).attr('height', $("#" + _imageAntennasCenterId).height());
            $("#" + _canvasAntennasCenterId).position($("#" + _imageAntennasCenterId).position());
            _canvasAntennasCenter.position.left =  $("#_azimuth_ac_canvas").position().left;
            _canvasAntennasCenter.position.top =  $("#_azimuth_ac_canvas").position().top;

            var _containerWidth = $("#_azimuth_ac_container").width();
            var _imageWidth = $("#_azimuth_ac_image").width();
            $("#_azimuth_ac_center").width(_imageWidth);
            $("#_azimuth_ac_left").width((_containerWidth-_imageWidth)/2);
            $("#_azimuth_ac_right").width((_containerWidth-_imageWidth)/2);

            canvasDraw.refreshSizes();
        }

        function _addAntennasCenterListener() {
            canvasDraw.init(_canvasAntennasCenterId);

            _pointers = [];
            if (_azimuthInfo.listObjects) {

                _azimuthInfo.listObjects.forEach(e => {
                    e.info = JSON.parse(e.info);
                    canvasDraw.updateSizes(e.info.canvasSize.width, e.info.canvasSize.height); 

                    switch (e.name) {
                        case 'polygon':
                            _pointers.push(canvasDraw.createPolygon(e.info.points, true))
                            break;                    
                        case 'ellipse':
                            _pointers.push(canvasDraw.createEllipse(e.info.x, e.info.y, e.info.rx, e.info.ry, e.info.rotation))
                            break;                    
                    }
                })
            }

            canvasDraw.setMouseListenerCallback(
                function (evtType, x, y, evt) {

                    switch (evtType) {
                        case 'wheel':
                            //catch the Wheel event on the canvas
                            //and trigger it on the image to do the zoom-in zoom-out
                            htmlEvents.dispatchEventWheel(_imageAntennasCenterId, evt);

                            //chamge the canvas' size base on the image's size
                            $timeout(function () {
                                var size = $("#_azimuth_ac_image").css("background-size").split(" ");
                                $("#" + _canvasAntennasCenterId).width(size[0]);
                                $("#" + _canvasAntennasCenterId).height(size[1]);

                                canvasDraw.updateNewSizes(Number(size[0].replace("px","")), Number(size[1].replace("px","")));

                                var position = $("#_azimuth_ac_image").css("background-position").split(" ");
                                $("#_azimuth_ac_canvas").css("left", _canvasAntennasCenter.position.left + Number(position[0].replace("px",""))+"px");
                                $("#_azimuth_ac_canvas").css("top", _canvasAntennasCenter.position.top + Number(position[1].replace("px",""))+"px");
                            }, 10)

                            break;

                        case 'down':
                            if (_pointers.filter(e => e.isDragging).length==0) {
                                htmlEvents.dispatchEventMouseDown(_imageAntennasCenterId, evt);
                            }

                            break;

                        case 'up':
                            if (_pointers.filter(e => e.isDragging).length==0) {
                                htmlEvents.dispatchEventMouseUp(_imageAntennasCenterId, evt);
                            }

                            break;

                        case 'rightUp':
                            //If the right mouse button has been clicked
                            //means that a shape has been deleted
                            _pointers = canvasDraw.getObjects();

                        case 'move':
                            if (_pointers.filter(e => e.isDragging).length==0) {
                                htmlEvents.dispatchEventMouseMove(_imageAntennasCenterId, evt);

                                //Move the canvas to the new position based on the image position
                                var position = $("#_azimuth_ac_image").css("background-position").split(" ");
                                $("#_azimuth_ac_canvas").css("left", _canvasAntennasCenter.position.left + Number(position[0].replace("px",""))+"px");
                                $("#_azimuth_ac_canvas").css("top", _canvasAntennasCenter.position.top + Number(position[1].replace("px",""))+"px");
                            }

                            break;
                    }

                }           
            ); 
        }

        function _clearCanvas(_canvasId) {
            var canvas = document.getElementById(_canvasId);
            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height) 

            return ctx;
        }

        function _drawAntennasCenter(_objects, _canvasId)  {
            // var canvas = document.getElementById(_canvasId);
            // var ctx = canvas.getContext('2d');
            // ctx.clearRect(0, 0, canvas.width, canvas.height) 
            var ctx = _clearCanvas(_canvasId);

            _objects.forEach(e => {
                e.renderCenter(ctx)
            })

        }

        function _addWheelzoomToImage() {
            // _wheelZoomACImageRef = undefined;
            if (!_wheelZoomACImageRef) {
                _wheelZoomACImageRef = wheelzoom(document.getElementById(_imageAntennasCenterId));
            }
        }


        function _rotateByCanvasCenter(canvasId, x, y, angle) {
            // $timeout(function() {
                // console.log(x, y, cx, cy)

                // var p = $("#_azimuth_canvas_notes").position();

                var cw = $("#" + canvasId).width();
                var ch = $("#" + canvasId).height();
                var p = $("#" + canvasId).position()

                var cx = cw / 2 + p.left;
                var cy = ch / 2 + p.top;

                var angleOfPoint = Math.atan2(x-cx, y-cy) *180/Math.PI;


                var r = Math.sqrt(Math.pow((x-cx),2)+Math.pow((y-cy),2)) //200//373;
                var xy = [r*Math.sin(-(angle-angleOfPoint)*Math.PI/180), r*Math.cos(-(angle-angleOfPoint)*Math.PI/180)]                


                // $("#" + _canvasNotesId).css("transform", "rotate(" + _azimuthInfo.gimbalYawDegree + "deg)");

                // _azimuthInfo.gimbalYawDegree += 1;

                
                // _rotate(x, y, cx, cy, left, top, angle);    
            // }, 100)
            return {x: xy[0], y: xy[1], cx: cx, cy: cy}
        }

        function _updateCompassPosition(x, y) {
            var w = $("#_azimuth_container").width()
            var h = $("#_azimuth_container").height()
            var cw = $("#_azimuth_compass").width()
            var ch = $("#_azimuth_compass").height()

            var newX = x + (w-cw)/2;
            var newY = y + (h-ch)/2;

            $("#" + _compassId).css("left", newX   + "px");
            $("#" + _compassId).css("top", newY + "px");
            $("#" + _compassOldId).css("left", newX   + "px");
            $("#" + _compassOldId).css("top", newY + "px");
            $("#" + _compassNeedleId).css("left", newX   + "px");
            $("#" + _compassNeedleId).css("top", newY + "px");
            $("#" + _compassNeedleOldId).css("left", newX   + "px");
            $("#" + _compassNeedleOldId).css("top", newY + "px");
        }

        function _moveCompassAC(antennasCenterIndex, prevAntennasCenterIndex) {
            var _elem = $("#"+_canvasNotesId)
            var _ctx = _elem.get(0).getContext('2d');
            var _ratio = canvasDraw.getRatio(_ctx);
            var _position = $("#"+_canvasId).position();

            _drawAntennasCenter(_pointers, _canvasNotesId)
            _azimuthInfo.azimuths.forEach(e => _renderAzimuths(e, _azimuthInfo.canvasNotes));

            // _pointers[prevAntennasCenterIndex].renderCenter(_ctx);
            _pointers[antennasCenterIndex].renderCenter(_ctx, '#ff0000');

            var x = _pointers[antennasCenterIndex].getCenter().x * _ratio.dx + _position.left;
            var y = _pointers[antennasCenterIndex].getCenter().y * _ratio.dy + _position.top; 

            _azimuthInfo.points['array'].pop() 
            drawPath(_azimuthInfo, _azimuthInfo.canvas)

            var _rotatePoint =_rotateByCanvasCenter(_canvasId, x, y, _azimuthInfo.gimbalYawDegree);

            _azimuthInfo.centerX = _rotatePoint.x + _rotatePoint.cx;
            _azimuthInfo.centerY = _rotatePoint.y + _rotatePoint.cy;

            _updateCompassPosition(_rotatePoint.x, _rotatePoint.y)
        }

        function _saveAntennaCenterObjects(_userId, _fdd, _pointers) {
            var _list = [];
            _pointers.forEach(e => {
                _list.push({
                    facilityDataDetailsId: _fdd.id,
                    name: e.getTypeName(),
                    info: JSON.stringify(e.getInfo())

                })
            })

            azimuthServices.saveCanvasObjects(_userId, _list, _fdd.id);
        }

        var _fns = {
            boot: function (wheelZoomRef, _scopeParam, _fddId, _success) {
                wheelZoomRef = undefined;

                _scope = _scopeParam;
                _azimuthInfo = {
                    viewActive: _views.azimuth,
                    views: _views,
                    isAntennasCenterInitiated: false,
                    points: undefined,
                    canvas: undefined,
                    c: undefined,
                    centerX: undefined,
                    centerY: undefined,
                    lineN: undefined,
                    radius: 0,
                    ctrl: false,
                    xCompass: undefined,
                    yCompass: undefined,
                    xCompassNew: undefined,
                    yCompassNew: undefined,
                    top: undefined,
                    left: undefined,
                    azimuthDegrees: undefined,
                    compassDegrees: 0,
                    gimbalYawDegree: 0,
                    isMapImageOverlapped: false,
                    imageOpacity: 0.5,
                    antennasCenterIndex: 0,
                    htmlIds: _getHtmlIds(),
                    canvasNotesPoints: undefined,
                    azimuths: [],
                }

                _azimuthInfoMessage = {
                    azimuthDegrees: undefined,
                    compassDegrees: 0
                }

                _mapOptions = {
                    zoomControl: true,
                    tilt: 0,
                    rotateControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                }

                _canvasARRation = {x:1, y:1};
                _canvasAntennasCenter = {position: {}};

                isDrawing = false;
                isMoving = false;

                _pointers = [];

                _getAssetDataDetails(_fddId, _res => {
                    _success(_azimuthInfo)
                });
            },
            setting: function (_success) {
                _setting(_resSetting => {
                    _addWindowResizeListener();
                    _addAntennasCenterListener();
                    $( "#"+_imageId ).load( function() {
                        $timeout(function() {
                            _resizeCavasWithRespectTo(_canvasId, _imageId);
                            _resizeCavasWithRespectTo(_canvasNotesId, _imageId);
                            _resizeCavasWithRespectTo(_canvasNoteSave, _imageId);
                            _resizeCavasWithRespectTo(_canvasNoteSave2, _imageId);
    
                            _drawAntennasCenter(_pointers, _canvasNotesId)    
                        }, 500)
                    } )

                    _success(_azimuthInfo);
                })


            },
            getHtmlIds: function() {
                return _getHtmlIds();
            },
            calculateAzimuthByNorth: function (_lat, _lng) {
                _azimuthInfo.azimuthDegrees = _calculateAzimuthByNorth(_lat, _lng).toFixed(2);
                _azimuthInfo.compassFromAzimuth = _azimuthInfo.azimuthDegrees;

                return _azimuthInfoMessage.azimuthDegrees = _azimuthInfo.azimuthDegrees;
            },
            calculateAzimuthByPoint: function (_lat, _lng, _lat1, _lng1) {
                console.log(_lat, _lng, _lat1, _lng1, _calculateAzimuthByPoint(_lat, _lng, _lat1, _lng1))
                return _calculateAzimuthByPoint(_lat, _lng, _lat1, _lng1).toFixed(2);
            },
            sendAzimuthInfoMessage: function () {
                _sendAzimuthInfoMessage();
            },
            rotateCompassNeedle: function (_azimuthDegrees) {
                //--------------Ericsson required to rotate the image instead of the compass--------------------
                // $("#"+_compassId).css("transform", "rotate("+_azimuthDegrees+"deg)");
                // $("#"+_compassOldId).css("transform", "rotate("+_azimuthDegrees+"deg)");
                $("#" + _compassNeedleId).css("transform", "rotate(" + _azimuthDegrees + "deg)");
                $("#" + _compassNeedleOldId).css("transform", "rotate(" + _azimuthDegrees + "deg)");
                //----------------------------------------------------------------------------------------------

                // $("#"+_imageId).css("transform", "rotate("+_azimuthDegrees+"deg)");
            },
            createMap: function () {
                utility.callFunctionEveryTime("$('#_azimuth_map').get(0)", 200, function () {
                    googlemaps.initGoogleMap("#_azimuth_map", _m => {
                        _map = _m;

                        var _lat = _azimuthInfo.facilityDataDetails.latitude;
                        var _lng = _azimuthInfo.facilityDataDetails.longitude;

                        _map.setZoom(18);
                        _map.setCenter(new google.maps.LatLng(_lat, _lng))

                        _mapOptions.zoomControl = !_azimuthInfo.isMapImageOverlapped;
                        _map.setOptions(_mapOptions)

                        _createClickBindOnMap('_azimuth_map', _map, _calculateAddMarkerCallback)


                        _marker = new google.maps.Marker({
                            map: _map,
                            position: new google.maps.LatLng(_lat, _lng),
                            icon: '/img/icons/markers/marker-photos.png',
                            title: _azimuthInfo.facilityDataDetails.fileName,
                            lat: _lat,
                            log: _lng,
                            id: _azimuthInfo.facilityDataDetails.id,
                        });

                    });
                });
            },
            rotateImage: function (_degree) {
                $("#" + _imageId).css("transform", "rotate(" + _degree + "deg)");
                // $("#" + _canvasId).css("transform", "rotate(" + _degree + "deg)");
                $("#" + _canvasNotesId).css("transform", "rotate(" + _degree + "deg)");
                $("#" + _canvasNoteSave).css("transform", "rotate(" + _degree + "deg)");
                $("#" + _canvasNoteSave2).css("transform", "rotate(" + _degree + "deg)");
            },
            toggleMapImageOverlapped: function () {
                // _azimuthInfo.isMapImageOverlapped = !_azimuthInfo.isMapImageOverlapped;

                $timeout(function () {
                    isDrawing = true;
                    _azimuthInfo.ctrl = true;



                    var ace = document.getElementById(_compassId);
                    var ac = document.getElementById(_containerId);
                    var elem = document.getElementById(_canvasId);

                    var e = new Event('mousemove');

                    // set coordinates
                    e.offsetX = ac.offsetWidth / 2 - ace.width / 2;
                    e.offsetY = ac.offsetHeight / 2 - ace.height / 2;

                    // console.log(ac.offsetWidth, ace.width, ac.offsetHeight, ace.heightù)
                    _azimuthInfo.xCompass = 0;
                    _azimuthInfo.yCompass = 0;
                    _azimuthInfo.left = 0;
                    _azimuthInfo.top = 0;

                    // trigger event - must trigger on document
                    elem.dispatchEvent(e);

                    $timeout(function () {
                        var e = new Event('mouseup');
                        elem.dispatchEvent(e);
                        var e = new Event('keyup');
                        document.dispatchEvent(e);

                        _resizeCavasWithRespectTo(_canvasId, _imageId);
                        _resizeCavasWithRespectTo(_canvasNotesId, _imageId);
                        _resizeCavasWithRespectTo(_canvasNoteSave, _imageId);
                        _resizeCavasWithRespectTo(_canvasNoteSave2, _imageId);
    
                        _drawAntennasCenter(_pointers, _canvasNotesId)
                        _azimuthInfo.azimuths.forEach(e => _renderAzimuths(e, _azimuthInfo.canvasNotes));
    
                    }, 200);


                }, 300)
            },
            imageOpacity: function (_opacity) {
                $("#" + _imageId).css("opacity", _opacity);
            },
            moveMapToLeft: function () {
                googlemaps.moveMapToLeft(_map);
            },
            moveMapToRight: function () {
                googlemaps.moveMapToRight(_map);
            },
            moveMapToUp: function () {
                googlemaps.moveMapToUp(_map);
            },
            moveMapToDown: function () {
                googlemaps.moveMapToDown(_map);
            },
            zoomIn: function () {
                googlemaps.zoomIn(_map);
            },
            zoomOut: function () {
                googlemaps.zoomOut(_map);
            },
            save: function (_userId, _azimuthImageUrl, _success, _error) {
                var _alert = myAlert("Azimuth is saving. Please wait", "info", 0)
                _azimuthInfo.facilityDataDetails.gimbalYawDegree = _azimuthInfo.gimbalYawDegree;

                assetDataServices.saveAssetDataDetails(_userId, _azimuthInfo.facilityDataDetails, _res => {
                    _azimuthInfo.facilityDataDetails = _res;

                    _saveAntennaCenterObjects(_userId, _azimuthInfo.facilityDataDetails, _pointers);

                    _saveImage(_azimuthImageUrl, _userId, _res1 => {
                        var _ret = {
                            facilityDataDetails: _res,
                            facilityDataDetailsImage: _res1
                        }
                        _alert.close();
                        _success(_ret);
                    })
                }, _err => {
                    _error(_err)
                })
            },
            calculateAddMarker: function (_note) {
                _azimuthInfo.note = _note;
                console.log("event triggere ", _azimuthInfo)
                var _event = $.Event('click', { offsetX: _azimuthInfo.points.array[0].x, offsetY: _azimuthInfo.points.array[0].y, isFirstPoint: true })
                $('#_azimuth_map').contents().find('div[style*=\"touch-action: pan-x pan-y\"]').trigger(_event);

                _event = $.Event('click', { offsetX: _azimuthInfo.points.array[1].x, offsetY: _azimuthInfo.points.array[1].y, isFirstPoint: false })
                $('#_azimuth_map').contents().find('div[style*=\"touch-action: pan-x pan-y\"]').trigger(_event);
            },
            buildNotePopup: function (_this) {
                var _fns = [
                    { 'btn_identifier': '_1_2', 'btn_msg': 'Cancel', 'btn_color': 'btn-clear' },
                    { 'btn_identifier': '_1_1', 'btn_msg': 'Save', 'btn_color': 'btn-primary', 'btn_fn': 'getPointTextFromPopup' }
                ];

                var _popupData = {
                }


                var _config = {
                    size: 'sm',
                    title: 'Add Azimuth',
                    ctrl: 'assetDataCtrl',
                    data: JSON.stringify(_popupData),
                    fns: JSON.stringify(_fns),
                    htmlPage: 'views/asset-data/popup/asset-data_azimuth-note_popup.html',
                }
                popup.openModalBody(_config);
            },
            gimbalYawDegreeModal: function () {
                _azimuthInfo.gimbalYawDegree = (_azimuthInfo.gimbalYawDegree + 360) % 360;
            },


            //Switch between the views belonging to the azimuth screen
            switchToView: function (_view) {
                _azimuthInfo.viewActive = _view;

                switch (_view) {
                    case _views.antennasCenter:
                        utility.callFunctionEveryTime("$('#_azimuth_ac_toolbar').get(0)", 200, function () {
                            $("#_azimuth_toolbar").css("display", "none!important");
                            $("#_azimuth_ac_toolbar").css("display", "inline-flex!important");
                            _addWheelzoomToImage();
                            _setAntennasCenter();
                            canvasDraw.renderAllObjects();
                        });
                        break;
                    case _views.azimuth:
                        utility.callFunctionEveryTime("$('#_azimuth_ac_toolbar').get(0)", 200, function () {
                            $("#_azimuth_toolbar").css("display", "inline-flex!important");
                            $("#_azimuth_ac_toolbar").css("display", "none!important");

                            _drawAntennasCenter(_pointers, _canvasNotesId)
                            _azimuthInfo.azimuths.forEach(e => _renderAzimuths(e, _azimuthInfo.canvasNotes));
                        })
                        break;
                }
            },

            addAntennasCenterPolygon: function() {
                var points = [
                    {x:100, y:100},
                    {x:150, y:100},
                    {x:150, y:150},
                    {x:100, y:150},
                    {x:100, y:100}                    
                ]

                _pointers.push(canvasDraw.createPolygon(points, true));
                canvasDraw.renderAllObjects();
            },
            addAntennasCenterEllipse: function() {
                _pointers.push(canvasDraw.createEllipse(100, 100, 50, 50, 0));
                canvasDraw.renderAllObjects();
            },
            setACPointerModifiable: function() {
                canvasDraw.setObjectModifiable(!_pointers[0].getModifiable());
            },
            moveCompassACBackward: function() {
                var _prevIndex = _azimuthInfo.antennasCenterIndex; 
                _azimuthInfo.antennasCenterIndex = _azimuthInfo.antennasCenterIndex>0?(_azimuthInfo.antennasCenterIndex - 1): _pointers.length-1;
                
                _moveCompassAC(_azimuthInfo.antennasCenterIndex, _prevIndex);
            },
            moveCompassACForward: function() {
                var _prevIndex = _azimuthInfo.antennasCenterIndex; 
                _azimuthInfo.antennasCenterIndex = _azimuthInfo.antennasCenterIndex<(_pointers.length-1)?(_azimuthInfo.antennasCenterIndex + 1): 0;

                _moveCompassAC(_azimuthInfo.antennasCenterIndex, _prevIndex);
            },
            anthennaCenterDeleteAllObjects: function() {
                var _confirm = popupConfirm('Delete the shapes', 'Do you want delete all the shapes? ')
                $mdDialog.show(_confirm).then(_yes => {
                    _pointers = [];
                    canvasDraw.deleteAllObjects();
                    canvasDraw.renderAllObjects();
                });
            },
            removeMarkers: function() {
                var _confirm = popupConfirm('Delete the azimuth markers', 'Do you want delete all the azimuth markers? ')
                $mdDialog.show(_confirm).then(_yes => {
                    _drawAntennasCenter(_pointers, _canvasNotesId)
                    _azimuthInfo.azimuths = [];
                });
            }
        }

        return _fns;
    }]);